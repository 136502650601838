var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "group",
      scopedSlots: _vm._u(
        [
          _vm.group
            ? {
                key: "title",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.group.name) + " ")]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.group
        ? _c("vel-entity-information-card", {
            attrs: {
              isLoading: _vm.isLoadingGroup,
              entity: _vm.group,
              properties: ["name", "currency"],
              explicitProperties: [
                {
                  name: "kind",
                  value: _vm.getTranslatedKindName(_vm.group.kind)
                }
              ],
              avatar: "name",
              translationPrefix: "group.info"
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "locations__alerts" },
        [
          _vm.getGroupLocationsError
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "error" },
                  on: { dismiss: _vm.clearGetGroupLocationsError }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("group.errors.getGroupLocationsError")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.latestAddedGroupLocationsCount
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "success" },
                  on: { dismiss: _vm.clearLatestAddedGroupLocations }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("group.locations.form.success.addLocations")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.latestDeletedGroupLocation
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "success" },
                  on: { dismiss: _vm.clearLatestDeletedGroupLocation }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("group.locations.form.success.deleteLocation")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "vel-card",
        {
          staticClass: "group__permissions",
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c("div", { staticClass: "group__permissions-title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("group.permissions.title")) + " "
                    ),
                    _c(
                      "div",
                      { staticClass: "group__permissions__list-length" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.groupPermissions &&
                                _vm.groupPermissions.length
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.isLoadingGroupPermissions
            ? _c("div", { staticClass: "permissions" }, [
                _c(
                  "div",
                  { staticClass: "permissions__list" },
                  [
                    _vm.hasPermissions
                      ? _c(
                          "div",
                          [
                            _c("permissions-list", {
                              staticClass: "list__table",
                              attrs: {
                                translationPrefix: "group",
                                permissions: _vm.groupPermissions
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "vel-alert",
                          {
                            staticClass: "list__none",
                            attrs: { dismissable: false, level: "notice" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("group.permissions.none")) +
                                " "
                            )
                          ]
                        )
                  ],
                  1
                )
              ])
            : _c("vel-spinner")
        ],
        1
      ),
      _c(
        "vel-card",
        {
          staticClass: "group__locations",
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c("div", { staticClass: "group__locations-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("group.locations.title")) + " "),
                    _c(
                      "div",
                      { staticClass: "group__locations__list-length" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.groupLocations && _vm.groupLocations.length
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.areLocationsLoading
            ? _c("div", { staticClass: "locations" }, [
                _c(
                  "div",
                  { staticClass: "locations__actions" },
                  [
                    _c(
                      "vel-button",
                      {
                        staticClass: "actions__add",
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleLocationAdd()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("group.locations.actions.add")) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "locations__list" },
                  [
                    _vm.hasLocations
                      ? _c(
                          "div",
                          [
                            _c("locations-list", {
                              staticClass: "list__table",
                              attrs: {
                                locationTitle: _vm.$t("group.locations.title"),
                                locations: _vm.groupLocations
                              },
                              on: {
                                goToLocation: _vm.handleGoToLocation,
                                delete: _vm.handleLocationDelete
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "vel-alert",
                          {
                            staticClass: "list__none",
                            attrs: { dismissable: false, level: "notice" }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("group.locations.none")) + " "
                            )
                          ]
                        )
                  ],
                  1
                )
              ])
            : _c("vel-spinner"),
          _c(
            "div",
            { staticClass: "location__modals" },
            [
              _vm.showAddLocation
                ? _c("add-location-modal", {
                    staticClass: "modals__add",
                    attrs: {
                      groupId: _vm.groupId,
                      locationsOptions: _vm.selectableLocations
                    },
                    on: {
                      dismiss: _vm.handleAddModalDismiss,
                      success: _vm.handleAddModalDismiss
                    }
                  })
                : _vm._e(),
              _vm.showDeleteLocation && !!_vm.deletedLocation
                ? _c("delete-location-modal", {
                    staticClass: "modals__delete",
                    attrs: {
                      groupId: _vm.groupId,
                      location: _vm.deletedLocation
                    },
                    on: {
                      dismiss: _vm.handleDeleteModalDismiss,
                      success: _vm.handleDeleteModalDismiss
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }