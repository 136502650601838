<template>
  <vel-backoffice-page
    class="backoffice-page bootstrap"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    :scroll="false"
  >
    <template v-slot:title>
      {{ $t('pages.location-configuration') }}
    </template>
    <template v-slot:header>
      <b-container fluid>
        <b-row class="d-flex flex-column flex-md-row pt-3">
          <b-col class="d-flex align-items-center">
            <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
          </b-col>
          <b-col class="d-flex justify-content-end align-items-center">
            <span class="d-none d-md-flex pr-2">{{ $t('employee.list.locationsLabel') }}:</span>
            <vel-location-selector
              :allowGlobalLocationOption="getLocationsAll.length > 1"
              class="toolbar__location-selector w-100 w-md-auto pt-2 pt-md-0"
              :allLocationsLabel="$t('dashboard.locationSelector.allLocationsText')"
              :disabled="getLocationsIsLoading"
              :locationId="locationId"
              :locationsOptions="locationsBySelectedGroup"
              :showGlobalLocationOptionGroupLabel="false"
              @change="onLocationChange"
            ></vel-location-selector>
          </b-col>
        </b-row>
        <b-row class="my-4">
          <b-col cols="12" md="6" class="d-flex flex-row justify-content-between">
            <h4>{{ $t('employee.list.title') }}</h4>
            <b-button
              v-if="showActive"
              variant="primary"
              class="ml-4 d-flex align-items-center d-md-none"
              :to="{ name: 'employee-create' }"
            >
              <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
              <span class="d-none d-md-block">{{ $t('employee.list.buttons.new') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="d-flex mt-4 mt-md-0 flex-row justify-content-md-end flex-md-row">
            <b-input-group style="max-width: 250px;" class="align-content-end align-self-end">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                debounce="1"
                type="text"
                :placeholder="$t('employee.list.searchPlaceholder')"
              ></b-form-input>
            </b-input-group>
            <b-button
              v-if="showActive"
              variant="primary"
              class="ml-4 d-md-flex align-items-center d-none"
              :to="{ name: 'employee-create' }"
            >
              <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
              {{ $t('employee.list.buttons.new') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-6">
          <b-col cols="12" md="6" class="mb-2 mb-md-0">
            <b-button-group>
              <b-button @click="setActive(true)" :variant="showActive ? 'primary' : 'light'" :disabled="loading">
                {{ $t('employee.list.buttons.active') }}
              </b-button>
              <b-button @click="setActive(false)" :variant="!showActive ? 'primary' : 'light'" :disabled="loading">
                {{ $t('employee.list.buttons.inactive') }}
              </b-button>
            </b-button-group>
          </b-col>
          <b-col cols="12" md="6" class="d-flex flex-row justify-content-end"></b-col>
        </b-row>
      </b-container>
    </template>
    <b-container fluid class="h-100" id="table-container">
      <b-table
        striped
        bordered
        hover
        show-empty
        fixed
        :filter="filter"
        primary-key="id"
        :empty-text="$t('employee.empty')"
        :empty-filtered-text="$t('employee.empty')"
        :filter-included-fields="filterOn"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-debounce="800"
        :busy="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :fields="fields"
        head-variant="info"
        :items="myProvider"
        responsive="sm"
        ref="table"
        sticky-header
      >
        <template #empty="scope">
          <div class="p-2">{{ scope.emptyText }}</div>
        </template>
        <template #emptyfiltered="scope">
          <div class="p-2">{{ scope.emptyFilteredText }}</div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner variant="primary" class="align-middle p-2 m-4"></b-spinner>
          </div>
        </template>
        <template #cell(name)="scope">
          <div class="w-100 d-flex align-items-center bootstrap">
            {{ scope.item.name }}
            <b-tooltip :target="scope.item.id" triggers="hover" placement="top" container="table-container">
              {{ $t('employee.list.rehirableTooltip') }}
            </b-tooltip>
            <button :id="scope.item.id" class="bg-transparent">
              <b-icon
                size="sm"
                v-if="scope.item.terminated && !scope.item.rehireable"
                class="ml-2 d-flex"
                icon="flag-fill"
                variant="danger"
              ></b-icon>
            </button>
          </div>
        </template>
        <template #cell(edit)="scope">
          <b-button variant="link" class="p-0" :to="{ name: 'employee-edit', params: { id: scope.item.id } }">
            <vel-icon size="small" class="row-action text-primary" name="xd-edit" />
            {{ $t('employee.list.table.edit') }}
          </b-button>
        </template>
        <template #cell(more)>
          <b-button variant="link" class="p-0">
            <b-icon icon="three-dots-vertical" aria-label="More" class="row-action"></b-icon>
          </b-button>
        </template>
        <template #cell(activate)="scope">
          <b-button variant="link" class="p-0" :to="{ name: 'employee-edit', params: { id: scope.item.id } }">
            {{ $t('employee.list.buttons.reactivate') }}
          </b-button>
        </template>
      </b-table>
    </b-container>
    <template v-slot:footer>
      <b-container fluid>
        <div class="d-flex justify-content-end align-items-center">
          <b-form-group class="d-none d-md-block">
            {{ $t('employee.list.rowsPerPage') }}
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="[10, 25, 50, 100]"
              style="width: 80px;"
              class="mx-3"
            ></b-form-select>
          </b-form-group>
          <span class="d-none d-md-flex align-self-center mr-6">
            {{ currentPage * perPage - perPage + 1 }} - {{ Math.min(currentPage * perPage, totalRows) }} of
            {{ totalRows }}
          </span>
          <b-pagination
            size="sm"
            pills
            class="m-0 ml-2"
            v-model="currentPage"
            :total-rows="totalRows"
            @filtered="onFiltered"
            :per-page="perPage"
            :limit="10"
            align="right"
          ></b-pagination>
        </div>
      </b-container>
    </template>
  </vel-backoffice-page>
</template>

<script>
import * as JsSearch from 'js-search';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import VelIcon from '@/components/icon/VelIcon';
import VelLocationSelector from '@/components/location-selector/VelLocationSelector';
import { getEmployees } from '@/services/employees.service';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {useDataLocationsStore} from "@/stores/data/locations.module";

export default {
  name: 'EmployeesListPage',
  components: {
    VelBackofficePage,
    VelLocationSelector,
    VelIcon
  },
  data() {
    return {
      showActive: true,
      totalRows: 0,
      filter: null,
      filterOn: ['name'],
      perPage: window.localStorage.getItem('employeeListRowsPerPage') || 25,
      currentPage: 1,
      results: [],
      loading: false,
      clearMessage: true,
      isError: false,
      employees: [],
      items: [],
      sortBy: 'name',
      sortDesc: false
    };
  },
  props: {},
  computed: {
    ...mapPiniaState(useUILocationSelector, {
      locationId: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsIsLoading', 'locationsBySelectedGroup']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsAll']),
    breadcrumbs() {
      return [
        {
          text: 'Configuration',
          active: false,
          to: { name: 'configuration-menu' }
        },
        {
          text: this.$t('employee.list.title'),
          active: true
        }
      ];
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('employee.list.table.headers.name'),
          class: 'name',
          sortable: true
        },
        {
          key: 'title',
          label: this.$t('employee.list.table.headers.title'),
          class: 'title',
          sortable: true
        },
        {
          key: 'edit',
          class: 'edit'
        }
      ];
    }
  },
  watch: {
    perPage(val) {
      window.localStorage.setItem('employeeListRowsPerPage', val);
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['onChange']),
    onLocationChange(locationId) {
      this.onChange(
        {
          locationId
        }
      );
    },
    async myProvider(ctx) {
      try {
        this.loading = true;
        const startAt = ctx.currentPage === 1 ? 0 : (ctx.currentPage - 1) * ctx.perPage;
        if (!this.results.length) {
          this.results = ((await getEmployees({ locationId: this.locationId })) || []).map(r => ({
            ...r,
            name: r.name.trim() === '' ? '---' : r.name,
            title: (r.jobRoles || []).map(b => b.function && b.function.name).join(', ')
          }));
        }
        let results = this.results.filter(r => this.showActive !== r.terminated);

        if (ctx.filter) {
          const search = new JsSearch.Search('id');
          search.addIndex('name');
          search.addDocuments(results);
          results = search.search(ctx.filter);
        }

        if (ctx.sortBy !== '') {
          results = results.sort((a, b) =>
            ctx.sortDesc ? b[ctx.sortBy].localeCompare(a[ctx.sortBy]) : a[ctx.sortBy].localeCompare(b[ctx.sortBy])
          );
        }

        this.totalRows = results.length;
        this.employees = results.slice(startAt, startAt + ctx.perPage);
        this.loading = false;
        return this.employees || [];
      } catch (e) {
        this.loading = false;
        this.isError = true;
        return [];
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setActive(isActive) {
      this.currentPage = 1;
      this.showActive = isActive;
      this.filter = null;
      this.$refs.table.refresh();
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.bootstrap .container-fluid {
  padding-left: 24px;
  padding-right: 24px;
}

/* stylelint-disable */
/deep/ {
  .input-group > .form-control {
    border-left-color: #fff;
    padding-left: 0;
  }
  .input-group-text {
    background-color: #fff !important;
    border-right: 1px solid #fff !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  th {
    background-color: #eff9fd;
  }
  .page,
  .page .vel-page__content {
    overflow: hidden;
  }

  .content,
  .vel-card__body,
  .b-table-sticky-header {
    height: 100%;
    max-height: 100%;
    padding-top: 0;
  }

  .btn-group {
    button {
      text-transform: uppercase;
      width: 90px;
    }
  }
  .pagination {
    height: calc(1.5em + 0.5rem + 2px);
    display: flex;
    align-items: center;
  }

  .thead-info {
    background-color: #eff9fd;
    font-weight: bold;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6fbfe !important;
  }

  .table-hover tbody tr:hover {
    background-color: transparent !important;
  }

  .table-hover tbody tr:nth-of-type(odd):hover {
    background-color: #f6fbfe !important;
  }

  .remoteId {
    width: 150px;
  }

  .name {
    width: 300px;
  }

  .title {
    width: 300px;
    @include breakpoint(medium) {
      width: auto;
    }
  }

  .activate {
    width: 200px;

    .btn-sm {
      padding: 0;
    }

    div {
      display: none;
    }
  }

  .edit {
    width: 115px;

    .btn-sm {
      padding: 0;
    }

    div {
      display: none;
    }
  }

  .more {
    width: 50px;

    .btn-sm {
      padding: 0;
    }

    div {
      display: none;
    }
  }

  .content {
    margin-bottom: 0;
  }

  .row-action {
    color: #444444;
  }
  .page-item.active .page-link {
    background-color: #000 !important;
  }
  .page-link {
    border: none !important;
    color: #444444;
  }

  .toolbar__location-selector {
    max-width: 100%;

    @include breakpoint(medium) {
      max-width: 360px;
    }
  }

  .breadcrumbs {
    padding-top: 16px;
  }

  /deep/ .breadcrumb-item {
    font-size: 0.9rem;
  }

  /deep/ .input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 7px);
  }

  /deep/ table td {
    border-color: #2a31420d !important;
    border-top: none !important;
    padding: 8px 16px !important;
  }

  /deep/ table th {
    padding: 8px 16px !important;
  }

  /deep/ .vel-icon_small {
    height: 1.15rem;
    width: 1.15rem;
  }
}
</style>
