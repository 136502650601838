var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "bg-white p-4 mt-2" },
      [
        _c(
          "b-form-group",
          {
            staticClass: "mr-5 w-100 mb-2 mb-md-0",
            attrs: {
              label: _vm.$t("bigDepartments.form.name.label"),
              description: _vm.$t("bigDepartments.form.required"),
              "label-for": "name"
            }
          },
          [
            _c("b-form-input", {
              class: {
                required:
                  !_vm.bigDepartment ||
                  !_vm.bigDepartment.name ||
                  _vm.bigDepartment.name === ""
              },
              attrs: {
                id: "name",
                maxlength: "50",
                "aria-describedby": "input-live-help input-live-feedback"
              },
              model: {
                value: _vm.bigDepartment.name,
                callback: function($$v) {
                  _vm.$set(
                    _vm.bigDepartment,
                    "name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "bigDepartment.name"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }