<template>
  <vel-form
    :rules="formRules"
    class="change-password-form"
    ref="changePasswordForm"
    :model="changePasswordForm"
    :submit="handleSubmit"
  >
    <vel-form-item prop="currentPassword" :label="$t('changePassword.form.currentPassword.label')">
      <vel-input
        class="current-password-input"
        autofocus
        @focus="clearMessage"
        type="password"
        v-model="changePasswordForm.currentPassword"
      ></vel-input>
    </vel-form-item>
    <vel-form-item prop="newPassword" :label="$t('changePassword.form.newPassword.label')">
      <vel-input
        class="new-password-input"
        @focus="clearMessage"
        type="password"
        v-model="changePasswordForm.newPassword"
      />
    </vel-form-item>
    <vel-form-item prop="confirmPassword" :label="$t('changePassword.form.confirmPassword.label')">
      <vel-input
        class="confirm-password-input"
        @focus="clearMessage"
        type="password"
        v-model="changePasswordForm.confirmPassword"
        @keyup.native.enter="handleSubmit"
      ></vel-input>
    </vel-form-item>

    <vel-alert @dismiss="clearMessage" class="vel-alert_error" v-if="error">
      {{ $t('changePassword.form.resetError.text') }}
    </vel-alert>
    <vel-form-item>
      <vel-button type="primary" class="submit-btn" @click="handleSubmit()">
        {{ $t('changePassword.form.submitButton.text') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/x-input/VelInput';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataUsersChangePassword} from "@/stores/data/users/change-password.module";

export default {
  name: 'change-password-form',
  components: { VelAlert, VelForm, VelFormItem, VelInput, VelButton },
  data() {
    return {
      changePasswordForm: {
        currentPassword: '',
        confirmPassword: '',
        newPassword: ''
      }
    };
  },
  methods: {
    ...mapPiniaActions(useDataUsersChangePassword, ['clearMessage']),
    handleSubmit() {
      this.$refs.changePasswordForm.validate(isValid => {
        if (isValid && !this.changePasswordForm.submitted) {
          this.$emit('submit', this.changePasswordForm);
        }
      });
    },
    validateCurrentPassword(rule, value, cb) {
      if (!value || !value.length) {
        cb(new Error(this.$t('changePassword.form.currentPassword.isRequired')));
      } else if (value.length < 8) {
        cb(new Error(this.$t('changePassword.form.currentPassword.tooShort')));
      } else {
        cb();
      }
    },
    validateNewPassword(rule, value, cb) {
      if (!value || !value.length) {
        cb(new Error(this.$t('changePassword.form.newPassword.isRequired')));
      } else if (value.length < 8) {
        cb(new Error(this.$t('changePassword.form.newPassword.tooShort')));
      } else {
        cb();
      }
    },
    validatePassConfirmation(rule, value, cb) {
      if (!value || !value.length) {
        cb(new Error(this.$t('changePassword.form.confirmPassword.isRequired')));
      } else if (value.length < 8) {
        cb(new Error(this.$t('changePassword.form.confirmPassword.tooShort')));
      } else {
        if (value !== this.changePasswordForm.newPassword) {
          cb(new Error(this.$t('changePassword.form.differentPasswords.text')));
        } else {
          cb();
        }
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataUsersChangePassword, ['error']),
    formRules() {
      return {
        currentPassword: [
          {
            validator: this.validateCurrentPassword,
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            validator: this.validateNewPassword,
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            validator: this.validatePassConfirmation,
            trigger: 'blur'
          }
        ]
      };
    }
  },
  beforeDestroy() {
    this.clearMessage();
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.change-password-form {
  input[type='password'] {
    max-width: rem-calc(300);
  }

  .change-password-title {
    font-size: rem-calc(20);
    text-align: center;
  }

  .vel-alert_error {
    margin-bottom: rem-calc(25);
    max-width: rem-calc(500);
  }
}
</style>
