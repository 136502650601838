var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "divisionForm",
      staticClass: "division-form",
      attrs: {
        model: _vm.divisionForm,
        rules: _vm.divisionFormRules,
        submit: _vm.handleSubmit,
        "label-width": "150px",
        "label-position": _vm.labelPosition
      }
    },
    [
      _c(
        "vel-form-item",
        {
          staticClass: "division-form-remoteId",
          attrs: {
            prop: "remoteId",
            label: _vm.$t("divisionInfo.form.remoteId.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: {
              disabled: "",
              name: "remoteId",
              type: "text",
              value: _vm.divisionForm.remoteId
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "division-form-name",
          attrs: { prop: "name", label: _vm.$t("divisionInfo.form.name.label") }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "name", type: "text", maxlength: "24" },
            model: {
              value: _vm.divisionForm.name,
              callback: function($$v) {
                _vm.$set(_vm.divisionForm, "name", $$v)
              },
              expression: "divisionForm.name"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "division-form-alternative-name",
          attrs: {
            prop: "alternativeName",
            label: _vm.$t("divisionInfo.form.alternativeName.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "alternativeName", type: "text", maxlength: "24" },
            model: {
              value: _vm.divisionForm.alternativeName,
              callback: function($$v) {
                _vm.$set(_vm.divisionForm, "alternativeName", $$v)
              },
              expression: "divisionForm.alternativeName"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "division-form-big-division-name",
          attrs: {
            prop: "bigDivisionName",
            label: _vm.$t("divisionInfo.form.bigDivisionName.label")
          }
        },
        [
          _c(
            "vel-select",
            {
              attrs: { value: "divisionForm.bigDivisionName" },
              model: {
                value: _vm.divisionForm.bigDivisionId,
                callback: function($$v) {
                  _vm.$set(_vm.divisionForm, "bigDivisionId", $$v)
                },
                expression: "divisionForm.bigDivisionId"
              }
            },
            _vm._l(_vm.availableBigDivisions, function(option, i) {
              return _c("vel-option", {
                key: i,
                attrs: { label: option.name, value: option.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "division-form-is-active",
          attrs: {
            prop: "isActive",
            label: _vm.$t("divisionInfo.form.isActive.label")
          }
        },
        [
          _c("vel-switch", {
            model: {
              value: _vm.divisionForm.isActive,
              callback: function($$v) {
                _vm.$set(_vm.divisionForm, "isActive", $$v)
              },
              expression: "divisionForm.isActive"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        { staticClass: "division-form__actions" },
        [
          _c(
            "vel-button",
            {
              staticClass: "actions__submit",
              attrs: { type: "primary", loading: _vm.pending },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
          ),
          _c(
            "vel-button",
            {
              staticClass: "actions__cancel",
              attrs: { type: "secondary", loading: _vm.pending },
              on: {
                click: function($event) {
                  return _vm.handleCancel()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }