var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page",
      attrs: {
        enableToolbar: false,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        mode: "edit",
        loading: _vm.loading
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("combo-footer", {
                attrs: {
                  combo: _vm.itemProfile,
                  pending: _vm.pending,
                  canSave: _vm.canSave
                },
                on: {
                  onSave: function($event) {
                    return _vm.updateItemProfile()
                  },
                  onCancel: function($event) {
                    return _vm.handleCancel(false)
                  }
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "header",
          fn: function() {
            return [
              _c("combo-header", {
                attrs: { combo: _vm.itemProfile },
                on: {
                  "update:combo": function($event) {
                    _vm.itemProfile = $event
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("combo.edit.configuration")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column flex-md-row mb-md-2" },
          [
            _c(
              "b-form-group",
              {
                staticClass:
                  "mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0",
                attrs: {
                  label: _vm.$t("combo.edit.fields.description.label"),
                  description: _vm.$t("combo.required"),
                  "label-for": "name"
                }
              },
              [
                _c("b-form-input", {
                  class: {
                    required:
                      !_vm.itemProfile.name || _vm.itemProfile.name === ""
                  },
                  attrs: {
                    id: "name",
                    state: !_vm.submited
                      ? null
                      : _vm.itemProfile.name !== "" && null,
                    maxlength: "50",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.itemProfile.name,
                    callback: function($$v) {
                      _vm.$set(_vm.itemProfile, "name", $$v)
                    },
                    expression: "itemProfile.name"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("combo.edit.items")))
      ]),
      _c(
        "b-container",
        { staticClass: "content p-4", attrs: { fluid: "" } },
        [
          _c(
            "b-form-group",
            {
              staticClass: "b-md-0 col-12 col-md-6 col-lg-2 p-0 mb-5",
              attrs: {
                label: _vm.$t(
                  "combo.edit.fields.allowToBeExchangedIntoItemId.label"
                ),
                "label-for": "name"
              }
            },
            [
              _c("b-form-select", {
                staticClass: "form-text",
                attrs: {
                  options: [
                    { id: null, name: _vm.$t("combo.emptyValue") }
                  ].concat(_vm.items),
                  id: "allowToBeExchangedIntoItemId",
                  "value-field": "itemId",
                  "text-field": "name",
                  "aria-describedby": "input-live-help input-live-feedback"
                },
                model: {
                  value: _vm.itemProfile.allowToBeExchangedIntoItemId,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.itemProfile,
                      "allowToBeExchangedIntoItemId",
                      $$v
                    )
                  },
                  expression: "itemProfile.allowToBeExchangedIntoItemId"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            [
              _c("span", { staticClass: "pr-4" }, [
                _vm._v(_vm._s(_vm.$t("combo.edit.fields.confirmationPrompt")))
              ]),
              _c("b-form-checkbox", {
                staticClass: "d-inline-block",
                attrs: { name: "check-button", switch: "" },
                model: {
                  value: _vm.itemProfile.confirmationPrompt,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "confirmationPrompt", $$v)
                  },
                  expression: "itemProfile.confirmationPrompt"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { id: "taxesOnInvoiceType" } },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "mt-4",
                  attrs: { name: "keepOriginalItem" },
                  model: {
                    value: _vm.itemProfile.keepOriginalItem,
                    callback: function($$v) {
                      _vm.$set(_vm.itemProfile, "keepOriginalItem", $$v)
                    },
                    expression: "itemProfile.keepOriginalItem"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("combo.edit.fields.keepOriginalItem")) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "max-width": "800px",
                overflow: "auto"
              }
            },
            [
              _c(
                "b-row",
                {
                  staticClass: "px-0 py-1 mt-4 mx-0",
                  staticStyle: { "min-width": "800px" }
                },
                [
                  _c("b-col", { staticClass: "pr-0", attrs: { cols: "8" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "p-1",
                        staticStyle: { "font-size": "0.8rem" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("combo.edit.fields.item")) + " "
                        )
                      ]
                    )
                  ]),
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "p-1",
                        staticStyle: { "font-size": "0.8rem" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("combo.edit.fields.quantity")) +
                            " "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "items", staticStyle: { "min-width": "800px" } },
                _vm._l(_vm.itemProfile.comboIncludedItems, function(
                  combo,
                  index
                ) {
                  return _c(
                    "b-row",
                    { key: index, staticClass: "px-0 py-0 mx-0" },
                    [
                      !combo.delete
                        ? [
                            _c(
                              "b-col",
                              {
                                staticClass: "px-2 py-1 itemId",
                                attrs: { cols: "8" }
                              },
                              [
                                _c("b-form-select", {
                                  staticClass: "form-text",
                                  attrs: {
                                    options: [
                                      {
                                        id: null,
                                        name: _vm.$t("combo.selectItem")
                                      }
                                    ].concat(_vm.items),
                                    id: "allowToBeExchangedIntoItemId",
                                    "value-field": "itemId",
                                    "text-field": "name",
                                    "aria-describedby":
                                      "input-live-help input-live-feedback"
                                  },
                                  model: {
                                    value:
                                      _vm.itemProfile.comboIncludedItems[index]
                                        .itemId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.itemProfile.comboIncludedItems[
                                          index
                                        ],
                                        "itemId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "itemProfile.comboIncludedItems[index].itemId"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "px-2 py-1 qty",
                                attrs: { cols: "2" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "name",
                                    placeholder: _vm.$t(
                                      "mealPlan.eligibility.amount.placeholder"
                                    ),
                                    number: "",
                                    formatter: _vm.formatAmount,
                                    "lazy-formatter": ""
                                  },
                                  model: {
                                    value:
                                      _vm.itemProfile.comboIncludedItems[index]
                                        .quantity,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.itemProfile.comboIncludedItems[
                                          index
                                        ],
                                        "quantity",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "itemProfile.comboIncludedItems[index].quantity"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "pl-4 d-flex align-items-center",
                                attrs: { cols: "2" }
                              },
                              [
                                _vm.itemProfile.comboIncludedItems.filter(
                                  function(x) {
                                    return !x.delete
                                  }
                                ).length > 1 ||
                                _vm.itemProfile.comboIncludedItems.filter(
                                  function(x) {
                                    return !x.delete
                                  }
                                )[0].itemId
                                  ? _c("b-icon", {
                                      staticClass: "rounded mr-2",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        icon: "x-circle",
                                        "font-scale": "1.5",
                                        variant: "danger"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeComboItem(index)
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _c("b-icon", {
                                  staticClass: "rounded",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    icon: "plus-circle",
                                    "font-scale": "1.5",
                                    variant: "primary"
                                  },
                                  on: { click: _vm.addComboItem }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("discardChange.title"),
            static: true,
            centered: true,
            "hide-header-close": true,
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          on: {
            hidden: function($event) {
              return _vm.$emit("onCancel", true)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c("b-container", { attrs: { fluid: "" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.continue")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right ml-2",
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                                _vm.handleCancel(true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.discard")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showDiscardChanges,
            callback: function($$v) {
              _vm.showDiscardChanges = $$v
            },
            expression: "showDiscardChanges"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("discardChange.body")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }