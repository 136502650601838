var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "export-item-container" }, [
    _c(
      "div",
      {
        staticClass: "export-item",
        class: { selected: _vm.subMenuOpen },
        on: { click: _vm.itemClicked }
      },
      [
        _vm.icon && _vm.icon.length
          ? _c("vel-icon", { attrs: { name: _vm.icon, size: _vm.iconSize } })
          : _vm._e(),
        _c("div", { staticClass: "export-item-title" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
          _vm.description && _vm.description.length
            ? _c("span", [_vm._v(_vm._s(_vm.$t(_vm.description)))])
            : _vm._e()
        ]),
        _vm.subMenu.length
          ? _c("div", { staticClass: "item-arrow" }, [_c("RightArrow")], 1)
          : _vm._e()
      ],
      1
    ),
    _vm.subMenuOpen
      ? _c("div", { staticClass: "submenu" }, [
          _c(
            "ul",
            _vm._l(_vm.subMenu, function(item, idx) {
              return _c(
                "li",
                { key: idx },
                [
                  _c("vel-export-item", {
                    attrs: {
                      "icon-size": "medium",
                      title: item.title,
                      description: item.description,
                      icon: item.icon
                    },
                    on: {
                      itemSelected: function($event) {
                        return _vm.subMenuItemClicked(item.id)
                      }
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }