var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "user",
      scopedSlots: _vm._u(
        [
          _vm.user
            ? {
                key: "title",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.user.fullName) + " ")]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.isUserAdmin && _vm.user ? _c("user-edit-container") : _vm._e(),
      _c("vel-entity-information-card", {
        attrs: {
          isLoading: _vm.isLoadingUser,
          entity: _vm.userData.entity,
          properties: _vm.userData.properties,
          avatar: "fullName",
          translationPrefix: "user.info"
        }
      }),
      _c(
        "vel-card",
        {
          staticClass: "user__permissions",
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c("div", { staticClass: "user__permissions-title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("user.permissions.title")) + " "
                    ),
                    _c(
                      "div",
                      { staticClass: "user__permissions__list-length" },
                      [_vm._v(" " + _vm._s(_vm.permissionsTotalCount) + " ")]
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.isUserForbiddenToChangePermissions
            ? _c(
                "vel-alert",
                {
                  staticClass: "permissions__forbidden-changes",
                  attrs: { level: "error", dismissable: false }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("user.permissions.errors.forbiddenChange")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          !_vm.isLoadingPermissions
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "permissions__actions" },
                    [
                      _c(
                        "vel-button",
                        {
                          staticClass: "actions__add",
                          attrs: {
                            disabled: _vm.areActionsDisabled,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handlePermissionAdd()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("user.permissions.actions.add")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.hasPermissions
                    ? _c(
                        "div",
                        [
                          _vm.hasGlobalPermissions
                            ? _c("permissions-matrix", {
                                staticClass: "permissions__global",
                                attrs: {
                                  disableActions: _vm.areActionsDisabled,
                                  permissionTitle:
                                    _vm.$t("user.permissions.global") +
                                    " " +
                                    (this.multiplePermissions
                                      ? "(" +
                                        this.globalPermissions.length +
                                        ")"
                                      : ""),
                                  permissions: _vm.globalPermissions,
                                  multiplePermissions: _vm.multiplePermissions
                                },
                                on: {
                                  edit: _vm.handlePermissionEdit,
                                  delete: _vm.handlePermissionDelete
                                }
                              })
                            : _vm._e(),
                          _vm.hasGroupsPermissions
                            ? _c("permissions-matrix", {
                                staticClass: "permissions__groups",
                                attrs: {
                                  disableActions: _vm.areActionsDisabled,
                                  multiplePermissions: _vm.multiplePermissions,
                                  permissions: _vm.groupsPermissions,
                                  showGroup: true
                                },
                                on: {
                                  edit: _vm.handlePermissionEdit,
                                  delete: _vm.handlePermissionDelete
                                }
                              })
                            : _vm._e(),
                          _vm.hasLocationsPermissions
                            ? _c("permissions-matrix", {
                                staticClass: "permissions__locations",
                                attrs: {
                                  disableActions: _vm.areActionsDisabled,
                                  multiplePermissions: _vm.multiplePermissions,
                                  permissions: _vm.locationsPermissions,
                                  showLocation: true
                                },
                                on: {
                                  edit: _vm.handlePermissionEdit,
                                  delete: _vm.handlePermissionDelete
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "vel-alert",
                        {
                          staticClass: "permissions__none",
                          attrs: { dismissable: false, level: "notice" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("user.permissions.none")) + " "
                          )
                        ]
                      ),
                  _c(
                    "div",
                    { staticClass: "permission__modals" },
                    [
                      _vm.showAddPermission
                        ? _c("add-permission-modal", {
                            staticClass: "modals__add",
                            attrs: {
                              locations: _vm.locationsForAddingPermission,
                              groups: _vm.groupsForAddingPermission
                            },
                            on: {
                              dismiss: _vm.handleAddModalDismiss,
                              success: _vm.handleAddModalSuccess
                            }
                          })
                        : _vm._e(),
                      _vm.editedPermission
                        ? _c("edit-permission-modal", {
                            staticClass: "modals__edit",
                            attrs: {
                              locations: _vm.locationsForEditingPermission,
                              groups: _vm.groupsForEditingPermission,
                              permission: _vm.editedPermission
                            },
                            on: {
                              dismiss: _vm.handleEditModalDismiss,
                              success: _vm.handleEditModalSuccess
                            }
                          })
                        : _vm._e(),
                      _vm.deletedPermission
                        ? _c("delete-permission-modal", {
                            staticClass: "modals__delete",
                            attrs: { permission: _vm.deletedPermission },
                            on: {
                              dismiss: _vm.handleDeleteModalDismiss,
                              success: _vm.handleDeleteModalSuccess
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _c("vel-spinner")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }