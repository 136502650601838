<template>
  <div class="login-content">
    <div class="login-content__wrapper">
      <login-form />
      <div class="login-footer">
        <vel-button type="link" @click="toggleLanguage">
          <span>
            <vel-icon name="globe-solid" size="small" />
            {{ $t('login.switchLanguage') }}
          </span>
        </vel-button>
        <h6>{{ $t('rightsReserved', { currentYear }) }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageMixin from '@/mixins/language-mixin';
import LoginForm from './LoginForm.vue';
import VelButton from '@/components/button/VelButton';
import VelIcon from '@/components/icon/VelIcon';
import {mapActions as mapPiniaActions} from "pinia/dist/pinia";
import {useDataAccount} from "@/stores/data/account.module";

export default {
  name: 'login-page',
  mixins: [LanguageMixin],
  components: {
    'login-form': LoginForm,
    VelButton,
    VelIcon
  },
  mounted() {
    //this.logout();
  },
  methods: {
    ...mapPiniaActions(useDataAccount, ['logout'])
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.login-content {
  height: 100%;

  @media screen and (max-width: map-get($breakpoints, 'large')) {
    overflow-y: scroll;
  }

  @include breakpoint(large) {
    background-attachment: fixed;
    background-image: url('./login-backgroung.jpg');
    background-position: right 0% bottom 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100vw;
  }

  .login-content__wrapper {
    align-items: center;
    background-color: $wild-sand;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: rem-calc(100);
    @include breakpoint(large) {
      opacity: 0.96;
      width: 40%;
    }

    @include breakpoint(xsmall) {
      padding: rem-calc(10);
    }

    h6 {
      padding-top: rem-calc(100);
      @include breakpoint(xsmall) {
        margin: 0;
        padding-top: 0;
      }
    }

    .login-footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .vel-button {
      font-size: rem-calc(13);
      height: rem-calc(25);
      width: 100%;
      @include noPrint;

      &:hover {
        background-color: $wild-sand;
      }

      /* stylelint-disable */
      .vel-icon_small {
        height: rem-calc(10);
        width: rem-calc(10);
      }
      /* stylelint-enable */
    }

    @mixin breakpoint {
      justify-content: flex-start;
    }

    @media screen and (max-width: map-get($breakpoints, 'medium')) {
      @include breakpoint;
    }

    @media (min-width: map-get($breakpoints, 'medium')) and (max-width: map-get($breakpoints, 'large')) {
      @include breakpoint;
    }
  }
}
</style>
