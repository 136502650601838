var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "d-flex flex-row is-justify-space-between" },
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-flex flex-column flex-md-row align-content-between justify-content-between justify-content-md-start mb-md-0",
              attrs: { cols: "6", sm: "4" }
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-4 mt-md-0 d-md-block",
                  attrs: {
                    disabled: !_vm.canSave || _vm.pending,
                    variant: !_vm.canSave ? "outline-primary" : "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("onSave")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.combo.id
                          ? _vm.$t("combo.edit.btns.edit")
                          : _vm.$t("combo.edit.btns.save")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "align-self-end text-right",
              attrs: { cols: "6", sm: "8" }
            },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: _vm.pending,
                    variant: "outline-secondary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("onCancel")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("combo.edit.btns.cancel")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }