import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { createCollectionState, getSelectors, removeAll, setAll, setOne } from '@/helpers/stores.helpers';
import router from '@/router';
import divisionsService from '@/services/divisions.service';
import {useDataBigDivisions} from "@/stores/data/big-divisions.module";

const { selectAllFiltered, selectTotal, selectSelected } = getSelectors();

export const useDataDivisions = defineStore('divisions', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null,
      filter: ''
    }),
  getters: {
    divisions: selectAllFiltered(['name', 'remoteId', 'bigDivisionName']),
    selectTotal,
    selectSelected
  },
  actions: {
    async getDivisions() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getDivisionsRequest();
        const divisions = await divisionsService.getDivisions(getToken(), routeQuery);
        this.getDivisionsSuccess(divisions);
      } catch (error) {
        this.getDivisionsFailure(error);
      }
    },
    async getDivisionById() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getDivisionByIdRequest();
        const bigDivisionsStore = useDataBigDivisions();
        await bigDivisionsStore.getBigDivisions(null);
        const division = await divisionsService.getDivision(getToken(), routeQuery);
        this.getDivisionByIdSuccess(division);
        this.setSelected(division.id);
      } catch (error) {
        this.getDivisionByIdFailure(error);
      }
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    setSelected(value) {
      this.setSelectedValue(value);
    },
    getDivisionsRequest() {
      this.loading = true;
      this.error = null;
    },
    getDivisionsSuccess(divisions) {
      this.data = setAll(
        divisions.map(s => ({
          ...s,
          remoteId: s.remoteId + 1,
          bigDivisionName: s.bigDivision.name,
          bigDivisionId: s.bigDivision.id
        })),
        this.data
      );
      this.error = null;
      this.loading = false;
    },
    getDivisionsFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    getDivisionByIdRequest() {
      this.loading = true;
      this.error = null;
    },
    getDivisionByIdSuccess(division) {
      this.data = removeAll(this.data);
      this.data = setOne({ ...division, remoteId: division.remoteId + 1 }, this.data);
      this.error = null;
      this.loading = false;
    },
    getDivisionByIdFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    setSelectedValue(value) {
      this.data.selected = value;
    }
  }
});
