var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ol", { staticClass: "sales-breadcrumb" }, [
    _c(
      "li",
      { staticClass: "sales-breadcrumb__crumb sales-breadcrumb__crumb_root" },
      [_vm._v(" " + _vm._s(_vm.$t("sales.title")) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }