<template>
  <vel-page class="big-divisions-page" :loading="loading">
    <template v-slot:title>
      {{ $t('bigDivisionsPage.title') }}
    </template>
    <div class="big-divisions-page-location-selector-wrapper">
      <vel-location-selector
        class="big-divisions-page-location-selector"
        :allLocationsLabel="$t('dashboard.locationSelector.allLocationsText')"
        :locationId="locationId"
        :locationsOptions="locations"
        :disabled="getLocationsIsLoading"
        @change="onLocationChange"
      />
    </div>
    <vel-card>
      <big-divisions-list
        :data="bigDivisions"
        :initialLength="selectTotal"
        :filterValue="filter"
        :filterCallback="filterCallback"
      />
    </vel-card>
  </vel-page>
</template>

<script>
import BigDivisionsList from './components/BigDivisionsList';
import VelCard from '@/components/card/VelCard';
import VelLocationSelector from '@/components/location-selector/VelLocationSelector';
import VelPage from '@/components/page/VelPage';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataBigDivisions} from "@/stores/data/big-divisions.module";

export default {
  name: 'big-divisions-page',
  components: { BigDivisionsList, VelPage, VelCard, VelLocationSelector },
  methods: {
    ...mapPiniaActions(useDataBigDivisions, ['getBigDivisions', 'setFilter']),
    ...mapPiniaActions(useUILocationSelector, ['onChange']),
    onLocationChange(locationId) {
      this.onChange( { locationId });
    },
    filterCallback(value) {
      this.setFilter(value);
    }
  },
  computed: {
    ...mapPiniaState(useDataBigDivisions, ['loading']),
    ...mapPiniaState(useDataLocationsStore, ['locations', 'getLocationsIsLoading']),
    ...mapPiniaState(useDataBigDivisions, ['bigDivisions', 'selectTotal']),
    ...mapPiniaState(useUILocationSelector, {
      locationId: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useDataBigDivisions, ['filter'])
  },
  async mounted() {
    await this.getBigDivisions();
  },
  beforeDestroy() {
    this.setFilter('');
  }
};
</script>

<style lang="scss">
@import 'mixins.scss';

.big-divisions-page {
  &-location-selector-wrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }

  &-location-selector {
    margin-bottom: rem-calc(20);
    width: rem-calc(300);
  }
}
</style>
