var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config_dashboard_display" },
    _vm._l(_vm.options, function(opt) {
      return _c(
        "div",
        { key: opt.text },
        [
          _c("vel-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(opt.title))
          ]),
          _vm._l(opt.elements, function(el) {
            return _c(
              "div",
              { key: el.text },
              [
                _vm.isAllowed(el.model)
                  ? _c("vel-checkbox", {
                      attrs: { label: el.text },
                      on: { change: _vm.update },
                      model: {
                        value: _vm.config[el.model],
                        callback: function($$v) {
                          _vm.$set(_vm.config, el.model, $$v)
                        },
                        expression: "config[el.model]"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }