import { defineStore } from 'pinia';
import router from '@/router';
import { parameterizeDate, today } from '@/helpers/date.helpers';
import { DateTime } from 'luxon';

export const useUIDateRangeSelector = defineStore('dateRangeSelector', {
  state: () => ({
    range: [today().toString(), today().toString()],
    error: null
  }),
  getters: {
    getDateSelectorSelected: state => [
      DateTime.fromISO(state.range[0]).toJSDate(),
      parameterizeDate(DateTime.fromISO(state.range[1]).toJSDate(), true)
    ],
    getDateSelectorSelectedRange: state => [
      DateTime.fromISO(state.range[0]).toString(),
      DateTime.fromISO(state.range[1]).toString()
    ]
  },
  actions: {
    async onChange(query: any = {}) {
      if (query && query.range) {
        this.updateSelectorDate({
          from: query.range[0],
          to: query.range[1]
        });
      }
      console.log('AAAAAAAAAAAAAAAAAA', this.range );
      if (!(query && query.disableRouteChange)) {
        try {
          await router.replace({
            name: router.currentRoute.name,
            query: {
              ...router.currentRoute.query,
              date: query.range[0],
              from: query.range[0],
              to: query.range[1]
            }
          });
        } catch (error) {
          console.error(error);
          // @todo
        }
      }
    },
    updateSelectorDate(payload) {
      this.range = [payload.from || today().toString(), payload.to || today().toString()];
    },
    updateDate(date) {
      this.range = date;
    },
    updateSelectorDateSuccess() {
      this.error = null;
    },
    updateSelectorDateFailure(error) {
      this.error = error;
    }
  }
});
