var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vel-page-spinner" }, [
    _c(
      "div",
      { staticClass: "vel-page-spinner__inner" },
      [
        _c("div", { staticClass: "vel-page-spinner__line" }),
        _c("div", { staticClass: "vel-page-spinner__line" }),
        _c("div", { staticClass: "vel-page-spinner__line" }),
        _c("logo", { staticClass: "vel-page-spinner__logo" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }