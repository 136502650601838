var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "sales-per-hour-report",
      attrs: {
        title: _vm.$t("pages.reports.salesPerHourReport"),
        "multiple-locations": _vm.locationsList && _vm.locationsList.length > 1,
        "enable-all-currencies": true,
        "enable-date-range": true,
        "show-selector-button": true,
        "enable-currency": true,
        "show-crumbs": false,
        "enable-toolbar": "",
        generatedTime: _vm.generatedTime
      },
      scopedSlots: _vm._u([
        {
          key: "export",
          fn: function() {
            return [
              _vm.getLocationsSalesHourly.length
                ? _c(
                    "vel-button",
                    {
                      staticClass: "vel-button",
                      attrs: {
                        loading: _vm.generating,
                        type: "primary",
                        icon: _vm.isMediumUp ? "file-excel" : undefined
                      },
                      on: { click: _vm.exportXLS }
                    },
                    [
                      _vm.isMediumDown
                        ? _c("vel-icon", { attrs: { name: "file-download" } })
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isMediumUp
                              ? _vm.$t("table.tableExport.button.value")
                              : ""
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.loading ? _c("vel-spinner", { staticClass: "spinner" }) : _vm._e(),
      !_vm.getLocationsSalesHourly.length && !_vm.loading
        ? _c("vel-card", [
            _c("div", [_vm._v(_vm._s(_vm.$t("defaults.empty")))])
          ])
        : _vm._e(),
      _vm._l(
        _vm.getLocationsSalesHourly.sort(function(a, b) {
          return (
            a.location.reportingDisplayName || a.location.name
          ).localeCompare(b.location.reportingDisplayName || b.location.name)
        }),
        function(location, i) {
          return _c(
            "vel-card",
            {
              key: i,
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                location.location.reportingDisplayName ||
                                  location.location.name
                              ) +
                              " "
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              _vm.getLocationsSalesHourly.length
                ? _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _vm._l(location.hourlySales, function(date, day) {
                        return _c(
                          "div",
                          {
                            key: day,
                            staticClass: "sales-per-hour-table-wrapper"
                          },
                          [
                            _c(
                              "table",
                              {
                                class:
                                  day === 0
                                    ? "sales-per-hour-table-date"
                                    : "sales-per-hour-table-date-range-selected"
                              },
                              [
                                day === 0
                                  ? _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "salesPerHourReport.table.headers.date"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("th", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "salesPerHourReport.table.headers.day"
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.getDate(location, day)))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.getDay(location, day)))
                                  ])
                                ])
                              ]
                            ),
                            _c(
                              "table",
                              {
                                class:
                                  day === 0
                                    ? "sales-per-hour-table-types"
                                    : "sales-per-hour-table-types-range-selected"
                              },
                              [
                                day === 0
                                  ? _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "salesPerHourReport.table.headers.type"
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "salesPerHourReport.table.headers.sales"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "salesPerHourReport.table.headers.checkQty"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "salesPerHourReport.table.headers.avgPerCheck"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _c(
                              "table",
                              {
                                class:
                                  day === 0
                                    ? "sales-per-hour-table-data"
                                    : "sales-per-hour-table-data-range-selected"
                              },
                              [
                                _c("thead", [
                                  day === 0
                                    ? _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "sales-per-hour-table-data-header",
                                            attrs: { colspan: "24" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "sales-per-hour-table-data-header-text"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "salesPerHourReport.table.headers.businessHours"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              _vm._l(24, function(_, h) {
                                                return _c(
                                                  "td",
                                                  { key: h },
                                                  [
                                                    h === 0
                                                      ? [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getTimeFormatByHour(
                                                                  location
                                                                    .location
                                                                    .dayChangeTime /
                                                                    3600
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      : location.dayChangeTime /
                                                          3600 +
                                                          h <
                                                        10
                                                      ? [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getTimeFormatByHour(
                                                                  location
                                                                    .location
                                                                    .dayChangeTime /
                                                                    3600 +
                                                                    h
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      : location.location
                                                          .dayChangeTime /
                                                          3600 +
                                                          h <
                                                        24
                                                      ? [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getTimeFormatByHour(
                                                                  location
                                                                    .location
                                                                    .dayChangeTime /
                                                                    3600 +
                                                                    h
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      : [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getTimeFormatByHour(
                                                                  location
                                                                    .location
                                                                    .dayChangeTime /
                                                                    3600 +
                                                                    h -
                                                                    24
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                  ],
                                                  2
                                                )
                                              }),
                                              0
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    _vm._l(
                                      _vm.getGrossSales(location, day),
                                      function(sale, i) {
                                        return _c(
                                          "td",
                                          { key: i },
                                          [
                                            _c("vel-amount", {
                                              attrs: {
                                                currency:
                                                  location.location
                                                    .detailedCurrency
                                                    .currencySymbol,
                                                amount: sale
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "tr",
                                    _vm._l(
                                      _vm.getOrdersCount(location, day),
                                      function(order, i) {
                                        return _c("td", { key: i }, [
                                          _vm._v(_vm._s(order))
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "tr",
                                    _vm._l(
                                      _vm.getAveragePerChecks(location, day),
                                      function(avg, i) {
                                        return _c(
                                          "td",
                                          { key: i, attrs: { width: "200" } },
                                          [
                                            _c("vel-amount", {
                                              attrs: {
                                                currency:
                                                  location.location
                                                    .detailedCurrency
                                                    .currencySymbol,
                                                amount: avg
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "table",
                              {
                                class:
                                  day === 0
                                    ? "sales-per-hour-table-end"
                                    : "sales-per-hour-table-end-range-selected"
                              },
                              [
                                day === 0
                                  ? _c("tr", [_c("th", [_vm._v("Total")])])
                                  : _vm._e(),
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            location.location.detailedCurrency
                                              .currencySymbol,
                                          amount: _vm.getGrossSalesTotal(
                                            location,
                                            day
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getOrdersCountTotal(location, day)
                                      )
                                    )
                                  ])
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            location.location.detailedCurrency
                                              .currencySymbol,
                                          amount: _vm.getAveragePerChecksTotal(
                                            location,
                                            day
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      _vm.showTotal
                        ? _c(
                            "div",
                            { staticClass: "sales-per-hour-total-wrapper" },
                            [
                              _c(
                                "table",
                                { staticClass: "sales-per-hour-total-date" },
                                [_c("tr", [_c("th", [_vm._v("Total")])])]
                              ),
                              _c(
                                "table",
                                { staticClass: "sales-per-hour-total-types" },
                                [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "salesPerHourReport.table.headers.sales"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "salesPerHourReport.table.headers.checkQty"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "salesPerHourReport.table.headers.avgPerCheck"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "table",
                                { staticClass: "sales-per-hour-total-data" },
                                [
                                  _c("tbody", [
                                    _c(
                                      "tr",
                                      _vm._l(
                                        _vm.grossSalesHourly(location),
                                        function(sale, i) {
                                          return _c(
                                            "td",
                                            { key: i },
                                            [
                                              _c("vel-amount", {
                                                attrs: {
                                                  currency:
                                                    location.location
                                                      .detailedCurrency
                                                      .currencySymbol,
                                                  amount: sale
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "tr",
                                      _vm._l(
                                        _vm.ordersCountHourly(location),
                                        function(order, i) {
                                          return _c("td", { key: i }, [
                                            _vm._v(_vm._s(order))
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "tr",
                                      _vm._l(
                                        _vm.averagePerChecksHourly(location),
                                        function(avg, i) {
                                          return _c(
                                            "td",
                                            { key: i },
                                            [
                                              _c("vel-amount", {
                                                attrs: {
                                                  currency:
                                                    location.location
                                                      .detailedCurrency
                                                      .currencySymbol,
                                                  amount: avg
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "table",
                                { staticClass: "sales-per-hour-total-end" },
                                [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            currency:
                                              location.location.detailedCurrency
                                                .currencySymbol,
                                            amount: _vm.totalGrossSales
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.totalOrdersCount))
                                    ])
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            currency:
                                              location.location.detailedCurrency
                                                .currencySymbol,
                                            amount: _vm.totalAveragePerCheck
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ]
          )
        }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }