var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      ref: "sidebarAccessType",
      attrs: {
        "header-class": "bg-light",
        "bg-variant": "white",
        width: "600px",
        right: "",
        id: "accessTypes-sidebar",
        title: "Sidebar",
        shadow: "",
        backdrop: ""
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                {
                  staticClass:
                    "bg-gray-200 bg-light w-100 d-flex flex-row align-items-center justify-content-center position-relative"
                },
                [
                  _c("h5", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("jobRoles.list.title")))
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "position-absolute",
                      staticStyle: { left: "0" },
                      attrs: { size: "sm", variant: "link" },
                      on: { click: hide }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-left",
                          "aria-hidden": "true"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("accessType.back")) + " ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        },
        {
          key: "footer",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex bg-light text-dark align-items-center px-3 py-2",
                  staticStyle: { "border-top": "2px solid #d6d6d8" }
                },
                [
                  _c(
                    "b-button",
                    { attrs: { variant: "primary" }, on: { click: hide } },
                    [_vm._v(_vm._s(_vm.$t("accessType.close")))]
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "b-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pending,
              expression: "pending"
            }
          ],
          staticClass: "p-4",
          attrs: { fluid: "" }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-end align-content-end" },
            [
              _c(
                "b-input-group",
                {
                  staticClass: "align-content-end align-self-end mr-4",
                  staticStyle: { "max-width": "180px" }
                },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c(
                        "b-input-group-text",
                        [_c("b-icon", { attrs: { icon: "search" } })],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    attrs: {
                      debounce: "1",
                      type: "text",
                      placeholder: _vm.$t("employee.list.searchPlaceholder")
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.accessTypeForm-sidebar",
                      modifiers: { "accessTypeForm-sidebar": true }
                    }
                  ],
                  attrs: { variant: "primary" }
                },
                [
                  _c("b-icon", { attrs: { icon: "plus" } }),
                  _vm._v(" " + _vm._s(_vm.$t("accessType.add.btn")) + " ")
                ],
                1
              )
            ],
            1
          ),
          _c("access-type-list", {
            attrs: { "access-types": _vm.accessTypes, filter: _vm.filter },
            on: {
              onModify: function($event) {
                return _vm.openEditAccessType($event)
              },
              onDelete: function($event) {
                return _vm.$emit("onDelete", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }