import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import workstationsService from '@/services/sales.service';

export const useDataSalesWorkstations = defineStore('salesWorkstations', {
  state: () => ({
    workstationSales: null,
    pending: true,
    success: null,
    error: null
  }),
  getters: {
    isPending: state => state.pending,
    getWorkstationSales: state => state.workstationSales,
    getWorkstationSalesByCurrency(state) {
      return currencyId => (state.workstationSales || []);
    },
    isError: state => !!state.error,
    getError: state => state.error,
    isSuccess: state => state.success,
    getWorkstationSalesSeries(state) {
      return currencyId =>
        this.getWorkstationSalesByCurrency(currencyId)
          .map(zs => zs.salesAmount)
          .sort((a, b) => b - a);
    },
    getSeries(state) {
      return currencyId =>
        this.getWorkstationSalesByCurrency(currencyId)
          .sort((a, b) => b.salesAmount - a.salesAmount)
          .map(zs => zs.salesAmount);
    },
    getLabels(state) {
      return currencyId =>
        (state.workstationSales || [])
          .sort((a, b) => b.salesAmount - a.salesAmount)
          .map(zs => (zs.name !== '' && zs.name) || `#${zs.remoteId}`);
    },
    getTotal(state) {
      return currencyId => this.getWorkstationSalesByCurrency(currencyId).length;
    }
  },
  actions: {
    async getSalesByWorkstation(payload) {
      const toDay = today().startOf('day').toString();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;

      const routeQuery = {
        ...router.currentRoute.query,
        from,
        to,
        ...payload
      };

      if (!requestAllocated(routeQuery)) {
        this.getWorkstationSalesRequest();

        try {
          const workstationSales = await workstationsService.getWorkstationsSales(getToken(), routeQuery);
          this.getWorkstationSalesSuccess({ workstationSales });
          deallocateRequest(routeQuery);
        } catch (error) {
          this.getWorkstationSalesFailure(error);
        }
      }
    },
    getWorkstationSalesRequest() {
      this.pending = true;
      this.success = null;
      this.workstationSales = null;
      this.error = null;
    },
    getWorkstationSalesSuccess({ workstationSales }) {
      this.pending = false;
      this.success = true;
      this.workstationSales = workstationSales || [];
      this.error = null;
    },
    getWorkstationSalesFailure(error) {
      this.pending = false;
      this.success = false;
      this.workstationSales = null;
      this.error = error;
    }
  }
});
