var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-card",
    { staticClass: "create-user" },
    [
      [
        _vm.error
          ? _c(
              "vel-alert",
              {
                staticClass: "alert create-user__error",
                attrs: { level: "error", autoDismissTime: 0 },
                on: { dismiss: _vm.init }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t((_vm.error && _vm.error.text) || _vm.error)) +
                    " "
                )
              ]
            )
          : _vm._e(),
        _c(
          "vel-form",
          {
            ref: "userForm",
            staticClass: "user-form",
            attrs: {
              model: _vm.userForm,
              rules: _vm.userFormRules,
              submit: _vm.handleSubmit,
              "label-width": "150px"
            }
          },
          [
            _c(
              "vel-form-item",
              {
                staticClass: "user-form-email",
                attrs: { prop: "email", label: _vm.$t("user.form.email.label") }
              },
              [
                _c("vel-input", {
                  staticClass: "email-input",
                  attrs: { name: "email", type: "email" },
                  model: {
                    value: _vm.userForm.email,
                    callback: function($$v) {
                      _vm.$set(_vm.userForm, "email", $$v)
                    },
                    expression: "userForm.email"
                  }
                })
              ],
              1
            ),
            _c(
              "vel-form-item",
              {
                staticClass: "user-form-firstName",
                attrs: {
                  prop: "firstName",
                  label: _vm.$t("user.form.firstName.label")
                }
              },
              [
                _c("vel-input", {
                  staticClass: "firstname-input",
                  attrs: { name: "firstName", type: "text" },
                  model: {
                    value: _vm.userForm.firstName,
                    callback: function($$v) {
                      _vm.$set(_vm.userForm, "firstName", $$v)
                    },
                    expression: "userForm.firstName"
                  }
                })
              ],
              1
            ),
            _c(
              "vel-form-item",
              {
                staticClass: "user-form-lastName",
                attrs: {
                  prop: "lastName",
                  label: _vm.$t("user.form.lastName.label")
                }
              },
              [
                _c("vel-input", {
                  staticClass: "lastname-input",
                  attrs: { name: "lastName", type: "text" },
                  model: {
                    value: _vm.userForm.lastName,
                    callback: function($$v) {
                      _vm.$set(_vm.userForm, "lastName", $$v)
                    },
                    expression: "userForm.lastName"
                  }
                })
              ],
              1
            ),
            _c(
              "vel-form-item",
              {
                staticClass: "user-form-enabled",
                attrs: {
                  prop: "enabled",
                  label: _vm.$t("user.form.enabled.label")
                }
              },
              [
                _c(
                  "vel-select",
                  {
                    staticClass: "enabled-input",
                    attrs: {
                      placeholder: _vm.$t("user.form.enabled.placeholder")
                    },
                    model: {
                      value: _vm.userForm.enabled,
                      callback: function($$v) {
                        _vm.$set(_vm.userForm, "enabled", $$v)
                      },
                      expression: "userForm.enabled"
                    }
                  },
                  [
                    _c("vel-option", {
                      key: true,
                      attrs: { label: "true", value: true }
                    }),
                    _c("vel-option", {
                      key: false,
                      attrs: { label: "false", value: false }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "vel-form-item",
              { staticClass: "user-form__actions" },
              [
                _c(
                  "vel-button",
                  {
                    staticClass: "actions__submit",
                    attrs: { type: "primary", loading: _vm.pending },
                    on: {
                      click: function($event) {
                        return _vm.handleSubmit()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
                ),
                _c(
                  "vel-button",
                  {
                    staticClass: "actions__cancel",
                    attrs: { type: "secondary" },
                    on: {
                      click: function($event) {
                        return _vm.handleCancel()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("defaults.actions.cancel")) + " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }