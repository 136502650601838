var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "employee__report",
      class: { modal: _vm.showConfig },
      attrs: {
        "multiple-locations": false,
        "enable-date-range": true,
        "show-selector-button": true,
        showCrumbs: false,
        "enable-currency": false,
        "enable-toolbar": true,
        "enable-all-locations": false,
        "enable-groups": false,
        generatedTime: _vm.generatedTime
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm._v(" " + _vm._s(_vm.$t("pages.reports.employeesSales")) + " ")
            ]
          },
          proxy: true
        },
        {
          key: "toolbarMenu",
          fn: function() {
            return [
              _c(
                "vel-elink",
                {
                  attrs: { type: "info" },
                  on: {
                    click: function($event) {
                      _vm.showConfig = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("editors.customize_link")))]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.showConfig
        ? _c(
            "vel-modal",
            {
              attrs: { dismissable: "" },
              on: {
                dismiss: function($event) {
                  _vm.showConfig = false
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$t("editors.customize_link")) + " "
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1723931544
              )
            },
            [
              _c("config-employee-report-display", {
                on: {
                  update: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.areSalesAvailable && _vm.employeesSalesChartData.length > 1
        ? _c(
            "vel-card",
            { staticClass: "report__chart" },
            [
              _c("apexchart", {
                attrs: {
                  type: "bar",
                  height: 300,
                  options: _vm.chartOptions,
                  series: _vm.series
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "vel-card",
        { staticClass: "report__tree", staticStyle: { width: "100%" } },
        [
          _c("employee-sales-report", {
            staticStyle: { width: "100%" },
            attrs: {
              sales: _vm.getSalesTree,
              areSalesLoading: _vm.areSalesLoading,
              multipleLocation: false,
              hiddenColumns: _vm.hiddenColumns
            },
            on: { input: _vm.handleInput }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }