import { render, staticRenderFns } from "./EmployeeDeleteErrorModal.vue?vue&type=template&id=2c587d79&scoped=true&"
import script from "./EmployeeDeleteErrorModal.vue?vue&type=script&lang=js&"
export * from "./EmployeeDeleteErrorModal.vue?vue&type=script&lang=js&"
import style0 from "./EmployeeDeleteErrorModal.vue?vue&type=style&index=0&id=2c587d79&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c587d79",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/runner/work/velweb/velweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c587d79')) {
      api.createRecord('2c587d79', component.options)
    } else {
      api.reload('2c587d79', component.options)
    }
    module.hot.accept("./EmployeeDeleteErrorModal.vue?vue&type=template&id=2c587d79&scoped=true&", function () {
      api.rerender('2c587d79', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/private/components/employees/components/EmployeeDeleteErrorModal.vue"
export default component.exports