import { defineStore } from 'pinia';
import { useUIAlertStore } from '@/stores/ui/alert.module';
import locationsService from '@/services/locations.service';
import { getToken } from '@/helpers/token.helper';
import { createCollectionState, getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';
import { filterLocationsVersionByLocationsIds, isValidVersion } from '@/helpers/versions.module.helper';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import cloneDeep from 'lodash.clonedeep';
import router from "@/router";

const { selectAll } = getSelectors();

export const useDataLocationsVersionsStore = defineStore('versions', {
  state: () =>
    createCollectionState({
      loading: true,
      error: null
    }),
  getters: {
    versions(state) {
      return selectAll(state);
    },
    isLoading: state => state.loading,
    isVersionValid(state) {
      const dataLocationsStore = useDataLocationsStore();
      return (minimalVersion, selectedLocationId, selectedGroupId, selectedDate) => {
        let locationIds = [];

        if (typeof selectedLocationId === 'string' && selectedLocationId !== '') {
          locationIds = [selectedLocationId];
        } else if (
          typeof selectedLocationId !== 'string' &&
          selectedLocationId &&
          selectedLocationId.length &&
          selectedLocationId[0] !== ''
        ) {
          locationIds = selectedLocationId;
        } else if (selectedGroupId && selectedGroupId.length) {
          locationIds = dataLocationsStore.getLocationsByGroupId(selectedGroupId).map(group => group.id);
        } else {
          locationIds = dataLocationsStore.getLocationsAll.map(l => l.id);
        }

        const filteredData = [...cloneDeep(filterLocationsVersionByLocationsIds(this.versions, locationIds))];

        if (filteredData && filteredData.length) {
          // assume the version is valid if we have not yet received invoices
          const numLocationsWithNoVersions = filteredData.filter(fd => !fd.versions.length).length;
          if (numLocationsWithNoVersions === filteredData.length) {
            return true;
          }

          // consider that the first version received was used since 1970
          // this will avoid having an error when the user chooses a date lower than his activation date
          filteredData.forEach((x, i) => {
            const lastVersionIndex = x.versions.length - 1;

            filteredData[i].versions[lastVersionIndex].start = '1970-01-01T00:00:01Z';
          });
        }

        return isValidVersion(filteredData, minimalVersion, selectedDate);
      };
    }
  },
  actions: {
    async getVersions() {
      try {
        this.getLocationsVersionsRequest();

        const versions = await locationsService.getLocationVersions(getToken(), router.currentRoute.query);

        this.getLocationsVersionsSuccess(versions);
      } catch (error) {
        this.getLocationsVersionsFailure(error);
        const alertStore = useUIAlertStore();
        alertStore.newError(error);
      }
    },
    getLocationsVersionsRequest() {
      this.error = null;
      this.loading = true;
    },
    getLocationsVersionsSuccess(versions) {
      this.data = setAll(versions, this.data, 'locationId');
      this.error = null;
      this.loading = false;
    },
    getLocationsVersionsFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    }
  }
});
