var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    _vm._b(
      {
        staticClass: "report",
        attrs: {
          "multiple-locations": _vm.locations && _vm.locations.length > 1,
          "enable-currency": true,
          "show-selector-button": true
        },
        scopedSlots: _vm._u([
          {
            key: "export",
            fn: function() {
              return [
                Object.values(_vm.filteredData).length
                  ? _c("vel-export", {
                      attrs: {
                        disabled:
                          _vm.loading ||
                          !Object.values(_vm.filteredData).length,
                        generating: _vm.generating,
                        "enable-pdf": true,
                        "enable-xls": true
                      },
                      on: {
                        exportPDF: _vm.onExportPDF,
                        exportXLS: _vm.onExportXLS
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      },
      "vel-page",
      _vm.pageAttrs,
      false
    ),
    [
      _vm.loading ? _c("vel-spinner", { staticClass: "spinner" }) : _vm._e(),
      _vm.invalidLocations && !_vm.isVersionLoading
        ? _c(
            "div",
            {
              staticStyle: {
                "background-color": "#fff2cc",
                "margin-bottom": "1rem",
                padding: "1rem"
              }
            },
            [
              _vm.getLocationSelectorSelected
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invalidVersions.requiredVersion")) +
                        " "
                    )
                  ]
                : [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "invalidVersions.requiredVersionMultiLocations"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript://" },
                        on: {
                          click: function($event) {
                            _vm.showLocationsList = !_vm.showLocationsList
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("invalidVersions.collapse")))]
                    ),
                    _vm.showLocationsList
                      ? _c(
                          "ul",
                          { staticStyle: { "margin-top": "1rem" } },
                          _vm._l(_vm.invalidLocations, function(location) {
                            return _c("li", { key: location.id }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    location.reportingDisplayName ||
                                      location.name
                                  ) +
                                  " "
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        : _vm._e(),
      !Object.values(_vm.filteredData).length && !_vm.loading
        ? _c("div", { staticClass: "no-data" }, [
            _vm._v(" " + _vm._s(_vm.$t("itemSalesCategory.noData")) + " ")
          ])
        : _vm._e(),
      !_vm.loading
        ? _vm._l(_vm.filteredData, function(sales) {
            return _c("vel-card", { key: sales.locationId }, [
              _c("h5", [_vm._v(_vm._s(sales.location.name))]),
              sales.salesByBigDivision
                ? _c("section", { staticClass: "data_section" }, [
                    _c("h6", [
                      _vm._v(_vm._s(_vm.$t("salesAndRevenue.grossSales")))
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("salesAndRevenue.category")))
                            ]),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.$t("salesAndRevenue.subCategory"))
                              )
                            ]),
                            _c("th"),
                            _c("th", { staticClass: "align-right" }, [
                              _vm._v(_vm._s(_vm.$t("salesAndRevenue.amount")))
                            ])
                          ])
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(sales.salesByBigDivision, function(
                              salesByBigDivision,
                              salesByBigDivisionIdx
                            ) {
                              return [
                                _vm._l(salesByBigDivision.divisions, function(
                                  salesByDivision,
                                  idx
                                ) {
                                  return _c(
                                    "tr",
                                    { key: salesByBigDivisionIdx + "_" + idx },
                                    [
                                      _c("td", [
                                        idx === 0
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (salesByBigDivision.bigDivision &&
                                                      salesByBigDivision
                                                        .bigDivision.name) ||
                                                      "--"
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (salesByDivision.division &&
                                                salesByDivision.division
                                                  .name) ||
                                                "--"
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("td"),
                                      _c(
                                        "td",
                                        { staticClass: "align-right" },
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              currency:
                                                (sales.location
                                                  .detailedCurrency &&
                                                  sales.location
                                                    .detailedCurrency
                                                    .currencySymbol) ||
                                                "",
                                              amount: salesByDivision.amount
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                _c("tr", { staticClass: "summary" }, [
                                  _c("td"),
                                  _c("td"),
                                  _c("td", { staticClass: "align-right" }, [
                                    _c("div", { staticClass: "total" }, [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "salesAndRevenue.averagePerCustomer"
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              currency:
                                                (sales.location
                                                  .detailedCurrency &&
                                                  sales.location
                                                    .detailedCurrency
                                                    .currencySymbol) ||
                                                "",
                                              amount: _vm.calcAverage(
                                                sales.salesIndicator.customers,
                                                salesByBigDivision.salesAmount
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "align-right" }, [
                                    _c("div", { staticClass: "total" }, [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "salesAndRevenue.categoryTotal"
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              currency:
                                                (sales.location
                                                  .detailedCurrency &&
                                                  sales.location
                                                    .detailedCurrency
                                                    .currencySymbol) ||
                                                "",
                                              amount:
                                                salesByBigDivision.salesAmount
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                ])
                              ]
                            }),
                            sales.salesIndicator.surchargeAmount ||
                            sales.salesIndicator.gratuityAmount
                              ? [
                                  sales.salesIndicator.gratuityAmount
                                    ? _c("tr", [
                                        _c("td"),
                                        _c("td"),
                                        _c("td"),
                                        _c(
                                          "td",
                                          { staticClass: "align-right" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "subtotal" },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "salesAndRevenue.serviceCharge"
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("vel-amount", {
                                                      attrs: {
                                                        currency:
                                                          (sales.location
                                                            .detailedCurrency &&
                                                            sales.location
                                                              .detailedCurrency
                                                              .currencySymbol) ||
                                                          "",
                                                        amount:
                                                          sales.salesIndicator
                                                            .gratuityAmount
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  sales.salesIndicator.surchargeAmount
                                    ? _c("tr", [
                                        _c("td"),
                                        _c("td"),
                                        _c("td"),
                                        _c(
                                          "td",
                                          { staticClass: "align-right" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "subtotal" },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "salesAndRevenue.surcharge"
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("vel-amount", {
                                                      attrs: {
                                                        currency:
                                                          (sales.location
                                                            .detailedCurrency &&
                                                            sales.location
                                                              .detailedCurrency
                                                              .currencySymbol) ||
                                                          "",
                                                        amount:
                                                          sales.salesIndicator
                                                            .surchargeAmount
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _c("tr", { staticClass: "summary" }, [
                                    _c("td"),
                                    _c("td"),
                                    _c("td"),
                                    _c("td", { staticClass: "align-right" }, [
                                      _c("div", { staticClass: "total" }, [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("salesAndRevenue.total")
                                            )
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c("vel-amount", {
                                              attrs: {
                                                currency:
                                                  (sales.location
                                                    .detailedCurrency &&
                                                    sales.location
                                                      .detailedCurrency
                                                      .currencySymbol) ||
                                                  "",
                                                amount:
                                                  sales.totalAdditionalFees
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              : _vm._e(),
                            _c("tr", { staticClass: "align-right" }, [
                              _c("td"),
                              _c("td"),
                              _c("td"),
                              _c("td", { staticClass: "bigtotal" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("salesAndRevenue.totalGrossSales")
                                    )
                                  )
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c("vel-amount", {
                                      attrs: {
                                        currency:
                                          (sales.location.detailedCurrency &&
                                            sales.location.detailedCurrency
                                              .currencySymbol) ||
                                          "",
                                        amount:
                                          sales.salesIndicator.grossSalesAmount
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ],
                          2
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              sales.salesByDiscount
                ? _c("section", { staticClass: "data_section" }, [
                    _c("h6", [
                      _vm._v(_vm._s(_vm.$t("salesAndRevenue.netSales")))
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.$t("salesAndRevenue.discounts"))
                              )
                            ]),
                            _c("th"),
                            _c("th", { staticClass: "align-right" }, [
                              _vm._v(_vm._s(_vm.$t("salesAndRevenue.quantity")))
                            ]),
                            _c("th", { staticClass: "align-right" }, [
                              _vm._v(_vm._s(_vm.$t("salesAndRevenue.amount")))
                            ])
                          ])
                        ]),
                        _c(
                          "tbody",
                          [
                            [
                              _vm._l(sales.salesByDiscount, function(
                                salesByDiscount,
                                idx
                              ) {
                                return _c("tr", { key: idx }, [
                                  _c("td", [
                                    _vm._v(_vm._s(salesByDiscount.name || "--"))
                                  ]),
                                  _c("td"),
                                  _c("td", { staticClass: "align-right" }, [
                                    _vm._v(_vm._s(salesByDiscount.quantity))
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "align-right" },
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            (sales.location.detailedCurrency &&
                                              sales.location.detailedCurrency
                                                .currencySymbol) ||
                                            "",
                                          amount:
                                            salesByDiscount.discountsAmount
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              }),
                              _c("tr", { staticClass: "summary" }, [
                                _c("td"),
                                _c("td"),
                                _c("td", { staticClass: "align-right" }, [
                                  _vm._v(_vm._s(sales.totalDiscountQuantity))
                                ]),
                                _c("td", { staticClass: "align-right" }, [
                                  _c("div", { staticClass: "subtotal" }, [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "salesAndRevenue.discountTotal"
                                          )
                                        )
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            currency:
                                              (sales.location
                                                .detailedCurrency &&
                                                sales.location.detailedCurrency
                                                  .currencySymbol) ||
                                              "",
                                            amount: sales.totalDiscountsAmount
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ]),
                              _c("tr", { staticClass: "align-right" }, [
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td", { staticClass: "bigtotal" }, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("salesAndRevenue.totalNetSales")
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            (sales.location.detailedCurrency &&
                                              sales.location.detailedCurrency
                                                .currencySymbol) ||
                                            "",
                                          amount:
                                            sales.salesIndicator.netSalesAmount
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ]
                          ],
                          2
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              sales.salesByTax.taxes
                ? _c("section", { staticClass: "data_section" }, [
                    _c("h6", [
                      _vm._v(_vm._s(_vm.$t("salesAndRevenue.subTotal")))
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("salesAndRevenue.taxes")))
                            ]),
                            _c("th"),
                            _c("th", { staticClass: "align-right" }, [
                              _vm._v(
                                _vm._s(_vm.$t("salesAndRevenue.taxableSales"))
                              )
                            ]),
                            _c("th", { staticClass: "align-right" }, [
                              _vm._v(_vm._s(_vm.$t("salesAndRevenue.amount")))
                            ])
                          ])
                        ]),
                        _c(
                          "tbody",
                          [
                            [
                              _vm._l(sales.salesByTax.taxes, function(
                                salesByTax,
                                idx
                              ) {
                                return _c("tr", { key: idx }, [
                                  _c("td", [
                                    _vm._v(_vm._s(salesByTax.name || "--"))
                                  ]),
                                  _c("td"),
                                  _c(
                                    "td",
                                    { staticClass: "align-right" },
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            (sales.location.detailedCurrency &&
                                              sales.location.detailedCurrency
                                                .currencySymbol) ||
                                            "",
                                          amount: salesByTax.taxableAmount
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "align-right" },
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            (sales.location.detailedCurrency &&
                                              sales.location.detailedCurrency
                                                .currencySymbol) ||
                                            "",
                                          amount: salesByTax.taxesAmount
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              }),
                              _c("tr", { staticClass: "summary" }, [
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td", { staticClass: "align-right" }, [
                                  _c("div", { staticClass: "subtotal" }, [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("salesAndRevenue.taxesTotal")
                                        )
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            currency:
                                              (sales.location
                                                .detailedCurrency &&
                                                sales.location.detailedCurrency
                                                  .currencySymbol) ||
                                              "",
                                            amount:
                                              sales.salesIndicator.taxesAmount
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ]),
                              _c("tr", { staticClass: "align-right" }, [
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td", { staticClass: "bigtotal" }, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "salesAndRevenue.subTotalExplained"
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            (sales.location.detailedCurrency &&
                                              sales.location.detailedCurrency
                                                .currencySymbol) ||
                                            "",
                                          amount: sales.subTotal
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ]
                          ],
                          2
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              sales.salesByTenderType
                ? _c("section", { staticClass: "data_section" }, [
                    _c("h6", [
                      _vm._v(_vm._s(_vm.$t("salesAndRevenue.totalRevenue")))
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.$t("salesAndRevenue.tenderTypes"))
                              )
                            ]),
                            _c("th", { staticClass: "align-right" }, [
                              _vm._v(_vm._s(_vm.$t("salesAndRevenue.quantity")))
                            ]),
                            _c("th", { staticClass: "align-right" }, [
                              _vm._v(_vm._s(_vm.$t("salesAndRevenue.tips")))
                            ]),
                            _c("th", { staticClass: "align-right" }, [
                              _vm._v(_vm._s(_vm.$t("salesAndRevenue.amount")))
                            ])
                          ])
                        ]),
                        _c(
                          "tbody",
                          [
                            [
                              _vm._l(sales.salesByTenderType, function(
                                salesByTenderType,
                                idx
                              ) {
                                return _c("tr", { key: idx }, [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        salesByTenderType.tenderType.name ||
                                          "--"
                                      )
                                    )
                                  ]),
                                  _c("td", { staticClass: "align-right" }, [
                                    _vm._v(_vm._s(salesByTenderType.count))
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "align-right" },
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            (sales.location.detailedCurrency &&
                                              sales.location.detailedCurrency
                                                .currencySymbol) ||
                                            "",
                                          amount:
                                            salesByTenderType.transferredTip
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "align-right" },
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            (sales.location.detailedCurrency &&
                                              sales.location.detailedCurrency
                                                .currencySymbol) ||
                                            "",
                                          amount: salesByTenderType.amount
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              }),
                              _c("tr", { staticClass: "summary" }, [
                                _c("td"),
                                _c("td", { staticClass: "align-right" }, [
                                  _vm._v(_vm._s(sales.totalTenderQuantity))
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "align-right" },
                                  [
                                    _c("vel-amount", {
                                      attrs: {
                                        currency:
                                          (sales.location.detailedCurrency &&
                                            sales.location.detailedCurrency
                                              .currencySymbol) ||
                                          "",
                                        amount:
                                          sales.salesIndicator.tipsTotalAmount
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", { staticClass: "align-right" }, [
                                  _c("div", { staticClass: "subtotal" }, [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("salesAndRevenue.tenderTotal")
                                        )
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            currency:
                                              (sales.location
                                                .detailedCurrency &&
                                                sales.location.detailedCurrency
                                                  .currencySymbol) ||
                                              "",
                                            amount: sales.totalTenderAmount
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ]),
                              _c("tr", { staticClass: "align-right" }, [
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td", { staticClass: "bigtotal" }, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("salesAndRevenue.totalRevenue")
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("vel-amount", {
                                        attrs: {
                                          currency:
                                            (sales.location.detailedCurrency &&
                                              sales.location.detailedCurrency
                                                .currencySymbol) ||
                                            "",
                                          amount: sales.totalRevenue
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ]
                          ],
                          2
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _c("div", { staticClass: "bigtotal align-right" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("salesAndRevenue.totalCustomerCount"))
                      )
                    ]),
                    _c("div", [_vm._v(_vm._s(sales.salesIndicator.customers))])
                  ]),
                  _c("div", { staticClass: "bigtotal align-right" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("salesAndRevenue.outOfBalanceExplained"))
                      )
                    ]),
                    _c(
                      "div",
                      { staticStyle: { color: "#ef4244" } },
                      [
                        _c("vel-amount", {
                          attrs: {
                            currency:
                              (sales.location.detailedCurrency &&
                                sales.location.detailedCurrency
                                  .currencySymbol) ||
                              "",
                            amount: sales.outOfBalance
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }