<template>
  <vel-page
    :multiple-locations="locationsList && locationsList.length > 1"
    :show-selector-button="true"
    class="report"
    v-bind="pageAttrs"
  >
    <indicators :key="updateIndicators" config-key="locations_report" :show-icon="false" secondary />
    <LocationReportCharts :key="updateCharts" />
    <LocationsSalesData />
  </vel-page>
</template>

<script>
import ConfigLocationsReportDisplay from '@/private/components/config/appearance/locations-report/ConfigLocationsReportDisplay';
import GeneratedTime from '@/mixins/generated-time-mixin';
import Indicators from '@/private/shared/indicators/Indicators';
import LocationReportCharts from '@/private/reports/components/LocationReportCharts';
import LocationsSalesData from '@/private/shared/sales/LocationsSalesData';
import VelPage from '@/components/page/VelPage';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataLocationsVersionsStore} from "@/stores/data/locations/versions.module";

export default {
  name: 'LocationReportPage',
  components: {
    Indicators,
    ConfigLocationsReportDisplay,
    LocationReportCharts,
    VelPage,
    LocationsSalesData
  },
  mixins: [GeneratedTime],
  data() {
    return {
      updateIndicators: 0,
      updateCharts: 1
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, { locationsList: 'locations', getLocationsIsLoading: 'getLocationsIsLoading' }),
    ...mapPiniaState(useUILocationSelector, ['getLocationSelectorSelected', 'locations']),
    pageAttrs() {
      return {
        enableToolbar: true,
        enableDateRange: true,
        enableCurrency: true,
        title: this.$t('pages.reports.locations'),
        showCrumbs: false,
        failed: this.hasPageFailed,
        loading: this.isPageLoading,
        generatedTime: this.generatedTime,
        showDateCompare: this.showLocationsSales
      };
    },
    hasPageFailed() {
      return this.false;
    },
    isPageLoading() {
      return this.getLocationsIsLoading;
    },
    showLocationsSales() {
      return !this.getLocationSelectorSelected || this.locations.length === 1;
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataLocationsVersionsStore, ['getVersions'])
  },
  beforeDestroy() {
    this.gen(false);
  },
  mounted() {
    this.getVersions();
    // this.generateTimeAt('data/sales/indicators/getIndicatorsSuccess');
  }
};
</script>
