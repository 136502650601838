var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selectList" }, [
    _vm.multiple && _vm.valueOptions.length > 0
      ? _c("div", { staticClass: "selectList__header" }, [
          _c("a", { on: { click: _vm.selectAll } }, [
            _vm._v(_vm._s(_vm.$t("listSelector.selectAll")))
          ]),
          _c("a", { on: { click: _vm.unselectAll } }, [
            _vm._v(_vm._s(_vm.$t("listSelector.deselectAll")))
          ])
        ])
      : _vm._e(),
    _c(
      "ul",
      _vm._l(_vm.valueOptions || [], function(option) {
        return _c(
          "li",
          {
            key: option.key,
            class: { selected: _vm.isItemSelected(option.key) },
            on: {
              click: function($event) {
                return _vm.selectedItem(option.key)
              }
            }
          },
          [
            !_vm.isItemSelected(option.key)
              ? _c("b-icon", { attrs: { icon: "circle" } })
              : _c("b-icon", { attrs: { icon: "check2-circle" } }),
            _c("label", [_vm._v(_vm._s(option.label || "--"))])
          ],
          1
        )
      }),
      0
    ),
    _vm.multiple && _vm.valueOptions.length > 0
      ? _c("div", { staticClass: "selectList__footer" }, [
          _c("a", { on: { click: _vm.cancel } }, [
            _vm._v(_vm._s(_vm.$t("listSelector.cancel")))
          ]),
          _c("a", { on: { click: _vm.validate } }, [
            _vm._v(_vm._s(_vm.$t("listSelector.ok")))
          ])
        ])
      : _vm._e(),
    _vm.valueOptions.length <= 0
      ? _c("div", [
          _vm._v(_vm._s(_vm.noDataLabel || _vm.$t("listSelector.noData")))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }