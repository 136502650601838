var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-card",
    {
      staticClass: "location__edit",
      attrs: { "v-if": !_vm.isLoadingLocation && _vm.location },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("location.info.title")) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isError
        ? _c(
            "vel-alert",
            {
              staticClass: "alert",
              attrs: { level: "error" },
              on: { dismiss: _vm.clearMessage }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("location.errors.patchLocation")) + " "
              )
            ]
          )
        : _vm._e(),
      _vm.isSuccess
        ? _c(
            "vel-alert",
            {
              staticClass: "success",
              attrs: { level: "success" },
              on: { dismiss: _vm.clearMessage }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("location.success.patchLocation")) + " "
              )
            ]
          )
        : _vm._e(),
      _c("location-form", {
        attrs: {
          location: _vm.location,
          countries: _vm.countries,
          currencies: _vm.currencies,
          resellers: _vm.resellers,
          pending: _vm.isPending
        },
        on: { cancel: _vm.cancel, submit: _vm.submit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }