<template>
  <div class="graph">
    <vel-card
      :style="getOrder('dashboard_show_gross_sales_per_hour')"
      v-can:show="'dashboard_show_gross_sales_per_hour'"
    >
      <hourly-sales v-bind="chartAttrs" />
    </vel-card>
    <vel-card
      :style="getOrder('dashboard_cumulative_gross_sales_per_hour')"
      v-can:show="'dashboard_cumulative_gross_sales_per_hour'"
    >
      <daily-sales v-bind="chartAttrs" />
    </vel-card>
    <vel-card
      :style="getOrder('dashboard_show_locations_sales')"
      v-can:show="'dashboard_show_locations_sales'"
      v-if="showLocationsSales"
    >
      <locations-sales-graph v-bind="chartAttrs" />
    </vel-card>
    <vel-card
      :style="getOrder('dashboard_show_employees_sales_top10')"
      v-if="hasSingleLocation"
      v-can:show="'dashboard_show_employees_sales_top10'"
    >
      <employee-graph v-bind="chartAttrs" />
    </vel-card>
    <vel-card :style="getOrder('dashboard_show_item_sales_top10')" v-can:show="'dashboard_show_item_sales_top10'">
      <items-sales-graph v-bind="chartAttrs" />
    </vel-card>
    <vel-card
      :style="getOrder('dashboard_show_gross_sales_per_order_mode')"
      v-can:show="'dashboard_show_gross_sales_per_order_mode'"
    >
      <zone-sales v-bind="chartAttrs" />
    </vel-card>
    <vel-card
      :style="getOrder('dashboard_show_gross_sales_per_department')"
      v-can:show="'dashboard_show_gross_sales_per_department'"
    >
      <department-sales v-bind="chartAttrs" />
    </vel-card>
    <vel-card
      :style="getOrder('dashboard_show_gross_sales_per_mode')"
      v-can:show="'dashboard_show_gross_sales_per_mode'"
    >
      <mode-sales v-bind="chartAttrs" />
    </vel-card>
    <vel-card :style="getOrder('dashboard_show_customers_per_mode')" v-can:show="'dashboard_show_customers_per_mode'">
      <mode-customers v-bind="chartAttrs" />
    </vel-card>
    <vel-card
      :style="getOrder('dashboard_show_mode_sales_per_customers')"
      v-can:show="'dashboard_show_mode_sales_per_customers'"
    >
      <mode-sales-per-customers v-bind="chartAttrs" />
    </vel-card>
    <vel-card :style="getOrder('dashboard_show_workstations_sales')" v-can:show="'dashboard_show_workstations_sales'">
      <workstations-sales v-bind="chartAttrs" />
    </vel-card>
    <vel-card
      :style="getOrder('dashboard_show_gross_sales_per_big_division')"
      v-can:show="'dashboard_show_gross_sales_per_big_division'"
    >
      <big-division-sales-graph v-bind="chartAttrs" />
    </vel-card>
    <!--
    <vel-card :style="getOrder('_show_gross_sales_per_order')">
      <paymode-sales v-bind="chartAttrs" />
    </vel-card>
    -->
    <vel-card :style="getOrder('dashboard_show_discounts_table')" v-can:show="'dashboard_show_discounts_table'">
      <discounts-table />
    </vel-card>
  </div>
</template>

<script>
import BigDivisionSalesGraph from '@/private/components/sales/BigDivisionSalesGraph';
import DailySales from '@/private/components/sales/DailySales';
import DepartmentSales from '@/private/components/sales/DepartmentSales';
import DiscountsTable from '@/private/components/discounts/DiscountsTable';
import EmployeeGraph from '@/private/components/sales/employee/EmployeeGraph';
import HourlySales from '@/private/components/sales/HourlySales';
import ItemsSalesGraph from '@/private/components/sales/items/ItemTop10Sales';
import LocationsSalesGraph from '@/private/components/locations/LocationsSalesGraph';
import ModeCustomers from '@/private/components/sales/ModeCustomers';
import ModeSales from '@/private/components/sales/ModeSales';
import ModeSalesPerCustomers from '@/private/components/sales/ModeSalesPerCustomers';
// import PaymodeSales from '@/private/components/sales/PaymodeSales';
import VelCard from '@/components/card/VelCard';
import WorkstationsSales from '@/private/components/sales/WorkstationsSales';
import ZoneSales from '@/private/components/sales/ZoneSales';
import { mapState as mapPiniaState } from 'pinia';
import {useUICurrencySelector} from "@/stores/ui/currency-selector.module";
import {useUIDateSelector} from "@/stores/ui/date-selector.module";
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {useDataConfig} from "@/stores/data/config.module";

export default {
  name: 'dashboard-charts',
  components: {
    HourlySales,
    DailySales,
    DepartmentSales,
    ZoneSales,
    LocationsSalesGraph,
    ItemsSalesGraph,
    //  PaymodeSales,
    BigDivisionSalesGraph,
    ModeSales,
    ModeCustomers,
    ModeSalesPerCustomers,
    DiscountsTable,
    VelCard,
    WorkstationsSales,
    EmployeeGraph
  },
  computed: {
    ...mapPiniaState(useUILocationSelector, ['getLocationSelectorSelected', 'hasSingleLocation', 'locations']),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelectedSymbol']),
    ...mapPiniaState(useUIDateSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useDataConfig, ['get']),
    config: {
      get() {
        return this.get;
      },
      set() {
        return true;
      }
    },
    chartAttrs() {
      return {
        locationId: this.getLocationSelectorSelected,
        currencyId: this.getCurrencySelectorSelectedSymbol,
        from: this.getDateSelectorSelectedRange[0],
        to: this.getDateSelectorSelectedRange[1],
        limit: this.get.charts_size_limitation.value
      };
    },
    showLocationsSales() {
      return !this.getLocationSelectorSelected || this.locations.length === 1;
    }
  },
  methods: {
    getOrder(key) {
      const order = (this.config[key] && this.config[key].order) || 0;
      return { order };
    }
  }
};
</script>

<style lang="scss" scopped>
@import 'mixins.scss';
@import 'constants.scss';

$indicator-grid-column-gap: rem-calc(20);

.graph {
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint(medium) {
    grid-gap: $indicator-grid-column-gap;
    grid-template-columns: 1fr;
    margin-bottom: rem-calc(20);
  }
  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr;
  }

  > * {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}

.vel-card {
  position: relative;
}
</style>
