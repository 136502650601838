<template>
  <div class="filters">
    <div class="filters__fields">
      <b-form-group v-if="enabledFilters.includes('check')">
        <label>{{ $t('checkSummary.filters.check.label') }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text">
              <b-icon icon="search" />
            </span>
          </b-input-group-prepend>
          <b-form-input
            :disabled="disabled"
            :state="invoiceNumberValueValid ? null : false"
            size="sm"
            :placeholder="$t('checkSummary.filters.check.placeholder')"
            v-model="invoiceNumberValue"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="enabledFilters.includes('tender')">
        <label>{{ $t('checkSummary.filters.tenderTypes.label') }}</label>
        <vel-multiple-selector
          :value-options="this.filtersValuesArray.tenderTypes"
          :multiple="true"
          :filterable="true"
          :disabled="disabled"
          v-model="selectedFilters.tenderTypes"
          :all-results-label="'checkSummary.filters.tenderTypes.all'"
          :multiples-results-label="'checkSummary.filters.tenderTypes.filtered'"
        ></vel-multiple-selector>
      </b-form-group>
      <b-form-group v-if="enabledFilters.includes('employee')">
        <label>{{ $t('checkSummary.filters.employee.label') }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text">
              <b-icon icon="search" />
            </span>
          </b-input-group-prepend>
          <b-form-input
            v-model="selectedFilters.employeeName"
            :disabled="disabled"
            size="sm"
            max="10"
            :placeholder="$t('checkSummary.filters.employee.placeholder')"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="enabledFilters.includes('status')">
        <label>{{ $t('checkSummary.filters.status.label') }}</label>
        <vel-multiple-selector
          :value-options="this.statusList"
          :multiple="false"
          :filterable="false"
          :disabled="disabled || loading"
          v-model="selectedFilters.status"
          :all-results-label="'checkSummary.filters.status.all'"
          :multiples-results-label="'checkSummary.filters.status.filtered'"
        ></vel-multiple-selector>
      </b-form-group>
      <div style="display: flex; align-items: end;">
        <div>
          <b-button
            variant="secondary"
            :disabled="disabled"
            class="d-flex justify-content-between align-items-center"
            @click="showFiltersPopup = true"
          >
            <b-icon icon="plus-circle" aria-hidden="true" scale="1.4"></b-icon>
            <span class="d-none d-md-block ml-2">{{ $t('checkSummary.filters.add') }}</span>
          </b-button>
          <VelListPopup
            v-if="showFiltersPopup"
            v-model="enabledFilters"
            :value-options="filtersOptions"
            @close="showFiltersPopup = false"
          />
        </div>
      </div>
    </div>
    <div class="filters__buttons">
      <b-button variant="primary" :disabled="disabled" @click="applyFilters">
        {{ $t('checkSummary.filters.apply') }}
      </b-button>
      <b-button variant="secondary" :disabled="disabled" @click="clearFilters">
        {{ $t('checkSummary.filters.clear') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import VelListPopup from '@/components/list/VelListPopup';
import VelMultipleSelector from '@/components/list/VelMultipleSelector';
import { useDataCheckSummary } from '@/stores/data/checks/check-summary.module';
import {mapState as mapPiniaState, mapActions as mapPiniaActions} from "pinia/dist/pinia";

export default {
  name: 'CheckSummaryFilters',
  components: {
    VelMultipleSelector,
    VelListPopup
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapPiniaState(useDataCheckSummary, [
      'getFilters',
      'getAppliedFilterEmployeeName',
      'getAppliedFilterInvoiceNumbers',
      'getAppliedFilterTenderType',
      'getAppliedFilterStatus'
    ]),
    filtersOptions() {
      return [
        { key: 'check', label: this.$t('checkSummary.filters.check.label') },
        { key: 'tender', label: this.$t('checkSummary.filters.tenderTypes.label') },
        { key: 'employee', label: this.$t('checkSummary.filters.employee.label') },
        { key: 'status', label: this.$t('checkSummary.filters.status.label') }
      ];
    },
    filtersValuesArray() {
      return {
        tenderTypes: this.getFilterKeys(this.getFilters.tenderTypes)
      };
    },
    statusList() {
      return [
        { value: null, key: null, label: this.$t('checkStatus.states.all') },
        { value: 0, key: 0, label: this.$t('checkStatus.states.0') },
        { value: 1, key: 1, label: this.$t('checkStatus.states.1') },
        { value: 2, key: 2, label: this.$t('checkStatus.states.2') }
      ];
    }
  },
  data() {
    return {
      showFiltersPopup: false,
      enabledFilters: JSON.parse(window.localStorage.getItem('summaryChecks.filters') || '[]'),
      filters: {
        tenderTypes: {}
      },
      selectedFilters: {
        tenderTypes: [],
        invoiceNumber: [],
        invoiceNumberRange: [],
        employeeName: '',
        status: null
      },
      invoiceNumberValue: '',
      invoiceNumberValueValid: true
    };
  },
  watch: {
    enabledFilters(value) {
      if (!value.includes('check')) {
        this.invoiceNumberValue = '';
        this.selectedFilters.invoiceNumber = [];
        this.selectedFilters.invoiceNumberRange = [];
      }
      if (!value.includes('tender')) {
        this.selectedFilters.tenderTypes = [];
      }
      if (!value.includes('employee')) {
        this.selectedFilters.employeeName = '';
      }
      if (!value.includes('status')) {
        this.selectedFilters.status = null;
      }

      window.localStorage.setItem('summaryChecks.filters', JSON.stringify(value));
    }
  },
  methods: {
    ...mapPiniaActions(useDataCheckSummary, [
      'setEmployeeNameFilter',
      'setInvoiceNumberRangeFilter',
      'setInvoiceNumberFilter',
      'setInvoiceNumberStringFilter',
      'setTenderTypeStringFilter',
      'setTenderTypeIDsFilter',
      'setStatusFilter'
    ]),
    reset() {
      this.selectedFilters = {
        tenderTypes: [],
        invoiceNumber: [],
        invoiceNumberRange: [],
        employeeName: '',
        status: null
      };
      this.invoiceNumberValue = '';
      this.invoiceNumberValueValid = true;

      this.sendFiltersToStore();
    },
    clearFilters() {
      this.reset();
      this.$emit('filtersChanged');
    },
    applyFilters() {
      this.invoiceNumberValueValid = true;
      if (this.extractInvoiceRange()) {
        this.sendFiltersToStore();
        this.$emit('filtersChanged');
      } else {
        this.invoiceNumberValueValid = false;
      }
    },
    sendFiltersToStore() {
      this.setEmployeeNameFilter(this.selectedFilters.employeeName);
      this.setTenderTypeStringFilter(this.selectedFilters.tenderTypes);
      this.setTenderTypeIDsFilter(this.getIDsFromKeys('tenderTypes', this.selectedFilters.tenderTypes));
      this.setInvoiceNumberStringFilter(this.invoiceNumberValue);
      this.setInvoiceNumberRangeFilter(this.selectedFilters.invoiceNumberRange);
      this.setInvoiceNumberFilter(this.selectedFilters.invoiceNumber);
      this.setStatusFilter(this.selectedFilters.status);
    },
    extractInvoiceRange() {
      this.selectedFilters.invoiceNumber = [];
      this.selectedFilters.invoiceNumberRange = [];

      if (this.invoiceNumberValue.length === 0) {
        return true;
      }

      const chunks = this.invoiceNumberValue.replaceAll(' ', '').split(',');

      for (const chunk of chunks) {
        if (isNaN(chunk)) {
          const potentialRange = chunk.split('-');
          if (potentialRange.length !== 2) {
            return false;
          }

          if (isNaN(potentialRange[0]) || isNaN(potentialRange[1])) {
            return false;
          }

          this.selectedFilters.invoiceNumberRange.push(chunk);
        } else {
          this.selectedFilters.invoiceNumber.push(chunk);
        }
      }

      return true;
    },
    getFilterKeys(fields) {
      return Object.keys(fields)
        .sort()
        .map(r => ({ value: r, key: r, label: r }));
    },
    getIDsFromKeys(filter, keys) {
      const ids = [];
      keys.forEach(k => ids.push(...this.getFilters[filter][k]));
      return ids;
    }
  },
  mounted() {
    this.selectedFilters.employeeName = this.getAppliedFilterEmployeeName;
    this.invoiceNumberValue = this.getAppliedFilterInvoiceNumbers;
    this.selectedFilters.tenderTypes = this.getAppliedFilterTenderType;
    this.selectedFilters.status = this.getAppliedFilterStatus;
  }
}
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$contrast-color: #00a9e1;

* {
  font-size: rem-calc(12) !important;
}

.filters {
  align-items: flex-end;
  display: flex;

  .btn-secondary {
    background: #f5f5f5;
    color: $contrast-color;
    border: none;
  }

  &__fields {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    gap: 5px;
    padding-right: rem-calc(16);

    .el-select,
    /deep/ .el-select__tags,
    /deep/ .el-input,
    /deep/ .el-select__input,
    /deep/ .el-input__inner,
    /deep/ .el-input__suffix {
      max-height: 32px;
      height: 32px;
    }

    /deep/ .el-input__icon {
      line-height: 32px;
      color: $contrast-color;
    }

    input.form-control {
      height: 34px;
    }

    .form-group {
      max-width: 150px;

      & > div > label {
        color: #808080;
        min-height: unset;
        margin: 0;
      }

      .input-group-text {
        width: 24px;
        border-right: none;
        background-color: transparent !important;
        color: #d9d9d9;
        padding: 0;

        svg {
          margin-left: 4px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-left: 1px solid #dbdbdb;
    padding-left: rem-calc(16);
  }
}
</style>
