import { render, staticRenderFns } from "./DiscountFooter.vue?vue&type=template&id=3babd223&"
import script from "./DiscountFooter.vue?vue&type=script&lang=js&"
export * from "./DiscountFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/runner/work/velweb/velweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3babd223')) {
      api.createRecord('3babd223', component.options)
    } else {
      api.reload('3babd223', component.options)
    }
    module.hot.accept("./DiscountFooter.vue?vue&type=template&id=3babd223&", function () {
      api.rerender('3babd223', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/private/discounts/components/DiscountFooter.vue"
export default component.exports