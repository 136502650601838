var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "page bootstrap",
      attrs: {
        "show-crumbs": false,
        loading: _vm.isLoading,
        title: _vm.taxForm.name || "--"
      }
    },
    [
      _c(
        "div",
        { staticClass: "tax-form-container bg-white" },
        [
          _vm.hasTaxError
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "error" },
                  on: { dismiss: _vm.clearError }
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.errorLabel)) + " ")]
              )
            : _vm._e(),
          _c(
            "b-form",
            {
              ref: "taxForm",
              staticClass: "tax-form",
              attrs: { model: _vm.taxForm, submit: _vm.handleSubmit }
            },
            [
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("taxForm.generalConfiguration")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-content-between",
                      style: { width: _vm.isMediumUp ? "75%" : "100%" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 w-100 mb-2 mb-md-0",
                          attrs: {
                            "label-for": "description",
                            "label-size": "sm",
                            label: _vm.$t("taxForm.form.description.label"),
                            description: _vm.$t(
                              "taxForm.form.description.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.description.error"
                            ),
                            state:
                              _vm.taxForm.name === ""
                                ? null
                                : !_vm.$v.taxForm.name.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            class: { required: _vm.$v.taxForm.name.$invalid },
                            attrs: {
                              id: "description",
                              placeholder: _vm.$t(
                                "taxForm.form.description.placeholder"
                              ),
                              maxlength: "50",
                              type: "text",
                              size: "md",
                              state: _vm.computeState(
                                _vm.$v.taxForm.name.$invalid
                              )
                            },
                            model: {
                              value: _vm.taxForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.taxForm, "name", $$v)
                              },
                              expression: "taxForm.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mr-5 mb-2 mb-md-0",
                          style: { width: _vm.isMediumUp ? "50%" : "100%" }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "includedTax__checkbox ml-1 ml-md-0",
                              style: {
                                marginTop: _vm.isMediumUp ? "2.2rem" : "0"
                              },
                              attrs: { id: "includedTax", name: "includedTax" },
                              model: {
                                value: _vm.taxForm.includedTax,
                                callback: function($$v) {
                                  _vm.$set(_vm.taxForm, "includedTax", $$v)
                                },
                                expression: "taxForm.includedTax"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("taxForm.form.includedTax.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 w-100 ml-md-5 mb-2 mb-md-0",
                          attrs: {
                            "label-for": "taxCategory",
                            "label-size": "sm",
                            label: _vm.$t("taxForm.form.taxCategory.label"),
                            description: _vm.$t(
                              "taxForm.form.taxCategory.required"
                            )
                          }
                        },
                        [
                          _c(
                            "b-form-select",
                            {
                              model: {
                                value: _vm.taxForm.taxCategoryId,
                                callback: function($$v) {
                                  _vm.$set(_vm.taxForm, "taxCategoryId", $$v)
                                },
                                expression: "taxForm.taxCategoryId"
                              }
                            },
                            [
                              _c(
                                "b-form-select-option",
                                { attrs: { value: null } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "taxForm.form.taxCategory.emptySelection"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _vm._l(_vm.taxCategories, function(taxCategory) {
                                return _c(
                                  "b-form-select-option",
                                  {
                                    key: taxCategory.id,
                                    attrs: { value: taxCategory.id }
                                  },
                                  [_vm._v(" " + _vm._s(taxCategory.name) + " ")]
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 w-100 ml-md-5 mb-2 mb-md-0",
                          attrs: {
                            "label-for": "fiscalId",
                            "label-size": "sm",
                            label: _vm.$t("taxForm.form.fiscalId.label"),
                            description: _vm.$t(
                              "taxForm.form.fiscalId.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.fiscalId.error"
                            ),
                            state:
                              _vm.taxForm.fiscalIdentification === ""
                                ? null
                                : !_vm.$v.taxForm.fiscalIdentification.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "fiscalId",
                              placeholder: _vm.$t(
                                "taxForm.form.fiscalId.placeholder"
                              ),
                              type: "text",
                              size: "md",
                              maxlength: "10",
                              state: _vm.computeState(
                                _vm.$v.taxForm.fiscalIdentification.$invalid
                              )
                            },
                            model: {
                              value: _vm.taxForm.fiscalIdentification,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.taxForm,
                                  "fiscalIdentification",
                                  $$v
                                )
                              },
                              expression: "taxForm.fiscalIdentification"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("taxForm.taxRates")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-content-between",
                      style: { width: _vm.isMediumUp ? "75%" : "100%" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 w-100 mb-2 mb-md-0",
                          attrs: {
                            "label-for": "taxRate",
                            "label-size": "sm",
                            label: _vm.$t("taxForm.form.taxRate.label"),
                            description: _vm.$t(
                              "taxForm.form.taxRate.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.taxRate.error"
                            ),
                            state:
                              _vm.taxForm.rate === null ||
                              _vm.taxForm.rate === ""
                                ? null
                                : !_vm.$v.taxForm.rate.$invalid
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { prepend: "%" } },
                            [
                              _c("b-form-input", {
                                class: {
                                  required: _vm.$v.taxForm.rate.$invalid
                                },
                                attrs: {
                                  id: "taxRate",
                                  placeholder: _vm.$t(
                                    "taxForm.form.taxRate.placeholder"
                                  ),
                                  formatter: _vm.formatAmount,
                                  "lazy-formatter": "",
                                  size: "md",
                                  state: _vm.computeState(
                                    _vm.$v.taxForm.rate.$invalid
                                  )
                                },
                                model: {
                                  value: _vm.taxForm.rate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.taxForm, "rate", _vm._n($$v))
                                  },
                                  expression: "taxForm.rate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mr-5 mb-2 mb-md-0",
                          style: { width: _vm.isMediumUp ? "50%" : "100%" }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass:
                                "scheduleRate__checkbox ml-1 ml-md-0",
                              style: {
                                marginTop: _vm.isMediumUp ? "2.2rem" : "0"
                              },
                              attrs: {
                                id: "scheduleRate",
                                name: "scheduleRate"
                              },
                              on: { change: _vm.toggleScheduleRate },
                              model: {
                                value: _vm.taxForm.scheduleRate,
                                callback: function($$v) {
                                  _vm.$set(_vm.taxForm, "scheduleRate", $$v)
                                },
                                expression: "taxForm.scheduleRate"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("taxForm.form.scheduleRate.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 w-100 ml-md-5 mb-2 mb-md-0",
                          class: { invisible: !_vm.taxForm.scheduleRate },
                          attrs: {
                            "label-for": "newTaxRate",
                            "label-size": "sm",
                            label: _vm.$t("taxForm.form.newTaxRate.label"),
                            description: _vm.$t(
                              "taxForm.form.newTaxRate.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.newTaxRate.error"
                            ),
                            state:
                              _vm.taxForm.newTaxRate === null ||
                              _vm.taxForm.newTaxRate === ""
                                ? null
                                : !_vm.$v.taxForm.newTaxRate.$invalid
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { prepend: "%" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "newTaxRate",
                                  placeholder: _vm.$t(
                                    "taxForm.form.newTaxRate.placeholder"
                                  ),
                                  size: "md",
                                  formatter: _vm.formatAmount,
                                  "lazy-formatter": "",
                                  state: _vm.computeState(
                                    _vm.$v.taxForm.newTaxRate.$invalid
                                  )
                                },
                                model: {
                                  value: _vm.taxForm.newTaxRate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.taxForm, "newTaxRate", $$v)
                                  },
                                  expression: "taxForm.newTaxRate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 w-100 ml-md-5 mb-2 mb-md-0",
                          class: { invisible: !_vm.taxForm.scheduleRate },
                          attrs: {
                            "label-for": "rate",
                            "label-size": "sm",
                            label: _vm.$t("taxForm.form.newTaxDate.label"),
                            description: _vm.$t(
                              "taxForm.form.newTaxDate.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.newTaxDate.error"
                            ),
                            state:
                              _vm.taxForm.newTaxRateTime === null
                                ? null
                                : !_vm.$v.taxForm.newTaxRateTime.$invalid
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "datepicker" },
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "99/99/9999",
                                    expression: "'99/99/9999'"
                                  }
                                ],
                                attrs: {
                                  id: "scheduledDate",
                                  size: "md",
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "taxForm.form.newTaxDate.placeholder"
                                  ),
                                  value: _vm.newTaxRateTime,
                                  autocomplete: "off",
                                  state: _vm.computeState(
                                    _vm.$v.taxForm.newTaxRateTime.$invalid
                                  )
                                },
                                on: { change: _vm.setScheduledDate }
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "button-only": "",
                                      size: "md",
                                      locale: _vm.$i18n.locale,
                                      right: "",
                                      "button-variant": "white",
                                      "label-no-date-selected": _vm.$t(
                                        "datePicker.noDate"
                                      ),
                                      "label-calendar": _vm.$t(
                                        "datePicker.calendar"
                                      ),
                                      "label-close-button": _vm.$t(
                                        "datePicker.close"
                                      ),
                                      "label-current-month": _vm.$t(
                                        "datePicker.currentMonth"
                                      ),
                                      "label-help": _vm.$t("datePicker.help"),
                                      "label-nav": _vm.$t("datePicker.nav"),
                                      "label-next-decade": _vm.$t(
                                        "datePicker.nextDecade"
                                      ),
                                      "label-next-month": _vm.$t(
                                        "datePicker.nextMonth"
                                      ),
                                      "label-next-year": _vm.$t(
                                        "datePicker.nextYear"
                                      ),
                                      "label-prev-decade": _vm.$t(
                                        "datePicker.previousDecade"
                                      ),
                                      "label-prev-month": _vm.$t(
                                        "datePicker.previousMonth"
                                      ),
                                      "label-prev-year": _vm.$t(
                                        "datePicker.previousYear"
                                      ),
                                      "label-reset-button": _vm.$t(
                                        "datePicker.reset"
                                      ),
                                      "label-today": _vm.$t("datePicker.today"),
                                      "label-today-button": _vm.$t(
                                        "datePicker.selectToday"
                                      ),
                                      min: new Date(),
                                      "date-format-options": {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit"
                                      },
                                      "aria-controls": "scheduledDate"
                                    },
                                    on: { context: _vm.onSelectScheduledDate },
                                    model: {
                                      value: _vm.taxForm.newTaxRateTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.taxForm,
                                          "newTaxRateTime",
                                          $$v
                                        )
                                      },
                                      expression: "taxForm.newTaxRateTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("taxForm.taxApplicability")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row justify-content-between",
                      style: { width: _vm.isMediumUp ? "50%" : "100%" }
                    },
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "mr-5 w-100 mb-2 mb-md-0" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "taxableDiscount__checkbox",
                              attrs: {
                                id: "taxableDiscount",
                                name: "taxableDiscount"
                              },
                              model: {
                                value: _vm.taxForm.taxableDiscount,
                                callback: function($$v) {
                                  _vm.$set(_vm.taxForm, "taxableDiscount", $$v)
                                },
                                expression: "taxForm.taxableDiscount"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("taxForm.form.taxableDiscount.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { staticClass: "mr-5 ml-md-5 mb-0 mb-md-0 w-100" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "taxableServiceCharge__checkbox",
                              attrs: {
                                id: "taxableServiceCharge",
                                name: "taxableServiceCharge"
                              },
                              model: {
                                value: _vm.taxForm.taxableGratuity,
                                callback: function($$v) {
                                  _vm.$set(_vm.taxForm, "taxableGratuity", $$v)
                                },
                                expression: "taxForm.taxableGratuity"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "taxForm.form.taxableServiceCharge.label"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("taxForm.exclusion")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row mb-md-4 justify-content-between",
                      style: { width: _vm.isMediumUp ? "50%" : "100%" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 w-100 mb-2 mb-md-0",
                          attrs: {
                            "label-for": "exclusionItemQuantity",
                            "label-size": "sm",
                            label: _vm.$t(
                              "taxForm.form.exclusionQuantity.label"
                            ),
                            description: _vm.$t(
                              "taxForm.form.exclusionQuantity.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.exclusionQuantity.error"
                            ),
                            state:
                              _vm.taxForm.exclusionItemQtyOver === null ||
                              _vm.taxForm.exclusionItemQtyOver === ""
                                ? null
                                : !_vm.$v.taxForm.exclusionItemQtyOver.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "exclusionItemQuantity",
                              placeholder: _vm.$t(
                                "taxForm.form.exclusionQuantity.placeholder"
                              ),
                              size: "md",
                              state: _vm.computeState(
                                _vm.$v.taxForm.exclusionItemQtyOver.$invalid
                              )
                            },
                            model: {
                              value: _vm.taxForm.exclusionItemQtyOver,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.taxForm,
                                  "exclusionItemQtyOver",
                                  $$v
                                )
                              },
                              expression: "taxForm.exclusionItemQtyOver"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 ml-md-5 mb-2 mb-md-0 w-100",
                          attrs: {
                            "label-for": "exclusionRate",
                            "label-size": "sm",
                            label: _vm.$t("taxForm.form.exclusionRate.label"),
                            description: _vm.$t(
                              "taxForm.form.exclusionRate.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.exclusionRate.error"
                            ),
                            state:
                              _vm.taxForm.exclusionTaxRate === null ||
                              _vm.taxForm.exclusionTaxRate === ""
                                ? null
                                : !_vm.$v.taxForm.exclusionTaxRate.$invalid
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { prepend: "%" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "exclusionRate",
                                  placeholder: _vm.$t(
                                    "taxForm.form.exclusionRate.placeholder"
                                  ),
                                  size: "md",
                                  formatter: _vm.formatAmount,
                                  "lazy-formatter": "",
                                  state: _vm.computeState(
                                    _vm.$v.taxForm.exclusionTaxRate.$invalid
                                  )
                                },
                                model: {
                                  value: _vm.taxForm.exclusionTaxRate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.taxForm,
                                      "exclusionTaxRate",
                                      $$v
                                    )
                                  },
                                  expression: "taxForm.exclusionTaxRate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("taxForm.exceptionRule")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row mb-md-4 justify-content-between",
                      style: {
                        width: _vm.isMediumUp ? "calc(75% + 1rem)" : "100%"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 w-100 mb-2 mb-md-0",
                          attrs: {
                            "label-for": "exceptionRuleDescription",
                            "label-size": "sm",
                            label: _vm.$t(
                              "taxForm.form.exceptionRuleDesc.label"
                            ),
                            description: _vm.$t(
                              "taxForm.form.exceptionRuleDesc.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.exceptionRuleDesc.error"
                            ),
                            state:
                              _vm.taxForm.reduceRuleDescription === ""
                                ? null
                                : !_vm.$v.taxForm.reduceRuleDescription.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "exceptionRuleDescription",
                              placeholder: _vm.$t(
                                "taxForm.form.exceptionRuleDesc.placeholder"
                              ),
                              type: "text",
                              maxlength: "30",
                              size: "md",
                              state: _vm.computeState(
                                _vm.$v.taxForm.reduceRuleDescription.$invalid
                              )
                            },
                            model: {
                              value: _vm.taxForm.reduceRuleDescription,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.taxForm,
                                  "reduceRuleDescription",
                                  $$v
                                )
                              },
                              expression: "taxForm.reduceRuleDescription"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 ml-md-5 mb-2 mb-md-0 w-100",
                          attrs: {
                            "label-for": "exceptionRuleRate",
                            "label-size": "sm",
                            label: _vm.$t(
                              "taxForm.form.exceptionRuleRate.label"
                            ),
                            description: _vm.$t(
                              "taxForm.form.exceptionRuleRate.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.exceptionRuleRate.error"
                            ),
                            state:
                              _vm.taxForm.reduceRuleTaxRate === null ||
                              _vm.taxForm.reduceRuleTaxRate === ""
                                ? null
                                : !_vm.$v.taxForm.reduceRuleTaxRate.$invalid
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { prepend: "%" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "exceptionRuleRate",
                                  placeholder: _vm.$t(
                                    "taxForm.form.exceptionRuleRate.placeholder"
                                  ),
                                  size: "md",
                                  formatter: _vm.formatAmount,
                                  "lazy-formatter": "",
                                  state: _vm.computeState(
                                    _vm.$v.taxForm.reduceRuleTaxRate.$invalid
                                  )
                                },
                                model: {
                                  value: _vm.taxForm.reduceRuleTaxRate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.taxForm,
                                      "reduceRuleTaxRate",
                                      $$v
                                    )
                                  },
                                  expression: "taxForm.reduceRuleTaxRate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 ml-md-5 mb-2 mb-md-0 w-100",
                          attrs: {
                            "label-for": "exceptionRuleMaxAmount",
                            "label-size": "sm",
                            label: _vm.$t(
                              "taxForm.form.exceptionRuleMaxAmount.label"
                            ),
                            description: _vm.$t(
                              "taxForm.form.exceptionRuleMaxAmount.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.exceptionRuleMaxAmount.error"
                            ),
                            state:
                              _vm.taxForm.reduceRuleMaxAmount === null ||
                              _vm.taxForm.reduceRuleMaxAmount === ""
                                ? null
                                : !_vm.$v.taxForm.reduceRuleMaxAmount.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "exceptionRuleMaxAmount",
                              placeholder: _vm.$t(
                                "taxForm.form.exceptionRuleMaxAmount.placeholder"
                              ),
                              size: "md",
                              "lazy-formatter": "",
                              formatter: _vm.formatAmount,
                              state: _vm.computeState(
                                _vm.$v.taxForm.reduceRuleMaxAmount.$invalid
                              )
                            },
                            model: {
                              value: _vm.taxForm.reduceRuleMaxAmount,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.taxForm,
                                  "reduceRuleMaxAmount",
                                  $$v
                                )
                              },
                              expression: "taxForm.reduceRuleMaxAmount"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("taxForm.accountingInterface")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row mb-md-4 justify-content-between",
                      style: { width: _vm.isMediumUp ? "50%" : "100%" }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 w-100 mb-2 mb-md-0",
                          attrs: {
                            "label-for": "accountingNumberSend",
                            "label-size": "sm",
                            label: _vm.$t(
                              "taxForm.form.accountingNumberSend.label"
                            ),
                            description: _vm.$t(
                              "taxForm.form.accountingNumberSend.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.accountingNumberSend.error"
                            ),
                            state:
                              _vm.taxForm.generalLedgerNumberToSend === ""
                                ? null
                                : !_vm.$v.taxForm.generalLedgerNumberToSend
                                    .$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "accountingNumberSend",
                              placeholder: _vm.$t(
                                "taxForm.form.accountingNumberSend.placeholder"
                              ),
                              type: "text",
                              maxlength: "10",
                              size: "md",
                              state: _vm.computeState(
                                _vm.$v.taxForm.generalLedgerNumberToSend
                                  .$invalid
                              )
                            },
                            model: {
                              value: _vm.taxForm.generalLedgerNumberToSend,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.taxForm,
                                  "generalLedgerNumberToSend",
                                  $$v
                                )
                              },
                              expression: "taxForm.generalLedgerNumberToSend"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mr-5 ml-md-5 mb-2 mb-md-0 w-100",
                          attrs: {
                            "label-for": "accountingNumberClaim",
                            "label-size": "sm",
                            label: _vm.$t(
                              "taxForm.form.accountingNumberClaim.label"
                            ),
                            description: _vm.$t(
                              "taxForm.form.accountingNumberClaim.required"
                            ),
                            "invalid-feedback": _vm.$t(
                              "taxForm.form.accountingNumberClaim.error"
                            ),
                            state:
                              _vm.taxForm.generalLedgerNumberToClaim === ""
                                ? null
                                : !_vm.$v.taxForm.generalLedgerNumberToClaim
                                    .$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "accountingNumberClaim",
                              placeholder: _vm.$t(
                                "taxForm.form.accountingNumberClaim.placeholder"
                              ),
                              type: "text",
                              maxlength: "10",
                              size: "md",
                              state: _vm.computeState(
                                _vm.$v.taxForm.generalLedgerNumberToClaim
                                  .$invalid
                              )
                            },
                            model: {
                              value: _vm.taxForm.generalLedgerNumberToClaim,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.taxForm,
                                  "generalLedgerNumberToClaim",
                                  $$v
                                )
                              },
                              expression: "taxForm.generalLedgerNumberToClaim"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-0 mr-md-3 mb-2 mb-md-0",
                  attrs: { disabled: !_vm.canSave, variant: "primary" },
                  on: { click: _vm.handleSubmit }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tax && _vm.tax.id && _vm.tax.id !== ""
                          ? _vm.$t("taxForm.edit.save")
                          : _vm.$t("taxForm.create.save")
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleCancel(false)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("discardChange.title"),
            static: true,
            centered: true,
            "hide-header-close": true,
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          on: {
            hidden: function($event) {
              return _vm.$emit("onCancel", true)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c("b-container", { attrs: { fluid: "" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.continue")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right ml-2",
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                                _vm.handleCancel(true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.discard")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showDiscardChanges,
            callback: function($$v) {
              _vm.showDiscardChanges = $$v
            },
            expression: "showDiscardChanges"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("discardChange.body")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }