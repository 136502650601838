var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vel-modal-wrapper",
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.handleEscape.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "vel-modal",
          class: _vm.classes,
          attrs: { role: "dialog" }
        },
        [
          _vm.$slots.header
            ? _c(
                "header",
                { staticClass: "vel-modal__header" },
                [
                  _vm.dismissable
                    ? _c(
                        "button",
                        {
                          staticClass: "vel-modal__dismiss",
                          attrs: { "aria-hidden": "true" },
                          on: { click: _vm.handleDismiss }
                        },
                        [_vm._v("×")]
                      )
                    : _vm._e(),
                  _c(
                    _vm.titleLevel,
                    { tag: "component", staticClass: "vel-modal__title" },
                    [_vm._t("header")],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "vel-modal__body" }, [_vm._t("default")], 2),
          _vm.$slots.footer
            ? _c(
                "footer",
                { staticClass: "vel-modal__footer" },
                [_vm._t("footer")],
                2
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }