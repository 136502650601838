var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-card", { class: _vm.classes }, [
    _c(
      "div",
      { staticClass: "indicator" },
      [
        _c(
          "div",
          {
            staticClass: "indicator__icon",
            class: _vm.iconClasses,
            attrs: { "data-icon-name": _vm.iconName }
          },
          [
            _vm.iconName
              ? _c("vel-icon", {
                  attrs: { size: _vm.computedIconSize, name: _vm.iconName }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "indicator__content" }, [
          _c("span", { staticClass: "indicator__value" }, [_vm._t("value")], 2),
          _c("span", { staticClass: "indicator__percent" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  (
                    (_vm.amount &&
                      100 - (_vm.previousAmount / _vm.amount) * 100) ||
                    0
                  )
                    .toFixed(2)
                    .replace("-", "")
                ) + "%"
              )
            ]),
            _vm.amount !== 0 && (_vm.amount || 0) > (_vm.previousAmount || 0)
              ? _c(
                  "span",
                  { staticClass: "arrow up pl-1" },
                  [_c("font-awesome-icon", { attrs: { icon: "arrow-up" } })],
                  1
                )
              : _vm._e(),
            _vm.amount !== 0 && (_vm.amount || 0) < (_vm.previousAmount || 0)
              ? _c(
                  "span",
                  { staticClass: "arrow down pl-1" },
                  [_c("font-awesome-icon", { attrs: { icon: "arrow-down" } })],
                  1
                )
              : _vm._e()
          ])
        ]),
        _vm.$slots.title
          ? _c(
              _vm.titleLevel,
              { tag: "component", staticClass: "indicator__title" },
              [
                _vm._t("title"),
                _vm.$slots.tooltip
                  ? _c(
                      "span",
                      { staticClass: "indicator__tooltip" },
                      [
                        _c(
                          "tooltip",
                          {
                            attrs: {
                              content: _vm.$slots.tooltip[0].text,
                              placement: "top",
                              effect: "light",
                              "hide-after": _vm.isMediumDown ? 3000 : 0
                            }
                          },
                          [
                            _c("vel-icon", {
                              attrs: {
                                name: "question-circle",
                                modifier: "on-bright",
                                size: "small"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }