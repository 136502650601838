import { environment } from '@/configs';
import {useDataConfig} from "@/stores/data/config.module";

export const showDirective = (el, binding, vnode) => {
  const configStore = useDataConfig();
  const userConfig = configStore.get;
  el.id = binding.value;
  if (
    (userConfig[binding.value] && userConfig[binding.value].value === false) ||
    environment[binding.value] === false
  ) {
    el.style.display = 'none';
  } else {
    el.style.display = null;
  }
};
