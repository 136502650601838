var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "location",
      attrs: {
        enableToolbar: true,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        loading: _vm.isPageLoading
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm._v(" " + _vm._s(_vm.$t("pages.location-configuration")) + " ")
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _vm.isError
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "error" },
                  on: { dismiss: _vm.clearMessage }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("locationConfiguration.form.failure")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.isSuccess
            ? _c(
                "vel-alert",
                {
                  staticClass: "success",
                  attrs: { level: "success" },
                  on: { dismiss: _vm.clearMessage }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("locationConfiguration.form.success")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "vel-form",
            {
              ref: "ruleForm",
              attrs: {
                "label-position": "top",
                model: _vm.locationConfigForm,
                rules: _vm.locationConfigRules,
                disabled: _vm.loading,
                "label-width": "120px"
              }
            },
            [
              _c(
                "vel-form-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "locationConfiguration.form.labels.startDayOfWeek"
                    ),
                    prop: "startDayOfWeek"
                  }
                },
                [
                  _c(
                    "vel-select",
                    {
                      model: {
                        value: _vm.locationConfigForm.startDayOfWeek,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.locationConfigForm,
                            "startDayOfWeek",
                            $$v
                          )
                        },
                        expression: "locationConfigForm.startDayOfWeek"
                      }
                    },
                    _vm._l(_vm.dayOfWeek, function(day) {
                      return _c("vel-option", {
                        key: day,
                        attrs: {
                          label: _vm.$t(
                            "locationConfiguration.form.dayOfWeek." + day
                          ),
                          value: day
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "vel-form-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "locationConfiguration.form.labels.numberOfMenus"
                    ),
                    prop: "numberOfMenus"
                  }
                },
                [
                  _c("vel-input-number", {
                    attrs: { min: 1, max: 10 },
                    model: {
                      value: _vm.locationConfigForm.numberOfMenus,
                      callback: function($$v) {
                        _vm.$set(_vm.locationConfigForm, "numberOfMenus", $$v)
                      },
                      expression: "locationConfigForm.numberOfMenus"
                    }
                  })
                ],
                1
              ),
              _c(
                "vel-form-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "locationConfiguration.form.labels.endDayTime"
                    ),
                    prop: "endDayTime"
                  }
                },
                [
                  _c("vel-time-picker", {
                    attrs: {
                      "picker-options": {
                        selectableRange: "00:00:00 - 23:59:59"
                      }
                    },
                    model: {
                      value: _vm.locationConfigForm.endDayTime,
                      callback: function($$v) {
                        _vm.$set(_vm.locationConfigForm, "endDayTime", $$v)
                      },
                      expression: "locationConfigForm.endDayTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("locationConfiguration.form.submitButton.text")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }