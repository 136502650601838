var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      ref: "sidebarBigDepartmentForm",
      attrs: {
        "header-class": "bg-light",
        "bg-variant": "white",
        width: "600px",
        right: "",
        id: "bigDepartmentForm-sidebar",
        title: "Sidebar",
        shadow: "",
        backdrop: "",
        "no-close-on-backdrop": _vm.$refs.form && _vm.$refs.form.haveChanges,
        "no-close-on-esc": _vm.$refs.form && _vm.$refs.form.haveChanges
      },
      on: { hidden: _vm.onHide },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "h6",
                {
                  staticClass:
                    "bg-gray-200 bg-light w-100 mb-0 text-center py-2"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          _vm.bigDepartment && _vm.bigDepartment.id
                            ? "bigDepartments.form.edit.title"
                            : "bigDepartments.form.create.title"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2",
                  staticStyle: { "border-top": "2px solid #d6d6d8" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !(
                          _vm.$refs.form && _vm.$refs.form.enableSaveChanges
                        ),
                        variant: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateBigDepartment()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.bigDepartment && _vm.bigDepartment.id
                                ? "bigDepartments.form.edit.save"
                                : "bigDepartments.form.create.save"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function($event) {
                          return _vm.onCancel(hide)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("bigDepartments.form.cancel")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isSidebarVisible,
        callback: function($$v) {
          _vm.isSidebarVisible = $$v
        },
        expression: "isSidebarVisible"
      }
    },
    [
      _c(
        "b-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          staticClass: "py-4 px-0",
          staticStyle: { "background-color": "#f4f4f4" },
          attrs: { fluid: "" }
        },
        [
          _c("big-department-form", {
            ref: "form",
            attrs: {
              licenceNumber: _vm.licenceNumber,
              bigDepartment: _vm.bigDepartment
            },
            on: {
              "update:bigDepartment": function($event) {
                _vm.bigDepartment = $event
              },
              "update:big-department": function($event) {
                _vm.bigDepartment = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }