var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group-edit-modal" },
    [
      _c("vel-modal", {
        attrs: { dismissable: "" },
        on: { dismiss: _vm.handleCancel },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _vm._v(" " + _vm._s(_vm.$t("groups.modals.edit.title")) + " ")
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm.hasUpdateGroupError
                  ? _c(
                      "vel-alert",
                      {
                        staticClass: "alert",
                        attrs: { level: "error" },
                        on: { dismiss: _vm.clearUpdateGroupError }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("groups.errors.updateGroup")) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                _c("group-form", {
                  staticClass: "group-edit-modal__form",
                  attrs: { group: _vm.group },
                  on: { submit: _vm.handleSubmit, cancel: _vm.handleCancel }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }