var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-card", attrs: { shadowed: "" } },
    [
      _c("div", { staticClass: "login-header" }, [
        _c(
          "div",
          { staticClass: "logo-wrapper" },
          [_c("logo", { staticClass: "login-logo" })],
          1
        ),
        _c("h3", {
          staticClass: "login-title",
          domProps: { innerHTML: _vm._s(_vm.$t("login.form.title")) }
        })
      ]),
      _c(
        "vel-form",
        {
          ref: "formInline",
          staticClass: "form-login",
          attrs: { model: _vm.formInline, rules: _vm.ruleInline }
        },
        [
          _c(
            "vel-form-item",
            {
              staticClass: "form-group-email",
              attrs: {
                prop: "email",
                label: _vm.$t("login.form.emailInput.text")
              }
            },
            [
              _c(
                "vel-input",
                {
                  attrs: { type: "email", autofocus: "" },
                  nativeOn: {
                    keyup: function($event) {
                      return _vm.userStartedTyping.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.formInline.email,
                    callback: function($$v) {
                      _vm.$set(_vm.formInline, "email", $$v)
                    },
                    expression: "formInline.email"
                  }
                },
                [
                  _c("vel-icon", {
                    attrs: {
                      slot: "prepend",
                      name: "email-login",
                      size: "small"
                    },
                    slot: "prepend"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vel-form-item",
            {
              staticClass: "form-group-password",
              attrs: {
                prop: "password",
                label: _vm.$t("login.form.passwordInput.text")
              }
            },
            [
              _c(
                "vel-input",
                {
                  attrs: { type: "password" },
                  nativeOn: {
                    keyup: function($event) {
                      return _vm.userStartedTypingPassword.apply(
                        null,
                        arguments
                      )
                    }
                  },
                  model: {
                    value: _vm.formInline.password,
                    callback: function($$v) {
                      _vm.$set(_vm.formInline, "password", $$v)
                    },
                    expression: "formInline.password"
                  }
                },
                [
                  _c("vel-icon", {
                    attrs: {
                      slot: "prepend",
                      name: "password-login",
                      size: "small"
                    },
                    slot: "prepend"
                  })
                ],
                1
              )
            ],
            1
          ),
          !!_vm.error && !_vm.formInline.startedTyping
            ? _c(
                "vel-alert",
                {
                  staticClass: "error",
                  attrs: { dismissable: false, level: "error" }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("login.form.invalidLogin.text")) + " "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "vel-form-item",
            { staticClass: "form-group-login" },
            [
              _c(
                "vel-button",
                {
                  staticClass: "btn-login",
                  attrs: { type: "primary", loading: _vm.status.loggingIn },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleSubmit()
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("login.form.submitButton.text")) + " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "login__reset-password" },
            [
              _c(
                "vel-link",
                {
                  staticClass: "vel-link__link",
                  attrs: {
                    to: { path: "password-reset" },
                    type: "link",
                    target: "_self"
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("login.form.forgotPassword.text")) + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }