<template>
  <vel-backoffice-page
    class="backoffice-page"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    mode="edit"
    :loading="loading"
  >
    <template v-slot:footer>
      <item-profile-footer
        :item-profile="itemProfile"
        :pending="pending"
        :canSave="canSave"
        @onSave="updateItemProfile()"
        @onCancel="handleCancel(false)"
      ></item-profile-footer>
    </template>
    <template v-slot:header>
      <item-profile-header :item-profile.sync="itemProfile"></item-profile-header>
    </template>

    <h5 class="pt-4 px-4 pb-2">{{ $t('itemProfile.edit.configuration') }}</h5>

    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-md-2">
        <b-form-group
          :label="$t('itemProfile.edit.fields.description.label')"
          :description="$t('itemProfile.required')"
          label-for="name"
          class="mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 pl-md-0"
        >
          <b-form-input
            id="name"
            :state="!submited ? null : itemProfile.name !== '' && null"
            v-model="itemProfile.name"
            maxlength="50"
            aria-describedby="input-live-help input-live-feedback"
            :class="{ required: !itemProfile.name || itemProfile.name === '' }"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('itemProfile.edit.fields.baseItem.label')"
          :description="$t('itemProfile.required')"
          label-for="name"
          class="mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2"
        >
          <b-form-select
            :options="[{ id: null, name: $t('itemProfile.emptyValue') }, ...items]"
            id="supplierId"
            value-field="itemId"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="itemProfile.baseItemId"
          ></b-form-select>
        </b-form-group>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('itemProfile.edit.parametersSettings') }}</h5>

    <b-container fluid class="content p-4">
      <div class="row mx-0 mb-lg-4">
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.category">
          {{ $t('itemProfile.edit.fields.category') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.remarksAndPopups">
          {{ $t('itemProfile.edit.fields.remarksAndPopups') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.options">
          {{ $t('itemProfile.edit.fields.options') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.forcedModes">
          {{ $t('itemProfile.edit.fields.forcedModes') }}
        </b-form-checkbox>
      </div>
      <div class="row mx-0 mb-lg-4">
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.itemType">
          {{ $t('itemProfile.edit.fields.itemType') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.links">
          {{ $t('itemProfile.edit.fields.links') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.availabilitySchedule">
          {{ $t('itemProfile.edit.fields.availabilitySchedule') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.copyChangedRegularPrices">
          {{ $t('itemProfile.edit.fields.copyChangedRegularPrices') }}
        </b-form-checkbox>
      </div>
      <div class="row mx-0 mb-lg-4">
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.price">
          {{ $t('itemProfile.edit.fields.price') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.conversions">
          {{ $t('itemProfile.edit.fields.conversions') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.delivery">
          {{ $t('itemProfile.edit.fields.delivery') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.holdAndFireDelay">
          {{ $t('itemProfile.edit.fields.holdAndFireDelay') }}
        </b-form-checkbox>
      </div>
      <div class="row mx-0">
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.printouts">
          {{ $t('itemProfile.edit.fields.printouts') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.counters">
          {{ $t('itemProfile.edit.fields.counters') }}
        </b-form-checkbox>
        <b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.taxes">
          {{ $t('itemProfile.edit.fields.taxes') }}
        </b-form-checkbox>
        <!--<b-form-checkbox class="ml-0 col-12 col-md-6 col-lg-2" v-model="itemProfile.kioskOptions">
          {{ $t('itemProfile.edit.fields.kioskOptions') }}
        </b-form-checkbox>-->
      </div>
    </b-container>
    <b-modal
      v-model="showDiscardChanges"
      :title="$t('discardChange.title')"
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
      @hidden="$emit('onCancel', true)"
    >
      {{ $t('discardChange.body') }}
      <template #modal-footer>
        <b-container fluid>
          <div class="w-100">
            <b-button variant="primary" class="float-right" @click="showDiscardChanges = false">
              {{ $t('discardChange.continue') }}
            </b-button>
            <b-button
              variant="link"
              class="float-right ml-2"
              @click="
                showDiscardChanges = false;
                handleCancel(true);
              "
            >
              {{ $t('discardChange.discard') }}
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
  </vel-backoffice-page>
</template>

<script>
import ItemProfileFooter from './components/ItemProfileFooter';
import ItemProfileHeader from './components/ItemProfileHeader';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import cloneDeep from 'lodash.clonedeep';
import { getToken } from '@/helpers/token.helper';
import itemsService from '@/services/items.service';
import locationService from '@/services/locations.service';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataLocationsStore} from "@/stores/data/locations.module";

export default {
  name: 'ItemProfileCreatePage',
  components: {
    VelBackofficePage,
    ItemProfileFooter,
    ItemProfileHeader
  },
  props: {},
  methods: {
    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    async updateItemProfile() {
      this.pending = true;
      this.submited = true;
      this.isError = false;
      try {
        if (this.itemProfile.id) {
          await itemsService.updateItemProfile(this.itemProfile.id, this.itemProfile);
          this.toast('toast.itemProfileUpdate', this.itemProfile);
        } else {
          await itemsService.postItemProfile({
            ...this.itemProfile
          });

          this.toast('toast.itemProfileCreate', this.itemProfile);
        }

        await this.$router.replace({ name: 'item-profile' });
      } catch (e) {
        this.isError = true;
      } finally {
        this.pending = false;
      }
      return null;
    },
    handleCancel(force = false) {
      if (JSON.stringify(this.itemProfileOrig) !== JSON.stringify(this.itemProfile) && !force) {
        this.showDiscardChanges = true;
      } else {
        this.$router.push({ name: 'item-profile' });
      }
    }
  },
  data() {
    return {
      loading: true,
      isError: false,
      pending: false,
      locationId: null,
      items: [],
      showDiscardChanges: false,
      itemProfileOrig: {
        name: null,
        licenceNumber: null,
        baseItemId: null,
        category: true,
        itemType: true,
        price: true,
        printouts: true,
        remarksAndPopups: true,
        links: true,
        conversions: true,
        counters: true,
        options: true,
        availabilitySchedule: true,
        delivery: true,
        taxes: true,
        forcedModes: true,
        copyChangedRegularPrices: true,
        holdAndFireDelay: true,
        kioskOptions: true
      },
      itemProfile: {
        name: null,
        licenceNumber: null,
        baseItemId: null,
        category: true,
        itemType: true,
        price: true,
        printouts: true,
        remarksAndPopups: true,
        links: true,
        conversions: true,
        counters: true,
        options: true,
        availabilitySchedule: true,
        delivery: true,
        taxes: true,
        forcedModes: true,
        copyChangedRegularPrices: true,
        holdAndFireDelay: true,
        kioskOptions: true
      },
      submited: false
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),

    canSave() {
      if (!this.itemProfile.name || this.itemProfile.name === '') {
        return false;
      }
      return JSON.stringify(this.itemProfileOrig) !== JSON.stringify(this.itemProfile);
    }
  },
  async mounted() {
    try {
      if (this.$route.params.id) {
        this.itemProfile = await itemsService.getItemProfileById(getToken(), this.$route.params.id);
      } else {
        this.itemProfile.licenceNumber = (
          this.getLocationsById(this.$route.query.locationIds) ||
          (await locationService.getLocation(getToken(), this.$route.query.locationIds))
        ).licenceNumber;
      }

      this.items = await itemsService.getItems(getToken(), { locationId: this.$route.query.locationIds });

      this.itemProfileOrig = cloneDeep(this.itemProfile);
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.isError = true;
      this.loading = false;
    }
    return true;
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

h5,
.backoffice-page {
  background-color: #f5f5f5;
}

.content {
  background-color: white;
}

/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

input,
select {
  + .form-text + .invalid-feedback {
    visibility: hidden;
    display: none !important;
  }
}

input,
select {
  + .form-text {
    display: block !important;
  }
}

input[aria-invalid='true'],
select[aria-invalid='true'] {
  + .form-text + .invalid-feedback {
    visibility: visible;
    display: block !important;
  }
}

input[aria-invalid='true'],
select[aria-invalid='true'] {
  + .form-text {
    display: none !important;
  }
}

.invalid-feedback {
  display: block !important;
}

.vel-alert {
  align-self: center;
  max-width: fit-content;
}

*:focus {
  box-shadow: none !important;
  outline: none !important;
}
</style>
