var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VelPage",
    _vm._b(
      {
        staticClass: "dashboard",
        attrs: {
          "multiple-locations": _vm.locations.length > 1,
          "show-selector-button": _vm.locations.length > 1,
          "show-selector-single-location-button": false
        },
        scopedSlots: _vm._u([
          {
            key: "customizer",
            fn: function() {
              return [_c("ConfigDashboardDisplay")]
            },
            proxy: true
          }
        ])
      },
      "VelPage",
      _vm.pageAttrs,
      false
    ),
    [
      _c("Indicators", {
        key: _vm.updateIndicators,
        attrs: { "disable-range": true, "show-icon": false, secondary: "" }
      }),
      _c("DashboardCharts", { key: _vm.updateCharts })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }