var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _c(
      "div",
      { staticClass: "filters__fields" },
      [
        _vm.enabledFilters.includes("check")
          ? _c(
              "b-form-group",
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("checkSummary.filters.check.label")))
                ]),
                _c(
                  "b-input-group",
                  [
                    _c("b-input-group-prepend", [
                      _c(
                        "span",
                        { staticClass: "input-group-text" },
                        [_c("b-icon", { attrs: { icon: "search" } })],
                        1
                      )
                    ]),
                    _c("b-form-input", {
                      attrs: {
                        disabled: _vm.disabled,
                        state: _vm.invoiceNumberValueValid ? null : false,
                        size: "sm",
                        placeholder: _vm.$t(
                          "checkSummary.filters.check.placeholder"
                        )
                      },
                      model: {
                        value: _vm.invoiceNumberValue,
                        callback: function($$v) {
                          _vm.invoiceNumberValue = $$v
                        },
                        expression: "invoiceNumberValue"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.enabledFilters.includes("tender")
          ? _c(
              "b-form-group",
              [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("checkSummary.filters.tenderTypes.label"))
                  )
                ]),
                _c("vel-multiple-selector", {
                  attrs: {
                    "value-options": this.filtersValuesArray.tenderTypes,
                    multiple: true,
                    filterable: true,
                    disabled: _vm.disabled,
                    "all-results-label": "checkSummary.filters.tenderTypes.all",
                    "multiples-results-label":
                      "checkSummary.filters.tenderTypes.filtered"
                  },
                  model: {
                    value: _vm.selectedFilters.tenderTypes,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedFilters, "tenderTypes", $$v)
                    },
                    expression: "selectedFilters.tenderTypes"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.enabledFilters.includes("employee")
          ? _c(
              "b-form-group",
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("checkSummary.filters.employee.label")))
                ]),
                _c(
                  "b-input-group",
                  [
                    _c("b-input-group-prepend", [
                      _c(
                        "span",
                        { staticClass: "input-group-text" },
                        [_c("b-icon", { attrs: { icon: "search" } })],
                        1
                      )
                    ]),
                    _c("b-form-input", {
                      attrs: {
                        disabled: _vm.disabled,
                        size: "sm",
                        max: "10",
                        placeholder: _vm.$t(
                          "checkSummary.filters.employee.placeholder"
                        )
                      },
                      model: {
                        value: _vm.selectedFilters.employeeName,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedFilters, "employeeName", $$v)
                        },
                        expression: "selectedFilters.employeeName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.enabledFilters.includes("status")
          ? _c(
              "b-form-group",
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("checkSummary.filters.status.label")))
                ]),
                _c("vel-multiple-selector", {
                  attrs: {
                    "value-options": this.statusList,
                    multiple: false,
                    filterable: false,
                    disabled: _vm.disabled || _vm.loading,
                    "all-results-label": "checkSummary.filters.status.all",
                    "multiples-results-label":
                      "checkSummary.filters.status.filtered"
                  },
                  model: {
                    value: _vm.selectedFilters.status,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedFilters, "status", $$v)
                    },
                    expression: "selectedFilters.status"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticStyle: { display: "flex", "align-items": "end" } }, [
          _c(
            "div",
            [
              _c(
                "b-button",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                  attrs: { variant: "secondary", disabled: _vm.disabled },
                  on: {
                    click: function($event) {
                      _vm.showFiltersPopup = true
                    }
                  }
                },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: "plus-circle",
                      "aria-hidden": "true",
                      scale: "1.4"
                    }
                  }),
                  _c("span", { staticClass: "d-none d-md-block ml-2" }, [
                    _vm._v(_vm._s(_vm.$t("checkSummary.filters.add")))
                  ])
                ],
                1
              ),
              _vm.showFiltersPopup
                ? _c("VelListPopup", {
                    attrs: { "value-options": _vm.filtersOptions },
                    on: {
                      close: function($event) {
                        _vm.showFiltersPopup = false
                      }
                    },
                    model: {
                      value: _vm.enabledFilters,
                      callback: function($$v) {
                        _vm.enabledFilters = $$v
                      },
                      expression: "enabledFilters"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "filters__buttons" },
      [
        _c(
          "b-button",
          {
            attrs: { variant: "primary", disabled: _vm.disabled },
            on: { click: _vm.applyFilters }
          },
          [_vm._v(" " + _vm._s(_vm.$t("checkSummary.filters.apply")) + " ")]
        ),
        _c(
          "b-button",
          {
            attrs: { variant: "secondary", disabled: _vm.disabled },
            on: { click: _vm.clearFilters }
          },
          [_vm._v(" " + _vm._s(_vm.$t("checkSummary.filters.clear")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }