var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    { attrs: { "show-crumbs": false } },
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("reportsMenuPage.title")))
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "vel-card",
            { attrs: { shadowed: "" } },
            [
              _c("template", { slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("reportsMenuPage.transaction.title")))
              ]),
              _vm._l(_vm.links.transaction, function(link) {
                return _c(
                  "router-link",
                  { key: link.name, attrs: { to: { name: link.name } } },
                  [_vm._v(" " + _vm._s(_vm.$t(link.text)) + " ")]
                )
              })
            ],
            2
          ),
          _c(
            "vel-card",
            { attrs: { shadowed: "" } },
            [
              _c("template", { slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("reportsMenuPage.financial.title")))
              ]),
              _vm._l(_vm.links.financial, function(link) {
                return _c(
                  "router-link",
                  { key: link.name, attrs: { to: { name: link.name } } },
                  [_vm._v(" " + _vm._s(_vm.$t(link.text)) + " ")]
                )
              })
            ],
            2
          ),
          _c(
            "vel-card",
            { attrs: { shadowed: "" } },
            [
              _c("template", { slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("reportsMenuPage.check.title")))
              ]),
              _vm._l(_vm.links.check, function(link) {
                return _c(
                  "router-link",
                  { key: link.name, attrs: { to: { name: link.name } } },
                  [_vm._v(" " + _vm._s(_vm.$t(link.text)) + " ")]
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }