var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      staticClass: "p-0",
      attrs: { href: "#", tabindex: "0", id: _vm.id, variant: "link" },
      on: { click: _vm.handleToggle }
    },
    [
      _c("b-icon", {
        staticClass: "row-action",
        staticStyle: { fill: "#00a9e1" },
        attrs: { icon: _vm.isToggled ? "x" : "three-dots-vertical" }
      }),
      _c(
        "b-tooltip",
        {
          attrs: {
            show: _vm.isToggled,
            triggers: "click blur",
            placement: "bottom",
            target: _vm.id
          },
          on: {
            "update:show": function($event) {
              _vm.isToggled = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "tooltip-wrapper" },
            [
              _c("b-button", { on: { click: _vm.handleDelete } }, [
                _vm._v(" " + _vm._s(_vm.$t("defaults.actions.delete")) + " ")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }