var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "bootstrap px-2",
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "px-3 py-1 row" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "col-md-6 d-flex align-items-center",
                      attrs: { to: { name: "reports-menu" } }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("checkDetails.backLinkReport")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-6 d-flex justify-content-end align-items-center"
                    },
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "d-none d-md-block" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("employee.list.rowsPerPage")) +
                              " "
                          ),
                          _c("b-form-select", {
                            staticClass: "mx-3",
                            staticStyle: { width: "80px" },
                            attrs: {
                              size: "sm",
                              options: [10, 25, 50, 100],
                              disabled:
                                _vm.isPending ||
                                _vm.generating ||
                                !_vm.getInvoices.length
                            },
                            on: { change: _vm.fetchData },
                            model: {
                              value: _vm.perPage,
                              callback: function($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.getInvoices.length
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "d-none d-md-flex align-self-center mr-6"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pagination.format", {
                                      first:
                                        _vm.getCurrentPage * _vm.getPerPage -
                                        _vm.getPerPage +
                                        1,
                                      last: Math.min(
                                        _vm.getCurrentPage * _vm.getPerPage,
                                        _vm.getTotalResults
                                      ),
                                      total: _vm.getTotalResults
                                    })
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("b-pagination", {
                        staticClass: "m-0 ml-2",
                        attrs: {
                          size: "sm",
                          pills: "",
                          "per-page": _vm.getPerPage,
                          "total-rows": _vm.getTotalResults,
                          limit: 5,
                          align: "right",
                          disabled:
                            _vm.isPending ||
                            _vm.generating ||
                            !_vm.getInvoices.length
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticStyle: { "border-bottom": "8px solid rgb(245, 245, 245)" } },
          [
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-between",
                staticStyle: {
                  "border-bottom": "1px solid rgba(76, 86, 103, 0.2)",
                  padding: "0.8rem 1.5rem"
                }
              },
              [
                _c(
                  "h1",
                  {
                    staticClass: "title-right my-0",
                    staticStyle: { "font-size": "1.5rem" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("checkSummary.title")) + " ")]
                ),
                Object.values(_vm.getInvoices).length
                  ? _c(
                      "vel-button",
                      {
                        staticClass: "vel-button",
                        attrs: {
                          loading: _vm.generating,
                          disabled:
                            _vm.isPending ||
                            !Object.values(_vm.getInvoices).length,
                          type: "primary",
                          icon: _vm.isMediumUp ? "file-excel" : undefined
                        },
                        on: { click: _vm.onExportXLS }
                      },
                      [
                        _vm.isMediumDown
                          ? _c("vel-icon", { attrs: { name: "file-download" } })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isMediumUp
                                ? _vm.$t("table.tableExport.button.value")
                                : ""
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "p-2 px-4" },
        [
          _c("toolbar-selectors", {
            ref: "toolbarSelector",
            staticClass: "toolbar",
            attrs: {
              "enable-date-compare": false,
              "enable-date-range": true,
              "enable-currency": false,
              "enable-date-picker": false,
              "enable-group": true,
              "enable-all-locations": true,
              "enable-button": true,
              loading: _vm.isPending,
              "multiple-locations": true
            }
          })
        ],
        1
      ),
      _c(
        "b-container",
        {
          staticClass: "h-100 px-4 mb-4 mt-2",
          attrs: { fluid: "", id: "table-container" }
        },
        [
          _vm.isPending && _vm.newLocationRangeGeneration
            ? _c(
                "div",
                { staticClass: "text-center" },
                [_c("b-spinner", { attrs: { variant: "primary" } })],
                1
              )
            : !_vm.newLocationRangeGeneration
            ? _c("vel-card", [
                _c(
                  "div",
                  { staticClass: "report" },
                  [
                    _c("div", { staticClass: "filters_toggle_container" }, [
                      _c(
                        "span",
                        {
                          staticClass: "filters_toggle",
                          on: {
                            click: function($event) {
                              _vm.showFilters = !_vm.showFilters
                            }
                          }
                        },
                        [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.showFilters
                                    ? _vm.$t("checkSummary.filters.hide")
                                    : _vm.$t("checkSummary.filters.show")
                                ) +
                                " "
                            )
                          ]),
                          _c("b-icon", { attrs: { icon: "chevron-down" } })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "transition",
                      { attrs: { name: "fade" } },
                      [
                        _vm.showFilters
                          ? _c("CheckSummaryFilters", {
                              attrs: { disabled: _vm.isPending },
                              on: { filtersChanged: _vm.fetchData }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.isPending || _vm.getInvoices.length > 0
                      ? _c("b-table", {
                          ref: "table",
                          attrs: {
                            "primary-key": "id",
                            busy: _vm.isPending,
                            items: _vm.getInvoices,
                            fields: _vm.columns,
                            responsive: "sm",
                            "sticky-header": "",
                            "no-local-sorting": true,
                            "tbody-tr-class": _vm.rowClass,
                            "sort-by": _vm.sortBy,
                            "sort-desc": _vm.sortDesc
                          },
                          on: {
                            "sort-changed": _vm.sortingChanged,
                            "row-clicked": _vm.onRowSelected
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "table-busy",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center text-danger my-2"
                                      },
                                      [
                                        _c("b-spinner", {
                                          staticClass: "align-middle p-2 m-4",
                                          attrs: { variant: "primary" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head()",
                                fn: function(scope) {
                                  return [
                                    _c("div", { staticClass: "container" }, [
                                      _c("div", [
                                        _vm._v(_vm._s(scope.field.label))
                                      ]),
                                      scope.field.sortable || false
                                        ? _c(
                                            "div",
                                            { staticClass: "sort-icon" },
                                            [
                                              _c("b-icon", {
                                                class:
                                                  "vel-table-header__sort" +
                                                  (_vm.getSortBy ===
                                                  scope.field.filterName
                                                    ? _vm.getSortDirection ===
                                                      "desc"
                                                      ? "-icon_down"
                                                      : "-icon_up"
                                                    : ""),
                                                attrs: { icon: "arrow-down" }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                }
                              },
                              {
                                key: "cell(invoiceTime)",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatDateTime(
                                            scope.item.invoiceTime
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(tableNumber)",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.item.tableNumber || "--") +
                                        " "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(customers)",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.item.customers || "--") +
                                        " "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(total)",
                                fn: function(scope) {
                                  return [
                                    _c("vel-amount", {
                                      attrs: {
                                        currency: scope.item.currency,
                                        amount: scope.item.total
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "cell(totalTaxAmount)",
                                fn: function(scope) {
                                  return [
                                    _c("vel-amount", {
                                      attrs: {
                                        currency: scope.item.currency,
                                        amount: scope.item.totalTaxAmount
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "cell(discounts)",
                                fn: function(scope) {
                                  return [
                                    _c("vel-amount", {
                                      attrs: {
                                        currency: scope.item.currency,
                                        amount:
                                          scope.item.discount +
                                          scope.item.cashDiscount
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "cell(services)",
                                fn: function(scope) {
                                  return [
                                    _c("vel-amount", {
                                      attrs: {
                                        currency: scope.item.currency,
                                        amount: scope.item.tips
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "cell(tenderName)",
                                fn: function(scope) {
                                  return _vm._l(scope.item.payments, function(
                                    tenderType,
                                    index
                                  ) {
                                    return _c("p", { key: index }, [
                                      _vm._v(_vm._s(tenderType.paymodeName))
                                    ])
                                  })
                                }
                              },
                              _vm.getHaveSurcharge
                                ? {
                                    key: "cell(tenderSurcharge)",
                                    fn: function(scope) {
                                      return _vm._l(
                                        scope.item.payments,
                                        function(tenderType, index) {
                                          return _c(
                                            "p",
                                            { key: index },
                                            [
                                              _c("vel-amount", {
                                                attrs: {
                                                  currency: scope.item.currency,
                                                  amount: tenderType.surcharge
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    }
                                  }
                                : null,
                              {
                                key: "cell(tenderAmount)",
                                fn: function(scope) {
                                  return _vm._l(scope.item.payments, function(
                                    tenderType,
                                    index
                                  ) {
                                    return _c(
                                      "p",
                                      { key: index },
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            currency: scope.item.currency,
                                            amount: tenderType.amount
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                }
                              },
                              {
                                key: "cell(tenderTip)",
                                fn: function(scope) {
                                  return _vm._l(scope.item.payments, function(
                                    tenderType,
                                    index
                                  ) {
                                    return _c(
                                      "p",
                                      { key: index },
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            currency: scope.item.currency,
                                            amount: tenderType.transferredTip
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                }
                              },
                              {
                                key: "cell(action)",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "checkDetails",
                                            params: { id: scope.item.id }
                                          }
                                        }
                                      },
                                      [_c("Action", { staticClass: "action" })],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    !_vm.isPending &&
                    _vm.getInvoices &&
                    _vm.getInvoices.length === 0
                      ? _c("vel-no-results")
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }