var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "d-flex", staticStyle: { "max-width": "640px" } },
        [
          _c(
            "b-col",
            { attrs: { sm: "8" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-4 w-100",
                  attrs: {
                    label: _vm.$t(
                      "employeeCreate.availability.timeOff.form.description"
                    ),
                    description: _vm.$t("employeeCreate.required"),
                    "label-for": "address"
                  }
                },
                [
                  _c("b-form-input", {
                    class: {
                      required:
                        !_vm.form.description || _vm.form.description === ""
                    },
                    attrs: {
                      placeholder: _vm.$t(
                        "employeeCreate.availability.timeOff.form.descriptionPlaceholder"
                      ),
                      id: "name"
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "d-flex", staticStyle: { "max-width": "640px" } },
        [
          _c(
            "b-col",
            { attrs: { sm: "8" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-4 w-100",
                  attrs: {
                    label: _vm.$t(
                      "employeeCreate.availability.timeOff.form.startDate"
                    ),
                    "label-for": "address"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "datepicker" },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "99/99/9999",
                            expression: "'99/99/9999'"
                          }
                        ],
                        attrs: {
                          id: "example-input",
                          type: "text",
                          placeholder: _vm.$t("employeeCreate.datePlaceholder"),
                          value: _vm.startDate,
                          autocomplete: "off"
                        },
                        on: { change: _vm.setStartDate }
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              "button-only": "",
                              locale: _vm.$i18n.locale,
                              right: "",
                              "button-variant": "white",
                              "label-no-date-selected": _vm.$t(
                                "datePicker.noDate"
                              ),
                              "label-calendar": _vm.$t("datePicker.calendar"),
                              "label-close-button": _vm.$t("datePicker.close"),
                              "label-current-month": _vm.$t(
                                "datePicker.currentMonth"
                              ),
                              "label-help": _vm.$t("datePicker.help"),
                              "label-nav": _vm.$t("datePicker.nav"),
                              "label-next-decade": _vm.$t(
                                "datePicker.nextDecade"
                              ),
                              "label-next-month": _vm.$t(
                                "datePicker.nextMonth"
                              ),
                              "label-next-year": _vm.$t("datePicker.nextYear"),
                              "label-prev-decade": _vm.$t(
                                "datePicker.previousDecade"
                              ),
                              "label-prev-month": _vm.$t(
                                "datePicker.previousMonth"
                              ),
                              "label-prev-year": _vm.$t(
                                "datePicker.previousYear"
                              ),
                              "label-reset-button": _vm.$t("datePicker.reset"),
                              "label-today": _vm.$t("datePicker.today"),
                              "label-today-button": _vm.$t(
                                "datePicker.selectToday"
                              ),
                              "date-format-options": {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit"
                              },
                              "aria-controls": "example-input"
                            },
                            on: {
                              input: function($event) {
                                _vm.form.endDate = _vm.form.startDate
                              },
                              context: _vm.onSelectStartDate
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "button-content",
                                fn: function() {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "calendar-alt" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.form.startDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "startDate", $$v)
                              },
                              expression: "form.startDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "8" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-4 w-100",
                  attrs: {
                    label: _vm.$t(
                      "employeeCreate.availability.timeOff.form.endDate"
                    ),
                    "label-for": "address"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "datepicker" },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "99/99/9999",
                            expression: "'99/99/9999'"
                          }
                        ],
                        attrs: {
                          id: "example-input",
                          type: "text",
                          placeholder: _vm.$t("employeeCreate.datePlaceholder"),
                          value: _vm.endDate,
                          autocomplete: "off"
                        },
                        on: { change: _vm.setEndDate }
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              "button-only": "",
                              locale: _vm.$i18n.locale,
                              right: "",
                              "button-variant": "white",
                              "label-no-date-selected": _vm.$t(
                                "datePicker.noDate"
                              ),
                              "label-calendar": _vm.$t("datePicker.calendar"),
                              "label-close-button": _vm.$t("datePicker.close"),
                              "label-current-month": _vm.$t(
                                "datePicker.currentMonth"
                              ),
                              "label-help": _vm.$t("datePicker.help"),
                              "label-nav": _vm.$t("datePicker.nav"),
                              "label-next-decade": _vm.$t(
                                "datePicker.nextDecade"
                              ),
                              "label-next-month": _vm.$t(
                                "datePicker.nextMonth"
                              ),
                              "label-next-year": _vm.$t("datePicker.nextYear"),
                              "label-prev-decade": _vm.$t(
                                "datePicker.previousDecade"
                              ),
                              "label-prev-month": _vm.$t(
                                "datePicker.previousMonth"
                              ),
                              "label-prev-year": _vm.$t(
                                "datePicker.previousYear"
                              ),
                              "label-reset-button": _vm.$t("datePicker.reset"),
                              "label-today": _vm.$t("datePicker.today"),
                              "label-today-button": _vm.$t(
                                "datePicker.selectToday"
                              ),
                              "date-format-options": {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit"
                              },
                              min: new Date(
                                new Date(_vm.form.startDate).setDate(
                                  new Date(_vm.form.startDate).getDate() + 1
                                )
                              ),
                              "aria-controls": "example-input"
                            },
                            on: { context: _vm.onSelectEndDate },
                            scopedSlots: _vm._u([
                              {
                                key: "button-content",
                                fn: function() {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "calendar-alt" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.form.endDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "endDate", $$v)
                              },
                              expression: "form.endDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }