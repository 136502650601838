import { defineStore } from 'pinia';
import { useLocationStorageStore } from '@/stores/data/local-storage.store';
import {getUserSettings, putUserSettings} from '@/services/users.service';

export const useUserSettingsStore = defineStore('user-settings', {
  state: () => ({
    loading: false,
    settings: {
      enableMultiSelection: false
    }
  }),
  getters: {
    isLoading: state => !!state.loading,
    get(state) {
      return name => {
        return this.settings[name] === undefined ? { value: null } : this.settings[name]
      }
    }
  },
  actions: {
    async hydrate() {
      try {
        this.loading = true;
        const localStorage = useLocationStorageStore();

        const currentSettings = await getUserSettings({ userIDs: localStorage.user.id });

        this.settings = currentSettings;
      } finally {
        this.loading = false;
      }
    },
    async set(settingObject: object) {
      try {
        this.loading = true;
        const localStorage = useLocationStorageStore();

        const currentSettings = await getUserSettings({ userIDs: localStorage.user.id });

        const newSettings = { ...currentSettings, ...settingObject };

        await putUserSettings(localStorage.user.id, newSettings);

        this.settings = newSettings;
      } catch(e) {
        console.error('AAAAAAAAAA', e);
      } finally {
        this.loading = false;
      }
    }
  }
});
