<template>
  <vel-page class="veltest">
    <h1>{{ $t('veltest.mainTitle') }}</h1>
    <vel-select
      :autofocus="true"
      :disabled="false"
      :filterable="true"
      no-data-text="noData"
      no-match-text="noMatch"
      placeholder="Choisir email"
      :value="defaultValue"
      class="vel-select"
      @change="handleChange"
    >
      <vel-option-group v-if="getSortedRows().length > 0" label="Choisir email">
        <vel-option
          v-for="option in getSortedRows()"
          :label="option.email"
          :value="option.id"
          :key="option.id"
        ></vel-option>
      </vel-option-group>
    </vel-select>

    <h1>{{ $t('veltest.subTitle') }}</h1>

    <h3>{{ $t('veltest.groupTitle') }}</h3>
    <div>
      <p v-if="!groupsPermissions.length">--</p>
      <div>
        <h5 v-for="l in groupsPermissions" :key="l.id">{{ l.group.name }}</h5>
      </div>
    </div>

    <h3>{{ $t('veltest.locationTitle') }}</h3>
    <p v-if="!locationsPermissions.length">--</p>
    <div>
      <h5 v-for="l in locationsPermissions" :key="l.id">{{ l.location.name }}</h5>
    </div>
  </vel-page>
</template>

<script>
import VelOption from '@/components/x-option/VelOption';
import VelOptionGroup from '@/components/x-option-group/VelOptionGroup';
import VelPage from '@/components/page/VelPage';
import VelSelect from '@/components/x-select/VelSelect';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import {transformPermissions, useDataUsersUser} from "@/stores/data/users/user.module";
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataUsers} from "@/stores/data/users.module";

export default {
  name: 'private-page',
  components: {
    VelPage,
    VelSelect,
    VelOption,
    VelOptionGroup
  },
  methods: {
    ...mapPiniaActions(useDataUsers, ['getUsers']),
    ...mapPiniaActions(useDataUsersUser, ['getPermissions', 'updatePermissions']),
    getSortedRows() {
      return (this.getUsersRows || []).sort((a, b) => (a[this.defaultSort] < b[this.defaultSort] ? -1 : 1));
    },
    handleChange(userId) {
      usersService.getUser(getToken(), userId).then(
        () => {
          usersService.getUserPermissions(getToken(), { userId, include: ['location', 'group'] }).then(
            permissions => {
              this.defaultValue = userId;
              usersService
                .updateUserPermissions(
                  getToken(),
                  '11ea42a5-721f-fab3-9e0b-0242ac110005',
                  transformPermissions(permissions)
                )
                .then(() => {
                  this.getPermissions('11ea42a5-721f-fab3-9e0b-0242ac110005');
                });
            },
            error => {
              throw new Error(error);
            }
          );
        },
        error => {
          throw new Error(error);
        }
      );
    }
  },
  data() {
    return {
      defaultValue: ''
    };
  },
  computed: {
    ...mapPiniaState(useDataUsers, ['getUsersRows']),
    ...mapPiniaState(useDataUsersUser, [
      'arePermissionsBeingChanged',
      'globalPermissions',
      'groupsPermissions',
      'locationsPermissions',
      'user'
    ]),
    defaultSort() {
      return 'name';
    }
  },
  created() {
    this.getUsers();
    this.getPermissions('11ea42a5-721f-fab3-9e0b-0242ac110005');
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

h1 {
  margin-bottom: 1rem;
}

.vel-select {
  margin-bottom: 1rem;
}

div {
  margin-top: 1rem;
}
</style>
