var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    _vm._b(
      {
        staticClass: "report",
        attrs: {
          "multiple-locations":
            _vm.locationsList && _vm.locationsList.length > 1,
          "enable-all-currencies": true,
          "show-selector-button": true
        },
        scopedSlots: _vm._u([
          {
            key: "export",
            fn: function() {
              return [
                _c(
                  "vel-button",
                  {
                    staticClass: "vel-button",
                    attrs: {
                      disabled:
                        _vm.loading ||
                        (!_vm.versionIsValid() && !_vm.isMultiLocations),
                      loading: _vm.generating,
                      type: "primary",
                      icon: _vm.isMediumUp ? "file-excel" : undefined
                    },
                    on: { click: _vm.exportXLS }
                  },
                  [
                    _vm.isMediumDown
                      ? _c("vel-icon", { attrs: { name: "file-download" } })
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isMediumUp
                            ? _vm.$t("table.tableExport.button.value")
                            : ""
                        ) +
                        " "
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "toolbar-options",
            fn: function() {
              return [
                (!_vm.locationCount || _vm.locationCount > 1) &&
                _vm.getCurrencySelectorSelected !== ""
                  ? _c(
                      "div",
                      { staticStyle: { padding: "8px 1rem 0 1rem" } },
                      [
                        _c(
                          "vel-checkbox",
                          {
                            on: {
                              change: function($event) {
                                return _vm.onConsolidate()
                              }
                            },
                            model: {
                              value: _vm.isConsolidated,
                              callback: function($$v) {
                                _vm.isConsolidated = $$v
                              },
                              expression: "isConsolidated"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("toolbar.selectors.consolidate")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      },
      "vel-page",
      _vm.pageAttrs,
      false
    ),
    [
      _vm.loading ? _c("vel-spinner", { staticClass: "spinner" }) : _vm._e(),
      _vm.invalidLocations && !_vm.loading
        ? _c(
            "div",
            {
              staticStyle: {
                "background-color": "#fff2cc",
                "margin-bottom": "1rem",
                padding: "1rem"
              }
            },
            [
              _vm.getLocationSelectorSelected
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invalidVersions.requiredVersion")) +
                        " "
                    )
                  ]
                : [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "invalidVersions.requiredVersionMultiLocations"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript://" },
                        on: {
                          click: function($event) {
                            _vm.showLocationsList = !_vm.showLocationsList
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("invalidVersions.collapse")))]
                    ),
                    _vm.showLocationsList
                      ? _c(
                          "ul",
                          { staticStyle: { "margin-top": "1rem" } },
                          _vm._l(_vm.invalidLocations, function(location) {
                            return _c("li", { key: location.id }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    location.reportingDisplayName ||
                                      location.name
                                  ) +
                                  " "
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm.showNotification && !_vm.isVersionLoading
        ? _c(
            "p",
            {
              staticStyle: {
                "background-color": "#ffd2d2",
                "margin-bottom": "1rem",
                padding: "1rem"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("invalidVersions.notice")) + " ")]
          )
        : _vm._e(),
      !_vm.loading
        ? [
            !Object.values(_vm.voids.data).filter(function(l) {
              return _vm.versionIsValid(l.location.id)
            }).length
              ? _c("div", { staticClass: "no-data" }, [
                  _vm._v(" " + _vm._s(_vm.$t("itemSalesCategory.noData")) + " ")
                ])
              : [
                  _c("vel-card", [
                    _c("h5", [_vm._v(_vm._s(_vm.$t("audit.sales.title")))]),
                    _c("table", [
                      _c("thead", [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticStyle: { width: "260px" },
                              attrs: { rowspan: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("audit.sales.table.headers.location")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: { width: "120px" },
                              attrs: { rowspan: "2" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("audit.sales.table.headers.date"))
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { width: "165px" },
                              attrs: { rowspan: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("audit.sales.table.headers.netSales")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              style:
                                "width: " +
                                (Object.keys(_vm.voids.taxes).length || 1) *
                                  165 +
                                "px",
                              attrs: {
                                colspan: Object.keys(_vm.voids.taxes).length
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("audit.sales.table.headers.taxes")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { width: "175px" },
                              attrs: { rowspan: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "audit.sales.table.headers.discounts"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _vm.haveCashDiscounts
                            ? _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "175px" },
                                  attrs: { rowspan: "2" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "audit.sales.table.headers.discountsCash"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.haveSurcharge
                            ? _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "175px" },
                                  attrs: { rowspan: "2" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "audit.sales.table.headers.surcharge"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { width: "175px" },
                              attrs: { rowspan: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "audit.sales.table.headers.grossSales"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { width: "180px" },
                              attrs: { rowspan: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "audit.sales.table.headers.transaction"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { width: "175px" },
                              attrs: { rowspan: 2 }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("audit.sales.table.headers.average")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("th")
                        ]),
                        _c(
                          "tr",
                          _vm._l(
                            Object.keys(_vm.voids.taxes).sort(function(a, b) {
                              return a.localeCompare(b)
                            }),
                            function(taxe) {
                              return _c(
                                "th",
                                { key: taxe, staticClass: "text-right" },
                                [_vm._v(" " + _vm._s(taxe) + " ")]
                              )
                            }
                          ),
                          0
                        )
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            Object.values(_vm.voids.data)
                              .filter(function(l) {
                                return _vm.versionIsValid(l.location.id)
                              })
                              .sort(function(a, b) {
                                return (
                                  a.location.reportingDisplayName ||
                                  a.location.name
                                ).localeCompare(
                                  b.location.reportingDisplayName ||
                                    b.location.name
                                )
                              }),
                            function(location, locationId) {
                              return [
                                _vm._l(location.dailySales, function(l, i) {
                                  return [
                                    _vm.expand.sales[locationId] ||
                                    !_vm.isMultiLocations ||
                                    !_vm.isMultiDays
                                      ? _c(
                                          "tr",
                                          { key: locationId + i },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "location",
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggle(
                                                      locationId,
                                                      "sales"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                !i
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _vm.isMultiDays &&
                                                        _vm.isMultiLocations
                                                          ? _c("vel-icon", {
                                                              staticStyle: {
                                                                height: "12px",
                                                                width: "8px"
                                                              },
                                                              attrs: {
                                                                name:
                                                                  "bottom-arrow"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm.isConsolidatable &&
                                                        (!_vm.locationCount ||
                                                          _vm.locationCount > 1)
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    !_vm.locationCount
                                                                      ? _vm.$t(
                                                                          "toolbar.selectors.consolidate"
                                                                        ) +
                                                                          " - " +
                                                                          _vm.$t(
                                                                            "dashboard.locationSelector.allLocationsText"
                                                                          )
                                                                      : _vm.$t(
                                                                          "dashboard.locationSelector.locationsSelected",
                                                                          {
                                                                            count:
                                                                              _vm.locationCount,
                                                                            total:
                                                                              _vm
                                                                                .locationsBySelectedGroup
                                                                                .length
                                                                          }
                                                                        )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  location
                                                                    .location
                                                                    .reportingDisplayName ||
                                                                    location
                                                                      .location
                                                                      .name
                                                                )
                                                              )
                                                            ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c("td", { staticClass: "date" }, [
                                              _c("span", [
                                                _vm._v(_vm._s(l.date))
                                              ])
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("vel-amount", {
                                                  attrs: {
                                                    amount:
                                                      l.sales.indicators
                                                        .netSalesAmount,
                                                    currency:
                                                      location.location
                                                        .detailedCurrency
                                                        .currencySymbol
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            !Object.keys(_vm.voids.taxes).length
                                              ? _c("td", {
                                                  staticClass: "text-right"
                                                })
                                              : _vm._e(),
                                            _vm._l(
                                              Object.keys(_vm.voids.taxes).sort(
                                                function(a, b) {
                                                  return a.localeCompare(b)
                                                }
                                              ),
                                              function(taxe) {
                                                return _c(
                                                  "td",
                                                  {
                                                    key: taxe,
                                                    staticClass: "text-right"
                                                  },
                                                  [
                                                    _c("vel-amount", {
                                                      attrs: {
                                                        amount:
                                                          (l.sales.taxesSales[
                                                            taxe
                                                          ] &&
                                                            l.sales.taxesSales[
                                                              taxe
                                                            ].taxesAmount) ||
                                                          0,
                                                        currency:
                                                          location.location
                                                            .detailedCurrency
                                                            .currencySymbol
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("vel-amount", {
                                                  attrs: {
                                                    amount:
                                                      l.sales.indicators
                                                        .discountsAmount,
                                                    currency:
                                                      location.location
                                                        .detailedCurrency
                                                        .currencySymbol
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm.haveCashDiscounts
                                              ? _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c("vel-amount", {
                                                      attrs: {
                                                        amount:
                                                          l.sales.indicators
                                                            .cashDiscountAmount,
                                                        currency:
                                                          location.location
                                                            .detailedCurrency
                                                            .currencySymbol
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.haveSurcharge
                                              ? _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c("vel-amount", {
                                                      attrs: {
                                                        amount:
                                                          l.sales.indicators
                                                            .surchargeAmount,
                                                        currency:
                                                          location.location
                                                            .detailedCurrency
                                                            .currencySymbol
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("vel-amount", {
                                                  attrs: {
                                                    amount:
                                                      l.sales.indicators
                                                        .grossSalesAmount,
                                                    currency:
                                                      location.location
                                                        .detailedCurrency
                                                        .currencySymbol
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("vel-quantity", {
                                                  attrs: {
                                                    qty:
                                                      l.sales.indicators
                                                        .customers
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("vel-amount", {
                                                  attrs: {
                                                    amount:
                                                      l.sales.indicators
                                                        .grossSalesAmount /
                                                      l.sales.indicators
                                                        .customers,
                                                    currency:
                                                      location.location
                                                        .detailedCurrency
                                                        .currencySymbol
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                }),
                                _vm.isMultiDays
                                  ? [
                                      _c(
                                        "tr",
                                        {
                                          key: locationId,
                                          class: {
                                            total:
                                              _vm.expand.sales[locationId] ||
                                              !_vm.isMultiLocations
                                          }
                                        },
                                        [
                                          _vm.isMultiLocations ||
                                          _vm.isMultiDays
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "location",
                                                  class: {
                                                    noBorder:
                                                      _vm.expand.sales[
                                                        locationId
                                                      ]
                                                  },
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(
                                                        locationId,
                                                        "sales"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !_vm.expand.sales[
                                                    locationId
                                                  ] && _vm.isMultiLocations
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("vel-icon", {
                                                            staticStyle: {
                                                              height: "8px",
                                                              position:
                                                                "relative",
                                                              top: "-2px",
                                                              width: "8px"
                                                            },
                                                            attrs: {
                                                              name: _vm.expand
                                                                .sales[
                                                                locationId
                                                              ]
                                                                ? "bottom-arrow"
                                                                : "right-arrow"
                                                            }
                                                          }),
                                                          _vm.isConsolidatable &&
                                                          (!_vm.locationCount ||
                                                            _vm.locationCount >
                                                              1)
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.locationCount
                                                                        ? _vm.$t(
                                                                            "toolbar.selectors.consolidate"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.$t(
                                                                              "dashboard.locationSelector.allLocationsText"
                                                                            )
                                                                        : _vm.$t(
                                                                            "dashboard.locationSelector.locationsSelected",
                                                                            {
                                                                              count:
                                                                                _vm.locationCount,
                                                                              total:
                                                                                _vm
                                                                                  .locationsBySelectedGroup
                                                                                  .length
                                                                            }
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location
                                                                      .location
                                                                      .reportingDisplayName ||
                                                                      location
                                                                        .location
                                                                        .name
                                                                  )
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("td", { staticClass: "date" }, [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("audit.sales.total")
                                                )
                                              )
                                            ])
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        location.totalNetSales,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _vm._l(
                                            Object.keys(_vm.voids.taxes).sort(
                                              function(a, b) {
                                                return a.localeCompare(b)
                                              }
                                            ),
                                            function(taxe) {
                                              return _c(
                                                "td",
                                                {
                                                  key: taxe,
                                                  staticClass: "text-right"
                                                },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalTaxes[
                                                              taxe
                                                            ] || 0,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        location.totalDiscounts,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _vm.haveCashDiscounts
                                            ? _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalCashDiscounts,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.haveSurcharge
                                            ? _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalSurcharge,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        location.totalGrossSales,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty:
                                                        location.totalTransaction
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        location.totalGrossSales /
                                                        location.totalTransaction,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            }
                          )
                        ],
                        2
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-container" },
                    [
                      _c("vel-card", { staticClass: "card-takeout" }, [
                        _c("h5", [
                          _vm._v(_vm._s(_vm.$t("audit.takeout.title")))
                        ]),
                        _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticStyle: { width: "260px" },
                                  attrs: { rowspan: "2" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "audit.takeout.table.headers.location"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticStyle: { width: "120px" },
                                  attrs: { rowspan: "2" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "audit.takeout.table.headers.date"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { width: "260px" },
                                  attrs: { colspan: "2" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "audit.takeout.table.headers.delivery"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { width: "260px" },
                                  attrs: { colspan: "2" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "audit.takeout.table.headers.takeout"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("th")
                            ]),
                            _c("tr", [
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "audit.takeout.table.headers.count"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "audit.takeout.table.headers.amount"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "audit.takeout.table.headers.count"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "audit.takeout.table.headers.amount"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm._l(
                                Object.values(_vm.voids.data)
                                  .filter(function(l) {
                                    return _vm.versionIsValid(l.location.id)
                                  })
                                  .sort(function(a, b) {
                                    return (
                                      a.location.reportingDisplayName ||
                                      a.location.name
                                    ).localeCompare(
                                      b.location.reportingDisplayName ||
                                        b.location.name
                                    )
                                  }),
                                function(location, locationId) {
                                  return [
                                    _vm._l(location.dailySales, function(l, i) {
                                      return [
                                        _vm.expand.zones[locationId] ||
                                        !_vm.isMultiLocations ||
                                        !_vm.isMultiDays
                                          ? _c("tr", { key: locationId + i }, [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "location",
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(
                                                        locationId,
                                                        "zones"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !i
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _vm.isMultiDays &&
                                                          _vm.isMultiLocations
                                                            ? _c("vel-icon", {
                                                                staticStyle: {
                                                                  height:
                                                                    "12px",
                                                                  width: "8px"
                                                                },
                                                                attrs: {
                                                                  name:
                                                                    "bottom-arrow"
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm.isConsolidatable &&
                                                          (!_vm.locationCount ||
                                                            _vm.locationCount >
                                                              1)
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.locationCount
                                                                        ? _vm.$t(
                                                                            "toolbar.selectors.consolidate"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.$t(
                                                                              "dashboard.locationSelector.allLocationsText"
                                                                            )
                                                                        : _vm.$t(
                                                                            "dashboard.locationSelector.locationsSelected",
                                                                            {
                                                                              count:
                                                                                _vm.locationCount,
                                                                              total:
                                                                                _vm
                                                                                  .locationsBySelectedGroup
                                                                                  .length
                                                                            }
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location
                                                                      .location
                                                                      .reportingDisplayName ||
                                                                      location
                                                                        .location
                                                                        .name
                                                                  )
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "date" },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(l.date))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty:
                                                        l.sales.zoneSales[
                                                          "DELIVERY"
                                                        ] &&
                                                        l.sales.zoneSales[
                                                          "DELIVERY"
                                                        ].customers
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        l.sales.zoneSales[
                                                          "DELIVERY"
                                                        ] &&
                                                        l.sales.zoneSales[
                                                          "DELIVERY"
                                                        ].salesAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty:
                                                        l.sales.zoneSales[
                                                          "TAKEOUT"
                                                        ] &&
                                                        l.sales.zoneSales[
                                                          "TAKEOUT"
                                                        ].customers
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        l.sales.zoneSales[
                                                          "TAKEOUT"
                                                        ] &&
                                                        l.sales.zoneSales[
                                                          "TAKEOUT"
                                                        ].salesAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }),
                                    _vm.isMultiDays
                                      ? [
                                          _c(
                                            "tr",
                                            {
                                              key: locationId,
                                              class: {
                                                total:
                                                  _vm.expand.zones[
                                                    locationId
                                                  ] || !_vm.isMultiLocations
                                              }
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "location",
                                                  class: {
                                                    noBorder:
                                                      _vm.expand.zones[
                                                        locationId
                                                      ]
                                                  },
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(
                                                        locationId,
                                                        "zones"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !_vm.expand.zones[
                                                    locationId
                                                  ] && _vm.isMultiLocations
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("vel-icon", {
                                                            staticStyle: {
                                                              height: "8px",
                                                              position:
                                                                "relative",
                                                              top: "-2px",
                                                              width: "8px"
                                                            },
                                                            attrs: {
                                                              name: _vm.expand
                                                                .zones[
                                                                locationId
                                                              ]
                                                                ? "bottom-arrow"
                                                                : "right-arrow"
                                                            }
                                                          }),
                                                          _vm.isConsolidatable &&
                                                          (!_vm.locationCount ||
                                                            _vm.locationCount >
                                                              1)
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.locationCount
                                                                        ? _vm.$t(
                                                                            "toolbar.selectors.consolidate"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.$t(
                                                                              "dashboard.locationSelector.allLocationsText"
                                                                            )
                                                                        : _vm.$t(
                                                                            "dashboard.locationSelector.locationsSelected",
                                                                            {
                                                                              count:
                                                                                _vm.locationCount,
                                                                              total:
                                                                                _vm
                                                                                  .locationsBySelectedGroup
                                                                                  .length
                                                                            }
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location
                                                                      .location
                                                                      .reportingDisplayName ||
                                                                      location
                                                                        .location
                                                                        .name
                                                                  )
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "date" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "audit.takeout.total"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-quantity", {
                                                        attrs: {
                                                          qty:
                                                            location.totalZones[
                                                              "DELIVERY"
                                                            ] &&
                                                            location.totalZones[
                                                              "DELIVERY"
                                                            ].customers
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalZones[
                                                              "DELIVERY"
                                                            ] &&
                                                            location.totalZones[
                                                              "DELIVERY"
                                                            ].salesAmount,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-quantity", {
                                                        attrs: {
                                                          qty:
                                                            location.totalZones[
                                                              "TAKEOUT"
                                                            ] &&
                                                            location.totalZones[
                                                              "TAKEOUT"
                                                            ].customers
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalZones[
                                                              "TAKEOUT"
                                                            ] &&
                                                            location.totalZones[
                                                              "TAKEOUT"
                                                            ].salesAmount,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                                }
                              )
                            ],
                            2
                          )
                        ])
                      ]),
                      _c("vel-card", { staticClass: "card-gift" }, [
                        _c("h5", [_vm._v(_vm._s(_vm.$t("audit.gift.title")))]),
                        _c("table", [
                          _c("thead", [
                            _c("tr", { staticStyle: { height: "64px" } }, [
                              _c("th", { staticStyle: { width: "260px" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "audit.gift.table.headers.location"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("th", { staticStyle: { width: "120px" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("audit.gift.table.headers.date")
                                  )
                                )
                              ]),
                              _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "120px" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "audit.gift.table.headers.giftLoad"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "120px" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "audit.gift.table.headers.loyaltyLoad"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm._l(
                                Object.values(_vm.voids.data)
                                  .filter(function(l) {
                                    return _vm.versionIsValid(l.location.id)
                                  })
                                  .sort(function(a, b) {
                                    return (
                                      a.location.reportingDisplayName ||
                                      a.location.name
                                    ).localeCompare(
                                      b.location.reportingDisplayName ||
                                        b.location.name
                                    )
                                  }),
                                function(location, locationId) {
                                  return [
                                    _vm._l(location.dailySales, function(l, i) {
                                      return [
                                        _vm.expand.gifts[locationId] ||
                                        !_vm.isMultiLocations ||
                                        !_vm.isMultiDays
                                          ? _c("tr", { key: locationId + i }, [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "location",
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(
                                                        locationId,
                                                        "gifts"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !i
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _vm.isMultiDays &&
                                                          _vm.isMultiLocations
                                                            ? _c("vel-icon", {
                                                                staticStyle: {
                                                                  height:
                                                                    "12px",
                                                                  width: "8px"
                                                                },
                                                                attrs: {
                                                                  name:
                                                                    "bottom-arrow"
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm.isConsolidatable &&
                                                          (!_vm.locationCount ||
                                                            _vm.locationCount >
                                                              1)
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.locationCount
                                                                        ? _vm.$t(
                                                                            "toolbar.selectors.consolidate"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.$t(
                                                                              "dashboard.locationSelector.allLocationsText"
                                                                            )
                                                                        : _vm.$t(
                                                                            "dashboard.locationSelector.locationsSelected",
                                                                            {
                                                                              count:
                                                                                _vm.locationCount,
                                                                              total:
                                                                                _vm
                                                                                  .locationsBySelectedGroup
                                                                                  .length
                                                                            }
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location
                                                                      .location
                                                                      .reportingDisplayName ||
                                                                      location
                                                                        .location
                                                                        .name
                                                                  )
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "date" },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(l.date))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        l.sales
                                                          .transactionsIndicators
                                                          .giftCardLoadsAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        l.sales
                                                          .transactionsIndicators
                                                          .loyaltiesAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }),
                                    _vm.isMultiDays
                                      ? [
                                          _c(
                                            "tr",
                                            {
                                              key: locationId,
                                              class: {
                                                total:
                                                  _vm.expand.gifts[
                                                    locationId
                                                  ] || !_vm.isMultiLocations
                                              }
                                            },
                                            [
                                              _vm.isMultiLocations ||
                                              _vm.isMultiDays
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass: "location",
                                                      class: {
                                                        noBorder:
                                                          _vm.expand.gifts[
                                                            locationId
                                                          ]
                                                      },
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.toggle(
                                                            locationId,
                                                            "gifts"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      !_vm.expand.gifts[
                                                        locationId
                                                      ] && _vm.isMultiLocations
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c("vel-icon", {
                                                                staticStyle: {
                                                                  height: "8px",
                                                                  position:
                                                                    "relative",
                                                                  top: "-2px",
                                                                  width: "8px"
                                                                },
                                                                attrs: {
                                                                  name: _vm
                                                                    .expand
                                                                    .gifts[
                                                                    locationId
                                                                  ]
                                                                    ? "bottom-arrow"
                                                                    : "right-arrow"
                                                                }
                                                              }),
                                                              _vm.isConsolidatable &&
                                                              (!_vm.locationCount ||
                                                                _vm.locationCount >
                                                                  1)
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          !_vm.locationCount
                                                                            ? _vm.$t(
                                                                                "toolbar.selectors.consolidate"
                                                                              ) +
                                                                                " - " +
                                                                                _vm.$t(
                                                                                  "dashboard.locationSelector.allLocationsText"
                                                                                )
                                                                            : _vm.$t(
                                                                                "dashboard.locationSelector.locationsSelected",
                                                                                {
                                                                                  count:
                                                                                    _vm.locationCount,
                                                                                  total:
                                                                                    _vm
                                                                                      .locationsBySelectedGroup
                                                                                      .length
                                                                                }
                                                                              )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        location
                                                                          .location
                                                                          .reportingDisplayName ||
                                                                          location
                                                                            .location
                                                                            .name
                                                                      )
                                                                    )
                                                                  ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "td",
                                                { staticClass: "date" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "audit.gift.total"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalGiftCardLoadsAmount,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalLoyaltiesAmount,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                                }
                              )
                            ],
                            2
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("vel-card", [
                    _c("h5", [_vm._v(_vm._s(_vm.$t("audit.refunds.title")))]),
                    _c("table", [
                      _c("thead", [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticStyle: { width: "260px" },
                              attrs: { rowspan: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "audit.refunds.table.headers.location"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: { width: "120px" },
                              attrs: { rowspan: "2" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("audit.refunds.table.headers.date")
                                )
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              staticStyle: { width: "400px" },
                              attrs: { colspan: "3" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "audit.refunds.table.headers.refunds"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              staticStyle: { width: "400px" },
                              attrs: { colspan: "3" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("audit.refunds.table.headers.voids")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              staticStyle: { width: "267px" },
                              attrs: { colspan: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "audit.refunds.table.headers.itemCorrection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(_vm.$t("audit.refunds.table.headers.qty"))
                            )
                          ]),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("audit.refunds.table.headers.total")
                              )
                            )
                          ]),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("audit.refunds.table.headers.percent")
                              )
                            )
                          ]),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(_vm.$t("audit.refunds.table.headers.qty"))
                            )
                          ]),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("audit.refunds.table.headers.total")
                              )
                            )
                          ]),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("audit.refunds.table.headers.percent")
                              )
                            )
                          ]),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(_vm.$t("audit.refunds.table.headers.qty"))
                            )
                          ]),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("audit.refunds.table.headers.total")
                              )
                            )
                          ])
                        ])
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            Object.values(_vm.voids.data)
                              .filter(function(l) {
                                return _vm.versionIsValid(l.location.id)
                              })
                              .sort(function(a, b) {
                                return (
                                  a.location.reportingDisplayName ||
                                  a.location.name
                                ).localeCompare(
                                  b.location.reportingDisplayName ||
                                    b.location.name
                                )
                              }),
                            function(location, locationId) {
                              return [
                                _vm._l(location.dailySales, function(l, i) {
                                  return [
                                    _vm.expand.refunds[locationId] ||
                                    !_vm.isMultiLocations ||
                                    !_vm.isMultiDays
                                      ? _c("tr", { key: locationId + i }, [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "location",
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggle(
                                                    locationId,
                                                    "refunds"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              !i
                                                ? _c(
                                                    "span",
                                                    [
                                                      _vm.isMultiDays &&
                                                      _vm.isMultiLocations
                                                        ? _c("vel-icon", {
                                                            staticStyle: {
                                                              height: "12px",
                                                              width: "8px"
                                                            },
                                                            attrs: {
                                                              name:
                                                                "bottom-arrow"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm.isConsolidatable &&
                                                      (!_vm.locationCount ||
                                                        _vm.locationCount > 1)
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  !_vm.locationCount
                                                                    ? _vm.$t(
                                                                        "toolbar.selectors.consolidate"
                                                                      ) +
                                                                        " - " +
                                                                        _vm.$t(
                                                                          "dashboard.locationSelector.allLocationsText"
                                                                        )
                                                                    : _vm.$t(
                                                                        "dashboard.locationSelector.locationsSelected",
                                                                        {
                                                                          count:
                                                                            _vm.locationCount,
                                                                          total:
                                                                            _vm
                                                                              .locationsBySelectedGroup
                                                                              .length
                                                                        }
                                                                      )
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                location
                                                                  .location
                                                                  .reportingDisplayName ||
                                                                  location
                                                                    .location
                                                                    .name
                                                              )
                                                            )
                                                          ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c("td", { staticClass: "date" }, [
                                            _c("span", [_vm._v(_vm._s(l.date))])
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("vel-quantity", {
                                                attrs: {
                                                  qty:
                                                    l.sales
                                                      .transactionsIndicators
                                                      .refunds
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("vel-amount", {
                                                attrs: {
                                                  amount:
                                                    l.sales
                                                      .transactionsIndicators
                                                      .refundsAmount,
                                                  currency:
                                                    location.location
                                                      .detailedCurrency
                                                      .currencySymbol
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("vel-quantity", {
                                                attrs: {
                                                  qty:
                                                    l.sales
                                                      .refundSalesProportion,
                                                  precision: 3
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("vel-quantity", {
                                                attrs: {
                                                  qty:
                                                    l.sales
                                                      .transactionsIndicators
                                                      .voids
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("vel-amount", {
                                                attrs: {
                                                  amount:
                                                    l.sales
                                                      .transactionsIndicators
                                                      .voidsAmount,
                                                  currency:
                                                    location.location
                                                      .detailedCurrency
                                                      .currencySymbol
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("vel-quantity", {
                                                attrs: {
                                                  qty:
                                                    l.sales.voidSalesProportion,
                                                  precision: 3
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("vel-quantity", {
                                                attrs: {
                                                  qty:
                                                    l.sales
                                                      .transactionsIndicators
                                                      .itemCorrections
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("vel-amount", {
                                                attrs: {
                                                  amount:
                                                    l.sales
                                                      .transactionsIndicators
                                                      .itemCorrectionsAmount,
                                                  currency:
                                                    location.location
                                                      .detailedCurrency
                                                      .currencySymbol
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                }),
                                _vm.isMultiDays
                                  ? [
                                      _c(
                                        "tr",
                                        {
                                          key: locationId,
                                          class: {
                                            total:
                                              _vm.expand.refunds[locationId] ||
                                              !_vm.isMultiLocations
                                          }
                                        },
                                        [
                                          _vm.isMultiLocations ||
                                          _vm.isMultiDays
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "location",
                                                  class: {
                                                    noBorder:
                                                      _vm.expand.refunds[
                                                        locationId
                                                      ]
                                                  },
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(
                                                        locationId,
                                                        "refunds"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !_vm.expand.refunds[
                                                    locationId
                                                  ] && _vm.isMultiLocations
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("vel-icon", {
                                                            staticStyle: {
                                                              height: "8px",
                                                              position:
                                                                "relative",
                                                              top: "-2px",
                                                              width: "8px"
                                                            },
                                                            attrs: {
                                                              name: _vm.expand
                                                                .refunds[
                                                                locationId
                                                              ]
                                                                ? "bottom-arrow"
                                                                : "right-arrow"
                                                            }
                                                          }),
                                                          _vm.isConsolidatable &&
                                                          (!_vm.locationCount ||
                                                            _vm.locationCount >
                                                              1)
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.locationCount
                                                                        ? _vm.$t(
                                                                            "toolbar.selectors.consolidate"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.$t(
                                                                              "dashboard.locationSelector.allLocationsText"
                                                                            )
                                                                        : _vm.$t(
                                                                            "dashboard.locationSelector.locationsSelected",
                                                                            {
                                                                              count:
                                                                                _vm.locationCount,
                                                                              total:
                                                                                _vm
                                                                                  .locationsBySelectedGroup
                                                                                  .length
                                                                            }
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location
                                                                      .location
                                                                      .reportingDisplayName ||
                                                                      location
                                                                        .location
                                                                        .name
                                                                  )
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("td", { staticClass: "date" }, [
                                            _c("strong", [_vm._v("Total")])
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty: location.totalRefunds
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        location.totalRefundsAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty:
                                                        location.totalRefundsPercent,
                                                      prevision: 3
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty: location.totalVoids
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        location.totalVoidsAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty:
                                                        location.totalVoidsPercent,
                                                      prevision: 3
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty:
                                                        location.totalItemCorrections
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "strong",
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        location.totalItemCorrectionsAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            }
                          )
                        ],
                        2
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-container" },
                    [
                      _c("vel-card", { staticClass: "card-cash" }, [
                        _c("h5", [_vm._v(_vm._s(_vm.$t("audit.cash.title")))]),
                        _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticStyle: { width: "260px" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "audit.cash.table.headers.location"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("th", { staticStyle: { width: "120px" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("audit.cash.table.headers.date")
                                  )
                                )
                              ]),
                              _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "145px" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "audit.cash.table.headers.cashDeposit"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "145px" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "audit.cash.table.headers.paidOuts"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "145px" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("audit.cash.table.headers.tips")
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "145px" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("audit.cash.table.headers.total")
                                    )
                                  )
                                ]
                              ),
                              _c("th")
                            ])
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm._l(
                                Object.values(_vm.voids.data)
                                  .filter(function(l) {
                                    return _vm.versionIsValid(l.location.id)
                                  })
                                  .sort(function(a, b) {
                                    return (
                                      a.location.reportingDisplayName ||
                                      a.location.name
                                    ).localeCompare(
                                      b.location.reportingDisplayName ||
                                        b.location.name
                                    )
                                  }),
                                function(location, locationId) {
                                  return [
                                    _vm._l(location.dailySales, function(l, i) {
                                      return [
                                        _vm.expand.cash[locationId] ||
                                        !_vm.isMultiLocations ||
                                        !_vm.isMultiDays
                                          ? _c("tr", { key: locationId + i }, [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "location",
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(
                                                        locationId,
                                                        "cash"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !i
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _vm.isMultiDays &&
                                                          _vm.isMultiLocations
                                                            ? _c("vel-icon", {
                                                                staticStyle: {
                                                                  height:
                                                                    "12px",
                                                                  width: "8px"
                                                                },
                                                                attrs: {
                                                                  name:
                                                                    "bottom-arrow"
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm.isConsolidatable &&
                                                          (!_vm.locationCount ||
                                                            _vm.locationCount >
                                                              1)
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.locationCount
                                                                        ? _vm.$t(
                                                                            "toolbar.selectors.consolidate"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.$t(
                                                                              "dashboard.locationSelector.allLocationsText"
                                                                            )
                                                                        : _vm.$t(
                                                                            "dashboard.locationSelector.locationsSelected",
                                                                            {
                                                                              count:
                                                                                _vm.locationCount,
                                                                              total:
                                                                                _vm
                                                                                  .locationsBySelectedGroup
                                                                                  .length
                                                                            }
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location
                                                                      .location
                                                                      .reportingDisplayName ||
                                                                      location
                                                                        .location
                                                                        .name
                                                                  )
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "date" },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(l.date))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        l.sales.indicators
                                                          .cashDepositAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        l.sales
                                                          .transactionsIndicators
                                                          .paidOutsAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        l.sales.indicators
                                                          .tipsTotalAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        l.sales.indicators
                                                          .cashDepositAmount +
                                                        l.sales
                                                          .transactionsIndicators
                                                          .paidOutsAmount +
                                                        l.sales.indicators
                                                          .tipsTotalAmount,
                                                      currency:
                                                        location.location
                                                          .detailedCurrency
                                                          .currencySymbol
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }),
                                    _vm.isMultiDays
                                      ? [
                                          _c(
                                            "tr",
                                            {
                                              key: locationId,
                                              class: {
                                                total:
                                                  _vm.expand.cash[locationId] ||
                                                  !_vm.isMultiLocations
                                              }
                                            },
                                            [
                                              _vm.isMultiLocations ||
                                              _vm.isMultiDays
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass: "location",
                                                      class: {
                                                        noBorder:
                                                          _vm.expand.cash[
                                                            locationId
                                                          ]
                                                      },
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.toggle(
                                                            locationId,
                                                            "cash"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      !_vm.expand.cash[
                                                        locationId
                                                      ] && _vm.isMultiLocations
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c("vel-icon", {
                                                                staticStyle: {
                                                                  height: "8px",
                                                                  position:
                                                                    "relative",
                                                                  top: "-2px",
                                                                  width: "8px"
                                                                },
                                                                attrs: {
                                                                  name: _vm
                                                                    .expand
                                                                    .cash[
                                                                    locationId
                                                                  ]
                                                                    ? "bottom-arrow"
                                                                    : "right-arrow"
                                                                }
                                                              }),
                                                              _vm.isConsolidatable &&
                                                              (!_vm.locationCount ||
                                                                _vm.locationCount >
                                                                  1)
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          !_vm.locationCount
                                                                            ? _vm.$t(
                                                                                "toolbar.selectors.consolidate"
                                                                              ) +
                                                                                " - " +
                                                                                _vm.$t(
                                                                                  "dashboard.locationSelector.allLocationsText"
                                                                                )
                                                                            : _vm.$t(
                                                                                "dashboard.locationSelector.locationsSelected",
                                                                                {
                                                                                  count:
                                                                                    _vm.locationCount,
                                                                                  total:
                                                                                    _vm
                                                                                      .locationsBySelectedGroup
                                                                                      .length
                                                                                }
                                                                              )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        location
                                                                          .location
                                                                          .reportingDisplayName ||
                                                                          location
                                                                            .location
                                                                            .name
                                                                      )
                                                                    )
                                                                  ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "td",
                                                { staticClass: "date" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "audit.cash.total"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalCashDepositsAmount,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalPaidOutsAmount,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalTipsTotalAmount,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location.totalCashDepositsAmount +
                                                            location.totalPaidOutsAmount +
                                                            location.totalTipsTotalAmount,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                                }
                              )
                            ],
                            2
                          )
                        ])
                      ]),
                      _c("vel-card", { staticClass: "card-drawer" }, [
                        _c("h5", [
                          _vm._v(_vm._s(_vm.$t("audit.drawer.title")))
                        ]),
                        _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticStyle: { width: "260px" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "audit.drawer.table.headers.location"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("th", { staticStyle: { width: "120px" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("audit.drawer.table.headers.date")
                                  )
                                )
                              ]),
                              _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "120px" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("audit.drawer.table.headers.qty")
                                    )
                                  )
                                ]
                              ),
                              _c("th")
                            ])
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm._l(
                                Object.values(_vm.voids.data)
                                  .filter(function(l) {
                                    return _vm.versionIsValid(l.location.id)
                                  })
                                  .sort(function(a, b) {
                                    return (
                                      a.location.reportingDisplayName ||
                                      a.location.name
                                    ).localeCompare(
                                      b.location.reportingDisplayName ||
                                        b.location.name
                                    )
                                  }),
                                function(location, locationId) {
                                  return [
                                    _vm._l(location.dailySales, function(l, i) {
                                      return [
                                        _vm.expand.drawer[locationId] ||
                                        !_vm.isMultiLocations ||
                                        !_vm.isMultiDays
                                          ? _c("tr", { key: locationId + i }, [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "location",
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(
                                                        locationId,
                                                        "drawer"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !i
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _vm.isMultiDays &&
                                                          _vm.isMultiLocations
                                                            ? _c("vel-icon", {
                                                                staticStyle: {
                                                                  height:
                                                                    "12px",
                                                                  width: "8px"
                                                                },
                                                                attrs: {
                                                                  name:
                                                                    "bottom-arrow"
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm.isConsolidatable &&
                                                          (!_vm.locationCount ||
                                                            _vm.locationCount >
                                                              1)
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.locationCount
                                                                        ? _vm.$t(
                                                                            "toolbar.selectors.consolidate"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.$t(
                                                                              "dashboard.locationSelector.allLocationsText"
                                                                            )
                                                                        : _vm.$t(
                                                                            "dashboard.locationSelector.locationsSelected",
                                                                            {
                                                                              count:
                                                                                _vm.locationCount,
                                                                              total:
                                                                                _vm
                                                                                  .locationsBySelectedGroup
                                                                                  .length
                                                                            }
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location
                                                                      .location
                                                                      .reportingDisplayName ||
                                                                      location
                                                                        .location
                                                                        .name
                                                                  )
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "date" },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(l.date))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty:
                                                        l.sales
                                                          .transactionsIndicators
                                                          .drawerOpenings
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }),
                                    _vm.isMultiDays
                                      ? [
                                          _c(
                                            "tr",
                                            {
                                              key: locationId,
                                              class: {
                                                total:
                                                  _vm.expand.drawer[
                                                    locationId
                                                  ] || !_vm.isMultiLocations
                                              }
                                            },
                                            [
                                              _vm.isMultiLocations ||
                                              _vm.isMultiDays
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass: "location",
                                                      class: {
                                                        noBorder:
                                                          _vm.expand.drawer[
                                                            locationId
                                                          ]
                                                      },
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.toggle(
                                                            locationId,
                                                            "drawer"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      !_vm.expand.drawer[
                                                        locationId
                                                      ] && _vm.isMultiLocations
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c("vel-icon", {
                                                                staticStyle: {
                                                                  height: "8px",
                                                                  position:
                                                                    "relative",
                                                                  top: "-2px",
                                                                  width: "8px"
                                                                },
                                                                attrs: {
                                                                  name: _vm
                                                                    .expand
                                                                    .drawer[
                                                                    locationId
                                                                  ]
                                                                    ? "bottom-arrow"
                                                                    : "right-arrow"
                                                                }
                                                              }),
                                                              _vm.isConsolidatable &&
                                                              (!_vm.locationCount ||
                                                                _vm.locationCount >
                                                                  1)
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          !_vm.locationCount
                                                                            ? _vm.$t(
                                                                                "toolbar.selectors.consolidate"
                                                                              ) +
                                                                                " - " +
                                                                                _vm.$t(
                                                                                  "dashboard.locationSelector.allLocationsText"
                                                                                )
                                                                            : _vm.$t(
                                                                                "dashboard.locationSelector.locationsSelected",
                                                                                {
                                                                                  count:
                                                                                    _vm.locationCount,
                                                                                  total:
                                                                                    _vm
                                                                                      .locationsBySelectedGroup
                                                                                      .length
                                                                                }
                                                                              )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        location
                                                                          .location
                                                                          .reportingDisplayName ||
                                                                          location
                                                                            .location
                                                                            .name
                                                                      )
                                                                    )
                                                                  ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "td",
                                                { staticClass: "date" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "audit.drawer.total"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-quantity", {
                                                        attrs: {
                                                          qty:
                                                            location.totalDrawerOpenings
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                                }
                              )
                            ],
                            2
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("vel-card", [
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$t("audit.workstations.title")))
                    ]),
                    _c("table", [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _c("th", { staticStyle: { width: "260px" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "audit.workstations.table.headers.location"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("th", { staticStyle: { width: "120px" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "audit.workstations.table.headers.date"
                                  )
                                )
                              )
                            ]),
                            _vm._l(
                              Object.keys(_vm.voids.workstations),
                              function(workstation) {
                                return _c(
                                  "th",
                                  {
                                    key: workstation,
                                    staticClass: "text-right",
                                    staticStyle: { width: "260px" }
                                  },
                                  [_vm._v(" " + _vm._s(workstation) + " ")]
                                )
                              }
                            ),
                            _c("th", { staticStyle: { border: "none" } })
                          ],
                          2
                        )
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            Object.values(_vm.voids.data)
                              .filter(function(l) {
                                return _vm.versionIsValid(l.location.id)
                              })
                              .sort(function(a, b) {
                                return (
                                  a.location.reportingDisplayName ||
                                  a.location.name
                                ).localeCompare(
                                  b.location.reportingDisplayName ||
                                    b.location.name
                                )
                              }),
                            function(location, locationId) {
                              return [
                                _vm._l(location.dailySales, function(l, i) {
                                  return [
                                    _vm.expand.stations[locationId] ||
                                    !_vm.isMultiLocations ||
                                    !_vm.isMultiDays
                                      ? _c(
                                          "tr",
                                          { key: locationId + i },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "location",
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggle(
                                                      locationId,
                                                      "stations"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                !i
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _vm.isMultiDays &&
                                                        _vm.isMultiLocations
                                                          ? _c("vel-icon", {
                                                              staticStyle: {
                                                                height: "12px",
                                                                width: "8px"
                                                              },
                                                              attrs: {
                                                                name:
                                                                  "bottom-arrow"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm.isConsolidatable &&
                                                        (!_vm.locationCount ||
                                                          _vm.locationCount > 1)
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    !_vm.locationCount
                                                                      ? _vm.$t(
                                                                          "toolbar.selectors.consolidate"
                                                                        ) +
                                                                          " - " +
                                                                          _vm.$t(
                                                                            "dashboard.locationSelector.allLocationsText"
                                                                          )
                                                                      : _vm.$t(
                                                                          "dashboard.locationSelector.locationsSelected",
                                                                          {
                                                                            count:
                                                                              _vm.locationCount,
                                                                            total:
                                                                              _vm
                                                                                .locationsBySelectedGroup
                                                                                .length
                                                                          }
                                                                        )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  location
                                                                    .location
                                                                    .reportingDisplayName ||
                                                                    location
                                                                      .location
                                                                      .name
                                                                )
                                                              )
                                                            ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c("td", { staticClass: "date" }, [
                                              _c("span", [
                                                _vm._v(_vm._s(l.date))
                                              ])
                                            ]),
                                            _vm._l(
                                              Object.keys(
                                                _vm.voids.workstations
                                              ),
                                              function(workstation) {
                                                return _c(
                                                  "td",
                                                  {
                                                    key: workstation,
                                                    staticClass: "text-right"
                                                  },
                                                  [
                                                    _c("vel-amount", {
                                                      attrs: {
                                                        amount:
                                                          (l.sales
                                                            .workstationSales[
                                                            workstation
                                                          ] &&
                                                            l.sales
                                                              .workstationSales[
                                                              workstation
                                                            ].salesAmount) ||
                                                          0,
                                                        currency:
                                                          location.location
                                                            .detailedCurrency
                                                            .currencySymbol
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                }),
                                _vm.isMultiDays
                                  ? [
                                      _c(
                                        "tr",
                                        {
                                          key: locationId,
                                          class: {
                                            total:
                                              _vm.expand.stations[locationId] ||
                                              !_vm.isMultiLocations
                                          }
                                        },
                                        [
                                          _vm.isMultiLocations ||
                                          _vm.isMultiDays
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "location",
                                                  class: {
                                                    noBorder:
                                                      _vm.expand.stations[
                                                        locationId
                                                      ]
                                                  },
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(
                                                        locationId,
                                                        "stations"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !_vm.expand.stations[
                                                    locationId
                                                  ] && _vm.isMultiLocations
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("vel-icon", {
                                                            staticStyle: {
                                                              height: "8px",
                                                              position:
                                                                "relative",
                                                              top: "-2px",
                                                              width: "8px"
                                                            },
                                                            attrs: {
                                                              name: _vm.expand
                                                                .stations[
                                                                locationId
                                                              ]
                                                                ? "bottom-arrow"
                                                                : "right-arrow"
                                                            }
                                                          }),
                                                          _vm.isConsolidatable &&
                                                          (!_vm.locationCount ||
                                                            _vm.locationCount >
                                                              1)
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.locationCount
                                                                        ? _vm.$t(
                                                                            "toolbar.selectors.consolidate"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.$t(
                                                                              "dashboard.locationSelector.allLocationsText"
                                                                            )
                                                                        : _vm.$t(
                                                                            "dashboard.locationSelector.locationsSelected",
                                                                            {
                                                                              count:
                                                                                _vm.locationCount,
                                                                              total:
                                                                                _vm
                                                                                  .locationsBySelectedGroup
                                                                                  .length
                                                                            }
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location
                                                                      .location
                                                                      .reportingDisplayName ||
                                                                      location
                                                                        .location
                                                                        .name
                                                                  )
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("td", { staticClass: "date" }, [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "audit.workstations.total"
                                                  )
                                                )
                                              )
                                            ])
                                          ]),
                                          _vm._l(
                                            Object.keys(_vm.voids.workstations),
                                            function(workstation) {
                                              return _c(
                                                "td",
                                                {
                                                  key: workstation,
                                                  staticClass: "text-right"
                                                },
                                                [
                                                  _c(
                                                    "strong",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            location
                                                              .totalWorkstations[
                                                              workstation
                                                            ] || 0,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            }
                          )
                        ],
                        2
                      )
                    ])
                  ]),
                  _c("vel-card", [
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$t("audit.tenderTypes.title")))
                    ]),
                    _c("table", [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _c(
                              "th",
                              {
                                staticStyle: { width: "260px" },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "audit.tenderTypes.table.headers.location"
                                    )
                                  )
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: { width: "120px" },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "audit.tenderTypes.table.headers.date"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._l(Object.keys(_vm.voids.tenderTypes), function(
                              tenderType
                            ) {
                              return [
                                _c(
                                  "th",
                                  {
                                    key: tenderType,
                                    staticClass: "text-center",
                                    staticStyle: { width: "360px" },
                                    attrs: { colspan: "3" }
                                  },
                                  [_vm._v(" " + _vm._s(tenderType) + " ")]
                                )
                              ]
                            }),
                            _c("th")
                          ],
                          2
                        ),
                        _c(
                          "tr",
                          [
                            _vm._l(Object.keys(_vm.voids.tenderTypes), function(
                              tenderType
                            ) {
                              return [
                                _c(
                                  "th",
                                  {
                                    key: tenderType,
                                    staticClass: "text-right",
                                    staticStyle: { width: "120px" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "audit.refunds.table.headers.qty"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    key: tenderType,
                                    staticClass: "text-right",
                                    staticStyle: { width: "120px" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "audit.refunds.table.headers.tips"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    key: tenderType,
                                    staticClass: "text-right",
                                    staticStyle: {
                                      width: "120px",
                                      "vertical-align": "middle"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "audit.refunds.table.headers.amount"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "tooltip",
                                      {
                                        staticClass: "ml-1",
                                        attrs: {
                                          content: _vm.$t(
                                            "audit.refunds.table.headers.amountTooltip"
                                          ),
                                          placement: "top",
                                          effect: "light"
                                        }
                                      },
                                      [
                                        _c("vel-icon", {
                                          staticStyle: {
                                            fill: "#2a314261",
                                            color: "#2a314261"
                                          },
                                          attrs: {
                                            name: "question-circle",
                                            modifier: "on-bright",
                                            size: "small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            Object.values(_vm.voids.data)
                              .filter(function(l) {
                                return _vm.versionIsValid(l.location.id)
                              })
                              .sort(function(a, b) {
                                return (
                                  a.location.reportingDisplayName ||
                                  a.location.name
                                ).localeCompare(
                                  b.location.reportingDisplayName ||
                                    b.location.name
                                )
                              }),
                            function(location, locationId) {
                              return [
                                _vm._l(location.dailySales, function(l, i) {
                                  return [
                                    _vm.expand.tenders[locationId] ||
                                    !_vm.isMultiLocations ||
                                    !_vm.isMultiDays
                                      ? _c(
                                          "tr",
                                          { key: locationId + i },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "location",
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggle(
                                                      locationId,
                                                      "tenders"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                !i
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _vm.isMultiDays &&
                                                        _vm.isMultiLocations
                                                          ? _c("vel-icon", {
                                                              staticStyle: {
                                                                height: "12px",
                                                                width: "8px"
                                                              },
                                                              attrs: {
                                                                name:
                                                                  "bottom-arrow"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm.isConsolidatable &&
                                                        (!_vm.locationCount ||
                                                          _vm.locationCount > 1)
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    !_vm.locationCount
                                                                      ? _vm.$t(
                                                                          "toolbar.selectors.consolidate"
                                                                        ) +
                                                                          " - " +
                                                                          _vm.$t(
                                                                            "dashboard.locationSelector.allLocationsText"
                                                                          )
                                                                      : _vm.$t(
                                                                          "dashboard.locationSelector.locationsSelected",
                                                                          {
                                                                            count:
                                                                              _vm.locationCount,
                                                                            total:
                                                                              _vm
                                                                                .locationsBySelectedGroup
                                                                                .length
                                                                          }
                                                                        )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  location
                                                                    .location
                                                                    .reportingDisplayName ||
                                                                    location
                                                                      .location
                                                                      .name
                                                                )
                                                              )
                                                            ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c("td", { staticClass: "date" }, [
                                              _c("span", [
                                                _vm._v(_vm._s(l.date))
                                              ])
                                            ]),
                                            _vm._l(
                                              Object.keys(
                                                _vm.voids.tenderTypes
                                              ),
                                              function(tenderType) {
                                                return [
                                                  _c(
                                                    "td",
                                                    {
                                                      key: tenderType,
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _c("vel-quantity", {
                                                        attrs: {
                                                          qty:
                                                            (l.sales
                                                              .tenderTypeSales[
                                                              tenderType
                                                            ] &&
                                                              l.sales
                                                                .tenderTypeSales[
                                                                tenderType
                                                              ].count) ||
                                                            0
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      key: tenderType,
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            (l.sales
                                                              .tenderTypeSales[
                                                              tenderType
                                                            ] &&
                                                              l.sales
                                                                .tenderTypeSales[
                                                                tenderType
                                                              ]
                                                                .transferredTip) ||
                                                            0,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      key: tenderType,
                                                      staticClass: "text-right",
                                                      staticStyle: {
                                                        width: "120px"
                                                      }
                                                    },
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            (l.sales
                                                              .tenderTypeSales[
                                                              tenderType
                                                            ] &&
                                                              l.sales
                                                                .tenderTypeSales[
                                                                tenderType
                                                              ].salesAmount) ||
                                                            0,
                                                          currency:
                                                            location.location
                                                              .detailedCurrency
                                                              .currencySymbol
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                }),
                                _vm.isMultiDays
                                  ? [
                                      _c(
                                        "tr",
                                        {
                                          key: locationId,
                                          class: {
                                            total:
                                              _vm.expand.tenders[locationId] ||
                                              !_vm.isMultiLocations
                                          }
                                        },
                                        [
                                          _vm.isMultiLocations ||
                                          _vm.isMultiDays
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "location",
                                                  class: {
                                                    noBorder:
                                                      _vm.expand.tenders[
                                                        locationId
                                                      ]
                                                  },
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggle(
                                                        locationId,
                                                        "tenders"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !_vm.expand.tenders[
                                                    locationId
                                                  ] && _vm.isMultiLocations
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("vel-icon", {
                                                            staticStyle: {
                                                              height: "8px",
                                                              position:
                                                                "relative",
                                                              top: "-2px",
                                                              width: "8px"
                                                            },
                                                            attrs: {
                                                              name: _vm.expand
                                                                .tenders[
                                                                locationId
                                                              ]
                                                                ? "bottom-arrow"
                                                                : "right-arrow"
                                                            }
                                                          }),
                                                          _vm.isConsolidatable &&
                                                          (!_vm.locationCount ||
                                                            _vm.locationCount >
                                                              1)
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.locationCount
                                                                        ? _vm.$t(
                                                                            "toolbar.selectors.consolidate"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.$t(
                                                                              "dashboard.locationSelector.allLocationsText"
                                                                            )
                                                                        : _vm.$t(
                                                                            "dashboard.locationSelector.locationsSelected",
                                                                            {
                                                                              count:
                                                                                _vm.locationCount,
                                                                              total:
                                                                                _vm
                                                                                  .locationsBySelectedGroup
                                                                                  .length
                                                                            }
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location
                                                                      .location
                                                                      .reportingDisplayName ||
                                                                      location
                                                                        .location
                                                                        .name
                                                                  )
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("td", { staticClass: "date" }, [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "audit.tenderTypes.total"
                                                  )
                                                )
                                              )
                                            ])
                                          ]),
                                          _vm._l(
                                            Object.keys(_vm.voids.tenderTypes),
                                            function(tenderType) {
                                              return [
                                                _c(
                                                  "td",
                                                  {
                                                    key: tenderType,
                                                    staticClass: "text-right"
                                                  },
                                                  [
                                                    _c(
                                                      "strong",
                                                      [
                                                        _c("vel-quantity", {
                                                          attrs: {
                                                            qty:
                                                              location
                                                                .totalTenderTypesQty[
                                                                tenderType
                                                              ] || 0
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    key: tenderType,
                                                    staticClass: "text-right"
                                                  },
                                                  [
                                                    _c(
                                                      "strong",
                                                      [
                                                        _c("vel-amount", {
                                                          attrs: {
                                                            amount:
                                                              location
                                                                .totalTenderTypesTips[
                                                                tenderType
                                                              ] || 0,
                                                            currency:
                                                              location.location
                                                                .detailedCurrency
                                                                .currencySymbol
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    key: tenderType,
                                                    staticClass: "text-right"
                                                  },
                                                  [
                                                    _c(
                                                      "strong",
                                                      [
                                                        _c("vel-amount", {
                                                          attrs: {
                                                            amount:
                                                              location
                                                                .totalTenderTypes[
                                                                tenderType
                                                              ] || 0,
                                                            currency:
                                                              location.location
                                                                .detailedCurrency
                                                                .currencySymbol
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            }
                          )
                        ],
                        2
                      )
                    ])
                  ])
                ]
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }