var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pie-line-chart", {
    staticClass: "hourly-sales__chart",
    attrs: {
      pending: _vm.areSalesLoading,
      options: _vm.chartOptions,
      series: _vm.series,
      labels: _vm.labels,
      currency: _vm.currencyId,
      line: true,
      multiple: false,
      title: _vm.$t("dashboard.salesPerHourChart.title")
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }