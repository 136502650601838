var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    _vm._b(
      {
        staticClass: "report",
        attrs: {
          "multiple-locations":
            _vm.locationsList && _vm.locationsList.length > 1,
          "show-selector-button": true,
          "enable-all-currencies": true
        },
        scopedSlots: _vm._u([
          {
            key: "export",
            fn: function() {
              return [
                Object.values(_vm.data).length
                  ? _c(
                      "vel-button",
                      {
                        staticClass: "vel-button",
                        attrs: {
                          disabled:
                            _vm.loading || !Object.values(_vm.data).length,
                          loading: _vm.generating,
                          type: "primary",
                          icon: _vm.isMediumUp ? "file-excel" : undefined
                        },
                        on: { click: _vm.exportXLS }
                      },
                      [
                        _vm.isMediumDown
                          ? _c("vel-icon", { attrs: { name: "file-download" } })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isMediumUp
                                ? _vm.$t("table.tableExport.button.value")
                                : ""
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      },
      "vel-page",
      _vm.pageAttrs,
      false
    ),
    [
      _vm.loading ? _c("vel-spinner", { staticClass: "spinner" }) : _vm._e(),
      _vm.invalidLocations
        ? _c(
            "div",
            {
              staticStyle: {
                "background-color": "#fff2cc",
                "margin-bottom": "1rem",
                padding: "1rem"
              }
            },
            [
              _vm.getLocationSelectorSelected
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invalidVersions.requiredVersion")) +
                        " "
                    )
                  ]
                : [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "invalidVersions.requiredVersionMultiLocations"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript://" },
                        on: {
                          click: function($event) {
                            _vm.showLocationsList = !_vm.showLocationsList
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("invalidVersions.collapse")))]
                    ),
                    _vm.showLocationsList
                      ? _c(
                          "ul",
                          { staticStyle: { "margin-top": "1rem" } },
                          _vm._l(_vm.invalidLocations, function(location) {
                            return _c("li", { key: location.id }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    location.reportingDisplayName ||
                                      location.name
                                  ) +
                                  " "
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm.showNotification && !_vm.isVersionLoading
        ? _c(
            "p",
            {
              staticStyle: {
                "background-color": "#ffd2d2",
                "margin-bottom": "1rem",
                padding: "1rem"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("invalidVersions.notice")) + " ")]
          )
        : _vm._e(),
      !_vm.loading
        ? [
            !Object.values(_vm.data).length
              ? _c("div", { staticClass: "no-data" }, [
                  _vm._v(" " + _vm._s(_vm.$t("itemSalesCategory.noData")) + " ")
                ])
              : _vm._l(
                  Object.values(_vm.data).sort(function(a, b) {
                    return (a.reportingDisplayName || a.name).localeCompare(
                      b.reportingDisplayName || b.name
                    )
                  }),
                  function(location) {
                    return _c("vel-card", { key: location.id }, [
                      _c("h5", { staticStyle: { color: "#409eff" } }, [
                        _vm._v(
                          _vm._s(
                            (
                              location.reportingDisplayName || location.name
                            ).toUpperCase()
                          )
                        )
                      ]),
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticStyle: { width: "160px" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("giftCard.table.headers.date"))
                              )
                            ]),
                            _c("th", { staticStyle: { width: "120px" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("giftCard.table.headers.time"))
                              )
                            ]),
                            _c("th", { staticStyle: { width: "200px" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("giftCard.table.headers.cardNumber")
                                )
                              )
                            ]),
                            _c("th", { staticStyle: { width: "200px" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("giftCard.table.headers.description")
                                )
                              )
                            ]),
                            _c(
                              "th",
                              {
                                staticClass: "text-right",
                                staticStyle: { width: "200px" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("giftCard.table.headers.funding")
                                  )
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "text-right",
                                staticStyle: { width: "200px" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("giftCard.table.headers.redeem")
                                  )
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "text-right",
                                staticStyle: { width: "200px" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("giftCard.table.headers.check")
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c("th", { staticStyle: { width: "200px" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("giftCard.table.headers.waiter"))
                              )
                            ])
                          ])
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(
                              location.dates.sort(function(a, b) {
                                return a.date.localeCompare(b.date)
                              }),
                              function(dates) {
                                return [
                                  _vm._l(dates.giftCards, function(
                                    tenderType,
                                    i
                                  ) {
                                    return _c("tr", { key: tenderType.id }, [
                                      _c(
                                        "td",
                                        { class: { whitecell: i, date: true } },
                                        [
                                          !i
                                            ? _c("span", [
                                                _vm._v(_vm._s(dates.date))
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              tenderType.time.replace(
                                                /^2(4|5|6|7|8)/gi,
                                                function(matched) {
                                                  return (
                                                    "0" +
                                                    (matched - 24).toString()
                                                  )
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          "**** **** **** " +
                                            _vm._s(tenderType.lastDigits)
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "giftCard.description." +
                                                tenderType.operation
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          [10, 11].includes(
                                            tenderType.operation
                                          )
                                            ? _c("vel-amount", {
                                                attrs: {
                                                  amount: tenderType.amount,
                                                  currency: location.currency
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          ![10, 11].includes(
                                            tenderType.operation
                                          )
                                            ? _c("vel-amount", {
                                                attrs: {
                                                  amount: tenderType.amount,
                                                  currency: location.currency
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(_vm._s(tenderType.checkNumber))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(tenderType.employee.name))
                                      ])
                                    ])
                                  }),
                                  _c(
                                    "tr",
                                    { key: dates.id, staticClass: "subtotal" },
                                    [
                                      _c("td", {
                                        staticStyle: {
                                          "background-color": "white"
                                        }
                                      }),
                                      _c("td", {
                                        staticStyle: {
                                          "background-color": "white"
                                        }
                                      }),
                                      _c("td", {
                                        staticStyle: {
                                          "background-color": "white"
                                        }
                                      }),
                                      _c("td", [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("giftCard.subtotal"))
                                          )
                                        ])
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _c(
                                          "b",
                                          [
                                            _c("vel-amount", {
                                              attrs: {
                                                amount: dates.totalFunding,
                                                currency: location.currency
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _c(
                                          "b",
                                          [
                                            _c("vel-amount", {
                                              attrs: {
                                                amount: dates.totalRedeem,
                                                currency: location.currency
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "background-color": "#e0e0e0"
                                          }
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("giftCard.liability")
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-right",
                                          staticStyle: {
                                            "background-color": "#e0e0e0"
                                          }
                                        },
                                        [
                                          _c(
                                            "b",
                                            [
                                              _c("vel-amount", {
                                                attrs: {
                                                  amount:
                                                    dates.totalFunding -
                                                    dates.totalRedeem,
                                                  currency: location.currency
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              }
                            )
                          ],
                          2
                        )
                      ]),
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", {
                              staticStyle: {
                                "border-bottom": "none",
                                width: "160px"
                              }
                            }),
                            _c("th", {
                              staticClass: "text-right",
                              staticStyle: {
                                "border-bottom": "none",
                                width: "160px"
                              }
                            }),
                            _c("th", {
                              staticClass: "text-right",
                              staticStyle: {
                                "border-bottom": "none",
                                width: "160px"
                              }
                            }),
                            _c("th", {
                              staticClass: "text-right",
                              staticStyle: {
                                "border-bottom": "none",
                                width: "200px"
                              }
                            }),
                            _c("th", {
                              staticClass: "text-right",
                              staticStyle: {
                                "border-bottom": "none",
                                width: "200px"
                              }
                            }),
                            _c("th", {
                              staticClass: "text-right",
                              staticStyle: {
                                "border-bottom": "none",
                                width: "200px"
                              }
                            }),
                            _c("th", {
                              staticClass: "text-right",
                              staticStyle: {
                                "border-bottom": "none",
                                width: "200px"
                              }
                            }),
                            _c("th", {
                              staticClass: "text-right",
                              staticStyle: {
                                "border-bottom": "none",
                                width: "200px"
                              }
                            })
                          ])
                        ]),
                        _c("tbody", [
                          _c("tr", { staticClass: "subCategoryTotal" }, [
                            _c("td", {
                              staticStyle: {
                                "background-color": "white",
                                border: "none"
                              }
                            }),
                            _c("td", {
                              staticStyle: {
                                "background-color": "white",
                                border: "none"
                              }
                            }),
                            _c("td", {
                              staticStyle: {
                                "background-color": "white",
                                border: "none"
                              }
                            }),
                            _c("td", [
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("giftCard.total")))
                              ])
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("vel-amount", {
                                  attrs: {
                                    amount: location.allTotalFunding,
                                    currency: location.currency
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("vel-amount", {
                                  attrs: {
                                    amount: location.allTotalRedeem,
                                    currency: location.currency
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: { "background-color": "#e0e0e0" }
                              },
                              [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("giftCard.liability")))
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "text-right",
                                staticStyle: {
                                  "background-color": "#e0e0e0",
                                  "border-top": "none"
                                }
                              },
                              [
                                _c("vel-amount", {
                                  attrs: {
                                    amount:
                                      location.allTotalFunding -
                                      location.allTotalRedeem,
                                    currency: location.currency
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ])
                  }
                )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }