var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "groupForm",
      staticClass: "group-form",
      attrs: {
        model: _vm.groupForm,
        rules: _vm.groupFormRules,
        "label-width": "150px"
      }
    },
    [
      _c(
        "vel-form-item",
        {
          staticClass: "group-form__group",
          attrs: {
            prop: "groupId",
            label: _vm.$t("location.groups.form.group.label"),
            required: ""
          }
        },
        [
          _c("vel-group-selector", {
            attrs: {
              groupId: _vm.groupForm.groupId,
              groupsOptions: _vm.groupsOptions,
              multiple: _vm.allowMultipleGroups,
              autofocus: "",
              filterable: ""
            },
            on: { change: _vm.handleGroupChange }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        { staticClass: "group-form__actions" },
        [
          _c(
            "vel-button",
            {
              staticClass: "actions__submit",
              attrs: { type: "primary", loading: _vm.isSubmitDisabled },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
          ),
          _c(
            "vel-button",
            {
              staticClass: "actions__cancel",
              attrs: { type: "secondary" },
              on: {
                click: function($event) {
                  return _vm.handleCancel()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }