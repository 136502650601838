import { mapActions } from 'pinia';
import {useDataAccount} from "@/stores/data/account.module";
export const REFRESH_INTERVAL_IN_MS = 1000 * 60 * 60;

export default {
  methods: {
    ...mapActions(useDataAccount, ['refreshUser'])
  },
  mounted() {
    this.refreshUser(false);
    this.refreshUserInterval = setInterval(() => {
      this.refreshUser();
    }, REFRESH_INTERVAL_IN_MS);
  },
  beforeDestroy() {
    if (this.refreshUserInterval) {
      clearInterval(this.refreshUserInterval);
      this.refreshUserInterval = null;
    }
  }
};
