var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vel-button",
        {
          staticClass: "vel-button",
          attrs: {
            type: "primary",
            loading:
              _vm.loading || (_vm.externalExport && _vm.isExternalGenerating),
            icon: _vm.isMediumUp ? "file-excel" : undefined
          },
          on: { click: this.exportXLS }
        },
        [
          _vm.isMediumDown
            ? _c("vel-icon", { attrs: { name: "file-download" } })
            : _vm._e(),
          _vm._v(
            " " +
              _vm._s(
                _vm.isMediumUp ? _vm.$t("table.tableExport.button.value") : ""
              ) +
              " "
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }