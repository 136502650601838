import { defineStore } from 'pinia';
import usersService from '@/services/users.service';
import Navigate from "@/navigation";


export const useDataUsersResetPassword = defineStore('usersResetPassword', {
  state: () => ({ password: {}, status: {}, error: null }),
  getters: {},
  actions: {
    resetPassword({ newPassword, resetToken }) {
      this.resetPasswordRequest();
      usersService.resetPassword(newPassword, resetToken).then(
        () => {
          this.resetPasswordSuccess();
          Navigate.toLogin();
        },
        error => {
          this.resetPasswordFailure(error);
        }
      );
    },
    verifyToken(resetToken) {
      this.verifyTokenRequest();
      usersService.verifyResetToken(resetToken).then(
        () => {
          this.verifyTokenSuccess();
        },
        error => {
          this.verifyTokenFailure(error);
        }
      );
    },
    resetTokenUnauthenticated(email) {
      this.resetTokenUnauthenticatedRequest();
      usersService.resetPasswordTokenUnauthenticated(email).then(
        () => {
          this.resetTokenUnauthenticatedSuccess();
        },
        error => {
          this.resetTokenUnauthenticatedFailure(error);
        }
      );
    },
    resetPasswordRequest() {
      this.status = { isLoading: true };
      this.error = null;
    },
    resetPasswordSuccess() {
      this.status = { hasPasswordReset: true };
      this.error = null;
    },
    resetPasswordFailure(error) {
      this.status = {};
      this.error = error;
    },
    verifyTokenRequest() {
      this.status = { isLoading: true };
      this.error = null;
    },
    verifyTokenSuccess() {
      this.status = { isValid: true };
      this.error = null;
    },
    verifyTokenFailure(error) {
      this.status = { isValid: false };
      this.error = error;
    },
    resetTokenUnauthenticatedRequest() {
      this.status = { isLoading: true };
      this.error = null;
    },
    resetTokenUnauthenticatedSuccess() {
      this.status = { isValid: true };
      this.error = null;
      console.log("success");
    },
    resetTokenUnauthenticatedFailure(error) {
      this.status = { isValid: false };
      this.error = error;
    }
  }
});
