var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "report",
      attrs: {
        showCrumbs: false,
        generatedTime: _vm.generatedTime,
        "enable-currency": true
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("pages.reports.sales")) + " ")]
          },
          proxy: true
        },
        {
          key: "toolbar",
          fn: function() {
            return [
              _c("vel-location-selector", {
                staticClass: "report__location-selector",
                attrs: {
                  allowGlobalLocationOption: "",
                  allLocationsLabel: _vm.$t(
                    "dashboard.locationSelector.allLocationsText"
                  ),
                  disabled: _vm.locationsLoading,
                  locationId: _vm.mutableLocationId,
                  locationsOptions: _vm.locations,
                  showGlobalLocationOptionGroupLabel: false
                },
                on: { change: _vm.handleLocationChange }
              }),
              _c("date-range-selector", {
                staticClass: "report__date-range-selector",
                attrs: { range: _vm.mutableRange },
                on: { change: _vm.handleDateRangeChange }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "vel-card",
        { staticClass: "report__sales" },
        [
          _c("sales-detailed", {
            staticClass: "report_table",
            attrs: {
              loading: _vm.areSalesLoading,
              sales: _vm.sales,
              hoverable: false,
              showQuantity: false,
              exportableMetas: _vm.exportableMetas
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }