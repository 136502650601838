var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLargeUp
    ? _c(
        "div",
        { class: "sidebar " + (_vm.appStore.isExpanded ? "" : "collapsed") },
        [
          _c("vel-button", {
            attrs: {
              type: "link",
              icon: "" + (_vm.appStore.isExpanded ? "menu-open" : "menu-closed")
            },
            on: { click: _vm.handleMenuCollapse }
          }),
          _vm.isUserAdmin
            ? _c(
                "router-link",
                {
                  class: this.selected === "analytics" ? "selected" : "",
                  attrs: { to: { name: "analytics" } }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "link-container",
                      on: {
                        mouseover: function($event) {
                          _vm.hover = "analytics"
                        },
                        mouseleave: function($event) {
                          _vm.hover = ""
                        }
                      }
                    },
                    [
                      !_vm.appStore.isExpanded
                        ? _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "light",
                                content: _vm.$t("sidebar.sections.analytics")
                              }
                            },
                            [
                              _c("vel-icon", {
                                attrs: {
                                  size: "medium",
                                  name:
                                    _vm.selected === "analytics" ||
                                    _vm.hover === "analytics"
                                      ? "analytics-solid"
                                      : "analytics"
                                }
                              })
                            ],
                            1
                          )
                        : _c("vel-icon", {
                            attrs: {
                              size: "medium",
                              name:
                                _vm.selected === "analytics" ||
                                _vm.hover === "analytics"
                                  ? "analytics-solid"
                                  : "analytics"
                            }
                          }),
                      _c(
                        "span",
                        {
                          class: "" + (_vm.appStore.isExpanded ? "" : "hidden")
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("sidebar.sections.analytics")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c(
            "router-link",
            {
              class: this.selected === "dashboard" ? "selected" : "",
              attrs: { to: { name: "dashboard" } }
            },
            [
              _c(
                "div",
                {
                  staticClass: "link-container",
                  on: {
                    mouseover: function($event) {
                      _vm.hover = "dashboard"
                    },
                    mouseleave: function($event) {
                      _vm.hover = ""
                    }
                  }
                },
                [
                  !_vm.appStore.isExpanded
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            content: _vm.$t("sidebar.sections.dashboard")
                          }
                        },
                        [
                          _c("vel-icon", {
                            attrs: {
                              size: "medium",
                              name:
                                _vm.selected === "dashboard" ||
                                _vm.hover === "dashboard"
                                  ? "dashboard-icon-solid"
                                  : "dashboard-icon"
                            }
                          })
                        ],
                        1
                      )
                    : _c("vel-icon", {
                        attrs: {
                          size: "medium",
                          name:
                            _vm.selected === "dashboard" ||
                            _vm.hover === "dashboard"
                              ? "dashboard-icon-solid"
                              : "dashboard-icon"
                        }
                      }),
                  _c(
                    "span",
                    { class: "" + (_vm.appStore.isExpanded ? "" : "hidden") },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("sidebar.sections.dashboard")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "router-link",
            {
              class:
                _vm.$route.meta.menu === "report" ? "router-link-active" : "",
              attrs: { to: "/navigation/reports" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "link-container",
                  on: {
                    mouseover: function($event) {
                      _vm.hover = "reports-menu"
                    },
                    mouseleave: function($event) {
                      _vm.hover = ""
                    }
                  }
                },
                [
                  !_vm.appStore.isExpanded
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            content: _vm.$t("sidebar.sections.reports")
                          }
                        },
                        [
                          _c("vel-icon", {
                            attrs: {
                              size: "medium",
                              name:
                                _vm.selected === "reports-menu" ||
                                _vm.hover === "reports-menu"
                                  ? "description-solid"
                                  : "description"
                            }
                          })
                        ],
                        1
                      )
                    : _c("vel-icon", {
                        attrs: {
                          size: "medium",
                          name:
                            _vm.selected === "reports-menu" ||
                            _vm.hover === "reports-menu"
                              ? "description-solid"
                              : "description"
                        }
                      }),
                  _c(
                    "span",
                    { class: "" + (_vm.appStore.isExpanded ? "" : "hidden") },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("sidebar.sections.reports")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm.isUserAdmin
            ? _c(
                "router-link",
                {
                  class:
                    _vm.$route.meta.menu === "configuration"
                      ? "router-link-active"
                      : "",
                  attrs: { to: "/navigation/config" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "link-container",
                      on: {
                        mouseover: function($event) {
                          _vm.hover = "configuration-menu"
                        },
                        mouseleave: function($event) {
                          _vm.hover = ""
                        }
                      }
                    },
                    [
                      !_vm.appStore.isExpanded
                        ? _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "light",
                                content: _vm.$t(
                                  "sidebar.sections.configuration"
                                )
                              }
                            },
                            [
                              _c("vel-icon", {
                                attrs: {
                                  size: "medium",
                                  name:
                                    _vm.$route.meta.menu === "configuration"
                                      ? "construction-solid"
                                      : "construction"
                                }
                              })
                            ],
                            1
                          )
                        : _c("vel-icon", {
                            attrs: {
                              size: "medium",
                              name:
                                _vm.$route.meta.menu === "configuration"
                                  ? "construction-solid"
                                  : "construction"
                            }
                          }),
                      _c(
                        "span",
                        {
                          class: "" + (_vm.appStore.isExpanded ? "" : "hidden")
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("sidebar.sections.configuration")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c(
            "router-link",
            {
              class:
                _vm.$route.meta.menu === "setup" ? "router-link-active" : "",
              attrs: { to: "/navigation/setup" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "link-container",
                  on: {
                    mouseover: function($event) {
                      _vm.hover = "setup"
                    },
                    mouseleave: function($event) {
                      _vm.hover = ""
                    }
                  }
                },
                [
                  !_vm.appStore.isExpanded
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            content: _vm.$t("sidebar.sections.settings")
                          }
                        },
                        [
                          _c("vel-icon", {
                            attrs: {
                              size: "medium",
                              name:
                                this.selected.includes("setup") ||
                                _vm.hover === "setup"
                                  ? "cog-solid"
                                  : "cog"
                            }
                          })
                        ],
                        1
                      )
                    : _c("vel-icon", {
                        attrs: {
                          size: "medium",
                          name:
                            _vm.$route.meta.menu === "setup"
                              ? "cog-solid"
                              : "cog"
                        }
                      }),
                  _c(
                    "span",
                    { class: "" + (_vm.appStore.isExpanded ? "" : "hidden") },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("sidebar.sections.settings")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "version" }, [
            _vm._v("v" + _vm._s(_vm.version))
          ])
        ],
        1
      )
    : _c(
        "div",
        {
          class:
            "sidebar " +
            (_vm.appStore.isExpanded ? "mobile" : "collapsed mobile")
        },
        [
          _c(
            "router-link",
            {
              class: {
                hidden: !_vm.appStore.isExpanded,
                selected: this.selected === "dashboard"
              },
              attrs: { to: { name: "dashboard" } }
            },
            [
              _c(
                "div",
                { staticClass: "link-container" },
                [
                  _c("vel-icon", {
                    attrs: {
                      size: "medium",
                      name:
                        _vm.selected === "dashboard"
                          ? "dashboard-icon-solid"
                          : "dashboard-icon"
                    }
                  }),
                  _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("sidebar.sections.dashboard")) + " "
                    )
                  ])
                ],
                1
              )
            ]
          ),
          _c(
            "router-link",
            {
              class: {
                hidden: !_vm.appStore.isExpanded,
                "router-link-active": _vm.$route.meta.menu === "report"
              },
              attrs: { to: "/navigation/reports" }
            },
            [
              _c(
                "div",
                { staticClass: "link-container" },
                [
                  _c("vel-icon", {
                    attrs: {
                      size: "medium",
                      name:
                        _vm.$route.meta.menu === "report"
                          ? "description-solid"
                          : "description"
                    }
                  }),
                  _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("sidebar.sections.reports")) + " "
                    )
                  ])
                ],
                1
              )
            ]
          ),
          _vm.isUserAdmin
            ? _c(
                "router-link",
                {
                  class: {
                    hidden: !_vm.appStore.isExpanded,
                    "router-link-active":
                      _vm.$route.meta.menu === "configuration"
                  },
                  attrs: { to: "/navigation/config" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "link-container" },
                    [
                      _c("vel-icon", {
                        attrs: {
                          size: "medium",
                          name:
                            _vm.$route.meta.menu === "configuration"
                              ? "construction-solid"
                              : "construction"
                        }
                      }),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("sidebar.sections.configuration")) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c(
            "router-link",
            {
              class: {
                hidden: !_vm.appStore.isExpanded,
                "router-link-active": _vm.$route.meta.menu === "setup"
              },
              attrs: { to: "/navigation/setup" }
            },
            [
              _c(
                "div",
                { staticClass: "link-container" },
                [
                  _c("vel-icon", {
                    attrs: {
                      size: "medium",
                      name:
                        _vm.$route.meta.menu === "setup" ? "cog-solid" : "cog"
                    }
                  }),
                  _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("sidebar.sections.settings")) + " "
                    )
                  ])
                ],
                1
              )
            ]
          ),
          _vm.appStore.isExpanded
            ? _c("div", { staticClass: "version" }, [
                _vm._v("v" + _vm._s(_vm.version))
              ])
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }