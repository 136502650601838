var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("indicator", {
    attrs: {
      color: "blue",
      amount: _vm.amount,
      "previous-amount": _vm.previousAmount,
      loading: _vm.loading,
      iconName: "indicator-sales",
      iconColor: "green"
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _vm._v(
              " " + _vm._s(_vm.$t("dashboard.grossIncomePerMeal.title")) + " "
            )
          ]
        },
        proxy: true
      },
      {
        key: "tooltip",
        fn: function() {
          return [
            _vm._v(
              " " +
                _vm._s(_vm.$t("dashboard.grossIncomePerMeal.description")) +
                " "
            )
          ]
        },
        proxy: true
      },
      {
        key: "value",
        fn: function() {
          return [
            _vm.amount
              ? _c("vel-amount", {
                  attrs: { currency: _vm.currency, amount: _vm.amount }
                })
              : _c("label", [_vm._v("-")])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }