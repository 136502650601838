var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-table", {
    ref: "table",
    staticClass: "mt-4",
    attrs: {
      "thead-class": "bg-info",
      striped: "",
      small: "",
      hover: "",
      bordered: "",
      "show-empty": "",
      fixed: "",
      "primary-key": "id",
      items: _vm.jobRoles,
      fields: _vm.fields,
      responsive: "sm",
      "empty-text": _vm.$t("jobRoles.empty"),
      "empty-filtered-text": _vm.$t("jobRoles.empty")
    },
    scopedSlots: _vm._u([
      {
        key: "empty",
        fn: function(scope) {
          return [
            _c("div", { staticClass: "p-2" }, [
              _vm._v(_vm._s(scope && scope.emptyText))
            ])
          ]
        }
      },
      {
        key: "emptyfiltered",
        fn: function(scope) {
          return [
            _c("div", { staticClass: "p-2" }, [
              _vm._v(_vm._s(scope && scope.emptyFilteredText))
            ])
          ]
        }
      },
      {
        key: "cell(edit)",
        fn: function(scope) {
          return [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.jobRolesEdit-sidebar",
                    modifiers: { "jobRolesEdit-sidebar": true }
                  }
                ],
                attrs: { variant: "link" },
                on: {
                  click: function($event) {
                    return _vm.$emit("onModify", scope)
                  }
                }
              },
              [
                _c("b-icon", {
                  staticClass: "row-action text-primary",
                  attrs: { icon: "pencil-fill", "aria-label": "Edit" }
                }),
                _vm._v(" " + _vm._s(_vm.$t("jobRoles.edit")) + " ")
              ],
              1
            )
          ]
        }
      },
      {
        key: "cell(delete)",
        fn: function(scope) {
          return [
            _c(
              "b-button",
              {
                attrs: { variant: "link text-danger" },
                on: {
                  click: function($event) {
                    return _vm.$emit("onDelete", scope.item)
                  }
                }
              },
              [
                _c("b-icon", {
                  staticClass: "row-action text-danger",
                  attrs: { icon: "trash-fill", "aria-label": "Delete" }
                }),
                _vm._v(" " + _vm._s(_vm.$t("mealPlan.delete")) + " ")
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }