<template>
  <vel-backoffice-page
    class="backoffice-page bootstrap"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    :scroll="false"
  >
    <template v-slot:header>
      <b-container fluid>
        <b-row class="d-flex flex-column flex-md-row pt-3">
          <b-col class="d-flex align-items-center">
            <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row class="my-4">
          <b-col cols="12" md="6" class="d-flex flex-row justify-content-between">
            <h4>{{ $t('itemsMixMatchList.title') }}</h4>
            <b-button
              variant="primary"
              class="ml-4 d-flex align-items-center d-md-none"
              :to="{ name: 'employee-create' }"
            >
              <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
              <span class="d-none d-md-block">{{ $t('itemsMixMatchList.add-new') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="d-flex mt-4 mt-md-0 flex-row justify-content-md-end flex-md-row">
            <b-input-group style="max-width: 250px;" class="align-content-end align-self-end">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="tableFilter"
                debounce="1"
                type="text"
                :placeholder="$t('employee.list.searchPlaceholder')"
              ></b-form-input>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-4 d-md-flex align-items-center d-none"
              :to="{ name: 'create-mix-match-page' }"
            >
              <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
              {{ $t('itemsMixMatchList.add-new') }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <b-container fluid class="h-100 mt-4">
      <!-- @todo the table doesn't update properly when deleting an item -->
      <b-table
        striped
        hover
        bordered
        show-empty
        fixed
        primary-key="id"
        :empty-text="$t('defaults.empty')"
        :empty-filtered-text="$t('defaults.empty')"
        :busy="loading"
        :fields="fields"
        head-variant="info"
        :items="getItemsMixMatchRows"
        responsive="sm"
        ref="table"
        sort-by="name"
        sticky-header
      >
        <template #empty="scope">
          <div class="p-2">{{ scope.emptyText }}</div>
        </template>
        <template #emptyfiltered="scope">
          <div class="p-2">{{ scope.emptyFilteredText }}</div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner variant="primary" class="align-middle p-2 m-4"></b-spinner>
          </div>
        </template>
        <template #cell(name)="scope">
          <div class="w-100 justify-content-between d-flex align-items-center">
            {{ scope.item.name }}
          </div>
        </template>
        <template #cell(edit)="scope">
          <b-button
            variant="link"
            @click="
              $router.push({
                name: 'edit-mix-match-page',
                params: { id: scope.item.id }
              })
            "
          >
            <vel-icon size="small" class="row-action text-primary p-0" name="xd-edit" />
            {{ $t('itemsMixMatchList.table.edit') }}
          </b-button>
        </template>
        <template #cell(delete)="scope">
          <b-button variant="link text-danger" @click="showDelete = scope.item">
            <b-icon icon="trash-fill" aria-label="Delete" class="row-action text-danger"></b-icon>
            {{ $t('defaults.actions.delete') }}
          </b-button>
        </template>
      </b-table>
      <mix-match-delete-modal
        :title="$t('itemsMixMatchList.modals.delete.title', { name: showDelete && showDelete.name })"
        :body="$t('itemsMixMatchList.modals.delete.body')"
        :show="!!showDelete"
        @onDelete="handleDeleteItemMixMatch"
        @onCancel="showDelete = null"
      ></mix-match-delete-modal>
    </b-container>

    <mix-match-delete-error-modal
      :title="deleteErrorModalTitle"
      :title-label="deleteErrorModalSubTitle"
      :file-identifier="deleteErrorModalIdentifier"
      :file-name="deleteErrorModalFileName"
      :body="deleteErrorModalBody"
      :list="itemsList || []"
      :location-name="locationName"
      :show="!!deleteErrorItemsList"
      @onCancel="deleteErrorItemsList = null"
    ></mix-match-delete-error-modal>
  </vel-backoffice-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import MixMatchDeleteErrorModal from '@/private/mix-match/components/MixMatchDeleteErrorModal';
import MixMatchDeleteModal from './components/MixMatchDeleteModal';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import VelIcon from '@/components/icon/VelIcon';
import { getToken } from '@/helpers/token.helper';
import mixMatchService from '@/services/mix-match.service';
import { slugify } from '@/helpers/string.helpers';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataMixMatch} from "@/stores/data/mix-match.module";

export default {
  name: 'ItemsMixMatchList',
  mixins: [DeviceMixin],
  components: {
    MixMatchDeleteModal,
    MixMatchDeleteErrorModal,
    VelBackofficePage,
    VelIcon
  },
  data() {
    return {
      showDelete: null,
      itemsList: null,
      locationName: '',
      deleteEntity: null,
      deleteErrorItemsList: null,
      deleteErrorModalBody: '',
      deleteErrorModalTitle: '',
      deleteErrorModalIdentifier: '',
      deleteErrorModalSubTitle: '',
      deleteErrorModalFileName: ''
    };
  },
  computed: {
    ...mapPiniaState(useDataMixMatch, ['getItemsMixMatchRows', 'loading', 'success', 'mode', 'name']),
    ...mapPiniaState(useDataMixMatch, ['filter']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    breadcrumbs() {
      return [
        {
          text: this.$t('itemsMixMatchList.breadcrumbs.setup'),
          to: { name: 'setup-menu', query: { previous: 'menu', ...this.$router.currentRoute.query } }
        },
        {
          text: this.$t('itemsMixMatchList.breadcrumbs.mix-match')
        }
      ];
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('itemsMixMatchList.table.fields.name'),
          class: 'name',
          sortable: true
        },
        {
          key: 'edit',
          class: 'edit'
        },
        {
          key: 'delete',
          class: 'edit text-center'
        }
      ];
    },
    tableFilter: {
      get() {
        return this.filter;
      },
      set(val) {
        this.setFilter(val);
      }
    }
  },
  methods: {
    ...mapPiniaActions(useDataMixMatch, ['getItemsMixMatch', 'setFilter', 'clearSuccessMessage', 'deleteItemMixMatch']),

    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    async handleDeleteItemMixMatch() {
      this.deleteErrorItemsList = null;
      try {
        await mixMatchService.deleteItemMixMatch(getToken(), this.showDelete.id);
        this.toast(this.$t('itemsMixMatchList.messages.deleted'));
        this.showDelete = null;
      } catch (e) {
        this.deleteErrorItemsList = this.showDelete;
        this.deleteEntity = this.showDelete;
        this.itemsList = e;
        this.deleteErrorModalTitle = this.$t('itemsMixMatchList.deleteModal.error.title', {
          name: this.deleteEntity.name
        });
        this.deleteErrorModalSubTitle = this.$t('itemsMixMatchList.deleteModal.error.items.subTitle');
        this.deleteErrorModalBody = this.$t('itemsMixMatchList.deleteModal.error.items.body', {
          count: (e || []).length,
          name: this.deleteEntity.name
        });
        this.deleteErrorModalIdentifier = slugify(this.deleteEntity.name);
        this.deleteErrorModalFileName = slugify(this.$t('itemsMixMatchList.title'));
        this.showDelete = null;
      }
      await this.getItemsMixMatch();
    }
  },
  async mounted() {
    this.clearSuccessMessage();
    await this.getItemsMixMatch();

    if(this.$route) {
      this.locationName = (this.getLocationsById(this.$route.query.locationId) || {}).name;
    }
  },
  destroyed() {
    this.setFilter('');
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.bootstrap .container-fluid {
  padding-left: 24px;
  padding-right: 24px;
}

/* stylelint-disable */
/deep/ {
  .input-group > .form-control {
    border-left-color: #fff;
    padding-left: 0;
  }

  .input-group-text {
    background-color: #fff !important;
    border-right: 1px solid #fff !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .breadcrumb {
    line-height: 2.375rem;
  }

  th {
    background-color: #eff9fd;
  }

  .page,
  .page .vel-page__content {
    overflow: hidden;
  }

  .vel-alert {
    align-self: center;
    bottom: 40px;
    max-width: fit-content;
    position: absolute;
  }

  .w-fit-content {
    max-width: fit-content;
  }

  .overlay {
    background: rgba(#000000, 0.1);
    bottom: 0;
    height: calc(100vh - 60px);
    position: fixed;
    z-index: 99;

    &.toggled {
      width: 100vw;
    }

    .drawer {
      background: #fff;
      box-shadow: 0 0 0 1px rgba(#505f79, 0.05), 0 3px 3px 0 rgba(#505f79, 0.15);
      display: hidden;
      height: calc(100vh - 60px);
      padding: 0;
      position: fixed;
      right: -100%;
      transition: all 0.2s;
      width: 100%;

      @include breakpoint(large) {
        width: 25%;
      }

      &.toggled {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        right: 0;
      }
    }

    .header {
      font-weight: 500;
      justify-content: center;
    }

    .footer {
      justify-content: space-between;
      padding: 0 24px;
    }

    .header,
    .footer {
      align-items: center;
      background: #f4f4f4;
      display: flex;
      flex-direction: row;
      height: 64px;
      width: 100%;
    }

    .body {
      background: #fff;
      height: 100%;
      width: 100%;

      .form-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 36px auto 0 24px;
        max-width: 330px;

        label,
        small {
          margin-left: 0.75rem;
        }
      }

      input {
        background: rgba(#00a9e1, 0.1);
        font-size: 16px;

        @include breakpoint(large) {
          max-width: 320px;
        }
      }
    }
  }

  .content,
  .vel-card__body,
  .b-table-sticky-header {
    height: 100%;
    max-height: 100%;
    padding-top: 0;
  }

  .btn-group {
    button {
      text-transform: uppercase;
      width: 160px;
    }
  }

  .pagination {
    height: calc(1.5em + 0.5rem + 2px);
    display: flex;
    align-items: center;
  }

  .thead-info {
    background-color: #eff9fd;
    font-weight: bold;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6fbfe !important;
  }

  .table-hover tbody tr:hover {
    background-color: transparent !important;
  }

  .table-hover tbody tr:nth-of-type(odd):hover {
    background-color: #f6fbfe !important;
  }

  .name {
    @include breakpoint(large) {
      width: auto;
    }
  }

  .edit {
    width: 120px;

    div {
      display: none;
    }
  }

  .delete {
    width: 120px;

    div {
      display: none;
    }
  }

  th,
  td {
    font-size: 0.9rem;
  }

  th {
    user-select: none;
  }

  .content {
    margin-bottom: 0;
  }

  .row-action {
    color: #444444;
  }

  .page-item.active .page-link {
    background-color: #000 !important;
  }

  .page-link {
    border: none !important;
    color: #444444;
  }

  .toolbar__location-selector {
    max-width: 100%;

    @include breakpoint(large) {
      max-width: 360px;
    }
  }

  .vel-modal {
    max-width: 560px;

    &__body {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}
</style>
