var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    _vm._b(
      {
        staticClass: "report",
        attrs: {
          "multiple-locations": _vm.locations && _vm.locations.length > 1,
          "show-selector-button": true
        },
        scopedSlots: _vm._u([
          {
            key: "export",
            fn: function() {
              return [
                !_vm.isDataLoading
                  ? _c(
                      "vel-button",
                      {
                        staticClass: "vel-button",
                        attrs: {
                          type: "primary",
                          icon: _vm.isMediumUp ? "file-excel" : undefined
                        },
                        on: { click: _vm.exportXLS }
                      },
                      [
                        _vm.isMediumDown
                          ? _c("vel-icon", { attrs: { name: "file-download" } })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isMediumUp
                                ? _vm.$t("table.tableExport.button.value")
                                : ""
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      },
      "vel-page",
      _vm.pageAttrs,
      false
    ),
    [
      _vm.invalidLocations && !_vm.isVersionLoading && _vm.isGenerated
        ? _c(
            "div",
            {
              staticStyle: {
                "background-color": "#fff2cc",
                "margin-bottom": "1rem",
                padding: "1rem"
              }
            },
            [
              _vm.getLocationSelectorSelected
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invalidVersions.requiredVersion")) +
                        " "
                    )
                  ]
                : [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "invalidVersions.requiredVersionMultiLocations"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript://" },
                        on: {
                          click: function($event) {
                            _vm.showLocationsList = !_vm.showLocationsList
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("invalidVersions.collapse")))]
                    ),
                    _vm.showLocationsList
                      ? _c(
                          "ul",
                          { staticStyle: { "margin-top": "1rem" } },
                          _vm._l(_vm.invalidLocations, function(location) {
                            return _c("li", { key: location.id }, [
                              _vm._v(" " + _vm._s(location.name) + " ")
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm.showNotification && !_vm.isVersionLoading && _vm.isGenerated
        ? _c(
            "p",
            {
              staticStyle: {
                "background-color": "#ffd2d2",
                "margin-bottom": "1rem",
                padding: "1rem"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("invalidVersions.notice")) + " ")]
          )
        : _vm._e(),
      !_vm.isAllInvalid &&
      !_vm.invalidLocations &&
      !_vm.showNotification &&
      !_vm.isVersionLoading &&
      _vm.isGenerated
        ? [
            _c("indicators", {
              key: _vm.updateIndicators,
              staticClass: "indicators",
              attrs: {
                "config-key": "summary",
                "show-icon": false,
                secondary: ""
              }
            }),
            _c(
              "div",
              { staticClass: "graph" },
              [
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value: "locations_report_show_locations_sales",
                        expression: "'locations_report_show_locations_sales'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "locations-sales-graph",
                      _vm._b(
                        {
                          ref: "locationsSalesTop",
                          attrs: { "show-previous-series": true }
                        },
                        "locations-sales-graph",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value: "locations_report_show_item_sales_top10",
                        expression: "'locations_report_show_item_sales_top10'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "items-sales-graph",
                      _vm._b(
                        { ref: "itemsSales" },
                        "items-sales-graph",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "indicator-grid",
              { staticClass: "sub-indicators" },
              [
                _c("refunds", {
                  ref: "refunds",
                  attrs: {
                    quantity: _vm.getIndicatorsRefunds,
                    amount: _vm.getIndicatorsRefundsAmount,
                    "previous-amount": _vm.getIndicatorsSalesVolume,
                    percent: _vm.getIndicatorsRefundsPercent,
                    currency: _vm.getCurrencySelectorSelectedSymbol
                  }
                }),
                _c("voids", {
                  ref: "voids",
                  attrs: {
                    quantity: _vm.getIndicatorsVoids,
                    amount: _vm.getIndicatorsVoidsAmount,
                    "previous-amount": _vm.getIndicatorsSalesVolume,
                    percent: _vm.getIndicatorsVoidsPercent,
                    currency: _vm.getCurrencySelectorSelectedSymbol
                  }
                }),
                _c("item-correction", {
                  ref: "itemCorrection",
                  attrs: {
                    quantity: _vm.getIndicatorsItemCorrections,
                    amount: _vm.getIndicatorsItemCorrectionsAmount,
                    "previous-amount": _vm.getIndicatorsSalesVolume,
                    currency: _vm.getCurrencySelectorSelectedSymbol
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "graph" },
              [
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value: "locations_report_show_volume_per_tender_types",
                        expression:
                          "'locations_report_show_volume_per_tender_types'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "volume-per-tender-types",
                      _vm._b(
                        { ref: "tenderTypes" },
                        "volume-per-tender-types",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                ),
                _vm.hasSingleLocation
                  ? _c(
                      "vel-card",
                      {
                        directives: [
                          {
                            name: "can",
                            rawName: "v-can:show",
                            value:
                              "locations_report_show_employees_sales_top10",
                            expression:
                              "'locations_report_show_employees_sales_top10'",
                            arg: "show"
                          }
                        ]
                      },
                      [
                        _c(
                          "employee-graph",
                          _vm._b({}, "employee-graph", _vm.chartAttrs, false)
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value:
                          "locations_report_show_gross_sales_per_order_mode",
                        expression:
                          "'locations_report_show_gross_sales_per_order_mode'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "zone-sales",
                      _vm._b(
                        { ref: "zoneSales" },
                        "zone-sales",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value: "locations_report_show_workstations_sales",
                        expression:
                          "'locations_report_show_workstations_sales'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "workstations-sales",
                      _vm._b(
                        { ref: "workstationSales" },
                        "workstations-sales",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value:
                          "locations_report_show_gross_sales_per_department",
                        expression:
                          "'locations_report_show_gross_sales_per_department'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "department-sales",
                      _vm._b(
                        { ref: "departmentSales" },
                        "department-sales",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value: "locations_report_show_gross_sales_per_mode",
                        expression:
                          "'locations_report_show_gross_sales_per_mode'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "mode-sales",
                      _vm._b(
                        { ref: "modeSales" },
                        "mode-sales",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value: "locations_report_show_mode_sales_per_customers",
                        expression:
                          "'locations_report_show_mode_sales_per_customers'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "mode-sales-per-customers",
                      _vm._b(
                        { ref: "modeSalesPerCustomers" },
                        "mode-sales-per-customers",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value: "locations_report_show_customers_per_mode",
                        expression:
                          "'locations_report_show_customers_per_mode'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "mode-customers",
                      _vm._b(
                        { ref: "modeCustomers" },
                        "mode-customers",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "vel-card",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value:
                          "locations_report_show_gross_sales_per_big_division",
                        expression:
                          "'locations_report_show_gross_sales_per_big_division'",
                        arg: "show"
                      }
                    ]
                  },
                  [
                    _c(
                      "big-division-sales-graph",
                      _vm._b(
                        { ref: "bigDivisionSales" },
                        "big-division-sales-graph",
                        _vm.chartAttrs,
                        false
                      )
                    )
                  ],
                  1
                ),
                _vm.config.dashboard.enable_pie_payment
                  ? _c(
                      "vel-card",
                      [
                        _c(
                          "paymode-sales",
                          _vm._b({}, "paymode-sales", _vm.chartAttrs, false)
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "vel-card",
              {
                directives: [
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: "locations_report_show_discounts_table",
                    expression: "'locations_report_show_discounts_table'",
                    arg: "show"
                  }
                ]
              },
              [_c("discounts-table", { ref: "discountSales" })],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }