var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-modal", {
    staticClass: "coupon-delete__modal",
    attrs: { dismissable: "" },
    on: { dismiss: _vm.handleCancel },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _vm._v(
              " " + _vm._s(_vm.$t("coupons.modals.batchDelete.title")) + " "
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("span", {
              staticClass: "coupon-delete__confirmation",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("coupons.modals.batchDelete.textHtmlConfirm", {
                    count: _vm.count,
                    group: _vm.getSelectedGroup.label
                  })
                )
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "vel-button",
              {
                staticClass: "coupon-delete__close-button",
                attrs: { type: "secondary" },
                on: { click: _vm.handleCancel }
              },
              [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
            ),
            _c(
              "vel-button",
              {
                staticClass: "coupon-delete__confirm-button",
                attrs: { type: "critical" },
                on: { click: _vm.handleDelete }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("coupons.modals.delete.button.text")) +
                    " "
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }