var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "permissionForm",
      staticClass: "permission-form",
      attrs: {
        model: _vm.permissionForm,
        rules: _vm.permissionFormRules,
        "label-width": "150px"
      }
    },
    [
      _vm.isAddMode
        ? _c(
            "vel-form-item",
            {
              staticClass: "permission-form__type",
              attrs: {
                prop: "permissionType",
                label: _vm.$t("user.permissions.form.type.label")
              }
            },
            [
              _c(
                "vel-radio-group",
                {
                  on: { change: _vm.handlePermissionTypeChange },
                  model: {
                    value: _vm.permissionType,
                    callback: function($$v) {
                      _vm.permissionType = $$v
                    },
                    expression: "permissionType"
                  }
                },
                [
                  _c("vel-radio-button", { attrs: { label: "global" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("user.permissions.form.type.global")) +
                        " "
                    )
                  ]),
                  _c("vel-radio-button", { attrs: { label: "group" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("user.permissions.form.type.group")) +
                        " "
                    )
                  ]),
                  _c("vel-radio-button", { attrs: { label: "location" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("user.permissions.form.type.location")) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.permissionType
        ? [
            _vm.isLocationPermissionType
              ? [
                  _c(
                    "vel-form-item",
                    {
                      staticClass: "permission-form__location",
                      attrs: {
                        prop: "locationId",
                        label: _vm.$t("user.permissions.form.location.label"),
                        required: ""
                      }
                    },
                    [
                      _c("vel-location-selector", {
                        attrs: {
                          locationId: _vm.permissionForm.locationId,
                          locationsOptions: _vm.locationsOptions,
                          multiple: _vm.allowMultipleLocations,
                          selectFirst: false,
                          autofocus: "",
                          filterable: ""
                        },
                        on: { change: _vm.handleLocationChange }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.isGroupPermissionType
              ? [
                  _c(
                    "vel-form-item",
                    {
                      staticClass: "permission-form__group",
                      attrs: {
                        prop: "groupId",
                        label: _vm.$t("user.permissions.form.group.label"),
                        required: ""
                      }
                    },
                    [
                      _c("vel-group-selector", {
                        attrs: {
                          groupId: _vm.permissionForm.groupId,
                          groupsOptions: _vm.groupsOptions,
                          multiple: _vm.allowMultipleGroups,
                          autofocus: "",
                          filterable: ""
                        },
                        on: { change: _vm.handleGroupChange }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _c(
              "vel-form-item",
              {
                staticClass: "permission-form__permission",
                attrs: {
                  prop: "permission",
                  label: _vm.$t("user.permissions.form.permission.label"),
                  required: ""
                }
              },
              [
                _c(
                  "vel-select",
                  {
                    attrs: {
                      placeholder: _vm.$t(
                        "user.permissions.form.permission.placeholder"
                      )
                    },
                    model: {
                      value: _vm.permissionForm.permission,
                      callback: function($$v) {
                        _vm.$set(_vm.permissionForm, "permission", $$v)
                      },
                      expression: "permissionForm.permission"
                    }
                  },
                  _vm._l(_vm.permissionsOptions, function(option) {
                    return _c("vel-option", {
                      key: option,
                      attrs: { label: option, value: option }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "vel-form-item",
        { staticClass: "permission-form__actions" },
        [
          _c(
            "vel-button",
            {
              staticClass: "actions__submit",
              attrs: { type: "primary", disabled: _vm.isSubmitDisabled },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
          ),
          _c(
            "vel-button",
            {
              staticClass: "actions__cancel",
              attrs: { type: "secondary" },
              on: {
                click: function($event) {
                  return _vm.handleCancel()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }