var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "vel-button",
      class: _vm.classes,
      attrs: {
        type: _vm.htmlType,
        disabled: _vm.isDisabled,
        "aria-disabled": _vm.isDisabled
      },
      on: { click: _vm.handleClick }
    },
    [
      _vm.isLoading
        ? _c(
            "span",
            { staticClass: "vel-button__loader" },
            [
              _c("vel-spinner", {
                attrs: { size: "small", modifier: _vm.modifier }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.hasIcon
        ? _c(
            "span",
            {
              staticClass: "vel-button__icon",
              class: { "vel-button__icon_with-text": _vm.hasText }
            },
            [
              _c("vel-icon", {
                staticClass: "vel-button__picto",
                attrs: { modifier: _vm.modifier, name: _vm.icon }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.hasText
        ? _c(
            "span",
            { staticClass: "vel-button__text" },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }