var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    { attrs: { "show-crumbs": false, generation: false } },
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("setupMenuPage.title")))
      ]),
      _c("template", { slot: "export" }, [
        _vm.isMediumUp
          ? _c(
              "span",
              { staticClass: "toolbar" },
              [
                [_vm._v(_vm._s(_vm.$t("setupMenuPage.toolbarLabel")))],
                _c("toolbar-selectors", {
                  attrs: {
                    "enable-group": false,
                    "enable-all-locations": false,
                    "enable-date-picker": false
                  }
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _vm.isMediumDown
        ? _c(
            "span",
            { staticClass: "toolbar mobile" },
            [
              _c("toolbar-selectors", {
                attrs: {
                  "enable-group": false,
                  "enable-all-locations": false,
                  "enable-date-picker": false
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "submenu" }, [
        _c(
          "div",
          { staticClass: "submenu-content" },
          [
            _vm.isUserAdmin
              ? _c(
                  "vel-button",
                  {
                    class: _vm.selected === "discounts" ? "selected" : "",
                    on: {
                      click: function($event) {
                        return _vm.handleSelect("discounts")
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screenSelector.discounts")
                        ) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            _vm.isUserAdmin
              ? _c(
                  "vel-button",
                  {
                    class: _vm.selected === "menu" ? "selected" : "",
                    on: {
                      click: function($event) {
                        return _vm.handleSelect("menu")
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("setupMenuPage.screenSelector.menu")) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            _vm.isUserAdmin
              ? _c(
                  "vel-button",
                  {
                    class:
                      _vm.selected === "business-settings" ? "selected" : "",
                    on: {
                      click: function($event) {
                        return _vm.handleSelect("business-settings")
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screenSelector.business")
                        ) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            _vm.isUserAdmin
              ? _c(
                  "vel-button",
                  {
                    class: _vm.selected === "taxes" ? "selected" : "",
                    on: {
                      click: function($event) {
                        return _vm.handleSelect("taxes")
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("setupMenuPage.screenSelector.taxes")) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "vel-button",
              {
                class: _vm.selected === "settings" ? "selected" : "",
                on: {
                  click: function($event) {
                    return _vm.handleSelect("settings")
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("setupMenuPage.screenSelector.settings")) +
                    " "
                )
              ]
            ),
            _c(
              "vel-button",
              {
                class: _vm.selected === "admin" ? "selected" : "",
                on: {
                  click: function($event) {
                    return _vm.handleSelect("admin")
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("setupMenuPage.screenSelector.admin")) +
                    " "
                )
              ]
            ),
            _vm.isUserAdmin
              ? _c(
                  "vel-button",
                  {
                    class: _vm.selected === "language-setup" ? "selected" : "",
                    on: {
                      click: function($event) {
                        return _vm.handleSelect("language-setup")
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screenSelector.language-setup")
                        ) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "vel-button",
              {
                class: _vm.selected === "system-setup" ? "selected" : "",
                on: {
                  click: function($event) {
                    return _vm.handleSelect("system-setup")
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("setupMenuPage.screenSelector.system")) +
                    " "
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.selected === "discounts"
            ? _c(
                "div",
                { staticClass: "discounts" },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t("setupMenuPage.screens.titles.discounts"))
                    )
                  ]),
                  _c("router-link", { attrs: { to: { name: "discounts" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screens.links.discounts.promos")
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.selected === "menu"
            ? _c(
                "div",
                { staticClass: "menu" },
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("setupMenuPage.screens.titles.menu")))
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "bigdivisions" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.menu.categories"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("router-link", { attrs: { to: { name: "divisions" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "setupMenuPage.screens.links.menu.sub-categories"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("router-link", { attrs: { to: { name: "salesItems" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screens.links.menu.items")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "item-profile" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.menu.itemProfile"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("router-link", { attrs: { to: { name: "popups" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screens.links.menu.popups")
                        ) +
                        " "
                    )
                  ]),
                  _c("router-link", { attrs: { to: { name: "combos" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screens.links.menu.combos")
                        ) +
                        " "
                    )
                  ]),
                  _c("router-link", { attrs: { to: { name: "mix-match" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screens.links.menu.mix-match")
                        ) +
                        " "
                    )
                  ]),
                  _c("router-link", { attrs: { to: { name: "coursing" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screens.links.menu.coursing")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "big-departments-setup" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.menu.big-departments"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("router-link", { attrs: { to: { name: "counters" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screens.links.menu.counters")
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.selected === "business-settings"
            ? _c(
                "div",
                { staticClass: "business-settings" },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t("setupMenuPage.screens.titles.business"))
                    )
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "location-configuration" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.business.business"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.selected === "taxes" && _vm.isUserAdmin
            ? _c(
                "div",
                { staticClass: "taxes" },
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("setupMenuPage.screens.titles.taxes")))
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "taxes-setup" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("setupMenuPage.screens.links.taxes.taxes")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "tax-categories-setup" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.taxes.tax-categories"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.selected === "settings"
            ? _c(
                "div",
                { staticClass: "settings" },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t("setupMenuPage.screens.titles.settings"))
                    )
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "globalSettings" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.settings.customization"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.selected === "admin" && _vm.isUserAdmin
            ? _c(
                "div",
                { staticClass: "admin" },
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("setupMenuPage.screens.titles.admin")))
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "locations-default" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.admin.locations"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "groups-default" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("setupMenuPage.screens.links.admin.groups")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "resellers-default" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.admin.resellers"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "coupons-default" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("setupMenuPage.screens.links.admin.coupons")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("router-link", { attrs: { to: { name: "analytics" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("setupMenuPage.screens.links.admin.analytics")
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.selected === "admin" && !_vm.isUserAdmin
            ? _c(
                "div",
                { staticClass: "admin" },
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("setupMenuPage.screens.titles.admin")))
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "locations-default" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.admin.locations"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _vm.hasCouponsAccess
                    ? _c(
                        "router-link",
                        { attrs: { to: { name: "coupons-default" } } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "setupMenuPage.screens.links.admin.coupons"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.selected === "language-setup"
            ? _c(
                "div",
                { staticClass: "language-setup" },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("setupMenuPage.screens.titles.language-setup")
                      )
                    )
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "language-setup" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.menu.language-setup"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.selected === "system-setup"
            ? _c(
                "div",
                { staticClass: "system-setup" },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t("setupMenuPage.screens.titles.system"))
                    )
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "locations-status" } } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "setupMenuPage.screens.links.system.siteStatus"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isSuccess
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "success" },
                  on: { dismiss: _vm.clearMessage }
                },
                [_vm._v(_vm._s(_vm.successMessage))]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }