var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "vel-link",
      class: _vm.classes,
      attrs: {
        "active-class": "vel-link_active",
        "exact-active-class": "vel-link_active_exact",
        to: _vm.to,
        tag: _vm.tag,
        target: _vm.target
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }