var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pie-line-chart", {
    staticClass: "hourly-sales__chart",
    attrs: {
      pending: _vm.isPending,
      options: _vm.chartOptions,
      series: _vm.series,
      labels: _vm.labels,
      currency: _vm.currencyId,
      title: _vm.$t("sales.bigDivisionChart.title", { limit: _vm.limit }),
      type: _vm.get.default_chart_sales_per_big_division.value
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }