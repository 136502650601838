var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    { attrs: { loading: _vm.loading, title: _vm.$t("discountInfo.title") } },
    [
      _c(
        "vel-card",
        [
          _c("discount-form", {
            attrs: { discount: _vm.selectSelected },
            on: { submit: _vm.handleSubmit }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }