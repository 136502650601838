import { DateTime } from 'luxon';

export default date => {
  if (!date) {
    return '-';
  }

  const dt = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  return dt.setZone('UTC').toFormat(`HH'h'`);
};
