var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-100 d-flex",
      staticStyle: { background: "#fff", padding: "24px 0 0 0" }
    },
    [
      _c(
        "b-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.alwaysActive,
              expression: "!alwaysActive"
            }
          ],
          staticClass: "activation-row mx-0"
        },
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label:
                      _vm.index === 0 &&
                      _vm.$t("createMixMatch.activation.startDate"),
                    "label-align": "top"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "99/99/9999",
                            expression: "'99/99/9999'"
                          }
                        ],
                        attrs: {
                          id: "example-input",
                          type: "text",
                          placeholder: _vm.$t("employeeCreate.datePlaceholder"),
                          value: _vm.startDate,
                          autocomplete: "off"
                        },
                        on: { change: _vm.setStartDate }
                      }),
                      _c(
                        "b-input-group-append",
                        { staticStyle: { border: "1px solid #ced4da" } },
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              "button-only": "",
                              locale: "fr",
                              right: "",
                              "button-variant": "white",
                              "label-no-date-selected": _vm.$t(
                                "datePicker.noDate"
                              ),
                              "label-calendar": _vm.$t("datePicker.calendar"),
                              "label-close-button": _vm.$t("datePicker.close"),
                              "label-current-month": _vm.$t(
                                "datePicker.currentMonth"
                              ),
                              "label-help": _vm.$t("datePicker.help"),
                              "label-nav": _vm.$t("datePicker.nav"),
                              "label-next-decade": _vm.$t(
                                "datePicker.nextDecade"
                              ),
                              "label-next-month": _vm.$t(
                                "datePicker.nextMonth"
                              ),
                              "label-next-year": _vm.$t("datePicker.nextYear"),
                              "label-prev-decade": _vm.$t(
                                "datePicker.previousDecade"
                              ),
                              "label-prev-month": _vm.$t(
                                "datePicker.previousMonth"
                              ),
                              "label-prev-year": _vm.$t(
                                "datePicker.previousYear"
                              ),
                              "label-reset-button": _vm.$t("datePicker.reset"),
                              "label-today": _vm.$t("datePicker.today"),
                              "label-today-button": _vm.$t(
                                "datePicker.selectToday"
                              ),
                              "date-format-options": {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit"
                              },
                              "aria-controls": "example-input"
                            },
                            on: {
                              input: function($event) {
                                _vm.formData.endDate = _vm.formData.startDate
                              },
                              context: _vm.onSelectStartDate
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "button-content",
                                fn: function() {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "calendar-alt" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.formData.startDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "startDate", $$v)
                              },
                              expression: "formData.startDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label:
                      _vm.index === 0 &&
                      _vm.$t("createMixMatch.activation.endDate")
                  }
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "99/99/9999",
                            expression: "'99/99/9999'"
                          }
                        ],
                        attrs: {
                          id: "example-input",
                          type: "text",
                          placeholder: _vm.$t("employeeCreate.datePlaceholder"),
                          value: _vm.endDate,
                          autocomplete: "off"
                        },
                        on: { change: _vm.setEndDate }
                      }),
                      _c(
                        "b-input-group-append",
                        { staticStyle: { border: "1px solid #ced4da" } },
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              "button-only": "",
                              locale: "fr",
                              right: "",
                              "button-variant": "white",
                              "label-no-date-selected": _vm.$t(
                                "datePicker.noDate"
                              ),
                              "label-calendar": _vm.$t("datePicker.calendar"),
                              "label-close-button": _vm.$t("datePicker.close"),
                              "label-current-month": _vm.$t(
                                "datePicker.currentMonth"
                              ),
                              "label-help": _vm.$t("datePicker.help"),
                              "label-nav": _vm.$t("datePicker.nav"),
                              "label-next-decade": _vm.$t(
                                "datePicker.nextDecade"
                              ),
                              "label-next-month": _vm.$t(
                                "datePicker.nextMonth"
                              ),
                              "label-next-year": _vm.$t("datePicker.nextYear"),
                              "label-prev-decade": _vm.$t(
                                "datePicker.previousDecade"
                              ),
                              "label-prev-month": _vm.$t(
                                "datePicker.previousMonth"
                              ),
                              "label-prev-year": _vm.$t(
                                "datePicker.previousYear"
                              ),
                              "label-reset-button": _vm.$t("datePicker.reset"),
                              "label-today": _vm.$t("datePicker.today"),
                              "label-today-button": _vm.$t(
                                "datePicker.selectToday"
                              ),
                              "date-format-options": {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit"
                              },
                              "aria-controls": "example-input",
                              min: new Date(
                                new Date(_vm.formData.startDate).setDate(
                                  new Date(_vm.formData.startDate).getDate() + 1
                                )
                              )
                            },
                            on: { context: _vm.onSelectEndDate },
                            scopedSlots: _vm._u([
                              {
                                key: "button-content",
                                fn: function() {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "calendar-alt" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.formData.endDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "endDate", $$v)
                              },
                              expression: "formData.endDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label:
                      _vm.index === 0 &&
                      _vm.$t("createMixMatch.activation.weekDay")
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.availableWeekDays,
                      "value-field": "value",
                      "text-field": "day",
                      "aria-describedby": "input-live-help input-live-feedback"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { disabled: "", value: "" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "createMixMatch.activation.weekDays.placeholder"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.formData.weekday,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "weekday", $$v)
                      },
                      expression: "formData.weekday"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label:
                      _vm.index === 0 &&
                      _vm.$t("createMixMatch.activation.startTime")
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.availableHours,
                      "value-field": "value",
                      "text-field": "time"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: "", disabled: "" } },
                              [_vm._v("HH:MM")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.formData.startTime,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "startTime", $$v)
                      },
                      expression: "formData.startTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label:
                      _vm.index === 0 &&
                      _vm.$t("createMixMatch.activation.endTime")
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.availableHours,
                      "value-field": "value",
                      "text-field": "time"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: "", disabled: "" } },
                              [_vm._v("HH:MM")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.formData.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "endTime", $$v)
                      },
                      expression: "formData.endTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "d-flex align-items-center", attrs: { cols: "1" } },
        [
          _vm.showDeleteButton && !_vm.alwaysActive
            ? _c("b-icon", {
                staticClass: "rounded mr-2",
                staticStyle: { cursor: "pointer" },
                attrs: {
                  icon: "x-circle",
                  "font-scale": "1.5",
                  variant: "danger"
                },
                on: { click: _vm.removeActivation }
              })
            : _vm._e(),
          !_vm.alwaysActive && _vm.activations.length < 5
            ? _c("b-icon", {
                staticClass: "rounded",
                staticStyle: { cursor: "pointer" },
                attrs: {
                  icon: "plus-circle",
                  "font-scale": "1.5",
                  variant: "primary"
                },
                on: { click: _vm.addActivation }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }