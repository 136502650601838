var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-input-group",
    [
      _vm.label
        ? _c("vel-label", {
            attrs: { name: _vm.name, required: _vm.required },
            domProps: { textContent: _vm._s(_vm.label) }
          })
        : _vm._e(),
      _c("input", {
        staticClass: "vel-input",
        class: _vm.classes,
        attrs: {
          "aria-disabled": _vm.disabled,
          disabled: _vm.disabled,
          required: _vm.required,
          "aria-required": _vm.required,
          type: _vm.type,
          placeholder: _vm.placeholder,
          "aria-label": _vm.label,
          text: _vm.text,
          name: _vm.name,
          maxlength: _vm.maxlength
        },
        domProps: { value: _vm.value },
        on: { input: _vm.handleInput }
      }),
      _vm.hasErrors
        ? _c("vel-form-error", { attrs: { error: _vm.errors.first(_vm.name) } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }