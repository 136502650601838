import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';
import {useDataSalesIndicators} from "@/stores/data/sales/indicators.module";
import discountsModuleHelper from "@/stores/discounts.module.helper";
import groupsService from "@/services/groups.service";
import {createCollectionState, getSelectors, removeAll, setAll} from "@/helpers/stores.helpers";

const { selectAll } = getSelectors();

export const useDataGroupsLocationGroup = defineStore('groupsLocationGroup', {
  state: () => createCollectionState({
    status: {},
    errors: {}
  }),
  getters: {
    getGroupLocationsError(state) {
      return this.errors.getGroupLocationsError || null
},
    addGroupLocationsError(state) {
      return this.errors.addGroupLocationsError || null
    },
    deleteGroupLocationError(state) {
      return this.errors.deleteGroupLocationError || null
    },
    isSavingLocations: state => !!state.status.isSavingLocations,
    isLoadingGroupLocations: state => !!state.status.isLoadingGroupLocations,
    latestAddedGroupLocationsCount: state => (state.status && state.status.latestAddedGroupLocationsCount) || null,
    latestDeletedGroupLocation: state => (state.status && state.status.latestDeletedGroupLocation) || null,
    locations: selectAll
  },
  actions: {
    getGroupLocations(groupId) {
      this.getGroupLocationsRequest();

      groupsService.getGroupLocations(getToken(), groupId).then(
        locations => {
          this.getGroupLocationsSuccess(locations);
        },
        error => {
          this.getGroupLocationsFailure(error);
        }
      );
    },
    addGroupLocations({ groupId, locationIds }) {
      this.addGroupLocationsRequest();

      groupsService.addGroupLocations(getToken(), groupId, locationIds).then(
        locations => {
          this.addGroupLocationsSuccess(locations.length);
          this.getGroupLocations(groupId);
        },
        error => {
          this.addGroupLocationsFailure(error);
        }
      );
    },
    deleteGroupLocation({ groupId, location }) {
      this.deleteGroupLocationRequest();

      groupsService.deleteGroupLocation(getToken(), groupId, location.id).then(
        () => {
          this.deleteGroupLocationSuccess(location);
          this.getGroupLocations(groupId);
        },
        error => {
          this.deleteGroupLocationFailure(error);
        }
      );
    },
    clearGetGroupLocationsError() {
      this.errors = { ...this.errors, getGroupLocationsError: null };
    },
    clearAddGroupLocationsError() {
      this.errors = { ...this.errors, addGroupLocationsError: null };
    },
    clearDeleteGroupLocationError() {
      this.errors = { ...this.errors, deleteGroupLocationError: null };
    },
    clearLatestAddedGroupLocations() {
      this.status = { ...this.status, latestAddedGroupLocationsCount: null };
    },
    clearLatestDeletedGroupLocation() {
      this.status = { ...this.status, latestDeletedGroupLocation: null };
    },
    resetGroupLocations() {
      this.reset();
    },
    getGroupLocationsRequest() {
      this.status = { ...this.status, isLoadingGroupLocations: true };
      this.data = removeAll(this.data);
      this.errors = { ...this.errors, getGroupLocationsError: null };
    },
    getGroupLocationsSuccess(locations) {
      this.status = { ...this.status, isLoadingGroupLocations: false };
      this.data = setAll(locations, this.data, 'locationId');
      this.errors = { ...this.errors, getGroupLocationsError: null };
    },
    getGroupLocationsFailure(error) {
      this.status = { ...this.status, isLoadingGroupLocations: false };
      this.data = removeAll(this.data);
      this.errors = { ...this.errors, getGroupLocationsError: error };
    },
    addGroupLocationsRequest() {
      this.status = { ...this.status, latestAddedGroupLocationsCount: null, isSavingLocations: true };
      this.errors = { ...this.errors, addGroupLocationsError: null };
    },
    addGroupLocationsSuccess(addedLocationsCount) {
      this.status = { ...this.status, latestAddedGroupLocationsCount: addedLocationsCount, isSavingLocations: false };
      this.errors = { ...this.errors, addGroupLocationsError: null };
    },
    addGroupLocationsFailure(error) {
      this.status = { ...this.status, latestAddedGroupLocationsCount: null, isSavingLocations: false };
      this.errors = { ...this.errors, addGroupLocationsError: error };
    },
    deleteGroupLocationRequest() {
      this.status = { ...this.status, latestDeletedGroupLocation: null, isSavingLocations: true };
      this.errors = { ...this.errors, deleteGroupLocationError: null };
    },
    deleteGroupLocationSuccess(deletedLocation) {
      this.status = { ...this.status, latestDeletedGroupLocation: deletedLocation, isSavingLocations: false };
      this.errors = { ...this.errors, deleteGroupLocationError: null };
    },
    deleteGroupLocationFailure(error) {
      this.status = { ...this.status, latestDeletedGroupLocation: null, isSavingLocations: false };
      this.errors = { ...this.errors, deleteGroupLocationError: error };
    },
    reset() {
      this.data = removeAll(this.data);
      this.status = {};
      this.errors = {};
    }
  }
});
