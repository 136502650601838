<template>
  <div class="reset-password">
    <div class="logo-wrapper">
      <logo class="login-logo" />
    </div>
    <h3 class="reset-password-title" v-html="$t('resetPassword.title')" />
    <vel-form ref="resetPasswordForm" :model="formInline" :rules="rules">
      <vel-form-item prop="newPassword" :label="$t('resetPassword.form.newPassword.placeholder')">
        <vel-input
          class="new-password-input"
          autofocus
          type="password"
          v-model="formInline.newPassword"
          @keyup.native="userStartedTyping"
        ></vel-input>
      </vel-form-item>
      <vel-form-item prop="confirmPassword" :label="$t('resetPassword.form.confirmPassword.placeholder')">
        <vel-input
          class="confirm-password-input"
          type="password"
          v-model="formInline.confirmPassword"
          @keyup.native="userStartedTypingConfirmation"
        ></vel-input>
      </vel-form-item>

      <Alert type="error" class="error" v-if="formInline.errors.length">
        {{ $t('resetPassword.form.differentPasswords.text') }}
      </Alert>
      <Alert type="error" class="error" v-if="error">{{ $t('resetPassword.form.resetError.text') }}</Alert>
      <vel-form-item>
        <vel-button type="primary" class="submit-btn" :loading="status.saving" @click="handleSubmit()">
          {{ $t('resetPassword.form.submitButton.text') }}
        </vel-button>
      </vel-form-item>
    </vel-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import Logo from '@/assets/svg/logo.svg';
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/x-input/VelInput';
import {useDataUsersResetPassword} from "@/stores/data/users/reset-password.module";

export default {
  name: 'ResetPassword',
  components: { Logo, VelForm, VelFormItem, VelInput, VelButton },
  props: {
    token: {
      type: String,
      default: null,
      required: true
    }
  },
  data() {
    return {
      formInline: {
        newPassword: '',
        confirmPassword: '',
        submitted: false,
        startedTyping: false,
        errors: []
      },
      rules: {
        newPassword: [
          {
            validator: this.validatePassword,
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            validator: this.validatePassConfirmation,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    ...mapState(useDataUsersResetPassword, ['error', 'status'])
  },
  methods: {
    ...mapActions(useDataUsersResetPassword, ['resetPassword']),
    handleSubmit() {
      const { newPassword } = this.formInline;

      this.formInline.startedTyping = false;
      this.formInline.errors = [];

      this.$refs.resetPasswordForm.validate(isValid => {
        if (isValid) {
          this.formInline.submitted = true;
          this.resetPassword({ newPassword, resetToken: this.token });
        }
      });
    },
    validatePassword(rule, value, cb) {
      if (!value || !value.length) {
        cb(new Error(this.$t('resetPassword.form.newPassword.isRequired')));
      } else if (value.length < 8) {
        cb(new Error(this.$t('resetPassword.form.newPassword.tooShort')));
      } else {
        cb();
      }
    },
    validatePassConfirmation(rule, value, cb) {
      if (!value || !value.length) {
        cb(new Error(this.$t('resetPassword.form.confirmPassword.isRequired')));
      } else if (value.length < 8) {
        cb(new Error(this.$t('resetPassword.form.confirmPassword.tooShort')));
      } else {
        if (value !== this.formInline.newPassword) {
          cb(new Error(this.$t('resetPassword.form.differentPasswords.text')));
        } else {
          cb();
        }
      }
    },
    userStartedTyping() {
      this.formInline.startedTyping = true;
      this.formInline.errors = [];
    },
    userStartedTypingConfirmation(event) {
      this.userStartedTyping();
      if (event.code === 'Enter') {
        this.handleSubmit();
      }
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.reset-password {
  width: rem-calc(300);
  @include breakpoint(xsmall) {
    padding-left: rem-calc(30);
    padding-right: rem-calc(30);
  }

  @include breakpoint(small) {
    padding-left: 0;
    padding-right: 0;
  }

  .logo-wrapper {
    align-items: center;
    display: flex;
    height: rem-calc(150);
    justify-content: center;
  }

  .login-logo {
    width: rem-calc(120);

    path {
      fill: $primary-color;
    }
    @media screen and (max-width: map-get($breakpoints, 'medium')) {
      max-height: rem-calc(80);
    }
  }

  .reset-password-title {
    font-size: rem-calc(20);
    text-align: center;
  }

  .el-form-item__content {
    text-align: right;
  }

  /* stylelint-disable */
  input.el-input__inner,
  .el-range-input:focus {
    @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
      font-size: $input-font-size !important;
    }
  }
  /* stylelint-enable */
}
</style>
