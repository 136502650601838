var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("pie-line-chart", {
        staticClass: "hourly-sales__chart",
        attrs: {
          pending: _vm.isPending,
          options: _vm.chartOptions,
          series: _vm.getSeries,
          "show-table": true,
          labels: _vm.getLabels,
          currency: _vm.currencyId,
          title: _vm.$t("sales.volumePerTenderTypes.title", {
            limit: _vm.limit
          }),
          type: _vm.get.default_chart_sales_per_order_mode.value
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }