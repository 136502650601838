var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-table", {
    attrs: {
      rows: _vm.rows,
      columns: _vm.columns,
      loading: _vm.loading,
      hoverable: false,
      defaultDirection: "asc",
      defaultSort: "user"
    },
    scopedSlots: _vm._u([
      {
        key: "empty",
        fn: function() {
          return [_vm._v(" " + _vm._s(_vm.$t("users.errors.noData")) + " ")]
        },
        proxy: true
      },
      {
        key: "name-column",
        fn: function(scope) {
          return [
            _c(
              "div",
              { staticClass: "user__name" },
              [
                _c("vel-avatar", {
                  staticClass: "user__name-avatar",
                  attrs: { fullname: scope.cell.value }
                }),
                _c(
                  "router-link",
                  {
                    staticClass: "user__name-text",
                    attrs: {
                      to: { name: "user", params: { userId: scope.row.id } }
                    }
                  },
                  [_vm._v(" " + _vm._s(scope.cell.value) + " ")]
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "sortedPermissions-column",
        fn: function(scope) {
          return [
            _c(
              "ul",
              { staticClass: "user__permissions" },
              _vm._l(scope.cell.value, function(permission) {
                return _c(
                  "li",
                  {
                    key: permission.id,
                    staticClass: "permissions__permission"
                  },
                  [
                    permission.originType
                      ? _c(
                          "i18n",
                          {
                            attrs: {
                              path:
                                "location.permissions.permissionName." +
                                permission.originType,
                              tag: "span"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "permissionName" },
                                slot: "permissionName"
                              },
                              [_vm._v(_vm._s(permission.name))]
                            ),
                            _c(
                              "span",
                              { attrs: { slot: "origin" }, slot: "origin" },
                              [
                                _c("router-link", {
                                  attrs: {
                                    slot: "origin",
                                    to: {
                                      name: permission.originType,
                                      params: { groupId: permission.originId }
                                    }
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(permission.originName)
                                  },
                                  slot: "origin"
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _c("div", [_vm._v(_vm._s(permission.name))])
                  ],
                  1
                )
              }),
              0
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }