var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "public-reset-password-page" }, [
    _c(
      "div",
      { staticClass: "public-reset-password-content__wrapper" },
      [
        _c("public-reset-password-form"),
        _c(
          "div",
          { staticClass: "public-reset-password-footer" },
          [
            _c(
              "vel-button",
              { attrs: { type: "link" }, on: { click: _vm.toggleLanguage } },
              [
                _c(
                  "span",
                  [
                    _c("vel-icon", {
                      attrs: { name: "globe-solid", size: "small" }
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("resetPassword.switchLanguage")) + " "
                    )
                  ],
                  1
                )
              ]
            ),
            _c("h6", [
              _vm._v(
                _vm._s(
                  _vm.$t("rightsReserved", { currentYear: _vm.currentYear })
                )
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }