var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VelList", {
        ref: "list",
        attrs: { "value-options": _vm.valueOptions, multiple: true },
        on: { cancel: _vm.cancel, ok: _vm.validate },
        model: {
          value: _vm.currentSelection,
          callback: function($$v) {
            _vm.currentSelection = $$v
          },
          expression: "currentSelection"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }