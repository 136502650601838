<template>
  <vel-page class="location">
    <template v-slot:title v-if="location">
      {{ location.name }}
    </template>

    <location-edit-container
      v-if="isUserAdmin && location"
      :countries="countries"
      :currencies="currencies"
      :location="location"
    ></location-edit-container>

    <vel-entity-information-card
      :isLoading="isLoadingLocation"
      :entity="{
        ...location,

        currency:
          (location &&
            location.detailedCurrency &&
            `${location.detailedCurrency.currencyCode} (${location.detailedCurrency.currencySymbol})`) ||
          '',
        countryCode: (location && location.countryCode && $t('countries.' + location.countryCode)) || '',
        areaCode: (location && location.areaCode && $t('areas.' + location.countryCode + '.' + location.areaCode)) || ''
      }"
      :properties="[
        'streetAddress',
        'city',
        'areaCode',
        'countryCode',
        'postalCode',
        'licenceNumber',
        'reseller',
        'currency',
        'version'
      ]"
      avatar="name"
      translationPrefix="location.info"
    ></vel-entity-information-card>

    <div class="location__alerts">
      <vel-alert
        @dismiss="clearGetLocationPermissionsError"
        class="alert"
        level="error"
        v-if="getLocationPermissionsError"
      >
        {{ $t('location.errors.getLocationPermissionsError') }}
      </vel-alert>

      <vel-alert @dismiss="clearGetLocationGroupsError" class="alert" level="error" v-if="getLocationGroupsError">
        {{ $t('location.errors.getLocationGroupsError') }}
      </vel-alert>

      <vel-alert
        @dismiss="clearLatestAddedLocationToGroup"
        class="alert"
        level="success"
        v-if="latestAddedLocationToGroup"
      >
        {{ $t('location.groups.form.success.addGroups') }}
      </vel-alert>

      <vel-alert
        @dismiss="clearLatestDeletedLocationFromGroup"
        class="alert"
        level="success"
        v-if="latestDeletedLocationFromGroup"
      >
        {{ $t('location.groups.form.success.deleteGroup') }}
      </vel-alert>
    </div>

    <vel-card v-if="isUserAdmin" class="location__users">
      <template v-slot:title>
        <div class="location__users-title">
          {{ $t('location.permissions.title') }}
          <div class="permissions__list-length">
            {{ locationPermissions && locationPermissions.length }}
          </div>
        </div>
      </template>

      <div class="permissions" v-if="!isLoadingLocationPermissions">
        <div class="permissions__list">
          <div v-if="hasPermissions">
            <permissions-list class="list__table" translationPrefix="location" :permissions="locationPermissions" />
          </div>

          <vel-alert :dismissable="false" class="list__none" level="notice" v-else>
            {{ $t('location.permissions.none') }}
          </vel-alert>
        </div>
      </div>
      <vel-spinner v-else />
    </vel-card>

    <vel-card v-if="isUserAdmin" class="location__groups">
      <template v-slot:title>
        <div class="location__groups-title">
          {{ $t('location.groups.title') }}
          <div class="permissions__list-length">
            {{ groups && groups.length }}
          </div>
        </div>
      </template>

      <div class="groups" v-if="!areGroupsLoading">
        <div class="groups__actions">
          <vel-button class="actions__add" type="primary" @click="handleGroupAdd()">
            {{ $t('location.groups.actions.add') }}
          </vel-button>
        </div>

        <div class="groups__list">
          <div v-if="hasGroups">
            <groups-list
              class="list__table"
              :groupTitle="$t('location.groups.title')"
              :groups="locationGroups"
              @goToGroup="handleGoToGroup"
              @delete="handleGroupDelete"
            />
          </div>

          <vel-alert :dismissable="false" class="list__none" level="notice" v-else>
            {{ $t('location.groups.none') }}
          </vel-alert>
        </div>
      </div>
      <vel-spinner v-else />

      <div class="group__modals">
        <add-group-modal
          class="modals__add"
          :locationId="locationId"
          :groupsOptions="selectableGroups"
          @dismiss="handleAddModalDismiss"
          @success="handleAddModalDismiss"
          v-if="showAddGroup"
        ></add-group-modal>

        <delete-group-modal
          class="modals__delete"
          :locationId="locationId"
          :group="deletedGroup"
          @dismiss="handleDeleteModalDismiss"
          @success="handleDeleteModalDismiss"
          v-if="showDeleteGroup && !!deletedGroup"
        ></delete-group-modal>
      </div>
    </vel-card>
  </vel-page>
</template>

<script>
import AddGroupModal from './components/modals/AddGroupModal';
import DeleteGroupModal from './components/modals/DeleteGroupModal';
import GroupsList from './components/groups-list/GroupsList';
import LocationEditContainer from './containers/LocationEditContainer';
import PermissionsList from '@/private/components/permissions-list/PermissionsList';
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelEntityInformationCard from '@/components/entity-information-card/VelEntityInformationCard';
import VelPage from '@/components/page/VelPage';
import VelSpinner from '@/components/spinner/VelSpinner';
import currencies from 'iso-currencies';
import { getCurrencies } from '@/services/currencies';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataLocationsGroupsLocation} from "@/stores/data/locations/groups-location";
import {useDataLocationsLocation} from "@/stores/data/locations/location.module";
import {useDataLocationsLocationsEdit} from "@/stores/data/locations/locations-edit.module";
import {useDataLocationsPermissionsLocation} from "@/stores/data/locations/permissions-location.module";
import {useDataUsersUserPermissions} from "@/stores/data/users/user-permissions.module";
import {useDataCountries} from "@/stores/data/countries.module";
import {useDataGroups} from "@/stores/data/groups.module";

export default {
  name: 'LocationPage',
  components: {
    AddGroupModal,
    DeleteGroupModal,
    GroupsList,
    PermissionsList,
    VelAlert,
    VelButton,
    VelCard,
    VelEntityInformationCard,
    VelPage,
    VelSpinner,
    LocationEditContainer
  },
  props: {
    locationId: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapPiniaActions(useDataLocationsLocation, ['getLocation', 'resetLocation']),
    ...mapPiniaActions(useDataLocationsPermissionsLocation, [
      'getLocationPermissions',
      'resetLocationPermissions',
      'clearGetLocationPermissionsError',
      'getPermissions'
    ]),
    ...mapPiniaActions(useDataLocationsGroupsLocation, [
      'getLocationGroups',
      'resetLocationGroups',
      'clearLatestAddedLocationToGroup',
      'clearLatestDeletedLocationFromGroup',
      'clearGetLocationGroupsError'
    ]),
    ...mapPiniaActions(useDataLocationsLocationsEdit, ['patchLocation']),
    ...mapPiniaActions(useDataCountries, ['getCountries']),
    handleGoToGroup({ value: groupId }) {
      this.$router.push({
        name: 'group',
        params: {
          groupId
        }
      });
    },
    handleGroupDelete(group) {
      this.deletedGroup = group;
      this.showDeleteGroup = true;
    },
    handleGroupAdd() {
      this.showAddGroup = true;
    },
    handleAddModalDismiss() {
      this.showAddGroup = false;
    },
    handleDeleteModalDismiss() {
      this.showDeleteGroup = false;
      this.deletedGroup = null;
    }
  },
  data() {
    return {
      currencies: [],
      showAddGroup: false,
      showDeleteGroup: false,
      deletedGroup: null
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsLocation, ['isLocationNotFound', 'location', 'isLoadingLocation']),
    ...mapPiniaState(useDataLocationsPermissionsLocation, {
      hasPermissions: 'hasPermissions',
      locationPermissions: 'sortedPermissions',
      isLoadingLocationPermissions: 'isLoadingLocationPermissions',
      getLocationPermissionsError: 'getLocationPermissionsError'
    }),
    ...mapPiniaState(useDataLocationsGroupsLocation, [
      'groups',
      'isLoadingLocationGroups',
      'getLocationGroupsError',
      'latestAddedLocationToGroup',
      'latestDeletedLocationFromGroup'
    ]),
    ...mapPiniaState(useDataUsersUserPermissions, ['isUserAdmin']),
    ...mapPiniaState(useDataGroups, {
      allGroups: 'getGroups',
      allGroupsLoading: 'isLoading'
    }),
    ...mapPiniaState(useDataCountries, { countries: 'selectAll' }),
    currentLocationGroupsIds() {
      return (this.groups && this.groups.map(group => group.id)) || [];
    },
    locationGroups() {
      if (!this.allGroups.length) {
        return [];
      }

      return this.allGroups.filter(group => this.currentLocationGroupsIds.includes(group.value));
    },
    selectableGroups() {
      if (!this.allGroups.length) {
        return [];
      }

      return this.allGroups.filter(group => !this.currentLocationGroupsIds.includes(group.value));
    },
    hasGroups() {
      return this.allGroups && this.groups && !!this.allGroups.length && !!this.groups.length;
    },
    areGroupsLoading() {
      return this.isLoadingLocationGroups || this.allGroupsLoading;
    },
    getCountryName() {
      if (!this.countries || !this.location.countryId) {
        return null;
      }

      return this.countries.filter(c => c.id === this.location.countryId)[0]?.translation[
        `desc${this.$i18n.locale.replace(/^\w/, c => c.toUpperCase())}`
      ];
    }
  },
  watch: {
    isLocationNotFound(newValue) {
      if (newValue) {
        this.$router.replace({ name: 'notfound' });
      }
    }
  },
  async created() {
    this.clearGetLocationPermissionsError();
    this.resetLocation();
    this.resetLocationGroups();
    this.resetLocationPermissions();
    this.getCountries();
    this.getLocation(this.locationId);
    this.getLocationGroups(this.locationId);
    this.getPermissions(this.locationId);
    this.currencies = await getCurrencies().then(list =>
      list
        .map(v => ({ code: v.currencyCode, name: `${v.currencyCode} (${v.currencySymbol})` }))
        .sort((a, b) => a.code.localeCompare(b.code))
    );

  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.location__users,
.location__groups {
  &-title {
    align-items: center;
    display: flex;
    height: rem-calc(25);
  }

  .permissions {
    &__list-length {
      background: $primary-color;
      border-radius: 5px;
      color: #fff;
      display: flex;
      font-size: $small-font-size;
      font-weight: bold;
      justify-content: center;
      margin-left: rem-calc(15);
      padding: rem-calc(5) 0 rem-calc(5) 0;
      width: rem-calc(40);
    }
  }
}

.location__alerts {
  margin: 1em 0;
}

.location__groups {
  margin-top: rem-calc(16);

  .groups {
    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    &__list {
      margin-top: rem-calc(16);
    }
  }
}
</style>
