var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pending || _vm.isLoadingSchedules || _vm.isLoadingPrices,
          expression: "pending || isLoadingSchedules || isLoadingPrices"
        }
      ],
      staticClass: "sales-items-edit-page",
      attrs: { loading: _vm.loading },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("salesItemsEdit.title")) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "vel-card",
        [
          _c(
            "vel-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.formValue,
                disabled: _vm.loading || _vm.pending,
                "label-position": _vm.labelPosition,
                "label-width": "175px"
              }
            },
            [
              _c("h5", [_vm._v("Configuration")]),
              _c(
                "vel-form-item",
                {
                  attrs: {
                    label: _vm.$t("salesItemsEdit.form.labels.id"),
                    prop: "id"
                  }
                },
                [
                  _c("vel-input", {
                    staticStyle: { "max-width": "120px" },
                    attrs: {
                      readonly: "",
                      "aria-readonly": "true",
                      disabled: ""
                    },
                    model: {
                      value: _vm.formValue.remoteId,
                      callback: function($$v) {
                        _vm.$set(_vm.formValue, "remoteId", $$v)
                      },
                      expression: "formValue.remoteId"
                    }
                  })
                ],
                1
              ),
              _c(
                "vel-form-item",
                {
                  attrs: {
                    label: _vm.$t("salesItemsEdit.form.labels.name"),
                    prop: "name"
                  }
                },
                [
                  _c("vel-input", {
                    attrs: { maxlength: "34" },
                    model: {
                      value: _vm.formValue.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formValue, "name", $$v)
                      },
                      expression: "formValue.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "vel-form-item",
                {
                  attrs: {
                    label: _vm.$t("salesItemsEdit.form.labels.alternativeName"),
                    prop: "alternativeName"
                  }
                },
                [
                  _c("vel-input", {
                    attrs: { maxlength: "34" },
                    model: {
                      value: _vm.formValue.alternativeName,
                      callback: function($$v) {
                        _vm.$set(_vm.formValue, "alternativeName", $$v)
                      },
                      expression: "formValue.alternativeName"
                    }
                  })
                ],
                1
              ),
              _c(
                "vel-form-item",
                {
                  attrs: {
                    prop: "divisionId",
                    label: _vm.$t("salesItemsEdit.form.labels.divisionId")
                  }
                },
                [
                  _c(
                    "vel-select",
                    {
                      attrs: { disabled: false },
                      model: {
                        value: _vm.formValue.divisionId,
                        callback: function($$v) {
                          _vm.$set(_vm.formValue, "divisionId", $$v)
                        },
                        expression: "formValue.divisionId"
                      }
                    },
                    _vm._l(_vm.divisions, function(division) {
                      return _c("vel-option", {
                        key: division.id,
                        attrs: { label: division.name, value: division.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "vel-form-item",
                {
                  attrs: {
                    prop: "itemType",
                    label: _vm.$t("salesItemsEdit.form.labels.itemType")
                  }
                },
                [
                  _c(
                    "vel-select",
                    {
                      attrs: { disabled: false },
                      model: {
                        value: _vm.formValue.itemType,
                        callback: function($$v) {
                          _vm.$set(_vm.formValue, "itemType", $$v)
                        },
                        expression: "formValue.itemType"
                      }
                    },
                    _vm._l(_vm.$t("items.types"), function(division, index) {
                      return _c("vel-option", {
                        key: Number(index),
                        attrs: { label: division, value: Number(index) }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "vel-form-item",
                {
                  attrs: {
                    prop: "regularPrice",
                    label: _vm.$t("salesItemsEdit.form.labels.regularPrice")
                  }
                },
                [
                  _c("el-input-number", {
                    staticStyle: { "max-width": "120px" },
                    attrs: {
                      controls: false,
                      precision: 2,
                      max: 100000,
                      min: -9999.99
                    },
                    on: {
                      change: function($event) {
                        return _vm.onPriceChange()
                      }
                    },
                    model: {
                      value: _vm.formValue.regularPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.formValue, "regularPrice", $$v)
                      },
                      expression: "formValue.regularPrice"
                    }
                  }),
                  _c("vel-checkbox", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      label: _vm.$t("salesItemsEdit.form.labels.copyIfChange")
                    },
                    on: {
                      change: function($event) {
                        _vm.enableCopyChange = false
                      }
                    },
                    model: {
                      value: _vm.formValue.copyIfChange,
                      callback: function($$v) {
                        _vm.$set(_vm.formValue, "copyIfChange", $$v)
                      },
                      expression: "formValue.copyIfChange"
                    }
                  })
                ],
                1
              ),
              _c(
                "vel-form-item",
                {
                  attrs: {
                    prop: "isAvailable",
                    label: _vm.$t("salesItemsEdit.form.labels.isAvailable")
                  }
                },
                [
                  _c("vel-switch", {
                    model: {
                      value: _vm.formValue.isAvailable,
                      callback: function($$v) {
                        _vm.$set(_vm.formValue, "isAvailable", $$v)
                      },
                      expression: "formValue.isAvailable"
                    }
                  })
                ],
                1
              ),
              _c("sales-items-avaibility-per-mode-form", {
                attrs: { schedulesValue: _vm.schedulesValue },
                on: {
                  selectAll: _vm.selectAll,
                  updateSchedule: _vm.updateSchedule
                }
              }),
              _c("sales-items-prices-per-mode-form", {
                attrs: { pricesData: _vm.pricesData }
              }),
              _c(
                "div",
                { staticStyle: { "margin-top": "1rem" } },
                [
                  _c(
                    "vel-button",
                    {
                      staticClass: "actions__submit",
                      attrs: { type: "primary", loading: _vm.pending },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("defaults.actions.save")) + " "
                      )
                    ]
                  ),
                  _c(
                    "vel-button",
                    {
                      staticClass: "actions__cancel",
                      staticStyle: { "margin-left": "1rem" },
                      attrs: { type: "secondary", loading: _vm.pending },
                      on: {
                        click: function($event) {
                          return _vm.handleCancel()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("defaults.actions.cancel")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }