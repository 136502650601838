<template>
  <div class="no-results">
    <img :src="require('@/assets/png/no_results.png')" />
    <div class="no-results__title">{{ $t('noResults.title') }}</div>
    <div class="no-results__subtitle" v-html="$t('noResults.subTitle')"></div>
  </div>
</template>

<script>
export default {
  name: 'VelNoResults'
};
</script>

<style lang="scss" scoped>
.no-results {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 50%;
  }

  &__title {
    font-size: 40px !important;
    font-weight: bold;
  }

  &__subtitle {
    text-align: center;
    font-size: 14px !important;
  }
}

</style>
