<template>
  <vel-page class="locations" :loading="status.isLoading">
    <template v-slot:title>
      {{ $t('pages.locations') }}
    </template>

    <template v-if="isLargeUp">
      <vel-card class="locations__table">
        <vel-table
          defaultDirection="asc"
          defaultSort="lastInvoiceTime"
          :rows="rows"
          :columns="columns"
          :hoverable="false"
          :exportable="true"
          :exportableMetas="exportableMetas"
          :filterValue="filter"
          :filterable="true"
          :filterPlaceholder="$t('defaults.placeholder.filter')"
          @filter-change="filterCallback"
          :showRowsLength="true"
          :initialLength="getLocationsAmount"
        >
          <template v-slot:name-column="scope">
            <div class="locations__name">
              <vel-avatar class="locations__name-avatar" :fullname="scope.cell.value" />
              <router-link
                class="locations__name-text"
                :to="{ name: 'location', params: { locationId: scope.row.id } }"
              >
                <span class="locations__name-text">{{ scope.cell.value || scope.row.id }}</span>
              </router-link>
            </div>
          </template>
          <template v-slot:version-column="scope">
            {{ scope.row.version }}
          </template>
          <template v-slot:lastInvoiceTime-column="scope">
            {{ scope.row.lastInvoiceTime | formatDate }}
          </template>
          <template v-slot:countryId-column="scope">
            {{ (scope.row.countryCode && $t('countries.' + scope.row.countryCode)) || '' }}
          </template>
          <template v-slot:empty>
            {{ $t('locations.errors.noData') }}
          </template>
        </vel-table>
      </vel-card>
    </template>
    <template v-else>
      <vel-table-filter class="locations__filter" v-model="inputFilter" />
      <vel-card class="locations__list" title-tag="header" v-for="location in rows" :key="location.licenceNumber">
        <template v-slot:title>
          <vel-avatar class="location__avatar" :fullname="location.name" />
          <router-link class="locations__name-text" :to="{ name: 'location', params: { locationId: location.id } }">
            <h3 class="location__name">{{ location.name }}</h3>
          </router-link>
        </template>
        <p class="location__group">{{ location.groups }}</p>
        <p class="location__address">{{ location.address }}</p>
        <p class="location__licence-number">{{ location.licenceNumber }}</p>
        <p class="location__veloce-version">
          {{ location.version }}
        </p>
        <p class="location__last-invoice-time">
          <span :title="$t('locations.syncDate.tooltip')">
            <vel-icon name="sync" modifier="on-bright" size="xsmall" />
          </span>
          <span class="last-invoice-time__date">{{ location.lastInvoiceTime | formatDate }}</span>
        </p>
      </vel-card>
    </template>
  </vel-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelAvatar from '@/components/avatar/VelAvatar';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import VelTable from '@/components/table/VelTable';
import VelTableFilter from '@/components/table/VelTableFilter';
import compareStringsInsensitive from '@/comparators/compare-strings-insensitive';
import formatDate from '@/filters/format-date';
import { slugify } from '@/helpers/string.helpers';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataCountries} from "@/stores/data/countries.module";
export default {
  name: 'locations-page',
  mixins: [DeviceMixin],
  components: {
    VelAvatar,
    VelCard,
    VelIcon,
    VelPage,
    VelTable,
    VelTableFilter
  },
  filters: {
    formatDate
  },
  methods: {
    ...mapPiniaActions(useDataLocationsStore, ['setFilter']),
    ...mapPiniaActions(useDataCountries, ['getCountries']),
    filterCallback(value) {
      this.setFilter(value);
    },
    selectBannerGroupName(groups) {
      if (!Array.isArray(groups)) {
        return groups;
      }
      const bannerGroups = groups.filter(g => g.kind < 2);
      return (bannerGroups[0] && bannerGroups[0].name) || '--';
    },
    validateVersion(version) {
      if (parseInt(version) <= 0 || !version) {
        return '-';
      }
      return version;
    },
    getCountryName(id) {
      if (!this.countries) {
        return null;
      }

      const filtered = this.countries.filter(c => c.id === id)[0];

      return filtered && filtered.translation[`desc${this.$i18n.locale.replace(/^\w/, c => c.toUpperCase())}`];
    }
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['status', 'filter']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsRows', 'getLocationsAmount']),
    ...mapPiniaState(useDataCountries, { countries: 'selectAll' }),
    rows() {
      return (this.getLocationsRows || [])
        .map(l => ({ ...l, version: this.validateVersion(l.version) }))
        .map(l => ({ ...l, groups: this.selectBannerGroupName(l.groups) }));
    },
    columns() {
      return {
        groups: {
          title: this.$t('locations.group.text'),
          sortable: true,
          sort: (s1, s2) =>
            compareStringsInsensitive(this.selectBannerGroupName(s1.groups), this.selectBannerGroupName(s2.groups)),
          width: this.isMediumDown ? '200px' : '15%'
        },
        name: {
          title: this.$t('locations.name.text'),
          sortable: true,
          sort: (s1, s2) => compareStringsInsensitive(s1.name, s2.name),
          width: this.isMediumDown ? '200px' : 'auto'
        },
        reportingDisplayName: {
          title: this.$t('locations.reportingDisplayName.text'),
          sortable: true,
          sort: (s1, s2) => compareStringsInsensitive(s1.name, s2.name),
          width: this.isMediumDown ? '200px' : 'auto'
        },
        address: {
          title: this.$t('locations.address.text'),
          sortable: true,
          sort: (s1, s2) => compareStringsInsensitive(s1.name, s2.name),
          width: this.isMediumDown ? '200px' : 'auto'
        },
        countryId: {
          class: 'countryId',
          title: this.$t('locations.countryName.text'),
          sortable: true,
          sort: (s1, s2) =>
            compareStringsInsensitive(
              (s1.countryCode && this.$t(`countries.${s1.countryCode}`)) || '',
              (s2.countryCode && this.$t(`countries.${s2.countryCode}`)) || ''
            ),
          width: '200px'
        },
        licenceNumber: {
          class: 'licenceNumber',
          title: this.$t('locations.noLicence.text'),
          sortable: true,
          width: '150px'
        },
        version: {
          class: 'veloceVersion',
          title: this.$t('locations.veloceVersion.text'),
          sortable: true,
          // eslint-disable-next-line
          sort: (a, b) => a.version.replace(/[\.-]/gu, '') - b.version.replace(/[\.-]/gu, ''),
          width: '140px'
        },
        lastInvoiceTime: {
          class: 'lastInvoiceTime',
          title: this.$t('locations.syncDate.text'),
          sort: (s1, s2) => compareStringsInsensitive(s1.lastInvoiceTime, s2.lastInvoiceTime),
          sortable: true,
          width: '200px'
        }
      };
    },
    inputFilter: {
      get() {
        return this.filter;
      },
      set(value) {
        this.setFilter(value);
      }
    },
    exportableMetas() {
      return {
        title: this.$t('exports.title.locationsList'),
        fileName: slugify(this.$t('exports.title.locationsList')),
        i18n: this.$t('export'),
        enableHeader: false
      };
    }
  },
  destroyed() {
    this.setFilter('');
  },
  mounted() {
    this.getCountries();
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';
// name
$locations__name-font-weight: $font-medium;

.locations {
  @include breakpoint(medium) {
    &__name {
      align-items: center;
      display: inline-flex;
    }

    &__name-text {
      font-weight: $locations__name-font-weight;
    }

    &__name-avatar {
      margin-right: rem-calc(8);
    }
  }
  @include breakpoint(small) {
    &__filter {
      margin-bottom: rem-calc(20);
    }
  }

  .location__group {
    font-weight: bold;
  }

  .location__licence-number,
  .location__address,
  .location__last-invoice-time {
    padding-top: rem-calc(8);
  }

  .location__last-invoice-time {
    .vel-icon {
      margin-right: rem-calc(4);
    }
  }

  .vel-table {
    .vel-table__cell {
      &.lastInvoiceTime {
        text-align: right;
        word-break: keep-all;
      }

      &.countryId {
        text-align: right;
      }

      &.licenceNumber {
        @include monospaced;

        text-align: right;
      }

      &.veloceVersion {
        text-align: right;
      }
    }

    .vel-table-header__cell:nth-last-child(1),
    .vel-table-header__cell:nth-last-child(2),
    .vel-table-header__cell:nth-last-child(3) {
      text-align: right;
    }

    .vel-table-header__cell:nth-child(5) {
      padding-right: 16px;
      text-align: right;
    }
  }

  .vel-table-action {
    margin-bottom: 1.2rem;
  }
}
</style>
