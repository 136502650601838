var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      staticClass: "vel-upload__uploader",
      attrs: {
        accept: _vm.accept,
        action: _vm.action,
        multiple: _vm.multiple,
        limit: _vm.fileUploadLimit,
        fileList: _vm.fileList,
        "auto-upload": _vm.autoUpload,
        httpRequest: _vm.submitUpload,
        onChange: _vm.handleSelectFile,
        onExceed: _vm.handleExceed,
        onRemove: _vm.handleRemove
      }
    },
    [
      _c(
        "vel-button",
        {
          staticClass: "uploader__button",
          attrs: { slot: "trigger", type: "secondary" },
          slot: "trigger"
        },
        [_vm._v(" " + _vm._s(_vm.$t("defaults.upload.selectAFile")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }