var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vel-page-reloader" },
    [
      _c("oh-snap", { staticClass: "vel-page-reloader__picto" }),
      _c("h1", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "defaults.page.failed.title",
            expression: "'defaults.page.failed.title'"
          }
        ],
        staticClass: "vel-page-reloader__title"
      }),
      _c("p", {
        staticClass: "vel-page-reloader__explanation",
        domProps: {
          innerHTML: _vm._s(_vm.$t("defaults.page.failed.explanationHtml"))
        }
      }),
      _c(
        "vel-button",
        {
          staticClass: "vel-page-reloader__action",
          attrs: { icon: "refresh" },
          on: { click: _vm.handleReload }
        },
        [_vm._v(" " + _vm._s(_vm.$t("defaults.page.failed.action")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }