var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "private-page", class: _vm.classes },
    [
      _c(
        "main-content",
        {
          scopedSlots: _vm._u([
            {
              key: "top",
              fn: function() {
                return [
                  _c("topbar", {
                    ref: "topbar",
                    attrs: {
                      account: _vm.account,
                      "handle-toggle": _vm.toggleSidebar
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("new-sidebar", { ref: "sidebar" }),
          _c("router-view", { key: _vm.$route.fullPath })
        ],
        1
      ),
      _c("resize-observer", { on: { notify: _vm.handleResize } }),
      _c("vel-feedback"),
      _c("vel-announce"),
      _vm.showConfig
        ? _c(
            "vel-modal",
            {
              attrs: { dismissable: "" },
              on: {
                dismiss: function($event) {
                  _vm.showConfig = false
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$t("editors.customize_link")) + " "
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1723931544
              )
            },
            [_c("settings-page")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }