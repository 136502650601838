var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "groupForm",
      staticClass: "group-form",
      attrs: {
        model: _vm.groupForm,
        rules: _vm.groupFormRules,
        "label-width": "150px"
      }
    },
    [
      _c(
        "vel-form-item",
        {
          attrs: { prop: "name", label: _vm.$t("groups.form.name.placeholder") }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { autofocus: "", type: "text" },
            model: {
              value: _vm.groupForm.name,
              callback: function($$v) {
                _vm.$set(_vm.groupForm, "name", $$v)
              },
              expression: "groupForm.name"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "group-form__kind",
          attrs: {
            prop: "kind",
            label: _vm.$t("groups.form.kind.label"),
            required: ""
          }
        },
        [
          _c(
            "vel-select",
            {
              attrs: { placeholder: _vm.$t("groups.form.kind.placeholder") },
              model: {
                value: _vm.groupForm.kind,
                callback: function($$v) {
                  _vm.$set(_vm.groupForm, "kind", $$v)
                },
                expression: "groupForm.kind"
              }
            },
            _vm._l(_vm.groupKindOptions, function(option) {
              return _c("vel-option", {
                key: option,
                attrs: { label: option, value: option }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "vel-form-item",
        { staticClass: "group-form__actions" },
        [
          _c(
            "vel-button",
            {
              staticClass: "actions__submit",
              attrs: { type: "primary", loading: _vm.isLoading },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
          ),
          _c(
            "vel-button",
            {
              staticClass: "actions__cancel",
              attrs: { type: "secondary", disabled: _vm.isLoading },
              on: {
                click: function($event) {
                  return _vm.handleCancel()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }