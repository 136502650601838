var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portal",
    { attrs: { to: "app" } },
    [
      _c("vel-modal", {
        staticClass: "coupon-import-csv__modal",
        attrs: { dismissable: "" },
        on: { dismiss: _vm.handleCancel },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$t("coupons.modals.import.title")) + " "
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm.hasImportCouponsError
                  ? _c(
                      "vel-alert",
                      {
                        staticClass: "alert import-csv-coupon__error",
                        attrs: { level: "error", autoDismissTime: 0 },
                        on: { dismiss: _vm.clearImportCouponsError }
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("coupons.errors.importCouponHtml", {
                                errorText: _vm.importCouponsError.text
                              })
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "import-csv__info" }, [
                  _c("p", {
                    staticClass: "info__text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("coupons.modals.import.textHtml")
                      )
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "import-csv__upload" },
                  [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("defaults.upload.selectAFile")))
                    ]),
                    _c("vel-file-upload", {
                      ref: "velUpload",
                      attrs: {
                        accept: "text/csv, text/x-csv",
                        onSubmit: _vm.analyzeFile,
                        additionalData: _vm.uploadFormData
                      },
                      on: { select: _vm.onFileSelected }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "import-csv__requirements" }, [
                  _c("h4", { staticClass: "requirements__title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("coupons.modals.import.requirementsTitle")
                        ) +
                        " "
                    )
                  ]),
                  _c("ul", {
                    staticClass: "requirements__text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("coupons.modals.import.requirementsHtml")
                      )
                    }
                  })
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "vel-button",
                  {
                    staticClass: "import-csv__close-button",
                    attrs: {
                      type: "secondary",
                      disabled: _vm.isLoading || _vm.isSavingCoupon
                    },
                    on: { click: _vm.handleCancel }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.close")) + " ")]
                ),
                _c(
                  "vel-button",
                  {
                    staticClass: "import-csv__confirm-button",
                    attrs: {
                      disabled: _vm.isFileListEmpty,
                      loading: _vm.isLoading || _vm.isSavingCoupon
                    },
                    on: { click: _vm.handleSubmit }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("coupons.modals.import.button.text")) +
                        " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("import-coupons-confirm-modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showConfirm,
            expression: "showConfirm"
          }
        ],
        attrs: { count: _vm.numberOfLinesOfDataInFile, data: _vm.formData },
        on: {
          dismiss: _vm.handleConfirmCancel,
          confirm: _vm.handleConfirmSubmit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }