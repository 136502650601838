var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no-results" }, [
    _c("img", { attrs: { src: require("@/assets/png/no_results.png") } }),
    _c("div", { staticClass: "no-results__title" }, [
      _vm._v(_vm._s(_vm.$t("noResults.title")))
    ]),
    _c("div", {
      staticClass: "no-results__subtitle",
      domProps: { innerHTML: _vm._s(_vm.$t("noResults.subTitle")) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }