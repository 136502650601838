import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';

export const useDataSalesModes = defineStore('salesModes', {
  state: () => ({
    modeSales: null,
    pending: true,
    success: null,
    error: null
  }),
  getters: {
    isPending: (state: any) => state.pending,
    isError: state => !!state.error,
    getError: state => state.error,
    isSuccess: state => state.success,
    getModeSales: state => state.modeSales || [],
    getCustomersSeries() {
      return [...this.getModeSales].sort((a, b) => b.customers - a.customers).map(zs => zs.customers).sort((a, b) => b - a);
    },
    getCustomersLabels() {
      return [...this.getModeSales].sort((a, b) => b.customers - a.customers).map(zs => zs.nameMain);
    },
    getSalesPerCustomersSeries(state: any) {
      return this.getModeSales
        .map(zs => Number(((zs.salesAmount && zs.salesAmount / zs.customers) || 0).toFixed(2)))
        .sort((a, b) => b - a);
    },
    getSeries(state: any) {
      return this.getModeSales.map(zs => zs.salesAmount);
    },
    getLabels(state: any) {
      return this.getModeSales.sort((a, b) => b.salesAmount - a.salesAmount).map(zs => zs.nameMain);
    },
    getTotal(state: any) {
      return this.getModeSales.length;
    }
  },
  actions: {
    async getSalesByMode(payload = {}) {
      console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', 'SSSSSSSSSSSSSSSS');
      try {
        const toDay = today().startOf('day').toString();
        const from = router.currentRoute.query.from || toDay;
        const to = router.currentRoute.query.to || from || toDay;

        const routeQuery = {
          ...router.currentRoute.query,
          from,
          to,
          ...payload
        };

        if (!requestAllocated(routeQuery)) {
          this.getModeSalesRequest();

          try {
            const modeSales = await salesService.getModeSales(getToken(), routeQuery);
            this.getModeSalesSuccess({ modeSales });
            deallocateRequest(routeQuery);
          } catch (error) {
            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', error);
            this.getModeSalesFailure(error);
          }
        }
      } catch (e) {
        console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', e);
      }
    },
    getModeSalesRequest() {
      this.pending = true;
      this.success = null;
      this.modeSales = null;
      this.error = null;
    },
    getModeSalesSuccess({ modeSales }) {
      console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAaa', modeSales);
      this.pending = false;
      this.success = true;
      this.modeSales = modeSales || [];
      this.error = null;
    },
    getModeSalesFailure(error) {
      this.pending = false;
      this.success = false;
      this.modeSales = null;
      this.error = error;
    }
  }
});
