<template>
  <pie-line-chart
    :pending="isLocationSalesLoading"
    :options="chartOptions"
    :show-table="true"
    :series="series"
    :labels="labels"
    :currency="currencyId"
    :title="$t('locations.sales.graph.title', { limit })"
    :type="get.default_chart_locations_sales.value"
    class="locations-sales-graph"
  ></pie-line-chart>
</template>

<script>
import { DateTime } from 'luxon';
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import { getDateFormatFromUserConfig } from '@/helpers/date.helpers';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUIDateCompare } from '@/stores/ui/date-compare.module';
import { useDataSalesLocations } from '@/stores/data/sales/locations.module';
import { storeToRefs } from 'pinia';
import {useDataConfig} from "@/stores/data/config.module";
export default {
  name: 'locations-sales-graph',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    from: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    showPreviousSeries: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  setup() {
    const locationsSalesStore = useDataSalesLocations();
    const { isLocationSalesLoading, locationsSales, getPreviousLocationsSales } = storeToRefs(locationsSalesStore);

    return { isLocationSalesLoading, locationsSales, getPreviousLocationsSales, getLocationsSales: locationsSalesStore.getLocationsSales };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),

    ...mapPiniaState(useUIDateCompare, ['getDateCompareSelectorSelected']),
    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('locations.sales.graph.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('locations.sales.graph.yaxis')
          }
        }
      };
    },
    labels() {
      return this.locationsSales[0]
        .map(location => location.reportingDisplayName || location.name);
    },
    currentSeries() {
      return {
        name: this.$t(`locations.sales.graph.sales`, {
          currency: this.currencyId,
          period: this.$t(`graphs.series.${this.seriesRangeType}.current`, {
            from: DateTime.fromISO(this.from).toFormat(getDateFormatFromUserConfig()),
            to: DateTime.fromISO(this.to).toFormat(getDateFormatFromUserConfig())
          })
        }),
        data: this.locationsSales[0]
          .map(location => location.salesAmount || 0)
      };
    },
    series() {
      if (!this.showPreviousSeries) {
        return [this.currentSeries];
      }
      return [this.currentSeries, this.previousSeries(1), this.previousSeries(2), this.previousSeries(3)];
    },
    seriesRangeType() {
      if (new Date(this.from).getTime() + 86400000 <= new Date(this.to).getTime()) {
        return 'range';
      }
      return 'single';
    },
    periods() {
      const startDate = DateTime.fromISO(this.from);
      const endDate = DateTime.fromISO(this.to);

      return [
        {
          from: startDate.minus({ days: 7 }).toJSDate(),
          to: endDate.minus({ days: 7 }).toJSDate()
        },
        {
          from: startDate.minus({ days: 28 }).toJSDate(),
          to: endDate.minus({ days: 28 }).toJSDate()
        },
        {
          from: startDate.minus({ days: 364 }).toJSDate(),
          to: endDate.minus({ days: 364 }).toJSDate()
        }
      ];
    }
  },
  methods: {
    previousSeries(index = 1) {
      const previousSales = (this.locationsSales[index] || []).reduce((previous, l) => {
        previous[l.id] = l;
        return previous;
      }, {});

      const comparedPeriod = {
        1: 'week',
        2: 'month',
        3: 'year'
      };

      return {
        name: this.$t(`locations.sales.graph.previousSales`, {
          currency: this.currencyId,
          period: this.$t(`graphs.series.${this.seriesRangeType}.${comparedPeriod[index]}`, {
            from: DateTime.fromJSDate(this.periods[index - 1].from).toFormat(getDateFormatFromUserConfig()),
            to: DateTime.fromJSDate(this.periods[index - 1].to).toFormat(getDateFormatFromUserConfig())
          })
        }),
        data: this.locationsSales[0]
          .map(location => (previousSales[location.id] && previousSales[location.id].salesAmount) || 0)
      };
    }
  },
  mounted() {
    this.getLocationsSales({
      periods: this.periods
    });
  }
};
</script>
<style type="scss" scoped>
.locations-sales-graph /deep/ .el-table {
  min-width: 800px;
}

.locations-sales-graph /deep/ .table {
  overflow: auto;
}
</style>
