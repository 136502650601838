var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-topbar", {
    staticClass: "topbar",
    scopedSlots: _vm._u([
      {
        key: "left",
        fn: function() {
          return [
            _vm.isLargeUp
              ? _c(
                  "div",
                  {
                    staticClass: "top",
                    on: {
                      click: function($event) {
                        return _vm.$router.push({ name: "dashboard" })
                      }
                    }
                  },
                  [
                    _c("logo", { staticClass: "logo" }),
                    _c("brand", { staticClass: "brand" })
                  ],
                  1
                )
              : _c(
                  "vel-button",
                  {
                    staticClass: "topbar__action",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleToggle }
                  },
                  [
                    _c("vel-icon", {
                      attrs: {
                        name: "hamburger",
                        size: "medium",
                        modifier: "on-dark"
                      }
                    })
                  ],
                  1
                ),
            _c(
              "div",
              {
                staticClass: "announcements__action",
                attrs: { type: "primary" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("topbar.whatsnew")) + " ")]
            )
          ]
        },
        proxy: true
      },
      {
        key: "right",
        fn: function() {
          return [
            _vm.account.fullName
              ? _c(
                  "vel-button",
                  {
                    ref: "accountPopoverTrigger",
                    staticClass: "topbar__action",
                    attrs: { type: "primary" },
                    on: { click: _vm.toggleAccountTooltip }
                  },
                  [
                    _c("vel-avatar", {
                      attrs: { fullname: _vm.account.fullName }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "portal",
              { attrs: { to: "app" } },
              [
                _c("account-tooltip", {
                  ref: "accountPopover",
                  staticClass: "topbar__popover",
                  attrs: {
                    firstname: _vm.account.firstName,
                    visible: _vm.appStore.isPopoverActive("topbar:account")
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }