var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portal", { attrs: { to: "app" } }, [
    _c(
      "div",
      { staticClass: "coupon-edit-modal" },
      [
        _c("vel-modal", {
          attrs: { dismissable: "" },
          on: { dismiss: _vm.handleCancel },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.$t("coupons.modals.edit.title")) + " "
                  )
                ]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [
                  _vm.hasUpdateCouponError
                    ? _c(
                        "vel-alert",
                        {
                          staticClass: "alert",
                          attrs: { level: "error" },
                          on: { dismiss: _vm.clearUpdateCouponError }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("coupons.errors.updateCoupon")) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("coupon-form", {
                    staticClass: "coupon-edit-modal__form",
                    attrs: {
                      currentGroupId: _vm.currentGroupId,
                      coupon: _vm.coupon
                    },
                    on: { submit: _vm.handleSubmit, cancel: _vm.handleCancel }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }