var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.breadcrumbsStore.total > 1
    ? _c(
        "ol",
        { staticClass: "vel-breadcrumb" },
        _vm._l(_vm.breadcrumbsStore.breadcrumbs, function(crumb, index) {
          return _c(
            "li",
            { key: crumb.name, staticClass: "vel-breadcrumb__crumb" },
            [
              index < _vm.total - 1
                ? _c(
                    "router-link",
                    {
                      staticClass: "vel-breadcrumb__link",
                      attrs: {
                        "active-class": "vel-breadcrumb__link_active",
                        "exact-active-class":
                          "vel-breadcrumb__link_active_exact",
                        to: crumb.path
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("pages")[crumb.name] || crumb.name) +
                          " "
                      )
                    ]
                  )
                : _c("span", [
                    _vm._v(_vm._s(_vm.$t("pages")[crumb.name] || crumb.name))
                  ])
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }