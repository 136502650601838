var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      ref: "sidebarTipDistributionEdit",
      attrs: {
        "header-class": "bg-light",
        "bg-variant": "white",
        width: "600px",
        right: "",
        id: "tipDistributionEdit-sidebar",
        title: "Sidebar",
        shadow: "",
        backdrop: ""
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "h6",
                {
                  staticClass:
                    "bg-gray-200 bg-light w-100 mb-0 text-center font-weight-bold"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("employeeCreate.tipDistribution.title")) +
                      " "
                  )
                ]
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2",
                  staticStyle: { "border-top": "2px solid #d6d6d8" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !(
                          _vm.$refs.form && _vm.$refs.form.enableSaveChanges
                        ),
                        variant: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateTipDistribution()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("employeeCreate.btns.saveChanges")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: { click: hide }
                    },
                    [_vm._v(_vm._s(_vm.$t("employeeCreate.btns.cancel")))]
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "b-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pending,
              expression: "pending"
            }
          ],
          staticClass: "p-4",
          attrs: { fluid: "" }
        },
        [
          _c("tip-distribution-form", {
            ref: "form",
            attrs: {
              licenceNumber: _vm.employee.licenceNumber,
              "tip-distribution": _vm.tipDistribution
            },
            on: {
              "update:tipDistribution": function($event) {
                _vm.tipDistribution = $event
              },
              "update:tip-distribution": function($event) {
                _vm.tipDistribution = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }