import 'whatwg-fetch';
import { downloadXlsxFile, get, resolveCommonQuery } from '@/helpers/service.helper';
import { getCurrentTimeForReport } from '@/helpers/date.helpers';
import pick from 'lodash.pick';
/**
 * Get transactions indicators
 * @link https://api.posveloce.com/docs/private#operation/getTransactionsIndicators
 */

const getTransactionsIndicators = async (token, query) => {
  try {
    const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
    const url = resolveCommonQuery('/transactions/indicators', params).toString();
    return await get(token, url);
  } catch (e) {
    // @todo to be deleted when the endpoint will be functional in production
    return Promise.resolve([
      {
        currency: '$',
        drawerOpenings: 0,
        paidOuts: 0,
        paidOutsAmount: 0,
        refunds: 0,
        refundsAmount: 0,
        voids: 0,
        voidsAmount: 0,
        itemCorrections: 0,
        itemCorrectionsAmount: 0
      },
      {
        currency: '£',
        drawerOpenings: 0,
        paidOuts: 0,
        paidOutsAmount: 0,
        refunds: 0,
        refundsAmount: 0,
        voids: 0,
        voidsAmount: 0,
        itemCorrections: 0,
        itemCorrectionsAmount: 0
      }
    ]);
  }
};

/**
 * Get a list of voids
 * @link https://api.posveloce.com/docs#operation/getTransactionsVoids
 */
const getTransactionsVoids = (token, query) => {
  const params = pick(query, [
    'from',
    'to',
    'limit',
    'timeSource',
    'include',
    'isTraining',
    'offset',
    'locationId',
    'locationIds',
    'groupId',
    'currencyId'
  ]);
  const url = resolveCommonQuery(
    '/transactions/voids?include=productComplete&include=workstation&include=sellingEmployee&include=carrierEmployee&include=location&bypassPagination=true',
    params
  ).toString();
  return get(token, url);
};

/**
 * Get item corrections
 * @link https://api.posveloce.com/docs#operation/getTransactionsItemCorrections
 */
const getTransactionsItemCorrections = (token, query) => {
  const params = pick(query, [
    'from',
    'to',
    'limit',
    'timeSource',
    'include',
    'isTraining',
    'offset',
    'locationId',
    'locationIds',
    'groupId',
    'currencyId'
  ]);
  const url = resolveCommonQuery(
    '/transactions/itemCorrections?include=productComplete&include=employee&include=location&bypassPagination=true',
    params
  ).toString();
  return get(token, url);
};

/**
 * Get a list of refunds
 * @link https://api.posveloce.com/docs#operation/getTransactionsRefunds
 */
const getTransactionsRefunds = (token, query) => {
  const params = pick(query, [
    'from',
    'to',
    'limit',
    'timeSource',
    'include',
    'isTraining',
    'offset',
    'locationId',
    'locationIds',
    'groupId',
    'currencyId'
  ]);
  const url = resolveCommonQuery(
    '/transactions/refunds?include=productComplete&include=sellingEmployee&include=carrierEmployee&include=location&bypassPagination=true',
    params
  ).toString();
  return get(token, url);
};

/**
 * Get a list of voids
 * @link https://api.posveloce.com/docs#operation/getTransactionsVoidsSummary
 */
 const downloadTransactionsVoidsSummaryXlsx = (token, query) => {
  const params = pick(query, [
    'from',
    'to',
    'timeSource',
    'include',
    'isTraining',
    'locationId',
    'locationIds',
    'groupId',
    'currencyId'
  ]);
  const url = resolveCommonQuery('/transactions/voids/summary?bypassPagination=true', params).toString();
  return downloadXlsxFile(token, url, `Void Report_${getCurrentTimeForReport()}.xlsx`);
};

export default {
  getTransactionsItemCorrections,
  getTransactionsRefunds,
  getTransactionsVoids,
  getTransactionsIndicators,
  downloadTransactionsVoidsSummaryXlsx
};
