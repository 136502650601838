var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "create-user-page",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("newUser.pageTitle")) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.latestCreatedUser
        ? _c(
            "vel-alert",
            {
              staticClass: "alert",
              attrs: { level: "success" },
              on: { dismiss: _vm.clearLatestCreatedUser }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("users.form.success.createUser", {
                      name: _vm.latestCreatedUser.name
                    })
                  ) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _c("create-user-form")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }