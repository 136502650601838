<template>
  <div class="sales-items-list">
    <vel-table
      :rows="data"
      :columns="columns"
      filterable
      :filterValue="filterValue"
      :filterPlaceholder="$t('defaults.placeholder.filter')"
      @filter-change="filterCallback"
      :showRowsLength="true"
      :initialLength="initialLength"
      class="items-list"
    >
      <template v-slot:title>{{ $('salesItemsPage.table.title') }}</template>

      <template v-slot:itemScreen-column="scope">
        {{ scope.row.screen ? scope.row.itemScreen : $t('salesItemsPage.table.items.screen', scope.row) }}
      </template>
      <template v-slot:actions-column="scope">
        <vel-button type="link" icon="edit" @click="handleGoToSalesItem(scope.row.id)" />
      </template>
    </vel-table>
  </div>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelButton from '@/components/button/VelButton';
import VelTable from '@/components/table/VelTable';
import { mapActions } from 'pinia';
import {useDataItems} from "@/stores/data/items.module";

export default {
  name: 'sales-items-list',
  components: { VelButton, VelTable },
  mixins: [DeviceMixin],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    filterValue: {
      type: String,
      default: ''
    },
    initialLength: {
      type: Number,
      default: 0
    },
    filterCallback: {
      type: Function,
      default: () => null
    }
  },
  computed: {
    columns() {
      return {
        remoteId: {
          title: this.$t('salesItemsPage.table.columns.remoteID'),
          width: '80px',
          sortable: true
        },
        name: {
          title: this.$t('salesItemsPage.table.columns.name'),
          width: this.isMediumDown ? '320px' : '33%',
          sortable: true
        },
        divisionName: {
          title: this.$t('salesItemsPage.table.columns.divisionName'),
          width: this.isMediumDown ? '320px' : '33%',
          sortable: true
        },
        itemType: {
          title: 'Type',
          width: this.isMediumDown ? '220px' : '33%',
          sortable: true
        } /*
        itemScreen: {
          title: this.$t('salesItemsPage.table.columns.itemScreen'),
          width: '320px',
          sortable: true
        }*/,
        actions: {
          title: 'Action',
          width: '80px'
        }
      };
    }
  },
  methods: {
    ...mapActions(useDataItems, ['setSelected']),

    handleGoToSalesItem(salesItemId) {
      this.setSelected(salesItemId);
      this.$router.push({
        name: 'salesItemsEdit',
        params: {
          id: salesItemId
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.sales-items-list {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  .items-list {
    height: 100%;
    overflow: auto;
    width: 100%;
  }
}
</style>
