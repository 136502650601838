var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    _vm._b(
      {
        staticClass: "report",
        attrs: {
          "multiple-locations": _vm.locations && _vm.locations.length > 1,
          "enable-all-currencies": true,
          "show-selector-button": true
        },
        scopedSlots: _vm._u([
          {
            key: "export",
            fn: function() {
              return [
                Object.values(_vm.data).length
                  ? _c(
                      "vel-button",
                      {
                        staticClass: "vel-button",
                        attrs: {
                          disabled:
                            _vm.loading || !Object.values(_vm.data).length,
                          loading: _vm.generating,
                          type: "primary",
                          icon: _vm.isMediumUp ? "file-excel" : undefined
                        },
                        on: { click: _vm.exportXLS }
                      },
                      [
                        _vm.isMediumDown
                          ? _c("vel-icon", { attrs: { name: "file-download" } })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isMediumUp
                                ? _vm.$t("table.tableExport.button.value")
                                : ""
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "toolbar-options",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "padding-left": "1rem",
                      "padding-top": "8px",
                      "padding-right": "1rem"
                    }
                  },
                  [
                    (!_vm.locationCount || _vm.locationCount > 1) &&
                    _vm.getCurrencySelectorSelected !== ""
                      ? _c(
                          "vel-checkbox",
                          {
                            on: {
                              change: function($event) {
                                return _vm.onConsolidate()
                              }
                            },
                            model: {
                              value: _vm.isConsolidated,
                              callback: function($$v) {
                                _vm.isConsolidated = $$v
                              },
                              expression: "isConsolidated"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("toolbar.selectors.consolidate")
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "vel-checkbox",
                      {
                        attrs: {
                          disabled:
                            !_vm.isVisibleTop10 && !_vm.isVisibleLowest10
                        },
                        on: {
                          change: function($event) {
                            return _vm.onCheck("isVisibleSales")
                          }
                        },
                        model: {
                          value: _vm.isVisibleSales,
                          callback: function($$v) {
                            _vm.isVisibleSales = $$v
                          },
                          expression: "isVisibleSales"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("itemSalesCategory.toolbar.items")) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "vel-checkbox",
                      {
                        attrs: {
                          disabled:
                            !_vm.isVisibleSales && !_vm.isVisibleLowest10
                        },
                        on: {
                          change: function($event) {
                            return _vm.onCheck("isVisibleTop10")
                          }
                        },
                        model: {
                          value: _vm.isVisibleTop10,
                          callback: function($$v) {
                            _vm.isVisibleTop10 = $$v
                          },
                          expression: "isVisibleTop10"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("itemSalesCategory.toolbar.top10")) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "vel-checkbox",
                      {
                        attrs: {
                          disabled: !_vm.isVisibleSales && !_vm.isVisibleTop10
                        },
                        on: {
                          change: function($event) {
                            return _vm.onCheck("isVisibleLowest10")
                          }
                        },
                        model: {
                          value: _vm.isVisibleLowest10,
                          callback: function($$v) {
                            _vm.isVisibleLowest10 = $$v
                          },
                          expression: "isVisibleLowest10"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("itemSalesCategory.toolbar.lowest10")
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      },
      "vel-page",
      _vm.pageAttrs,
      false
    ),
    [
      _vm.loading ? _c("vel-spinner", { staticClass: "spinner" }) : _vm._e(),
      !Object.values(_vm.data).length && !_vm.loading
        ? _c("div", { staticClass: "no-data" }, [
            _vm._v(_vm._s(_vm.$t("itemSalesCategory.noData")))
          ])
        : _vm._e(),
      _vm._l(
        Object.values(_vm.data).sort(function(a, b) {
          return a.name.localeCompare(b.name)
        }),
        function(location) {
          return _c("vel-card", { key: location.id }, [
            _vm.isConsolidatable &&
            (!_vm.locationCount || _vm.locationCount > 1)
              ? _c("h5", { staticStyle: { color: "#409eff" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        !_vm.locationCount
                          ? _vm
                              .$t("toolbar.selectors.consolidate")
                              .toUpperCase() +
                              " - " +
                              _vm
                                .$t(
                                  "dashboard.locationSelector.allLocationsText"
                                )
                                .toUpperCase()
                          : _vm
                              .$t("toolbar.selectors.consolidate")
                              .toUpperCase() +
                              " - " +
                              _vm
                                .$t(
                                  "dashboard.locationSelector.locationsSelected",
                                  {
                                    count: _vm.locationCount,
                                    total: _vm.locationsBySelectedGroup.length
                                  }
                                )
                                .toUpperCase()
                      ) +
                      " "
                  )
                ])
              : _c("h5", { staticStyle: { color: "#409eff" } }, [
                  _vm._v(_vm._s(location.reportingDisplayName || location.name))
                ]),
            _vm.isVisibleSales
              ? _c("table", [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c(
                          "th",
                          {
                            staticStyle: {
                              "min-width": "300px",
                              "white-space": "normal",
                              width: "auto"
                            },
                            attrs: { rowspan: "2" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "itemSalesCategory.items.table.headers.category"
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm._l(_vm.modes(location.id), function(mode) {
                          return _c(
                            "th",
                            {
                              key: mode.id,
                              staticClass: "text-center",
                              staticStyle: { width: "400px" },
                              attrs: { colspan: "2" }
                            },
                            [_vm._v(" " + _vm._s(mode.name) + " ")]
                          )
                        }),
                        _c(
                          "th",
                          {
                            staticClass: "text-center",
                            staticStyle: { width: "400px" },
                            attrs: { colspan: "2" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("itemSalesCategory.items.total"))
                            )
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-center",
                            staticStyle: { width: "400px" },
                            attrs: { colspan: "2" }
                          },
                          [_vm._v("%")]
                        )
                      ],
                      2
                    ),
                    _c(
                      "tr",
                      [
                        _vm._l(_vm.modes(location.id), function(mode) {
                          return [
                            _c(
                              "th",
                              {
                                key: mode.id,
                                staticClass: "text-right",
                                staticStyle: { width: "200px" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "itemSalesCategory.items.table.headers.qty"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                key: mode.id,
                                staticClass: "text-right",
                                staticStyle: { width: "200px" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "itemSalesCategory.items.table.headers.amount"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }),
                        _c(
                          "th",
                          {
                            staticClass: "text-right",
                            staticStyle: { width: "200px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "itemSalesCategory.items.table.headers.qty"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-right",
                            staticStyle: { width: "200px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "itemSalesCategory.items.table.headers.amount"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-right",
                            staticStyle: { width: "200px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "itemSalesCategory.items.table.headers.item"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-right",
                            staticStyle: { width: "200px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "itemSalesCategory.items.table.headers.all"
                                )
                              )
                            )
                          ]
                        )
                      ],
                      2
                    )
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(
                        Object.values(location.categories).sort(function(a, b) {
                          return a.name.localeCompare(b.name)
                        }),
                        function(category) {
                          return [
                            _c(
                              "tr",
                              {
                                key: category.id,
                                class: {
                                  expanded: _vm.expand.category[category.id]
                                }
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function($event) {
                                        return _vm.toggle(
                                          category.id,
                                          "category"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("vel-icon", {
                                      staticStyle: {
                                        height: "8px",
                                        position: "relative",
                                        top: "-2px",
                                        width: "8px"
                                      },
                                      attrs: {
                                        name: _vm.expand.category[category.id]
                                          ? "bottom-arrow"
                                          : "right-arrow"
                                      }
                                    }),
                                    _vm._v(" " + _vm._s(category.name) + " ")
                                  ],
                                  1
                                ),
                                _vm._l(_vm.modes(location.id), function(mode) {
                                  return [
                                    _c(
                                      "td",
                                      {
                                        key: category.id + mode.id,
                                        staticClass: "text-right qty"
                                      },
                                      [
                                        _c(
                                          "span",
                                          [
                                            _c("vel-quantity", {
                                              attrs: {
                                                qty: _vm.getCategoryTotalQty(
                                                  location.id,
                                                  category.id,
                                                  mode.id
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        key: category.id + mode.id,
                                        staticClass: "text-right amount"
                                      },
                                      [
                                        _c(
                                          "span",
                                          [
                                            _c("vel-amount", {
                                              attrs: {
                                                amount: _vm.getCategoryTotalAmount(
                                                  location.id,
                                                  category.id,
                                                  mode.id
                                                ),
                                                currency: location.currency
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                }),
                                _c(
                                  "td",
                                  { staticClass: "text-right qty total" },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("vel-quantity", {
                                          attrs: { qty: category.qty }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-right amount total 2" },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            amount: category.amount,
                                            currency: location.currency
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c("td", { staticClass: "text-right" }),
                                _c("td", { staticClass: "text-right amount" }, [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (
                                            (_vm.getCategoryGrandTotalAmount(
                                              location.id,
                                              category.id
                                            ) /
                                              _vm.getGrandTotalAmount(
                                                location.id
                                              )) *
                                            100
                                          ).toFixed(2)
                                        ) +
                                        "% "
                                    )
                                  ])
                                ])
                              ],
                              2
                            ),
                            _vm._l(
                              Object.values(category.categories).sort(function(
                                a,
                                b
                              ) {
                                return a.name.localeCompare(b.name)
                              }),
                              function(subCategory) {
                                return [
                                  _vm.expand.category[category.id]
                                    ? _c(
                                        "tr",
                                        {
                                          key: subCategory.id,
                                          class: {
                                            expanded:
                                              _vm.expand.subCategory[
                                                subCategory.id
                                              ]
                                          }
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                "padding-left": "1rem"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggle(
                                                    subCategory.id,
                                                    "subCategory"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("vel-icon", {
                                                staticStyle: {
                                                  height: "8px",
                                                  position: "relative",
                                                  top: "-2px",
                                                  width: "8px"
                                                },
                                                attrs: {
                                                  name: _vm.expand.subCategory[
                                                    subCategory.id
                                                  ]
                                                    ? "bottom-arrow"
                                                    : "right-arrow"
                                                }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(subCategory.name) +
                                                  " "
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.modes(location.id),
                                            function(mode) {
                                              return [
                                                _c(
                                                  "td",
                                                  {
                                                    key:
                                                      subCategory.id + mode.id,
                                                    staticClass:
                                                      "text-right qty"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      [
                                                        _c("vel-quantity", {
                                                          attrs: {
                                                            qty: _vm.getSubcategoryTotalQty(
                                                              location.id,
                                                              category.id,
                                                              subCategory.id,
                                                              mode.id
                                                            )
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    key:
                                                      subCategory.id + mode.id,
                                                    staticClass:
                                                      "text-right amount"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      [
                                                        _c("vel-amount", {
                                                          attrs: {
                                                            amount: _vm.getSubcategoryTotalAmount(
                                                              location.id,
                                                              category.id,
                                                              subCategory.id,
                                                              mode.id
                                                            ),
                                                            currency:
                                                              location.currency
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-right qty total"
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _c("vel-quantity", {
                                                    attrs: {
                                                      qty: _vm.getSubcategoryGrandTotalQty(
                                                        location.id,
                                                        category.id,
                                                        subCategory.id
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-right amount total 3"
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount: _vm.getSubcategoryGrandTotalAmount(
                                                        location.id,
                                                        category.id,
                                                        subCategory.id
                                                      ),
                                                      currency:
                                                        location.currency
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right qty" },
                                            [_c("span")]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-right amount"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (
                                                        (_vm.getSubcategoryGrandTotalAmount(
                                                          location.id,
                                                          category.id,
                                                          subCategory.id
                                                        ) /
                                                          _vm.getGrandTotalAmount(
                                                            location.id
                                                          )) *
                                                        100
                                                      ).toFixed(2)
                                                    ) +
                                                    "% "
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._l(
                                    Object.values(subCategory.categories).sort(
                                      function(a, b) {
                                        return (
                                          a.name +
                                          a.qty.toString() +
                                          a.amount.toString()
                                        ).localeCompare(
                                          b.name +
                                            b.qty.toString() +
                                            b.amount.toString()
                                        )
                                      }
                                    ),
                                    function(item) {
                                      return [
                                        _vm.expand.subCategory[
                                          subCategory.id
                                        ] && _vm.expand.category[category.id]
                                          ? _c(
                                              "tr",
                                              { key: item.id },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "2rem"
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                ),
                                                _vm._l(
                                                  _vm.modes(location.id),
                                                  function(mode) {
                                                    return [
                                                      _c(
                                                        "td",
                                                        {
                                                          key:
                                                            subCategory.id +
                                                            mode.id,
                                                          staticClass:
                                                            "text-right"
                                                        },
                                                        [
                                                          _c("vel-quantity", {
                                                            attrs: {
                                                              qty:
                                                                item.modes[
                                                                  mode.id
                                                                ] &&
                                                                item.modes[
                                                                  mode.id
                                                                ].qty
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          key:
                                                            subCategory.id +
                                                            mode.id,
                                                          staticClass:
                                                            "text-right"
                                                        },
                                                        [
                                                          _c("vel-amount", {
                                                            attrs: {
                                                              amount:
                                                                (item.modes[
                                                                  mode.id
                                                                ] &&
                                                                  item.modes[
                                                                    mode.id
                                                                  ].amount) ||
                                                                0,
                                                              currency:
                                                                location.currency
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-right qty total"
                                                  },
                                                  [
                                                    _c("vel-quantity", {
                                                      attrs: {
                                                        qty: _vm.getItemGrandTotalQty(
                                                          location.id,
                                                          category.id,
                                                          subCategory.id,
                                                          item.id
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-right amount total 4"
                                                  },
                                                  [
                                                    _c("vel-amount", {
                                                      attrs: {
                                                        amount: _vm.getItemGrandTotalAmount(
                                                          location.id,
                                                          category.id,
                                                          subCategory.id,
                                                          item.id
                                                        ),
                                                        currency:
                                                          location.currency
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          (
                                                            (_vm.getItemGrandTotalAmount(
                                                              location.id,
                                                              category.id,
                                                              subCategory.id,
                                                              item.id
                                                            ) /
                                                              _vm.getSubcategoryGrandTotalAmount(
                                                                location.id,
                                                                category.id,
                                                                subCategory.id
                                                              )) *
                                                              100 || 0
                                                          ).toFixed(2)
                                                        ) +
                                                        "% "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          (
                                                            (_vm.getItemGrandTotalAmount(
                                                              location.id,
                                                              category.id,
                                                              subCategory.id,
                                                              item.id
                                                            ) /
                                                              _vm.getGrandTotalAmount(
                                                                location.id
                                                              )) *
                                                            100
                                                          ).toFixed(2)
                                                        ) +
                                                        "% "
                                                    )
                                                  ]
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  ),
                                  _vm.expand.subCategory[subCategory.id] &&
                                  _vm.expand.category[category.id]
                                    ? _c(
                                        "tr",
                                        {
                                          key: subCategory.id,
                                          staticClass: "itemTotal"
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "padding-left": "2rem"
                                              }
                                            },
                                            [_vm._v("Total")]
                                          ),
                                          _vm._l(
                                            _vm.modes(location.id),
                                            function(mode) {
                                              return [
                                                _c(
                                                  "td",
                                                  {
                                                    key:
                                                      subCategory.id + mode.id,
                                                    staticClass: "text-right"
                                                  },
                                                  [
                                                    _c("vel-quantity", {
                                                      attrs: {
                                                        qty: _vm.getSubcategoryTotalQty(
                                                          location.id,
                                                          category.id,
                                                          subCategory.id,
                                                          mode.id
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    key:
                                                      subCategory.id + mode.id,
                                                    staticClass: "text-right"
                                                  },
                                                  [
                                                    _c("vel-amount", {
                                                      attrs: {
                                                        amount: _vm.getSubcategoryTotalAmount(
                                                          location.id,
                                                          category.id,
                                                          subCategory.id,
                                                          mode.id
                                                        ),
                                                        currency:
                                                          location.currency
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right total" },
                                            [
                                              _c("vel-quantity", {
                                                attrs: {
                                                  qty: _vm.getSubcategoryGrandTotalQty(
                                                    location.id,
                                                    category.id,
                                                    subCategory.id
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right total" },
                                            [
                                              _c("vel-amount", {
                                                attrs: {
                                                  amount: _vm.getSubcategoryGrandTotalAmount(
                                                    location.id,
                                                    category.id,
                                                    subCategory.id
                                                  ),
                                                  currency: location.currency
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("td", {
                                            staticClass: "text-right"
                                          }),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (
                                                      (_vm.getSubcategoryGrandTotalAmount(
                                                        location.id,
                                                        category.id,
                                                        subCategory.id
                                                      ) /
                                                        _vm.getGrandTotalAmount(
                                                          location.id
                                                        )) *
                                                      100
                                                    ).toFixed(2)
                                                  ) +
                                                  "% "
                                              )
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              }
                            ),
                            _vm.expand.category[category.id]
                              ? _c(
                                  "tr",
                                  {
                                    key: category.id,
                                    staticClass: "subCategoryTotal"
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "padding-left": "1rem" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "itemSalesCategory.itemPerformance.subtotal"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._l(_vm.modes(location.id), function(
                                      mode
                                    ) {
                                      return [
                                        _c(
                                          "td",
                                          {
                                            key: category.id + mode.id,
                                            staticClass: "text-right"
                                          },
                                          [
                                            _c("vel-quantity", {
                                              attrs: {
                                                qty: _vm.getCategoryTotalQty(
                                                  location.id,
                                                  category.id,
                                                  mode.id
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            key: category.id + mode.id,
                                            staticClass: "text-right"
                                          },
                                          [
                                            _c("vel-amount", {
                                              attrs: {
                                                amount: _vm.getCategoryTotalAmount(
                                                  location.id,
                                                  category.id,
                                                  mode.id
                                                ),
                                                currency: location.currency
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }),
                                    _c(
                                      "td",
                                      { staticClass: "text-right total" },
                                      [
                                        _c("vel-quantity", {
                                          attrs: {
                                            qty: _vm.getCategoryGrandTotalQty(
                                              location.id,
                                              category.id
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-right total" },
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            amount: _vm.getCategoryGrandTotalAmount(
                                              location.id,
                                              category.id
                                            ),
                                            currency: location.currency
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("td", { staticClass: "text-right" }),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (
                                              (_vm.getCategoryGrandTotalAmount(
                                                location.id,
                                                category.id
                                              ) /
                                                _vm.getGrandTotalAmount(
                                                  location.id
                                                )) *
                                              100
                                            ).toFixed(2)
                                          ) +
                                          "% "
                                      )
                                    ])
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        }
                      ),
                      _c(
                        "tr",
                        { staticClass: "total" },
                        [
                          _c(
                            "td",
                            { staticStyle: { "padding-left": "1rem" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "itemSalesCategory.itemPerformance.total"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._l(_vm.modes(location.id), function(mode) {
                            return [
                              _c(
                                "td",
                                {
                                  key: location.id + mode.id,
                                  staticClass: "text-right"
                                },
                                [
                                  _c("vel-quantity", {
                                    attrs: {
                                      qty: _vm.getTotalQty(location.id, mode.id)
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "td",
                                {
                                  key: location.id + mode.id,
                                  staticClass: "text-right"
                                },
                                [
                                  _c("vel-amount", {
                                    attrs: {
                                      amount: _vm.getTotalAmount(
                                        location.id,
                                        mode.id
                                      ),
                                      currency: location.currency
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("vel-quantity", {
                                attrs: {
                                  qty: _vm.getGrandTotalQty(location.id)
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("vel-amount", {
                                attrs: {
                                  amount: _vm.getGrandTotalAmount(location.id),
                                  currency: location.currency
                                }
                              })
                            ],
                            1
                          ),
                          _c("td", { staticClass: "text-right" }),
                          _c("td", { staticClass: "text-right" })
                        ],
                        2
                      )
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _vm.isVisibleTop10 || _vm.isVisibleLowest10
              ? _c("div", [
                  _c("h5", { staticStyle: { "margin-top": "2rem" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("itemSalesCategory.itemPerformance.title"))
                    )
                  ]),
                  _c("table", [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "300px",
                                width: "auto"
                              },
                              attrs: { rowspan: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "itemSalesCategory.itemPerformance.table.headers.category"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _vm._l(_vm.performanceModes(location.id), function(
                            mode
                          ) {
                            return _c(
                              "th",
                              {
                                key: mode.id,
                                staticClass: "text-center",
                                staticStyle: { width: "400px" },
                                attrs: { colspan: "2" }
                              },
                              [_vm._v(" " + _vm._s(mode.name) + " ")]
                            )
                          }),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              staticStyle: { width: "400px" },
                              attrs: { colspan: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "itemSalesCategory.itemPerformance.total"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              staticStyle: { width: "400px" },
                              attrs: { colspan: "2" }
                            },
                            [_vm._v("%")]
                          )
                        ],
                        2
                      ),
                      _c(
                        "tr",
                        [
                          _vm._l(_vm.performanceModes(location.id), function(
                            mode
                          ) {
                            return [
                              _c(
                                "th",
                                {
                                  key: mode.id,
                                  staticClass: "text-right",
                                  staticStyle: { width: "200px" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "itemSalesCategory.itemPerformance.table.headers.qty"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  key: mode.id,
                                  staticClass: "text-right",
                                  staticStyle: { width: "200px" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "itemSalesCategory.itemPerformance.table.headers.amount"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          }),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { width: "200px" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "itemSalesCategory.itemPerformance.table.headers.qty"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { width: "200px" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "itemSalesCategory.itemPerformance.table.headers.amount"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { width: "200px" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "itemSalesCategory.itemPerformance.table.headers.qty"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { width: "200px" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "itemSalesCategory.itemPerformance.table.headers.amount"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    ]),
                    _c(
                      "tbody",
                      [
                        _vm._l(
                          Object.values(
                            _vm.performanceData.categories[location.id]
                              .categories
                          ),
                          function(category) {
                            return [
                              (category.id === "lowest_10" &&
                                _vm.isVisibleLowest10) ||
                              (category.id === "top_10" && _vm.isVisibleTop10)
                                ? [
                                    _c(
                                      "tr",
                                      {
                                        key: category.id,
                                        class: {
                                          expanded:
                                            _vm.expand.category[category.id]
                                        }
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function($event) {
                                                return _vm.toggle(
                                                  category.id,
                                                  "category"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("vel-icon", {
                                              staticStyle: {
                                                height: "8px",
                                                position: "relative",
                                                top: "-2px",
                                                width: "8px"
                                              },
                                              attrs: {
                                                name: _vm.expand.category[
                                                  category.id
                                                ]
                                                  ? "bottom-arrow"
                                                  : "right-arrow"
                                              }
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "itemSalesCategory.itemPerformance.items." +
                                                      category.id
                                                  )
                                                ) +
                                                " "
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          _vm.performanceModes(location.id),
                                          function(mode) {
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  key: category.id + mode.id,
                                                  staticClass: "text-right qty"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    [
                                                      _c("vel-quantity", {
                                                        attrs: {
                                                          qty: _vm.getTop10TotalQtyByMode(
                                                            location.id,
                                                            category.id,
                                                            mode.id
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  key: category.id + mode.id,
                                                  staticClass:
                                                    "text-right amount"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount: _vm.getTop10TotalAmountByMode(
                                                            location.id,
                                                            category.id,
                                                            mode.id
                                                          ),
                                                          currency:
                                                            location.currency
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right qty total"
                                          },
                                          [
                                            _c(
                                              "span",
                                              [
                                                _c("vel-quantity", {
                                                  attrs: {
                                                    qty: _vm.getTop10TotalQty(
                                                      location.id,
                                                      category.id
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-right amount total 5"
                                          },
                                          [
                                            _c(
                                              "span",
                                              [
                                                _c("vel-amount", {
                                                  attrs: {
                                                    amount: _vm.getTop10TotalAmount(
                                                      location.id,
                                                      category.id
                                                    ),
                                                    currency: location.currency
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-right qty" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (
                                                      (_vm.getTop10TotalQty(
                                                        location.id,
                                                        category.id
                                                      ) /
                                                        _vm.getGrandTotalQty(
                                                          location.id
                                                        )) *
                                                      100
                                                    ).toFixed(2)
                                                  ) +
                                                  "% "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-right amount" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (
                                                      (_vm.getTop10TotalAmount(
                                                        location.id,
                                                        category.id
                                                      ) /
                                                        _vm.getGrandTotalAmount(
                                                          location.id
                                                        )) *
                                                      100
                                                    ).toFixed(2)
                                                  ) +
                                                  "% "
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      2
                                    ),
                                    _vm._l(
                                      Object.values(category.categories),
                                      function(subCategory) {
                                        return [
                                          _vm.expand.category[category.id]
                                            ? _c(
                                                "tr",
                                                {
                                                  key: subCategory.id,
                                                  class: {
                                                    expanded:
                                                      _vm.expand.subCategory[
                                                        subCategory.id
                                                      ]
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "2rem"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(subCategory.name)
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.performanceModes(
                                                      location.id
                                                    ),
                                                    function(mode) {
                                                      return [
                                                        _c(
                                                          "td",
                                                          {
                                                            key:
                                                              subCategory.id +
                                                              mode.id,
                                                            staticClass:
                                                              "text-right qty"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "vel-quantity",
                                                                  {
                                                                    attrs: {
                                                                      qty:
                                                                        subCategory
                                                                          .modes[
                                                                          mode
                                                                            .id
                                                                        ] &&
                                                                        subCategory
                                                                          .modes[
                                                                          mode
                                                                            .id
                                                                        ].qty
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            key:
                                                              subCategory.id +
                                                              mode.id,
                                                            staticClass:
                                                              "text-right amount"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "vel-amount",
                                                                  {
                                                                    attrs: {
                                                                      amount:
                                                                        (subCategory
                                                                          .modes[
                                                                          mode
                                                                            .id
                                                                        ] &&
                                                                          subCategory
                                                                            .modes[
                                                                            mode
                                                                              .id
                                                                          ]
                                                                            .amount) ||
                                                                        0,
                                                                      currency:
                                                                        location.currency
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-right qty total"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            subCategory.totalQty
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-right amount total 6"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        [
                                                          _c("vel-amount", {
                                                            attrs: {
                                                              amount:
                                                                subCategory.totalAmount,
                                                              currency:
                                                                location.currency
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-right qty"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            (
                                                              (subCategory.totalQty /
                                                                _vm.getGrandTotalQty(
                                                                  location.id
                                                                )) *
                                                              100
                                                            ).toFixed(2)
                                                          ) +
                                                          "% "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-right amount"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              (
                                                                (subCategory.totalAmount /
                                                                  _vm.getGrandTotalAmount(
                                                                    location.id
                                                                  )) *
                                                                100
                                                              ).toFixed(2)
                                                            ) +
                                                            "% "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    ),
                                    _vm.expand.category[category.id]
                                      ? _c(
                                          "tr",
                                          {
                                            key: category.id,
                                            staticClass: "total"
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "padding-left": "1rem"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "itemSalesCategory.itemPerformance.total"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.performanceModes(location.id),
                                              function(mode) {
                                                return [
                                                  _c(
                                                    "td",
                                                    {
                                                      key:
                                                        location.id + mode.id,
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getTop10TotalQtyByMode(
                                                              location.id,
                                                              category.id,
                                                              mode.id
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      key:
                                                        location.id + mode.id,
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount: _vm.getTop10TotalAmountByMode(
                                                            location.id,
                                                            category.id,
                                                            mode.id
                                                          ),
                                                          currency:
                                                            location.currency
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass: "text-right total"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getTop10TotalQty(
                                                      location.id,
                                                      category.id
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass: "text-right total"
                                              },
                                              [
                                                _c("vel-amount", {
                                                  attrs: {
                                                    amount: _vm.getTop10TotalAmount(
                                                      location.id,
                                                      category.id
                                                    ),
                                                    currency: location.currency
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (
                                                        (_vm.getTop10TotalQty(
                                                          location.id,
                                                          category.id
                                                        ) /
                                                          _vm.getGrandTotalQty(
                                                            location.id
                                                          )) *
                                                        100
                                                      ).toFixed(2)
                                                    ) +
                                                    "% "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (
                                                        (_vm.getTop10TotalAmount(
                                                          location.id,
                                                          category.id
                                                        ) /
                                                          _vm.getGrandTotalAmount(
                                                            location.id
                                                          )) *
                                                        100
                                                      ).toFixed(2)
                                                    ) +
                                                    "% "
                                                )
                                              ]
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ]
                          }
                        )
                      ],
                      2
                    )
                  ])
                ])
              : _vm._e()
          ])
        }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }