import { createPinia } from "pinia";

const pinia = createPinia();

const stores = [];
pinia.use(({ store }) => {
  const existing = stores.filter(s => s.$id === store.$id).length > 0;

  if (!existing) {
    stores.push(store);
  }
});

function resetAllStores() {
  stores.forEach(store => {
    store.$reset();
  });
}

export { pinia, resetAllStores };
