<template>
  <vel-page class="groups" :loading="isLoading" :enable-toolbar="true">
    <template v-slot:title>
      {{ $t('pages.groups') }}
    </template>

    <template v-slot:alert>
      <vel-alert @dismiss="clearGetGroupsError" class="alert" level="error" v-if="hasGetGroupsError">
        {{ $t('groups.errors.getGroups') }}
      </vel-alert>

      <vel-alert @dismiss="clearLatestCreatedGroup" class="alert" level="success" v-if="latestCreatedGroup">
        {{ $t('groups.form.success.createGroup', { name: latestCreatedGroup.name }) }}
      </vel-alert>

      <vel-alert @dismiss="clearLatestUpdatedGroup" class="alert" level="success" v-if="latestUpdatedGroup">
        {{ $t('groups.form.success.updateGroup', { name: latestUpdatedGroup.name }) }}
      </vel-alert>

      <vel-alert @dismiss="clearLatestDeletedGroup" class="alert" level="success" v-if="latestDeletedGroup">
        {{ $t('groups.form.success.deleteGroup', { name: latestDeletedGroup.name }) }}
      </vel-alert>
    </template>

    <template v-slot:toolbar>
      <vel-button class="groups__action-add" :disabled="areActionsDisabled" type="primary" @click="handleAddGroup()">
        {{ $t('groups.actions.create') }}
      </vel-button>
    </template>

    <div class="group__modals">
      <add-group-modal
        class="modals__add"
        @dismiss="handleAddGroupModalDismiss"
        @success="handleAddGroupModalSuccess"
        v-if="showAddGroup"
      ></add-group-modal>

      <edit-group-modal
        class="modals__edit"
        @dismiss="handleEditGroupModalDismiss"
        @success="handleEditGroupModalSuccess"
        :group="selectedGroup"
        v-if="showEditGroup && selectedGroup"
      ></edit-group-modal>

      <delete-group-modal
        class="modals__edit"
        @dismiss="handleDeleteGroupModalDismiss"
        @success="handleDeleteGroupModalSuccess"
        :group="selectedGroup"
        v-if="showDeleteGroup && selectedGroup"
      ></delete-group-modal>
    </div>

    <template v-if="isLargeUp">
      <vel-card class="groups__table">
        <vel-table
          :rows="rows"
          :columns="columns"
          :hoverable="false"
          :exportable="true"
          :exportableMetas="exportableMetas"
          :filterValue="filter"
          :filterable="true"
          :filterPlaceholder="$t('defaults.placeholder.filter')"
          @filter-change="filterCallback"
          :showRowsLength="true"
          :initialLength="getGroupsAmount"
        >
          <template v-slot:name-column="scope">
            <div class="groups__name">
              <vel-avatar class="groups__name-avatar" :fullname="scope.cell.value" />
              <router-link class="groups__name-text" :to="{ name: 'group', params: { groupId: scope.row.id } }">
                <span class="groups__name-text">{{ scope.cell.value }}</span>
              </router-link>
            </div>
          </template>
          <template v-slot:kindName-column="scope">
            <div class="groups__kind">
              <span class="groups__kind-text">{{ scope.row.kindName }}</span>
            </div>
          </template>
          <template v-slot:actions-column="scope">
            <div class="groups__action">
              <vel-button class="action__edit-button" type="link" icon="edit" @click="handleEditGroup(scope.row)" />
              <vel-button
                class="action__delete-button"
                type="link"
                icon="delete"
                @click="handleDeleteGroup(scope.row)"
              />
            </div>
          </template>
          <template v-slot:empty>
            {{ $t('groups.errors.noData') }}
          </template>
        </vel-table>
      </vel-card>
    </template>
    <template v-else>
      <vel-table-filter class="groups__filter" v-model="inputFilter" />
      <vel-card class="groups__list" title-tag="header" v-for="group in rows" :key="group.name">
        <template v-slot:title>
          <div class="group__title">
            <vel-avatar class="group__avatar" :fullname="group.name" />
            <router-link class="group-title__text" :to="{ name: 'group', params: { groupId: group.id } }">
              <h3 class="group__name">{{ group.name }}</h3>
            </router-link>
            <div class="group-title__action">
              <vel-button
                class="action__edit-button"
                type="link"
                icon="edit"
                @click="handleEditGroup(group)"
              ></vel-button>
              <vel-button
                class="action__delete-button"
                type="link"
                icon="delete"
                @click="handleDeleteGroup(group)"
              ></vel-button>
            </div>
          </div>
        </template>

        <div class="group__info-details">
          <p class="property property__kind">
            <span class="property__label">{{ $t('groups.kind.text') }}</span>
            <span class="property__value">{{ group.kindName }}</span>
          </p>
        </div>
      </vel-card>
      <vel-card class="groups__empty" title-tag="header" v-if="hasEmptyRows">
        <div>{{ $t('groups.errors.noData') }}</div>
      </vel-card>
    </template>
  </vel-page>
</template>

<script>
import { mapActions, mapState } from 'pinia';

import AddGroupModal from './components/modals/AddGroupModal.vue';
import DeleteGroupModal from './components/modals/DeleteGroupModal.vue';
import DeviceMixin from '@/mixins/device-mixin';
import EditGroupModal from './components/modals/EditGroupModal.vue';
import GroupKindMixin from '@/mixins/group/group-kind-mixin';
import VelAlert from '@/components/alert/VelAlert';
import VelAvatar from '@/components/avatar/VelAvatar';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelPage from '@/components/page/VelPage';
import VelTable from '@/components/table/VelTable';
import VelTableFilter from '@/components/table/VelTableFilter';
import { slugify } from '@/helpers/string.helpers';
import {useDataGroups} from "@/stores/data/groups.module";

export default {
  name: 'groups-page',
  mixins: [DeviceMixin, GroupKindMixin],
  components: {
    AddGroupModal,
    EditGroupModal,
    DeleteGroupModal,
    VelAlert,
    VelAvatar,
    VelButton,
    VelCard,
    VelPage,
    VelTable,
    VelTableFilter
  },
  data() {
    return {
      showAddGroup: false,
      showEditGroup: false,
      showDeleteGroup: false,
      selectedGroup: null
    };
  },
  methods: {
    ...mapActions(useDataGroups, [
      'setFilter',
      'clearGetGroupsError',
      'clearLatestCreatedGroup',
      'clearLatestDeletedGroup',
      'clearLatestUpdatedGroup'
    ]),
    filterCallback(value) {
      this.setFilter(value);
    },
    handleAddGroup() {
      this.showAddGroup = true;
    },
    handleAddGroupModalDismiss() {
      this.showAddGroup = false;
    },
    handleAddGroupModalSuccess() {
      this.showAddGroup = false;
    },
    handleDeleteGroup(group) {
      this.selectedGroup = { ...group };
      this.showDeleteGroup = true;
    },
    handleDeleteGroupModalDismiss() {
      this.selectedGroup = null;
      this.showDeleteGroup = false;
    },
    handleDeleteGroupModalSuccess() {
      this.selectedGroup = null;
      this.shoDeleteGroup = false;
    },
    handleEditGroup(group) {
      this.selectedGroup = { ...group };
      this.selectedGroup.kind = this.getKindName(group.kind);
      this.showEditGroup = true;
    },
    handleEditGroupModalDismiss() {
      this.selectedGroup = null;
      this.showEditGroup = false;
    },
    handleEditGroupModalSuccess() {
      this.selectedGroup = null;
      this.showEditGroup = false;
    }
  },
  computed: {
    ...mapState(useDataGroups, [
      'getGroupsRows',
      'filter',
      'isLoading',
      'hasGetGroupsError',
      'latestCreatedGroup',
      'latestDeletedGroup',
      'latestUpdatedGroup',
      'getGroupsAmount'
    ]),
    rows() {
      return (this.getGroupsRows || []).map(g => ({ ...g, kindName: this.getTranslatedKindName(g.kind) }));
    },
    hasEmptyRows() {
      return !this.rows || !this.rows.length;
    },
    columns() {
      return {
        name: {
          title: this.$t('groups.name.text'),
          sortable: true,
          width: '60%'
        },
        kindName: {
          title: this.$t('groups.kind.text'),
          sortable: true,
          width: '20%'
        },
        actions: {
          title: this.$t('defaults.actions.title'),
          sortable: false,
          exportable: false,
          width: '20%'
        }
      };
    },
    inputFilter: {
      get() {
        return this.filter;
      },
      set(value) {
        this.setFilter(value);
      }
    },
    areActionsDisabled() {
      return this.isLoading;
    },
    exportableMetas() {
      return {
        title: this.$t('exports.title.groups'),
        fileName: slugify(this.$t('exports.title.groups')),
        i18n: this.$t('export'),
        enableHeader: false
      };
    }
  },
  destroyed() {
    this.setFilter('');
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

// name
$groups__name-font-weight: $font-medium;

.groups {
  &__filter {
    margin-bottom: 0;
  }

  .groups__empty {
    text-align: center;
  }

  .groups__filter {
    margin-bottom: rem-calc(16);
  }

  .groups__action-add {
    align-self: flex-end;
    justify-self: flex-end;
    margin-bottom: rem-calc(16);
    margin-left: auto;
    place-self: flex-end;
  }

  .groups__action {
    .vel-button__icon {
      padding: 0 0.125rem;
    }
  }

  @include breakpoint(medium) {
    &__filter {
      margin-bottom: rem-calc(20);
    }

    &__name {
      align-items: center;
      display: inline-flex;
    }

    &__name-text {
      font-weight: $groups__name-font-weight;
      @include text-ellipsis;
    }

    &__name-avatar {
      margin-right: rem-calc(8);
    }
  }

  .vel-table {
    .vel-table-header__cell:nth-child(3) {
      padding-right: 16px;
      text-align: right;
    }

    .vel-table__row {
      .vel-table__cell:nth-child(3) {
        text-align: right;
      }
    }
  }

  .groups__list {
    .group__title {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .group-title__action {
        .vel-button__icon {
          padding: 0;
        }
      }

      .group__avatar {
        margin-right: rem-calc(16);
      }

      .group-title__text {
        flex-grow: 1;
      }
    }
  }

  .vel-table-action {
    margin-bottom: 1.2rem;
  }

  .alert {
    margin-bottom: rem-calc(16);
  }

  .property {
    margin-bottom: 8px;

    .property__label {
      color: $property__label-color;
    }

    .property__value {
      margin-left: 8px;
    }
  }
}
</style>
