<template>
  <div v-bind:class="classObject" style="width: 100%;">
    <vel-spinner v-if="areSalesLoading" class="spinner" />
    <div class="vel-sales-report__action" v-if="!areSalesLoading">
      <div class="vel-action__consolidated" v-if="consolidated">
        {{ $t('toolbar.selectors.consolidate') }} -
        {{
          !locationCount
            ? $t('dashboard.locationSelector.allLocationsText')
            : $t('dashboard.locationSelector.locationsSelected', {
                count: locationCount,
                total: locationTotal
              })
        }}
      </div>
      <div class="vel-action__filter">
        <vel-table-filter v-model="inputFilter" v-if="filterable" />
      </div>
      <div class="vel-action__buttons">
        <vel-button
          v-if="expandable"
          class="vel-expand"
          icon="expand"
          size="small"
          :disabled="isBusy"
          @click="expandAll"
        >
          <slot v-if="isLargeUp">{{ $t('salesReport.expandAll') }}</slot>
        </vel-button>
        <vel-button
          v-if="expandable"
          class="vel-collapse"
          icon="collapse"
          size="small"
          :disabled="isBusy"
          @click="collapseAll"
        >
          <slot v-if="isLargeUp">{{ $t('salesReport.collapseAll') }}</slot>
        </vel-button>
        <vel-table-export
          :columns="mutableExportableColumns"
          :rows="exportableRows"
          :metas="exportableMetas"
          :external-export="externalExport"
          :is-external-generating="isExternalGenerating"
          @exportXls="$emit('exportXls')"
        />
      </div>
    </div>
    <div class="vel-sales-report__header">
      <div v-if="displayedColumns[0]">{{ displayedColumns[0] }}</div>
      <div v-if="displayedColumns[1]" class="number">{{ displayedColumns[1] }}</div>
      <div v-if="displayedColumns[4]" class="number">{{ displayedColumns[4] }}</div>
      <div v-if="displayedColumns[3]" class="number">{{ displayedColumns[3] }}</div>
      <div v-if="displayedColumns[2]" class="number">{{ displayedColumns[2] }}</div>
      <div v-if="displayedColumns[5]" class="number">{{ displayedColumns[5] }}</div>
    </div>
    <div class="vel-sales-report__body" v-if="!areSalesLoading">
      <vel-tree
        :data="data"
        :empty-text="$t('defaults.empty')"
        :props="defaultProps"
        :render-content="renderContent"
        :render-after-expand="false"
        ref="tree"
      />
    </div>
  </div>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelButton from '@/components/button/VelButton';
import VelSpinner from '@/components/spinner/VelSpinner';
import VelTableExport from '@/components/table/VelTableExport';
import VelTableFilter from '@/components/table/VelTableFilter';
import VelTree from '@/components/tree/VelTree';
import formatMoney from '@/filters/format-money';
import formatQuantity from '@/filters/format-quantity';

export default {
  name: 'vel-sales-report',
  components: { VelButton, VelSpinner, VelTableExport, VelTree, VelTableFilter },
  mixins: [DeviceMixin],
  props: {
    areSalesLoading: {
      type: Boolean,
      default: true
    },
    consolidated: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    expandable: {
      type: Boolean,
      default: true
    },
    displayedColumns: {
      type: Array,
      default() {
        return [];
      }
    },
    exportableColumns: {
      type: Array,
      default() {
        return [];
      }
    },
    exportableRows: {
      type: Array,
      default() {
        return [];
      }
    },
    exportableMetas: {
      type: Object,
      default: null
    },
    filterable: {
      type: Boolean,
      default: false
    },
    locationCount: {
      type: Number,
      default: 0
    },
    locationTotal: {
      type: Number,
      default: 0
    },
    externalExport: {
      type: Boolean,
      default: false
    },
    isExternalGenerating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label',
        quantity: 'quantity',
        salesAmount: 'salesAmount',
        currency: 'currency',
        expanded: 'expanded'
      },
      isBusy: false,
      filter: ''
    };
  },
  methods: {
    renderContent(h, { node }) {
      const salesAmount = formatMoney(node.data.salesAmount || 0, node.data.currency);
      return (
        <span class="body__row">
          {this.displayedColumns[0] && <span class="row__name">{node.label}</span>}
          {this.displayedColumns[1] && <span class="row__amount">{formatQuantity(node.data.quantity)}</span>}
          {this.displayedColumns[4] && <span class="row__amount">{node.data.salesOverallPercent}%</span>}
          {this.displayedColumns[3] && <span class="row__amount">{node.data.salesPercent}%</span>}
          {this.displayedColumns[2] && <span class="row__amount">{salesAmount}</span>}
          {this.displayedColumns[5] && <span class="row__amount">{node.data.averageVolumeUnit}</span>}
        </span>
      );
    },
    async expandAll() {
      this.isBusy = true;
      await this.$refs.tree.expandAll();
      this.isBusy = false;
    },
    async collapseAll() {
      this.isBusy = true;
      await this.$refs.tree.collapseAll();
      this.isBusy = false;
    }
  },
  computed: {
    mutableExportableColumns() {
      return this.exportableColumns;
    },
    inputFilter: {
      get() {
        return this.filter;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    classObject() {
      const totalColumn =
        Number((this.displayedColumns[0] && 1) || 0) +
        Number((this.displayedColumns[1] && 1) || 0) +
        Number((this.displayedColumns[2] && 1) || 0) +
        Number((this.displayedColumns[3] && 1) || 0) +
        Number((this.displayedColumns[4] && 1) || 0) +
        Number((this.displayedColumns[5] && 1) || 0) +
        Number((this.displayedColumns[6] && 1) || 0);

      const dynKey = `col-${totalColumn.toString()}`;
      return {
        'vel-sales-report': true,
        [dynKey]: true
      };
    }
  }
};
</script>
<style lang="scss" src="./VelSalesReport.scss" />
