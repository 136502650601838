var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.areSalesLoading,
          expression: "areSalesLoading"
        }
      ],
      staticClass: "daily-sales__chart"
    },
    [
      _c("h5", [
        _vm._v(_vm._s(_vm.$t("dashboard.salesCumulativeLineChart.title")))
      ]),
      !_vm.areSalesLoading
        ? _c("div", [
            _vm.areSalesAvailable
              ? _c(
                  "div",
                  { staticClass: "chart__toolbar" },
                  [
                    _vm.areSalesAvailable
                      ? _c("apexchart", {
                          attrs: {
                            type: "area",
                            height: _vm.height,
                            options: _vm.chartOptions,
                            series: _vm.series
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "chart__empty-state" },
                  [
                    _vm._t("empty", function() {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.$t("dashboard.salesCumulativeLineChart.empty")
                          )
                        )
                      ]
                    })
                  ],
                  2
                )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }