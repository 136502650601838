var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-table", {
    staticClass: "sales",
    attrs: {
      defaultDirection: _vm.defaultDirection,
      defaultSort: _vm.defaultSort,
      rows: _vm.rows,
      columns: _vm.columns,
      loading: _vm.loading,
      hoverable: _vm.hoverable,
      exportable: true,
      exportableMetas: _vm.exportableMetas,
      filterable: _vm.filterable,
      filterValue: _vm.filterValue,
      filterPlaceholder: _vm.$t("defaults.placeholder.filter")
    },
    on: { "filter-change": _vm.filterChangeHandler, row: _vm.handleRow },
    scopedSlots: _vm._u([
      {
        key: "salesPercent-column",
        fn: function(scope) {
          return [_vm._v(_vm._s(scope.row.salesPercent) + "%")]
        }
      },
      {
        key: "salesOverallPercent-column",
        fn: function(scope) {
          return [_vm._v(_vm._s(scope.row.salesOverallPercent) + "%")]
        }
      },
      {
        key: "salesAmount-column",
        fn: function(scope) {
          return [
            _c("vel-amount", {
              attrs: {
                amount: scope.row.salesAmount,
                currency: scope.row.currency
              }
            })
          ]
        }
      },
      {
        key: "netSales-column",
        fn: function(scope) {
          return [
            _c("vel-amount", {
              attrs: {
                amount: scope.row.netSales,
                currency: scope.row.currency
              }
            })
          ]
        }
      },
      {
        key: "discountsAmount-column",
        fn: function(scope) {
          return [
            _c("vel-amount", {
              attrs: {
                amount: scope.row.discountsAmount,
                currency: scope.row.currency
              }
            })
          ]
        }
      },
      {
        key: "empty",
        fn: function() {
          return [
            _vm._v(" " + _vm._s(_vm.$t("sales.table.messages.noData")) + " ")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }