<template>
  <vel-page
    :multiple-locations="locations && locations.length > 1"
    :enable-all-currencies="true"
    :show-selector-button="true"
    class="report"
    v-bind="pageAttrs"
  >
    <template v-slot:export>
      <vel-button
        :disabled="loading || !Object.values(data).length"
        :loading="generating"
        type="primary"
        @click="exportXLS"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button"
        v-if="Object.values(data).length"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>
    <template v-slot:toolbar-options>
      <div style="padding-left: 1rem; padding-top: 8px; padding-right: 1rem;">
        <vel-checkbox v-model="isConsolidated" @change="onConsolidate()" v-if="(!locationCount || locationCount > 1) && getCurrencySelectorSelected !== ''">
          {{ $t('toolbar.selectors.consolidate') }}
        </vel-checkbox>
        <vel-checkbox
          :disabled="!isVisibleTop10 && !isVisibleLowest10"
          v-model="isVisibleSales"
          @change="onCheck('isVisibleSales')"
        >
          {{ $t('itemSalesCategory.toolbar.items') }}
        </vel-checkbox>
        <vel-checkbox
          :disabled="!isVisibleSales && !isVisibleLowest10"
          v-model="isVisibleTop10"
          @change="onCheck('isVisibleTop10')"
        >
          {{ $t('itemSalesCategory.toolbar.top10') }}
        </vel-checkbox>
        <vel-checkbox
          :disabled="!isVisibleSales && !isVisibleTop10"
          v-model="isVisibleLowest10"
          @change="onCheck('isVisibleLowest10')"
        >
          {{ $t('itemSalesCategory.toolbar.lowest10') }}
        </vel-checkbox>
      </div>
    </template>
    <vel-spinner v-if="loading" class="spinner" />
    <div class="no-data" v-if="!Object.values(data).length && !loading">{{ $t('itemSalesCategory.noData') }}</div>
    <vel-card v-for="location in Object.values(data).sort((a, b) => a.name.localeCompare(b.name))" :key="location.id">
      <h5 style="color: #409eff;" v-if="isConsolidatable && (!locationCount || locationCount > 1)">
        {{
          !locationCount
            ? $t('toolbar.selectors.consolidate').toUpperCase() +
              ' - ' +
              $t('dashboard.locationSelector.allLocationsText').toUpperCase()
            : $t('toolbar.selectors.consolidate').toUpperCase() +
              ' - ' +
              $t('dashboard.locationSelector.locationsSelected', {
                count: locationCount,
                total: locationsBySelectedGroup.length
              }).toUpperCase()
        }}
      </h5>
      <h5 style="color: #409eff;" v-else>{{ location.reportingDisplayName || location.name }}</h5>
      <table v-if="isVisibleSales">
        <thead>
          <tr>
            <th style="min-width: 300px; white-space: normal; width: auto;" rowspan="2">
              {{ $t('itemSalesCategory.items.table.headers.category') }}
            </th>

            <th v-for="mode in modes(location.id)" :key="mode.id" style="width: 400px;" class="text-center" colspan="2">
              {{ mode.name }}
            </th>
            <th style="width: 400px;" class="text-center" colspan="2">{{ $t('itemSalesCategory.items.total') }}</th>
            <th style="width: 400px;" class="text-center" colspan="2">%</th>
          </tr>
          <tr>
            <template v-for="mode in modes(location.id)">
              <th style="width: 200px;" :key="mode.id" class="text-right">
                {{ $t('itemSalesCategory.items.table.headers.qty') }}
              </th>
              <th style="width: 200px;" :key="mode.id" class="text-right">
                {{ $t('itemSalesCategory.items.table.headers.amount') }}
              </th>
            </template>
            <th style="width: 200px;" class="text-right">{{ $t('itemSalesCategory.items.table.headers.qty') }}</th>
            <th style="width: 200px;" class="text-right">{{ $t('itemSalesCategory.items.table.headers.amount') }}</th>
            <th style="width: 200px;" class="text-right">{{ $t('itemSalesCategory.items.table.headers.item') }}</th>
            <th style="width: 200px;" class="text-right">{{ $t('itemSalesCategory.items.table.headers.all') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="category in Object.values(location.categories).sort((a, b) => a.name.localeCompare(b.name))">
            <tr :class="{ expanded: expand.category[category.id] }" :key="category.id">
              <td style="cursor: pointer;" @click="toggle(category.id, 'category')">
                <vel-icon
                  style="height: 8px; position: relative; top: -2px; width: 8px;"
                  :name="expand.category[category.id] ? 'bottom-arrow' : 'right-arrow'"
                ></vel-icon>
                {{ category.name }}
              </td>
              <template v-for="mode in modes(location.id)">
                <td :key="category.id + mode.id" class="text-right qty">
                  <span>
                    <vel-quantity :qty="getCategoryTotalQty(location.id, category.id, mode.id)"></vel-quantity>
                  </span>
                </td>
                <td :key="category.id + mode.id" class="text-right amount">
                  <span>
                    <vel-amount
                      :amount="getCategoryTotalAmount(location.id, category.id, mode.id)"
                      :currency="location.currency"
                    ></vel-amount>
                  </span>
                </td>
              </template>
              <td class="text-right qty total">
                <span><vel-quantity :qty="category.qty"></vel-quantity></span>
              </td>
              <td class="text-right amount total 2">
                <span>
                  <vel-amount :amount="category.amount" :currency="location.currency"></vel-amount>
                </span>
              </td>
              <td class="text-right"></td>
              <td class="text-right amount">
                <span>
                  {{
                    (
                      (getCategoryGrandTotalAmount(location.id, category.id) / getGrandTotalAmount(location.id)) *
                      100
                    ).toFixed(2)
                  }}%
                </span>
              </td>
            </tr>
            <template
              v-for="subCategory in Object.values(category.categories).sort((a, b) => a.name.localeCompare(b.name))"
            >
              <tr
                v-if="expand.category[category.id]"
                :class="{ expanded: expand.subCategory[subCategory.id] }"
                :key="subCategory.id"
              >
                <td style="cursor: pointer; padding-left: 1rem;" @click="toggle(subCategory.id, 'subCategory')">
                  <vel-icon
                    style="height: 8px; position: relative; top: -2px; width: 8px;"
                    :name="expand.subCategory[subCategory.id] ? 'bottom-arrow' : 'right-arrow'"
                  ></vel-icon>
                  {{ subCategory.name }}
                </td>
                <template v-for="mode in modes(location.id)">
                  <td :key="subCategory.id + mode.id" class="text-right qty">
                    <span>
                      <vel-quantity
                        :qty="getSubcategoryTotalQty(location.id, category.id, subCategory.id, mode.id)"
                      ></vel-quantity>
                    </span>
                  </td>
                  <td :key="subCategory.id + mode.id" class="text-right amount">
                    <span>
                      <vel-amount
                        :amount="getSubcategoryTotalAmount(location.id, category.id, subCategory.id, mode.id)"
                        :currency="location.currency"
                      ></vel-amount>
                    </span>
                  </td>
                </template>
                <td class="text-right qty total">
                  <span>
                    <vel-quantity
                      :qty="getSubcategoryGrandTotalQty(location.id, category.id, subCategory.id)"
                    ></vel-quantity>
                  </span>
                </td>
                <td class="text-right amount total 3">
                  <span>
                    <vel-amount
                      :amount="getSubcategoryGrandTotalAmount(location.id, category.id, subCategory.id)"
                      :currency="location.currency"
                    ></vel-amount>
                  </span>
                </td>
                <td class="text-right qty"><span></span></td>
                <td class="text-right amount">
                  <span>
                    {{
                      (
                        (getSubcategoryGrandTotalAmount(location.id, category.id, subCategory.id) /
                          getGrandTotalAmount(location.id)) *
                        100
                      ).toFixed(2)
                    }}%
                  </span>
                </td>
              </tr>
              <!-- ITEMS LIST -->
              <template
                v-for="item in Object.values(subCategory.categories).sort((a, b) =>
                  (a.name + a.qty.toString() + a.amount.toString()).localeCompare(
                    b.name + b.qty.toString() + b.amount.toString()
                  )
                )"
              >
                <tr v-if="expand.subCategory[subCategory.id] && expand.category[category.id]" :key="item.id">
                  <td style="padding-left: 2rem;">{{ item.name }}</td>
                  <template v-for="mode in modes(location.id)">
                    <td :key="subCategory.id + mode.id" class="text-right">
                      <vel-quantity :qty="item.modes[mode.id] && item.modes[mode.id].qty"></vel-quantity>
                    </td>
                    <td :key="subCategory.id + mode.id" class="text-right">
                      <vel-amount
                        :amount="(item.modes[mode.id] && item.modes[mode.id].amount) || 0"
                        :currency="location.currency"
                      ></vel-amount>
                    </td>
                  </template>
                  <td class="text-right qty total">
                    <vel-quantity
                      :qty="getItemGrandTotalQty(location.id, category.id, subCategory.id, item.id)"
                    ></vel-quantity>
                  </td>
                  <td class="text-right amount total 4">
                    <vel-amount
                      :amount="getItemGrandTotalAmount(location.id, category.id, subCategory.id, item.id)"
                      :currency="location.currency"
                    ></vel-amount>
                  </td>
                  <td class="text-right">
                    {{
                      (
                        (getItemGrandTotalAmount(location.id, category.id, subCategory.id, item.id) /
                          getSubcategoryGrandTotalAmount(location.id, category.id, subCategory.id)) *
                          100 || 0
                      ).toFixed(2)
                    }}%
                  </td>
                  <td class="text-right">
                    {{
                      (
                        (getItemGrandTotalAmount(location.id, category.id, subCategory.id, item.id) /
                          getGrandTotalAmount(location.id)) *
                        100
                      ).toFixed(2)
                    }}%
                  </td>
                </tr>
              </template>
              <!-- / ITEMS LIST -->
              <!-- GRAND TOTAL ITEM -->
              <tr
                :key="subCategory.id"
                v-if="expand.subCategory[subCategory.id] && expand.category[category.id]"
                class="itemTotal"
              >
                <td style="padding-left: 2rem;">Total</td>
                <template v-for="mode in modes(location.id)">
                  <td :key="subCategory.id + mode.id" class="text-right">
                    <vel-quantity
                      :qty="getSubcategoryTotalQty(location.id, category.id, subCategory.id, mode.id)"
                    ></vel-quantity>
                  </td>
                  <td :key="subCategory.id + mode.id" class="text-right">
                    <vel-amount
                      :amount="getSubcategoryTotalAmount(location.id, category.id, subCategory.id, mode.id)"
                      :currency="location.currency"
                    ></vel-amount>
                  </td>
                </template>
                <td class="text-right total">
                  <vel-quantity
                    :qty="getSubcategoryGrandTotalQty(location.id, category.id, subCategory.id)"
                  ></vel-quantity>
                </td>
                <td class="text-right total">
                  <vel-amount
                    :amount="getSubcategoryGrandTotalAmount(location.id, category.id, subCategory.id)"
                    :currency="location.currency"
                  ></vel-amount>
                </td>
                <td class="text-right"></td>
                <td class="text-right">
                  {{
                    (
                      (getSubcategoryGrandTotalAmount(location.id, category.id, subCategory.id) /
                        getGrandTotalAmount(location.id)) *
                      100
                    ).toFixed(2)
                  }}%
                </td>
              </tr>
              <!-- / GRAND TOTAL ITEM -->
            </template>
            <!-- GRAND TOTAL SUBCATEGORIES -->
            <tr :key="category.id" v-if="expand.category[category.id]" class="subCategoryTotal">
              <td style="padding-left: 1rem;">{{ $t('itemSalesCategory.itemPerformance.subtotal') }}</td>
              <template v-for="mode in modes(location.id)">
                <td :key="category.id + mode.id" class="text-right">
                  <vel-quantity :qty="getCategoryTotalQty(location.id, category.id, mode.id)"></vel-quantity>
                </td>
                <td :key="category.id + mode.id" class="text-right">
                  <vel-amount
                    :amount="getCategoryTotalAmount(location.id, category.id, mode.id)"
                    :currency="location.currency"
                  ></vel-amount>
                </td>
              </template>
              <td class="text-right total">
                <vel-quantity :qty="getCategoryGrandTotalQty(location.id, category.id)"></vel-quantity>
              </td>
              <td class="text-right total">
                <vel-amount
                  :amount="getCategoryGrandTotalAmount(location.id, category.id)"
                  :currency="location.currency"
                ></vel-amount>
              </td>
              <td class="text-right"></td>
              <td class="text-right">
                {{
                  (
                    (getCategoryGrandTotalAmount(location.id, category.id) / getGrandTotalAmount(location.id)) *
                    100
                  ).toFixed(2)
                }}%
              </td>
            </tr>
            <!-- / GRAND TOTAL SUBCATEGORIES -->
          </template>
          <!-- GRAND TOTAL CATEGORIES -->
          <tr class="total">
            <td style="padding-left: 1rem;">{{ $t('itemSalesCategory.itemPerformance.total') }}</td>
            <template v-for="mode in modes(location.id)">
              <td :key="location.id + mode.id" class="text-right">
                <vel-quantity :qty="getTotalQty(location.id, mode.id)"></vel-quantity>
              </td>
              <td :key="location.id + mode.id" class="text-right">
                <vel-amount
                  :amount="getTotalAmount(location.id, mode.id)"
                  :currency="location.currency"
                ></vel-amount>
              </td>
            </template>
            <td class="text-right">
              <vel-quantity :qty="getGrandTotalQty(location.id)"></vel-quantity>
            </td>
            <td class="text-right">
              <vel-amount
                :amount="getGrandTotalAmount(location.id)"
                :currency="location.currency"
              ></vel-amount>
            </td>
            <td class="text-right"></td>
            <td class="text-right"></td>
          </tr>
          <!-- / GRAND TOTAL CATEGORIES -->
        </tbody>
      </table>
      <div v-if="isVisibleTop10 || isVisibleLowest10">
        <h5 style="margin-top: 2rem;">{{ $t('itemSalesCategory.itemPerformance.title') }}</h5>
        <table>
          <thead>
            <tr>
              <th rowspan="2" style="min-width: 300px; width: auto;">
                {{ $t('itemSalesCategory.itemPerformance.table.headers.category') }}
              </th>

              <th
                v-for="mode in performanceModes(location.id)"
                :key="mode.id"
                style="width: 400px;"
                class="text-center"
                colspan="2"
              >
                {{ mode.name }}
              </th>
              <th style="width: 400px;" class="text-center" colspan="2">
                {{ $t('itemSalesCategory.itemPerformance.total') }}
              </th>
              <th style="width: 400px;" class="text-center" colspan="2">%</th>
            </tr>
            <tr>
              <template v-for="mode in performanceModes(location.id)">
                <th style="width: 200px;" :key="mode.id" class="text-right">
                  {{ $t('itemSalesCategory.itemPerformance.table.headers.qty') }}
                </th>
                <th style="width: 200px;" :key="mode.id" class="text-right">
                  {{ $t('itemSalesCategory.itemPerformance.table.headers.amount') }}
                </th>
              </template>
              <th style="width: 200px;" class="text-right">
                {{ $t('itemSalesCategory.itemPerformance.table.headers.qty') }}
              </th>
              <th style="width: 200px;" class="text-right">
                {{ $t('itemSalesCategory.itemPerformance.table.headers.amount') }}
              </th>
              <th style="width: 200px;" class="text-right">
                {{ $t('itemSalesCategory.itemPerformance.table.headers.qty') }}
              </th>
              <th style="width: 200px;" class="text-right">
                {{ $t('itemSalesCategory.itemPerformance.table.headers.amount') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="category in Object.values(performanceData.categories[location.id].categories)">
              <template
                v-if="
                  (category.id === 'lowest_10' && isVisibleLowest10) || (category.id === 'top_10' && isVisibleTop10)
                "
              >
                <tr :class="{ expanded: expand.category[category.id] }" :key="category.id">
                  <td style="cursor: pointer;" @click="toggle(category.id, 'category')">
                    <vel-icon
                      style="height: 8px; position: relative; top: -2px; width: 8px;"
                      :name="expand.category[category.id] ? 'bottom-arrow' : 'right-arrow'"
                    ></vel-icon>
                    {{ $t('itemSalesCategory.itemPerformance.items.' + category.id) }}
                  </td>
                  <template v-for="mode in performanceModes(location.id)">
                    <td :key="category.id + mode.id" class="text-right qty">
                      <span>
                        <vel-quantity :qty="getTop10TotalQtyByMode(location.id, category.id, mode.id)"></vel-quantity>
                      </span>
                    </td>
                    <td :key="category.id + mode.id" class="text-right amount">
                      <span>
                        <vel-amount
                          :amount="getTop10TotalAmountByMode(location.id, category.id, mode.id)"
                          :currency="location.currency"
                        ></vel-amount>
                      </span>
                    </td>
                  </template>
                  <td class="text-right qty total">
                    <span>
                      <vel-quantity :qty="getTop10TotalQty(location.id, category.id)"></vel-quantity>
                    </span>
                  </td>
                  <td class="text-right amount total 5">
                    <span>
                      <vel-amount
                        :amount="getTop10TotalAmount(location.id, category.id)"
                        :currency="location.currency"
                      ></vel-amount>
                    </span>
                  </td>
                  <td class="text-right qty">
                    <span>
                      {{
                        ((getTop10TotalQty(location.id, category.id) / getGrandTotalQty(location.id)) * 100).toFixed(2)
                      }}%
                    </span>
                  </td>
                  <td class="text-right amount">
                    <span>
                      {{
                        (
                          (getTop10TotalAmount(location.id, category.id) / getGrandTotalAmount(location.id)) *
                          100
                        ).toFixed(2)
                      }}%
                    </span>
                  </td>
                </tr>
                <template v-for="subCategory in Object.values(category.categories)">
                  <tr
                    v-if="expand.category[category.id]"
                    :class="{ expanded: expand.subCategory[subCategory.id] }"
                    :key="subCategory.id"
                  >
                    <td style="padding-left: 2rem;">{{ subCategory.name }}</td>
                    <template v-for="mode in performanceModes(location.id)">
                      <td :key="subCategory.id + mode.id" class="text-right qty">
                        <span>
                          <vel-quantity
                            :qty="subCategory.modes[mode.id] && subCategory.modes[mode.id].qty"
                          ></vel-quantity>
                        </span>
                      </td>
                      <td :key="subCategory.id + mode.id" class="text-right amount">
                        <span>
                          <vel-amount
                            :amount="(subCategory.modes[mode.id] && subCategory.modes[mode.id].amount) || 0"
                            :currency="location.currency"
                          ></vel-amount>
                        </span>
                      </td>
                    </template>
                    <td class="text-right qty total">
                      <span>{{ subCategory.totalQty }}</span>
                    </td>
                    <td class="text-right amount total 6">
                      <span>
                        <vel-amount
                          :amount="subCategory.totalAmount"
                          :currency="location.currency"
                        ></vel-amount>
                      </span>
                    </td>
                    <td class="text-right qty">
                      {{ ((subCategory.totalQty / getGrandTotalQty(location.id)) * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right amount">
                      <span>
                        {{ ((subCategory.totalAmount / getGrandTotalAmount(location.id)) * 100).toFixed(2) }}%
                      </span>
                    </td>
                  </tr>
                </template>
                <!-- GRAND TOTAL CATEGORIES -->
                <tr :key="category.id" class="total" v-if="expand.category[category.id]">
                  <td style="padding-left: 1rem;">{{ $t('itemSalesCategory.itemPerformance.total') }}</td>
                  <template v-for="mode in performanceModes(location.id)">
                    <td :key="location.id + mode.id" class="text-right">
                      {{ getTop10TotalQtyByMode(location.id, category.id, mode.id) }}
                    </td>
                    <td :key="location.id + mode.id" class="text-right">
                      <vel-amount
                        :amount="getTop10TotalAmountByMode(location.id, category.id, mode.id)"
                        :currency="location.currency"
                      ></vel-amount>
                    </td>
                  </template>
                  <td class="text-right total">{{ getTop10TotalQty(location.id, category.id) }}</td>
                  <td class="text-right total">
                    <vel-amount
                      :amount="getTop10TotalAmount(location.id, category.id)"
                      :currency="location.currency"
                    ></vel-amount>
                  </td>
                  <td class="text-right">
                    {{
                      ((getTop10TotalQty(location.id, category.id) / getGrandTotalQty(location.id)) * 100).toFixed(2)
                    }}%
                  </td>
                  <td class="text-right">
                    {{
                      (
                        (getTop10TotalAmount(location.id, category.id) / getGrandTotalAmount(location.id)) *
                        100
                      ).toFixed(2)
                    }}%
                  </td>
                </tr>
              </template>
              <!-- / GRAND TOTAL CATEGORIES -->
            </template>
          </tbody>
        </table>
      </div>
    </vel-card>
  </vel-page>
</template>

<script>
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import GeneratedTime from '@/mixins/generated-time-mixin';
import VelAmount from '@/components/amount/VelAmount';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelCheckbox from '@/components/checkbox/VelCheckbox';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import VelQuantity from '@/components/quantity/VelQuantity';
import VelSpinner from '@/components/spinner/VelSpinner';
import formatQuantity from '@/filters/format-quantity';
import { getToken } from '@/helpers/token.helper';
import router from '@/router';
import salesService from '@/services/sales.service';
import sortBy from 'lodash.sortby';
import {getDateFormatFromUserConfig, today} from '@/helpers/date.helpers';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useUICurrencySelector} from "@/stores/ui/currency-selector.module";
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {useDataLocationsStore} from "@/stores/data/locations.module";

export default {
  name: 'SummaryReportPage',
  components: {
    VelCheckbox,
    VelPage,
    VelButton,
    VelAmount,
    VelQuantity,
    VelCard,
    VelIcon,
    VelSpinner
  },
  mixins: [GeneratedTime, DeviceMixin],
  data() {
    return {
      isConsolidated:  localStorage.getItem('isConsolidated') === 'true',
      isVisibleSales:
        localStorage.getItem('isVisibleSales') === null ? true : localStorage.getItem('isVisibleSales') === 'true',
      isVisibleTop10:
        localStorage.getItem('isVisibleTop10') === null ? true : localStorage.getItem('isVisibleTop10') === 'true',
      isVisibleLowest10:
        localStorage.getItem('isVisibleLowest10') === null
          ? true
          : localStorage.getItem('isVisibleLowest10') === 'true',
      performanceData: {
        categories: {}
      },
      data: [],
      dataModes: {},
      performanceDataModes: {},
      loading: true,
      generating: false,
      refunds: [],
      expand: {
        category: {},
        subCategory: {}
      }
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['locations', 'locationsBySelectedGroup']),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities'
    }),
    isConsolidatable() {
      return this.isConsolidated && this.getCurrencySelectorSelected !== ''
    },
    pageAttrs() {
      return {
        enableToolbar: true,
        enableDateRange: true,
        enableCurrency: true,
        title: this.$t('pages.reports.itemSalesCategory'),
        showCrumbs: false,
        failed: this.hasPageFailed,
        generatedTime: this.generatedTime
      };
    },
    hasPageFailed() {
      return false;
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    onCheck(elementModel) {
      localStorage.setItem(elementModel, this[elementModel]);
    },
    modes(locationId) {
      return Object.values(this.dataModes[locationId]).sort((a, b) => a.remoteId - b.remoteId);
    },
    performanceModes(locationId) {
      return Object.values(this.performanceDataModes[locationId]).sort((a, b) => a.remoteId - b.remoteId);
    },
    getTop10TotalQtyByMode(locationId, category, modeId) {
      return Object.values(this.performanceData.categories[locationId].categories[category].categories).reduce(
        (a, b) => (b.modes[modeId]?.qty || 0) + a,
        0
      );
    },
    getTop10TotalQty(locationId, category) {
      return Object.values(this.performanceData.categories[locationId].categories[category].categories).reduce(
        (a, b) => b.totalQty + a,
        0
      );
    },
    getPerformanceTotalAmount(locationId) {
      return Object.values(this.performanceData.categories[locationId].categories).reduce(
        (a, b) => this.getTop10TotalAmount(locationId, b.id) + a,
        0
      );
    },
    getTop10TotalAmount(locationId, category) {
      return Object.values(this.performanceData.categories[locationId].categories[category].categories).reduce(
        (a, b) => b.totalAmount + a,
        0
      );
    },
    getTop10TotalAmountByMode(locationId, category, modeId) {
      return Object.values(this.performanceData.categories[locationId].categories[category].categories).reduce(
        (a, b) => (b.modes[modeId]?.amount || 0) + a,
        0
      );
    },
    getItemGrandTotalQty(locationId, categoryId, subcategoryId, itemId) {
      return Object.values(
        this.data[locationId].categories[categoryId].categories[subcategoryId].categories[itemId].modes
      ).reduce((a, b) => b.qty + a, 0);
    },
    getItemGrandTotalAmount(locationId, categoryId, subcategoryId, itemId) {
      return Object.values(
        this.data[locationId].categories[categoryId].categories[subcategoryId].categories[itemId].modes
      ).reduce((a, b) => b.amount + a, 0);
    },
    getTotalQty(locationId, modeId) {
      return Object.values(this.data[locationId].categories).reduce(
        (a, b) => this.getCategoryTotalQty(locationId, b.id, modeId) + a,
        0
      );
    },
    getTotalAmount(locationId, modeId) {
      return Object.values(this.data[locationId].categories).reduce(
        (a, b) => this.getCategoryTotalAmount(locationId, b.id, modeId) + a,
        0
      );
    },
    getCategoryTotalQty(locationId, categoryId, modeId) {
      return Object.values(this.data[locationId].categories[categoryId].categories).reduce(
        (a, b) => this.getSubcategoryTotalQty(locationId, categoryId, b.id, modeId) + a,
        0
      );
    },
    getCategoryGrandTotalQty(locationId, categoryId) {
      return Object.values(this.data[locationId].categories[categoryId].categories).reduce(
        (a, b) => this.getSubcategoryGrandTotalQty(locationId, categoryId, b.id) + a,
        0
      );
    },
    getGrandTotalQty(locationId) {
      return Object.values(this.data[locationId].categories).reduce(
        (a, b) => this.getCategoryGrandTotalQty(locationId, b.id) + a,
        0
      );
    },
    getGrandTotalAmount(locationId) {
      return Object.values(this.data[locationId].categories).reduce(
        (a, b) => this.getCategoryGrandTotalAmount(locationId, b.id) + a,
        0
      );
    },
    getCategoryGrandTotalAmount(locationId, categoryId) {
      return Object.values(this.data[locationId].categories[categoryId].categories).reduce(
        (a, b) => this.getSubcategoryGrandTotalAmount(locationId, categoryId, b.id) + a,
        0
      );
    },
    getCategoryTotalAmount(locationId, categoryId, modeId) {
      return Object.values(this.data[locationId].categories[categoryId].categories).reduce(
        (a, b) => this.getSubcategoryTotalAmount(locationId, categoryId, b.id, modeId) + a,
        0
      );
    },
    getSubcategoryTotalQty(locationId, categoryId, subcategoryId, modeId) {
      return Object.values(this.data[locationId].categories[categoryId].categories[subcategoryId].categories).reduce(
        (a, b) => (b.modes[modeId]?.qty || 0) + a,
        0
      );
    },
    getSubcategoryGrandTotalQty(locationId, categoryId, subcategoryId) {
      return Object.values(this.data[locationId].categories[categoryId].categories[subcategoryId].categories).reduce(
        (a, b) => Object.values(b.modes).reduce((aa, bb) => bb.qty + aa, 0) + a,
        0
      );
    },
    getSubcategoryTotalAmount(locationId, categoryId, subcategoryId, modeId) {
      return Object.values(this.data[locationId].categories[categoryId].categories[subcategoryId].categories).reduce(
        (a, b) => (b.modes[modeId]?.amount || 0) + a,
        0
      );
    },
    getSubcategoryGrandTotalAmount(locationId, categoryId, subcategoryId) {
      return Object.values(this.data[locationId].categories[categoryId].categories[subcategoryId].categories).reduce(
        (a, b) => Object.values(b.modes).reduce((aa, bb) => bb.amount + aa, 0) + a,
        0
      );
    },
    async exportXLS() {
      this.generating = true;
      this.$ga.event('report', 'download', this.$route.name);

      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;

      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };

      await salesService.downloadLocationsSalesPerModesXlsx(getToken(), { ...routeQuery }).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    toggle(id, type) {
      this.expand = { ...this.expand, [type]: { ...this.expand[type], [id]: !this.expand[type][id] } };
    },
    async onConsolidate() {
      localStorage.setItem('isConsolidated', this.isConsolidated);
      await this.gen(false);

      await this.fetchData();
    },
    /* eslint-disable */
    async fetchData(orderBy = 'date', order = 'asc') {
      this.loading = true;
      this.generating = true;

      this.performanceData = {
        categories: {}
      };
      this.data = [];
      this.dataModes = {};
      this.performanceDataModes = {};

      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };

      const top10 = await salesService
        .getLocationsSalesPerItems(getToken(), {
          ...routeQuery,
          sort: 'quantity',
          order: 'desc'
        })
        .then(x => {
          if (this.isConsolidatable && (!this.locationCount || this.locationCount > 1)) {
            const locationTitle = !this.locationCount
              ? this.$t('dashboard.locationSelector.allLocationsText')
              : this.$t('dashboard.locationSelector.locationsSelected', {
                  count: this.locationCount,
                  total: this.locationsBySelectedGroup.length
                });

            const consolidatedResults = {
              ...x,
              content: [
                {
                  ...x.content[0],
                  location: {
                    ...x.content[0].location,
                    reportingDisplayName: locationTitle,
                    name: locationTitle
                  }
                }
              ]
            };

            x.content
              .filter((c, i) => i > 0)
              .forEach((c, i) => {
                consolidatedResults.content[0].products = [...consolidatedResults.content[0].products, ...c.products];
              });

            const groupedProducts = {};
            const groupedProductsModes = {};

            consolidatedResults.content[0].products.forEach(x => {
              x.modes.forEach(m => {
                if (!groupedProductsModes[x.product.name]) {
                  groupedProductsModes[x.product.name] = {};
                }
                if (!groupedProductsModes[x.product.name][m.mode.remoteId + 1]) {
                  groupedProductsModes[x.product.name][m.mode.remoteId + 1] = {
                    ...m,
                    id: m.mode.remoteId + 1,
                    mode: {
                      ...m.mode,
                      id: m.mode.remoteId + 1,
                      name: 'Mode ' + (m.mode.remoteId + 1),
                      alternativeName: m.mode.remoteId + 1
                    }
                  };
                } else {
                  groupedProductsModes[x.product.name][m.mode.remoteId + 1].quantity += m.quantity;
                  groupedProductsModes[x.product.name][m.mode.remoteId + 1].amount += m.amount;
                }
              });

              if (!groupedProducts[x.product.name]) {
                groupedProducts[x.product.name] = x;
              } else {
                groupedProducts[x.product.name].quantity += x.quantity;
                groupedProducts[x.product.name].amount += x.amount;
              }
            });

            consolidatedResults.content[0].products = sortBy(Object.values(groupedProducts), [
              'quantity',
              'amount'
            ]).reverse();

            consolidatedResults.content[0].products = consolidatedResults.content[0].products.map(x => {
              return {
                ...x,
                modes: Object.values(groupedProductsModes[x.product.name])
              };
            });

            return consolidatedResults;
          }
          return x;
        });

      this.performanceData.categories = (top10.content || []).reduce((a, b) => {
        a[b.locationId] = {
          categories: {
            top_10: {
              id: 'top_10',
              name: 'Top 10',
              categories: b.products.slice(0, 10).reduce((c, d) => {
                let totalAmount = 0;
                let totalQty = 0;

                const uniqueModes = {};

                d.modes.forEach((m, k) => {
                  uniqueModes[m.id] = {
                    ...m,
                    id: m.mode && m.mode.name,
                    mode: {
                      ...(m.mode || {}),
                      id: (m.mode && m.mode.name) || ''
                    },
                    amount: ((uniqueModes[m.id] && uniqueModes[m.id].amount) || 0) + m.amount,
                    quantity: ((uniqueModes[m.id] && uniqueModes[m.id].quantity) || 0) + m.quantity
                  };
                });
                d.modes = Object.values(uniqueModes);

                const modes = d.modes.reduce((e, f) => {
                  if (!this.performanceDataModes[b.locationId]) {
                    this.performanceDataModes[b.locationId] = {};
                  }
                  this.performanceDataModes[b.locationId][f.id] = f.mode;
                  totalAmount += f.amount;
                  totalQty += f.quantity;

                  e[f.id] = {
                    id: f.id,
                    name: (f.mode && f.mode.name) || '--',
                    qty: f.quantity,
                    amount: f.amount
                  };
                  return e;
                }, {});

                c[d.id] = {
                  id: d.id,
                  name: (d.product && d.product.name) || '--',
                  totalAmount: totalAmount,
                  totalQty: totalQty,
                  modes: modes
                };
                return c;
              }, {})
            },
            lowest_10: {
              id: 'lowest_10',
              name: 'Top 10',
              categories: b.products
                .reverse()
                .slice(0, 10)
                .reduce((c, d) => {
                  let totalAmount = 0;
                  let totalQty = 0;

                  const uniqueModes = {};

                  d.modes.forEach((m, k) => {
                    uniqueModes[m.id] = {
                      ...m,
                      id: (m.mode && m.mode.name) || '',
                      mode: {
                        ...(m.mode || {}),
                        id: (m.mode && m.mode.name) || ''
                      },
                      amount: ((uniqueModes[m.id] && uniqueModes[m.id].amount) || 0) + m.amount,
                      quantity: ((uniqueModes[m.id] && uniqueModes[m.id].quantity) || 0) + m.quantity
                    };
                  });
                  d.modes = Object.values(uniqueModes);

                  const modes = d.modes.reduce((e, f) => {
                    totalAmount += f.amount;
                    totalQty += f.quantity;
                    if (!this.performanceDataModes[b.locationId]) {
                      this.performanceDataModes[b.locationId] = {};
                    }
                    this.performanceDataModes[b.locationId][f.id] = f.mode;
                    e[f.id] = {
                      id: f.id,
                      name: (f.mode && f.mode.name) || '--',
                      qty: f.quantity,
                      amount: f.amount
                    };
                    return e;
                  }, {});
                  c[d.id] = {
                    id: d.id,
                    name: (d.product && d.product.name) || '--',
                    totalAmount: totalAmount,
                    totalQty: totalQty,
                    modes: modes
                  };
                  return c;
                }, {})
            }
          }
        };
        //if (!this.isVisibleTop10) {
        //  delete a[b.locationId].categories.top_10;
        //}
        //if (!this.isVisibleLowest10) {
        //  delete a[b.locationId].categories.lowest_10;
        //}
        return a;
      }, {});

      this.data = await salesService
        .getLocationsSalesPerModes(getToken(), {
          ...routeQuery
        })
        .then(x => {
          if (this.isConsolidatable && (!this.locationCount || this.locationCount > 1)) {
            const locationTitle = !this.locationCount
              ? this.$t('dashboard.locationSelector.allLocationsText')
              : this.$t('dashboard.locationSelector.locationsSelected', {
                  count: this.locationCount,
                  total: this.locationsBySelectedGroup.length
                });

            const consolidatedResults = {
              ...x,
              content: [
                {
                  ...x.content[0],
                  location: {
                    ...x.content[0].location,
                    reportingDisplayName: locationTitle,
                    name: locationTitle
                  }
                }
              ]
            };

            x.content
              .filter((c, i) => i > 0)
              .forEach(c => {
                consolidatedResults.content[0].bigDivisions = [
                  ...consolidatedResults.content[0].bigDivisions,
                  ...(c.bigDivisions || [])
                ];
              });

            const groupedBigDivisions = {};
            consolidatedResults.content[0].bigDivisions
              .forEach(x => {
                const bigDivisionName = x.bigDivision && x.bigDivision.name || '--';
                if (!groupedBigDivisions[bigDivisionName]) {
                  groupedBigDivisions[bigDivisionName] = x;
                } else {
                  groupedBigDivisions[bigDivisionName].divisions = [
                    ...groupedBigDivisions[bigDivisionName].divisions,
                    ...x.divisions
                  ];
                }
              });
            consolidatedResults.content[0].bigDivisions = Object.values(groupedBigDivisions);

            consolidatedResults.content[0].bigDivisions.forEach((x, i) => {
              const groupedDivisions = {};

              x.divisions.forEach(x => {
                const divisionName = x.division && x.division.name || '--';
                if (!groupedDivisions[divisionName]) {
                  groupedDivisions[divisionName] = x;
                } else {
                  groupedDivisions[divisionName].products = [
                    ...groupedDivisions[divisionName].products,
                    ...x.products
                  ];
                }
              });

              consolidatedResults.content[0].bigDivisions[i].divisions = Object.values(groupedDivisions);
            });

            consolidatedResults.content[0].bigDivisions.forEach((x, i) => {
              x.divisions.forEach((x, y) => {
                const groupedProducts = {};
                x.products.forEach(x => {
                  const groupedModes = {};
                  x.modes.forEach(m => {
                    if (!groupedModes[m.mode.remoteId + 1]) {
                      groupedModes[m.mode.remoteId + 1] = {
                        ...m,
                        id: m.mode.remoteId + 1,
                        mode: {
                          ...m.mode,
                          id: m.mode.remoteId + 1,
                          name: 'Mode ' + (m.mode.remoteId + 1),
                          alternativeName: m.mode.remoteId + 1
                        }
                      };
                    } else {
                      groupedModes[m.mode.remoteId + 1].quantity += m.quantity;
                      groupedModes[m.mode.remoteId + 1].amount += m.amount;
                    }
                  });

                  x.modes = Object.values(groupedModes);

                  if (!groupedProducts[x.product.name]) {
                    groupedProducts[x.product.name] = x;
                  } else {
                    groupedProducts[x.product.name].modes = [...groupedProducts[x.product.name].modes, ...x.modes];

                    groupedProducts[x.product.name].amount += x.amount;
                    groupedProducts[x.product.name].quantity += x.quantity;
                  }
                });
                consolidatedResults.content[0].bigDivisions[i].divisions[y].products = Object.values(groupedProducts);
              });
            });

            consolidatedResults.content[0].bigDivisions.forEach((x, i) => {
              x.divisions.forEach((x, y) => {
                x.products.forEach((x, z) => {
                  const groupedModes = {};
                  x.modes.forEach(x => {
                    if (!groupedModes[x.mode.remoteId + 1]) {
                      groupedModes[x.mode.remoteId + 1] = {
                        ...x,
                        id: x.mode.remoteId + 1,
                        mode: {
                          ...x.mode,
                          id: x.mode.remoteId + 1,
                          name: 'Mode ' + (x.mode.remoteId + 1),
                          alternativeName: x.mode.remoteId + 1
                        }
                      };
                    } else {
                      groupedModes[x.mode.remoteId + 1].amount += x.amount;
                      groupedModes[x.mode.remoteId + 1].quantity += x.quantity;
                    }
                  });
                  consolidatedResults.content[0].bigDivisions[i].divisions[y].products[z].modes = Object.values(
                    groupedModes
                  );
                });
              });
            });
            return consolidatedResults;
          }
          return x;
        })
        .then(ld => {
          return ld.content.reduce((a, b) => {
            a[b.locationId] = {
              id: b.locationId,
              name: b.location.reportingDisplayName || b.location.name,
              currency: b.location.detailedCurrency.currencySymbol,
              categories: b.bigDivisions.reduce((c, d) => {
                let totalBigDivQty = 0;
                let totalBigDivAmount = 0;
                c[d.id] = {
                  id: d.id,
                  name: (d.bigDivision && d.bigDivision.name) || '--',
                  categories: d.divisions.reduce((e, f) => {
                    let totalDivQty = 0;
                    let totalDivAmount = 0;

                    e[f.id] = {
                      id: f.id,
                      name: (f.division && f.division.name) || '---',
                      categories: f.products.reduce((g, h) => {
                        let totalItemQty = 0;
                        let totalItemAmount = 0;

                        const uniqueModes = {};

                        h.modes.forEach((m, k) => {
                          uniqueModes[m.id] = {
                            ...m,
                            id: (m.mode && m.mode.name) || '',
                            mode: {
                              ...(m.mode || {}),
                              id: (m.mode && m.mode.name) || ''
                            },
                            amount: ((uniqueModes[m.id] && uniqueModes[m.id].amount) || 0) + m.amount,
                            quantity: ((uniqueModes[m.id] && uniqueModes[m.id].quantity) || 0) + m.quantity
                          };
                        });
                        h.modes = Object.values(uniqueModes);
                        g[h.id] = {
                          id: h.id,
                          name: (h.product && h.product.name) || '---',
                          modes: h.modes.reduce((i, j) => {
                            if (!this.dataModes[b.locationId]) {
                              this.dataModes[b.locationId] = {};
                            }
                            this.dataModes[b.locationId][j.id] = j.mode;

                            totalBigDivQty += j.quantity;
                            totalBigDivAmount += j.amount;
                            totalDivQty += j.quantity;
                            totalDivAmount += j.amount;
                            totalItemQty += j.quantity;
                            totalItemAmount += j.amount;

                            i[j.id] = {
                              id: j.id,
                              name: j.mode.name,
                              qty: j.quantity,
                              amount: j.amount
                            };

                            return i;
                          }, {})
                        };

                        g[h.id].qty = formatQuantity(totalItemQty);
                        g[h.id].amount = totalItemAmount;
                        return g;
                      }, {})
                    };

                    e[f.id].qty = formatQuantity(totalDivQty);
                    e[f.id].amount = totalDivAmount;

                    return e;
                  }, {})
                };
                c[d.id].qty = formatQuantity(totalBigDivQty);
                c[d.id].amount = totalBigDivAmount;

                return c;
              }, {})
            };
            return a;
          }, {});
        });

      this.loading = false;
      this.generating = false;
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  async mounted() {
    await this.fetchData();
    // this.generateTimeAt('data/sales/indicators/getIndicatorsSuccess');
  }
};
</script>
<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .vel-card__body {
  overflow: auto;
}

table {
  border-spacing: 0;
  margin-top: 1rem;
  min-width: 1200px;
  width: 100%;
}

/* stylelint-disable */
th {
  border: 1px solid #f6f6f6;
  font-size: 0.9375rem;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

td {
  border: 1px solid #f6f6f6;
  font-size: 0.9375rem;
  overflow: hidden;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  word-break: keep-all;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.arrow-right {
  /* Vector */
  background: #000;
  bottom: 25%;
  left: 35.79%;
  position: absolute;
  right: 33.33%;
  top: 25%;
}

.date {
  width: 120px;
}

.time {
  max-width: 100px;
}

.itemName {
  width: 360px;
}

.price {
  width: 120px;
}

.quantity {
  width: 90px;
}

.check {
  width: 120px;
}

.categoryName {
  width: 300px;
}

.employee {
  width: 300px;
}

.authorized {
  width: 300px;
}

.location {
  border: none;
  width: 200px;
}

.total {
  background-color: #d0d0d0;
  font-weight: bold;
}

.subCategoryTotal td {
  background-color: #e0e0e0;
  font-weight: bold;
}

.itemTotal td {
  background-color: #f6f6f6;
  font-weight: bold;
}

.itemTotal td.total {
  background-color: #e0e0e0;
  font-weight: bold;
}

.subtotal td {
  background-color: #f6f6f6;
  border-bottom: 1px solid #c0c0c0;

  &.location {
  }
}

.order-header {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;

  .sort {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .order {
      height: 9px;
      width: 9px;

      &.active {
        color: #00a9e1;
        fill: #00a9e1;
      }
    }
  }
}

.multiple {
  cursor: pointer;
}

.subtotal + tr .location span {
  display: none;
}

.empty {
  border-top: 1px solid #fff;
}

tr {
  border: 1px solid #ccc;
}

.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

strong {
  font-weight: 700;
}

td {
  font-size: 0.9375rem;
}

.expanded > td.qty span {
  display: none;
}

.expanded > td.amount span {
  display: none;
}

.expanded .total {
  background-color: #ffffff;
}

td {
  &.qty.total,
  &.amount.total {
    background-color: #e0e0e0;
  }
}

.expanded td {
  &.qty.total,
  &.amount.total {
    background-color: #ffffff;
  }
}

.no-data {
  font-size: 2rem;
  color: #4f4f4f;
  text-align: center;
  margin: 2rem;
}
</style>
