var render, staticRenderFns
import script from "./VelForm.vue?vue&type=script&lang=js&"
export * from "./VelForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/runner/work/velweb/velweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('76b1a94a')) {
      api.createRecord('76b1a94a', component.options)
    } else {
      api.reload('76b1a94a', component.options)
    }
    
  }
}
component.options.__file = "src/components/x-form/VelForm.vue"
export default component.exports