<template>
  <tax-form
    :tax="getSelectedTax"
    :taxCategories="getTaxCategoriesRows"
    :isLoading="isLoading || isTaxLoading"
    :errorLabel="'taxForm.errors.edit'"
    @submit="handleSubmit"
  />
</template>

<script>
import TaxForm from './components/TaxForm';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataTaxCategories} from "@/stores/data/tax-categories.module";
import {useDataTaxes} from "@/stores/data/taxes.module";

export default {
  name: 'edit-tax',
  components: {
    TaxForm
  },
  methods: {
    ...mapPiniaActions(useDataTaxCategories, ['fetchTaxCategories']),
    ...mapPiniaActions(useDataTaxes, ['clearMessage']),
    ...mapPiniaActions(useDataTaxes, ['getTaxById', 'editTax']),
    async handleSubmit(obj) {
      await this.editTax({ ...obj, id: this.$route.params.id });
      if (this.success) {
        await this.$router.replace({ name: 'taxes-setup' });
      } else {
        await this.getTaxById(this.$route.params.id);
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataTaxes, ['getSelectedTax', 'isTaxLoading', 'success']),
    ...mapPiniaState(useDataTaxCategories, ['getTaxCategoriesRows', 'isLoading'])
  },
  beforeDestroy() {
    this.clearMessage();
  },
  async mounted() {
    await Promise.all([this.fetchTaxCategories(), this.getTaxById(this.$route.params.id)]);
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/deep/ .vel-page {
  &__content {
    color: #2a3142;
    -ms-overflow-style: none;
    overflow-y: hidden;
    padding: 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .footer {
      display: none;
    }
  }
}
</style>
