import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import {createCollectionState, getSelectors, removeAll, setAll, setOne} from '@/helpers/stores.helpers';
import router from "@/router";
import bigDivisionsService from "@/services/bigDivisions.service";


const { selectAll, selectAllFiltered, selectSelected, selectTotal } = getSelectors();

export const useDataBigDivisions = defineStore('bigDivisions', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null,
      filter: ''
    }),
  getters: {
    bigDivisions: selectAllFiltered(['remoteId', 'name', 'alternativeName', 'licenceNumber']),
    getAllBigDivisions: selectAll,
    selectTotal,
    selectSelected
  },
  actions: {
    async getBigDivisions() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getBigDivisionsRequest();
        const bigDivisions = await bigDivisionsService.getBigDivisions(getToken(), routeQuery);
        this.getBigDivisionsSuccess(bigDivisions);
      } catch (error) {
        this.getBigDivisionsFailure(error);
      }
    },
    async getBigDivisionById() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getBigDivisionByIdRequest();
        const bigDivision = await bigDivisionsService.getBigDivision(getToken(), routeQuery);
        this.getBigDivisionByIdSuccess( bigDivision);
        this.setSelected(bigDivision.id);
      } catch (error) {
        this.getBigDivisionByIdFailure( error);
      }
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    getBigDivisionsRequest() {
      this.loading = true;
      this.error = null;
    },
    getBigDivisionsSuccess(bigDivisions) {
      this.data = setAll(
        bigDivisions.map(s => ({ ...s, remoteId: s.remoteId + 1 })),
        this.data
      );
      this.error = null;
      this.loading = false;
    },
    getBigDivisionsFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    getBigDivisionByIdRequest() {
      this.loading = true;
      this.error = null;
    },
    getBigDivisionByIdSuccess(bigDivision) {
      this.data = removeAll(this.data);
      this.data = setOne({ ...bigDivision, remoteId: bigDivision.remoteId + 1 }, this.data);
      this.error = null;
      this.loading = false;
    },
    getBigDivisionByIdFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    setSelected(value) {
      this.data.selected = value;
    }
  }
});
