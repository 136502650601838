<template>
  <div v-if="isLargeUp" :class="`sidebar ${appStore.isExpanded ? '' : 'collapsed'}`">
    <vel-button @click="handleMenuCollapse" type="link" :icon="`${appStore.isExpanded ? 'menu-open' : 'menu-closed'}`" />
    <!-- <div>
      <vel-icon size="medium" name="flag" />
      {{ $t('sidebar.sections.exceptions') }}
    </div> -->
    <router-link
      :to="{ name: 'analytics' }"
      v-if="isUserAdmin"
      :class="this.selected === 'analytics' ? 'selected' : ''"
    >
      <div class="link-container" v-on:mouseover="hover = 'analytics'" v-on:mouseleave="hover = ''">
        <el-tooltip effect="light" v-if="!appStore.isExpanded" :content="$t('sidebar.sections.analytics')">
          <vel-icon
            size="medium"
            :name="selected === 'analytics' || hover === 'analytics' ? 'analytics-solid' : 'analytics'"
          />
        </el-tooltip>
        <vel-icon
          v-else
          size="medium"
          :name="selected === 'analytics' || hover === 'analytics' ? 'analytics-solid' : 'analytics'"
        />
        <span :class="`${appStore.isExpanded ? '' : 'hidden'}`">
          {{ $t('sidebar.sections.analytics') }}
        </span>
      </div>
    </router-link>
    <router-link :to="{ name: 'dashboard' }" :class="this.selected === 'dashboard' ? 'selected' : ''">
      <div class="link-container" v-on:mouseover="hover = 'dashboard'" v-on:mouseleave="hover = ''">
        <el-tooltip effect="light" v-if="!appStore.isExpanded" :content="$t('sidebar.sections.dashboard')">
          <vel-icon
            size="medium"
            :name="selected === 'dashboard' || hover === 'dashboard' ? 'dashboard-icon-solid' : 'dashboard-icon'"
          />
        </el-tooltip>
        <vel-icon
          v-else
          size="medium"
          :name="selected === 'dashboard' || hover === 'dashboard' ? 'dashboard-icon-solid' : 'dashboard-icon'"
        />
        <span :class="`${appStore.isExpanded ? '' : 'hidden'}`">
          {{ $t('sidebar.sections.dashboard') }}
        </span>
      </div>
    </router-link>

    <router-link to="/navigation/reports" :class="$route.meta.menu === 'report' ? 'router-link-active' : ''">
      <div class="link-container" v-on:mouseover="hover = 'reports-menu'" v-on:mouseleave="hover = ''">
        <el-tooltip effect="light" v-if="!appStore.isExpanded" :content="$t('sidebar.sections.reports')">
          <vel-icon
            size="medium"
            :name="selected === 'reports-menu' || hover === 'reports-menu' ? 'description-solid' : 'description'"
          />
        </el-tooltip>
        <vel-icon
          v-else
          size="medium"
          :name="selected === 'reports-menu' || hover === 'reports-menu' ? 'description-solid' : 'description'"
        />
        <span :class="`${appStore.isExpanded ? '' : 'hidden'}`">
          {{ $t('sidebar.sections.reports') }}
        </span>
      </div>
    </router-link>
    <router-link
      to="/navigation/config"
      v-if="isUserAdmin"
      :class="$route.meta.menu === 'configuration' ? 'router-link-active' : ''"
    >
      <div class="link-container" v-on:mouseover="hover = 'configuration-menu'" v-on:mouseleave="hover = ''">
        <el-tooltip effect="light" v-if="!appStore.isExpanded" :content="$t('sidebar.sections.configuration')">
          <vel-icon
            size="medium"
            :name="$route.meta.menu === 'configuration' ? 'construction-solid' : 'construction'"
          />
        </el-tooltip>
        <vel-icon
          v-else
          size="medium"
          :name="$route.meta.menu === 'configuration' ? 'construction-solid' : 'construction'"
        />
        <span :class="`${appStore.isExpanded ? '' : 'hidden'}`">
          {{ $t('sidebar.sections.configuration') }}
        </span>
      </div>
    </router-link>
    <router-link to="/navigation/setup" :class="$route.meta.menu === 'setup' ? 'router-link-active' : ''">
      <div class="link-container" v-on:mouseover="hover = 'setup'" v-on:mouseleave="hover = ''">
        <el-tooltip effect="light" v-if="!appStore.isExpanded" :content="$t('sidebar.sections.settings')">
          <vel-icon size="medium" :name="this.selected.includes('setup') || hover === 'setup' ? 'cog-solid' : 'cog'" />
        </el-tooltip>
        <vel-icon v-else size="medium" :name="$route.meta.menu === 'setup' ? 'cog-solid' : 'cog'" />
        <span :class="`${appStore.isExpanded ? '' : 'hidden'}`">
          {{ $t('sidebar.sections.settings') }}
        </span>
      </div>
    </router-link>
    <div class="version">v{{ version }}</div>
  </div>
  <div v-else :class="`sidebar ${appStore.isExpanded ? 'mobile' : 'collapsed mobile'}`">
    <!-- <div>
      <vel-icon size="medium" name="flag" />
      {{ $t('sidebar.sections.exceptions') }}
    </div> -->

    <router-link
      :to="{ name: 'dashboard' }"
      v-bind:class="{ hidden: !appStore.isExpanded, selected: this.selected === 'dashboard' }"
    >
      <div class="link-container">
        <vel-icon size="medium" :name="selected === 'dashboard' ? 'dashboard-icon-solid' : 'dashboard-icon'" />
        <span>
          {{ $t('sidebar.sections.dashboard') }}
        </span>
      </div>
    </router-link>
    <router-link
      to="/navigation/reports"
      v-bind:class="{ hidden: !appStore.isExpanded, 'router-link-active': $route.meta.menu === 'report' }"
    >
      <div class="link-container">
        <vel-icon size="medium" :name="$route.meta.menu === 'report' ? 'description-solid' : 'description'" />
        <span>
          {{ $t('sidebar.sections.reports') }}
        </span>
      </div>
    </router-link>

    <router-link
      to="/navigation/config"
      v-bind:class="{ hidden: !appStore.isExpanded, 'router-link-active': $route.meta.menu === 'configuration' }"
      v-if="isUserAdmin"
    >
      <div class="link-container">
        <vel-icon size="medium" :name="$route.meta.menu === 'configuration' ? 'construction-solid' : 'construction'" />
        <span>
          {{ $t('sidebar.sections.configuration') }}
        </span>
      </div>
    </router-link>
    <router-link
      to="/navigation/setup"
      v-bind:class="{ hidden: !appStore.isExpanded, 'router-link-active': $route.meta.menu === 'setup' }"
    >
      <div class="link-container">
        <vel-icon size="medium" :name="$route.meta.menu === 'setup' ? 'cog-solid' : 'cog'" />
        <span>
          {{ $t('sidebar.sections.settings') }}
        </span>
      </div>
    </router-link>
    <div v-if="appStore.isExpanded" class="version">v{{ version }}</div>
  </div>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelButton from '@/components/button/VelButton';
import VelIcon from '@/components/icon/VelIcon';
import {useUIBreadcrumbsStore} from "@/stores/ui/breadcrumbs.module";
import {mapStores} from "pinia/dist/pinia.esm-browser";
import {useUIAppStore} from "@/stores/ui/app.module";
import {mapState} from "pinia";
import {useDataUsersUserPermissions} from "@/stores/data/users/user-permissions.module";

export default {
  name: 'new-sidebar',
  mixins: [DeviceMixin],
  components: {
    VelButton,
    VelIcon
  },
  setup() {
    const breadcrumbsStore = useUIBreadcrumbsStore();
    return { breadcrumbs: breadcrumbsStore.breadcrumbs }
  },
  data() {
    return {
      selected: '',
      hover: ''
    };
  },
  computed: {
    ...mapStores(useUIAppStore),
    ...mapState(useDataUsersUserPermissions, ['isUserAdmin']),
    version() {
      return process.env.PACKAGE_VERSION || '0.0.0';
    }
  },
  methods: {
    handleMenuCollapse() {
      this.appStore.setExpanded(!this.appStore.isExpanded);
    }
  },
  watch: {
    breadcrumbs(newVal) {
      alert('test');
      if (newVal) {
        this.selected = newVal[newVal.length - 1].name;
        if (!this.isLargeUp) {
          this.appStore.setExpanded(false);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';
@import 'layout.scss';

.link-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  position: relative;
  width: 100%;
}

.sidebar {
  align-items: center;
  background: #2a3142;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: $layout-sidebar-height;
  transition: width 0.25s;
  user-select: none;

  .router-link-active:hover > div {
    color: #2a3142 !important;

    svg {
      fill: #2a3142 !important;
    }
  }
  .router-link-active > div {
    background: #ffffff;
    color: #2a3142;

    ::after {
      background-color: #2a3142;
      border-bottom-right-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      bottom: 8px;
      content: ' ';
      display: block;
      left: 0;
      position: absolute;
      top: 8px;
      width: 0.2rem;
    }

    svg {
      fill: #2a3142 !important;
    }
  }

  .hidden {
    display: none;
  }

  &.mobile {
    position: absolute;
    z-index: $layout-sidebar-z;
  }

  &.collapsed {
    width: 4rem;

    &.mobile {
      width: 0;
    }
  }

  div:not(:last-child),
  button,
  a {
    align-items: center;
    background: #2a3142;
    border-radius: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem 0;
    text-decoration: none;
    width: 100%;

    &:hover {
      background-color: #323949;
    }

    &.selected {
      cursor: pointer;

      svg {
        fill: #2a3142;
      }
    }

    span {
      font-family: Roboto, sans-serif;
      margin-top: rem-calc(4);
      white-space: nowrap;
    }
  }

  button {
    height: auto !important;
    padding: 1rem 0 !important;
  }

  .version {
    height: rem-calc(40);
    margin-bottom: rem-calc(90);
    margin-top: auto;

    @include breakpoint(medium) {
      margin-bottom: unset;
    }
  }
}
</style>
