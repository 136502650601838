var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticStyle: { "border-bottom": "8px solid #f5f5f5" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "b-row",
        {
          staticClass: "py-3",
          staticStyle: { "border-bottom": "1px solid #4c566733" }
        },
        [
          _c(
            "b-col",
            {
              staticStyle: { display: "flex", "flex-direction": "row" },
              attrs: { md: "3" }
            },
            [
              _c(
                "div",
                { staticClass: "title-icon d-none" },
                [_c("b-icon", { attrs: { icon: "pencil-fill" } })],
                1
              ),
              _c("div", { staticStyle: { "padding-left": "1rem" } }, [
                _c("h4", { staticClass: "title-right mb-2 my-0" }, [
                  _vm._v(_vm._s(_vm.employee.name || "--"))
                ]),
                _c(
                  "div",
                  {
                    staticClass: "title-right",
                    staticStyle: { "font-size": "1rem" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.employee.jobRoles || [])
                            .filter(function(x) {
                              return x.delete !== true && x.functionId !== null
                            })
                            .map(function(b) {
                              return (_vm.accessTypes || []).filter(function(
                                x
                              ) {
                                return x.id === b.functionId
                              })[0]
                            })
                            .map(function(b) {
                              return (b && b.name) || ""
                            })
                            .join(", ") || "--"
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticStyle: {
                "align-items": "center",
                display: "flex",
                "justify-content": "flex-start"
              }
            },
            [
              _c(
                "label",
                { staticClass: "mb-0", attrs: { for: "terminated" } },
                [_vm._v(_vm._s(_vm.$t("employeeCreate.deactivateEmployee")))]
              ),
              _c("b-form-checkbox", {
                staticClass: "ml-2 mr-n2",
                staticStyle: { "font-size": "13px" },
                attrs: { switch: "", id: "terminated", name: "terminated" },
                model: {
                  value: _vm.employee.terminated,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "terminated", $$v)
                  },
                  expression: "employee.terminated"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }