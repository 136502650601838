var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-datepicker", {
    staticClass: "date-range-selector",
    attrs: {
      align: "center",
      type: "daterange",
      clearable: _vm.clearable,
      editable: _vm.isEditable,
      disabled: _vm.disabled,
      format: _vm.format,
      "picker-options": _vm.options,
      "unlink-panels": false
    },
    on: { change: _vm.handleChange },
    model: {
      value: _vm.editedRange,
      callback: function($$v) {
        _vm.editedRange = $$v
      },
      expression: "editedRange"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }