<template>
  <vel-page
    :multiple-locations="locationsList && locationsList.length > 1"
    :enable-all-currencies="true"
    :show-selector-button="true"
    class="report"
    v-bind="pageAttrs"
  >
    <template v-slot:export>
      <vel-button
        :disabled="loading || (!versionIsValid() && !isMultiLocations)"
        :loading="generating"
        type="primary"
        @click="exportXLS"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>
    <template v-slot:toolbar-options>
      <div style="padding: 8px 1rem 0 1rem;" v-if="(!locationCount || locationCount > 1) && getCurrencySelectorSelected !== ''">
        <vel-checkbox v-model="isConsolidated" @change="onConsolidate()">
          {{ $t('toolbar.selectors.consolidate') }}
        </vel-checkbox>
      </div>
    </template>
    <vel-spinner class="spinner" v-if="loading" />
    <div v-if="invalidLocations && !loading" style="background-color: #fff2cc; margin-bottom: 1rem; padding: 1rem;">
      <template v-if="getLocationSelectorSelected">
        {{ $t('invalidVersions.requiredVersion') }}
      </template>
      <template v-else>
        {{ $t('invalidVersions.requiredVersionMultiLocations') }}
        <a href="javascript://" @click="showLocationsList = !showLocationsList">{{ $t('invalidVersions.collapse') }}</a>
        <ul v-if="showLocationsList" style="margin-top: 1rem;">
          <li v-for="location in invalidLocations" :key="location.id">
            {{ location.reportingDisplayName || location.name }}
          </li>
        </ul>
      </template>
    </div>

    <p
      v-if="showNotification && !isVersionLoading"
      style="background-color: #ffd2d2; margin-bottom: 1rem; padding: 1rem;"
    >
      {{ $t('invalidVersions.notice') }}
    </p>

    <template v-if="!loading">
      <div class="no-data" v-if="!Object.values(voids.data).filter(l => versionIsValid(l.location.id)).length">
        {{ $t('itemSalesCategory.noData') }}
      </div>
      <template v-else>
        <vel-card>
          <h5>{{ $t('audit.sales.title') }}</h5>
          <table>
            <thead>
              <tr>
                <th style="width: 260px;" rowspan="2">
                  {{ $t('audit.sales.table.headers.location') }}
                </th>
                <th style="width: 120px;" rowspan="2">{{ $t('audit.sales.table.headers.date') }}</th>
                <th style="width: 165px;" class="text-right" rowspan="2">
                  {{ $t('audit.sales.table.headers.netSales') }}
                </th>
                <th
                  class="text-center"
                  :style="'width: ' + (Object.keys(voids.taxes).length || 1) * 165 + 'px'"
                  :colspan="Object.keys(voids.taxes).length"
                >
                  {{ $t('audit.sales.table.headers.taxes') }}
                </th>
                <th style="width: 175px;" class="text-right" rowspan="2">
                  {{ $t('audit.sales.table.headers.discounts') }}
                </th>
                <th style="width: 175px;" class="text-right" rowspan="2" v-if="haveCashDiscounts">
                  {{ $t('audit.sales.table.headers.discountsCash') }}
                </th>
                <th style="width: 175px;" class="text-right" rowspan="2" v-if="haveSurcharge">
                  {{ $t('audit.sales.table.headers.surcharge') }}
                </th>
                <th style="width: 175px;" class="text-right" rowspan="2">
                  {{ $t('audit.sales.table.headers.grossSales') }}
                </th>
                <th style="width: 180px;" class="text-right" rowspan="2">
                  {{ $t('audit.sales.table.headers.transaction') }}
                </th>
                <th style="width: 175px;" class="text-right" :rowspan="2">
                  {{ $t('audit.sales.table.headers.average') }}
                </th>
                <th></th>
              </tr>
              <tr>
                <th
                  class="text-right"
                  v-for="taxe in Object.keys(voids.taxes).sort((a, b) => a.localeCompare(b))"
                  v-bind:key="taxe"
                >
                  {{ taxe }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(location, locationId) in Object.values(voids.data)
                  .filter(l => versionIsValid(l.location.id))
                  .sort((a, b) =>
                    (a.location.reportingDisplayName || a.location.name).localeCompare(
                      b.location.reportingDisplayName || b.location.name
                    )
                  )"
              >
                <template v-for="(l, i) in location.dailySales">
                  <tr v-if="expand.sales[locationId] || !isMultiLocations || !isMultiDays" :key="locationId + i">
                    <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'sales')">
                      <span v-if="!i">
                        <vel-icon
                          v-if="isMultiDays && isMultiLocations"
                          style="height: 12px; width: 8px;"
                          name="bottom-arrow"
                        ></vel-icon>
                        <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                          {{
                            !locationCount
                              ? $t('toolbar.selectors.consolidate') +
                                ' - ' +
                                $t('dashboard.locationSelector.allLocationsText')
                              : $t('dashboard.locationSelector.locationsSelected', {
                                  count: locationCount,
                                  total: locationsBySelectedGroup.length
                                })
                          }}
                        </span>
                        <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                      </span>
                    </td>
                    <td class="date">
                      <span>{{ l.date }}</span>
                    </td>
                    <td class="text-right">
                      <vel-amount
                        :amount="l.sales.indicators.netSalesAmount"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                    <td v-if="!Object.keys(voids.taxes).length" class="text-right"></td>
                    <td
                      class="text-right"
                      v-for="taxe in Object.keys(voids.taxes).sort((a, b) => a.localeCompare(b))"
                      v-bind:key="taxe"
                    >
                      <vel-amount
                        :amount="(l.sales.taxesSales[taxe] && l.sales.taxesSales[taxe].taxesAmount) || 0"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                    <td class="text-right">
                      <vel-amount
                        :amount="l.sales.indicators.discountsAmount"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                    <td class="text-right" v-if="haveCashDiscounts">
                      <vel-amount
                        :amount="l.sales.indicators.cashDiscountAmount"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                    <td class="text-right" v-if="haveSurcharge">
                      <vel-amount
                        :amount="l.sales.indicators.surchargeAmount"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                    <td class="text-right">
                      <vel-amount
                        :amount="l.sales.indicators.grossSalesAmount"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                    <td class="text-right">
                      <vel-quantity :qty="l.sales.indicators.customers"></vel-quantity>
                    </td>
                    <td class="text-right">
                      <vel-amount
                        :amount="l.sales.indicators.grossSalesAmount / l.sales.indicators.customers"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                  </tr>
                </template>
                <template v-if="isMultiDays">
                  <tr :class="{ total: expand.sales[locationId] || !isMultiLocations }" :key="locationId">
                    <td
                      class="location"
                      style="cursor: pointer;"
                      :class="{ noBorder: expand.sales[locationId] }"
                      @click="toggle(locationId, 'sales')"
                      v-if="isMultiLocations || isMultiDays"
                    >
                      <span v-if="!expand.sales[locationId] && isMultiLocations">
                        <vel-icon
                          style="height: 8px; position: relative; top: -2px; width: 8px;"
                          :name="expand.sales[locationId] ? 'bottom-arrow' : 'right-arrow'"
                        ></vel-icon>
                        <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                          {{
                            !locationCount
                              ? $t('toolbar.selectors.consolidate') +
                                ' - ' +
                                $t('dashboard.locationSelector.allLocationsText')
                              : $t('dashboard.locationSelector.locationsSelected', {
                                  count: locationCount,
                                  total: locationsBySelectedGroup.length
                                })
                          }}
                        </span>
                        <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                      </span>
                    </td>

                    <td class="date">
                      <strong>{{ $t('audit.sales.total') }}</strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-amount
                          :amount="location.totalNetSales"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>

                    <td
                      class="text-right"
                      v-for="taxe in Object.keys(voids.taxes).sort((a, b) => a.localeCompare(b))"
                      v-bind:key="taxe"
                    >
                      <strong>
                        <vel-amount
                          :amount="location.totalTaxes[taxe] || 0"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>

                    <td class="text-right">
                      <strong>
                        <vel-amount
                          :amount="location.totalDiscounts"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>
                    <td class="text-right" v-if="haveCashDiscounts">
                      <strong>
                        <vel-amount
                          :amount="location.totalCashDiscounts"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>
                    <td class="text-right" v-if="haveSurcharge">
                      <strong>
                        <vel-amount
                          :amount="location.totalSurcharge"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-amount
                          :amount="location.totalGrossSales"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-quantity :qty="location.totalTransaction"></vel-quantity>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-amount
                          :amount="location.totalGrossSales / location.totalTransaction"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </vel-card>
        <div class="card-container">
          <vel-card class="card-takeout">
            <h5>{{ $t('audit.takeout.title') }}</h5>
            <table>
              <thead>
                <tr>
                  <th style="width: 260px;" rowspan="2">
                    {{ $t('audit.takeout.table.headers.location') }}
                  </th>
                  <th style="width: 120px;" rowspan="2">
                    {{ $t('audit.takeout.table.headers.date') }}
                  </th>
                  <th style="width: 260px;" class="text-center" colspan="2">
                    {{ $t('audit.takeout.table.headers.delivery') }}
                  </th>
                  <th style="width: 260px;" class="text-center" colspan="2">
                    {{ $t('audit.takeout.table.headers.takeout') }}
                  </th>
                  <th></th>
                </tr>
                <tr>
                  <th class="text-right">
                    {{ $t('audit.takeout.table.headers.count') }}
                  </th>
                  <th class="text-right">
                    {{ $t('audit.takeout.table.headers.amount') }}
                  </th>
                  <th class="text-right">
                    {{ $t('audit.takeout.table.headers.count') }}
                  </th>
                  <th class="text-right">
                    {{ $t('audit.takeout.table.headers.amount') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(location, locationId) in Object.values(voids.data)
                    .filter(l => versionIsValid(l.location.id))
                    .sort((a, b) =>
                      (a.location.reportingDisplayName || a.location.name).localeCompare(
                        b.location.reportingDisplayName || b.location.name
                      )
                    )"
                >
                  <template v-for="(l, i) in location.dailySales">
                    <tr v-if="expand.zones[locationId] || !isMultiLocations || !isMultiDays" :key="locationId + i">
                      <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'zones')">
                        <span v-if="!i">
                          <vel-icon
                            v-if="isMultiDays && isMultiLocations"
                            style="height: 12px; width: 8px;"
                            name="bottom-arrow"
                          ></vel-icon>
                          <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                            {{
                              !locationCount
                                ? $t('toolbar.selectors.consolidate') +
                                  ' - ' +
                                  $t('dashboard.locationSelector.allLocationsText')
                                : $t('dashboard.locationSelector.locationsSelected', {
                                    count: locationCount,
                                    total: locationsBySelectedGroup.length
                                  })
                            }}
                          </span>
                          <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                        </span>
                      </td>
                      <td class="date">
                        <span>{{ l.date }}</span>
                      </td>
                      <td class="text-right">
                        <vel-quantity
                          :qty="l.sales.zoneSales['DELIVERY'] && l.sales.zoneSales['DELIVERY'].customers"
                        ></vel-quantity>
                      </td>

                      <td class="text-right">
                        <vel-amount
                          :amount="l.sales.zoneSales['DELIVERY'] && l.sales.zoneSales['DELIVERY'].salesAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                      <td class="text-right">
                        <vel-quantity
                          :qty="l.sales.zoneSales['TAKEOUT'] && l.sales.zoneSales['TAKEOUT'].customers"
                        ></vel-quantity>
                      </td>

                      <td class="text-right">
                        <vel-amount
                          :amount="l.sales.zoneSales['TAKEOUT'] && l.sales.zoneSales['TAKEOUT'].salesAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                    </tr>
                  </template>
                  <template v-if="isMultiDays">
                    <tr :class="{ total: expand.zones[locationId] || !isMultiLocations }" :key="locationId">
                      <td
                        class="location"
                        style="cursor: pointer;"
                        :class="{ noBorder: expand.zones[locationId] }"
                        @click="toggle(locationId, 'zones')"
                      >
                        <span v-if="!expand.zones[locationId] && isMultiLocations">
                          <vel-icon
                            style="height: 8px; position: relative; top: -2px; width: 8px;"
                            :name="expand.zones[locationId] ? 'bottom-arrow' : 'right-arrow'"
                          ></vel-icon>
                          <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                            {{
                              !locationCount
                                ? $t('toolbar.selectors.consolidate') +
                                  ' - ' +
                                  $t('dashboard.locationSelector.allLocationsText')
                                : $t('dashboard.locationSelector.locationsSelected', {
                                    count: locationCount,
                                    total: locationsBySelectedGroup.length
                                  })
                            }}
                          </span>
                          <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                        </span>
                      </td>

                      <td class="date">
                        <strong>
                          {{ $t('audit.takeout.total') }}
                        </strong>
                      </td>

                      <td class="text-right">
                        <strong>
                          <vel-quantity
                            :qty="location.totalZones['DELIVERY'] && location.totalZones['DELIVERY'].customers"
                          ></vel-quantity>
                        </strong>
                      </td>
                      <td class="text-right">
                        <strong>
                          <vel-amount
                            :amount="location.totalZones['DELIVERY'] && location.totalZones['DELIVERY'].salesAmount"
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>
                      <td class="text-right">
                        <strong>
                          <vel-quantity
                            :qty="location.totalZones['TAKEOUT'] && location.totalZones['TAKEOUT'].customers"
                          ></vel-quantity>
                        </strong>
                      </td>
                      <td class="text-right">
                        <strong>
                          <vel-amount
                            :amount="location.totalZones['TAKEOUT'] && location.totalZones['TAKEOUT'].salesAmount"
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </vel-card>
          <vel-card class="card-gift">
            <h5>{{ $t('audit.gift.title') }}</h5>
            <table>
              <thead>
                <tr style="height: 64px;">
                  <th style="width: 260px;">
                    {{ $t('audit.gift.table.headers.location') }}
                  </th>
                  <th style="width: 120px;">{{ $t('audit.gift.table.headers.date') }}</th>
                  <th style="width: 120px;" class="text-right">
                    {{ $t('audit.gift.table.headers.giftLoad') }}
                  </th>
                  <th style="width: 120px;" class="text-right">
                    {{ $t('audit.gift.table.headers.loyaltyLoad') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(location, locationId) in Object.values(voids.data)
                    .filter(l => versionIsValid(l.location.id))
                    .sort((a, b) =>
                      (a.location.reportingDisplayName || a.location.name).localeCompare(
                        b.location.reportingDisplayName || b.location.name
                      )
                    )"
                >
                  <template v-for="(l, i) in location.dailySales">
                    <tr v-if="expand.gifts[locationId] || !isMultiLocations || !isMultiDays" :key="locationId + i">
                      <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'gifts')">
                        <span v-if="!i">
                          <vel-icon
                            v-if="isMultiDays && isMultiLocations"
                            style="height: 12px; width: 8px;"
                            name="bottom-arrow"
                          ></vel-icon>
                          <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                            {{
                              !locationCount
                                ? $t('toolbar.selectors.consolidate') +
                                  ' - ' +
                                  $t('dashboard.locationSelector.allLocationsText')
                                : $t('dashboard.locationSelector.locationsSelected', {
                                    count: locationCount,
                                    total: locationsBySelectedGroup.length
                                  })
                            }}
                          </span>
                          <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                        </span>
                      </td>
                      <td class="date">
                        <span>{{ l.date }}</span>
                      </td>
                      <td class="text-right">
                        <vel-amount
                          :amount="l.sales.transactionsIndicators.giftCardLoadsAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                      <td class="text-right">
                        <vel-amount
                          :amount="l.sales.transactionsIndicators.loyaltiesAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                    </tr>
                  </template>

                  <template v-if="isMultiDays">
                    <tr :class="{ total: expand.gifts[locationId] || !isMultiLocations }" :key="locationId">
                      <td
                        class="location"
                        style="cursor: pointer;"
                        :class="{ noBorder: expand.gifts[locationId] }"
                        @click="toggle(locationId, 'gifts')"
                        v-if="isMultiLocations || isMultiDays"
                      >
                        <span v-if="!expand.gifts[locationId] && isMultiLocations">
                          <vel-icon
                            style="height: 8px; position: relative; top: -2px; width: 8px;"
                            :name="expand.gifts[locationId] ? 'bottom-arrow' : 'right-arrow'"
                          ></vel-icon>
                          <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                            {{
                              !locationCount
                                ? $t('toolbar.selectors.consolidate') +
                                  ' - ' +
                                  $t('dashboard.locationSelector.allLocationsText')
                                : $t('dashboard.locationSelector.locationsSelected', {
                                    count: locationCount,
                                    total: locationsBySelectedGroup.length
                                  })
                            }}
                          </span>
                          <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                        </span>
                      </td>

                      <td class="date">
                        <strong>{{ $t('audit.gift.total') }}</strong>
                      </td>
                      <td class="text-right">
                        <strong>
                          <vel-amount
                            :amount="location.totalGiftCardLoadsAmount"
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>

                      <td class="text-right">
                        <strong>
                          <vel-amount
                            :amount="location.totalLoyaltiesAmount"
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </vel-card>
        </div>
        <vel-card>
          <h5>{{ $t('audit.refunds.title') }}</h5>
          <table>
            <thead>
              <tr>
                <th style="width: 260px;" rowspan="2">
                  {{ $t('audit.refunds.table.headers.location') }}
                </th>
                <th style="width: 120px;" rowspan="2">{{ $t('audit.refunds.table.headers.date') }}</th>
                <th style="width: 400px;" class="text-center" colspan="3">
                  {{ $t('audit.refunds.table.headers.refunds') }}
                </th>
                <th style="width: 400px;" class="text-center" colspan="3">
                  {{ $t('audit.refunds.table.headers.voids') }}
                </th>
                <th style="width: 267px;" class="text-center" colspan="2">
                  {{ $t('audit.refunds.table.headers.itemCorrection') }}
                </th>
              </tr>
              <tr>
                <th class="text-right">{{ $t('audit.refunds.table.headers.qty') }}</th>
                <th class="text-right">{{ $t('audit.refunds.table.headers.total') }}</th>
                <th class="text-right">{{ $t('audit.refunds.table.headers.percent') }}</th>
                <th class="text-right">{{ $t('audit.refunds.table.headers.qty') }}</th>
                <th class="text-right">{{ $t('audit.refunds.table.headers.total') }}</th>
                <th class="text-right">{{ $t('audit.refunds.table.headers.percent') }}</th>
                <th class="text-right">{{ $t('audit.refunds.table.headers.qty') }}</th>
                <th class="text-right">{{ $t('audit.refunds.table.headers.total') }}</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(location, locationId) in Object.values(voids.data)
                  .filter(l => versionIsValid(l.location.id))
                  .sort((a, b) =>
                    (a.location.reportingDisplayName || a.location.name).localeCompare(
                      b.location.reportingDisplayName || b.location.name
                    )
                  )"
              >
                <template v-for="(l, i) in location.dailySales">
                  <tr v-if="expand.refunds[locationId] || !isMultiLocations || !isMultiDays" :key="locationId + i">
                    <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'refunds')">
                      <span v-if="!i">
                        <vel-icon
                          v-if="isMultiDays && isMultiLocations"
                          style="height: 12px; width: 8px;"
                          name="bottom-arrow"
                        ></vel-icon>
                        <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                          {{
                            !locationCount
                              ? $t('toolbar.selectors.consolidate') +
                                ' - ' +
                                $t('dashboard.locationSelector.allLocationsText')
                              : $t('dashboard.locationSelector.locationsSelected', {
                                  count: locationCount,
                                  total: locationsBySelectedGroup.length
                                })
                          }}
                        </span>
                        <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                      </span>
                    </td>
                    <td class="date">
                      <span>{{ l.date }}</span>
                    </td>
                    <td class="text-right">
                      <vel-quantity :qty="l.sales.transactionsIndicators.refunds"></vel-quantity>
                    </td>
                    <td class="text-right">
                      <vel-amount
                        :amount="l.sales.transactionsIndicators.refundsAmount"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                    <td class="text-right">
                      <vel-quantity :qty="l.sales.refundSalesProportion" :precision="3"></vel-quantity>
                    </td>
                    <td class="text-right">
                      <vel-quantity :qty="l.sales.transactionsIndicators.voids"></vel-quantity>
                    </td>
                    <td class="text-right">
                      <vel-amount
                        :amount="l.sales.transactionsIndicators.voidsAmount"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                    <td class="text-right">
                      <vel-quantity :qty="l.sales.voidSalesProportion" :precision="3"></vel-quantity>
                    </td>

                    <td class="text-right">
                      <vel-quantity :qty="l.sales.transactionsIndicators.itemCorrections"></vel-quantity>
                    </td>
                    <td class="text-right">
                      <vel-amount
                        :amount="l.sales.transactionsIndicators.itemCorrectionsAmount"
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                  </tr>
                </template>

                <template v-if="isMultiDays">
                  <tr :class="{ total: expand.refunds[locationId] || !isMultiLocations }" :key="locationId">
                    <td
                      class="location"
                      style="cursor: pointer;"
                      :class="{ noBorder: expand.refunds[locationId] }"
                      @click="toggle(locationId, 'refunds')"
                      v-if="isMultiLocations || isMultiDays"
                    >
                      <span v-if="!expand.refunds[locationId] && isMultiLocations">
                        <vel-icon
                          style="height: 8px; position: relative; top: -2px; width: 8px;"
                          :name="expand.refunds[locationId] ? 'bottom-arrow' : 'right-arrow'"
                        ></vel-icon>
                        <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                          {{
                            !locationCount
                              ? $t('toolbar.selectors.consolidate') +
                                ' - ' +
                                $t('dashboard.locationSelector.allLocationsText')
                              : $t('dashboard.locationSelector.locationsSelected', {
                                  count: locationCount,
                                  total: locationsBySelectedGroup.length
                                })
                          }}
                        </span>
                        <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                      </span>
                    </td>

                    <td class="date"><strong>Total</strong></td>

                    <td class="text-right">
                      <strong>
                        <vel-quantity :qty="location.totalRefunds"></vel-quantity>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-amount
                          :amount="location.totalRefundsAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-quantity :qty="location.totalRefundsPercent" :prevision="3"></vel-quantity>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-quantity :qty="location.totalVoids"></vel-quantity>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-amount
                          :amount="location.totalVoidsAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-quantity :qty="location.totalVoidsPercent" :prevision="3"></vel-quantity>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-quantity :qty="location.totalItemCorrections"></vel-quantity>
                      </strong>
                    </td>
                    <td class="text-right">
                      <strong>
                        <vel-amount
                          :amount="location.totalItemCorrectionsAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </vel-card>
        <div class="card-container">
          <vel-card class="card-cash">
            <h5>{{ $t('audit.cash.title') }}</h5>
            <table>
              <thead>
                <tr>
                  <th style="width: 260px;">
                    {{ $t('audit.cash.table.headers.location') }}
                  </th>
                  <th style="width: 120px;">{{ $t('audit.cash.table.headers.date') }}</th>
                  <th style="width: 145px;" class="text-right">{{ $t('audit.cash.table.headers.cashDeposit') }}</th>
                  <th style="width: 145px;" class="text-right">{{ $t('audit.cash.table.headers.paidOuts') }}</th>
                  <th style="width: 145px;" class="text-right">{{ $t('audit.cash.table.headers.tips') }}</th>
                  <th style="width: 145px;" class="text-right">{{ $t('audit.cash.table.headers.total') }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(location, locationId) in Object.values(voids.data)
                    .filter(l => versionIsValid(l.location.id))
                    .sort((a, b) =>
                      (a.location.reportingDisplayName || a.location.name).localeCompare(
                        b.location.reportingDisplayName || b.location.name
                      )
                    )"
                >
                  <template v-for="(l, i) in location.dailySales">
                    <tr v-if="expand.cash[locationId] || !isMultiLocations || !isMultiDays" :key="locationId + i">
                      <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'cash')">
                        <span v-if="!i">
                          <vel-icon
                            v-if="isMultiDays && isMultiLocations"
                            style="height: 12px; width: 8px;"
                            name="bottom-arrow"
                          ></vel-icon>
                          <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                            {{
                              !locationCount
                                ? $t('toolbar.selectors.consolidate') +
                                  ' - ' +
                                  $t('dashboard.locationSelector.allLocationsText')
                                : $t('dashboard.locationSelector.locationsSelected', {
                                    count: locationCount,
                                    total: locationsBySelectedGroup.length
                                  })
                            }}
                          </span>
                          <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                        </span>
                      </td>
                      <td class="date">
                        <span>{{ l.date }}</span>
                      </td>
                      <td class="text-right">
                        <vel-amount
                          :amount="l.sales.indicators.cashDepositAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                      <td class="text-right">
                        <vel-amount
                          :amount="l.sales.transactionsIndicators.paidOutsAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                      <td class="text-right">
                        <vel-amount
                          :amount="l.sales.indicators.tipsTotalAmount"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                      <td class="text-right">
                        <vel-amount
                          :amount="
                            l.sales.indicators.cashDepositAmount +
                            l.sales.transactionsIndicators.paidOutsAmount +
                            l.sales.indicators.tipsTotalAmount
                          "
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                    </tr>
                  </template>

                  <template v-if="isMultiDays">
                    <tr :class="{ total: expand.cash[locationId] || !isMultiLocations }" :key="locationId">
                      <td
                        class="location"
                        style="cursor: pointer;"
                        :class="{ noBorder: expand.cash[locationId] }"
                        @click="toggle(locationId, 'cash')"
                        v-if="isMultiLocations || isMultiDays"
                      >
                        <span v-if="!expand.cash[locationId] && isMultiLocations">
                          <vel-icon
                            style="height: 8px; position: relative; top: -2px; width: 8px;"
                            :name="expand.cash[locationId] ? 'bottom-arrow' : 'right-arrow'"
                          ></vel-icon>
                          <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                            {{
                              !locationCount
                                ? $t('toolbar.selectors.consolidate') +
                                  ' - ' +
                                  $t('dashboard.locationSelector.allLocationsText')
                                : $t('dashboard.locationSelector.locationsSelected', {
                                    count: locationCount,
                                    total: locationsBySelectedGroup.length
                                  })
                            }}
                          </span>
                          <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                        </span>
                      </td>

                      <td class="date">
                        <strong>{{ $t('audit.cash.total') }}</strong>
                      </td>

                      <td class="text-right">
                        <strong>
                          <vel-amount
                            :amount="location.totalCashDepositsAmount"
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>
                      <td class="text-right">
                        <strong>
                          <vel-amount
                            :amount="location.totalPaidOutsAmount"
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>
                      <td class="text-right">
                        <strong>
                          <vel-amount
                            :amount="location.totalTipsTotalAmount"
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>
                      <td class="text-right">
                        <strong>
                          <vel-amount
                            :amount="
                              location.totalCashDepositsAmount +
                              location.totalPaidOutsAmount +
                              location.totalTipsTotalAmount
                            "
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </vel-card>
          <vel-card class="card-drawer">
            <h5>{{ $t('audit.drawer.title') }}</h5>
            <table>
              <thead>
                <tr>
                  <th style="width: 260px;">
                    {{ $t('audit.drawer.table.headers.location') }}
                  </th>
                  <th style="width: 120px;">{{ $t('audit.drawer.table.headers.date') }}</th>
                  <th style="width: 120px;" class="text-right">{{ $t('audit.drawer.table.headers.qty') }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(location, locationId) in Object.values(voids.data)
                    .filter(l => versionIsValid(l.location.id))
                    .sort((a, b) =>
                      (a.location.reportingDisplayName || a.location.name).localeCompare(
                        b.location.reportingDisplayName || b.location.name
                      )
                    )"
                >
                  <template v-for="(l, i) in location.dailySales">
                    <tr v-if="expand.drawer[locationId] || !isMultiLocations || !isMultiDays" :key="locationId + i">
                      <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'drawer')">
                        <span v-if="!i">
                          <vel-icon
                            v-if="isMultiDays && isMultiLocations"
                            style="height: 12px; width: 8px;"
                            name="bottom-arrow"
                          ></vel-icon>
                          <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                            {{
                              !locationCount
                                ? $t('toolbar.selectors.consolidate') +
                                  ' - ' +
                                  $t('dashboard.locationSelector.allLocationsText')
                                : $t('dashboard.locationSelector.locationsSelected', {
                                    count: locationCount,
                                    total: locationsBySelectedGroup.length
                                  })
                            }}
                          </span>
                          <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                        </span>
                      </td>
                      <td class="date">
                        <span>{{ l.date }}</span>
                      </td>
                      <td class="text-right">
                        <vel-quantity :qty="l.sales.transactionsIndicators.drawerOpenings"></vel-quantity>
                      </td>
                    </tr>
                  </template>

                  <template v-if="isMultiDays">
                    <tr :class="{ total: expand.drawer[locationId] || !isMultiLocations }" :key="locationId">
                      <td
                        class="location"
                        style="cursor: pointer;"
                        :class="{ noBorder: expand.drawer[locationId] }"
                        @click="toggle(locationId, 'drawer')"
                        v-if="isMultiLocations || isMultiDays"
                      >
                        <span v-if="!expand.drawer[locationId] && isMultiLocations">
                          <vel-icon
                            style="height: 8px; position: relative; top: -2px; width: 8px;"
                            :name="expand.drawer[locationId] ? 'bottom-arrow' : 'right-arrow'"
                          ></vel-icon>
                          <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                            {{
                              !locationCount
                                ? $t('toolbar.selectors.consolidate') +
                                  ' - ' +
                                  $t('dashboard.locationSelector.allLocationsText')
                                : $t('dashboard.locationSelector.locationsSelected', {
                                    count: locationCount,
                                    total: locationsBySelectedGroup.length
                                  })
                            }}
                          </span>
                          <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                        </span>
                      </td>

                      <td class="date">
                        <strong>{{ $t('audit.drawer.total') }}</strong>
                      </td>
                      <td class="text-right">
                        <strong>
                          <vel-quantity :qty="location.totalDrawerOpenings"></vel-quantity>
                        </strong>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </vel-card>
        </div>
        <vel-card>
          <h5>{{ $t('audit.workstations.title') }}</h5>
          <table>
            <thead>
              <tr>
                <th style="width: 260px;">
                  {{ $t('audit.workstations.table.headers.location') }}
                </th>
                <th style="width: 120px;">{{ $t('audit.workstations.table.headers.date') }}</th>
                <th
                  style="width: 260px;"
                  class="text-right"
                  v-for="workstation in Object.keys(voids.workstations)"
                  v-bind:key="workstation"
                >
                  {{ workstation }}
                </th>
                <th style="border: none;"></th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(location, locationId) in Object.values(voids.data)
                  .filter(l => versionIsValid(l.location.id))
                  .sort((a, b) =>
                    (a.location.reportingDisplayName || a.location.name).localeCompare(
                      b.location.reportingDisplayName || b.location.name
                    )
                  )"
              >
                <template v-for="(l, i) in location.dailySales">
                  <tr v-if="expand.stations[locationId] || !isMultiLocations || !isMultiDays" :key="locationId + i">
                    <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'stations')">
                      <span v-if="!i">
                        <vel-icon
                          v-if="isMultiDays && isMultiLocations"
                          style="height: 12px; width: 8px;"
                          name="bottom-arrow"
                        ></vel-icon>
                        <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                          {{
                            !locationCount
                              ? $t('toolbar.selectors.consolidate') +
                                ' - ' +
                                $t('dashboard.locationSelector.allLocationsText')
                              : $t('dashboard.locationSelector.locationsSelected', {
                                  count: locationCount,
                                  total: locationsBySelectedGroup.length
                                })
                          }}
                        </span>
                        <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                      </span>
                    </td>
                    <td class="date">
                      <span>{{ l.date }}</span>
                    </td>
                    <td
                      class="text-right"
                      v-for="workstation in Object.keys(voids.workstations)"
                      v-bind:key="workstation"
                    >
                      <vel-amount
                        :amount="
                          (l.sales.workstationSales[workstation] &&
                            l.sales.workstationSales[workstation].salesAmount) ||
                          0
                        "
                        :currency="location.location.detailedCurrency.currencySymbol"
                      ></vel-amount>
                    </td>
                  </tr>
                </template>

                <template v-if="isMultiDays">
                  <tr :class="{ total: expand.stations[locationId] || !isMultiLocations }" :key="locationId">
                    <td
                      class="location"
                      style="cursor: pointer;"
                      :class="{ noBorder: expand.stations[locationId] }"
                      @click="toggle(locationId, 'stations')"
                      v-if="isMultiLocations || isMultiDays"
                    >
                      <span v-if="!expand.stations[locationId] && isMultiLocations">
                        <vel-icon
                          style="height: 8px; position: relative; top: -2px; width: 8px;"
                          :name="expand.stations[locationId] ? 'bottom-arrow' : 'right-arrow'"
                        ></vel-icon>
                        <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                          {{
                            !locationCount
                              ? $t('toolbar.selectors.consolidate') +
                                ' - ' +
                                $t('dashboard.locationSelector.allLocationsText')
                              : $t('dashboard.locationSelector.locationsSelected', {
                                  count: locationCount,
                                  total: locationsBySelectedGroup.length
                                })
                          }}
                        </span>
                        <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                      </span>
                    </td>

                    <td class="date">
                      <strong>{{ $t('audit.workstations.total') }}</strong>
                    </td>

                    <td
                      class="text-right"
                      v-for="workstation in Object.keys(voids.workstations)"
                      v-bind:key="workstation"
                    >
                      <strong>
                        <vel-amount
                          :amount="location.totalWorkstations[workstation] || 0"
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </strong>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </vel-card>
        <vel-card>
          <h5>{{ $t('audit.tenderTypes.title') }}</h5>
          <table>
            <thead>
              <tr>
                <th rowspan="2" style="width: 260px;">{{ $t('audit.tenderTypes.table.headers.location') }}</th>
                <th rowspan="2" style="width: 120px;">{{ $t('audit.tenderTypes.table.headers.date') }}</th>
                <template v-for="tenderType in Object.keys(voids.tenderTypes)">
                  <th colspan="3" style="width: 360px;" class="text-center" v-bind:key="tenderType">
                    {{ tenderType }}
                  </th>
                </template>

                <th></th>
              </tr>
              <tr>
                <template v-for="tenderType in Object.keys(voids.tenderTypes)">
                  <th style="width: 120px;" class="text-right" v-bind:key="tenderType">
                    {{ $t('audit.refunds.table.headers.qty') }}
                  </th>
                  <th style="width: 120px;" class="text-right" v-bind:key="tenderType">
                    {{ $t('audit.refunds.table.headers.tips') }}
                  </th>
                  <th style="width: 120px; vertical-align: middle;" class="text-right" v-bind:key="tenderType">
                    {{ $t('audit.refunds.table.headers.amount') }}
                    <tooltip
                      :content="$t('audit.refunds.table.headers.amountTooltip')"
                      placement="top"
                      effect="light"
                      class="ml-1"
                    >
                      <vel-icon
                        style="fill: #2a314261; color: #2a314261;"
                        name="question-circle"
                        modifier="on-bright"
                        size="small"
                      />
                    </tooltip>
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(location, locationId) in Object.values(voids.data)
                  .filter(l => versionIsValid(l.location.id))
                  .sort((a, b) =>
                    (a.location.reportingDisplayName || a.location.name).localeCompare(
                      b.location.reportingDisplayName || b.location.name
                    )
                  )"
              >
                <template v-for="(l, i) in location.dailySales">
                  <tr v-if="expand.tenders[locationId] || !isMultiLocations || !isMultiDays" :key="locationId + i">
                    <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'tenders')">
                      <span v-if="!i">
                        <vel-icon
                          v-if="isMultiDays && isMultiLocations"
                          style="height: 12px; width: 8px;"
                          name="bottom-arrow"
                        ></vel-icon>
                        <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                          {{
                            !locationCount
                              ? $t('toolbar.selectors.consolidate') +
                                ' - ' +
                                $t('dashboard.locationSelector.allLocationsText')
                              : $t('dashboard.locationSelector.locationsSelected', {
                                  count: locationCount,
                                  total: locationsBySelectedGroup.length
                                })
                          }}
                        </span>
                        <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                      </span>
                    </td>
                    <td class="date">
                      <span>{{ l.date }}</span>
                    </td>
                    <template v-for="tenderType in Object.keys(voids.tenderTypes)">
                      <td class="text-right" v-bind:key="tenderType">
                        <vel-quantity
                          :qty="(l.sales.tenderTypeSales[tenderType] && l.sales.tenderTypeSales[tenderType].count) || 0"
                        ></vel-quantity>
                      </td>
                      <td class="text-right" v-bind:key="tenderType">
                        <vel-amount
                          :amount="
                            (l.sales.tenderTypeSales[tenderType] &&
                              l.sales.tenderTypeSales[tenderType].transferredTip) ||
                            0
                          "
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                      <td style="width: 120px;" class="text-right" v-bind:key="tenderType">
                        <vel-amount
                          :amount="
                            (l.sales.tenderTypeSales[tenderType] && l.sales.tenderTypeSales[tenderType].salesAmount) ||
                            0
                          "
                          :currency="location.location.detailedCurrency.currencySymbol"
                        ></vel-amount>
                      </td>
                    </template>
                  </tr>
                </template>
                <template v-if="isMultiDays">
                  <tr :class="{ total: expand.tenders[locationId] || !isMultiLocations }" :key="locationId">
                    <td
                      class="location"
                      style="cursor: pointer;"
                      :class="{ noBorder: expand.tenders[locationId] }"
                      @click="toggle(locationId, 'tenders')"
                      v-if="isMultiLocations || isMultiDays"
                    >
                      <span v-if="!expand.tenders[locationId] && isMultiLocations">
                        <vel-icon
                          style="height: 8px; position: relative; top: -2px; width: 8px;"
                          :name="expand.tenders[locationId] ? 'bottom-arrow' : 'right-arrow'"
                        ></vel-icon>
                        <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                          {{
                            !locationCount
                              ? $t('toolbar.selectors.consolidate') +
                                ' - ' +
                                $t('dashboard.locationSelector.allLocationsText')
                              : $t('dashboard.locationSelector.locationsSelected', {
                                  count: locationCount,
                                  total: locationsBySelectedGroup.length
                                })
                          }}
                        </span>
                        <span v-else>{{ location.location.reportingDisplayName || location.location.name }}</span>
                      </span>
                    </td>

                    <td class="date">
                      <strong>{{ $t('audit.tenderTypes.total') }}</strong>
                    </td>
                    <template v-for="tenderType in Object.keys(voids.tenderTypes)">
                      <td class="text-right" v-bind:key="tenderType">
                        <strong>
                          <vel-quantity :qty="location.totalTenderTypesQty[tenderType] || 0"></vel-quantity>
                        </strong>
                      </td>
                      <td class="text-right" v-bind:key="tenderType">
                        <strong>
                          <vel-amount
                            :amount="location.totalTenderTypesTips[tenderType] || 0"
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>
                      <td class="text-right" v-bind:key="tenderType">
                        <strong>
                          <vel-amount
                            :amount="location.totalTenderTypes[tenderType] || 0"
                            :currency="location.location.detailedCurrency.currencySymbol"
                          ></vel-amount>
                        </strong>
                      </td>
                    </template>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </vel-card>
      </template>
    </template>
  </vel-page>
</template>

<script>
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import GeneratedTime from '@/mixins/generated-time-mixin';
import { Tooltip } from 'element-ui';
import VelAmount from '@/components/amount/VelAmount';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelCheckbox from '@/components/checkbox/VelCheckbox';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import VelQuantity from '@/components/quantity/VelQuantity';
import VelTooltip from '@/components/tooltip/VelTooltip';
import VelSpinner from '@/components/spinner/VelSpinner';
import { environment } from '@/configs';
import formatQuantity from '@/filters/format-quantity';
import { formatVersion } from '@/helpers/versions.module.helper';
import { getToken } from '@/helpers/token.helper';
import router from '@/router';
import salesService from '@/services/sales.service';
import {getDateFormatFromUserConfig, today} from '@/helpers/date.helpers';
import { mapState as mapPiniaState } from 'pinia';
import {useUIGroupSelector} from "@/stores/ui/group-selector.module";
import {useUICurrencySelector} from "@/stores/ui/currency-selector.module";
import {useUIDateRangeSelector} from "@/stores/ui/date-range-selector.module";
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {mapActions as mapPiniaActions, storeToRefs} from "pinia/dist/pinia";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataSalesLocations} from "@/stores/data/sales/locations.module";
import {useDataSalesIndicators} from "@/stores/data/sales/indicators.module";
import {useDataLocationsVersionsStore} from "@/stores/data/locations/versions.module";
import {useDataConfig} from "@/stores/data/config.module";

export default {
  name: 'SummaryReportPage',
  components: {
    VelButton,
    VelPage,
    VelAmount,
    VelQuantity,
    VelIcon,
    VelCard,
    VelSpinner,
    VelCheckbox,
    VelTooltip,
    Tooltip
  },
  mixins: [GeneratedTime, DeviceMixin],
  data() {
    return {
      isConsolidated: localStorage.getItem('isConsolidated') === 'true',
      haveCashDiscounts: false,
      haveSurcharge: false,
      showLocationsList: false,
      updateIndicators: 0,
      updateCharts: 1,
      loading: true,
      generating: false,
      voids: { data: {}, workstations: {}, tenderTypes: {}, taxes: {} },
      expand: {
        sales: {},
        zones: {},
        gifts: {},
        tenders: {},
        cash: {},
        drawer: {},
        stations: {},
        refunds: {}
      },
      previousIndicators: {
        itemCorrectionsAmount: 0,
        voidsAmount: 0,
        refundsAmount: 101
      }
    };
  },
  setup() {
    const locationsSalesStore = useDataSalesLocations();

    const { getLocationsSales } = storeToRefs(locationsSalesStore);

    return { getSales: getLocationsSales, locationsSalesStore };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, { locationsList: 'locations' }),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useUILocationSelector, [
      'getLocationSelectorSelected',
      'locations',
      'isGenerated',
      'getLocationSelectorSelectedMultiple'
    ]),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities'
    }),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataSalesIndicators, [
      'getIndicatorsVoids',
      'getIndicatorsVoidsAmount',
      'getIndicatorsRefundsAmount',
      'getIndicatorsSalesVolume',
      'getIndicatorsRefunds',
      'getIndicatorsItemCorrections',
      'getIndicatorsItemCorrectionsAmount'
    ]),

    ...mapPiniaState(useDataLocationsStore, ['locationsBySelectedGroup', 'getLocationsIsLoading', 'getLocationsById']),
    ...mapPiniaState(useDataLocationsVersionsStore, {
      isVersionValid: 'isVersionValid',
      isVersionLoading: 'loading'
    }),
    isConsolidatable() {
      return this.isConsolidated && this.getCurrencySelectorSelected !== '' && (!this.locationCount || this.locationCount > 1)
    },
    isMultiLocations() {
      return !this.getLocationSelectorSelected;
    },
    isMultiDays() {
      return this.getDateSelectorSelectedRange[0] !== this.getDateSelectorSelectedRange[1];
    },
    chartAttrs() {
      return {
        locationId: this.getLocationSelectorSelected,
        currencyId: this.getCurrencySelectorSelected,
        from: this.getDateSelectorSelectedRange[0],
        to: this.getDateSelectorSelectedRange[1],
        limit: this.get.charts_size_limitation.value
      };
    },
    config() {
      return environment;
    },
    pageAttrs() {
      return {
        enableToolbar: true,
        enableCurrency: true,
        enableDateRange: true,
        title: this.$t('pages.reports.audit'),
        showCrumbs: false,
        failed: this.hasPageFailed,
        generatedTime: this.generatedTime,
        showDateCompare: this.showLocationsSales,
        loading: this.isPageLoading
      };
    },
    hasPageFailed() {
      return this.false;
    },
    isPageLoading() {
      return this.getLocationsIsLoading;
    },
    showLocationsSales() {
      return !this.getLocationSelectorSelected || this.locations.length === 1;
    },
    isAllInvalid() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) < 9620) {
        return true;
      }
      const locations = (this.getLocationSelectorSelectedMultiple || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      return locations.length === this.getLocationSelectorSelectedMultiple.length;
    },
    invalidLocations() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) >= 9620) {
        return false;
      }
      const locations = (this.getLocationSelectorSelectedMultiple || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      if (!locations.length) {
        return null;
      }
      return locations;
    },
    showNotification() {
      const isUpdated = this.getLocationSelectorSelected
        ? formatVersion(this.getLocationsById(this.getLocationSelectorSelected).version) >= 9620
        : !this.invalidLocations;
      const hasInvalidData = this.getLocationSelectorSelected
        ? !this.versionIsValid(this.getLocationSelectorSelected)
        : !!(this.getLocationSelectorSelectedMultiple || []).filter(x => !this.versionIsValid(x.value)).length;

      if (!this.getLocationSelectorSelected && !isUpdated) {
        const locations = (this.getLocationSelectorSelectedMultiple || [])
          .map(k => {
            const l = this.getLocationsById(k.value) || {};
            return { ...l, version: formatVersion(l.version) };
          })
          .filter(l => l.version >= 9620)
          .filter(l => !this.versionIsValid(l.id));

        if (locations.length) {
          return true;
        }
      }
      return isUpdated && hasInvalidData;
    }
  },
  watch: {
    async isGenerated(newVal) {
      if (newVal) {
        await this.fetchData(this.isConsolidatable);
      }
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataLocationsVersionsStore, ['getVersions']),
    async onConsolidate() {
      localStorage.setItem('isConsolidated', this.isConsolidated);
      await this.gen(false);
    },
    versionIsValid(locationId = null) {
      if (this.isConsolidatable) {
        return true;
      }
      // @todo refactor so that we no longer have to pass getters as arguments
      return (
        !this.isVersionLoading &&
        this.isVersionValid(
          '9.62.0',
          locationId ? locationId : this.getLocationSelectorSelected,
          locationId ? null : this.getGroupSelectorSelected,
          this.getDateSelectorSelectedRange[0]
        )
      );
    },
    autoUpdate() {
      this.updateIndicators += 2;
      this.updateCharts += 2;
    },
    toggle(id, type) {
      this.expand = { ...this.expand, [type]: { ...this.expand[type], [id]: !this.expand[type][id] } };
    },

    async exportXLS() {
      this.$ga.event('report', 'download', this.$route.name);
      this.generating = true;

      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;

      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO(),
        currency: this.getCurrencySelectorSelected
      };

      await salesService
        .downloadAuditSalesXlsx(getToken(), { ...routeQuery })
        .catch(() => {
          this.generating = false;
        });

      this.generating = false;
    },
    async fetchData(isConsolidated = false) {
      this.voids = { data: {}, workstations: {}, tenderTypes: {}, taxes: {} };
      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO(),
        currency: this.getCurrencySelectorSelected
      };
      this.loading = true;

      await (this.isConsolidatable && (!this.locationCount || this.locationCount > 1)
        ? salesService
            .getAuditSalesConsolidated(getToken(), {
              ...routeQuery
            })
            .then(res => {
              const locationTitle = !this.locationCount
                ? this.$t('dashboard.locationSelector.allLocationsText')
                : this.$t('dashboard.locationSelector.locationsSelected', {
                    count: this.locationCount,
                    total: this.locationsBySelectedGroup.length
                  });
              const results = [
                {
                  locationId: 'all',
                  location: {
                    locationId: 'all',
                    id: 'all',
                    currency: '$',
                    detailedCurrency: {
                      currencySymbol: res[0].sales.indicators[0].detailedCurrency.currencySymbol
                    },
                    reportingDisplayName: locationTitle,
                    name: locationTitle
                  },
                  dailySales: res.map(s => ({
                    ...s,
                    sales: {
                      ...s.sales,
                      indicators: (s.sales.indicators && s.sales.indicators[0]) || {},
                      transactionsIndicators:
                        (s.sales.transactionsIndicators && s.sales.transactionsIndicators[0]) || {}
                    }
                  }))
                }
              ];

              results[0].dailySales.map((x, a) => {
                const groupedTenderTypes = {};

                x.sales.tenderTypeSales.forEach((t, b) => {
                  if (!groupedTenderTypes[t.nameMain]) {
                    groupedTenderTypes[t.nameMain] = t;
                  } else {
                    groupedTenderTypes[t.nameMain].salesAmount += t.salesAmount;
                    groupedTenderTypes[t.nameMain].count += t.count;
                    groupedTenderTypes[t.nameMain].transferredTip += t.transferredTip;
                  }
                });

                results[0].dailySales[a].sales.tenderTypeSales = Object.values(groupedTenderTypes);
              });

              return results;
            })
        : salesService.getAuditSales(getToken(), {
            ...routeQuery
          })
      )
        .then(ld => ld)
        .then(ld => {
          (ld || []).forEach(d => {
            const locationId = d.locationId;
            let totalNetSales = 0;
            let totalDiscounts = 0;
            let totalCashDiscounts = 0;
            let totalSurcharge = 0;
            let totalGrossSales = 0;
            let totalTransaction = 0;
            let totalDrawerOpenings = 0;
            let totalGiftCardLoadsAmount = 0;
            let totalLoyaltiesAmount = 0;
            let totalCashDepositsAmount = 0;
            let totalPaidOutsAmount = 0;
            let totalTipsTotalAmount = 0;
            let totalVoids = 0;
            let totalVoidsAmount = 0;
            let totalVoidsPercent = 0;
            let totalItemCorrections = 0;
            let totalItemCorrectionsAmount = 0;
            let totalRefunds = 0;
            let totalRefundsAmount = 0;
            let totalRefundsPercent = 0;
            const totalWorkstations = {};
            const totalTenderTypes = {};
            const totalTenderTypesQty = {};
            const totalTenderTypesTips = {};
            const totalTaxes = {};
            const totalZones = {};

            this.voids = {
              ...this.voids,
              workstations: d.dailySales.reduce(
                (a, b) => {
                  b.sales.workstationSales.forEach(w => {
                    a[w.name] = w;
                  });
                  return a;
                },
                { ...this.voids.workstations }
              ),
              tenderTypes: d.dailySales.reduce(
                (a, b) => {
                  b.sales.tenderTypeSales
                    .sort((a, b) => a.nameMain.localeCompare(b.nameMain))
                    .forEach(w => {
                      a[w.nameMain] = w;
                    });
                  return a;
                },
                { ...this.voids.tenderTypes }
              ),
              taxes: d.dailySales.reduce(
                (a, b) => {
                  b.sales.taxesSales.forEach(w => {
                    const identifier =
                      this.locationCount === 1
                        ? w.name
                        : this.$t('audit.sales.table.headers.taxe', { number: w.remoteId + 1 });
                    a[identifier] = w;
                  });
                  return a;
                },
                { ...this.voids.taxes }
              ),
              zones: d.dailySales.reduce(
                (a, b) => {
                  b.sales.zoneSales
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .forEach(w => {
                      a[w.name] = w;
                    });
                  return a;
                },
                { ...this.voids.taxes }
              ),
              data: {
                ...this.voids.data,
                [locationId]: {
                  ...d,
                  totalNetSales: 0,
                  totalDiscounts: 0,
                  totalCashDiscounts: 0,
                  totalSurcharge: 0,
                  totalGrossSales: 0,
                  totalTransaction: 0,
                  totalGiftCardLoadsAmount: 0,
                  totalLoyaltiesAmount: 0,
                  totalDrawerOpenings: 0,
                  totalCashDepositsAmount: 0,
                  totalPaidOutsAmount: 0,
                  totalTipsTotalAmount: 0,
                  totalVoids: 0,
                  totalVoidsAmount: 0,
                  totalVoidsPercent: 0,
                  totalItemCorrections: 0,
                  totalItemCorrectionsAmount: 0,
                  totalRefunds: 0,
                  totalRefundsAmount: 0,
                  totalRefundsPercent: 0,
                  totalWorkstations: {},
                  totalTenderTypesQty: {},
                  totalTenderTypesTips: {},
                  totalTenderTypes: {},
                  totalTaxes: {},
                  totalZones: {},

                  dailySales: d.dailySales.map(ds => {
                    const newDs = {
                      date: DateTime.fromISO(ds.day, { setZone: true }).endOf('day').toFormat(getDateFormatFromUserConfig()),
                      ...ds,
                      sales: {
                        ...ds.sales,
                        workstationSales: ds.sales.workstationSales.reduce((a, b) => {
                          a[b.name] = b;
                          totalWorkstations[b.name] = (totalWorkstations[b.name] || 0) + b.salesAmount;
                          return a;
                        }, {}),
                        tenderTypeSales: ds.sales.tenderTypeSales.reduce((a, b) => {
                          a[b.nameMain] = b;
                          totalTenderTypes[b.nameMain] = (totalTenderTypes[b.nameMain] || 0) + b.salesAmount;
                          totalTenderTypesTips[b.nameMain] = (totalTenderTypesTips[b.nameMain] || 0) + b.transferredTip;
                          totalTenderTypesQty[b.nameMain] = (totalTenderTypesQty[b.nameMain] || 0) + b.count;
                          return a;
                        }, {}),
                        taxesSales: ds.sales.taxesSales.reduce((a, b) => {
                          const identifier =
                            this.locationCount === 1
                              ? b.name
                              : this.$t('audit.sales.table.headers.taxe', { number: b.remoteId + 1 });
                          a[identifier] = b;
                          totalTaxes[identifier] = (totalTaxes[identifier] || 0) + b.taxesAmount;
                          return a;
                        }, {}),
                        zoneSales: ds.sales.zoneSales.reduce((a, b) => {
                          const identifier = b.name;
                          a[identifier] = b;
                          totalZones[identifier] = {
                            customers:
                              ((totalZones[identifier] && totalZones[identifier].customers) || 0) + b.customers,
                            salesAmount:
                              ((totalZones[identifier] && totalZones[identifier].salesAmount) || 0) + b.salesAmount
                          };
                          return a;
                        }, {})
                      }
                    };

                    totalNetSales += newDs.sales.indicators.netSalesAmount;
                    totalDiscounts += newDs.sales.indicators.discountsAmount;
                    totalCashDiscounts += newDs.sales.indicators.cashDiscountAmount;
                    totalSurcharge += newDs.sales.indicators.surchargeAmount;
                    totalGrossSales += newDs.sales.indicators.grossSalesAmount;
                    totalTransaction += newDs.sales.indicators.customers;
                    totalGiftCardLoadsAmount += newDs.sales.transactionsIndicators.giftCardLoadsAmount;
                    totalDrawerOpenings += newDs.sales.transactionsIndicators.drawerOpenings;
                    totalLoyaltiesAmount += newDs.sales.transactionsIndicators.loyaltiesAmount;
                    totalCashDepositsAmount += newDs.sales.indicators.cashDepositAmount;
                    totalPaidOutsAmount += newDs.sales.transactionsIndicators.paidOutsAmount;
                    totalTipsTotalAmount += newDs.sales.indicators.tipsTotalAmount;
                    totalVoids += newDs.sales.transactionsIndicators.voids;
                    totalVoidsAmount += newDs.sales.transactionsIndicators.voidsAmount;
                    totalItemCorrections += newDs.sales.transactionsIndicators.itemCorrections;
                    totalItemCorrectionsAmount += newDs.sales.transactionsIndicators.itemCorrectionsAmount;
                    totalRefunds += newDs.sales.transactionsIndicators.refunds;
                    totalRefundsAmount += newDs.sales.transactionsIndicators.refundsAmount;
                    totalRefundsPercent += newDs.sales.refundSalesProportion;
                    totalVoidsPercent += newDs.sales.voidSalesProportion;
                    return newDs;
                  })
                }
              }
            };
            this.voids.data[locationId].totalNetSales = totalNetSales;
            this.voids.data[locationId].totalDiscounts = totalDiscounts;
            this.voids.data[locationId].totalCashDiscounts = totalCashDiscounts;
            this.voids.data[locationId].totalSurcharge = totalSurcharge;
            this.voids.data[locationId].totalGrossSales = totalGrossSales;
            this.voids.data[locationId].totalTransaction = totalTransaction;
            this.voids.data[locationId].totalGiftCardLoadsAmount = totalGiftCardLoadsAmount;
            this.voids.data[locationId].totalDrawerOpenings = totalDrawerOpenings;
            this.voids.data[locationId].totalLoyaltiesAmount = totalLoyaltiesAmount;
            this.voids.data[locationId].totalCashDepositsAmount = totalCashDepositsAmount;
            this.voids.data[locationId].totalPaidOutsAmount = totalPaidOutsAmount;
            this.voids.data[locationId].totalTipsTotalAmount = totalTipsTotalAmount;
            this.voids.data[locationId].totalVoids = totalVoids;
            this.voids.data[locationId].totalVoidsAmount = totalVoidsAmount;
            this.voids.data[locationId].totalVoidsPercent = totalVoidsPercent;
            this.voids.data[locationId].totalRefunds = totalRefunds;
            this.voids.data[locationId].totalRefundsAmount = totalRefundsAmount;
            this.voids.data[locationId].totalRefundsPercent = totalRefundsPercent;
            this.voids.data[locationId].totalItemCorrections = totalItemCorrections;
            this.voids.data[locationId].totalItemCorrectionsAmount = totalItemCorrectionsAmount;
            this.voids.data[locationId].totalWorkstations = totalWorkstations;
            this.voids.data[locationId].totalTenderTypes = totalTenderTypes;
            this.voids.data[locationId].totalTenderTypesQty = totalTenderTypesQty;
            this.voids.data[locationId].totalTenderTypesTips = totalTenderTypesTips;
            this.voids.data[locationId].totalTaxes = totalTaxes;
            this.voids.data[locationId].totalZones = totalZones;

            if (!this.haveCashDiscounts && totalCashDiscounts > 0) {
              this.haveCashDiscounts = true;
            }

            if (!this.haveSurcharge && totalSurcharge > 0) {
              this.haveSurcharge = true;
            }
          });
        });

      this.loading = false;
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  async mounted() {
    await this.getVersions();
    await this.fetchData();
    // this.generateTimeAt('data/sales/indicators/getIndicatorsSuccess');
  }
};
</script>
<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .vel-card__body {
  overflow: auto;
}

table {
  border-spacing: 0;
  margin-top: 1rem;
  table-layout: fixed;
  width: 100%;
}

tr {
  border-left: 1px solid #e5e5e5;
}

/* stylelint-disable */
th {
  border: 1px solid #f6f6f6;
  font-weight: bold;
  overflow: hidden;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

td {
  border: 1px solid #f6f6f6;
  overflow: hidden;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  word-break: keep-all;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.arrow-right {
  /* Vector */
  background: #000;
  bottom: 25%;
  left: 35.79%;
  position: absolute;
  right: 33.33%;
  top: 25%;
}

.date {
  width: 120px;
}

.time {
  max-width: 100px;
}

.itemName {
  width: 360px;
}

.price {
  width: 120px;
}

.quantity {
  width: 90px;
}

.check {
  width: 120px;
}

.categoryName {
  width: 300px;
}

.employee {
  width: 300px;
}

.authorized {
  width: 300px;
}

.location {
  border: none;
  width: 200px;
}

.total td {
  background-color: #efefef;
}

.subtotal td {
  background-color: #f6f6f6;
  border-bottom: 1px solid #c0c0c0;

  &.location {
  }
}

.order-header {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;

  .sort {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .order {
      height: 9px;
      width: 9px;

      &.active {
        color: #00a9e1;
        fill: #00a9e1;
      }
    }
  }
}

.multiple {
  cursor: pointer;
}

.subtotal + tr .location span {
  display: none;
}

.empty {
  border-top: 1px solid #fff;
}

tr {
  border: none;
}

.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

strong {
  font-weight: 700;
}

td {
  font-size: 0.9375rem;
}

.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

.no-data {
  font-size: 2rem;
  color: #4f4f4f;
  text-align: center;
  margin: 2rem;
}

.card-container {
  display: flex;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.card-cash {
  min-width: 100%;

  @include breakpoint(medium) {
    min-width: 1005px;
  }
}

.card-drawer {
  min-width: 100%;

  @include breakpoint(medium) {
    min-width: 200px;
    margin-left: 1rem;
  }
}

.card-takeout {
  min-width: 100%;

  @include breakpoint(medium) {
    min-width: 940px;
  }
}

.card-gift {
  min-width: 100%;

  @include breakpoint(medium) {
    min-width: 200px;
    margin-left: 1rem;
  }
}

th:empty,
td:empty {
  border: none;
}
</style>
