<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="bigDepartmentForm-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarBigDepartmentForm"
    v-model="isSidebarVisible"
    :no-close-on-backdrop="$refs.form && $refs.form.haveChanges"
    :no-close-on-esc="$refs.form && $refs.form.haveChanges"
    @hidden="onHide"
  >
    <template #header>
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center py-2">
        {{
          $t(bigDepartment && bigDepartment.id ? 'bigDepartments.form.edit.title' : 'bigDepartments.form.create.title')
        }}
      </h6>
    </template>
    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <b-button
          :disabled="!($refs.form && $refs.form.enableSaveChanges)"
          variant="primary"
          @click="updateBigDepartment()"
        >
          {{
            $t(bigDepartment && bigDepartment.id ? 'bigDepartments.form.edit.save' : 'bigDepartments.form.create.save')
          }}
        </b-button>
        <b-button variant="outline-primary" @click="onCancel(hide)">
          {{ $t('bigDepartments.form.cancel') }}
        </b-button>
      </div>
    </template>
    <b-container style="background-color: #f4f4f4;" fluid class="py-4 px-0" v-loading="isLoading">
      <big-department-form
        :licenceNumber="licenceNumber"
        :bigDepartment.sync="bigDepartment"
        ref="form"
      ></big-department-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import departmentService from '@/services/departments.service';
import BigDepartmentForm from '@/private/components/big-departments/BigDepartmentForm';
import { bus } from '@/bus';
import {mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataDepartments} from "@/stores/data/departments.module";

export default {
  name: 'big-department-sidebar-form-big-department',
  components: {
    BigDepartmentForm
  },
  props: {
    bigDepartment: {
      type: Object,
      default: () => ({
        id: null,
        name: ''
      })
    },
    licenceNumber: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSidebarVisible: false
    };
  },
  computed: {
    ...mapPiniaState(useDataDepartments, {
      isLoading: 'isLoading'
    })
  },
  methods: {
    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    onCancel(hide) {
      if (this.$refs.form.haveChanges) {
        this.$emit('onCancel', null);
      } else {
        hide();
      }
    },
    onHide() {
      this.bigDepartment = {};
      this.$refs.form.reset();
      this.$emit('update:bigDepartment', null);
      this.$forceUpdate();
      return true;
    },
    async updateBigDepartment() {
      try {
        this.$emit('update:pending', true);
        if (this.bigDepartment.id) {
          await departmentService.editDepartment({
            ...this.bigDepartment,
            alternativeName:
              this.bigDepartment.alternativeName === '' ? this.bigDepartment.name : this.bigDepartment.alternativeName
          });
          this.toast('toast.update');
        } else {
          await departmentService.createDepartment({
            ...this.bigDepartment,
            alternativeName: this.bigDepartment.name,
            licenceNumber: this.licenceNumber,
            isActive: true
          });
          this.toast('toast.bigDepartmentCreate', this.bigDepartment);
        }

        this.$emit('onSuccess');
        this.$emit('update:pending', false);
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.bigDepartment);
        console.error(e);
      }
    }
  },
  mounted() {
    bus.$on('document:click', evt => {
      if (evt.target.className.includes('b-sidebar-backdrop') && this.$refs.form.haveChanges) {
        this.$emit('onCancel', null);
      }
    });
  },
  destroyed() {
    bus.$off('document:click');
  }
};
</script>
<style scoped lang="scss">
/deep/ .b-sidebar-header {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

/deep/ .b-sidebar-body {
  background-color: #f4f4f4;
}
</style>
