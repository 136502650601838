<template>
  <div class="config_dashboard_display">
    <div v-for="opt in options" :key="opt.text">
      <vel-divider content-position="left">{{ opt.title }}</vel-divider>
      <div v-for="el in opt.elements" :key="el.text">
        <vel-checkbox
          v-model="config[el.model]"
          v-if="isAllowed(el.model)"
          :label="el.text"
          @change="update"
        ></vel-checkbox>
      </div>
    </div>
  </div>
</template>
<script>
import VelCheckbox from '@/components/checkbox/VelCheckbox';
import VelDivider from '@/components/divider/VelDivider';
import { environment } from '@/configs';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataConfig} from "@/stores/data/config.module";

export default {
  name: 'config-employee-report-display',
  components: {
    VelCheckbox,
    VelDivider
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    config: {
      get() {
        return this.get;
      },
      set() {
        return true;
      }
    },
    options() {
      return [
        {
          title: this.$t('editors.groups.employees_report_table'),
          elements: [
            {
              model: 'employees_column_quantity',
              text: this.$t('editors.elements.employees_column_quantity')
            },
            {
              model: 'employees_column_sales_percent_overall',
              text: this.$t('editors.elements.employees_column_sales_percent_overall')
            },
            {
              model: 'employees_column_sales_percent',
              text: this.$t('editors.elements.employees_column_sales_percent')
            },
            {
              model: 'employees_column_sales_amount',
              text: this.$t('editors.elements.employees_column_sales_amount')
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapPiniaActions(useDataConfig, ['patchConfig']),
    update() {
      this.$emit('update', this.config);
      this.patchConfig({ config: this.config });
    },
    isAllowed(value) {
      return environment[value] !== false;
    }
  }
};
</script>

<style lang="scss">
@import 'mixins.scss';
@import 'constants.scss';

.config_dashboard_display {
  display: block;

  .el-checkbox {
    display: block;
  }

  .el-divider__text {
    background-color: $wild-sand;
    padding: 0 10px 0 0;

    &.is-left {
      left: 0;
    }
  }
}
</style>
