var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-sales-report", {
    staticClass: "item-sales-report w-100",
    staticStyle: { width: "100%" },
    attrs: {
      displayedColumns: _vm.displayedColumns,
      data: _vm.formatedTree,
      areSalesLoading: _vm.areSalesLoading,
      filterable: true,
      "external-export": true,
      "is-external-generating": _vm.generating
    },
    on: { exportXls: _vm.exportXLSX, input: _vm.handleInput }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }