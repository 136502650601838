var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page bootstrap",
      attrs: {
        enableToolbar: false,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        scroll: false
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "d-flex flex-column flex-md-row pt-3" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("b-breadcrumb", {
                            attrs: { items: _vm.breadcrumbs }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isXLargeUp
                    ? _c(
                        "b-row",
                        { staticClass: "mb-4 mt-4" },
                        [
                          _c("b-col", [
                            _c("h4", [
                              _vm._v(_vm._s(_vm.$t("taxCategoriesList.title")))
                            ])
                          ]),
                          _c(
                            "b-col",
                            { staticClass: "pb-4", attrs: { cols: "2" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "search" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    attrs: {
                                      debounce: "1",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "taxCategoriesList.search"
                                      )
                                    },
                                    model: {
                                      value: _vm.tableFilter,
                                      callback: function($$v) {
                                        _vm.tableFilter = $$v
                                      },
                                      expression: "tableFilter"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "w-fit-content text-right",
                              attrs: { cols: "2" }
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleDrawer(true, "creation")
                                    }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    staticStyle: {
                                      "text-transform": "uppercase"
                                    },
                                    attrs: {
                                      icon: "plus",
                                      "aria-hidden": "true"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("taxCategoriesList.add-new")
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "b-row",
                        { staticClass: "mb-4 mt-4" },
                        [
                          _c("b-col", [
                            _c("h4", [
                              _vm._v(_vm._s(_vm.$t("taxCategoriesList.title")))
                            ])
                          ]),
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleDrawer(true, "creation")
                                    }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    staticStyle: {
                                      "text-transform": "uppercase"
                                    },
                                    attrs: {
                                      icon: "plus",
                                      "aria-hidden": "true"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("taxCategoriesList.add-new")
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "pt-4", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "search" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    attrs: {
                                      debounce: "1",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "taxCategoriesList.search"
                                      )
                                    },
                                    model: {
                                      value: _vm.tableFilter,
                                      callback: function($$v) {
                                        _vm.tableFilter = $$v
                                      },
                                      expression: "tableFilter"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-container",
        { staticClass: "h-100", attrs: { fluid: "" } },
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              striped: "",
              small: "",
              hover: "",
              bordered: "",
              "show-empty": "",
              fixed: "",
              "primary-key": "id",
              "empty-text": _vm.$t("defaults.empty"),
              "empty-filtered-text": _vm.$t("defaults.empty"),
              busy: _vm.isLoading,
              fields: _vm.fields,
              "head-variant": "info",
              items: _vm.getTaxCategoriesRows,
              responsive: "sm",
              "sort-by": "name",
              "sticky-header": ""
            },
            scopedSlots: _vm._u([
              {
                key: "empty",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "p-2" }, [
                      _vm._v(_vm._s(scope.emptyText))
                    ])
                  ]
                }
              },
              {
                key: "emptyfiltered",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "p-2" }, [
                      _vm._v(_vm._s(scope.emptyFilteredText))
                    ])
                  ]
                }
              },
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle p-2 m-4",
                          attrs: { variant: "primary" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(name)",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 justify-content-between d-flex align-items-center"
                      },
                      [_vm._v(" " + _vm._s(scope.item.name) + " ")]
                    )
                  ]
                }
              },
              {
                key: "cell(edit)",
                fn: function(scope) {
                  return [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "link" },
                        on: {
                          click: function($event) {
                            return _vm.toggleDrawer(true, "edition", scope.item)
                          }
                        }
                      },
                      [
                        _c("vel-icon", {
                          staticClass: "row-action text-primary p-0",
                          attrs: { size: "small", name: "xd-edit" }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("taxCategoriesList.table.edit")) +
                            " "
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(more)",
                fn: function(scope) {
                  return [
                    _c("tax-category-more", {
                      attrs: { id: scope.item.id },
                      on: {
                        delete: function($event) {
                          return _vm.handleDeleteModal(scope.item)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "overlay", class: { toggled: _vm.isDrawerToggled } },
        [
          _vm.creationMode
            ? _c(
                "div",
                {
                  staticClass: "drawer",
                  class: { toggled: _vm.isDrawerToggled }
                },
                [
                  _c("div", { staticClass: "header" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("taxCategoriesList.drawer.creationMode.header")
                      )
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "body" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-for": "name",
                            label: _vm.$t(
                              "taxCategoriesList.drawer.creationMode.form.name.label"
                            ),
                            description: _vm.$t(
                              "taxCategoriesList.drawer.creationMode.form.name.required"
                            )
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "name",
                              placeholder: _vm.$t(
                                "taxCategoriesList.drawer.creationMode.form.name.placeholder"
                              ),
                              required: "",
                              maxlength: "50",
                              type: "text",
                              size: "md"
                            },
                            model: {
                              value: _vm.createForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.createForm, "name", $$v)
                              },
                              expression: "createForm.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "footer" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: !_vm.createForm.name.length,
                            variant: "primary"
                          },
                          on: { click: _vm.handleSave }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("defaults.actions.save")) + " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-secondary" },
                          on: { click: _vm.handleDiscardChanges }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("defaults.actions.cancel")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm.editionMode
            ? _c(
                "div",
                {
                  staticClass: "drawer",
                  class: { toggled: _vm.isDrawerToggled }
                },
                [
                  _c("div", { staticClass: "header" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("taxCategoriesList.drawer.editionMode.header")
                      )
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "body" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-for": "name",
                            label: _vm.$t(
                              "taxCategoriesList.drawer.editionMode.form.name.label"
                            ),
                            description: _vm.$t(
                              "taxCategoriesList.drawer.editionMode.form.name.required"
                            )
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "name",
                              placeholder: _vm.$t(
                                "taxCategoriesList.drawer.editionMode.form.name.placeholder"
                              ),
                              required: "",
                              maxlength: "50",
                              type: "text",
                              size: "md"
                            },
                            model: {
                              value: _vm.editForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.editForm, "name", $$v)
                              },
                              expression: "editForm.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "footer" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              !_vm.editForm.name.length ||
                              _vm.editForm.name === _vm.selected.name,
                            variant: "primary"
                          },
                          on: {
                            click: function($event) {
                              _vm.showModal.save = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("defaults.actions.save")) + " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-secondary" },
                          on: {
                            click: function($event) {
                              _vm.editForm.name !== _vm.selected.name
                                ? (_vm.showModal.cancel = true)
                                : _vm.toggleDrawer(false)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("defaults.actions.cancel")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm.success && _vm.creationMode
        ? _c(
            "vel-alert",
            {
              staticClass: "alert",
              staticStyle: { "font-size": "1.5rem" },
              attrs: { level: "success" },
              on: { dismiss: _vm.handleClearMessage }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("taxCategoriesList.success.created")) + " "
              )
            ]
          )
        : _vm.success && _vm.editionMode
        ? _c(
            "vel-alert",
            {
              staticClass: "alert",
              staticStyle: { "font-size": "1.5rem" },
              attrs: { level: "success" },
              on: { dismiss: _vm.handleClearMessage }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("taxCategoriesList.success.edited")) + " "
              )
            ]
          )
        : _vm.success && _vm.deleteMode
        ? _c(
            "vel-alert",
            {
              staticClass: "alert",
              staticStyle: { "font-size": "1.5rem" },
              attrs: { level: "success" },
              on: { dismiss: _vm.handleClearMessage }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("taxCategoriesList.success.deleted")) + " "
              )
            ]
          )
        : _vm._e(),
      _vm.showModal.save
        ? _c("vel-modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("taxCategoriesList.modal.save.header")
                          ) +
                          " "
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("b-row", { staticClass: "mb-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("taxCategoriesList.modal.save.body")
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: {
                                border: "none",
                                "margin-right": "15px"
                              },
                              attrs: { variant: "outline-primary" },
                              on: { click: _vm.handleDiscardChanges }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "taxCategoriesList.modal.save.actions.discard"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: { click: _vm.handleEditSave }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "taxCategoriesList.modal.save.actions.save"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2409103713
            )
          })
        : _vm._e(),
      _vm.showModal.cancel
        ? _c("vel-modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("taxCategoriesList.modal.cancel.header")
                          ) +
                          " "
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("b-row", { staticClass: "mb-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("taxCategoriesList.modal.cancel.body")
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: {
                                border: "none",
                                "margin-right": "15px"
                              },
                              attrs: { variant: "outline-primary" },
                              on: { click: _vm.handleDiscardChanges }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "taxCategoriesList.modal.cancel.actions.discard"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showModal.cancel = false
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "taxCategoriesList.modal.cancel.actions.continue"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2076021659
            )
          })
        : _vm._e(),
      _vm.showModal.delete
        ? _c("vel-modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("taxCategoriesList.modal.delete.header")
                          ) +
                          " "
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("b-row", { staticClass: "mb-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("taxCategoriesList.modal.delete.body", {
                                name: _vm.selected.name
                              })
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: {
                                border: "none",
                                "margin-right": "15px"
                              },
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function($event) {
                                  _vm.showModal.delete = false
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "taxCategoriesList.modal.delete.actions.discard"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: { click: _vm.handleDelete }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "taxCategoriesList.modal.delete.actions.delete"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2448987322
            )
          })
        : _vm._e(),
      _c("tax-category-delete-error-modal", {
        attrs: {
          title: _vm.$t("taxCategoriesList.modal.errorDelete.header"),
          body: _vm.$t("taxCategoriesList.modal.errorDelete.body", {
            name: _vm.selected.name,
            count: _vm.linkedTaxes.length
          }),
          currentTaxCategory: _vm.selected,
          locationName: _vm.locationName,
          fileName: _vm.fileName,
          fileIdentifier: _vm.fileIdentifier,
          list: _vm.linkedTaxes,
          show: !!_vm.error
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }