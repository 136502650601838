var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-password" },
    [
      _c(
        "div",
        { staticClass: "logo-wrapper" },
        [_c("logo", { staticClass: "login-logo" })],
        1
      ),
      _c("h3", {
        staticClass: "reset-password-title",
        domProps: { innerHTML: _vm._s(_vm.$t("resetPassword.title")) }
      }),
      _c(
        "vel-form",
        {
          ref: "resetPasswordForm",
          attrs: { model: _vm.formInline, rules: _vm.rules }
        },
        [
          _c(
            "vel-form-item",
            {
              attrs: {
                prop: "newPassword",
                label: _vm.$t("resetPassword.form.newPassword.placeholder")
              }
            },
            [
              _c("vel-input", {
                staticClass: "new-password-input",
                attrs: { autofocus: "", type: "password" },
                nativeOn: {
                  keyup: function($event) {
                    return _vm.userStartedTyping.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.formInline.newPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.formInline, "newPassword", $$v)
                  },
                  expression: "formInline.newPassword"
                }
              })
            ],
            1
          ),
          _c(
            "vel-form-item",
            {
              attrs: {
                prop: "confirmPassword",
                label: _vm.$t("resetPassword.form.confirmPassword.placeholder")
              }
            },
            [
              _c("vel-input", {
                staticClass: "confirm-password-input",
                attrs: { type: "password" },
                nativeOn: {
                  keyup: function($event) {
                    return _vm.userStartedTypingConfirmation.apply(
                      null,
                      arguments
                    )
                  }
                },
                model: {
                  value: _vm.formInline.confirmPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.formInline, "confirmPassword", $$v)
                  },
                  expression: "formInline.confirmPassword"
                }
              })
            ],
            1
          ),
          _vm.formInline.errors.length
            ? _c("Alert", { staticClass: "error", attrs: { type: "error" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("resetPassword.form.differentPasswords.text")
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.error
            ? _c("Alert", { staticClass: "error", attrs: { type: "error" } }, [
                _vm._v(_vm._s(_vm.$t("resetPassword.form.resetError.text")))
              ])
            : _vm._e(),
          _c(
            "vel-form-item",
            [
              _c(
                "vel-button",
                {
                  staticClass: "submit-btn",
                  attrs: { type: "primary", loading: _vm.status.saving },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("resetPassword.form.submitButton.text")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }