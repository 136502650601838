import { render, staticRenderFns } from "./VelMultipleSelector.vue?vue&type=template&id=11b57f4b&scoped=true&"
import script from "./VelMultipleSelector.vue?vue&type=script&lang=js&"
export * from "./VelMultipleSelector.vue?vue&type=script&lang=js&"
import style0 from "./VelMultipleSelector.vue?vue&type=style&index=0&id=11b57f4b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b57f4b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/runner/work/velweb/velweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11b57f4b')) {
      api.createRecord('11b57f4b', component.options)
    } else {
      api.reload('11b57f4b', component.options)
    }
    module.hot.accept("./VelMultipleSelector.vue?vue&type=template&id=11b57f4b&scoped=true&", function () {
      api.rerender('11b57f4b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/list/VelMultipleSelector.vue"
export default component.exports