<template>
  <div class="group-edit-modal">
    <vel-modal dismissable @dismiss="handleCancel">
      <template v-slot:header>
        {{ $t('groups.modals.edit.title') }}
      </template>

      <template v-slot:default>
        <vel-alert @dismiss="clearUpdateGroupError" class="alert" level="error" v-if="hasUpdateGroupError">
          {{ $t('groups.errors.updateGroup') }}
        </vel-alert>

        <group-form
          class="group-edit-modal__form"
          :group="group"
          @submit="handleSubmit"
          @cancel="handleCancel"
        ></group-form>
      </template>
    </vel-modal>
  </div>
</template>

<script>
import GroupForm from '../group-form/GroupForm.vue';
import VelAlert from '@/components/alert/VelAlert';
import VelModal from '@/components/modal/VelModal';
import groupKind from '@/models/groups/group-kind';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataGroups} from "@/stores/data/groups.module";

export default {
  name: 'edit-group-modal',
  components: {
    GroupForm,
    VelModal,
    VelAlert
  },
  props: {
    group: {
      type: Object,
      default: () => ({
        name: null,
        kind: null
      })
    }
  },
  data() {
    return {
      currentGroup: { ...this.group }
    };
  },
  methods: {
    ...mapPiniaActions(useDataGroups, [
      'clearLatestCreatedGroup',
      'clearLatestDeletedGroup',
      'clearLatestUpdatedGroup',
      'updateGroup',
      'clearCreateGroupError',
      'clearDeleteGroupError',
      'clearUpdateGroupError'
    ]),
    handleCancel() {
      this.$emit('dismiss');
    },
    handleSubmit(group) {
      this.currentGroup = { ...group };
      this.currentGroup.kind = groupKind[group.kind];
      this.updateGroup(this.currentGroup);
    }
  },
  watch: {
    latestUpdatedGroup(updatedGroup) {
      if (updatedGroup && this.currentGroup.name === updatedGroup.name) {
        this.$emit('dismiss');
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataGroups, ['hasUpdateGroupError', 'latestUpdatedGroup'])
  },
  created() {
    this.clearLatestCreatedGroup();
    this.clearLatestDeletedGroup();
    this.clearLatestUpdatedGroup();
    this.clearCreateGroupError();
    this.clearDeleteGroupError();
    this.clearUpdateGroupError();
  }
};
</script>
<style lang="scss" scoped>
/deep/ .group-form__location .el-select {
  width: 100%;
}
</style>
