var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-modal", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [_vm._v(" " + _vm._s(_vm.title) + " ")]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [_c("p", [_vm._v(_vm._s(_vm.message))])]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "vel-button",
              {
                staticClass: "btn-login",
                attrs: { type: "critical" },
                on: { click: _vm.accept }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("msgboxConfirm.button.accept.text")) + " "
                )
              ]
            ),
            _c(
              "vel-button",
              {
                staticClass: "btn-login",
                attrs: { type: "secondary" },
                on: { click: _vm.cancel }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("msgboxConfirm.button.cancel.text")) + " "
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }