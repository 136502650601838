var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("indicator-quantity-amount", {
    attrs: {
      loading: _vm.loading,
      previousAmount: _vm.previousAmount,
      quantity: _vm.quantity,
      amount: _vm.amount,
      percent: _vm.percent,
      currency: _vm.currency
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(" " + _vm._s(_vm.$t("dashboard.refunds.title")) + " ")]
        },
        proxy: true
      },
      {
        key: "value",
        fn: function() {
          return [
            _c("vel-amount", {
              attrs: { currency: _vm.currency, amount: _vm.amount }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }