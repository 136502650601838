var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.title,
            static: true,
            centered: true,
            "hide-header-close": true,
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          on: {
            hidden: function($event) {
              return _vm.$emit("onCancel", true)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c("b-container", { attrs: { fluid: "" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right ml-2",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("onDelete", true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t(_vm.yesActionLabel)) + " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                _vm.showDeleteModal = false
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t(_vm.noActionLabel)) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showDeleteModal,
            callback: function($$v) {
              _vm.showDeleteModal = $$v
            },
            expression: "showDeleteModal"
          }
        },
        [_vm._v(" " + _vm._s(_vm.body) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }