import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import {createCollectionState, getSelectors, removeAll, setAll, setOne} from '@/helpers/stores.helpers';
import router from "@/router";
import mixMatchService from "@/services/mix-match.service";

const { selectAll, selectById, selectAllFiltered, selectSelected, selectTotal } = getSelectors();

export const useDataMixMatch = defineStore('mixMatch', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null,
      filter: '',
      status: {}
    }),
  getters: {
    getItemsMixMatchRows: state => selectAllFiltered(['name'])(state),
    selectAll,
    selectTotal,
    selectById,
    selectSelected,
    success: state => !!state.status.success,
    mode: state => state.status.mode,
    name: state => state.status.name
  },
  actions: {
    async getItemsMixMatch(payload = {}) {
      const routeQuery = {
        ...router.currentRoute.query,
        payload
      };
      try {
        this.getItemsMixMatchRequest();
        const itemsMixMatch = await mixMatchService.getItemsMixMatch(getToken(), routeQuery);
        this.getItemsMixMatchSuccess(itemsMixMatch);
      } catch (error) {
        this.getItemsMixMatchFailure(error);
      }
    },
    async createItemMixMatch(payload) {
      try {
        this.createItemMixMatchRequest();
        const itemMixMatch = await mixMatchService.createMixMatch(getToken(), payload);
        this.createItemMixMatchSuccess(itemMixMatch);
      } catch (error) {
        this.createItemMixMatchFailure(error);
      }
    },
    async getItemMixMatchById(id = '') {
      try {
        this.getItemMixMatchByIdRequest();
        const itemMixMatch = await mixMatchService.getItemMixMatchById(getToken(), id);
        this.getItemMixMatchByIdSuccess(itemMixMatch);
      } catch (error) {
        this.getItemMixMatchByIdFailure(error);
      }
    },
    async editItemMixMatch(body) {
      try {
        this.editItemMixMatchRequest();
        const itemMixMatch = await mixMatchService.editItemMixMatch(getToken(), body);
        this.editItemMixMatchSuccess(itemMixMatch);
      } catch (error) {
        this.editItemMixMatchFailure(error);
      }
    },
    async deleteItemMixMatch(id) {
      try {
        this.deleteItemMixMatchRequest();
        const itemMixMatch = await mixMatchService.deleteItemMixMatch(getToken(), id);
        this.deleteItemMixMatchSuccess(itemMixMatch);
      } catch (error) {
        this.deleteItemMixMatchFailure( error);
      }
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    setSelected(value) {
      this.setSelectedValue(value);
    },
    clearSuccessMessage() {
      this.clearMessage();
    },
    getItemsMixMatchRequest() {
      this.loading = true;
      this.error = null;
    },
    getItemsMixMatchSuccess( itemsMixMatch) {
      this.data = setAll(itemsMixMatch.content, this.data);
      this.error = null;
      this.loading = false;
    },
    getItemsMixMatchFailure( error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    createItemMixMatchRequest() {
      this.loading = true;
      this.error = null;
    },
    createItemMixMatchSuccess(itemMixMatch) {
      this.data = setOne(itemMixMatch, this.data);
      this.status = { success: true, mode: 'create', name: itemMixMatch.name };
      this.loading = false;
    },
    createItemMixMatchFailure( error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    getItemMixMatchByIdRequest() {
      this.loading = true;
      this.error = null;
    },
    getItemMixMatchByIdSuccess(itemMixMatch) {
      this.data = setOne(itemMixMatch, this.data);
      this.error = null;
      this.loading = false;
    },
    getItemMixMatchByIdFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    editItemMixMatchRequest() {
      this.loading = true;
      this.error = null;
    },
    editItemMixMatchSuccess(itemMixMatch) {
      this.data = setOne(itemMixMatch, this.data);
      this.status = { success: true, mode: 'edit', name: itemMixMatch.name };
      this.error = null;
      this.loading = false;
    },
    editItemMixMatchFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    deleteItemMixMatchRequest() {
      this.loading = true;
      this.error = null;
    },
    deleteItemMixMatchSuccess(itemMixMatch) {
      this.data = setOne(itemMixMatch, this.data);
      this.status = { success: true, mode: 'delete', name: itemMixMatch.name };
      this.error = null;
      this.loading = false;
    },
    deleteItemMixMatchFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    setSelectedValue(value) {
      this.data.selected = value;
    },
    clearMessage() {
      this.status.success = null;
    }
  }
});
