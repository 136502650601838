var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "location",
      scopedSlots: _vm._u(
        [
          _vm.location
            ? {
                key: "title",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.location.name) + " ")]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.isUserAdmin && _vm.location
        ? _c("location-edit-container", {
            attrs: {
              countries: _vm.countries,
              currencies: _vm.currencies,
              location: _vm.location
            }
          })
        : _vm._e(),
      _c("vel-entity-information-card", {
        attrs: {
          isLoading: _vm.isLoadingLocation,
          entity: Object.assign(
            {},
            _vm.location,

            {
              currency:
                (_vm.location &&
                  _vm.location.detailedCurrency &&
                  _vm.location.detailedCurrency.currencyCode +
                    " (" +
                    _vm.location.detailedCurrency.currencySymbol +
                    ")") ||
                "",
              countryCode:
                (_vm.location &&
                  _vm.location.countryCode &&
                  _vm.$t("countries." + _vm.location.countryCode)) ||
                "",
              areaCode:
                (_vm.location &&
                  _vm.location.areaCode &&
                  _vm.$t(
                    "areas." +
                      _vm.location.countryCode +
                      "." +
                      _vm.location.areaCode
                  )) ||
                ""
            }
          ),
          properties: [
            "streetAddress",
            "city",
            "areaCode",
            "countryCode",
            "postalCode",
            "licenceNumber",
            "reseller",
            "currency",
            "version"
          ],
          avatar: "name",
          translationPrefix: "location.info"
        }
      }),
      _c(
        "div",
        { staticClass: "location__alerts" },
        [
          _vm.getLocationPermissionsError
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "error" },
                  on: { dismiss: _vm.clearGetLocationPermissionsError }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("location.errors.getLocationPermissionsError")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.getLocationGroupsError
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "error" },
                  on: { dismiss: _vm.clearGetLocationGroupsError }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("location.errors.getLocationGroupsError")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.latestAddedLocationToGroup
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "success" },
                  on: { dismiss: _vm.clearLatestAddedLocationToGroup }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("location.groups.form.success.addGroups")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.latestDeletedLocationFromGroup
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "success" },
                  on: { dismiss: _vm.clearLatestDeletedLocationFromGroup }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("location.groups.form.success.deleteGroup")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isUserAdmin
        ? _c(
            "vel-card",
            {
              staticClass: "location__users",
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "location__users-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("location.permissions.title")) +
                              " "
                          ),
                          _c(
                            "div",
                            { staticClass: "permissions__list-length" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.locationPermissions &&
                                      _vm.locationPermissions.length
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2672619366
              )
            },
            [
              !_vm.isLoadingLocationPermissions
                ? _c("div", { staticClass: "permissions" }, [
                    _c(
                      "div",
                      { staticClass: "permissions__list" },
                      [
                        _vm.hasPermissions
                          ? _c(
                              "div",
                              [
                                _c("permissions-list", {
                                  staticClass: "list__table",
                                  attrs: {
                                    translationPrefix: "location",
                                    permissions: _vm.locationPermissions
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "vel-alert",
                              {
                                staticClass: "list__none",
                                attrs: { dismissable: false, level: "notice" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("location.permissions.none")
                                    ) +
                                    " "
                                )
                              ]
                            )
                      ],
                      1
                    )
                  ])
                : _c("vel-spinner")
            ],
            1
          )
        : _vm._e(),
      _vm.isUserAdmin
        ? _c(
            "vel-card",
            {
              staticClass: "location__groups",
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "location__groups-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("location.groups.title")) + " "
                          ),
                          _c(
                            "div",
                            { staticClass: "permissions__list-length" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.groups && _vm.groups.length) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                4030471580
              )
            },
            [
              !_vm.areGroupsLoading
                ? _c("div", { staticClass: "groups" }, [
                    _c(
                      "div",
                      { staticClass: "groups__actions" },
                      [
                        _c(
                          "vel-button",
                          {
                            staticClass: "actions__add",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleGroupAdd()
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("location.groups.actions.add")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "groups__list" },
                      [
                        _vm.hasGroups
                          ? _c(
                              "div",
                              [
                                _c("groups-list", {
                                  staticClass: "list__table",
                                  attrs: {
                                    groupTitle: _vm.$t("location.groups.title"),
                                    groups: _vm.locationGroups
                                  },
                                  on: {
                                    goToGroup: _vm.handleGoToGroup,
                                    delete: _vm.handleGroupDelete
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "vel-alert",
                              {
                                staticClass: "list__none",
                                attrs: { dismissable: false, level: "notice" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("location.groups.none")) +
                                    " "
                                )
                              ]
                            )
                      ],
                      1
                    )
                  ])
                : _c("vel-spinner"),
              _c(
                "div",
                { staticClass: "group__modals" },
                [
                  _vm.showAddGroup
                    ? _c("add-group-modal", {
                        staticClass: "modals__add",
                        attrs: {
                          locationId: _vm.locationId,
                          groupsOptions: _vm.selectableGroups
                        },
                        on: {
                          dismiss: _vm.handleAddModalDismiss,
                          success: _vm.handleAddModalDismiss
                        }
                      })
                    : _vm._e(),
                  _vm.showDeleteGroup && !!_vm.deletedGroup
                    ? _c("delete-group-modal", {
                        staticClass: "modals__delete",
                        attrs: {
                          locationId: _vm.locationId,
                          group: _vm.deletedGroup
                        },
                        on: {
                          dismiss: _vm.handleDeleteModalDismiss,
                          success: _vm.handleDeleteModalDismiss
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }