var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("indicator", {
    attrs: {
      color: "blue",
      amount: _vm.amount,
      "previous-amount": _vm.previousAmount,
      loading: _vm.loading,
      iconName: "cash-deposit",
      iconColor: "pink"
    },
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function() {
            return [
              _vm._v(
                " " + _vm._s(_vm.$t("dashboard.paidInsAmount.title")) + " "
              )
            ]
          },
          proxy: true
        },
        {
          key: "tooltip",
          fn: function() {
            return [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("dashboard.paidInsAmount.description")) +
                  " "
              )
            ]
          },
          proxy: true
        },
        _vm.versionIsValid
          ? {
              key: "value",
              fn: function() {
                return [
                  _c("vel-amount", {
                    attrs: { currency: _vm.currency, amount: _vm.amount }
                  })
                ]
              },
              proxy: true
            }
          : !_vm.loading
          ? {
              key: "value",
              fn: function() {
                return [
                  _vm.isMulti
                    ? _c(
                        "tooltip",
                        {
                          attrs: {
                            placement: "top",
                            effect: "light",
                            content: _vm.$t(
                              "dashboard.tipsTotalAmount.versionError.multiple"
                            )
                          }
                        },
                        [
                          _c("vel-icon", {
                            attrs: {
                              name: "exclamation-thick",
                              modifier: "on-bright",
                              size: "medium"
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "tooltip",
                        {
                          attrs: {
                            placement: "top",
                            effect: "light",
                            content: _vm.$t(
                              "dashboard.tipsTotalAmount.versionError.single"
                            )
                          }
                        },
                        [
                          _c("vel-icon", {
                            attrs: { name: "exclamation-thick", size: "medium" }
                          })
                        ],
                        1
                      )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }