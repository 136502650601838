var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "public-reset-password" },
    [
      _c(
        "div",
        { staticClass: "logo-wrapper" },
        [_c("logo", { staticClass: "login-logo" })],
        1
      ),
      _c("h3", {
        staticClass: "public-reset-password-title",
        domProps: { innerHTML: _vm._s(_vm.$t("publicResetPassword.title")) }
      }),
      _c(
        "vel-form",
        {
          ref: "publicResetPassword",
          attrs: { model: _vm.formInline, rules: _vm.rules },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "vel-form-item",
            {
              staticClass: "form-group-email",
              attrs: {
                prop: "email",
                label: _vm.$t("publicResetPassword.form.email.label")
              }
            },
            [
              _c(
                "vel-input",
                {
                  staticClass: "email-input",
                  attrs: { type: "email", autofocus: "" },
                  model: {
                    value: _vm.formInline.email,
                    callback: function($$v) {
                      _vm.$set(_vm.formInline, "email", $$v)
                    },
                    expression: "formInline.email"
                  }
                },
                [
                  _c("vel-icon", {
                    attrs: {
                      slot: "prepend",
                      name: "email-login",
                      size: "small"
                    },
                    slot: "prepend"
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.error
            ? _c(
                "vel-alert",
                { staticClass: "alert", attrs: { level: "error" } },
                [_vm._v(_vm._s(_vm.$t("publicResetPassword.form.error.text")))]
              )
            : _vm._e(),
          _c(
            "vel-form-item",
            { staticClass: "public-reset-password-row" },
            [
              _c(
                "vel-button",
                {
                  staticClass: "link-btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("login")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("publicResetPassword.form.loginPageLink.text")
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "vel-button",
                {
                  staticClass: "submit-btn",
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleSubmit()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("publicResetPassword.form.submitButton.text")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.formInline.submitted
        ? _c(
            "vel-alert",
            {
              staticClass: "alert",
              attrs: { autoDismissTime: 60000, level: "success" },
              on: { dismiss: _vm.dismissAlert }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("publicResetPassword.modal.success.text")) +
                  " "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }