var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page bootstrap",
      class: { page__edit: _vm.mode === "edit", noScroll: !_vm.scroll }
    },
    [
      _vm.failed
        ? _c("vel-page-reloader", { staticClass: "vel-page__reloader" })
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "vel-page__loader" },
            [_c("vel-page-spinner", { staticClass: "vel-page__spinner" })],
            1
          )
        : _vm._e(),
      _vm.$slots.header && !_vm.loading
        ? _c("header", [_vm._t("header")], 2)
        : _vm._e(),
      _c("section", { staticClass: "vel-page", class: _vm.classes }, [
        !_vm.loading
          ? _c(
              "div",
              {
                ref: "page",
                staticClass: "vel-page__content",
                on: {
                  "&scroll": function($event) {
                    return _vm.handleDebouncedScroll.apply(null, arguments)
                  }
                }
              },
              [
                _vm.$slots.alert
                  ? _c(
                      "div",
                      { staticClass: "vel-page__alert" },
                      [_vm._t("alert")],
                      2
                    )
                  : _vm._e(),
                !_vm.showSelectorButton || _vm.isGenerated
                  ? _vm._t("default")
                  : _vm._e(),
                !_vm.showSelectorButton || _vm.isGenerated
                  ? _c("div", { staticClass: "footer" }, [
                      _vm.generatedTime
                        ? _c(
                            "div",
                            { staticClass: "vel-page__generated-time" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("defaults.page.generatedTime", {
                                      date: _vm.dateFormat(_vm.generatedTime)
                                    })
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ]),
      _vm.$slots.footer && !_vm.loading
        ? _c("footer", { staticClass: "px-0 py-2" }, [_vm._t("footer")], 2)
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }