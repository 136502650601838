import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import salesService from '@/services/sales.service';
import {deallocateRequest, requestAllocated} from "@/helpers/service.helper";
import router from "@/router";



export const useDataSalesDepartments = defineStore('salesDepartments', {
  state: () => ({
    departmentSales: null,
    pending: true,
    success: null,
    error: null
  }),
  getters: {
    isPending: state => state.pending,
    getDepartmentSales: state => state.departmentSales,
    getDepartmentSalesByCurrency(state) {
      return currencyId =>
        (this.getDepartmentSales || [])
          .filter(zs => zs.salesAmount > 0)
          .sort((a, b) => b.salesAmount - a.salesAmount)
    },
    isError: state => !!state.error,
    getError: state => state.error,
    isSuccess: state => state.success,
    getSeries(state) {
      return currencyId => this.getDepartmentSalesByCurrency(currencyId).map(zs => zs.salesAmount);
    },
    getLabels(state) {
      return currencyId => this.getDepartmentSalesByCurrency(currencyId).map(zs => zs.name);
    },
    getTotal(state) {
      return currencyId => this.getDepartmentSalesByCurrency(currencyId).length;
    }
  },
  actions: {
    async getSalesByDepartment(payload) {
      const toDay = today().startOf('day').toString();
      const fromDate = router.currentRoute.query.from || toDay;
      const toDate = router.currentRoute.query.to || fromDate || toDay;

      const routeQuery = {
        currency: '$',
        ...router.currentRoute.query,
        from: fromDate,
        to: toDate,
        ...payload
      };

      if (!requestAllocated(routeQuery)) {
        this.getDepartmentSalesRequest();

        try {
          const departmentSales = await salesService.getDepartmentSales(getToken(), routeQuery);
          this.getDepartmentSalesSuccess({ departmentSales });
          deallocateRequest(routeQuery);
        } catch (error) {
          this.getDepartmentSalesFailure(error);
        }
      }
    },
    getDepartmentSalesRequest() {
      this.pending = true;
      this.success = null;
      this.departmentSales = null;
      this.error = null;
    },
    getDepartmentSalesSuccess({ departmentSales }) {
      this.pending = false;
      this.success = true;
      this.departmentSales = departmentSales;
      this.error = null;
    },
    getDepartmentSalesFailure(error) {
      this.pending = false;
      this.success = false;
      this.departmentSales = null;
      this.error = error;
    }
  }
});
