var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page bootstrap",
      attrs: {
        enableToolbar: false,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        scroll: false
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm._v(" " + _vm._s(_vm.$t("pages.location-configuration")) + " ")
            ]
          },
          proxy: true
        },
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "d-flex flex-column flex-md-row pt-3" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("b-breadcrumb", {
                            attrs: { items: _vm.breadcrumbs }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex justify-content-end align-items-center"
                        },
                        [
                          _c("span", { staticClass: "d-none d-md-flex pr-2" }, [
                            _vm._v(
                              _vm._s(_vm.$t("employee.list.locationsLabel")) +
                                ":"
                            )
                          ]),
                          _c("vel-location-selector", {
                            staticClass:
                              "toolbar__location-selector w-100 w-md-auto pt-2 pt-md-0",
                            attrs: {
                              allowGlobalLocationOption:
                                _vm.getLocationsAll.length > 1,
                              allLocationsLabel: _vm.$t(
                                "dashboard.locationSelector.allLocationsText"
                              ),
                              disabled: _vm.getLocationsIsLoading,
                              locationId: _vm.locationId,
                              locationsOptions: _vm.locationsBySelectedGroup,
                              showGlobalLocationOptionGroupLabel: false
                            },
                            on: { change: _vm.onLocationChange }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-between",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.$t("employee.list.title")))
                          ]),
                          _vm.showActive
                            ? _c(
                                "b-button",
                                {
                                  staticClass:
                                    "ml-4 d-flex align-items-center d-md-none",
                                  attrs: {
                                    variant: "primary",
                                    to: { name: "employee-create" }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "plus",
                                      "aria-hidden": "true",
                                      scale: "1.4"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-md-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("employee.list.buttons.new")
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex mt-4 mt-md-0 flex-row justify-content-md-end flex-md-row",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              staticClass: "align-content-end align-self-end",
                              staticStyle: { "max-width": "250px" }
                            },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "search" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  debounce: "1",
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "employee.list.searchPlaceholder"
                                  )
                                },
                                model: {
                                  value: _vm.filter,
                                  callback: function($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.showActive
                            ? _c(
                                "b-button",
                                {
                                  staticClass:
                                    "ml-4 d-md-flex align-items-center d-none",
                                  attrs: {
                                    variant: "primary",
                                    to: { name: "employee-create" }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "plus",
                                      "aria-hidden": "true",
                                      scale: "1.4"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("employee.list.buttons.new")
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "mb-2 mb-md-0",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c(
                            "b-button-group",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: _vm.showActive
                                      ? "primary"
                                      : "light",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setActive(true)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("employee.list.buttons.active")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: !_vm.showActive
                                      ? "primary"
                                      : "light",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setActive(false)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("employee.list.buttons.inactive")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", {
                        staticClass: "d-flex flex-row justify-content-end",
                        attrs: { cols: "12", md: "6" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c("b-container", { attrs: { fluid: "" } }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-content-end align-items-center"
                  },
                  [
                    _c(
                      "b-form-group",
                      { staticClass: "d-none d-md-block" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("employee.list.rowsPerPage")) +
                            " "
                        ),
                        _c("b-form-select", {
                          staticClass: "mx-3",
                          staticStyle: { width: "80px" },
                          attrs: { size: "sm", options: [10, 25, 50, 100] },
                          model: {
                            value: _vm.perPage,
                            callback: function($$v) {
                              _vm.perPage = $$v
                            },
                            expression: "perPage"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "d-none d-md-flex align-self-center mr-6"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.currentPage * _vm.perPage - _vm.perPage + 1
                            ) +
                            " - " +
                            _vm._s(
                              Math.min(
                                _vm.currentPage * _vm.perPage,
                                _vm.totalRows
                              )
                            ) +
                            " of " +
                            _vm._s(_vm.totalRows) +
                            " "
                        )
                      ]
                    ),
                    _c("b-pagination", {
                      staticClass: "m-0 ml-2",
                      attrs: {
                        size: "sm",
                        pills: "",
                        "total-rows": _vm.totalRows,
                        "per-page": _vm.perPage,
                        limit: 10,
                        align: "right"
                      },
                      on: { filtered: _vm.onFiltered },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-container",
        { staticClass: "h-100", attrs: { fluid: "", id: "table-container" } },
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              striped: "",
              bordered: "",
              hover: "",
              "show-empty": "",
              fixed: "",
              filter: _vm.filter,
              "primary-key": "id",
              "empty-text": _vm.$t("employee.empty"),
              "empty-filtered-text": _vm.$t("employee.empty"),
              "filter-included-fields": _vm.filterOn,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
              "filter-debounce": 800,
              busy: _vm.loading,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              fields: _vm.fields,
              "head-variant": "info",
              items: _vm.myProvider,
              responsive: "sm",
              "sticky-header": ""
            },
            on: {
              "update:sortBy": function($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function($event) {
                _vm.sortDesc = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "empty",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "p-2" }, [
                      _vm._v(_vm._s(scope.emptyText))
                    ])
                  ]
                }
              },
              {
                key: "emptyfiltered",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "p-2" }, [
                      _vm._v(_vm._s(scope.emptyFilteredText))
                    ])
                  ]
                }
              },
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle p-2 m-4",
                          attrs: { variant: "primary" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(name)",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "w-100 d-flex align-items-center bootstrap"
                      },
                      [
                        _vm._v(" " + _vm._s(scope.item.name) + " "),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: scope.item.id,
                              triggers: "hover",
                              placement: "top",
                              container: "table-container"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("employee.list.rehirableTooltip")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "bg-transparent",
                            attrs: { id: scope.item.id }
                          },
                          [
                            scope.item.terminated && !scope.item.rehireable
                              ? _c("b-icon", {
                                  staticClass: "ml-2 d-flex",
                                  attrs: {
                                    size: "sm",
                                    icon: "flag-fill",
                                    variant: "danger"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(edit)",
                fn: function(scope) {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "p-0",
                        attrs: {
                          variant: "link",
                          to: {
                            name: "employee-edit",
                            params: { id: scope.item.id }
                          }
                        }
                      },
                      [
                        _c("vel-icon", {
                          staticClass: "row-action text-primary",
                          attrs: { size: "small", name: "xd-edit" }
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("employee.list.table.edit")) + " "
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(more)",
                fn: function() {
                  return [
                    _c(
                      "b-button",
                      { staticClass: "p-0", attrs: { variant: "link" } },
                      [
                        _c("b-icon", {
                          staticClass: "row-action",
                          attrs: {
                            icon: "three-dots-vertical",
                            "aria-label": "More"
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(activate)",
                fn: function(scope) {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "p-0",
                        attrs: {
                          variant: "link",
                          to: {
                            name: "employee-edit",
                            params: { id: scope.item.id }
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("employee.list.buttons.reactivate")) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }