var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-card",
    { staticClass: "indicator_card", class: _vm.classes },
    [
      _vm.$slots.title
        ? _c(
            _vm.titleLevel,
            { tag: "component", staticClass: "indicator__title" },
            [_vm._t("title")],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "indicator" }, [
        _c("div", { staticClass: "indicator__content" }, [
          _c("span", { staticClass: "indicator__values" }, [
            _c("div", { staticClass: "quantity" }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.$t("indicatorQuantityAmount.labels.quantity"))
                )
              ]),
              _c("div", [_vm._v(_vm._s(_vm.formatQty(_vm.quantity)))])
            ]),
            _c(
              "div",
              { staticClass: "amount" },
              [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("indicatorQuantityAmount.labels.amount"))
                  )
                ]),
                _c("vel-amount", {
                  attrs: { currency: _vm.currency, amount: _vm.amount }
                })
              ],
              1
            )
          ]),
          _vm.previousAmount !== null && _vm.showPercent && _vm.quantity
            ? _c("span", { staticClass: "indicator__percent" })
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }