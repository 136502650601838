var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classObject, staticStyle: { width: "100%" } },
    [
      _vm.areSalesLoading
        ? _c("vel-spinner", { staticClass: "spinner" })
        : _vm._e(),
      !_vm.areSalesLoading
        ? _c("div", { staticClass: "vel-sales-report__action" }, [
            _vm.consolidated
              ? _c("div", { staticClass: "vel-action__consolidated" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("toolbar.selectors.consolidate")) +
                      " - " +
                      _vm._s(
                        !_vm.locationCount
                          ? _vm.$t(
                              "dashboard.locationSelector.allLocationsText"
                            )
                          : _vm.$t(
                              "dashboard.locationSelector.locationsSelected",
                              {
                                count: _vm.locationCount,
                                total: _vm.locationTotal
                              }
                            )
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "vel-action__filter" },
              [
                _vm.filterable
                  ? _c("vel-table-filter", {
                      model: {
                        value: _vm.inputFilter,
                        callback: function($$v) {
                          _vm.inputFilter = $$v
                        },
                        expression: "inputFilter"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vel-action__buttons" },
              [
                _vm.expandable
                  ? _c(
                      "vel-button",
                      {
                        staticClass: "vel-expand",
                        attrs: {
                          icon: "expand",
                          size: "small",
                          disabled: _vm.isBusy
                        },
                        on: { click: _vm.expandAll }
                      },
                      [
                        _vm.isLargeUp
                          ? _vm._t("default", function() {
                              return [
                                _vm._v(_vm._s(_vm.$t("salesReport.expandAll")))
                              ]
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.expandable
                  ? _c(
                      "vel-button",
                      {
                        staticClass: "vel-collapse",
                        attrs: {
                          icon: "collapse",
                          size: "small",
                          disabled: _vm.isBusy
                        },
                        on: { click: _vm.collapseAll }
                      },
                      [
                        _vm.isLargeUp
                          ? _vm._t("default", function() {
                              return [
                                _vm._v(
                                  _vm._s(_vm.$t("salesReport.collapseAll"))
                                )
                              ]
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _c("vel-table-export", {
                  attrs: {
                    columns: _vm.mutableExportableColumns,
                    rows: _vm.exportableRows,
                    metas: _vm.exportableMetas,
                    "external-export": _vm.externalExport,
                    "is-external-generating": _vm.isExternalGenerating
                  },
                  on: {
                    exportXls: function($event) {
                      return _vm.$emit("exportXls")
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "vel-sales-report__header" }, [
        _vm.displayedColumns[0]
          ? _c("div", [_vm._v(_vm._s(_vm.displayedColumns[0]))])
          : _vm._e(),
        _vm.displayedColumns[1]
          ? _c("div", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.displayedColumns[1]))
            ])
          : _vm._e(),
        _vm.displayedColumns[4]
          ? _c("div", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.displayedColumns[4]))
            ])
          : _vm._e(),
        _vm.displayedColumns[3]
          ? _c("div", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.displayedColumns[3]))
            ])
          : _vm._e(),
        _vm.displayedColumns[2]
          ? _c("div", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.displayedColumns[2]))
            ])
          : _vm._e(),
        _vm.displayedColumns[5]
          ? _c("div", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.displayedColumns[5]))
            ])
          : _vm._e()
      ]),
      !_vm.areSalesLoading
        ? _c(
            "div",
            { staticClass: "vel-sales-report__body" },
            [
              _c("vel-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.data,
                  "empty-text": _vm.$t("defaults.empty"),
                  props: _vm.defaultProps,
                  "render-content": _vm.renderContent,
                  "render-after-expand": false
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }