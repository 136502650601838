var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "changePasswordForm",
      staticClass: "change-password-form",
      attrs: {
        rules: _vm.formRules,
        model: _vm.changePasswordForm,
        submit: _vm.handleSubmit
      }
    },
    [
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "currentPassword",
            label: _vm.$t("changePassword.form.currentPassword.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "current-password-input",
            attrs: { autofocus: "", type: "password" },
            on: { focus: _vm.clearMessage },
            model: {
              value: _vm.changePasswordForm.currentPassword,
              callback: function($$v) {
                _vm.$set(_vm.changePasswordForm, "currentPassword", $$v)
              },
              expression: "changePasswordForm.currentPassword"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "newPassword",
            label: _vm.$t("changePassword.form.newPassword.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "new-password-input",
            attrs: { type: "password" },
            on: { focus: _vm.clearMessage },
            model: {
              value: _vm.changePasswordForm.newPassword,
              callback: function($$v) {
                _vm.$set(_vm.changePasswordForm, "newPassword", $$v)
              },
              expression: "changePasswordForm.newPassword"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "confirmPassword",
            label: _vm.$t("changePassword.form.confirmPassword.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "confirm-password-input",
            attrs: { type: "password" },
            on: { focus: _vm.clearMessage },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSubmit.apply(null, arguments)
              }
            },
            model: {
              value: _vm.changePasswordForm.confirmPassword,
              callback: function($$v) {
                _vm.$set(_vm.changePasswordForm, "confirmPassword", $$v)
              },
              expression: "changePasswordForm.confirmPassword"
            }
          })
        ],
        1
      ),
      _vm.error
        ? _c(
            "vel-alert",
            {
              staticClass: "vel-alert_error",
              on: { dismiss: _vm.clearMessage }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("changePassword.form.resetError.text")) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _c(
        "vel-form-item",
        [
          _c(
            "vel-button",
            {
              staticClass: "submit-btn",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("changePassword.form.submitButton.text")) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }