var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.status.loading,
          expression: "status.loading"
        }
      ],
      staticClass: "discounts-table"
    },
    [
      _c("h5", { staticClass: "discounts-table__title" }, [
        _vm._v(_vm._s(_vm.$t("discountsTable.title")))
      ]),
      _c(
        "div",
        {
          staticClass: "bootstrap",
          staticStyle: { width: "100%", overflow: "auto" }
        },
        [
          _c(
            "div",
            { staticStyle: { "min-width": "660px" } },
            [
              _c("b-table", {
                ref: "table",
                staticClass: "mb-0",
                attrs: {
                  "show-empty": !_vm.discountsData.length,
                  "primary-key": "id",
                  fields: _vm.fields,
                  "head-variant": "info",
                  items: _vm.discountsData.filter(function(x) {
                    return x.salesDiscountsOrigin !== "2.00"
                  }),
                  "sticky-header": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-colgroup",
                    fn: function(scope) {
                      return _vm._l(scope.fields, function(field) {
                        return _c("col", {
                          key: field.key,
                          class: "col_" + field.key
                        })
                      })
                    }
                  },
                  {
                    key: "cell(quantity)",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(scope.item.quantity))
                        ])
                      ]
                    }
                  },
                  {
                    key: "cell(discountsAmount)",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c("vel-amount", {
                              attrs: {
                                currency: scope.item.currency,
                                amount: scope.item.discountsAmount
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(grossSalesPercent)",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(scope.item.percentage) + " %")
                        ])
                      ]
                    }
                  },
                  {
                    key: "custom-foot",
                    fn: function() {
                      return [
                        _vm.discountsData.length > 0
                          ? _c(
                              "b-tr",
                              {
                                staticStyle: {
                                  "background-color": "#f5f7fa",
                                  "font-weight": "bold",
                                  "table-layout": "fixed"
                                }
                              },
                              [
                                _c("b-td", [_vm._v("Total")]),
                                _c("b-td", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(_vm.totalQuantity()))
                                ]),
                                _c(
                                  "b-td",
                                  { staticClass: "text-right" },
                                  [
                                    _c("vel-amount", {
                                      attrs: {
                                        currency:
                                          _vm.discountsData &&
                                          _vm.discountsData[0] &&
                                          _vm.discountsData[0].currency,
                                        amount: _vm.totalDiscount()
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("b-td", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(_vm.totalPercent()) + " %")
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _vm.haveCashDiscounts
                ? _c("b-table", {
                    ref: "table",
                    staticClass: "mb-0 cash_discount",
                    attrs: {
                      "empty-text": _vm.$t("discountsTable.empty"),
                      "primary-key": "id",
                      fields: _vm.fields,
                      "head-variant": "info",
                      items: _vm.discountsData.filter(function(x) {
                        return x.salesDiscountsOrigin === "2.00"
                      }),
                      "sticky-header": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "table-colgroup",
                          fn: function(scope) {
                            return _vm._l(scope.fields, function(field) {
                              return _c("col", {
                                key: field.key,
                                class: "col_" + field.key
                              })
                            })
                          }
                        },
                        {
                          key: "cell(quantity)",
                          fn: function(scope) {
                            return [
                              _c("div", { staticClass: "text-right" }, [
                                _vm._v(_vm._s(scope.item.quantity))
                              ])
                            ]
                          }
                        },
                        {
                          key: "cell(discountsAmount)",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c("vel-amount", {
                                    attrs: {
                                      currency: scope.item.currency,
                                      amount: scope.item.discountsAmount
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(grossSalesPercent)",
                          fn: function(scope) {
                            return [
                              _c("div", { staticClass: "text-right" }, [
                                _vm._v(_vm._s(scope.item.percentage) + " %")
                              ])
                            ]
                          }
                        },
                        {
                          key: "custom-foot",
                          fn: function() {
                            return [
                              _vm.discountsData.length > 0
                                ? _c(
                                    "b-tr",
                                    {
                                      staticStyle: {
                                        "background-color": "#f5f7fa",
                                        "font-weight": "bold",
                                        "table-layout": "fixed"
                                      }
                                    },
                                    [
                                      _c("b-td", [_vm._v("Total")]),
                                      _c(
                                        "b-td",
                                        { staticClass: "text-right" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.totalQuantity(true))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              currency:
                                                _vm.discountsData &&
                                                _vm.discountsData[0] &&
                                                _vm.discountsData[0].currency,
                                              amount: _vm.totalDiscount(true)
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-td",
                                        { staticClass: "text-right" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.totalPercent(true)) +
                                              " %"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2637355623
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }