<template>
  <vel-page class="page bootstrap" :show-crumbs="false" :loading="isLoading" :title="taxForm.name || '--'">
    <div class="tax-form-container bg-white">
      <vel-alert @dismiss="clearError" class="alert" level="error" v-if="hasTaxError">
        {{ $t(errorLabel) }}
      </vel-alert>

      <b-form ref="taxForm" class="tax-form" :model="taxForm" :submit="handleSubmit">
        <h5 class="pt-4 px-4 pb-2">{{ $t('taxForm.generalConfiguration') }}</h5>
        <b-container fluid class="content p-4">
          <div
            class="d-flex flex-column flex-md-row justify-content-between"
            :style="{ width: isMediumUp ? '75%' : '100%' }"
          >
            <b-form-group
              class="mr-5 w-100 mb-2 mb-md-0"
              label-for="description"
              label-size="sm"
              :label="$t('taxForm.form.description.label')"
              :description="$t('taxForm.form.description.required')"
              :invalid-feedback="$t('taxForm.form.description.error')"
              :state="taxForm.name === '' ? null : !$v.taxForm.name.$invalid"
            >
              <b-form-input
                id="description"
                :placeholder="$t('taxForm.form.description.placeholder')"
                v-model="taxForm.name"
                maxlength="50"
                type="text"
                size="md"
                :class="{ required: $v.taxForm.name.$invalid }"
                :state="computeState($v.taxForm.name.$invalid)"
              ></b-form-input>
            </b-form-group>
            <div class="mr-5 mb-2 mb-md-0" :style="{ width: isMediumUp ? '50%' : '100%' }">
              <b-form-checkbox
                id="includedTax"
                class="includedTax__checkbox ml-1 ml-md-0"
                v-model="taxForm.includedTax"
                name="includedTax"
                :style="{ marginTop: isMediumUp ? '2.2rem' : '0' }"
              >
                {{ $t('taxForm.form.includedTax.label') }}
              </b-form-checkbox>
            </div>
            <b-form-group
              label-for="taxCategory"
              label-size="sm"
              :label="$t('taxForm.form.taxCategory.label')"
              :description="$t('taxForm.form.taxCategory.required')"
              class="mr-5 w-100 ml-md-5 mb-2 mb-md-0"
            >
              <b-form-select v-model="taxForm.taxCategoryId">
                <b-form-select-option :value="null">
                  {{ $t('taxForm.form.taxCategory.emptySelection') }}
                </b-form-select-option>
                <b-form-select-option
                  v-for="taxCategory in taxCategories"
                  :key="taxCategory.id"
                  v-bind:value="taxCategory.id"
                >
                  {{ taxCategory.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group
              label-for="fiscalId"
              label-size="sm"
              :label="$t('taxForm.form.fiscalId.label')"
              :description="$t('taxForm.form.fiscalId.required')"
              :invalid-feedback="$t('taxForm.form.fiscalId.error')"
              :state="taxForm.fiscalIdentification === '' ? null : !$v.taxForm.fiscalIdentification.$invalid"
              class="mr-5 w-100 ml-md-5 mb-2 mb-md-0"
            >
              <b-form-input
                id="fiscalId"
                :placeholder="$t('taxForm.form.fiscalId.placeholder')"
                v-model="taxForm.fiscalIdentification"
                type="text"
                size="md"
                maxlength="10"
                :state="computeState($v.taxForm.fiscalIdentification.$invalid)"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('taxForm.taxRates') }}</h5>
        <b-container fluid class="content p-4">
          <div
            class="d-flex flex-column flex-md-row justify-content-between"
            :style="{ width: isMediumUp ? '75%' : '100%' }"
          >
            <b-form-group
              class="mr-5 w-100 mb-2 mb-md-0"
              label-for="taxRate"
              label-size="sm"
              :label="$t('taxForm.form.taxRate.label')"
              :description="$t('taxForm.form.taxRate.required')"
              :invalid-feedback="$t('taxForm.form.taxRate.error')"
              :state="taxForm.rate === null || taxForm.rate === '' ? null : !$v.taxForm.rate.$invalid"
            >
              <b-input-group prepend="%">
                <b-form-input
                  id="taxRate"
                  :placeholder="$t('taxForm.form.taxRate.placeholder')"
                  v-model.number="taxForm.rate"
                  :formatter="formatAmount"
                  lazy-formatter
                  size="md"
                  :class="{ required: $v.taxForm.rate.$invalid }"
                  :state="computeState($v.taxForm.rate.$invalid)"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <div class="mr-5 mb-2 mb-md-0" :style="{ width: isMediumUp ? '50%' : '100%' }">
              <b-form-checkbox
                id="scheduleRate"
                class="scheduleRate__checkbox ml-1 ml-md-0"
                v-model="taxForm.scheduleRate"
                name="scheduleRate"
                @change="toggleScheduleRate"
                :style="{ marginTop: isMediumUp ? '2.2rem' : '0' }"
              >
                {{ $t('taxForm.form.scheduleRate.label') }}
              </b-form-checkbox>
            </div>
            <b-form-group
              label-for="newTaxRate"
              label-size="sm"
              :label="$t('taxForm.form.newTaxRate.label')"
              :description="$t('taxForm.form.newTaxRate.required')"
              :invalid-feedback="$t('taxForm.form.newTaxRate.error')"
              :state="taxForm.newTaxRate === null || taxForm.newTaxRate === '' ? null : !$v.taxForm.newTaxRate.$invalid"
              :class="{ invisible: !taxForm.scheduleRate }"
              class="mr-5 w-100 ml-md-5 mb-2 mb-md-0"
            >
              <b-input-group prepend="%">
                <b-form-input
                  id="newTaxRate"
                  :placeholder="$t('taxForm.form.newTaxRate.placeholder')"
                  v-model="taxForm.newTaxRate"
                  size="md"
                  :formatter="formatAmount"
                  lazy-formatter
                  :state="computeState($v.taxForm.newTaxRate.$invalid)"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-for="rate"
              label-size="sm"
              :label="$t('taxForm.form.newTaxDate.label')"
              :description="$t('taxForm.form.newTaxDate.required')"
              :invalid-feedback="$t('taxForm.form.newTaxDate.error')"
              :state="taxForm.newTaxRateTime === null ? null : !$v.taxForm.newTaxRateTime.$invalid"
              :class="{ invisible: !taxForm.scheduleRate }"
              class="mr-5 w-100 ml-md-5 mb-2 mb-md-0"
            >
              <b-input-group class="datepicker">
                <b-form-input
                  id="scheduledDate"
                  size="md"
                  type="text"
                  :placeholder="$t('taxForm.form.newTaxDate.placeholder')"
                  :value="newTaxRateTime"
                  v-mask="'99/99/9999'"
                  autocomplete="off"
                  @change="setScheduledDate"
                  :state="computeState($v.taxForm.newTaxRateTime.$invalid)"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    button-only
                    size="md"
                    :locale="$i18n.locale"
                    right
                    button-variant="white"
                    :label-no-date-selected="$t('datePicker.noDate')"
                    :label-calendar="$t('datePicker.calendar')"
                    :label-close-button="$t('datePicker.close')"
                    :label-current-month="$t('datePicker.currentMonth')"
                    :label-help="$t('datePicker.help')"
                    :label-nav="$t('datePicker.nav')"
                    :label-next-decade="$t('datePicker.nextDecade')"
                    :label-next-month="$t('datePicker.nextMonth')"
                    :label-next-year="$t('datePicker.nextYear')"
                    :label-prev-decade="$t('datePicker.previousDecade')"
                    :label-prev-month="$t('datePicker.previousMonth')"
                    :label-prev-year="$t('datePicker.previousYear')"
                    :label-reset-button="$t('datePicker.reset')"
                    :label-today="$t('datePicker.today')"
                    :label-today-button="$t('datePicker.selectToday')"
                    :min="new Date()"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    aria-controls="scheduledDate"
                    v-model="taxForm.newTaxRateTime"
                    @context="onSelectScheduledDate"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('taxForm.taxApplicability') }}</h5>
        <b-container fluid class="content p-4">
          <div
            class="d-flex flex-column flex-md-row justify-content-between"
            :style="{ width: isMediumUp ? '50%' : '100%' }"
          >
            <b-form-group class="mr-5 w-100 mb-2 mb-md-0">
              <b-form-checkbox
                id="taxableDiscount"
                class="taxableDiscount__checkbox"
                v-model="taxForm.taxableDiscount"
                name="taxableDiscount"
              >
                {{ $t('taxForm.form.taxableDiscount.label') }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="mr-5 ml-md-5 mb-0 mb-md-0 w-100">
              <b-form-checkbox
                id="taxableServiceCharge"
                class="taxableServiceCharge__checkbox"
                v-model="taxForm.taxableGratuity"
                name="taxableServiceCharge"
              >
                {{ $t('taxForm.form.taxableServiceCharge.label') }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('taxForm.exclusion') }}</h5>
        <b-container fluid class="content p-4">
          <div
            class="d-flex flex-column flex-md-row mb-md-4 justify-content-between"
            :style="{ width: isMediumUp ? '50%' : '100%' }"
          >
            <b-form-group
              class="mr-5 w-100 mb-2 mb-md-0"
              label-for="exclusionItemQuantity"
              label-size="sm"
              :label="$t('taxForm.form.exclusionQuantity.label')"
              :description="$t('taxForm.form.exclusionQuantity.required')"
              :invalid-feedback="$t('taxForm.form.exclusionQuantity.error')"
              :state="
                taxForm.exclusionItemQtyOver === null || taxForm.exclusionItemQtyOver === ''
                  ? null
                  : !$v.taxForm.exclusionItemQtyOver.$invalid
              "
            >
              <b-form-input
                id="exclusionItemQuantity"
                :placeholder="$t('taxForm.form.exclusionQuantity.placeholder')"
                v-model="taxForm.exclusionItemQtyOver"
                size="md"
                :state="computeState($v.taxForm.exclusionItemQtyOver.$invalid)"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="mr-5 ml-md-5 mb-2 mb-md-0 w-100"
              label-for="exclusionRate"
              label-size="sm"
              :label="$t('taxForm.form.exclusionRate.label')"
              :description="$t('taxForm.form.exclusionRate.required')"
              :invalid-feedback="$t('taxForm.form.exclusionRate.error')"
              :state="
                taxForm.exclusionTaxRate === null || taxForm.exclusionTaxRate === ''
                  ? null
                  : !$v.taxForm.exclusionTaxRate.$invalid
              "
            >
              <b-input-group prepend="%">
                <b-form-input
                  id="exclusionRate"
                  :placeholder="$t('taxForm.form.exclusionRate.placeholder')"
                  v-model="taxForm.exclusionTaxRate"
                  size="md"
                  :formatter="formatAmount"
                  lazy-formatter
                  :state="computeState($v.taxForm.exclusionTaxRate.$invalid)"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('taxForm.exceptionRule') }}</h5>
        <b-container fluid class="content p-4">
          <div
            class="d-flex flex-column flex-md-row mb-md-4 justify-content-between"
            :style="{ width: isMediumUp ? 'calc(75% + 1rem)' : '100%' }"
          >
            <b-form-group
              class="mr-5 w-100 mb-2 mb-md-0"
              label-for="exceptionRuleDescription"
              label-size="sm"
              :label="$t('taxForm.form.exceptionRuleDesc.label')"
              :description="$t('taxForm.form.exceptionRuleDesc.required')"
              :invalid-feedback="$t('taxForm.form.exceptionRuleDesc.error')"
              :state="taxForm.reduceRuleDescription === '' ? null : !$v.taxForm.reduceRuleDescription.$invalid"
            >
              <b-form-input
                id="exceptionRuleDescription"
                :placeholder="$t('taxForm.form.exceptionRuleDesc.placeholder')"
                v-model="taxForm.reduceRuleDescription"
                type="text"
                maxlength="30"
                size="md"
                :state="computeState($v.taxForm.reduceRuleDescription.$invalid)"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="mr-5 ml-md-5 mb-2 mb-md-0 w-100"
              label-for="exceptionRuleRate"
              label-size="sm"
              :label="$t('taxForm.form.exceptionRuleRate.label')"
              :description="$t('taxForm.form.exceptionRuleRate.required')"
              :invalid-feedback="$t('taxForm.form.exceptionRuleRate.error')"
              :state="
                taxForm.reduceRuleTaxRate === null || taxForm.reduceRuleTaxRate === ''
                  ? null
                  : !$v.taxForm.reduceRuleTaxRate.$invalid
              "
            >
              <b-input-group prepend="%">
                <b-form-input
                  id="exceptionRuleRate"
                  :placeholder="$t('taxForm.form.exceptionRuleRate.placeholder')"
                  v-model="taxForm.reduceRuleTaxRate"
                  size="md"
                  :formatter="formatAmount"
                  lazy-formatter
                  :state="computeState($v.taxForm.reduceRuleTaxRate.$invalid)"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              class="mr-5 ml-md-5 mb-2 mb-md-0 w-100"
              label-for="exceptionRuleMaxAmount"
              label-size="sm"
              :label="$t('taxForm.form.exceptionRuleMaxAmount.label')"
              :description="$t('taxForm.form.exceptionRuleMaxAmount.required')"
              :invalid-feedback="$t('taxForm.form.exceptionRuleMaxAmount.error')"
              :state="
                taxForm.reduceRuleMaxAmount === null || taxForm.reduceRuleMaxAmount === ''
                  ? null
                  : !$v.taxForm.reduceRuleMaxAmount.$invalid
              "
            >
              <b-form-input
                id="exceptionRuleMaxAmount"
                :placeholder="$t('taxForm.form.exceptionRuleMaxAmount.placeholder')"
                v-model="taxForm.reduceRuleMaxAmount"
                size="md"
                lazy-formatter
                :formatter="formatAmount"
                :state="computeState($v.taxForm.reduceRuleMaxAmount.$invalid)"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('taxForm.accountingInterface') }}</h5>
        <b-container fluid class="content p-4">
          <div
            class="d-flex flex-column flex-md-row mb-md-4 justify-content-between"
            :style="{ width: isMediumUp ? '50%' : '100%' }"
          >
            <b-form-group
              class="mr-5 w-100 mb-2 mb-md-0"
              label-for="accountingNumberSend"
              label-size="sm"
              :label="$t('taxForm.form.accountingNumberSend.label')"
              :description="$t('taxForm.form.accountingNumberSend.required')"
              :invalid-feedback="$t('taxForm.form.accountingNumberSend.error')"
              :state="taxForm.generalLedgerNumberToSend === '' ? null : !$v.taxForm.generalLedgerNumberToSend.$invalid"
            >
              <b-form-input
                id="accountingNumberSend"
                :placeholder="$t('taxForm.form.accountingNumberSend.placeholder')"
                v-model="taxForm.generalLedgerNumberToSend"
                type="text"
                maxlength="10"
                size="md"
                :state="computeState($v.taxForm.generalLedgerNumberToSend.$invalid)"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="mr-5 ml-md-5 mb-2 mb-md-0 w-100"
              label-for="accountingNumberClaim"
              label-size="sm"
              :label="$t('taxForm.form.accountingNumberClaim.label')"
              :description="$t('taxForm.form.accountingNumberClaim.required')"
              :invalid-feedback="$t('taxForm.form.accountingNumberClaim.error')"
              :state="
                taxForm.generalLedgerNumberToClaim === '' ? null : !$v.taxForm.generalLedgerNumberToClaim.$invalid
              "
            >
              <b-form-input
                id="accountingNumberClaim"
                :placeholder="$t('taxForm.form.accountingNumberClaim.placeholder')"
                v-model="taxForm.generalLedgerNumberToClaim"
                type="text"
                maxlength="10"
                size="md"
                :state="computeState($v.taxForm.generalLedgerNumberToClaim.$invalid)"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-container>
      </b-form>
      <div class="actions">
        <b-button :disabled="!canSave" variant="primary" class="mr-0 mr-md-3 mb-2 mb-md-0" @click="handleSubmit">
          {{ tax && tax.id && tax.id !== '' ? $t('taxForm.edit.save') : $t('taxForm.create.save') }}
        </b-button>
        <b-button variant="outline-primary" @click="handleCancel(false)">
          {{ $t('defaults.actions.cancel') }}
        </b-button>
      </div>
    </div>
    <b-modal
      v-model="showDiscardChanges"
      :title="$t('discardChange.title')"
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
      @hidden="$emit('onCancel', true)"
    >
      {{ $t('discardChange.body') }}
      <template #modal-footer>
        <b-container fluid>
          <div class="w-100">
            <b-button variant="primary" class="float-right" @click="showDiscardChanges = false">
              {{ $t('discardChange.continue') }}
            </b-button>
            <b-button
              variant="link"
              class="float-right ml-2"
              @click="
                showDiscardChanges = false;
                handleCancel(true);
              "
            >
              {{ $t('discardChange.discard') }}
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
  </vel-page>
</template>

<script>
import { decimal, integer, maxLength, minValue, required, requiredIf } from 'vuelidate/lib/validators';
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import VelAlert from '@/components/alert/VelAlert';
import VelPage from '@/components/page/VelPage';
import cloneDeep from 'lodash.clonedeep';
import { validationMixin } from 'vuelidate';
import {mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataTaxes} from "@/stores/data/taxes.module";

export default {
  name: 'tax-form',
  mixins: [DeviceMixin, validationMixin],
  components: {
    VelAlert,
    VelPage
  },
  props: {
    tax: {
      type: Object,
      default: () => ({
        id: '',
        name: '',
        licenceNumber: '',
        alternativeName: '',
        active: true,
        rate: null,
        taxNumber: '',
        includedTax: false,
        scheduleRate: false,
        newTaxRate: null,
        newTaxRateTime: null,
        taxCategoryId: null,
        fiscalIdentification: '',
        taxableDiscount: false,
        taxableGratuity: false,
        exclusionItemQtyOver: null,
        exclusionTaxRate: null,
        reduceRuleDescription: '',
        reduceRuleTaxRate: null,
        reduceRuleMaxAmount: null,
        generalLedgerNumberToSend: '',
        generalLedgerNumberToClaim: ''
      })
    },
    taxCategories: {
      type: Array,
      default: () => []
    },
    errorLabel: {
      type: String,
      default: () => 'Error'
    }
  },
  data() {
    return {
      showDiscardChanges: false,
      failedSubmit: false,
      newTaxRateTime: '',
      dateFormat: 'yyyy-MM-dd',
      taxOrigin: null,
      taxForm: {
        id: this.tax.id,
        name: this.tax.name,
        licenceNumber: this.tax.licenceNumber,
        alternativeName: this.tax.alternativeName,
        active: this.tax.active,
        rate: this.tax.rate ? Number(this.tax.rate).toFixed(2) : null,
        taxNumber: this.tax.taxNumber,
        includedTax: this.tax.includedTax,
        scheduleRate: this.tax.scheduleRate,
        newTaxRate: this.tax.newTaxRate ? Number(this.tax.newTaxRate).toFixed(2) : null,
        newTaxRateTime: this.tax.newTaxRateTime,
        taxCategoryId: this.tax.taxCategoryId,
        fiscalIdentification: this.tax.fiscalIdentification,
        taxableDiscount: this.tax.taxableDiscount,
        taxableGratuity: this.tax.taxableGratuity,
        exclusionItemQtyOver: this.tax.exclusionItemQtyOver,
        exclusionTaxRate: this.tax.exclusionTaxRate ? Number(this.tax.exclusionTaxRate).toFixed(2) : null,
        reduceRuleDescription: this.tax.reduceRuleDescription,
        reduceRuleTaxRate: this.tax.reduceRuleTaxRate ? Number(this.tax.reduceRuleTaxRate).toFixed(2) : null,
        reduceRuleMaxAmount: this.tax.reduceRuleMaxAmount ? Number(this.tax.reduceRuleMaxAmount).toFixed(2) : null,
        generalLedgerNumberToSend: this.tax.generalLedgerNumberToSend,
        generalLedgerNumberToClaim: this.tax.generalLedgerNumberToClaim
      }
    };
  },
  watch: {
    tax(value) {
      this.taxForm = Object.assign(
        {},
        {
          ...value,
          rate: this.tax.rate ? Number(this.tax.rate).toFixed(2) : null,
          newTaxRate: this.tax.newTaxRate ? Number(this.tax.newTaxRate).toFixed(2) : null,
          reduceRuleTaxRate: this.tax.reduceRuleTaxRate ? Number(this.tax.reduceRuleTaxRate).toFixed(2) : null,
          exclusionTaxRate: this.tax.exclusionTaxRate ? Number(this.tax.exclusionTaxRate).toFixed(2) : null,
          reduceRuleMaxAmount: this.tax.reduceRuleMaxAmount ? Number(this.tax.reduceRuleMaxAmount).toFixed(2) : null
        }
      );

      if (!this.taxOrigin) {
        this.taxOrigin = cloneDeep(this.taxForm);
      }
    }
  },
  validations() {
    return {
      taxForm: {
        name: {
          required,
          maxLength: maxLength(50)
        },
        rate: {
          required,
          decimal: decimal(),
          minValue: minValue(0)
        },
        fiscalIdentification: {
          maxLength: maxLength(10)
        },
        exclusionItemQtyOver: {
          integer,
          minValue: minValue(0)
        },
        exclusionTaxRate: {
          decimal,
          minValue: minValue(0)
        },
        reduceRuleDescription: {
          maxLength: maxLength(30)
        },
        reduceRuleTaxRate: {
          decimal,
          minValue: minValue(0)
        },
        reduceRuleMaxAmount: {
          decimal,
          minValue: minValue(0)
        },
        generalLedgerNumberToSend: {
          maxLength: maxLength(10)
        },
        generalLedgerNumberToClaim: {
          maxLength: maxLength(10)
        },
        newTaxRate: {
          required: requiredIf(() => this.taxForm.scheduleRate),
          decimal,
          minValue: minValue(0)
        },
        newTaxRateTime: {
          required: requiredIf(() => this.taxForm.scheduleRate)
        }
      }
    };
  },
  computed: {
    ...mapPiniaState(useDataTaxes, ['hasTaxError']),
    canSave() {
      this.$v.taxForm.$touch();
      if (this.$v.taxForm.$invalid) {
        return false;
      }

      if (JSON.stringify(this.taxOrigin) !== JSON.stringify(this.taxForm)) {
        return true;
      }

      return false;
    },
    haveChanges() {
      if (JSON.stringify(this.taxOrigin) !== JSON.stringify(this.taxForm)) {
        return true;
      }
      return false;
    }
  },
  methods: {
    formatAmount(value) {
      return isNaN(value) ? value : Number(value).toFixed(2);
    },
    handleSubmit() {
      this.$v.taxForm.$touch();
      if (this.$v.taxForm.$invalid) {
        this.failedSubmit = true;
        return;
      }

      const safeForm = this.sanitizeForm(this.taxForm);

      this.$emit('submit', safeForm);
    },
    handleCancel(force = false) {
      if (this.haveChanges && !force) {
        this.showDiscardChanges = true;
      } else {
        this.$router.push({ name: 'taxes-setup' });
      }
    },
    toggleScheduleRate(value) {
      this.taxForm.scheduleRate = value;
      if (!this.taxForm.scheduleRate) {
        this.taxForm.newTaxRate = this.taxForm.newTaxRateTime = null;
      }
    },
    onSelectScheduledDate(ctx) {
      this.newTaxRateTime = ctx.selectedFormatted;
    },
    setScheduledDate(val) {
      if (val.length === 10) {
        this.taxForm.newTaxRateTime = DateTime.fromFormat(val, this.$t('dateFormat')).toISO();
      } else if (val.length === 0) {
        this.taxForm.newTaxRateTime = null;
      }
    },
    computeState(validatatorValue) {
      return this.failedSubmit && validatatorValue ? !validatatorValue : null;
    },
    sanitizeForm(form) {
      const toFloatOrNull = f => (isNaN(parseFloat(f)) ? null : parseFloat(f));
      return {
        ...form,
        alternativeName: form.name,
        rate: toFloatOrNull(form.rate),
        newTaxRate: toFloatOrNull(form.newTaxRate),
        exclusionItemQtyOver: toFloatOrNull(form.exclusionItemQtyOver),
        exclusionTaxRate: toFloatOrNull(form.exclusionTaxRate),
        reduceRuleTaxRate: toFloatOrNull(form.reduceRuleTaxRate),
        reduceRuleMaxAmount: toFloatOrNull(form.reduceRuleMaxAmount),
        newTaxRateTime: this.newTaxRateTime
          ? DateTime.fromFormat(this.newTaxRateTime, this.$t('dateFormat')).toISO()
          : form.newTaxRateTime
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.tax-form-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  .tax-form {
    -ms-overflow-style: none;
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .actions {
    align-items: center;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.08), 0 -1px 8px 0 rgba(0, 0, 0, 0.06), 0 -3px 3px -2px rgba(0, 0, 0, 0.12);
    display: flex;
    height: rem-calc(64);
    justify-content: space-between;
    margin: rem-calc(8) 0;
    padding: rem-calc(24);
    z-index: 200;

    @include breakpoint(large) {
      margin: unset;
    }
  }
}

.datepicker /deep/ button {
  background-color: white;
  border-bottom: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
}

h5 {
  background-color: #f5f5f5;
}

/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.datepicker .input-group-append {
  margin-left: -35px;
}
</style>
