import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import bigDivisionsService from "@/services/bigDivisions.service";
import router from "@/router";
import divisionsService from "@/services/divisions.service";
import locationsService from "@/services/locations.service";
import salesService from "@/services/sales.service";
import salesModuleHelper from "@/stores/sales.module.helper";
import {accentFold} from "@/helpers/string.helpers";
import clonedeep from 'lodash.clonedeep';
import uniq from 'lodash.uniq'
import {oc} from "ts-optchain";

const initialState = {
  sales: [],
  filter: '',
  division: null,
  bigDivision: null,
  location: null,
  status: {},
  error: null
};


const getSalesWithMethod = ({ self, getSales }, query) => {
  self.getSalesRequest();

  getSales(getToken(), query).then(
    sales => {
      self.getSalesSuccess(salesModuleHelper.formatSales(sales));
    },
    error => {
      self.getSalesFailure(error);
    }
  );
};
const applyFilter = (prop, filter) => element => element[prop].toLowerCase().indexOf(filter.toLowerCase()) >= 0;

export const useDataSales = defineStore('sales', {
  state: () => initialState,
  getters: {
    areSalesLoading: (state: any) => state.status.isLoadingSales || false,
    getSales: state => (state.filter ? state.sales.filter(applyFilter('label', state.filter)) : state.sales),
    getSalesByCurrency: state => currencyId => {
      const sales = state.filter ? state.sales.filter(applyFilter('label', state.filter)) : state.sales;
      return sales
    },
    getSalesTree: state =>
      state.filter
        ? clonedeep(state.sales)
          .map(elem => {
            elem.children = elem.children.filter(applyFilter('label', state.filter));
            return elem;
          })
          .filter(elem => elem.children.length > 0)
        : state.sales,
    getSalesTreeByCurrency(state) {
      return currency => state.filter
        ? clonedeep(state.sales)
          .map(elem => {
            elem.children = elem.children.filter(applyFilter('label', state.filter));
            return elem;
          })
          .filter(s => s.currencyCode === currency)
          .filter(elem => elem.children.length > 0)
        : this.getSalesByCurrency(currency)
    },
    getFilter: state => state.filter,
    uniqueCurrencies: state => uniq([...(state.sales || []).map(sale => sale.currency)])
  },
  actions: {
    getBigDivision(query) {
      this.getBigDivisionRequest();

      bigDivisionsService.getBigDivision(getToken(), { ...router.currentRoute.query, ...query }).then(
        bigDivision => {
          this.getBigDivisionSuccess(bigDivision);
        },
        error => {
          this.getBigDivisionFailure(error);
        }
      );
    },
    getDivision(query) {
      this.getDivisionRequest();

      divisionsService.getDivision(getToken(), { ...router.currentRoute.query, ...query }).then(
        division => {
          this.getDivisionSuccess(division);
        },
        error => {
          this.getDivisionFailure( error);
        }
      );
    },
    getLocation(locationId) {
      this.getLocationRequest();

      locationsService.getLocation(getToken(), locationId).then(
        location => {
          this.getLocationSuccess(location);
        },
        error => {
          this.getLocationFailure(error);
        }
      );
    },
    getSalesByItemReportWithLevels(query) {
      this.getSalesRequest();
      salesService.getDetailedSales(getToken(), { ...router.currentRoute.query, ...query }).then(
        sales => {
          this.getSalesSuccess(salesModuleHelper.formatItemsByLevels(sales));
        },
        error => {
          this.getSalesFailure( error);
        }
      );
    },
    getSalesByBigDivisionsReportWithLevels(query) {
      this.getSalesRequest();
      salesService.getDetailedSales(getToken(), { ...router.currentRoute.query, ...query }).then(
        sales => {
          this.getSalesSuccess(salesModuleHelper.formatBigDivisionByLevels(sales));
        },
        error => {
          this.getSalesFailure(error);
        }
      );
    },
    getSalesByBigDivision(query) {
      getSalesWithMethod(
        { self: this, getSales: salesService.getBigDivisionSales },
        { ...router.currentRoute.query, ...query }
      );
    },
    getSalesByDivision(query) {
      getSalesWithMethod(
        { self: this, getSales: salesService.getSalesByDivision },
        { ...router.currentRoute.query, ...query }
      );
    },
    getSalesByItem(query) {
      getSalesWithMethod(
        { self: this, getSales: salesService.getSalesByItem },
        { ...router.currentRoute.query, ...query }
      );
    },
    getSalesByLocation(query) {
      getSalesWithMethod(
        { self: this, getSales: salesService.getSalesByLocation },
        { ...router.currentRoute.query, ...query }
      );
    },
    getDetailedSales(query) {
      getSalesWithMethod(
        { self: this, getSales: salesService.getDetailedSales },
        { ...router.currentRoute.query, ...query }
      );
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    getSalesRequest() {
      this.status = { ...this.status, isLoadingSales: true };
      this.sales = [];
      this.filter = '';
      this.error = null;
    },
    getSalesSuccess(sales) {
      this.status = { ...this.status, isLoadingSales: false };
      this.sales = sales;
      this.filter = '';
      this.error = null;
    },
    getSalesFailure(error) {
      this.status = { ...this.status, isLoadingSales: false };
      this.sales = [];
      this.filter = '';
      this.error = error;
    },
    getLocationRequest() {
      this.status = { ...this.status, isLoadingLocation: true };
      this.location = null;
      this.error = null;
    },
    getLocationSuccess(location) {
      this.status = { ...this.status, isLoadingLocation: false };
      this.location = location;
      this.error = null;
    },
    getLocationFailure(error) {
      this.status = { ...this.status, isLoadingLocation: false };
      this.location = null;
      this.error = error;
    },
    getBigDivisionRequest() {
      this.status = { ...this.status, isLoadingBigDivision: true };
      this.bigDivision = null;
      this.error = null;
    },
    getBigDivisionSuccess(bigDivision) {
      this.status = { ...this.status, isLoadingBigDivision: false };
      this.bigDivision = bigDivision;
      this.error = null;
    },
    getBigDivisionFailure(error) {
      this.status = { ...this.status, isLoadingBigDivision: false };
      this.bigDivision = null;
      this.error = error;
    },
    getDivisionRequest() {
      this.status = { ...this.status, isLoadingDivision: true };
      this.division = null;
      this.error = null;
    },
    getDivisionSuccess(division) {
      this.status = { ...this.status, isLoadingDivision: false };
      this.division = division;
      this.error = null;
    },
    getDivisionFailure(error) {
      this.status = { ...this.status, isLoadingDivision: false };
      this.division = null;
      this.error = error;
    },
    setFilterValue(value) {
      this.filter = accentFold(value.trim());
    },
    reset() {
      Object.keys(initialState).forEach(key => {
        this[key] = initialState[key];
      });
    }
  }
});
