var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", { staticClass: "px-4" }, [
      _vm._v(_vm._s(_vm.$t("coursing.form.generalConfig")))
    ]),
    _c(
      "div",
      { staticClass: "bg-white p-4 mt-2" },
      [
        _c(
          "b-form-group",
          {
            staticClass: "mr-5 w-100 mb-2 mb-md-0",
            attrs: {
              label: _vm.$t("coursing.form.name.label"),
              description: _vm.$t("coursing.required"),
              "label-for": "name"
            }
          },
          [
            _c("b-form-input", {
              class: {
                required:
                  !_vm.coursing ||
                  !_vm.coursing.name ||
                  _vm.coursing.name === ""
              },
              attrs: {
                id: "name",
                maxlength: "50",
                "aria-describedby": "input-live-help input-live-feedback"
              },
              model: {
                value: _vm.coursing.name,
                callback: function($$v) {
                  _vm.$set(
                    _vm.coursing,
                    "name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "coursing.name"
              }
            })
          ],
          1
        ),
        _c(
          "b-form-group",
          {
            staticClass: "mr-5 w-100 mb-2 mb-md-0 mt-3",
            attrs: {
              label: _vm.$t("coursing.form.automaticDelayMinutes.label"),
              "label-for": "automaticDelayMinutes"
            }
          },
          [
            _c("b-form-input", {
              attrs: {
                id: "automaticDelayMinutes",
                number: "",
                maxlength: "50"
              },
              model: {
                value: _vm.coursing.automaticDelayMinutes,
                callback: function($$v) {
                  _vm.$set(_vm.coursing, "automaticDelayMinutes", _vm._n($$v))
                },
                expression: "coursing.automaticDelayMinutes"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }