var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center mb-2 mb-md-0 pb-4",
              attrs: { cols: "12", md: "4" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("mealPlan.description.label")) + " ")]
          ),
          _c(
            "b-col",
            [
              _c("b-form-input", {
                staticClass: "required",
                class: { required: !_vm.form.name || _vm.form.name === "" },
                attrs: {
                  id: "name",
                  state: _vm.enableSaveChanges,
                  placeholder: _vm.$t("mealPlan.description.placeholder"),
                  "aria-describedby": "passwd-feedback"
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              }),
              _c("b-form-text", { attrs: { id: "input-live-help" } }, [
                _vm._v(_vm._s(_vm.$t("employeeCreate.required")))
              ]),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "passwd-feedback" } },
                [_vm._v(" " + _vm._s(_vm.$t("employeeCreate.required")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { name: "flavour-4a", inline: "" },
                  model: {
                    value: _vm.form.excludeBenefit,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "excludeBenefit", $$v)
                    },
                    expression: "form.excludeBenefit"
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("mealPlan.excludeBenefit.label")) + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("h6", [
        _vm._v(" " + _vm._s(_vm.$t("mealPlan.eligibility.title")) + " ( "),
        _c("span", { staticClass: "font-weight-light" }, [_vm._v("Max. 5")]),
        _vm._v(" ) ")
      ]),
      _c(
        "b-row",
        { staticClass: "px-3 py-1" },
        [
          _c("b-col", { staticClass: "pr-0", attrs: { cols: "4" } }, [
            _c(
              "div",
              {
                staticClass: "text-center p-1",
                staticStyle: {
                  border: "1px solid #dfdfdf",
                  "border-radius": "3px",
                  "font-size": "0.8rem"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("mealPlan.eligibility.duration.label")) +
                    " "
                )
              ]
            )
          ]),
          _c("b-col", { attrs: { cols: "4" } }, [
            _c(
              "div",
              {
                staticClass: "text-center p-1",
                staticStyle: {
                  border: "1px solid #dfdfdf",
                  "border-radius": "3px",
                  "font-size": "0.8rem"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("mealPlan.eligibility.amount.label")) +
                    " "
                )
              ]
            )
          ])
        ],
        1
      ),
      _vm._l(_vm.form.eligibility, function(eligibility, index) {
        return _c(
          "b-row",
          { key: index, staticClass: "px-3 py-1" },
          [
            _c(
              "b-col",
              { staticClass: "pr-0", attrs: { cols: "4" } },
              [
                _c("b-form-input", {
                  attrs: {
                    id: "name",
                    placeholder: _vm.$t(
                      "mealPlan.eligibility.duration.placeholder"
                    ),
                    number: ""
                  },
                  model: {
                    value: _vm.form.eligibility[index].duration,
                    callback: function($$v) {
                      _vm.$set(_vm.form.eligibility[index], "duration", $$v)
                    },
                    expression: "form.eligibility[index].duration"
                  }
                })
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { cols: "4" } },
              [
                _c(
                  "b-input-group",
                  {
                    class: { active: _vm.form.eligibility[index].amount },
                    attrs: { prepend: "$" }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "name",
                        placeholder: _vm.$t(
                          "mealPlan.eligibility.amount.placeholder"
                        ),
                        number: "",
                        formatter: _vm.formatAmount,
                        "lazy-formatter": ""
                      },
                      model: {
                        value: _vm.form.eligibility[index].amount,
                        callback: function($$v) {
                          _vm.$set(_vm.form.eligibility[index], "amount", $$v)
                        },
                        expression: "form.eligibility[index].amount"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-col",
              {
                staticClass: "pl-0 d-flex align-items-center",
                attrs: { cols: "4" }
              },
              [
                index > 0
                  ? _c("b-icon", {
                      staticClass: "rounded mr-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        icon: "x-circle",
                        "font-scale": "1.5",
                        variant: "danger"
                      },
                      on: {
                        click: function($event) {
                          return _vm.removeEligibility(index)
                        }
                      }
                    })
                  : _vm._e(),
                index < 4 && index == _vm.form.eligibility.length - 1
                  ? _c("b-icon", {
                      staticClass: "rounded",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        icon: "plus-circle",
                        "font-scale": "1.5",
                        variant: "primary"
                      },
                      on: { click: _vm.addEligibility }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }