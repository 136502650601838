var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasIndicatorsError
        ? _c(
            "vel-card",
            [
              _c(
                "vel-alert",
                { attrs: { level: "error", dismissable: false } },
                [_vm._v(_vm._s(_vm.$t("dashboard.errors.other")))]
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.hasIndicatorsError
        ? _c(
            "indicator-grid",
            { class: { "hide-icon": !_vm.showIcon, secondary: _vm.secondary } },
            [
              _c("gross-sales-volume", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_gross_sales_volume",
                    expression: "configKey + '_show_gross_sales_volume'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_gross_sales_volume"),
                attrs: {
                  amount: _vm.getIndicatorsSalesVolume,
                  "previous-amount": _vm.previousIndicators.grossSalesAmount,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("discounts-volume", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_discounts_volume",
                    expression: "configKey + '_show_discounts_volume'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_discounts_volume"),
                attrs: {
                  amount: _vm.getIndicatorsDiscountsVolume,
                  "previous-amount": _vm.previousIndicators.discountsAmount,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("net-sales-volume", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_net_sales_volume",
                    expression: "configKey + '_show_net_sales_volume'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_net_sales_volume"),
                attrs: {
                  amount: _vm.getIndicatorsNetSalesVolume,
                  "previous-amount": _vm.previousIndicators.netSalesAmount,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("taxes-volume", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_taxe_volume",
                    expression: "configKey + '_show_taxe_volume'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_taxe_volume"),
                attrs: {
                  amount: _vm.getIndicatorsTaxesVolume,
                  "previous-amount": _vm.previousIndicators.taxesAmount,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("net-sales-volume-taxes", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_net_sales_volume_taxes",
                    expression: "configKey + '_show_net_sales_volume_taxes'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(
                  _vm.configKey + "_show_net_sales_volume_taxes"
                ),
                attrs: {
                  amount: _vm.getIndicatorsNetSalesVolumeTaxes,
                  "previous-amount":
                    _vm.previousIndicators.netSalesAmount +
                    _vm.previousIndicators.taxesAmount,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("discounts-percentage", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_discounts_percentage",
                    expression: "configKey + '_show_discounts_percentage'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(
                  _vm.configKey + "_show_discounts_percentage"
                ),
                attrs: {
                  amount: _vm.getIndicatorsDiscountsPercent,
                  "previous-amount":
                    (_vm.previousIndicators.discountsAmount /
                      _vm.previousIndicators.grossSalesAmount) *
                      100 || 0
                }
              }),
              _c("meals-amount", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_meal_count",
                    expression: "configKey + '_show_meal_count'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_meal_count"),
                attrs: {
                  amount: _vm.getIndicatorsMeals,
                  "previous-amount": _vm.previousIndicators.meals
                }
              }),
              _c("orders-amount", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_order_count",
                    expression: "configKey + '_show_order_count'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_order_count"),
                attrs: {
                  amount: _vm.getIndicatorsOrders,
                  "previous-amount": _vm.previousIndicators.orders
                }
              }),
              _c("customer-amount", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_customer_count",
                    expression: "configKey + '_show_customer_count'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_customer_count"),
                attrs: {
                  amount: _vm.getIndicatorsCustomers,
                  "previous-amount": _vm.previousIndicators.customers
                }
              }),
              _c("no-sales", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_no_sales",
                    expression: "configKey + '_show_no_sales'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_no_sales"),
                attrs: {
                  amount: _vm.getIndicatorsNoSales,
                  "previous-amount": _vm.previousIndicators.drawerOpenings
                }
              }),
              _c("cash-deposit", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_cash_deposit",
                    expression: "configKey + '_show_cash_deposit'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_cash_deposit"),
                attrs: {
                  amount: _vm.getIndicatorsCashDeposit || 0,
                  "previous-amount":
                    _vm.previousIndicators.cashDepositAmount || 0,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("paid-outs", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_paid_outs",
                    expression: "configKey + '_show_paid_outs'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_paid_outs"),
                attrs: {
                  loading: _vm.loading,
                  amount: _vm.getIndicatorsPaidOuts,
                  "previous-amount": _vm.previousIndicators.paidOuts,
                  currency: _vm.getCurrencySelectorSelectedSymbol,
                  versionIsValid: _vm.versionIsValid("9.67.0")
                }
              }),
              _c("paid-ins-amount", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_paid_ins",
                    expression: "configKey + '_show_paid_ins'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_paid_ins"),
                attrs: {
                  loading: _vm.loading,
                  amount: _vm.getIndicatorsPaidInsAmount || 0,
                  "previous-amount": _vm.previousIndicators.paidInsAmount || 0,
                  currency: _vm.getCurrencySelectorSelectedSymbol,
                  versionIsValid: _vm.versionIsValid("9.67.0")
                }
              }),
              _c("net-cash-amount", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_net_cash",
                    expression: "configKey + '_show_net_cash'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_net_cash"),
                attrs: {
                  loading: _vm.loading,
                  amount: _vm.getIndicatorsNetCashAmount || 0,
                  "previous-amount": _vm.previousIndicators.netCashAmount || 0,
                  currency: _vm.getCurrencySelectorSelectedSymbol,
                  versionIsValid: _vm.versionIsValid("9.67.0")
                }
              }),
              _c("tips-total-amount", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_tips_total_amount",
                    expression: "configKey + '_show_tips_total_amount'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_tips_total_amount"),
                attrs: {
                  loading: _vm.loading,
                  amount: _vm.getIndicatorTipsTotalAmount,
                  "previous-amount": _vm.previousIndicators.tipsTotalAmount,
                  currency: _vm.getCurrencySelectorSelectedSymbol,
                  versionIsValid: _vm.versionIsValid()
                }
              }),
              _c("tips-with-total", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_total_with_tips_amount",
                    expression: "configKey + '_show_total_with_tips_amount'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(
                  _vm.configKey + "_show_total_with_tips_amount"
                ),
                attrs: {
                  loading: _vm.loading,
                  amount: _vm.getIndicatorsTotalWithTipsAmount,
                  "previous-amount":
                    _vm.previousIndicators.tipsTotalAmount +
                    _vm.previousIndicators.netSalesAmount +
                    _vm.previousIndicators.taxesAmount,
                  currency: _vm.getCurrencySelectorSelectedSymbol,
                  versionIsValid: _vm.versionIsValid()
                }
              }),
              _vm.getIndicatorsCashDiscounts !== 0
                ? _c("cash-discounts", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isIndicatorsLoading,
                        expression: "isIndicatorsLoading"
                      },
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value: _vm.configKey + "_show_cash_discounts",
                        expression: "configKey + '_show_cash_discounts'",
                        arg: "show"
                      }
                    ],
                    style: _vm.getOrder(_vm.configKey + "_show_cash_discounts"),
                    attrs: {
                      amount: _vm.getIndicatorsCashDiscounts,
                      "previous-amount":
                        _vm.previousIndicators.cashDiscountAmount,
                      currency: _vm.getCurrencySelectorSelectedSymbol
                    }
                  })
                : _vm._e(),
              _vm.getIndicatorsSurchargeAmount !== 0
                ? _c("surcharge", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isIndicatorsLoading,
                        expression: "isIndicatorsLoading"
                      },
                      {
                        name: "can",
                        rawName: "v-can:show",
                        value: _vm.configKey + "_show_surcharge",
                        expression: "configKey + '_show_surcharge'",
                        arg: "show"
                      }
                    ],
                    style: _vm.getOrder(_vm.configKey + "_show_surcharge"),
                    attrs: {
                      amount: _vm.getIndicatorsSurchargeAmount,
                      "previous-amount": _vm.previousIndicators.surchargeAmount,
                      currency: _vm.getCurrencySelectorSelectedSymbol
                    }
                  })
                : _vm._e(),
              _c("gross-sales-per-order", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_gross_sales_per_order",
                    expression: "configKey + '_show_gross_sales_per_order'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(
                  _vm.configKey + "_show_gross_sales_per_order"
                ),
                attrs: {
                  amount: _vm.getIndicatorsSalesPerOrder,
                  "previous-amount":
                    _vm.previousIndicators.grossSalesAmount /
                      _vm.previousIndicators.orders || 0,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("gross-income-per-customer", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_gross_sales_per_customer",
                    expression: "configKey + '_show_gross_sales_per_customer'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(
                  _vm.configKey + "_show_gross_sales_per_customer"
                ),
                attrs: {
                  amount: _vm.getIndicatorsSalesPerCustomer,
                  "previous-amount":
                    _vm.previousIndicators.grossSalesAmount /
                      _vm.previousIndicators.customers || 0,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("net-income-per-customer", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_net_income_per_customer",
                    expression: "configKey + '_show_net_income_per_customer'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(
                  _vm.configKey + "_show_net_income_per_customer"
                ),
                attrs: {
                  amount: _vm.getIndicatorsIncomePerCustomer,
                  "previous-amount":
                    _vm.previousIndicators.netSalesAmount /
                      _vm.previousIndicators.customers || 0,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("income-per-order", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_income_per_order",
                    expression: "configKey + '_show_income_per_order'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_income_per_order"),
                attrs: {
                  amount: _vm.getIndicatorsRevenuePerOrder,
                  "previous-amount":
                    _vm.previousIndicators.netSalesAmount /
                      _vm.previousIndicators.orders || 0,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("gross-income-per-meal", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_gross_income_meal",
                    expression: "configKey + '_show_gross_income_meal'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_gross_income_meal"),
                attrs: {
                  amount: _vm.getIndicatorsGrossIncomePerMeal,
                  "previous-amount":
                    _vm.previousIndicators.grossSalesPerMeal || 0,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              }),
              _c("net-income-per-meal", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isIndicatorsLoading,
                    expression: "isIndicatorsLoading"
                  },
                  {
                    name: "can",
                    rawName: "v-can:show",
                    value: _vm.configKey + "_show_net_income_meal",
                    expression: "configKey + '_show_net_income_meal'",
                    arg: "show"
                  }
                ],
                style: _vm.getOrder(_vm.configKey + "_show_net_income_meal"),
                attrs: {
                  amount: _vm.getIndicatorsNetIncomePerMeal,
                  "previous-amount":
                    _vm.previousIndicators.netSalesPerMeal || 0,
                  currency: _vm.getCurrencySelectorSelectedSymbol
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }