import { render, staticRenderFns } from "./VelAmount.vue?vue&type=template&id=7cfcc64e&scoped=true&"
import script from "./VelAmount.vue?vue&type=script&lang=js&"
export * from "./VelAmount.vue?vue&type=script&lang=js&"
import style0 from "./VelAmount.vue?vue&type=style&index=0&id=7cfcc64e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cfcc64e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/runner/work/velweb/velweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7cfcc64e')) {
      api.createRecord('7cfcc64e', component.options)
    } else {
      api.reload('7cfcc64e', component.options)
    }
    module.hot.accept("./VelAmount.vue?vue&type=template&id=7cfcc64e&scoped=true&", function () {
      api.rerender('7cfcc64e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/amount/VelAmount.vue"
export default component.exports