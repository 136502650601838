var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "permission-edit-modal" },
    [
      _c(
        "portal",
        { attrs: { to: "app" } },
        [
          _c("vel-modal", {
            staticClass: "modals__edit",
            attrs: { dismissable: "" },
            on: { dismiss: _vm.handleCancel },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("user.permissions.modals.edit.title")) +
                        " "
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [
                    _c("permission-form", {
                      staticClass: "permission-edit-modal__form",
                      attrs: {
                        locationsOptions: !_vm.permission.locationId
                          ? []
                          : _vm.locations,
                        groupsOptions: !_vm.permission.groupId
                          ? []
                          : _vm.groups,
                        permission: _vm.permission
                      },
                      on: {
                        submit: _vm.handleSuccess,
                        cancel: _vm.handleCancel
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }