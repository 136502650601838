var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page bootstrap",
      attrs: {
        enableToolbar: false,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        scroll: false
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "d-flex flex-column flex-md-row pt-3" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("b-breadcrumb", {
                            attrs: { items: _vm.breadcrumbs }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4 mb-6" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-between",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("h4", [_vm._v(_vm._s(_vm.$t("taxesList.title")))]),
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "ml-4 d-flex align-items-center d-md-none",
                              attrs: { variant: "primary" }
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "plus",
                                  "aria-hidden": "true",
                                  scale: "1.4"
                                }
                              }),
                              _c("span", { staticClass: "d-none d-md-block" }, [
                                _vm._v(_vm._s(_vm.$t("taxesList.add-new")))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex mt-4 mt-md-0 flex-row justify-content-md-end flex-md-row",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              staticClass: "align-content-end align-self-end",
                              staticStyle: { "max-width": "250px" }
                            },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "search" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  debounce: "1",
                                  type: "text",
                                  placeholder: _vm.$t("taxesList.search")
                                },
                                model: {
                                  value: _vm.tableFilter,
                                  callback: function($$v) {
                                    _vm.tableFilter = $$v
                                  },
                                  expression: "tableFilter"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "ml-4 d-md-flex align-items-center d-none",
                              attrs: {
                                variant: "primary",
                                to: { name: "tax-create" }
                              }
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "plus",
                                  "aria-hidden": "true",
                                  scale: "1.4"
                                }
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("taxesList.add-new")) + " "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-container",
        { staticClass: "h-100", attrs: { fluid: "" } },
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              striped: "",
              bordered: "",
              hover: "",
              "show-empty": "",
              fixed: "",
              "primary-key": "id",
              "empty-text": _vm.$t("defaults.empty"),
              "empty-filtered-text": _vm.$t("defaults.empty"),
              busy: _vm.isTaxLoading,
              fields: _vm.fields,
              "head-variant": "info",
              items: _vm.getTaxesRows,
              responsive: "sm",
              "sort-by": "name",
              "sticky-header": ""
            },
            scopedSlots: _vm._u([
              {
                key: "empty",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "p-2" }, [
                      _vm._v(_vm._s(scope.emptyText))
                    ])
                  ]
                }
              },
              {
                key: "emptyfiltered",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "p-2" }, [
                      _vm._v(_vm._s(scope.emptyFilteredText))
                    ])
                  ]
                }
              },
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle p-2 m-4",
                          attrs: { variant: "primary" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(name)",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 justify-content-between d-flex align-items-center"
                      },
                      [_vm._v(" " + _vm._s(scope.item.name) + " ")]
                    )
                  ]
                }
              },
              {
                key: "cell(taxCategory)",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 justify-content-between d-flex align-items-center"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.item.taxCategory
                                ? scope.item.taxCategory.name
                                : ""
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "cell(edit)",
                fn: function(scope) {
                  return [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "link",
                          size: "sm",
                          to: {
                            name: "tax-edit",
                            params: { id: scope.item.id }
                          }
                        }
                      },
                      [
                        _c("vel-icon", {
                          staticClass: "row-action text-primary",
                          attrs: { size: "small", name: "xd-edit" }
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("taxesList.table.edit")) + " "
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(more)",
                fn: function(scope) {
                  return [_c("tax-more", { attrs: { id: scope.item.id } })]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.createSucceeded
        ? _c(
            "vel-alert",
            {
              staticClass: "alert",
              staticStyle: { "font-size": "1.5rem" },
              attrs: { level: "success" },
              on: { dismiss: _vm.resetState }
            },
            [_vm._v(" " + _vm._s(_vm.$t("taxForm.succeed.create")) + " ")]
          )
        : _vm.editSucceeded
        ? _c(
            "vel-alert",
            {
              staticClass: "alert",
              staticStyle: { "font-size": "1.5rem" },
              attrs: { level: "success" },
              on: { dismiss: _vm.resetState }
            },
            [_vm._v(" " + _vm._s(_vm.$t("taxForm.succeed.edit")) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }