<template>
  <vel-backoffice-page class="bootstrap">
    <template slot="header">
      <div style="border-bottom: 8px solid rgb(245, 245, 245);">
        <div
          class="d-flex justify-content-between"
          style="border-bottom: 1px solid rgba(76, 86, 103, 0.2); padding: 0.8rem 1.5rem;"
        >
          <h1 class="title-right my-0" style="font-size: 1.5rem;">
            {{ isSearchMode ? $t('checkDetails.titleSearch') : $t('checkDetails.title') }}
          </h1>
          <span
            v-if="Object.values(invoices).length"
            v-b-tooltip.top.hover
            :title="totalRows > 3000 ? $t('checkDetails.generateTooMany') : ''"
          >
            <vel-button
              :loading="generating"
              :disabled="loading || totalRows > 3000"
              type="primary"
              @click="onExportPDF"
              :icon="isMediumUp ? 'file-pdf' : undefined"
              class="vel-button"
            >
              <vel-icon v-if="isMediumDown" name="file-download" />
              {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
            </vel-button>
          </span>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="px-3 py-1 row">
        <router-link v-if="!isSearchMode" class="col-md-6 d-flex jalign-items-center" :to="{ name: 'checksSummary' }">
          {{ $t('checkDetails.backLinkCheck') }}
        </router-link>
        <router-link v-if="isSearchMode" class="col-md-6 d-flex align-items-center" :to="{ name: 'reports-menu' }">
          {{ $t('checkDetails.backLinkReport') }}
        </router-link>
        <div v-if="isSearchMode" class="col-md-6 d-flex justify-content-end align-items-center">
          <b-form-group class="d-none d-md-block">
            {{ $t('employee.list.rowsPerPage') }}
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="[10, 25, 50, 100]"
              style="width: 80px;"
              class="mx-3"
              :disabled="!invoices.length"
              @change="onPerPageChange"
            ></b-form-select>
          </b-form-group>
          <span class="d-none d-md-flex align-self-center mr-6" v-if="invoices.length">
            {{
              $t('pagination.format', {
                first: currentPage * perPage - perPage + 1,
                last: Math.min(currentPage * perPage, totalRows),
                total: totalRows
              })
            }}
          </span>
          <b-pagination
            size="sm"
            pills
            class="m-0 ml-2"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            :limit="5"
            align="right"
            :disabled="!invoices.length"
            @change="searchInvoiceByNumber"
          ></b-pagination>
        </div>
      </div>
    </template>
    <b-container fluid class="h-100 pt-4 h-100" id="table-container">
      <div class="__toolbar">
        <toolbar-selectors
          v-if="isSearchMode"
          generate-label="toolbar.selectors.searchLabel"
          :enable-date-compare="false"
          :enable-date-range="false"
          :enable-currency="false"
          :enable-search="false"
          :enable-date-picker="false"
          :enable-group="true"
          :enable-all-locations="true"
          :enable-button="false"
          :loading="loading"
          :multiple-locations="true"
          @onLocationMultipleChange="$refs.checkDetailsFilters.fetchFiltersData()"
          @onGroupChange="$refs.checkDetailsFilters.fetchFiltersData()"
        >
          <slot name="toolbar-options"></slot>
        </toolbar-selectors>
      </div>
      <div id="main-container" class="mt-5 row justify-content-between flex-row">
        <check-details-filters
          v-if="!this.$route.params.id"
          class="col-md-2"
          ref="checkDetailsFilters"
          @filtersChanged="onFilterChanged"
          @filtersCleared="onFilterCleared"
          :disabled="loading"
          :enable-search-button="enableSearchButton"
        />
        <vel-card
          id="not-found"
          class="text-center h-100 mb-10"
          :class="this.$route.params.id ? 'col-md-12' : 'col-md-10'"
          v-if="!loading && isGenerated && invoices.length === 0"
        >
          <vel-no-results class="p-5" />
        </vel-card>
        <div
          class="h-100"
          :class="this.$route.params.id ? 'col-md-12' : 'col-md-10'"
          id="invoices-container"
          v-loading="loading"
        >
          <vel-card v-for="invoice of invoices" :key="invoice.id" class="mx-auto" style="max-width: 1000px;">
            <template>
              <div
                class="row check"
                :data-status="invoice.status == 0 ? `` : $t(`checkStatus.states[${invoice.status}]`)"
              >
                <div class="col-md-3">
                  <h4 class="mb-3" style="border-bottom: 1px solid #efefef; line-height: 1.5;">
                    {{ $t('checkDetails.checkNumber', { invoiceNumber: invoice.invoiceNumber }) }}
                  </h4>
                  <div class="d-flex flex-row align-content-between justify-content-between w-100 mt-3 py-1">
                    <b>
                      {{ $t('checkDetails.location') }}
                    </b>
                    <div class="text-right">
                      {{ invoice.location && invoice.location.reportingDisplayName || (invoice.location.name || '---') }}
                    </div>
                  </div>
                  <div class="d-flex flex-row align-content-between justify-content-between w-100 py-1">
                    <b>
                      {{ $t('checkDetails.date') }}
                    </b>
                    <div class="text-right">{{ accountingDateFormatted(invoice.openLocalTime) }}</div>
                  </div>
                  <div class="d-flex flex-row align-content-between justify-content-between w-100 py-1">
                    <b>
                      {{ $t('checkDetails.time') }}
                    </b>
                    <div class="text-right">{{ accountingTimeFormatted(invoice.openLocalTime) }}</div>
                  </div>
                  <div class="d-flex flex-row align-content-between justify-content-between w-100 py-1">
                    <b>
                      {{ $t('checkDetails.status') }}
                    </b>
                    <div class="text-right">{{ $t(`checkStatus.states[${invoice.status}]`) }}</div>
                  </div>
                  <div class="d-flex flex-row align-content-between justify-content-between w-100 py-1">
                    <b>
                      {{ $t('checkDetails.table') }}
                    </b>
                    <div class="text-right">{{ invoice.tableNumber || '--' }}</div>
                  </div>
                  <div class="d-flex flex-row align-content-between justify-content-between w-100 py-1">
                    <b>
                      {{ $t('checkDetails.customer') }}
                    </b>
                    <div class="text-right">{{ invoice.customers || '--' }}</div>
                  </div>
                  <div class="d-flex flex-row align-content-between justify-content-between w-100 py-1">
                    <b>
                      {{ $t('checkDetails.employee') }}
                    </b>
                    <div class="text-right">
                      {{ (invoice.sellingEmployee && invoice.sellingEmployee.name) || '--' }}
                    </div>
                  </div>
                  <div class="d-flex flex-row align-content-between justify-content-between w-100 py-1">
                    <b>
                      {{ $t('checkDetails.employeeNo') }}
                    </b>
                    <div class="text-right">
                      {{ (invoice.sellingEmployee && invoice.sellingEmployee.remoteId) || '--' }}
                    </div>
                  </div>
                </div>
                <div class="col-md-9 mt-4 mt-md-0" style="overflow: auto;">
                  <h4 class="mb-3" style="border-bottom: 1px solid #efefef; line-height: 1.5;">
                    {{ $t('checkDetails.details') }}
                  </h4>
                  <div class="pb-3" style="border-bottom: 1px solid #efefef; min-width: 600px;">
                    <table class="w-100">
                      <thead>
                        <tr>
                          <th style="width: 54px;">{{ $t('checkDetails.items.headers.qty') }}</th>
                          <th>{{ $t('checkDetails.items.headers.name') }}</th>
                          <th style="width: 160px;"><!--{{ $t('checkDetails.items.headers.orderTime') }}--></th>
                          <th class="text-right" style="width: 130px;">
                            {{ $t('checkDetails.items.headers.amount') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) of invoice.items" :key="index">
                          <td class="text-center">
                            {{ item.quantity }}
                          </td>
                          <td>
                            {{ item.message ? item.message : item.name }}
                          </td>
                          <td>
                            <!--{{ item.orderTimeFormatted }}-->
                          </td>
                          <td class="text-right">
                            <vel-amount :amount="item.amount" currency="$"></vel-amount>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div v-if="invoice.tip" class="pb-3" style="border-bottom: 1px solid #efefef;">
                    <table class="w-100 mt-3">
                      <thead>
                        <tr>
                          <th style="width: 54px;"></th>
                          <th>{{ $t('checkDetails.fees.headers.name') }}</th>
                          <th class="text-right" style="width: 130px;">{{ $t('checkDetails.fees.headers.amount') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center"></td>
                          <td>
                            {{ $t('checkDetails.fees.items.tip') }}
                          </td>
                          <td class="text-right">
                            <vel-amount :amount="invoice.tip" currency="$"></vel-amount>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    v-if="invoice.discounts.length"
                    class="pb-3"
                    style="border-bottom: 1px solid #efefef; min-width: 600px;"
                  >
                    <table class="w-100 mt-3">
                      <thead>
                        <tr>
                          <th style="width: 54px;"></th>
                          <th>{{ $t('checkDetails.discounts.headers.name') }}</th>
                          <th class="text-right" style="width: 130px;">
                            {{ $t('checkDetails.discounts.headers.amount') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(discount, index) of invoice.discounts" :key="index">
                          <td></td>
                          <td>
                            {{ discount.name }}
                          </td>
                          <td class="text-right">
                            <vel-amount :amount="discount.amount" currency="$"></vel-amount>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="pb-3" style="border-bottom: 1px solid #efefef;">
                    <table class="w-100 mt-3">
                      <thead>
                        <tr>
                          <th></th>
                          <th style="width: 160px;"></th>
                          <th class="text-right" style="width: 130px;"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center"></td>
                          <td>
                            <b>{{ $t('checkDetails.summary.subTotal') }}</b>
                          </td>
                          <td class="text-right">
                            <vel-amount :amount="invoice.subTotal" currency="$"></vel-amount>
                          </td>
                        </tr>
                        <tr v-for="(tax, index) of invoice.taxes" :key="index">
                          <td class="text-center"></td>
                          <td>
                            <b>{{ tax.name }}</b>
                          </td>
                          <td class="text-right">
                            <vel-amount :amount="tax.amount" currency="$"></vel-amount>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center font-weight-bolder"></td>
                          <td>
                            <b>{{ $t('checkDetails.summary.total') }}</b>
                          </td>
                          <td class="text-right font-weight-bolder" style="font-size: 1.2rem;">
                            <vel-amount :amount="invoice.total" currency="$" style="font-size: 1.2rem;"></vel-amount>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div v-if="invoice.payments.length">
                    <table class="w-100 mt-3">
                      <thead>
                        <tr>
                          <th style="width: 54px;"></th>
                          <th>{{ $t('checkDetails.payments.headers.name') }}</th>
                          <th class="text-right" style="width: 130px;">
                            {{ $t('checkDetails.payments.headers.amount') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(payment, index) of invoice.payments" :key="index">
                          <td class="text-center"></td>
                          <td>
                            {{ payment.paymodeName }}
                          </td>
                          <td class="text-right">
                            <vel-amount :amount="payment.amount" currency="$"></vel-amount>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>
          </vel-card>
        </div>
      </div>
    </b-container>
  </vel-backoffice-page>
</template>
<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import CheckDetailsFilters from './components/CheckDetailsFilters.vue';
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import LanguageMixin from '@/mixins/language-mixin';
import ToolbarSelectors from '@/private/shared/toolbar/ToolbarSelectors';
import VelAmount from '@/components/amount/VelAmount';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelNoResults from '@/components/no-results/VelNoResults';
import { getTimeFormatFromUserConfig } from '@/helpers/date.helpers';
import invoicesService from '@/services/invoices.service';
import { useDataCheckSummary } from '@/stores/data/checks/check-summary.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';

export default {
  name: 'CheckDetails',
  mixins: [DeviceMixin, LanguageMixin],
  components: {
    VelBackofficePage,
    VelAmount,
    VelButton,
    VelCard,
    ToolbarSelectors,
    VelIcon,
    VelNoResults,
    CheckDetailsFilters
  },
  computed: {
    ...mapPiniaState(useUILocationSelector, {
      locationId: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsIsLoading', 'locationsBySelectedGroup', 'getLocationsAll']),
    ...mapPiniaActions(useUIGroupSelector, { groupId: 'getGroupSelectorSelected', groups: 'groups' })
  },
  watch: {
    perPage(val) {
      window.localStorage.setItem('CheckSearchPerPage', val);
    }
  },
  data() {
    return {
      loading: false,
      invoices: [],
      filter: '',
      perPage: window.localStorage.getItem('CheckSearchPerPage') || 25,
      totalRows: 1,
      currentPage: 1,
      isSearchMode: false,
      generating: false,
      isGenerated: false,
      enableSearchButton: true,
      currentAppliedFilters: {}
    };
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    async onExportPDF() {
      this.$ga.event('report', 'download', this.$route.name);
      this.generating = true;

      if (this.isSearchMode) {
        await invoicesService
          .downloadInvoicesSearchPDF({
            ...this.$route.query,
            ...this.currentAppliedFilters,
            lang: this.getLanguage()
          })
          .catch(() => {
            this.generating = false;
          });
      } else {
        await invoicesService.downloadInvoiceByIdPDF(this.$route.params.id, { lang: this.getLanguage() }).catch(() => {
          this.generating = false;
        });
      }
      this.generating = false;
    },
    async onPerPageChange() {
      await this.searchInvoiceByNumber(this.currentPage);
    },
    async onFilterChanged() {
      this.enableSearchButton = true;
      await this.searchInvoiceByNumber(1);
    },
    onFilterCleared() {
      this.invoices = [];
      this.isGenerated = false;
    },
    async searchInvoiceByNumber(page) {
      this.invoices = [];
      try {
        this.currentAppliedFilters = this.$refs.checkDetailsFilters.getFilters();
        this.loading = true;
        this.invoices = await invoicesService
          .getInvoicesSearch({
            ...this.$route.query,
            limit: this.perPage,
            offset: ((page || 1) - 1) * this.perPage,
            order: 'desc',
            ...this.currentAppliedFilters
          })
          .then(r => {
            this.totalRows = r.total;

            return (r.content || []).map(i => {
              const discounts = [];

              if (i.invoiceDiscount) {
                discounts.push({ ...i.invoiceDiscount, amount: i.discount });
              }

              if (i.invoiceCashDiscount) {
                discounts.push({ ...i.invoiceCashDiscount, amount: i.cashDiscount });
              }

              return {
                ...i,
                discounts,
                items: (i.items || []).map(ii => ({
                  ...ii,
                  orderTimeFormatted: DateTime.fromISO(ii.orderTime).toFormat(
                    getTimeFormatFromUserConfig() === 't' ? 'tt' : 'TT'
                  )
                }))
              };
            });
          });
      } finally {
        this.loading = false;
        this.isGenerated = true;
        this.enableSearchButton = false;
      }
    },
    accountingDateFormatted(openLocalTime) {
      return DateTime.fromISO(openLocalTime).toFormat('DDDD');
    },
    accountingTimeFormatted(openLocalTime) {
      return DateTime.fromISO(openLocalTime).toFormat(getTimeFormatFromUserConfig() === 't' ? 'tt' : 'TT');
    },
    async getSingleInvoiceById(id) {
      try {
        this.loading = true;

        this.invoices = [await invoicesService.getInvoiceById(id)];

        const discounts = [];

        if (this.invoices[0].invoiceDiscount) {
          discounts.push({ ...this.invoices[0].invoiceDiscount, amount: this.invoices[0].discount });
        }

        if (this.invoices[0].invoiceCashDiscount) {
          discounts.push({ ...this.invoices[0].invoiceCashDiscount, amount: this.invoices[0].cashDiscount });
        }

        this.invoices[0].discounts = discounts;
        this.invoices[0].items = (this.invoices[0].items || []).map(ii => ({
          ...ii,
          orderTimeFormatted: DateTime.fromISO(ii.orderTime).toFormat(
            getTimeFormatFromUserConfig() === 't' ? 'tt' : 'TT'
          )
        }));
      } finally {
        this.loading = false;
        this.isGenerated = true;
        this.enableSearchButton = false;
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getSingleInvoiceById(this.$route.params.id);
    } else {
      this.isSearchMode = true;
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'checksSummary') {
      useDataCheckSummary().$reset();
    }

    next();
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.bootstrap .container-fluid {
  padding-left: 24px;
  padding-right: 24px;
}

/deep/ .vel-page {
  background-color: #f5f5f5;

  &__content {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

/deep/ .__toolbar > div > div {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoint(medium) {
    flex-direction: row;

    & > :not(:first-child) {
      margin-left: rem-calc(4);
    }

    & > :not(:last-child) {
      margin-right: rem-calc(4);
    }
  }
}

/deep/ .tooltip-inner {
  background-color: #fff;
  border: 1px solid #6f6f6f;
  border-radius: 0.25rem;
  color: #666;
  font-size: 0.8rem;
  max-width: 150px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}

/deep/ {
  .input-group > .form-control {
    border-left-color: #fff;
    height: calc(1.3em + 1rem + 4px);
  }

  .input-group-text {
    background-color: #fff !important;
    border-right: 1px solid #fff !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .input-group-prepend {
    height: calc(1.3em + 1rem + 4px);
  }
}
#table-container {
  display: flex;
  flex-direction: column;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

#main-container {
  flex: 1;
  overflow: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

#invoices-container {
  overflow-y: scroll;
}

.vel-button {
  @include noPrint;
  background-color: #c40b0a;
  border-color: #c40b0a;
  direction: rtl;

  &:hover {
    background-color: mix(black, #c40b0a, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  &.vel-button_loading {
    border-color: unset;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

/deep/ #not-found .vel-card__body {
  height: 100%;
}

.check {
  z-index: 1;
  position: relative;
}

.check::before {
  content: attr(data-status);
  text-transform: uppercase;
  z-index: 0;
  font-weight: 900;
  font-size: 5em;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0.1;
  transform: rotateZ(-30deg);
}
</style>
