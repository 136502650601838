<template>
  <portal to="app">
    <div class="group-add-modal">
      <vel-modal dismissable @dismiss="handleCancel">
        <template v-slot:header>
          {{ $t('location.groups.modals.add.title') }}
        </template>

        <template v-slot:default>
          <vel-alert @dismiss="clearAddLocationToGroupError" class="alert" level="error" v-if="addLocationToGroupError">
            {{ $t('location.errors.addLocationToGroup') }}
          </vel-alert>

          <add-group-form
            class="group-add-modal__form"
            :groupsOptions="groupsOptions"
            :allowMultipleGroups="false"
            :isSavingGroups="isSavingGroups"
            @submit="handleSubmit"
            @cancel="handleCancel"
          ></add-group-form>
        </template>
      </vel-modal>
    </div>
  </portal>
</template>

<script>
import AddGroupForm from '../form/AddGroupForm.vue';
import VelAlert from '@/components/alert/VelAlert';
import VelModal from '@/components/modal/VelModal';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataLocationsGroupsLocation} from "@/stores/data/locations/groups-location";

export default {
  name: 'add-group-modal',
  components: {
    AddGroupForm,
    VelAlert,
    VelModal
  },
  data() {
    return {
      group: {}
    };
  },
  props: {
    locationId: {
      type: String,
      required: true
    },
    groupsOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapPiniaState(useDataLocationsGroupsLocation, [
      'addLocationToGroupError',
      'latestAddedLocationToGroup',
      'isSavingGroups'
    ])
  },
  watch: {
    latestAddedLocationToGroup(addedLocationGroups) {
      if (addedLocationGroups) {
        this.$emit('dismiss');
      }
    }
  },
  methods: {
    ...mapPiniaActions(useDataLocationsGroupsLocation, [
      'addLocationToGroup',
      'clearLatestAddedLocationToGroup',
      'clearAddLocationToGroupError'
    ]),
    handleCancel() {
      this.$emit('dismiss');
    },
    handleSubmit(groupId) {
      this.addLocationToGroup({ locationId: this.locationId, groupId });
    }
  },
  created() {
    this.clearLatestAddedLocationToGroup();
    this.clearAddLocationToGroupError();
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/deep/ .group-form__group .el-select {
  width: 100%;
}

.group-add-modal__form {
  margin-top: rem-calc(16);
}
</style>
