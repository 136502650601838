var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.exportable || _vm.filterable
      ? _c(
          "div",
          { staticClass: "vel-table-action" },
          [
            _c(
              "vel-row",
              { attrs: { gutter: 10 } },
              [
                _vm.filterable
                  ? _c(
                      "vel-col",
                      { attrs: { span: 16 } },
                      [
                        _c("vel-table-filter", {
                          attrs: { placeholder: _vm.filterPlaceholder },
                          model: {
                            value: _vm.inputFilter,
                            callback: function($$v) {
                              _vm.inputFilter = $$v
                            },
                            expression: "inputFilter"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showRowsLength
                  ? _c(
                      "vel-col",
                      {
                        attrs: {
                          span: _vm.exportable && _vm.filterable ? 4 : 8
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vel-table-action__rows-length" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vel-table-action__rows-length__value"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.rows.length + " / " + _vm.initialLength
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.exportable
                  ? _c(
                      "vel-col",
                      {
                        attrs: {
                          span: _vm.filterable && _vm.showRowsLength ? 4 : 8
                        }
                      },
                      [
                        _c("vel-table-export", {
                          staticClass: "vel-table-action__export",
                          attrs: {
                            columns: _vm.exportableColumns,
                            rows: _vm.exportableRows,
                            metas: _vm.exportableMetas,
                            "external-export": _vm.externalExport
                          },
                          on: {
                            exportXls: function($event) {
                              return _vm.$emit("exportXls")
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "vel-table-wrapper" }, [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "vel-table__loader" },
            [_c("vel-spinner", { staticClass: "vel-table__spinner" })],
            1
          )
        : _vm._e(),
      _c(
        "table",
        {
          staticClass: "vel-table",
          class: _vm.classes,
          attrs: {
            "aria-busy": _vm.ariaBusy,
            "aria-colcount": this.computedColumns.length
          }
        },
        [
          _c("vel-table-header", {
            staticClass: "vel-table__header",
            attrs: {
              columns: _vm.computedColumns,
              bordered: _vm.bordered,
              sortBy: _vm.sortBy,
              direction: _vm.direction
            },
            on: { click: _vm.handleColumnClick }
          }),
          _c(
            "tbody",
            { staticClass: "vel-table__body", attrs: { role: "rowgroup" } },
            [
              _vm.isEmpty
                ? _c(
                    "tr",
                    { staticClass: "vel-table__row", attrs: { role: "row" } },
                    [
                      _c(
                        "td",
                        {
                          staticClass: "vel-table__cell vel-table__cell_empty",
                          attrs: {
                            role: "cell",
                            colspan: this.computedColumns.length
                          }
                        },
                        [
                          _vm._t("empty", function() {
                            return [_vm._v(_vm._s(_vm.defaultEmptyMessage))]
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _vm._l(_vm.computedRows, function(row, rindex) {
                    return _c(
                      "tr",
                      {
                        key: rindex,
                        staticClass: "vel-table__row",
                        on: {
                          click: function($event) {
                            _vm.handleRowClick(_vm.originalRow(row))
                          }
                        }
                      },
                      _vm._l(_vm.filterRow(row), function(cell, cindex) {
                        return _c(
                          "td",
                          {
                            key: cindex,
                            staticClass: "vel-table__cell",
                            class: cell.class,
                            attrs: { role: "cell", "aria-colindex": cindex + 1 }
                          },
                          [
                            _vm._t(
                              cell.key + "-column",
                              function() {
                                return [_vm._v(" " + _vm._s(cell.value) + " ")]
                              },
                              {
                                cell: cell,
                                cellIndex: cindex,
                                row: _vm.originalRow(row),
                                rowIndex: row.__$index
                              }
                            )
                          ],
                          2
                        )
                      }),
                      0
                    )
                  }),
              _vm.showSummary && _vm.rows.length
                ? _c(
                    "tr",
                    { staticClass: "vel-table__row vel-table__total" },
                    _vm._l(_vm.visibleColumns, function(col, i) {
                      return _c(
                        "td",
                        {
                          key: i,
                          staticClass: "vel-table__cell",
                          attrs: { role: "cell" }
                        },
                        [_vm._v(" " + _vm._s(_vm.getSummaries(col)) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }