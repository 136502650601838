var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "notfound" },
    [
      _c("h1", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "notfound.title",
            expression: "'notfound.title'"
          }
        ],
        staticClass: "notfound__title"
      }),
      _c("p", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "notfound.text",
            expression: "'notfound.text'"
          }
        ],
        staticClass: "notfound__text"
      }),
      _c("vel-link", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "notfound.return",
            expression: "'notfound.return'"
          }
        ],
        staticClass: "notfound__back-button",
        attrs: { to: { name: "home" } }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }