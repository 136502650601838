var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "partners-list" },
    [
      _c("vel-table", {
        staticClass: "items-list",
        attrs: {
          rows: _vm.data,
          columns: _vm.columns,
          filterable: "",
          filterValue: _vm.filterValue,
          filterPlaceholder: _vm.$t("defaults.placeholder.filter"),
          showRowsLength: true,
          initialLength: _vm.initialLength
        },
        on: { "filter-change": _vm.filterCallback },
        scopedSlots: _vm._u([
          {
            key: "actions-column",
            fn: function(scope) {
              return [
                _c("vel-button", {
                  attrs: { type: "link", icon: "edit" },
                  on: {
                    click: function($event) {
                      return _vm.handleGoToPartners(scope.row.id)
                    }
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }