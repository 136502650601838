var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vel-avatar",
      class: _vm.classes,
      attrs: { "aria-hidden": "true" }
    },
    [
      _vm.src
        ? _c("img", {
            staticClass: "vel-avatar__picture",
            attrs: { src: _vm.src, alt: _vm.fullname }
          })
        : _c("div", {
            staticClass: "vel-avatar__identicon",
            domProps: { innerHTML: _vm._s(_vm.identicon) }
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }