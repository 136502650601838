import { render, staticRenderFns } from "./AddLocationForm.vue?vue&type=template&id=06ee3000&scoped=true&"
import script from "./AddLocationForm.vue?vue&type=script&lang=js&"
export * from "./AddLocationForm.vue?vue&type=script&lang=js&"
import style0 from "./AddLocationForm.vue?vue&type=style&index=0&id=06ee3000&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ee3000",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/runner/work/velweb/velweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06ee3000')) {
      api.createRecord('06ee3000', component.options)
    } else {
      api.reload('06ee3000', component.options)
    }
    module.hot.accept("./AddLocationForm.vue?vue&type=template&id=06ee3000&scoped=true&", function () {
      api.rerender('06ee3000', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/private/groups/components/group-form/AddLocationForm.vue"
export default component.exports