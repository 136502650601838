var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "users-commulative__chart"
    },
    [
      _c("h5", [_vm._v(_vm._s(_vm.$t("analytics.grossSalesPerMonth.title")))]),
      !_vm.loading
        ? _c(
            "div",
            [
              !_vm.loading
                ? _c("apexchart", {
                    attrs: {
                      type: "area",
                      height: "330",
                      options: _vm.chartOptions,
                      series: _vm.series
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }