<template>
  <div class="status" :class="{ online: this.status === 0, warning: this.status === 1, offline: this.status === 2 }">
    <div class="bubble"></div>
    <div>{{ $t(`locationState.states[${this.status}]`) }}</div>
  </div>
</template>

<script>
export default {
  name: 'LocationState',
  props: {
    status: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.bubble {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.offline {
  color: #EF4244;

  .bubble {
    background-color: #EF4244;
  }
}

.online {
  color:#36D53D;

  .bubble {
    background-color: #36D53D;
  }
}

.warning {
  color: #EEAB09;

  .bubble {
    background-color: #EEAB09;
  }
}
</style>
