var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      ref: "sidebarAccessTypeForm",
      attrs: {
        "header-class": "bg-light",
        "bg-variant": "white",
        width: "1400px",
        right: "",
        id: "accessTypeForm-sidebar",
        title: "Sidebar",
        shadow: "",
        backdrop: ""
      },
      on: {
        hidden: function($event) {
          _vm.accessType = null
          _vm.$refs.form.reset()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "h6",
                { staticClass: "bg-gray-200 bg-light w-100 mb-0 text-center" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          _vm.accessType && _vm.accessType.id
                            ? "employeeCreate.accessType.titleEdit"
                            : "employeeCreate.accessType.title"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2",
                  staticStyle: { "border-top": "2px solid #d6d6d8" }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              !(
                                _vm.$refs.form && _vm.$refs.form.enableContinue
                              ) || _vm.$refs.form.selectedTab === 5,
                            variant: "primary",
                            f: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.$refs.form.selectedTab++
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("employeeCreate.btns.continue")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-3",
                          attrs: {
                            disabled: !(
                              _vm.$refs.form && _vm.$refs.form.enableSaveChanges
                            ),
                            variant: "outline-primary",
                            f: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.updateAccessType()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  _vm.accessType && _vm.accessType.id
                                    ? "employeeCreate.btns.saveChanges"
                                    : "employeeCreate.btns.save"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary", f: "" },
                      on: {
                        click: function($event) {
                          hide()
                          _vm.$refs.form.reset(null)
                          _vm.$emit("update:accessType", null)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("employeeCreate.btns.cancel")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "b-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pending,
              expression: "pending"
            }
          ],
          attrs: { fluid: "" }
        },
        [
          _c("access-type-form", {
            ref: "form",
            attrs: {
              licenceNumber: this.employee.licenceNumber || this.licenceNumber,
              "job-roles": _vm.jobRoles,
              "tables-sections": _vm.tablesSections,
              "key-groups": _vm.keyGroups,
              modes: _vm.modes,
              "requisition-indexes": _vm.requisitionIndexes,
              "access-type": _vm.accessType
            },
            on: {
              "update:accessType": function($event) {
                _vm.accessType = $event
              },
              "update:access-type": function($event) {
                _vm.accessType = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }