var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portal", { attrs: { to: "app" } }, [
    _c(
      "div",
      { staticClass: "coupon-add-modal" },
      [
        _c("vel-modal", {
          attrs: { dismissable: "" },
          on: { dismiss: _vm.handleCancel },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _vm._v(" " + _vm._s(_vm.$t("coupons.modals.add.title")) + " ")
                ]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [
                  _vm.hasCreateCouponError
                    ? _c(
                        "vel-alert",
                        {
                          staticClass: "alert",
                          attrs: { level: "error" },
                          on: { dismiss: _vm.clearCreateCouponError }
                        },
                        [
                          _vm.hasCreateConflictError
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "coupons.errors.createConflictHtml",
                                      { errorText: _vm.createCouponError.text }
                                    )
                                  )
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("coupons.errors.createCoupon"))
                                )
                              ])
                        ]
                      )
                    : _vm._e(),
                  _c("coupon-form", {
                    staticClass: "coupon-add-modal__form",
                    attrs: {
                      coupon: _vm.coupon,
                      currentGroupId: _vm.currentGroupId
                    },
                    on: { submit: _vm.handleSubmit, cancel: _vm.handleCancel }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }