var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm.failed
        ? _c("vel-page-reloader", { staticClass: "vel-page__reloader" })
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "vel-page__loader" },
            [_c("vel-page-spinner", { staticClass: "vel-page__spinner" })],
            1
          )
        : _vm._e(),
      !_vm.loading && _vm.$slots.customizer
        ? _c(
            "vel-modal",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showConfig,
                  expression: "showConfig"
                }
              ],
              staticClass: "configModal",
              attrs: { loading: _vm.getPending, dismissable: "" },
              on: {
                dismiss: function($event) {
                  _vm.showConfig = false
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$t("editors.customize_link")) + " "
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1723931544
              )
            },
            [_vm._t("customizer")],
            2
          )
        : _vm._e(),
      _c(
        "section",
        { staticClass: "vel-page", class: _vm.classes },
        [
          _c(
            "header",
            { staticClass: "vel-page__header" },
            [
              _c(
                "h1",
                {
                  staticClass:
                    "vel-page__title w-100 d-flex justify-content-between"
                },
                [
                  _vm.$slots.title
                    ? _vm._t("title")
                    : [
                        _vm._v(" " + _vm._s(_vm.title) + " "),
                        _vm.showStickyToolbar
                          ? _c(
                              "el-popover",
                              {
                                directives: [
                                  {
                                    name: "can",
                                    rawName: "v-can:show",
                                    value: "enable_period_selector",
                                    expression: "'enable_period_selector'",
                                    arg: "show"
                                  }
                                ],
                                attrs: {
                                  placement: "bottom",
                                  trigger: _vm.isMediumUp ? "hover" : "click"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "toolbar" },
                                  [
                                    _c("toolbar-selectors", {
                                      attrs: {
                                        "enable-date-range":
                                          _vm.enableDateRange,
                                        "enable-all-currencies":
                                          _vm.enableAllCurrencies,
                                        "enable-group": _vm.enableGroups,
                                        "enable-currency": _vm.enableCurrency,
                                        "enable-date-picker":
                                          _vm.enableDatePicker,
                                        "enable-all-locations":
                                          _vm.enableAllLocations,
                                        "enable-button": _vm.showSelectorButton,
                                        "enable-button-on-single-selection":
                                          _vm.showSelectorSingleLocationButton,
                                        loading: _vm.loading
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("vel-icon", {
                                  attrs: {
                                    slot: "reference",
                                    size: _vm.computedIconSize,
                                    name: "cog"
                                  },
                                  slot: "reference"
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                ],
                2
              ),
              _c(
                "div",
                {
                  class: { invisible: !_vm.isGenerated && _vm.generation },
                  staticStyle: { "margin-left": "auto" }
                },
                [_vm._t("export")],
                2
              ),
              _vm.$slots.customizer
                ? _c(
                    "vel-elink",
                    {
                      attrs: { type: "info" },
                      on: {
                        click: function($event) {
                          _vm.showConfig = true
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { title: _vm.$t("editors.customize_link") } },
                        [
                          _c("vel-icon", {
                            staticStyle: { width: "24px", height: "24px" },
                            attrs: { name: "dashboard_customize" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.showCrumbs
            ? _c("vel-breadcrumb", { staticClass: "vel-page__breadcrumb" })
            : _vm._e(),
          !_vm.loading
            ? _c(
                "div",
                {
                  ref: "page",
                  staticClass: "vel-page__content",
                  on: {
                    "&scroll": function($event) {
                      return _vm.handleDebouncedScroll.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.$slots.alert
                    ? _c(
                        "div",
                        { staticClass: "vel-page__alert" },
                        [_vm._t("alert")],
                        2
                      )
                    : _vm._e(),
                  _vm.enableToolbar
                    ? _c(
                        "div",
                        { ref: "toolbar", staticClass: "vel-page__toolbar" },
                        [
                          _vm.$slots.toolbar
                            ? _vm._t("toolbar")
                            : _c(
                                "toolbar-selectors",
                                {
                                  attrs: {
                                    "enable-date-compare": _vm.showDateCompare,
                                    "enable-all-currencies":
                                      _vm.enableAllCurrencies,
                                    "enable-date-range": _vm.enableDateRange,
                                    "enable-datetime-range":
                                      _vm.enableDatetimeRange,
                                    "enable-currency": _vm.enableCurrency,
                                    "enable-date-picker": _vm.enableDatePicker,
                                    "enable-group": _vm.enableGroups,
                                    "enable-all-locations":
                                      _vm.enableAllLocations,
                                    "enable-button": _vm.showSelectorButton,
                                    "enable-button-on-single-selection":
                                      _vm.showSelectorSingleLocationButton,
                                    loading: _vm.loading,
                                    "multiple-locations": _vm.multipleLocations,
                                    showLabels: _vm.showToolbarLabels
                                  }
                                },
                                [_vm._t("toolbar-options")],
                                2
                              )
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.showSelectorButton || _vm.isGenerated
                    ? _vm._t("default")
                    : _vm._e(),
                  !_vm.showSelectorButton || _vm.isGenerated
                    ? _c("div", { staticClass: "footer" }, [
                        _vm.generatedTime
                          ? _c(
                              "div",
                              { staticClass: "vel-page__generated-time" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("defaults.page.generatedTime", {
                                        date: _vm.dateFormat(_vm.generatedTime)
                                      })
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm.$slots.footer && !_vm.loading
            ? _c("footer", [_vm._t("footer")], 2)
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }