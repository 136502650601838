var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-reset" },
    [
      _vm.isMediumUp
        ? [
            _c("vel-button", {
              staticClass: "user-reset__button",
              attrs: { type: "link", icon: "lock-reset" },
              on: { click: _vm.handleModalButtonClick }
            })
          ]
        : [
            _c(
              "vel-button",
              {
                staticClass: "user-reset__button",
                attrs: { type: "secondary", icon: "lock-reset" },
                on: { click: _vm.handleModalButtonClick }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("resetPassword.modal.resetPassword.button.text")
                    ) +
                    " "
                )
              ]
            )
          ],
      _c(
        "portal",
        { attrs: { to: "app" } },
        [
          _vm.isModalDisplayed
            ? _c("vel-modal", {
                staticClass: "user-reset__modal",
                attrs: { dismissable: "" },
                on: { dismiss: _vm.handleModalDismiss },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "resetPassword.modal.resetPassword.title"
                                )
                              ) +
                              " "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _vm.passwordResetUrl
                            ? [
                                _c("p", {
                                  staticClass: "user-reset__url",
                                  domProps: {
                                    textContent: _vm._s(_vm.passwordResetUrl)
                                  }
                                })
                              ]
                            : [
                                _c("span", {
                                  staticClass: "user-reset__confirmation",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "resetPassword.modal.resetPassword.textHtml",
                                        { email: _vm.user.email }
                                      )
                                    )
                                  }
                                })
                              ]
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "footer",
                      fn: function() {
                        return [
                          _c(
                            "vel-button",
                            {
                              staticClass: "user-reset__close-button",
                              attrs: { type: "secondary" },
                              on: { click: _vm.handleModalDismiss }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("defaults.actions.close")) +
                                  " "
                              )
                            ]
                          ),
                          !_vm.passwordResetUrl
                            ? _c(
                                "vel-button",
                                {
                                  staticClass: "user-reset__reset-button",
                                  attrs: { loading: _vm.status.isLoading },
                                  on: { click: _vm.handleResetButtonClick }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "resetPassword.modal.resetPassword.button.text"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  311488456
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }