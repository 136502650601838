var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4 mt-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center mb-2 mb-md-0 pb-4",
              attrs: { cols: "12", md: "4" }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("timeAttendances.description.label")) + " "
              )
            ]
          ),
          _c(
            "b-col",
            [
              _c("b-form-input", {
                class: { required: !_vm.form.name || _vm.form.name === "" },
                attrs: {
                  id: "name",
                  state: !_vm.enableSaveChanges
                    ? null
                    : _vm.form.name !== "" && null,
                  placeholder: _vm.$t(
                    "timeAttendances.description.placeholder"
                  ),
                  "aria-describedby": "passwd-feedback"
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              }),
              _c("b-form-text", { attrs: { id: "input-live-help" } }, [
                _vm._v(_vm._s(_vm.$t("employeeCreate.required")))
              ]),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "passwd-feedback" } },
                [_vm._v(" " + _vm._s(_vm.$t("employeeCreate.required")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4 mt-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center mb-2 mb-md-0",
              attrs: { cols: "12", md: "4" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("timeAttendances.code.label")) + " ")]
          ),
          _c(
            "b-col",
            [
              _c("b-form-input", {
                attrs: {
                  id: "code",
                  placeholder: _vm.$t("timeAttendances.code.placeholder")
                },
                model: {
                  value: _vm.form.code,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "code", $$v)
                  },
                  expression: "form.code"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4 mt-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center mb-2 mb-md-0",
              attrs: { cols: "12", md: "4" }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("timeAttendances.payroll.label")) + " "
              )
            ]
          ),
          _c(
            "b-col",
            [
              _c("b-form-input", {
                attrs: {
                  id: "payrollNumber",
                  type: "text",
                  placeholder: _vm.$t("timeAttendances.payroll.placeholder")
                },
                model: {
                  value: _vm.form.payrollNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "payrollNumber", $$v)
                  },
                  expression: "form.payrollNumber"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }