var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-tooltip",
    { staticClass: "account-tooltip", class: _vm.classes },
    [
      _c("header", { staticClass: "account-tooltip__header" }, [
        _c("h5", { staticClass: "account-tooltip__title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("topbar.accountTooltip.title", {
                  firstname: _vm.firstname
                })
              ) +
              " "
          )
        ])
      ]),
      _c("ul", { staticClass: "account-tooltip__actions" }, [
        _c("li", { staticClass: "action-tooltip__action" }, [
          _c(
            "button",
            {
              staticClass: "account-tooltip__action-button",
              on: { click: _vm.toggleLanguage }
            },
            [
              _c("vel-icon", {
                staticClass: "account-tooltip__action-icon",
                attrs: { name: "hearth" }
              }),
              _c("span", { staticClass: "account-tooltip__action-text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("topbar.accountTooltip.actions.switchLanguage")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ]),
        _c(
          "li",
          { staticClass: "account-tooltip__action" },
          [
            _c(
              "router-link",
              {
                staticClass: "account-tooltip__action-link",
                attrs: { to: { name: "change-password" } }
              },
              [
                _c("vel-icon", {
                  staticClass: "account-tooltip__action-icon",
                  attrs: { name: "password-login" }
                }),
                _c("span", { staticClass: "account-tooltip__action-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("topbar.accountTooltip.actions.changePassword")
                      ) +
                      " "
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "account-tooltip__action" },
          [
            _c(
              "router-link",
              {
                staticClass: "account-tooltip__action-link",
                attrs: { to: { name: "logout" } }
              },
              [
                _c("vel-icon", {
                  staticClass: "account-tooltip__action-icon",
                  attrs: { name: "logout" }
                }),
                _c("span", { staticClass: "account-tooltip__action-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("topbar.accountTooltip.actions.logout")) +
                      " "
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }