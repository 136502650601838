var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-table", {
    staticClass: "sales-detailed",
    class: _vm.isLocationUnique ? "unique-location" : "",
    attrs: {
      defaultDirection: _vm.defaultDirection,
      defaultSort: _vm.defaultSort,
      rows: _vm.rows,
      columns: _vm.columns,
      loading: _vm.loading,
      hoverable: _vm.hoverable,
      exportable: true,
      metas: _vm.exportableMetas
    },
    on: { row: _vm.handleRow },
    scopedSlots: _vm._u([
      {
        key: "names-column",
        fn: function(scope) {
          return [
            _c("span", { staticClass: "item-name" }, [
              _vm._v(_vm._s(scope.row.itemName))
            ]),
            _c("span", { staticClass: "division-name" }, [
              _vm._v(_vm._s(scope.row.divisionName))
            ]),
            _c("span", { staticClass: "big-division-name" }, [
              _vm._v(_vm._s(scope.row.bigDivisionName))
            ]),
            !_vm.isLocationUnique
              ? _c("span", { staticClass: "location-name" }, [
                  _vm._v(_vm._s(scope.row.locationName))
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "salesAmount-column",
        fn: function(scope) {
          return [
            _c("vel-amount", {
              attrs: {
                amount: scope.row.salesAmount,
                currency: scope.row.currency
              }
            })
          ]
        }
      },
      {
        key: "empty",
        fn: function() {
          return [
            _vm._v(" " + _vm._s(_vm.$t("sales.table.messages.noData")) + " ")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }