var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "couponForm",
      staticClass: "coupon-form",
      attrs: {
        model: _vm.couponForm,
        rules: _vm.couponFormRules,
        "label-width": "35%"
      }
    },
    [
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "code",
            label: _vm.$t("coupons.form.code.placeholder")
          }
        },
        [
          _c("vel-input", {
            staticClass: "code-input",
            attrs: { autofocus: "", type: "text", disabled: _vm.isEditMode },
            model: {
              value: _vm.couponForm.code,
              callback: function($$v) {
                _vm.$set(_vm.couponForm, "code", $$v)
              },
              expression: "couponForm.code"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "name",
            label: _vm.$t("coupons.form.name.placeholder")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { type: "text" },
            model: {
              value: _vm.couponForm.name,
              callback: function($$v) {
                _vm.$set(_vm.couponForm, "name", $$v)
              },
              expression: "couponForm.name"
            }
          })
        ],
        1
      ),
      !_vm.isGroupSelectorHidden
        ? _c(
            "vel-form-item",
            {
              staticClass: "group-input",
              attrs: {
                prop: "group",
                label: _vm.$t("coupons.form.group.placeholder")
              }
            },
            [
              _c(
                "vel-select",
                {
                  attrs: {
                    placeholder: _vm.$t("coupons.form.group.placeholder")
                  },
                  model: {
                    value: _vm.couponForm.groupId,
                    callback: function($$v) {
                      _vm.$set(_vm.couponForm, "groupId", $$v)
                    },
                    expression: "couponForm.groupId"
                  }
                },
                _vm._l(_vm.groups, function(group) {
                  return _c("vel-option", {
                    key: group.value,
                    attrs: { label: group.label, value: group.value }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "vel-form-item",
        {
          staticClass: "expires-input",
          attrs: {
            prop: "expires",
            label: _vm.$t("coupons.form.expires.placeholder")
          }
        },
        [
          _c("vel-datepicker", {
            attrs: {
              align: "center",
              type: "date",
              clearable: false,
              format: _vm.getDateFormat()
            },
            model: {
              value: _vm.couponForm.expires,
              callback: function($$v) {
                _vm.$set(_vm.couponForm, "expires", $$v)
              },
              expression: "couponForm.expires"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "group-input",
          attrs: {
            prop: "couponType",
            label: _vm.$t("coupons.form.type.label")
          }
        },
        [
          _c(
            "vel-select",
            {
              attrs: { placeholder: _vm.$t("coupons.form.type.placeholder") },
              model: {
                value: _vm.couponForm.couponType,
                callback: function($$v) {
                  _vm.$set(_vm.couponForm, "couponType", $$v)
                },
                expression: "couponForm.couponType"
              }
            },
            _vm._l(2, function(i) {
              return _c("vel-option", {
                key: i,
                attrs: {
                  label: _vm.$t("coupons.form.type.values[" + (i - 1) + "]"),
                  value: i - 1
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "isActive",
            label: _vm.$t("coupons.form.isActive.placeholder")
          }
        },
        [
          _c("vel-switch", {
            staticClass: "isActive-input",
            model: {
              value: _vm.couponForm.isActive,
              callback: function($$v) {
                _vm.$set(_vm.couponForm, "isActive", $$v)
              },
              expression: "couponForm.isActive"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "plu1",
            label: _vm.$t("coupons.form.plu1.placeholder")
          }
        },
        [
          _c("vel-input", {
            staticClass: "plu1-input",
            attrs: { type: "text" },
            model: {
              value: _vm.couponForm.plu1,
              callback: function($$v) {
                _vm.$set(_vm.couponForm, "plu1", $$v)
              },
              expression: "couponForm.plu1"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "plu1Amount",
            label: _vm.$t("coupons.form.plu1Amount.placeholder")
          }
        },
        [
          _c("vel-input", {
            staticClass: "plu1Amount-input",
            model: {
              value: _vm.couponForm.plu1Amount,
              callback: function($$v) {
                _vm.$set(_vm.couponForm, "plu1Amount", $$v)
              },
              expression: "couponForm.plu1Amount"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "plu2",
            label: _vm.$t("coupons.form.plu2.placeholder")
          }
        },
        [
          _c("vel-input", {
            staticClass: "plu2-input",
            attrs: { type: "text" },
            model: {
              value: _vm.couponForm.plu2,
              callback: function($$v) {
                _vm.$set(_vm.couponForm, "plu2", $$v)
              },
              expression: "couponForm.plu2"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          attrs: {
            prop: "plu2Amount",
            label: _vm.$t("coupons.form.plu2Amount.placeholder")
          }
        },
        [
          _c("vel-input", {
            staticClass: "plu2Amount-input",
            model: {
              value: _vm.couponForm.plu2Amount,
              callback: function($$v) {
                _vm.$set(_vm.couponForm, "plu2Amount", $$v)
              },
              expression: "couponForm.plu2Amount"
            }
          })
        ],
        1
      ),
      _vm.isEditMode && _vm.coupon.couponType == 1
        ? _c(
            "vel-form-item",
            {
              staticClass: "coupon-form__redeemed",
              attrs: {
                prop: "redeemed",
                label: _vm.$t("coupons.form.redeemed.label")
              }
            },
            [
              _c("vel-switch", {
                staticClass: "redeemed-switch",
                attrs: { disabled: _vm.coupon.redeemed === null },
                model: {
                  value: _vm.isRedeemCanceled,
                  callback: function($$v) {
                    _vm.isRedeemCanceled = $$v
                  },
                  expression: "isRedeemCanceled"
                }
              }),
              _vm.isRedeemCanceled
                ? _c("vel-input", {
                    staticClass: "redeemed-input",
                    attrs: {
                      value: _vm.formatDateTime(_vm.coupon.redeemed),
                      readonly: "readonly"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.coupon.redeemCanceled
        ? _c("div", { staticClass: "coupon-form__additional-message" }, [
            _vm._v(" " + _vm._s(_vm.redeemCanceledByMessage) + " ")
          ])
        : _vm._e(),
      _vm.coupon.redeemFromLocation
        ? _c("div", { staticClass: "coupon-form__additional-message" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("coupons.form.redeemedFromLocation", {
                    location: _vm.coupon.redeemFromLocation.name
                  })
                ) +
                " "
            )
          ])
        : _vm._e(),
      _c(
        "vel-form-item",
        { staticClass: "coupon-form__actions" },
        [
          _c(
            "vel-button",
            {
              staticClass: "actions__submit",
              attrs: { type: "primary", loading: _vm.isLoading },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
          ),
          _c(
            "vel-button",
            {
              staticClass: "actions__cancel",
              attrs: { type: "secondary", disabled: _vm.isLoading },
              on: {
                click: function($event) {
                  return _vm.handleCancel()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }