var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "groups",
      attrs: { loading: _vm.isLoading, "enable-toolbar": true },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("pages.groups")) + " ")]
          },
          proxy: true
        },
        {
          key: "alert",
          fn: function() {
            return [
              _vm.hasGetGroupsError
                ? _c(
                    "vel-alert",
                    {
                      staticClass: "alert",
                      attrs: { level: "error" },
                      on: { dismiss: _vm.clearGetGroupsError }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("groups.errors.getGroups")) + " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.latestCreatedGroup
                ? _c(
                    "vel-alert",
                    {
                      staticClass: "alert",
                      attrs: { level: "success" },
                      on: { dismiss: _vm.clearLatestCreatedGroup }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("groups.form.success.createGroup", {
                              name: _vm.latestCreatedGroup.name
                            })
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.latestUpdatedGroup
                ? _c(
                    "vel-alert",
                    {
                      staticClass: "alert",
                      attrs: { level: "success" },
                      on: { dismiss: _vm.clearLatestUpdatedGroup }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("groups.form.success.updateGroup", {
                              name: _vm.latestUpdatedGroup.name
                            })
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.latestDeletedGroup
                ? _c(
                    "vel-alert",
                    {
                      staticClass: "alert",
                      attrs: { level: "success" },
                      on: { dismiss: _vm.clearLatestDeletedGroup }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("groups.form.success.deleteGroup", {
                              name: _vm.latestDeletedGroup.name
                            })
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "toolbar",
          fn: function() {
            return [
              _c(
                "vel-button",
                {
                  staticClass: "groups__action-add",
                  attrs: { disabled: _vm.areActionsDisabled, type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleAddGroup()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("groups.actions.create")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "group__modals" },
        [
          _vm.showAddGroup
            ? _c("add-group-modal", {
                staticClass: "modals__add",
                on: {
                  dismiss: _vm.handleAddGroupModalDismiss,
                  success: _vm.handleAddGroupModalSuccess
                }
              })
            : _vm._e(),
          _vm.showEditGroup && _vm.selectedGroup
            ? _c("edit-group-modal", {
                staticClass: "modals__edit",
                attrs: { group: _vm.selectedGroup },
                on: {
                  dismiss: _vm.handleEditGroupModalDismiss,
                  success: _vm.handleEditGroupModalSuccess
                }
              })
            : _vm._e(),
          _vm.showDeleteGroup && _vm.selectedGroup
            ? _c("delete-group-modal", {
                staticClass: "modals__edit",
                attrs: { group: _vm.selectedGroup },
                on: {
                  dismiss: _vm.handleDeleteGroupModalDismiss,
                  success: _vm.handleDeleteGroupModalSuccess
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isLargeUp
        ? [
            _c(
              "vel-card",
              { staticClass: "groups__table" },
              [
                _c("vel-table", {
                  attrs: {
                    rows: _vm.rows,
                    columns: _vm.columns,
                    hoverable: false,
                    exportable: true,
                    exportableMetas: _vm.exportableMetas,
                    filterValue: _vm.filter,
                    filterable: true,
                    filterPlaceholder: _vm.$t("defaults.placeholder.filter"),
                    showRowsLength: true,
                    initialLength: _vm.getGroupsAmount
                  },
                  on: { "filter-change": _vm.filterCallback },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "name-column",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "groups__name" },
                              [
                                _c("vel-avatar", {
                                  staticClass: "groups__name-avatar",
                                  attrs: { fullname: scope.cell.value }
                                }),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "groups__name-text",
                                    attrs: {
                                      to: {
                                        name: "group",
                                        params: { groupId: scope.row.id }
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "groups__name-text" },
                                      [_vm._v(_vm._s(scope.cell.value))]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "kindName-column",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "groups__kind" }, [
                              _c("span", { staticClass: "groups__kind-text" }, [
                                _vm._v(_vm._s(scope.row.kindName))
                              ])
                            ])
                          ]
                        }
                      },
                      {
                        key: "actions-column",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "groups__action" },
                              [
                                _c("vel-button", {
                                  staticClass: "action__edit-button",
                                  attrs: { type: "link", icon: "edit" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleEditGroup(scope.row)
                                    }
                                  }
                                }),
                                _c("vel-button", {
                                  staticClass: "action__delete-button",
                                  attrs: { type: "link", icon: "delete" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDeleteGroup(scope.row)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "empty",
                        fn: function() {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.$t("groups.errors.noData")) + " "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3414801781
                  )
                })
              ],
              1
            )
          ]
        : [
            _c("vel-table-filter", {
              staticClass: "groups__filter",
              model: {
                value: _vm.inputFilter,
                callback: function($$v) {
                  _vm.inputFilter = $$v
                },
                expression: "inputFilter"
              }
            }),
            _vm._l(_vm.rows, function(group) {
              return _c(
                "vel-card",
                {
                  key: group.name,
                  staticClass: "groups__list",
                  attrs: { "title-tag": "header" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "group__title" },
                              [
                                _c("vel-avatar", {
                                  staticClass: "group__avatar",
                                  attrs: { fullname: group.name }
                                }),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "group-title__text",
                                    attrs: {
                                      to: {
                                        name: "group",
                                        params: { groupId: group.id }
                                      }
                                    }
                                  },
                                  [
                                    _c("h3", { staticClass: "group__name" }, [
                                      _vm._v(_vm._s(group.name))
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "group-title__action" },
                                  [
                                    _c("vel-button", {
                                      staticClass: "action__edit-button",
                                      attrs: { type: "link", icon: "edit" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEditGroup(group)
                                        }
                                      }
                                    }),
                                    _c("vel-button", {
                                      staticClass: "action__delete-button",
                                      attrs: { type: "link", icon: "delete" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleDeleteGroup(group)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c("div", { staticClass: "group__info-details" }, [
                    _c("p", { staticClass: "property property__kind" }, [
                      _c("span", { staticClass: "property__label" }, [
                        _vm._v(_vm._s(_vm.$t("groups.kind.text")))
                      ]),
                      _c("span", { staticClass: "property__value" }, [
                        _vm._v(_vm._s(group.kindName))
                      ])
                    ])
                  ])
                ]
              )
            }),
            _vm.hasEmptyRows
              ? _c(
                  "vel-card",
                  {
                    staticClass: "groups__empty",
                    attrs: { "title-tag": "header" }
                  },
                  [_c("div", [_vm._v(_vm._s(_vm.$t("groups.errors.noData")))])]
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }