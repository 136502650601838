var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _vm.appStore.isReady ? _c("router-view") : _vm._e(),
      _c("portal-target", { attrs: { name: "app" } }),
      _vm.hasNewVersion
        ? _c("div", { staticClass: "version-notification" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _vm._v(" " + _vm._s(_vm.$t("app.version.message")) + " "),
                _c(
                  "vel-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.refresh()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("app.version.button")))]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }