var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      ref: "sidebarTipDistribution",
      attrs: {
        "header-class": "bg-light",
        "bg-variant": "white",
        width: "600px",
        right: "",
        id: "tipDistribution-sidebar",
        title: "Sidebar",
        shadow: "",
        backdrop: ""
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                { staticClass: "bg-gray-200 bg-light w-100" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: { click: hide }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-left",
                          "aria-hidden": "true"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("tipDistribution.back")) + " ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        },
        {
          key: "footer",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex bg-light text-dark align-items-center px-3 py-2",
                  staticStyle: { "border-top": "2px solid #d6d6d8" }
                },
                [
                  _c(
                    "b-button",
                    { attrs: { variant: "primary" }, on: { click: hide } },
                    [_vm._v(_vm._s(_vm.$t("tipDistribution.close")))]
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "b-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pending,
              expression: "pending"
            }
          ],
          staticClass: "p-4",
          attrs: { fluid: "" }
        },
        [
          _c("h5", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.$t("tipDistribution.list.title")))
          ]),
          _c("tip-distribution-form", {
            ref: "form",
            attrs: { "licence-number": _vm.employee.licenceNumber },
            on: {
              success: function($event) {
                return _vm.$emit("success", $event)
              }
            }
          }),
          _c("tip-distribution-list", {
            attrs: { "tip-distributions": _vm.tipDistributions },
            on: {
              onModify: function($event) {
                return _vm.openEditTipDistribution($event)
              },
              onDelete: function($event) {
                return _vm.$emit("onDelete", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }