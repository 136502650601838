var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    _vm._b(
      {
        staticClass: "report",
        attrs: {
          "show-selector-button": true,
          "multiple-locations":
            _vm.locationsList && _vm.locationsList.length > 1,
          "enable-all-currencies": true
        },
        scopedSlots: _vm._u([
          {
            key: "export",
            fn: function() {
              return [
                Object.values(_vm.voids).length ||
                Object.values(_vm.refunds).length ||
                Object.values(_vm.corrections).length
                  ? _c(
                      "vel-button",
                      {
                        staticClass: "vel-button",
                        attrs: {
                          disabled:
                            _vm.loading ||
                            (!Object.values(_vm.voids).length &&
                              !Object.values(_vm.refunds).length &&
                              !Object.values(_vm.corrections).length),
                          loading: _vm.generating,
                          type: "primary",
                          icon: _vm.isMediumUp ? "file-excel" : undefined
                        },
                        on: { click: _vm.exportXLS }
                      },
                      [
                        _vm.isMediumDown
                          ? _c("vel-icon", { attrs: { name: "file-download" } })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isMediumUp
                                ? _vm.$t("table.tableExport.button.value")
                                : ""
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "toolbar-options",
            fn: function() {
              return [
                (!_vm.locationCount || _vm.locationCount > 1) &&
                _vm.getCurrencySelectorSelected !== ""
                  ? _c(
                      "div",
                      { staticStyle: { padding: "8px 1rem 0 1rem" } },
                      [
                        _c(
                          "vel-checkbox",
                          {
                            on: {
                              change: function($event) {
                                return _vm.onConsolidate()
                              }
                            },
                            model: {
                              value: _vm.isConsolidated,
                              callback: function($$v) {
                                _vm.isConsolidated = $$v
                              },
                              expression: "isConsolidated"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("toolbar.selectors.consolidate")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      },
      "vel-page",
      _vm.pageAttrs,
      false
    ),
    [
      _vm.invalidLocations
        ? _c(
            "div",
            {
              staticStyle: {
                "background-color": "#fff2cc",
                "margin-bottom": "1rem",
                padding: "1rem"
              }
            },
            [
              _vm.getLocationSelectorSelected
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invalidVersions.requiredVersion")) +
                        " "
                    )
                  ]
                : [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "invalidVersions.requiredVersionMultiLocations"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript://" },
                        on: {
                          click: function($event) {
                            _vm.showLocationsList = !_vm.showLocationsList
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("invalidVersions.collapse")))]
                    ),
                    _vm.showLocationsList
                      ? _c(
                          "ul",
                          { staticStyle: { "margin-top": "1rem" } },
                          _vm._l(_vm.invalidLocations, function(location) {
                            return _c("li", { key: location.id }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    location.reportingDisplayName ||
                                      location.name
                                  ) +
                                  " "
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm.showNotification && !_vm.isVersionLoading
        ? _c(
            "p",
            {
              staticStyle: {
                "background-color": "#ffd2d2",
                "margin-bottom": "1rem",
                padding: "1rem"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("invalidVersions.notice")) + " ")]
          )
        : _vm._e(),
      _c(
        "vel-card",
        [
          _c("h5", [_vm._v(_vm._s(_vm.$t("voids.voidedItems.title")))]),
          _vm.loading
            ? _c("vel-spinner", { staticClass: "spinner" })
            : _vm._e(),
          !Object.keys(_vm.voids)[0] && !_vm.loading
            ? _c("div", { staticStyle: { "margin-top": "1rem" } }, [
                _vm._v(" " + _vm._s(_vm.$t("voids.voidedItems.empty")) + " ")
              ])
            : _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "12%" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("voids.voidedItems.table.headers.locations")
                        )
                      )
                    ]),
                    _c(
                      "th",
                      { staticClass: "date", staticStyle: { width: "120px" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "order-header",
                            class: { multiple: _vm.isMultiDays },
                            on: {
                              click: function($event) {
                                _vm.isMultiDays &&
                                  _vm.toggleSort("date", "voids")
                              }
                            }
                          },
                          [
                            _vm._v(" Date "),
                            _vm.isMultiDays
                              ? _c(
                                  "div",
                                  { staticClass: "sort" },
                                  [
                                    _c("vel-icon", {
                                      staticClass: "order",
                                      class: {
                                        active:
                                          _vm.sort.voids.by === "date" &&
                                          _vm.sort.voids.order === "asc"
                                      },
                                      attrs: { name: "order-asc" }
                                    }),
                                    _c("vel-icon", {
                                      staticClass: "order",
                                      class: {
                                        active:
                                          _vm.sort.voids.by === "date" &&
                                          _vm.sort.voids.order === "desc"
                                      },
                                      attrs: { name: "order-desc" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm.isConsolidatable &&
                    (!_vm.locationCount || _vm.locationCount > 1)
                      ? _c("th", { staticStyle: { width: "12%" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.voidedItems.table.headers.location"
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "th",
                      { staticClass: "time", staticStyle: { width: "85px" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("voids.voidedItems.table.headers.hours")
                          )
                        )
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "85px" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("voids.voidedItems.table.headers.qty"))
                        )
                      ]
                    ),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "voids.voidedItems.table.headers.itemDescription"
                          )
                        )
                      )
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "120px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("voids.voidedItems.table.headers.price")
                          )
                        )
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "120px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("voids.voidedItems.table.headers.amount")
                          )
                        )
                      ]
                    ),
                    _c("th", { staticStyle: { width: "120px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("voids.voidedItems.table.headers.check"))
                      )
                    ]),
                    _c("th", [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              _vm.isMultiDays &&
                                _vm.toggleSort("category", "voids")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.voidedItems.table.headers.category"
                                )
                              ) +
                              " "
                          ),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.voids.by === "category" &&
                                        _vm.sort.voids.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.voids.by === "category" &&
                                        _vm.sort.voids.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _c("th", [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              _vm.isMultiDays &&
                                _vm.toggleSort("employee", "voids")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.voidedItems.table.headers.employee"
                                )
                              ) +
                              " "
                          ),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.voids.by === "employee" &&
                                        _vm.sort.voids.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.voids.by === "employee" &&
                                        _vm.sort.voids.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _c("th", [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              return _vm.toggleSort("authorize", "voids")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.voidedItems.table.headers.menager"
                                )
                              ) +
                              " "
                          ),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.voids.by === "authorize" &&
                                        _vm.sort.voids.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.voids.by === "authorize" &&
                                        _vm.sort.voids.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ])
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.voids, function(location, locationId) {
                      return [
                        _vm._l(location, function(l, i) {
                          return [
                            _vm._l(l, function(v, k) {
                              return [
                                _vm.expand.voids[locationId]
                                  ? _c("tr", { key: v.id + i }, [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "location",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggle(
                                                locationId,
                                                "voids"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          !k
                                            ? _c(
                                                "span",
                                                [
                                                  _c("vel-icon", {
                                                    staticStyle: {
                                                      height: "12px",
                                                      width: "8px"
                                                    },
                                                    attrs: {
                                                      name: "bottom-arrow"
                                                    }
                                                  }),
                                                  _vm.isConsolidatable &&
                                                  (!_vm.locationCount ||
                                                    _vm.locationCount > 1)
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              !_vm.locationCount
                                                                ? _vm.$t(
                                                                    "dashboard.locationSelector.allLocationsText"
                                                                  )
                                                                : _vm.$t(
                                                                    "dashboard.locationSelector.locationsSelected",
                                                                    {
                                                                      count:
                                                                        _vm.locationCount,
                                                                      total:
                                                                        _vm
                                                                          .locationsBySelectedGroup
                                                                          .length
                                                                    }
                                                                  )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              v.location
                                                                .reportingDisplayName ||
                                                                v.location.name
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "date",
                                          class: {
                                            empty:
                                              _vm.sort.voids.by === "date" &&
                                              k &&
                                              (!_vm.isDifferentValue(
                                                v,
                                                "date"
                                              ) ||
                                                !k)
                                          }
                                        },
                                        [
                                          _vm.isDifferentValue(v, "date") || !k
                                            ? _c("span", [
                                                _vm._v(_vm._s(v.date))
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm.isConsolidatable &&
                                      (!_vm.locationCount ||
                                        _vm.locationCount > 1)
                                        ? _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  v.location
                                                    .reportingDisplayName ||
                                                    v.location.name
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      _c("td", { staticClass: "time" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              v.time.replace(
                                                /^2(4|5|6|7|8)/gi,
                                                function(matched) {
                                                  return (
                                                    "0" +
                                                    (matched - 24).toString()
                                                  )
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "quantity text-right" },
                                        [_vm._v(_vm._s(v.quantity))]
                                      ),
                                      _c("td", { staticClass: "itemName" }, [
                                        _vm._v(
                                          _vm._s(
                                            (v.product && v.product.name) ||
                                              "--"
                                          )
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "price text-right" },
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: v.price / v.quantity,
                                              currency:
                                                v.location.detailedCurrency
                                                  .currencySymbol
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "amount text-right" },
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: v.price,
                                              currency:
                                                v.location.detailedCurrency
                                                  .currencySymbol
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td", { staticClass: "check" }),
                                      _c(
                                        "td",
                                        { staticClass: "categoryName" },
                                        [_vm._v(_vm._s(v.category))]
                                      ),
                                      _c("td", { staticClass: "employee" }, [
                                        _vm._v(_vm._s(v.employee))
                                      ]),
                                      _c("td", { staticClass: "authorized" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              v.employee !== v.authorize ||
                                                _vm.sort.voids.by ===
                                                  "authorize"
                                                ? v.authorize
                                                : "--"
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.expand.voids[v.id]
                                  ? _c("tr", { key: "a" + v.id }, [
                                      _c("td", { staticClass: "location" }),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.total")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td", { staticClass: "location" }),
                                      _vm.isConsolidatable &&
                                      (!_vm.locationCount ||
                                        _vm.locationCount > 1)
                                        ? _c("td")
                                        : _vm._e(),
                                      _c("td", [_vm._v(_vm._s(v.quantity))]),
                                      _c("td"),
                                      _c(
                                        "td",
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: v.price,
                                              currency:
                                                v.location.detailedCurrency
                                                  .currencySymbol
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td"),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.all")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.all")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.all")
                                            )
                                          )
                                        ])
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            }),
                            _vm.expand.voids[locationId] && _vm.isMultiDays
                              ? _c("tr", { key: i, staticClass: "subtotal" }, [
                                  _c("td", {
                                    staticClass: "location",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggle(locationId, "voids")
                                      }
                                    }
                                  }),
                                  _c("td", { staticClass: "date" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("voids.voidedItems.subtotal")
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm.isConsolidatable &&
                                  (!_vm.locationCount || _vm.locationCount > 1)
                                    ? _c("td")
                                    : _vm._e(),
                                  _c("td", { staticClass: "time" }),
                                  _c(
                                    "td",
                                    { staticClass: "quantity text-right" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getVoidsQuantityByIndex(
                                                locationId,
                                                i
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c("td", { staticClass: "itemName" }),
                                  _c("td", { staticClass: "price text-right" }),
                                  _c(
                                    "td",
                                    { staticClass: "amount text-right" },
                                    [
                                      _c(
                                        "strong",
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: _vm.getVoidsAmountByIndex(
                                                locationId,
                                                i
                                              ),
                                              currency: _vm.getVoidsCurrencyByIndex(
                                                locationId,
                                                i
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("td", { staticClass: "check" }),
                                  _c("td", { staticClass: "categoryName" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sort.voids.by === "category"
                                            ? _vm.voids[locationId][i][0]
                                                .category
                                            : "All"
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "employee" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sort.voids.by === "employee"
                                            ? _vm.voids[locationId][i][0]
                                                .employee
                                            : "All"
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "authorized" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sort.voids.by === "authorize"
                                            ? _vm.voids[locationId][i][0]
                                                .authorize
                                            : "All"
                                        )
                                      )
                                    ])
                                  ])
                                ])
                              : _vm._e()
                          ]
                        }),
                        _c(
                          "tr",
                          {
                            key: locationId,
                            class: { total: _vm.expand.voids[locationId] }
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "location",
                                class: {
                                  noBorder: _vm.expand.voids[locationId]
                                },
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.toggle(locationId, "voids")
                                  }
                                }
                              },
                              [
                                !_vm.expand.voids[locationId]
                                  ? _c(
                                      "span",
                                      [
                                        _c("vel-icon", {
                                          staticStyle: {
                                            height: "8px",
                                            position: "relative",
                                            top: "-2px",
                                            width: "8px"
                                          },
                                          attrs: {
                                            name: _vm.expand.voids[locationId]
                                              ? "bottom-arrow"
                                              : "right-arrow"
                                          }
                                        }),
                                        _vm.isConsolidatable &&
                                        (!_vm.locationCount ||
                                          _vm.locationCount > 1)
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    !_vm.locationCount
                                                      ? _vm.$t(
                                                          "dashboard.locationSelector.allLocationsText"
                                                        )
                                                      : _vm.$t(
                                                          "dashboard.locationSelector.locationsSelected",
                                                          {
                                                            count:
                                                              _vm.locationCount,
                                                            total:
                                                              _vm
                                                                .locationsBySelectedGroup
                                                                .length
                                                          }
                                                        )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    Object.values(
                                                      _vm.voids[locationId]
                                                    )[0][0].location
                                                      .reportingDisplayName ||
                                                      Object.values(
                                                        _vm.voids[locationId]
                                                      )[0][0].location.name
                                                  ) +
                                                  " "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c("td", { staticClass: "date" }, [
                              _c("strong", [_vm._v("Total")])
                            ]),
                            _vm.isConsolidatable &&
                            (!_vm.locationCount || _vm.locationCount > 1)
                              ? _c("td")
                              : _vm._e(),
                            _c("td", { staticClass: "time" }),
                            _c("td", { staticClass: "quantity text-right" }, [
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getVoidsQuantityByIndex(locationId)
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c("td", { staticClass: "itemName" }),
                            _c("td", { staticClass: "price text-right" }),
                            _c("td", { staticClass: "amount text-right" }, [
                              _c(
                                "strong",
                                [
                                  _c("vel-amount", {
                                    attrs: {
                                      amount: _vm.getVoidsAmountByIndex(
                                        locationId
                                      ),
                                      currency: _vm.getVoidsCurrencyByIndex(
                                        locationId
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("td", { staticClass: "check" }),
                            _c("td", { staticClass: "categoryName" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("voids.voidedItems.all")))
                              ])
                            ]),
                            _c("td", { staticClass: "employee" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("voids.voidedItems.all")))
                              ])
                            ]),
                            _c("td", { staticClass: "authorized" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("voids.voidedItems.all")))
                              ])
                            ])
                          ]
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
        ],
        1
      ),
      _c(
        "vel-card",
        [
          _c("h5", [_vm._v(_vm._s(_vm.$t("voids.refundsItems.title")))]),
          _vm.loading
            ? _c("vel-spinner", { staticClass: "spinner" })
            : _vm._e(),
          !Object.keys(_vm.refunds)[0] && !_vm.loading
            ? _c("div", { staticStyle: { "margin-top": "1rem" } }, [
                _vm._v(" " + _vm._s(_vm.$t("voids.refundsItems.empty")) + " ")
              ])
            : _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "12%" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("voids.refundsItems.table.headers.locations")
                        )
                      )
                    ]),
                    _c("th", { staticStyle: { width: "120px" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              _vm.isMultiDays &&
                                _vm.toggleSort("date", "refunds")
                            }
                          }
                        },
                        [
                          _vm._v(" Date "),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.refunds.by === "date" &&
                                        _vm.sort.refunds.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.refunds.by === "date" &&
                                        _vm.sort.refunds.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _vm.isConsolidatable &&
                    (!_vm.locationCount || _vm.locationCount > 1)
                      ? _c("th", { staticStyle: { width: "12%" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.voidedItems.table.headers.location"
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("th", { staticStyle: { width: "85px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("voids.refundsItems.table.headers.hours"))
                      )
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "85px" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("voids.refundsItems.table.headers.qty"))
                        )
                      ]
                    ),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "voids.refundsItems.table.headers.itemDescription"
                          )
                        )
                      )
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "120px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("voids.refundsItems.table.headers.price")
                          )
                        )
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "120px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("voids.refundsItems.table.headers.amount")
                          )
                        )
                      ]
                    ),
                    _c("th", { staticStyle: { width: "120px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("voids.refundsItems.table.headers.check"))
                      )
                    ]),
                    _c("th", [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              _vm.isMultiDays &&
                                _vm.toggleSort("category", "refunds")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.refundsItems.table.headers.category"
                                )
                              ) +
                              " "
                          ),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.refunds.by === "category" &&
                                        _vm.sort.refunds.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.refunds.by === "category" &&
                                        _vm.sort.refunds.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _c("th", [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              _vm.isMultiDays &&
                                _vm.toggleSort("employee", "refunds")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.refundsItems.table.headers.employee"
                                )
                              ) +
                              " "
                          ),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.refunds.by === "employee" &&
                                        _vm.sort.refunds.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.refunds.by === "employee" &&
                                        _vm.sort.refunds.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _c("th", [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              return _vm.toggleSort("authorize", "refunds")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.refundsItems.table.headers.menager"
                                )
                              ) +
                              " "
                          ),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.refunds.by === "authorize" &&
                                        _vm.sort.refunds.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.refunds.by === "authorize" &&
                                        _vm.sort.refunds.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ])
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.refunds, function(location, locationId) {
                      return [
                        _vm._l(location, function(l, i) {
                          return [
                            _vm._l(l, function(v, k) {
                              return [
                                _vm.expand.refunds[locationId]
                                  ? _c("tr", { key: v.id + i }, [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "location",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggle(
                                                locationId,
                                                "refunds"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          !k
                                            ? _c(
                                                "span",
                                                [
                                                  _c("vel-icon", {
                                                    staticStyle: {
                                                      height: "12px",
                                                      width: "8px"
                                                    },
                                                    attrs: {
                                                      name: "bottom-arrow"
                                                    }
                                                  }),
                                                  _vm.isConsolidatable &&
                                                  (!_vm.locationCount ||
                                                    _vm.locationCount > 1)
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              !_vm.locationCount
                                                                ? _vm.$t(
                                                                    "dashboard.locationSelector.allLocationsText"
                                                                  )
                                                                : _vm.$t(
                                                                    "dashboard.locationSelector.locationsSelected",
                                                                    {
                                                                      count:
                                                                        _vm.locationCount,
                                                                      total:
                                                                        _vm
                                                                          .locationsBySelectedGroup
                                                                          .length
                                                                    }
                                                                  )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              v.location
                                                                .reportingDisplayName ||
                                                                v.location.name
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "date",
                                          class: {
                                            empty:
                                              _vm.sort.refunds.by === "date" &&
                                              k &&
                                              (!_vm.isDifferentValue(
                                                v,
                                                "date"
                                              ) ||
                                                !k)
                                          }
                                        },
                                        [
                                          _vm.isDifferentValue(v, "date") || !k
                                            ? _c("span", [
                                                _vm._v(_vm._s(v.date))
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm.isConsolidatable &&
                                      (!_vm.locationCount ||
                                        _vm.locationCount > 1)
                                        ? _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  v.location
                                                    .reportingDisplayName ||
                                                    v.location.name
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      _c("td", { staticClass: "time" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              v.time.replace(
                                                /^2(4|5|6|7|8)/gi,
                                                function(matched) {
                                                  return (
                                                    "0" +
                                                    (matched - 24).toString()
                                                  )
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "quantity text-right" },
                                        [_vm._v(_vm._s(v.quantity))]
                                      ),
                                      _c("td", { staticClass: "itemName" }, [
                                        _vm._v(
                                          _vm._s(
                                            (v.product && v.product.name) ||
                                              "--"
                                          )
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "price text-right" },
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: v.price / v.quantity,
                                              currency:
                                                v.location.detailedCurrency
                                                  .currencySymbol
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "price text-right" },
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: v.price,
                                              currency:
                                                v.location.detailedCurrency
                                                  .currencySymbol
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td", { staticClass: "check" }),
                                      _c(
                                        "td",
                                        { staticClass: "categoryName" },
                                        [_vm._v(_vm._s(v.category))]
                                      ),
                                      _c("td", { staticClass: "employee" }, [
                                        _vm._v(_vm._s(v.employee))
                                      ]),
                                      _c("td", { staticClass: "authorized" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              v.employee !== v.authorize ||
                                                _vm.sort.refunds.by ===
                                                  "authorize"
                                                ? v.authorize
                                                : "--"
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.expand.refunds[v.id]
                                  ? _c("tr", { key: "a" + v.id }, [
                                      _c("td", { staticClass: "location" }),
                                      _vm.isConsolidatable &&
                                      (!_vm.locationCount ||
                                        _vm.locationCount > 1)
                                        ? _c("td")
                                        : _vm._e(),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.total")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td"),
                                      _c("td", [_vm._v(_vm._s(v.quantity))]),
                                      _c("td"),
                                      _c(
                                        "td",
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: v.price,
                                              currency:
                                                v.location.detailedCurrency
                                                  .currencySymbol
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td"),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.all")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.all")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.all")
                                            )
                                          )
                                        ])
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            }),
                            _vm.expand.refunds[locationId] && _vm.isMultiDays
                              ? _c("tr", { key: i, staticClass: "subtotal" }, [
                                  _c("td", {
                                    staticClass: "location",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggle(locationId, "refunds")
                                      }
                                    }
                                  }),
                                  _c("td", { staticClass: "date" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("voids.voidedItems.subtotal")
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "time" }),
                                  _vm.isConsolidatable &&
                                  (!_vm.locationCount || _vm.locationCount > 1)
                                    ? _c("td")
                                    : _vm._e(),
                                  _c(
                                    "td",
                                    { staticClass: "quantity text-right" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getRefundsQuantityByIndex(
                                                locationId,
                                                i
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c("td", { staticClass: "itemName" }),
                                  _c("td", { staticClass: "price text-right" }),
                                  _c(
                                    "td",
                                    { staticClass: "amount text-right" },
                                    [
                                      _c(
                                        "strong",
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: _vm.getRefundsAmountByIndex(
                                                locationId,
                                                i
                                              ),
                                              currency: _vm.getRefundsCurrencyByIndex(
                                                locationId,
                                                i
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("td", { staticClass: "check" }),
                                  _c("td", { staticClass: "categoryName" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sort.refunds.by === "category"
                                            ? _vm.refunds[locationId][i][0]
                                                .category
                                            : "All"
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "employee" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sort.refunds.by === "employee"
                                            ? _vm.refunds[locationId][i][0]
                                                .employee
                                            : "All"
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "authorized" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sort.refunds.by === "authorize"
                                            ? _vm.refunds[locationId][i][0]
                                                .authorize
                                            : "All"
                                        )
                                      )
                                    ])
                                  ])
                                ])
                              : _vm._e()
                          ]
                        }),
                        _c(
                          "tr",
                          {
                            key: locationId,
                            class: { total: _vm.expand.refunds[locationId] }
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "location",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.toggle(locationId, "refunds")
                                  }
                                }
                              },
                              [
                                !_vm.expand.refunds[locationId]
                                  ? _c(
                                      "span",
                                      [
                                        _c("vel-icon", {
                                          staticStyle: {
                                            height: "8px",
                                            position: "relative",
                                            top: "-2px",
                                            width: "8px"
                                          },
                                          attrs: {
                                            name: _vm.expand.refunds[locationId]
                                              ? "bottom-arrow"
                                              : "right-arrow"
                                          }
                                        }),
                                        _vm.isConsolidatable &&
                                        (!_vm.locationCount ||
                                          _vm.locationCount > 1)
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    !_vm.locationCount
                                                      ? _vm.$t(
                                                          "dashboard.locationSelector.allLocationsText"
                                                        )
                                                      : _vm.$t(
                                                          "dashboard.locationSelector.locationsSelected",
                                                          {
                                                            count:
                                                              _vm.locationCount,
                                                            total:
                                                              _vm
                                                                .locationsBySelectedGroup
                                                                .length
                                                          }
                                                        )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    Object.values(
                                                      _vm.refunds[locationId]
                                                    )[0][0].location
                                                      .reportingDisplayName ||
                                                      Object.values(
                                                        _vm.refunds[locationId]
                                                      )[0][0].location.name
                                                  ) +
                                                  " "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c("td", { staticClass: "date" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("voids.voidedItems.total"))
                                )
                              ])
                            ]),
                            _vm.isConsolidatable &&
                            (!_vm.locationCount || _vm.locationCount > 1)
                              ? _c("td")
                              : _vm._e(),
                            _c("td", { staticClass: "time" }),
                            _c("td", { staticClass: "quantity text-right" }, [
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getRefundsQuantityByIndex(locationId)
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c("td", { staticClass: "itemName" }),
                            _c("td", { staticClass: "price text-right" }),
                            _c("td", { staticClass: "amount text-right" }, [
                              _c(
                                "strong",
                                [
                                  _c("vel-amount", {
                                    attrs: {
                                      amount: _vm.getRefundsAmountByIndex(
                                        locationId
                                      ),
                                      currency: _vm.getRefundsCurrencyByIndex(
                                        locationId
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("td", { staticClass: "check" }),
                            _c("td", { staticClass: "categoryName" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("voids.voidedItems.all")))
                              ])
                            ]),
                            _c("td", { staticClass: "employee" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("voids.voidedItems.all")))
                              ])
                            ]),
                            _c("td", { staticClass: "authorized" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("voids.voidedItems.all")))
                              ])
                            ])
                          ]
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
        ],
        1
      ),
      _c(
        "vel-card",
        [
          _c("h5", [_vm._v(_vm._s(_vm.$t("voids.itemCorrections.title")))]),
          _vm.loading
            ? _c("vel-spinner", { staticClass: "spinner" })
            : _vm._e(),
          !Object.keys(_vm.corrections)[0] && !_vm.loading
            ? _c("div", { staticStyle: { "margin-top": "1rem" } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("voids.itemCorrections.empty")) + " "
                )
              ])
            : _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "12%" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "voids.itemCorrections.table.headers.locations"
                          )
                        )
                      )
                    ]),
                    _c("th", { staticStyle: { width: "120px" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              _vm.isMultiDays &&
                                _vm.toggleSort("date", "corrections")
                            }
                          }
                        },
                        [
                          _vm._v(" Date "),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.corrections.by === "date" &&
                                        _vm.sort.corrections.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.corrections.by === "date" &&
                                        _vm.sort.corrections.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _vm.isConsolidatable &&
                    (!_vm.locationCount || _vm.locationCount > 1)
                      ? _c("th", { staticStyle: { width: "12%" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.voidedItems.table.headers.location"
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("th", { staticStyle: { width: "85px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("voids.itemCorrections.table.headers.hours")
                        )
                      )
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "85px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("voids.itemCorrections.table.headers.qty")
                          )
                        )
                      ]
                    ),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "voids.itemCorrections.table.headers.itemDescription"
                          )
                        )
                      )
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "120px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("voids.itemCorrections.table.headers.price")
                          )
                        )
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "120px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("voids.itemCorrections.table.headers.amount")
                          )
                        )
                      ]
                    ),
                    _c("th", { staticStyle: { width: "120px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("voids.itemCorrections.table.headers.check")
                        )
                      )
                    ]),
                    _c("th", [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              _vm.isMultiDays &&
                                _vm.toggleSort("category", "corrections")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.itemCorrections.table.headers.category"
                                )
                              ) +
                              " "
                          ),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.corrections.by ===
                                          "category" &&
                                        _vm.sort.corrections.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.corrections.by ===
                                          "category" &&
                                        _vm.sort.corrections.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _c("th", [
                      _c(
                        "div",
                        {
                          staticClass: "order-header",
                          class: { multiple: _vm.isMultiDays },
                          on: {
                            click: function($event) {
                              _vm.isMultiDays &&
                                _vm.toggleSort("employee", "corrections")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "voids.itemCorrections.table.headers.employee"
                                )
                              ) +
                              " "
                          ),
                          _vm.isMultiDays
                            ? _c(
                                "div",
                                { staticClass: "sort" },
                                [
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.corrections.by ===
                                          "employee" &&
                                        _vm.sort.corrections.order === "asc"
                                    },
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    staticClass: "order",
                                    class: {
                                      active:
                                        _vm.sort.corrections.by ===
                                          "employee" &&
                                        _vm.sort.corrections.order === "desc"
                                    },
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _c("th")
                  ])
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.corrections, function(location, locationId) {
                      return [
                        _vm._l(location, function(l, i) {
                          return [
                            _vm._l(l, function(v, k) {
                              return [
                                _vm.expand.corrections[locationId]
                                  ? _c("tr", { key: v.id + i }, [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "location",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggle(
                                                locationId,
                                                "corrections"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          !k
                                            ? _c(
                                                "span",
                                                [
                                                  _c("vel-icon", {
                                                    staticStyle: {
                                                      height: "12px",
                                                      width: "8px"
                                                    },
                                                    attrs: {
                                                      name: "bottom-arrow"
                                                    }
                                                  }),
                                                  _vm.isConsolidatable &&
                                                  (!_vm.locationCount ||
                                                    _vm.locationCount > 1)
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              !_vm.locationCount
                                                                ? _vm.$t(
                                                                    "dashboard.locationSelector.allLocationsText"
                                                                  )
                                                                : _vm.$t(
                                                                    "dashboard.locationSelector.locationsSelected",
                                                                    {
                                                                      count:
                                                                        _vm.locationCount,
                                                                      total:
                                                                        _vm
                                                                          .locationsBySelectedGroup
                                                                          .length
                                                                    }
                                                                  )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              v.location
                                                                .reportingDisplayName ||
                                                                v.location.name
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "date",
                                          class: {
                                            empty:
                                              _vm.sort.corrections.by ===
                                                "date" &&
                                              k &&
                                              (!_vm.isDifferentValue(
                                                v,
                                                "date"
                                              ) ||
                                                !k)
                                          }
                                        },
                                        [
                                          _vm.isDifferentValue(v, "date") || !k
                                            ? _c("span", [
                                                _vm._v(_vm._s(v.date))
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm.isConsolidatable &&
                                      (!_vm.locationCount ||
                                        _vm.locationCount > 1)
                                        ? _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  v.location
                                                    .reportingDisplayName ||
                                                    v.location.name
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      _c("td", { staticClass: "time" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              v.time.replace(
                                                /^2(4|5|6|7|8)/gi,
                                                function(matched) {
                                                  return (
                                                    "0" +
                                                    (matched - 24).toString()
                                                  )
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "quantity text-right" },
                                        [_vm._v(_vm._s(v.quantity))]
                                      ),
                                      _c("td", { staticClass: "itemName" }, [
                                        _vm._v(
                                          _vm._s(
                                            (v.product && v.product.name) ||
                                              "--"
                                          )
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "price text-right" },
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: v.price / v.quantity,
                                              currency:
                                                v.location.detailedCurrency
                                                  .currencySymbol
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "amount text-right" },
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: v.price,
                                              currency:
                                                v.location.detailedCurrency
                                                  .currencySymbol
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td", { staticClass: "check" }),
                                      _c(
                                        "td",
                                        { staticClass: "categoryName" },
                                        [_vm._v(_vm._s(v.category))]
                                      ),
                                      _c("td", { staticClass: "employee" }, [
                                        _vm._v(_vm._s(v.employee))
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.expand.corrections[v.id]
                                  ? _c("tr", { key: "a" + v.id }, [
                                      _c("td", { staticClass: "location" }),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.total")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td"),
                                      _c("td", [_vm._v(_vm._s(v.quantity))]),
                                      _c("td"),
                                      _c(
                                        "td",
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: v.price,
                                              currency:
                                                v.location.detailedCurrency
                                                  .currencySymbol
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td"),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.all")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.all")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("voids.voidedItems.all")
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("td")
                                    ])
                                  : _vm._e()
                              ]
                            }),
                            _vm.expand.corrections[locationId] &&
                            _vm.isMultiDays
                              ? _c("tr", { key: i, staticClass: "subtotal" }, [
                                  _c("td", {
                                    staticClass: "location",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggle(
                                          locationId,
                                          "corrections"
                                        )
                                      }
                                    }
                                  }),
                                  _c("td", { staticClass: "date" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("voids.voidedItems.subtotal")
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm.isConsolidatable &&
                                  (!_vm.locationCount || _vm.locationCount > 1)
                                    ? _c("td")
                                    : _vm._e(),
                                  _c("td", { staticClass: "time" }),
                                  _c(
                                    "td",
                                    { staticClass: "quantity text-right" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getCorrectionsQuantityByIndex(
                                                locationId,
                                                i
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c("td", { staticClass: "itemName" }),
                                  _c("td", { staticClass: "price text-right" }),
                                  _c(
                                    "td",
                                    { staticClass: "amount text-right" },
                                    [
                                      _c(
                                        "strong",
                                        [
                                          _c("vel-amount", {
                                            attrs: {
                                              amount: _vm.getCorrectionsAmountByIndex(
                                                locationId,
                                                i
                                              ),
                                              currency: _vm.getCorrectionsCurrencyByIndex(
                                                locationId,
                                                i
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("td", { staticClass: "check" }),
                                  _c("td", { staticClass: "categoryName" }, [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.sort.corrections.by ===
                                              "category"
                                              ? _vm.corrections[locationId][
                                                  i
                                                ][0].category
                                              : "All"
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]),
                                  _c("td", { staticClass: "employee" }, [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.sort.corrections.by ===
                                              "employee"
                                              ? _vm.corrections[locationId][
                                                  i
                                                ][0].employee
                                              : "All"
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]),
                                  _c("td")
                                ])
                              : _vm._e()
                          ]
                        }),
                        _c(
                          "tr",
                          {
                            key: locationId,
                            class: { total: _vm.expand.corrections[locationId] }
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "location",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.toggle(locationId, "corrections")
                                  }
                                }
                              },
                              [
                                !_vm.expand.corrections[locationId]
                                  ? _c(
                                      "span",
                                      [
                                        _c("vel-icon", {
                                          staticStyle: {
                                            height: "8px",
                                            position: "relative",
                                            top: "-2px",
                                            width: "8px"
                                          },
                                          attrs: {
                                            name: _vm.expand.corrections[
                                              locationId
                                            ]
                                              ? "bottom-arrow"
                                              : "right-arrow"
                                          }
                                        }),
                                        _vm.isConsolidatable &&
                                        (!_vm.locationCount ||
                                          _vm.locationCount > 1)
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    !_vm.locationCount
                                                      ? _vm.$t(
                                                          "dashboard.locationSelector.allLocationsText"
                                                        )
                                                      : _vm.$t(
                                                          "dashboard.locationSelector.locationsSelected",
                                                          {
                                                            count:
                                                              _vm.locationCount,
                                                            total:
                                                              _vm
                                                                .locationsBySelectedGroup
                                                                .length
                                                          }
                                                        )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    Object.values(
                                                      _vm.corrections[
                                                        locationId
                                                      ]
                                                    )[0][0].location
                                                      .reportingDisplayName ||
                                                      Object.values(
                                                        _vm.corrections[
                                                          locationId
                                                        ]
                                                      )[0][0].location.name
                                                  ) +
                                                  " "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c("td", { staticClass: "date" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("voids.voidedItems.total"))
                                )
                              ])
                            ]),
                            _vm.isConsolidatable &&
                            (!_vm.locationCount || _vm.locationCount > 1)
                              ? _c("td")
                              : _vm._e(),
                            _c("td", { staticClass: "time" }),
                            _c("td", { staticClass: "quantity text-right" }, [
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getCorrectionsQuantityByIndex(
                                        locationId
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c("td", { staticClass: "itemName" }),
                            _c("td", { staticClass: "price text-right" }),
                            _c("td", { staticClass: "amount text-right" }, [
                              _c(
                                "strong",
                                [
                                  _c("vel-amount", {
                                    attrs: {
                                      amount: _vm.getCorrectionsAmountByIndex(
                                        locationId
                                      ),
                                      currency: _vm.getCorrectionsCurrencyByIndex(
                                        locationId
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("td", { staticClass: "check" }),
                            _c("td", { staticClass: "categoryName" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("voids.voidedItems.all")))
                              ])
                            ]),
                            _c("td", { staticClass: "employee" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("voids.voidedItems.all")))
                              ])
                            ]),
                            _c("td")
                          ]
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }