<template>
  <vel-backoffice-page class="bootstrap px-2">
    <template slot="header">
      <div style="border-bottom: 8px solid rgb(245, 245, 245);">
        <div
          class="d-flex justify-content-between"
          style="border-bottom: 1px solid rgba(76, 86, 103, 0.2); padding: 0.8rem 1.5rem;"
        >
          <h1 class="title-right my-0" style="font-size: 1.5rem;">
            {{ $t('checkSummary.title') }}
          </h1>
          <vel-button
            :loading="generating"
            :disabled="isPending || !Object.values(getInvoices).length"
            type="primary"
            @click="onExportXLS"
            :icon="isMediumUp ? 'file-excel' : undefined"
            class="vel-button"
            v-if="Object.values(getInvoices).length"
          >
            <vel-icon v-if="isMediumDown" name="file-download" />
            {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
          </vel-button>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="px-3 py-1 row">
        <router-link class="col-md-6 d-flex align-items-center" :to="{ name: 'reports-menu' }">
          {{ $t('checkDetails.backLinkReport') }}
        </router-link>
        <div class="col-md-6 d-flex justify-content-end align-items-center">
          <b-form-group class="d-none d-md-block">
            {{ $t('employee.list.rowsPerPage') }}
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="[10, 25, 50, 100]"
              style="width: 80px;"
              class="mx-3"
              :disabled="isPending || generating || !getInvoices.length"
              @change="fetchData"
            ></b-form-select>
          </b-form-group>
          <span class="d-none d-md-flex align-self-center mr-6" v-if="getInvoices.length">
            {{
              $t('pagination.format', {
                first: getCurrentPage * getPerPage - getPerPage + 1,
                last: Math.min(getCurrentPage * getPerPage, getTotalResults),
                total: getTotalResults
              })
            }}
          </span>
          <b-pagination
            size="sm"
            pills
            class="m-0 ml-2"
            v-model="currentPage"
            :per-page="getPerPage"
            :total-rows="getTotalResults"
            :limit="5"
            align="right"
            :disabled="isPending || generating || !getInvoices.length"
          ></b-pagination>
        </div>
      </div>
    </template>
    <div class="p-2 px-4">
      <toolbar-selectors
        ref="toolbarSelector"
        class="toolbar"
        :enable-date-compare="false"
        :enable-date-range="true"
        :enable-currency="false"
        :enable-date-picker="false"
        :enable-group="true"
        :enable-all-locations="true"
        :enable-button="true"
        :loading="isPending"
        :multiple-locations="true"
      />
    </div>
    <b-container fluid class="h-100 px-4 mb-4 mt-2" id="table-container">
      <div class="text-center" v-if="isPending && newLocationRangeGeneration">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <vel-card v-else-if="!newLocationRangeGeneration">
        <div class="report">
          <div class="filters_toggle_container">
            <span class="filters_toggle" @click="showFilters = !showFilters">
              <label>
                {{ showFilters ? $t('checkSummary.filters.hide') : $t('checkSummary.filters.show') }}
              </label>
              <b-icon icon="chevron-down" />
            </span>
          </div>
          <transition name="fade">
            <CheckSummaryFilters v-if="showFilters" :disabled="isPending" @filtersChanged="fetchData" />
          </transition>
          <b-table
            v-if="isPending || getInvoices.length > 0"
            primary-key="id"
            :busy="isPending"
            :items="getInvoices"
            :fields="columns"
            responsive="sm"
            ref="table"
            sticky-header
            :no-local-sorting="true"
            @sort-changed="sortingChanged"
            :tbody-tr-class="rowClass"
            @row-clicked="onRowSelected"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner variant="primary" class="align-middle p-2 m-4"></b-spinner>
              </div>
            </template>
            <template v-slot:head()="scope">
              <div class="container">
                <div>{{ scope.field.label }}</div>
                <div v-if="scope.field.sortable || false" class="sort-icon">
                  <b-icon
                    icon="arrow-down"
                    :class="`vel-table-header__sort${
                      getSortBy === scope.field.filterName
                        ? getSortDirection === 'desc'
                          ? '-icon_down'
                          : '-icon_up'
                        : ''
                    }`"
                  />
                </div>
              </div>
            </template>
            <template #cell(invoiceTime)="scope">
              {{ formatDateTime(scope.item.invoiceTime) }}
            </template>
            <template #cell(tableNumber)="scope">
              {{ scope.item.tableNumber || '--' }}
            </template>
            <template #cell(customers)="scope">
              {{ scope.item.customers || '--' }}
            </template>
            <template #cell(total)="scope">
              <vel-amount :currency="scope.item.currency" :amount="scope.item.total" />
            </template>
            <template #cell(totalTaxAmount)="scope">
              <vel-amount :currency="scope.item.currency" :amount="scope.item.totalTaxAmount" />
            </template>
            <template #cell(discounts)="scope">
              <vel-amount :currency="scope.item.currency" :amount="scope.item.discount + scope.item.cashDiscount" />
            </template>
            <template #cell(services)="scope">
              <vel-amount :currency="scope.item.currency" :amount="scope.item.tips" />
            </template>
            <template #cell(tenderName)="scope">
              <p v-for="(tenderType, index) in scope.item.payments" :key="index">{{ tenderType.paymodeName }}</p>
            </template>
            <template #cell(tenderSurcharge)="scope" v-if="getHaveSurcharge">
              <p v-for="(tenderType, index) in scope.item.payments" :key="index">
                <vel-amount :currency="scope.item.currency" :amount="tenderType.surcharge" />
              </p>
            </template>
            <template #cell(tenderAmount)="scope">
              <p v-for="(tenderType, index) in scope.item.payments" :key="index">
                <vel-amount :currency="scope.item.currency" :amount="tenderType.amount" />
              </p>
            </template>
            <template #cell(tenderTip)="scope">
              <p v-for="(tenderType, index) in scope.item.payments" :key="index">
                <vel-amount :currency="scope.item.currency" :amount="tenderType.transferredTip" />
              </p>
            </template>
            <template #cell(action)="scope">
              <router-link :to="{ name: 'checkDetails', params: { id: scope.item.id } }">
                <Action class="action" />
              </router-link>
            </template>
          </b-table>

          <vel-no-results v-if="!isPending && getInvoices && getInvoices.length === 0" />
        </div>
      </vel-card>
    </b-container>
  </vel-backoffice-page>
</template>

<script>
import { getDateFormatFromUserConfig, today } from '@/helpers/date.helpers';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import Action from '@/assets/svg/icons/open-link.svg';
import CheckSummaryFilters from './components/CheckSummaryFilters';
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import { getToken } from '@/helpers/token.helper';
import invoicesService from '@/services/invoices.service';
import { storeToRefs } from 'pinia';
import { useDataCheckSummary } from '@/stores/data/checks/check-summary.module';
import { useDataConfig } from '@/stores/data/config.module';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import VelAmount from '@/components/amount/VelAmount';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import ToolbarSelectors from '@/private/shared/toolbar/ToolbarSelectors';
import VelNoResults from '@/components/no-results/VelNoResults';

export default {
  name: 'ChecksSummaryReportPage',
  components: {
    VelCard,
    VelAmount,
    VelButton,
    Action,
    CheckSummaryFilters,
    VelBackofficePage,
    ToolbarSelectors,
    VelIcon,
    VelNoResults
  },
  mixins: [DeviceMixin],
  setup() {
    const uiDateRangeSelectorStore = useUIDateRangeSelector();
    const { getDateSelectorSelectedRange } = storeToRefs(uiDateRangeSelectorStore);

    return { getDateSelectorSelectedRange };
  },
  data() {
    return {
      newLocationRangeGeneration: true,
      showFilters: false,
      selectedRow: null,
      generating: false,
      sortBy: 'invoiceNumber',
      sortDesc: true
    };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useUILocationSelector, ['isGenerated', 'getLocationSelectorSelectedMultipleEntities']),
    ...mapPiniaState(useDataCheckSummary, [
      'getInvoices',
      'isPending',
      'getSortBy',
      'getSortDirection',
      'getHaveSurcharge',
      'getCurrentPage',
      'getPerPage',
      'getTotalResults'
    ]),
    columns() {
      return this.getHaveSurcharge ? this.allFields : this.allFields.filter(x => x.key !== 'tenderSurcharge');
    },
    allFields() {
      return [
        {
          key: 'invoiceNumber',
          label: this.$t('checkSummary.table.headers.checkNumber'),
          sortable: true,
          filterName: 'check',
          class: 'align-right'
        },
        {
          key: 'location.name',
          label: this.$t('checkSummary.table.headers.location'),
          sortable: true,
          filterName: 'location'
        },
        {
          key: 'invoiceTime',
          label: this.$t('checkSummary.table.headers.datetime'),
          sortable: true,
          filterName: 'time'
        },
        {
          key: 'tableNumber',
          label: this.$t('checkSummary.table.headers.table'),
          sortable: true,
          filterName: 'table',
          class: 'align-right'
        },
        {
          key: 'customers',
          label: this.$t('checkSummary.table.headers.customers'),
          sortable: true,
          filterName: 'customers',
          class: 'align-right'
        },
        {
          key: 'sellingEmployee.name',
          label: this.$t('checkSummary.table.headers.employee'),
          sortable: true,
          filterName: 'employeeName'
        },
        {
          key: 'sellingEmployee.remoteId',
          label: this.$t('checkSummary.table.headers.employeeNumber'),
          sortable: true,
          filterName: 'employeeNumber',
          class: 'align-right'
        },
        {
          key: 'total',
          label: this.$t('checkSummary.table.headers.total'),
          sortable: true,
          filterName: 'total',
          class: 'align-right'
        },
        {
          key: 'totalTaxAmount',
          label: this.$t('checkSummary.table.headers.taxes'),
          sortable: true,
          filterName: 'taxes',
          class: 'align-right'
        },
        {
          key: 'discounts',
          label: this.$t('checkSummary.table.headers.discounts'),
          sortable: true,
          filterName: 'discounts',
          class: 'align-right'
        },
        {
          key: 'services',
          label: this.$t('checkSummary.table.headers.services'),
          sortable: true,
          filterName: 'services',
          class: 'align-right'
        },
        {
          key: 'tenderName',
          label: this.$t('checkSummary.table.headers.tender'),
          sortable: true,
          filterName: 'tenderName'
        },
        {
          key: 'tenderSurcharge',
          label: this.$t('checkSummary.table.headers.surcharge'),
          sortable: true,
          filterName: 'tenderSurcharge',
          class: 'align-right'
        },
        {
          key: 'tenderAmount',
          label: this.$t('checkSummary.table.headers.amount'),
          sortable: true,
          filterName: 'tenderAmount',
          class: 'align-right'
        },
        {
          key: 'tenderTip',
          label: this.$t('checkSummary.table.headers.tip'),
          sortable: true,
          filterName: 'tenderTip',
          class: 'align-right'
        },
        {
          key: 'action',
          label: '',
          sortable: false
        }
      ];
    },
    perPage: {
      get() {
        return this.getPerPage;
      },
      set(value) {
        this.setPerPage(value);
      }
    },
    currentPage: {
      get() {
        return this.getCurrentPage;
      },
      set(value) {
        this.setCurrentPage(value);
      }
    }
  },
  watch: {
    async currentPage() {
      await this.fetchData();
    },
    async perPage() {
      await this.fetchData();
    },
    async isGenerated(newVal) {
      this.newLocationRangeGeneration = true;
      useDataCheckSummary().$reset();

      if (newVal) {
        await this.fetchData();
        this.newLocationRangeGeneration = false;
      }
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataCheckSummary, [
      'getCheckSummaryAction',
      'setSortBy',
      'setSortDirection',
      'setPerPage',
      'setCurrentPage',
      'getFilterQueryParams'
    ]),
    async fetchData() {
      await this.getCheckSummaryAction();
    },
    formatDateTime(value) {
      if (!value) {
        return '--';
      }

      const use24h = this.get.enable_military_time_format.value;
      const date = DateTime.fromISO(value);

      if (use24h) {
        return `${date.toFormat(getDateFormatFromUserConfig())} - ${date.toFormat('HH:mm')}`;
      }
      return `${date.toFormat(getDateFormatFromUserConfig())} - ${date.toFormat('hh:mm a')}`;
    },
    async sortingChanged(ctx) {
      this.setSortBy(this.allFields.find(a => a.key === ctx.sortBy).filterName || 'check');
      this.setSortDirection(ctx.sortDesc ? 'desc' : 'asc');

      await this.fetchData();
    },
    onRowSelected(record, index) {
      this.selectedRow = record;
    },
    rowClass(item, index) {
      if (!item) {
        return '';
      }

      if (this.selectedRow === item) {
        return 'selected';
      }

      return '';
    },
    async onExportXLS() {
      this.$ga.event('report', 'download', this.$route.name);
      this.generating = true;

      await invoicesService.downloadInvoiceSummaryXlsx(getToken(), { ...this.getFilterQueryParams() }).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  mounted() {
    this.newLocationRangeGeneration = this.getInvoices.length === 0;
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'checkDetails') {
      useDataCheckSummary().$reset();
    }
    next();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';
@import '@/components/button/VelButton.scss';

$font-size-small: rem-calc(12);
$font-size-normal: rem-calc(14);
$contrast-color: #00a9e1;

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.page {
  display: flex;
  overflow: hidden;
  background: #f5f5f5;

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    margin: rem-calc(10) rem-calc(10) rem-calc(0);

    @include breakpoint(large) {
      margin: rem-calc(32) rem-calc(40) rem-calc(0);
    }
  }
}

#table-container {
  * {
    font-size: $font-size-small;
  }

  flex-grow: 1;
  overflow: hidden;
  padding: 0;
  padding-bottom: 2px;

  .report {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .vel-card {
    height: 100%;
    overflow: hidden;
    padding: 20px 24px;
    box-shadow: 0px 2px 4px #00000014;

    /deep/ .vel-card__body,
    .b-table-sticky-header {
      height: 100%;
      max-height: 100%;
      padding-top: 0;
      gap: 10px;
    }

    /deep/ .vel-card__body {
      display: flex;
      flex-direction: column;
    }
  }

  /deep/ table {
    padding-top: rem-calc(12);

    th {
      border-top: none;
      background-image: none;
      background: white;
      padding-top: rem-calc(16);
      padding-bottom: rem-calc(16);
      padding-right: 0.5rem;

      .container {
        align-items: center;
        cursor: pointer;
        display: flex;
        user-select: none;
        word-break: keep-all;
        padding: 0;
      }

      .sort-icon {
        margin-left: rem-calc(5);

        & .vel-table-header__sort-icon_down,
        & .vel-table-header__sort-icon_up {
          color: $contrast-color;
        }

        & .vel-table-header__sort-icon_up {
          transform: rotate(180deg);
        }
      }

      &.align-right > .container {
        justify-content: end;
      }
    }

    tbody {
      td {
        &,
        * {
          font-size: $font-size-normal !important;
        }

        min-width: unset;
        padding: 0;
        text-align: left;
        text-overflow: ellipsis;
        vertical-align: top !important;
        @include breakpoint(medium) {
          padding: 1em 1.25em 1em 0.5em;
        }

        &.align-right {
          text-align: right;
        }

        &:last-child a {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          padding: 0.5em;

          & > .action {
            color: $contrast-color;
            height: 16px;
            width: 16px;
          }
        }
      }

      tr.selected {
        &:not(.b-table-empty-row):not(.b-table-busy-slot) {
          & td {
            color: white;
            background: $contrast-color;

            &:first-child {
              border-top-left-radius: 0.5em;
              border-bottom-left-radius: 0.5em;
            }
            &:last-child {
              border-top-right-radius: 0.5em;
              border-bottom-right-radius: 0.5em;

              & a {
                background: white;
                border-radius: 50%;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .filters_toggle {
    overflow: hidden;

    * {
      cursor: pointer;
      color: $contrast-color;
    }

    & > .b-icon {
      padding-left: 5px;
      height: 16px;
      width: 16px;
    }
  }

  .fade-enter-active {
    animation: fade 0.5s ease-in forwards;
  }

  .fade-leave-active {
    animation: fade 0.5s reverse ease-in forwards;
  }

  @keyframes fade {
    0% {
      max-height: 0%;

    }
    100% {
      max-height: 100%;
    }
  }

  .filters {
    align-items: flex-end;
    display: flex;
    margin-bottom: 0.5em;

    .btn-secondary {
      background: #f5f5f5;
      color: $contrast-color;
      border: none;
    }

    &__fields {
      display: flex;
      flex-grow: 1;
      gap: 5px;
      padding-right: rem-calc(16);

      .el-select,
      /deep/ .el-select__tags,
      /deep/ .el-input,
      /deep/ .el-select__input,
      /deep/ .el-input__inner,
      /deep/ .el-input__suffix {
        max-height: 32px;
        height: 32px;
      }

      /deep/ .el-input__icon {
        line-height: 32px;
        color: $contrast-color;
      }

      input.form-control {
        height: 32px;
      }

      .form-group {
        max-width: 150px;
        .input-group-text {
          width: 24px;
          border-right: none;
          background-color: transparent !important;
          color: #d9d9d9;
          padding: 0;

          svg {
            margin-left: 4px;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      gap: 5px;
      border-left: 1px solid #dbdbdb;
      padding-left: rem-calc(16);
    }
  }
}

.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}
</style>
