<template>
  <vel-page
    :multiple-locations="locationsList && locationsList.length > 1"
    :enable-all-currencies="true"
    :show-selector-button="true"
    class="report"
    v-bind="pageAttrs"
  >
    <template v-slot:export>
      <vel-button
        :disabled="loading || !Object.values(data).length"
        :loading="generating"
        type="primary"
        @click="exportXLS"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button"
        v-if="Object.values(data).length"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>

    <template v-slot:toolbar-options>
      <div
        style="padding: 8px 1rem 0 1rem;"
        v-if="(!locationCount || locationCount > 1) && getCurrencySelectorSelected !== ''"
      >
        <vel-checkbox v-model="isConsolidated" @change="onConsolidate()">
          {{ $t('toolbar.selectors.consolidate') }}
        </vel-checkbox>
      </div>
    </template>
    <vel-spinner class="spinner" v-if="loading" />
    <template v-if="!loading">
      <div class="no-data" v-if="!Object.values(data).length">{{ $t('itemSalesCategory.noData') }}</div>
      <template v-else>
        <vel-card
          v-for="location in Object.values(data).sort((a, b) => a.name.localeCompare(b.name))"
          :key="location.id"
        >
          <h5
            style="color: #409eff; text-transform: uppercase;"
            v-if="isConsolidable && (!locationCount || locationCount > 1)"
          >
            {{
              !locationCount
                ? $t('toolbar.selectors.consolidate') + ' - ' + $t('dashboard.locationSelector.allLocationsText')
                : $t('dashboard.locationSelector.locationsSelected', {
                    count: locationCount,
                    total: locationsBySelectedGroup.length
                  })
            }}
          </h5>
          <h5 style="color: #409eff;" v-else>
            {{ (location.reportingDisplayName || location.name || '').toUpperCase() }}
          </h5>
          <table>
            <thead>
              <tr>
                <th style="width: 160px;">{{ $t('discount.discounts.table.headers.date') }}</th>
                <th v-if="!isConsolidable" style="width: 120px;">{{ $t('discount.discounts.table.headers.time') }}</th>
                <th style="min-width: 200px; width: 100%; max-width: 400px;">
                  {{ $t('discount.discounts.table.headers.type') }}
                </th>
                <th class="text-right" style="width: 200px;">{{ $t('discount.discounts.table.headers.amount') }}</th>
                <th v-if="!isConsolidable" class="text-right" style="width: 200px;">
                  {{ $t('discount.discounts.table.headers.check') }}
                </th>
                <th v-if="!isConsolidable" class="text-right" style="width: 200px;">
                  {{ $t('discount.discounts.table.headers.waiter') }}
                </th>
                <th v-if="isConsolidable" class="text-right" style="width: 200px;">
                  {{ $t('discount.discounts.table.headers.count') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="dates in location.dates">
                <tr v-for="(tenderType, i) in dates.discounts" :key="tenderType.id">
                  <td v-bind:class="{ whitecell: i, date: true }">
                    <span v-if="!i">{{ dates.date }}</span>
                  </td>
                  <td v-if="!isConsolidable">
                    {{
                      tenderType.time.replace(/^2(4|5|6|7|8)/gi, matched => {
                        return '0' + (matched - 24).toString();
                      })
                    }}
                  </td>
                  <td>
                    {{
                      (tenderType.isItemDiscount
                        ? tenderType.item && tenderType.item.name
                        : tenderType.discount && tenderType.discount.name) || '--'
                    }}
                  </td>
                  <td class="text-right">
                    <vel-amount
                      :amount="tenderType.amount"
                      :currency="location.currency || tenderType.currency"
                    ></vel-amount>
                  </td>
                  <td v-if="!isConsolidable" class="text-right">{{ tenderType.check }}</td>
                  <td v-if="!isConsolidable" class="text-right">
                    {{ (tenderType.employee && tenderType.employee.name) || '--' }}
                  </td>
                  <td v-if="isConsolidable" class="text-right">{{ tenderType.count }}</td>
                </tr>
                <tr v-if="dates.discounts.length" class="subtotal" :key="dates.id">
                  <td>
                    <b>{{ $t('discount.discounts.subtotal') }}</b>
                  </td>
                  <td v-if="!isConsolidable"></td>
                  <td></td>
                  <td class="text-right">
                    <b>
                      <vel-amount
                        :amount="dates.totalAmount"
                        :currency="location.currency || tenderType.currency"
                      ></vel-amount>
                    </b>
                  </td>
                  <td v-if="!isConsolidable"></td>
                  <td v-if="!isConsolidable"></td>
                  <td v-if="isConsolidable" class="text-right">{{ dates.totalCount }}</td>
                </tr>
                <template v-if="dates.discountsCash.length">
                  <tr v-for="(tenderType, i) in dates.discountsCash" :key="tenderType.id">
                    <td v-bind:class="{ whitecell: i, date: true }">
                      <span v-if="!i">{{ dates.date }}</span>
                    </td>
                    <td v-if="!isConsolidable">
                      {{
                        tenderType.time.replace(/^2(4|5|6|7|8)/gi, matched => {
                          return '0' + (matched - 24).toString();
                        })
                      }}
                    </td>
                    <td>
                      {{
                        (tenderType.isItemDiscount
                          ? tenderType.item && tenderType.item.name
                          : tenderType.discount && tenderType.discount.name) || '--'
                      }}
                    </td>
                    <td class="text-right">
                      <vel-amount
                        :amount="tenderType.amount"
                        :currency="location.currency || tenderType.currency"
                      ></vel-amount>
                    </td>
                    <td v-if="!isConsolidable" class="text-right">{{ tenderType.check }}</td>
                    <td v-if="!isConsolidable" class="text-right">
                      {{ (tenderType.employee && tenderType.employee.name) || '' }}
                    </td>
                    <td v-if="isConsolidable" class="text-right">{{ tenderType.count }}</td>
                  </tr>
                  <tr class="subtotal" :key="dates.id">
                    <td>
                      <b>{{ $t('discount.discounts.subtotal') }}</b>
                    </td>
                    <td v-if="!isConsolidable"></td>
                    <td></td>
                    <td class="text-right">
                      <b>
                        <vel-amount
                          :amount="dates.totalAmountCash"
                          :currency="location.currency || tenderType.currency"
                        ></vel-amount>
                      </b>
                    </td>
                    <td v-if="!isConsolidable"></td>
                    <td v-if="!isConsolidable"></td>
                    <td v-if="isConsolidable" class="text-right">{{ dates.totalCount }}</td>
                  </tr>
                  <tr v-if="dates.discountsCash.length && dates.discounts.length" class="total" :key="dates.id">
                    <td>
                      <b>{{ $t('discount.discounts.dailyTotal', { date: dates.date }) }}</b>
                    </td>
                    <td v-if="!isConsolidable"></td>
                    <td class="text-right"></td>
                    <td class="text-right">
                      <b>
                        <vel-amount
                          :amount="dates.totalAmountCash + dates.totalAmount"
                          :currency="location.currency || tenderType.currency"
                        ></vel-amount>
                      </b>
                    </td>
                    <td v-if="!isConsolidable"></td>
                    <td v-if="!isConsolidable"></td>
                    <td v-if="isConsolidable" class="text-right">{{ dates.totalCount }}</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>

          <table v-if="isConsolidable && discountSalesTotals.length">
            <tr v-for="(discount, index) of discountSalesTotals" :key="index">
              <td style="min-width: 200px; width: 100%; max-width: 400px;">
                {{ discount.name }}
              </td>
              <td class="text-right" style="width: 200px;">
                <vel-amount :amount="discount.amount" :currency="discount.currency"></vel-amount>
              </td>
              <td class="text-right" style="width: 200px;">{{ discount.count }}</td>
            </tr>
          </table>

          <table>
            <thead>
              <tr>
                <th style="width: 160px;"></th>
                <th v-if="!isConsolidable" style="width: 120px;"></th>
                <th style="min-width: 200px; width: 100%;"></th>
                <th class="text-right" style="width: 200px;"></th>
                <th v-if="!isConsolidable" class="text-right" style="width: 200px;"></th>
                <th v-if="!isConsolidable" class="text-right" style="width: 200px;"></th>
                <th v-if="isConsolidable" class="text-right" style="width: 200px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="subCategoryTotal">
                <td>
                  <b>{{ $t('discount.discounts.total') }}</b>
                </td>
                <td v-if="!isConsolidable"></td>
                <td></td>
                <td class="text-right">
                  <vel-amount :amount="location.allTotalAmount" :currency="location.currency"></vel-amount>
                </td>
                <td v-if="!isConsolidable"></td>
                <td v-if="!isConsolidable"></td>
                <td v-if="isConsolidable" class="text-right">{{ location.allTotalCount }}</td>
              </tr>
            </tbody>
          </table>
        </vel-card>
      </template>
    </template>
  </vel-page>
</template>

<script>
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import GeneratedTime from '@/mixins/generated-time-mixin';
import VelAmount from '@/components/amount/VelAmount';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelCheckbox from '@/components/checkbox/VelCheckbox';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import VelSpinner from '@/components/spinner/VelSpinner';
import clonedeep from 'lodash.clonedeep';
import { environment } from '@/configs';
import { getToken } from '@/helpers/token.helper';
import router from '@/router';
import salesService from '@/services/sales.service';
import { getDateFormatFromUserConfig, getTimeFormatFromUserConfig, today } from '@/helpers/date.helpers';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { mapState as mapPiniaState } from 'pinia';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { mapActions as mapPiniaActions, storeToRefs } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataSalesLocations } from '@/stores/data/sales/locations.module';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import { useDataConfig } from '@/stores/data/config.module';
import { useDataGroups } from '@/stores/data/groups.module';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';

export default {
  name: 'SummaryReportPage',
  components: {
    VelPage,
    VelButton,
    VelAmount,
    VelCard,
    VelSpinner,
    VelIcon,
    VelCheckbox
  },
  mixins: [GeneratedTime, DeviceMixin],
  data() {
    return {
      isConsolidated: localStorage.getItem('isConsolidated') === 'true',
      loading: true,
      generating: false,
      updateIndicators: 0,
      updateCharts: 1,
      diffValues: {},
      discountSalesTotals: [],
      serviceData: [],
      voids: {},
      corrections: {},
      refunds: [],
      expand: {
        category: {},
        subCategory: {}
      },
      previousIndicators: {
        itemCorrectionsAmount: 0,
        voidsAmount: 0,
        refundsAmount: 101
      }
    };
  },
  setup() {
    const locationsSalesStore = useDataSalesLocations();

    const { getLocationsSales } = storeToRefs(locationsSalesStore);

    return { getSales: getLocationsSales, locationsSalesStore };
  },
  computed: {
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useUILocationSelector, ['getLocationSelectorSelected', 'locations', 'getLocationById']),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useDataGroups, ['getGroupsRows']),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities'
    }),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataLocationsStore, {
      locationsList: 'locations',
      getLocationsById: 'getLocationsById',
      locationsBySelectedGroup: 'locationsBySelectedGroup',
      getLocationsIsLoading: 'getLocationsIsLoading'
    }),
    ...mapPiniaState(useDataSalesIndicators, [
      'getIndicatorsVoids',
      'getIndicatorsVoidsAmount',
      'getIndicatorsRefundsAmount',
      'getIndicatorsSalesVolume',
      'getIndicatorsRefunds',
      'getIndicatorsItemCorrections',
      'getIndicatorsItemCorrectionsAmount'
    ]),
    isConsolidable() {
      return (
        this.isConsolidated &&
        this.getCurrencySelectorSelected !== '' &&
        (!this.locationCount || this.locationCount > 1)
      );
    },
    chartAttrs() {
      return {
        locationId: this.getLocationSelectorSelected,
        from: this.getDateSelectorSelectedRange[0],
        to: this.getDateSelectorSelectedRange[1],
        limit: this.get.charts_size_limitation.value
      };
    },
    data() {
      const data = {};
      let consolidatedData = null;

      if (this.isConsolidable && this.serviceData.length > 1) {
        consolidatedData = [clonedeep(this.serviceData[0])];

        for (let i = 1; i < this.serviceData.length; i++) {
          this.serviceData[i].accountingDates.forEach(ad => {
            let dateExists = false;
            consolidatedData[0].accountingDates.forEach((a, b) => {
              if (ad.date === a.date) {
                dateExists = true;
                const discounts = ad.discounts
                  .map(x => ({
                    ...x,
                    locationName:
                      (this.serviceData[i].location.reportingDisplayName &&
                        this.serviceData[i].location.reportingDisplayName !== '' &&
                        this.serviceData[i].location.reportingDisplayName) ||
                      this.serviceData[i].location.name
                  }))
                  .reduce((ac, v) => {
                    const discountName = (v.discount && v.discount.name) || '--';
                    if (!ac[discountName]) {
                      ac[discountName] = { ...v, count: 1 };
                    } else {
                      ac[discountName].amount += Number(v.amount);
                      ac[discountName].count++;
                    }
                    return ac;
                  }, {});

                consolidatedData[0].accountingDates[b].discounts = Object.values(discounts);
              }
            });

            if (!dateExists) {
              const discounts = ad.discounts
                .map(x => ({
                  ...x,
                  locationName:
                    (this.serviceData[i].location.reportingDisplayName &&
                      this.serviceData[i].location.reportingDisplayName !== '' &&
                      this.serviceData[i].location.reportingDisplayName) ||
                    this.serviceData[i].location.name
                }))
                .reduce((ac, v) => {
                  const discountName = (v.discount && v.discount.name) || '--';
                  if (!ac[discountName]) {
                    ac[discountName] = { ...v, count: 1 };
                  } else {
                    ac[discountName].amount += Number(v.amount);
                    ac[discountName].count++;
                  }
                  return ac;
                }, {});
              consolidatedData[0].accountingDates.push({
                location: {
                  detailedCurrency: {
                    currencySymbol: '$'
                  }
                },
                date: DateTime.fromISO(ad.date, { zone: 'UTC' }).toFormat(getDateFormatFromUserConfig()),
                discounts: Object.values(discounts)
              });
            }
          });
        }
      }
      (consolidatedData || this.serviceData).forEach(c => {
        let allTotalAmount = 0;
        let allTotalCount = 0;

        data[c.locationId] = {
          name: c.location && (c.location.reportingDisplayName || c.location.name),
          currency:
            (c.location && c.location.detailedCurrency && c.location.detailedCurrency.currencySymbol) ||
            (c.accountingDates &&
              c.accountingDates[0] &&
              c.accountingDates[0].discounts &&
              c.accountingDates[0].discounts[0] &&
              c.accountingDates[0].discounts[0].detailedCurrency.currencySymbol),
          id: (c.location && c.location.id) || null,
          dates: (c.accountingDates || [])
            .map(ad => {
              let totalAmount = 0;
              let totalCount = 0;
              let totalAmountCash = 0;
              console.log('AAAAAAAAAA', c.accountingDates);
              const dateData = {
                ...ad,
                date: DateTime.fromISO(ad.date, { zone: 'UTC' }).toFormat(getDateFormatFromUserConfig()),
                totalAmount: 0,
                discounts: (ad.discounts || [])
                  .filter(d => d.salesDiscountsOrigin !== 2)
                  .map(d => {
                    allTotalAmount += d.amount;
                    allTotalCount += d.count;
                    totalAmount += d.amount;
                    totalCount += d.count;
                    return {
                      ...d,
                      time: DateTime.fromISO(d.invoiceTime).toFormat(getTimeFormatFromUserConfig())
                    };
                  })
                  .sort((a, b) => (a.invoiceTime || '0').localeCompare(b.invoiceTime || '0')),

                discountsCash: (ad.discounts || [])
                  .filter(d => d.salesDiscountsOrigin === 2)
                  .map(d => {
                    allTotalAmount += d.amount;
                    allTotalCount += d.count;
                    totalAmountCash += d.amount;
                    return {
                      ...d,
                      time: DateTime.fromISO(d.invoiceTime).toFormat(getTimeFormatFromUserConfig())
                    };
                  })
                  .sort((a, b) => a.invoiceTime.localeCompare(b.invoiceTime))
              };
              dateData.totalAmount = totalAmount;
              dateData.totalCount = totalCount;
              dateData.totalAmountCash = totalAmountCash;
              return dateData;
            })
            .sort((a, b) => a.date.localeCompare(b.date))
        };
        data[c.locationId].allTotalAmount = allTotalAmount;
        data[c.locationId].allTotalCount = allTotalCount;
      });
      return data;
    },
    config() {
      return environment;
    },
    pageAttrs() {
      return {
        enableToolbar: true,
        enableCurrency: true,
        enableDateRange: true,
        title: this.$t('pages.reports.discount'),
        showCrumbs: false,
        failed: this.hasPageFailed,
        generatedTime: this.generatedTime,
        showDateCompare: this.showLocationsSales
      };
    },
    hasPageFailed() {
      return this.false;
    },
    isPageLoading() {
      return this.getLocationsIsLoading;
    },
    showLocationsSales() {
      return !this.getLocationSelectorSelected || this.locations.length === 1;
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    async onConsolidate() {
      this.loading = true;
      this.gen(false);
      localStorage.setItem('isConsolidated', this.isConsolidated);
      await this.fetchData();
    },
    getGrandTotalAmount(locationId) {
      return Object.values(this.data[locationId].categories).reduce(
        (a, b) => this.getCategoryGrandTotalAmount(locationId, b.id) + a,
        0
      );
    },
    getCategoryGrandTotalAmount(locationId, categoryId) {
      return Object.values(this.data[locationId].categories[categoryId].categories).reduce((a, b) => b.amount + a, 0);
    },

    getGrandTotalClients(locationId) {
      return Object.values(this.data[locationId].categories).reduce(
        (a, b) => this.getCategoryGrandTotalClients(locationId, b.id) + a,
        0
      );
    },
    getCategoryGrandTotalClients(locationId, categoryId) {
      return Object.values(this.data[locationId].categories[categoryId].categories).reduce((a, b) => b.clients + a, 0);
    },
    async exportXLS() {
      this.generating = true;
      this.$ga.event('report', 'download', this.$route.name);

      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };

      await salesService.downloadLocationSalesAccountingDiscountsXlsx(getToken(), { ...routeQuery }).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    async fetchData(orderBy = 'date', order = 'asc') {
      this.loading = true;
      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };
      const serviceVoidsData = (!this.isConsolidable
        ? await salesService.getLocationSalesAccountingDiscounts
        : await salesService.getSalesAccountingDiscounts)(getToken(), {
        ...routeQuery
      })
        .then(ld => {
          if (this.isConsolidable) {
            this.discountSalesTotals = (ld.discountSalesTotals || []).sort((a, b) => a.name.localeCompare(b.name));
          }
          this.serviceData = !this.isConsolidable
            ? ld.content || []
            : [
                {
                  accountingDates: ld.discountSalesDaily.map(x => ({
                    date: x.day,
                    discounts: x.discountSales
                      .map((y, k) => ({
                        ...y,
                        id: k,
                        discount: { name: y.name }
                      }))
                      .sort((a, b) => (!this.isConsolidable ? 1 : a.discount.name.localeCompare(b.discount.name)))
                  }))
                }
              ];
        })
        .catch(() => {
          this.loading = false;
          this.generating = false;
        })
        .finally(() => {
          this.loading = false;
          this.generating = false;
        });
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  async mounted() {
    await this.fetchData();
    // this.generateTimeAt('data/sales/indicators/getIndicatorsSuccess');
  }
};
</script>
<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .vel-card__body {
  overflow: auto;
}

table {
  border-spacing: 0;
  margin-top: 1rem;
  min-width: 1200px;
  table-layout: fixed;
  width: 100%;
}

/* stylelint-disable */
th {
  border-bottom: 1px solid #e5e5e5;
  font-size: 0.9375rem;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

td {
  border-top: 1px solid #e5e5e5;
  font-size: 0.9375rem;
  overflow: hidden;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  word-break: keep-all;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.arrow-right {
  /* Vector */
  background: #000;
  bottom: 25%;
  left: 35.79%;
  position: absolute;
  right: 33.33%;
  top: 25%;
}

.date {
  width: 120px;
}

.time {
  max-width: 100px;
}

.itemName {
  width: 360px;
}

.price {
  width: 120px;
}

.quantity {
  width: 90px;
}

.check {
  width: 120px;
}

.categoryName {
  width: 300px;
}

.employee {
  width: 300px;
}

.authorized {
  width: 300px;
}

.location {
  border: none;
  width: 200px;
}

.total {
  background-color: #d0d0d0 !important;
  font-weight: bold;
}

.subCategoryTotal td {
  background-color: #e0e0e0;
  font-weight: bold;
}

.itemTotal td {
  background-color: #f0f0f0;
  font-weight: bold;
}

.subtotal td {
  background-color: #f6f6f6;
  border-bottom: 1px solid #c0c0c0;

  &.location {
  }
}

.order-header {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;

  .sort {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .order {
      height: 9px;
      width: 9px;

      &.active {
        color: #00a9e1;
        fill: #00a9e1;
      }
    }
  }
}

.multiple {
  cursor: pointer;
}

.subtotal + tr .location span {
  display: none;
}

.empty {
  border-top: 1px solid #fff;
}

tr {
  border: none;
}

.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

strong {
  font-weight: 700;
}

td {
  font-size: 0.9375rem;
}

.expanded > td.qty span {
  display: none;
}

.expanded > td.amount span {
  display: none;
}

.no-data {
  font-size: 2rem;
  color: #4f4f4f;
  text-align: center;
  margin: 2rem;
}

.whitecell {
  border: none;
  border-right: 1px solid #e5e5e5;
}

.date {
  border-right: 1px solid #e5e5e5;
}
</style>
