var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portal", { attrs: { to: "app" } }, [
    _c(
      "div",
      { staticClass: "permission-add-modal" },
      [
        _c("vel-modal", {
          attrs: { dismissable: "" },
          on: { dismiss: _vm.handleCancel },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("user.permissions.modals.add.title")) +
                      " "
                  )
                ]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [
                  _c("permission-form", {
                    staticClass: "permission-add-modal__form",
                    attrs: {
                      locationsOptions: _vm.locations,
                      groupsOptions: _vm.groups,
                      permission: _vm.permission,
                      allowMultipleLocations: true,
                      allowMultipleGroups: true
                    },
                    on: { submit: _vm.handleSuccess, cancel: _vm.handleCancel }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }