var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "graph" },
    [
      _vm.showLocationsSales
        ? _c(
            "vel-card",
            {
              directives: [
                {
                  name: "can",
                  rawName: "v-can:show",
                  value: "locations_report_show_locations_sales",
                  expression: "'locations_report_show_locations_sales'",
                  arg: "show"
                }
              ],
              style: _vm.getOrder("locations_report_show_locations_sales")
            },
            [
              _c(
                "locations-sales-graph",
                _vm._b(
                  { attrs: { "show-previous-series": true } },
                  "locations-sales-graph",
                  _vm.chartAttrs,
                  false
                )
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasSingleLocation
        ? _c(
            "vel-card",
            {
              directives: [
                {
                  name: "can",
                  rawName: "v-can:show",
                  value: "locations_report_show_employees_sales_top10",
                  expression: "'locations_report_show_employees_sales_top10'",
                  arg: "show"
                }
              ],
              style: _vm.getOrder("locations_report_show_employees_sales_top10")
            },
            [
              _c(
                "employee-graph",
                _vm._b({}, "employee-graph", _vm.chartAttrs, false)
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can:show",
              value: "locations_report_show_item_sales_top10",
              expression: "'locations_report_show_item_sales_top10'",
              arg: "show"
            }
          ],
          style: _vm.getOrder("locations_report_show_item_sales_top10")
        },
        [
          _c(
            "items-sales-graph",
            _vm._b({}, "items-sales-graph", _vm.chartAttrs, false)
          )
        ],
        1
      ),
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can:show",
              value: "locations_report_show_gross_sales_per_order_mode",
              expression: "'locations_report_show_gross_sales_per_order_mode'",
              arg: "show"
            }
          ],
          style: _vm.getOrder(
            "locations_report_show_gross_sales_per_order_mode"
          )
        },
        [_c("zone-sales", _vm._b({}, "zone-sales", _vm.chartAttrs, false))],
        1
      ),
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can:show",
              value: "locations_report_show_gross_sales_per_department",
              expression: "'locations_report_show_gross_sales_per_department'",
              arg: "show"
            }
          ],
          style: _vm.getOrder(
            "locations_report_show_gross_sales_per_department"
          )
        },
        [
          _c(
            "department-sales",
            _vm._b({}, "department-sales", _vm.chartAttrs, false)
          )
        ],
        1
      ),
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can:show",
              value: "locations_report_show_gross_sales_per_mode",
              expression: "'locations_report_show_gross_sales_per_mode'",
              arg: "show"
            }
          ],
          style: _vm.getOrder("locations_report_show_gross_sales_per_mode")
        },
        [_c("mode-sales", _vm._b({}, "mode-sales", _vm.chartAttrs, false))],
        1
      ),
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can:show",
              value: "locations_report_show_customers_per_mode",
              expression: "'locations_report_show_customers_per_mode'",
              arg: "show"
            }
          ],
          style: _vm.getOrder("locations_report_show_customers_per_mode")
        },
        [
          _c(
            "mode-customers",
            _vm._b({}, "mode-customers", _vm.chartAttrs, false)
          )
        ],
        1
      ),
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can:show",
              value: "locations_report_show_mode_sales_per_customers",
              expression: "'locations_report_show_mode_sales_per_customers'",
              arg: "show"
            }
          ],
          style: _vm.getOrder("locations_report_show_mode_sales_per_customers")
        },
        [
          _c(
            "mode-sales-per-customers",
            _vm._b({}, "mode-sales-per-customers", _vm.chartAttrs, false)
          )
        ],
        1
      ),
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can:show",
              value: "locations_report_show_workstations_sales",
              expression: "'locations_report_show_workstations_sales'",
              arg: "show"
            }
          ],
          style: _vm.getOrder("locations_report_show_workstations_sales")
        },
        [
          _c(
            "workstations-sales",
            _vm._b({}, "workstations-sales", _vm.chartAttrs, false)
          )
        ],
        1
      ),
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can:show",
              value: "locations_report_show_gross_sales_per_big_division",
              expression:
                "'locations_report_show_gross_sales_per_big_division'",
              arg: "show"
            }
          ],
          style: _vm.getOrder(
            "locations_report_show_gross_sales_per_big_division"
          )
        },
        [
          _c(
            "big-division-sales-graph",
            _vm._b({}, "big-division-sales-graph", _vm.chartAttrs, false)
          )
        ],
        1
      ),
      _c(
        "vel-card",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can:show",
              value: "locations_report_show_discounts_table",
              expression: "'locations_report_show_discounts_table'",
              arg: "show"
            }
          ],
          style: _vm.getOrder("locations_report_show_discounts_table")
        },
        [_c("discounts-table")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }