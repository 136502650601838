var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "big-divisions-page",
      attrs: { loading: _vm.loading },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm._v(" " + _vm._s(_vm.$t("bigDivisionsPage.title")) + " ")
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "big-divisions-page-location-selector-wrapper" },
        [
          _c("vel-location-selector", {
            staticClass: "big-divisions-page-location-selector",
            attrs: {
              allLocationsLabel: _vm.$t(
                "dashboard.locationSelector.allLocationsText"
              ),
              locationId: _vm.locationId,
              locationsOptions: _vm.locations,
              disabled: _vm.getLocationsIsLoading
            },
            on: { change: _vm.onLocationChange }
          })
        ],
        1
      ),
      _c(
        "vel-card",
        [
          _c("big-divisions-list", {
            attrs: {
              data: _vm.bigDivisions,
              initialLength: _vm.selectTotal,
              filterValue: _vm.filter,
              filterCallback: _vm.filterCallback
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }