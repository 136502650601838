var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "bootstrap px-2",
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "px-3 py-1 row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-12 d-flex justify-content-end align-items-center"
                  },
                  [
                    _c(
                      "b-form-group",
                      { staticClass: "d-none d-md-block" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("employee.list.rowsPerPage")) +
                            " "
                        ),
                        _c("b-form-select", {
                          staticClass: "mx-3",
                          staticStyle: { width: "80px" },
                          attrs: {
                            size: "sm",
                            options: [10, 25, 50, 100],
                            disabled:
                              _vm.isLoading ||
                              _vm.generating ||
                              !_vm.locations.length
                          },
                          on: { change: _vm.fetchData },
                          model: {
                            value: _vm.perPage,
                            callback: function($$v) {
                              _vm.perPage = $$v
                            },
                            expression: "perPage"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.locations.length
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "d-none d-md-flex align-self-center mr-6"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("pagination.format", {
                                    first:
                                      _vm.currentPage * _vm.perPage -
                                      _vm.perPage +
                                      1,
                                    last: Math.min(
                                      _vm.currentPage * _vm.perPage,
                                      _vm.totalResults
                                    ),
                                    total: _vm.totalResults
                                  })
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("b-pagination", {
                      staticClass: "m-0 ml-2",
                      attrs: {
                        size: "sm",
                        pills: "",
                        "per-page": _vm.perPage,
                        "total-rows": _vm.totalResults,
                        limit: 5,
                        align: "right",
                        disabled:
                          _vm.isLoading ||
                          _vm.generating ||
                          !_vm.locations.length
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticStyle: { "border-bottom": "8px solid rgb(245, 245, 245)" } },
          [
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-between",
                staticStyle: {
                  "border-bottom": "1px solid rgba(76, 86, 103, 0.2)",
                  padding: "0.8rem 1.5rem"
                }
              },
              [
                _c(
                  "h1",
                  {
                    staticClass: "title-right my-0",
                    staticStyle: { "font-size": "1.5rem" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("locationStatus.title")) + " ")]
                ),
                Object.values(_vm.locations).length
                  ? _c("vel-export", {
                      attrs: {
                        disabled:
                          _vm.isLoading || !Object.values(_vm.locations).length,
                        generating: _vm.generating,
                        "enable-pdf": true,
                        "enable-xls": true
                      },
                      on: {
                        exportPDF: _vm.onExportPDF,
                        exportXLS: _vm.onExportXLS
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "p-2 px-4" },
        [
          _c("vel-group-selector", {
            staticClass: "group-selector",
            attrs: {
              allowGlobalGroupOption: "",
              allGroupsLabel: _vm.$t("dashboard.groupSelector.allGroupsText"),
              groupId: _vm.groupId,
              groupsOptions: _vm.groups,
              disabled: _vm.groupsLoading,
              multiple: false,
              autofocus: "",
              filterable: "",
              showGlobalGroupOptionGroupLabel: false
            },
            on: { change: _vm.handleGroupChange }
          }),
          _c(
            "vel-button",
            {
              attrs: { id: "generateButton" },
              on: {
                click: function($event) {
                  return _vm.fetchData()
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    this.newGeneration
                      ? _vm.$t("toolbar.selectors.generateLabel")
                      : _vm.$t("locationStatus.refresh")
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-container",
        {
          staticClass: "h-100 px-4 mb-4 mt-2",
          attrs: { fluid: "", id: "table-container" }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading"
                }
              ],
              staticClass: "text-center"
            },
            [_c("b-spinner", { attrs: { variant: "primary" } })],
            1
          ),
          _c(
            "vel-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading && !_vm.newGeneration,
                  expression: "!isLoading && !newGeneration"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "report" },
                [
                  _c("div", { staticClass: "filters_toggle_container" }, [
                    _c(
                      "span",
                      {
                        staticClass: "filters_toggle",
                        on: {
                          click: function($event) {
                            _vm.showFilters = !_vm.showFilters
                          }
                        }
                      },
                      [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.showFilters
                                  ? _vm.$t("locationStatus.filters.hide")
                                  : _vm.$t("locationStatus.filters.show")
                              ) +
                              " "
                          )
                        ]),
                        _c("b-icon", { attrs: { icon: "chevron-down" } })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _c("LocationsFilters", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showFilters,
                            expression: "showFilters"
                          }
                        ],
                        ref: "locationsFilters",
                        attrs: { disabled: _vm.isLoading },
                        on: { filtersChanged: _vm.fetchData }
                      })
                    ],
                    1
                  ),
                  _vm.isLoading || _vm.locations.length > 0
                    ? _c("b-table", {
                        ref: "table",
                        staticClass: "locationStatusTable",
                        attrs: {
                          "primary-key": "id",
                          busy: _vm.isLoading,
                          items: _vm.locations,
                          fields: _vm.columns,
                          responsive: "sm",
                          "sticky-header": "",
                          "no-local-sorting": true,
                          "sort-by": _vm.sortBy,
                          "sort-desc": _vm.sortDesc
                        },
                        on: { "sort-changed": _vm.sortingChanged },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "table-busy",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center text-danger my-2"
                                    },
                                    [
                                      _c("b-spinner", {
                                        staticClass: "align-middle p-2 m-4",
                                        attrs: { variant: "primary" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "head()",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.bottom",
                                          modifiers: {
                                            hover: true,
                                            bottom: true
                                          }
                                        }
                                      ],
                                      staticClass: "container",
                                      attrs: {
                                        id: "table_header_" + scope.field.key,
                                        title: scope.field.tooltip
                                          ? scope.field.tooltip
                                          : null
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(scope.field.label))
                                      ]),
                                      scope.field.tooltip
                                        ? _c("vel-icon", {
                                            staticStyle: {
                                              fill: "#2a314261",
                                              color: "#2a314261",
                                              "margin-left": "0.5rem"
                                            },
                                            attrs: {
                                              name: "question-circle",
                                              modifier: "on-bright",
                                              size: "small"
                                            }
                                          })
                                        : _vm._e(),
                                      scope.field.sortable || false
                                        ? _c(
                                            "div",
                                            { staticClass: "sort-icon" },
                                            [
                                              _c("b-icon", {
                                                class:
                                                  "vel-table-header__sort" +
                                                  (_vm.sortBy ===
                                                  scope.field.key
                                                    ? _vm.sortDesc
                                                      ? "-icon_down"
                                                      : "-icon_up"
                                                    : ""),
                                                attrs: { icon: "arrow-down" }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(licenceNumber)",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(scope.item.licenceNumber) + " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(locationName)",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(scope.item.name || "--") + " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(status)",
                              fn: function(scope) {
                                return [
                                  _c("LocationState", {
                                    attrs: { status: scope.item.status.health }
                                  })
                                ]
                              }
                            },
                            {
                              key: "cell(lastActivity)",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.item.status.lastActivityTime
                                          ? _vm.formatDateTime(
                                              scope.item.status.lastActivityTime
                                            )
                                          : "--"
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(lastInvoice)",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.item.status.lastInvoiceTime
                                          ? _vm.formatDateTime(
                                              scope.item.status.lastInvoiceTime
                                            )
                                          : "--"
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(posType)",
                              fn: function(scope) {
                                return [
                                  scope.item.posType === 0
                                    ? _c("VeloceLogo")
                                    : _vm._e(),
                                  scope.item.posType === 1
                                    ? _c("MaitreDLogo")
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "cell(posVersion)",
                              fn: function(scope) {
                                return [
                                  _vm._v(" " + _vm._s(scope.item.version) + " ")
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3220176746
                        )
                      })
                    : _vm._e(),
                  !_vm.isLoading && _vm.locations.length === 0
                    ? _c("vel-no-results")
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }