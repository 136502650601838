var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      ref: "sidebarMealPlan",
      attrs: {
        "header-class": "bg-light",
        "bg-variant": "white",
        width: "600px",
        right: "",
        id: "mealPlan-sidebar",
        title: "Sidebar",
        shadow: "",
        backdrop: ""
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                { staticClass: "bg-gray-200 bg-light w-100" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: { click: hide }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-left",
                          "aria-hidden": "true"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("mealPlan.back")) + " ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        },
        {
          key: "footer",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex bg-light text-dark align-items-center px-3 py-2",
                  staticStyle: { "border-top": "2px solid #d6d6d8" }
                },
                [
                  _c(
                    "b-button",
                    { attrs: { variant: "primary" }, on: { click: hide } },
                    [_vm._v(_vm._s(_vm.$t("mealPlan.close")))]
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "b-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pending,
              expression: "pending"
            }
          ],
          staticClass: "p-4",
          attrs: { fluid: "" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-content-between"
            },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("mealPlan.list.title")))]),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.mealPlanForm-sidebar",
                      modifiers: { "mealPlanForm-sidebar": true }
                    }
                  ],
                  attrs: { variant: "primary" }
                },
                [
                  _c("b-icon", { attrs: { icon: "plus" } }),
                  _vm._v(" " + _vm._s(_vm.$t("mealPlan.add.btn")) + " ")
                ],
                1
              )
            ],
            1
          ),
          _c("meal-plan-list", {
            attrs: { "meal-plans": _vm.mealPlans },
            on: {
              onModify: function($event) {
                return _vm.openEditMealPlan($event)
              },
              onDelete: function($event) {
                return _vm.$emit("onDelete", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }