var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      attrs: {
        "header-class": "bg-light",
        "bg-variant": "white",
        width: "70%",
        right: "",
        id: "history-sidebar",
        title: "Sidebar",
        shadow: "",
        backdrop: "",
        "v-loading": _vm.loading
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                { staticClass: "bg-gray-200 bg-light w-100" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: { click: hide }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-left",
                          "aria-hidden": "true"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("jobRoles.back")) + " ")
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "bg-light",
                      staticStyle: { "margin-left": "calc(50% - 100px)" }
                    },
                    [_vm._v(_vm._s(_vm.$t("employeeCreate.history.title")))]
                  )
                ],
                1
              )
            ]
          }
        },
        {
          key: "footer",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex bg-light text-dark align-items-center px-3 py-2",
                  staticStyle: { "border-top": "2px solid #d6d6d8" }
                },
                [
                  _c(
                    "b-button",
                    { attrs: { variant: "primary" }, on: { click: hide } },
                    [_vm._v(_vm._s(_vm.$t("jobRoles.close")))]
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c("b-table", {
        ref: "table",
        staticStyle: { margin: "1.5rem 1rem" },
        attrs: {
          "thead-class": "bg-info",
          striped: "",
          small: "",
          hover: "",
          bordered: "",
          fixed: "",
          "primary-key": "id",
          items: _vm.history,
          fields: _vm.fields,
          responsive: "sm"
        },
        scopedSlots: _vm._u([
          {
            key: "cell(field)",
            fn: function(scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "employeeCreate.history.fields." +
                          _vm.camelCase(scope.item.field)
                      )
                    ) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "employeeCreate.history.actions." +
                          _vm.getModificationType(scope.item)
                      )
                    ) +
                    " "
                ),
                _c("br")
              ]
            }
          },
          {
            key: "cell(eventDate)",
            fn: function(scope) {
              return [
                _vm._v(" " + _vm._s(_vm.getDate(scope.item.eventDate)) + " "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.getTime(scope.item.eventDate)) + " "),
                _c("br")
              ]
            }
          },
          {
            key: "cell(previousValue)",
            fn: function(scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.item.previousValue === ""
                        ? "--"
                        : scope.item.previousValue
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(newValue)",
            fn: function(scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.item.newValue === "" ? "--" : scope.item.newValue
                    ) +
                    " "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }