<template>
  <div v-loading="areSalesLoading" class="daily-sales__chart">
    <h5>{{ $t('dashboard.salesCumulativeLineChart.title') }}</h5>
    <div v-if="!areSalesLoading">
      <div class="chart__toolbar" v-if="areSalesAvailable">
        <apexchart type="area" :height="height" :options="chartOptions" :series="series" v-if="areSalesAvailable" />
      </div>
      <div class="chart__empty-state" v-else>
        <slot name="empty">{{ $t('dashboard.salesCumulativeLineChart.empty') }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import {
  convertMilitaryTimeLabelToMeridiem,
  getDateFormatFromUserConfig,
  getUTCHourWithWeekDay
} from '@/helpers/date.helpers';
import { DateTime } from 'luxon';
import VueApexCharts from 'vue-apexcharts';
import formatMoney from '../../../filters/format-money';
import { oc } from 'ts-optchain';
import {mapState as mapPiniaState} from "pinia/dist/pinia";
import {useUIDateCompare} from "@/stores/ui/date-compare.module";
import {useDataSalesHourly} from "@/stores/data/sales/hourly-sales.module";
import {useDataConfig} from "@/stores/data/config.module";

export default {
  name: 'daily-sales__chart',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    locationId: {
      type: String,
      default: null
    },
    currencyId: {
      type: String,
      default: null
    },
    from: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 300
    }
  },
  computed: {
    ...mapPiniaState(useDataSalesHourly, {
      areSalesLoading: 'areSalesLoading',
      hs: 'hourlySales',
      hourlySales: 'hourlySalesGroupedByCurrencyAndLocalTime',
      previousWeekHourlySales: 'previousWeekHourlySalesGrouped',
      uniqueHours: 'uniqueHours',
      uniqueCurrencies: 'uniqueCurrencies'
    }),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useUIDateCompare, ['getDateCompareSelectorSelected']),
    annotationPos() {
      const diffInHours = Math.abs(new Date(this.from) - new Date().setHours(0)) / 36e5;

      if (!((this.hs && this.hs[0]) || []).length || diffInHours >= 24) {
        return -2;
      }
      const [lastLabel] = this.hs[0]
        .sort((a, b) => new Date(b.invoiceLocalTime).getTime() - new Date(a.invoiceLocalTime).getTime())
        .map(x => getUTCHourWithWeekDay(x.invoiceLocalTime));

      return this.uniqueHours.indexOf(lastLabel.replace(/[0-7]-/, '1-'));
    },
    areSalesAvailable() {
      return this.hs[0] && this.hs[0].length;
    },
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: [6, 3, 3, 3],
          dashArray: [0, 0, 0, 0]
        },
        labels: this.labels,
        annotations: {
          xaxis: [
            {
              x: this.labels[this.annotationPos],
              x2: this.labels[this.annotationPos + 1],
              borderColor: '#000',
              fillColor: '#FEB019',
              label: {
                text: ''
              }
            }
          ]
        },
        fill: {
          type: ['gradient', 'gradient', 'gradient', 'gradient'],
          opacity: [0.85, 0.65, 0.65, 0.65],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: [0, 0, 0, 0],
            opacityTo: [0.25, 0.25, 0.25, 0.25],
            stops: [100, 100, 100, 100]
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          title: {
            text: this.$t('dashboard.salesCumulativeLineChart.xaxis')
          },
          labels: {
            rotate: -45,
            rotateAlways: true
          },
          type: 'category',
          categories: this.labels
        },
        yaxis: {
          labels: {
            formatter: val => formatMoney(val, '')
          },
          opposite: false,
          title: {
            text: this.$t('dashboard.salesCumulativeLineChart.yaxis')
          }
        }
      };
    },
    getSelectedCurrency() {
      if (this.currencyId) {
        return this.currencyId;
      }

      if (this.uniqueCurrencies && this.uniqueCurrencies.length) {
        return this.uniqueCurrencies[0];
      }

      return null;
    },
    labels() {
      return this.extractHourfromHourWeekdayString();
    },
    currenciesOptions() {
      return this.uniqueCurrencies.map(currency => ({ label: currency, value: currency }));
    },
    getPreviousDaySales() {
      return Array.from(this.previousWeekHourlySales.values())
        .map(sale => oc(sale[this.getSelectedCurrency]).salesAmount())
        .filter(x => x !== undefined);
    },
    series() {
      let cumulativeSales = 0;
      const currentDaySales = {
        name: this.$t('dashboard.salesCumulativeLineChart.sales', {
          currency: this.getSelectedCurrency,
          period: this.$t(`graphs.series.single.current`, {
            from: DateTime.fromISO(this.from).toFormat(getDateFormatFromUserConfig())
          })
        }),
        type: 'area',
        data: this.getCurrentDaySales(0).map(v => {
          cumulativeSales += v;
          return cumulativeSales;
        })
      };

      cumulativeSales = 0;

      return [currentDaySales, this.previousDaySales(1), this.previousDaySales(2), this.previousDaySales(3)];
    },
    periods() {
      const startDate = DateTime.fromISO(this.from);

      return [
        {
          from: startDate.minus({ days: 7 }).toJSDate()
        },
        {
          from: startDate.minus({ days: 28 }).toJSDate()
        },
        {
          from: startDate.minus({ days: 364 }).toJSDate()
        }
      ];
    }
  },
  methods: {
    extractHourfromHourWeekdayString() {
      const uniqueHours = this.uniqueHours.map(hour => hour.split('-')[1]);

      return uniqueHours.map(x =>
        DateTime.now()
          .set({ hour: Number(x.substr(0, 2)), minute: 0 })
          .toFormat(this.get.enable_military_time_format.value ? 'T' : 't')
      );
    },
    previousDaySales(index = 1) {
      let cumulativeSales = 0;

      const comparedPeriod = {
        1: 'week',
        2: 'month',
        3: 'year'
      };

      return {
        name: this.$t('dashboard.salesCumulativeLineChart.previousSales', {
          currency: this.getSelectedCurrency,
          period: this.$t(`graphs.series.single.${comparedPeriod[index]}`, {
            from: DateTime.fromJSDate(this.periods[index - 1].from).toFormat(getDateFormatFromUserConfig())
          })
        }),
        type: 'area',
        data: this.getCurrentDaySales(index).map(v => {
          cumulativeSales += v;
          return cumulativeSales;
        })
      };
    },
    getCurrentDaySales(index = 0) {
      return Array.from(this.hourlySales[index].values())
        .map(sale => oc(Object.values(sale) && Object.values(sale)[0]).salesAmount())
        .filter(x => x !== undefined);
    }
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$charts-empty-text-color: $shuttle-gray;
$charts-title-text-color: $river-bed;

.daily-sales__chart {
  font-family: $base-font-family;

  .chart__empty-state {
    color: $charts-empty-text-color;
  }

  .apexcharts-xaxis-texts-g text:nth-child(2n):not(:last-child) {
    visibility: hidden;
  }

  h5 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.apexcharts-title-text {
  color: $charts-title-text-color;
  font-size: rem-calc(13);
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(medium) {
    font-size: rem-calc(15);
  }
}

.apexcharts-xaxistooltip {
  overflow: visible;
}

.apexcharts-xaxistooltip-text {
  /* stylelint-disable */
  min-width: 30px !important;
}

.apexcharts-legend-text,
.apexcharts-tooltip-text-label {
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}
</style>
