var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-input-group",
        {
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "b-input-group-text",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$refs.input.$el.focus()
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "chevron-down" } })],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("b-form-input", {
            ref: "input",
            attrs: { disabled: _vm.disabled, readonly: !_vm.filterable },
            on: { focus: _vm.inputFocusIn, input: _vm.onInput },
            model: {
              value: _vm.currentText,
              callback: function($$v) {
                _vm.currentText = $$v
              },
              expression: "currentText"
            }
          })
        ],
        1
      ),
      _vm.openList
        ? _c("VelList", {
            ref: "list",
            attrs: { "value-options": _vm.options, multiple: _vm.multiple },
            on: {
              "update:valueOptions": function($event) {
                _vm.options = $event
              },
              "update:value-options": function($event) {
                _vm.options = $event
              },
              cancel: _vm.cancel,
              ok: _vm.validate
            },
            model: {
              value: _vm.modelValue,
              callback: function($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }