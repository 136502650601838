var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "big-division-page",
      attrs: { loading: _vm.loading },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("bigDivisionInfo.title")))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "vel-card",
        [
          _c("big-division-form", {
            attrs: { bigDivision: _vm.selectSelected },
            on: { submit: _vm.handleSubmit }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }