<template>
  <portal to="app">
    <vel-modal class="group-delete__modal" dismissable @dismiss="handleCancel">
      <template v-slot:header>
        {{ $t('groups.modals.delete.title') }}
      </template>
      <template v-slot:default>
        <vel-alert
          @dismiss="clearDeleteGroupError"
          class="alert delete-group__error"
          level="error"
          v-if="deleteGroupError"
        >
          {{ $t('groups.errors.deleteGroup') }}
        </vel-alert>

        <span class="group-delete__confirmation" v-html="$t('groups.modals.delete.textHtml', { name: group.name })" />

        <span class="group-delete__information" v-html="$t('groups.modals.delete.informationHtml')" />
      </template>
      <template v-slot:footer>
        <vel-button class="group-delete__close-button" type="secondary" @click="handleCancel">
          {{ $t('defaults.actions.close') }}
        </vel-button>
        <vel-button
          class="group-delete__confirm-button"
          type="critical"
          :loading="isDeletingGroup"
          @click="handleDelete"
        >
          {{ $t('groups.modals.delete.button.text') }}
        </vel-button>
      </template>
    </vel-modal>
  </portal>
</template>

<script>
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelModal from '@/components/modal/VelModal';
import {mapState as mapPiniaState, mapActions as mapPiniaActions} from "pinia/dist/pinia";
import {useDataGroups} from "@/stores/data/groups.module";

export default {
  name: 'delete-group-modal',
  components: {
    VelButton,
    VelModal,
    VelAlert
  },
  props: {
    group: {
      type: Object,
      default: () => ({
        name: null
      })
    }
  },
  methods: {
    ...mapPiniaActions(useDataGroups, [
      'clearLatestCreatedGroup',
      'clearLatestDeletedGroup',
      'clearLatestUpdatedGroup',
      'deleteGroup',
      'clearCreateGroupError',
      'clearDeleteGroupError',
      'clearUpdateGroupError'
    ]),
    handleCancel() {
      this.$emit('dismiss');
    },
    handleDelete() {
      this.deleteGroup(this.group);
    }
  },
  watch: {
    latestDeletedGroup(deletedGroup) {
      if (deletedGroup && this.group.name === deletedGroup.name) {
        this.$emit('dismiss');
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataGroups, ['deleteGroupError', 'latestDeletedGroup', 'isDeletingGroup'])
  },
  created() {
    this.clearLatestCreatedGroup();
    this.clearLatestDeletedGroup();
    this.clearLatestUpdatedGroup();
    this.clearCreateGroupError();
    this.clearDeleteGroupError();
    this.clearUpdateGroupError();
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.delete-group__error {
  margin-bottom: rem-calc(16);
}

.group-delete__confirmation {
  display: block;
  margin-bottom: 16px;
}
</style>
