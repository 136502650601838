<template>
  <section class="private-page" :class="classes">
    <main-content>
      <new-sidebar ref="sidebar" />
      <template v-slot:top>
        <topbar ref="topbar" :account="account" :handle-toggle="toggleSidebar" />
      </template>
      <router-view :key="$route.fullPath" />
    </main-content>
    <resize-observer @notify="handleResize" />
    <vel-feedback></vel-feedback>
    <vel-announce></vel-announce>
    <vel-modal v-if="showConfig" dismissable @dismiss="showConfig = false">
      <template v-slot:header>
        {{ $t('editors.customize_link') }}
      </template>
      <settings-page></settings-page>
    </vel-modal>
  </section>
</template>

<script>
import MainContent from '@/private/components/MainContent.vue';
import NewSidebar from '@/private/components/NewSidebar';
import SettingsPage from '@/private/settings/SettingsPage';
import Topbar from '@/private/components/Topbar.vue';
import VelAnnounce from '@/components/announce/VelAnnounce';
import VelFeedback from '@/components/feedback/VelFeedback';
import VelModal from '@/components/modal/VelModal';
import { useUIAppStore } from '@/stores/ui/app.module';
import { mapStores } from 'pinia';
import { useDataAccount } from '@/stores/data/account.module';
import {mapActions as mapPiniaActions, storeToRefs} from 'pinia/dist/pinia';
import {useDataConfig} from "@/stores/data/config.module";

export default {
  name: 'private-page',
  components: {
    SettingsPage,
    MainContent,
    NewSidebar,
    Topbar,
    VelAnnounce,
    VelFeedback,
    VelModal
  },
  resize: {
    timeout: 300
  },
  watch: {
    $mq() {
      this.collapseSidebarOnMedium();
    }
  },
  setup() {
    const accountStore = useDataAccount();
    const configStore = useDataConfig();

    const { account } = storeToRefs(accountStore);

    return {
      account,
      resizeTimeout: null,
      isResizing: false,
      showConfig: false,
      getConfig: configStore.getConfig
    };
  },
  computed: {
    ...mapStores(useUIAppStore),
    classes() {
      const { name } = this.$options;
      return {
        [`${name}_resizing`]: this.isResizing
      };
    }
  },
  methods: {
    toggleSidebar() {
      this.appStore.setExpanded(!this.appStore.isExpanded);
    },
    itemClick(itemName) {
      if (itemName === 'globalSettings') {
        this.showConfig = false;
      }
      this.collapseSidebarOnMedium();
    },
    collapseSidebarOnMedium() {
      return this.appStore.setExpanded(this.$mq === 'xxlarge');
    },
    handleResize() {
      clearTimeout(this.resizeTimeout);
      this.isResizing = true;
      this.resizeTimeout = setTimeout(() => {
        this.isResizing = false;
      }, this.$options.resize.timeout);
    }
  },
  created() {
    this.collapseSidebarOnMedium();
  },
  mounted() {
    this.getConfig();
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$private-page-background-color: $wild-sand;
$private-page-height: 100%;

.private-page {
  background-color: $private-page-background-color;
  display: flex;
  flex-grow: 1;
  height: $private-page-height;

  &_resizing * {
    transition: none;
  }

  .vel-modal {
    background-color: $wild-sand;
    max-height: 85vh;
    max-width: 800px;
    width: calc(100vw - 30px);
  }
}
</style>
