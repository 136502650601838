var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "permission-delete-modal" },
    [
      _c("vel-modal", {
        attrs: { dismissable: "" },
        on: { dismiss: _vm.handleDismiss },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("user.permissions.modals.delete.title")) +
                    " "
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", { staticClass: "modal__confirmation-text" }, [
                  _vm.permission.location
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "user.permissions.modals.delete.locationMessage",
                              {
                                permission: _vm.permission.permission,
                                locationName: _vm.permission.location.name
                              }
                            )
                          )
                        }
                      })
                    : _vm.permission.group
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "user.permissions.modals.delete.groupMessage",
                              {
                                permission: _vm.permission.permission,
                                groupName: _vm.permission.group.name
                              }
                            )
                          )
                        }
                      })
                    : _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "user.permissions.modals.delete.globalMessage",
                              { permission: _vm.permission.permission }
                            )
                          )
                        }
                      })
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "vel-button",
                  {
                    staticClass: "actions__cancel",
                    attrs: { type: "secondary" },
                    on: { click: _vm.handleDismiss }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("defaults.confirmations.no")) + " "
                    )
                  ]
                ),
                _c(
                  "vel-button",
                  {
                    staticClass: "actions__confirm",
                    attrs: { type: "critical" },
                    on: { click: _vm.handleSuccess }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("defaults.confirmations.yes")) + " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }