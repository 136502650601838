<template>
  <div>
    <b-alert :show="error" variant="danger" class="mb-4">
      {{ $t('employeeCreate.updateError') }}
    </b-alert>
    <div class="px-4 pt-4" style="background-color: #f5f5f5;">
      <b-button-group class="text-right mode">
        <b-button @click="setActive(false)" :variant="!showActive ? 'primary' : 'light'" :disabled="pending">
          {{ $t('employeeCreate.availability.buttonGroup.availability') }}
        </b-button>
        <b-button @click="setActive(true)" :variant="showActive ? 'primary' : 'light'" :disabled="pending">
          {{ $t('employeeCreate.availability.buttonGroup.timeOff') }}
        </b-button>
      </b-button-group>
    </div>
    <h5 class="pt-4 px-4 pb-2">
      {{
        showActive ? $t('employeeCreate.availability.timeOff.form.title') : $t('employeeCreate.availability.form.title')
      }}
    </h5>

    <b-container fluid class="content p-4">
      <div class="mb-md-4" v-loading="pending">
        <b-form-checkbox
          class="ml-0 mb-4"
          v-if="!showActive"
          v-model="employee.availableAllTime"
          @change="onAvailableAllTime"
        >
          {{ $t('employeeCreate.availability.form.selectAllTime') }}
        </b-form-checkbox>
        <template v-if="!showActive && shifts.length">
          <div class="availability-grid" style="overflow-x: auto;">
            <b-row class="mb-4" :style="'width:' + (180 * shifts.length + 120) + 'px'">
              <b-col style="width: 120px;" class="p0"></b-col>
              <b-col class="p-0" v-for="shift of shifts" :key="shift.id" style="width: 180px;">
                <b-button class="w-100 font-weight-normal" :variant="'light'">
                  {{ shift.name }}
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-for="dayNumber in [0, 1, 2, 3, 4, 5, 6]"
              :key="dayNumber"
              class="mb-4"
              :style="'width:' + (180 * shifts.length + 120) + 'px'"
            >
              <b-col style="width: 120px;">
                {{ $t(`employeeCreate.availability.dayOfWeek.${dayNumber}`) }}
              </b-col>
              <b-col class="p-0 day" v-for="shift of shifts" :key="shift.id" style="width: 180px;">
                <b-button
                  class="w-100 font-weight-normal"
                  @click="toggle(shift.id, dayNumber)"
                  :variant="availabilityForm[shift.id][dayNumber] ? 'success' : 'light'"
                >
                  {{
                    availabilityForm[shift.id][dayNumber]
                      ? $t('employeeCreate.availability.form.available')
                      : $t('employeeCreate.availability.form.notAvailable')
                  }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="showActive">
          <b-button variant="outline-primary" v-b-toggle.timeOffForm-sidebar>
            <b-icon icon="plus" aria-hidden="true"></b-icon>
            {{ $t('employeeCreate.availability.timeOff.form.submit') }}
          </b-button>
          <div class="mt-4" style="max-width: 100%; overflow-x: auto;">
            <h6 class="mt-4 mt-md-0 mb-3">
              {{ $t('employeeCreate.availability.timeOff.list.upcomingTitle') }}
            </h6>
            <p class="mt-4 mt-md-0" v-if="!upcomingLeaves.length">
              {{ $t('employeeCreate.availability.timeOff.list.empty') }}
            </p>
            <b-table
              v-else
              thead-class="bg-info"
              striped
              small
              hover
              bordered
              show-empty
              fixed
              primary-key="id"
              :items="upcomingLeaves"
              :fields="fields"
              responsive="sm"
              ref="table"
              class="mt-4"
              style="max-width: 1200px; min-width: 1000px;"
            >
              <template #empty="scope">
                <div class="p-2">{{ scope.emptyText }}</div>
              </template>
              <template #emptyfiltered="scope">
                <div class="p-2">{{ scope.emptyFilteredText }}</div>
              </template>
              <template #cell(edit)="scope">
                <b-button variant="link" @click="$emit('onModify', scope)" v-b-toggle.timeOffForm-sidebar>
                  <b-icon icon="pencil-fill" aria-label="Edit" class="row-action text-primary"></b-icon>
                  {{ $t('timeAttendances.edit') }}
                </b-button>
              </template>
              <template #cell(delete)="scope">
                <b-button variant="link text-danger" @click="showDelete = scope.item">
                  <b-icon icon="trash-fill" aria-label="Delete" class="row-action text-danger"></b-icon>
                  {{ $t('mealPlan.delete') }}
                </b-button>
              </template>
            </b-table>
            <h6 class="mt-4 mb-3">
              {{ $t('employeeCreate.availability.timeOff.list.previousTitle') }}
            </h6>
            <p class="mt-4 mt-md-0" v-if="!previousLeaves.length">
              {{ $t('employeeCreate.availability.timeOff.list.empty') }}
            </p>
            <b-table
              v-else
              thead-class="bg-info"
              striped
              small
              hover
              bordered
              show-empty
              fixed
              primary-key="id"
              :items="previousLeaves"
              :fields="fields"
              responsive="sm"
              ref="table"
              class="mt-4"
              style="max-width: 1200px; min-width: 1000px;"
            >
              <template #empty="scope">
                <div class="p-2">{{ scope.emptyText }}</div>
              </template>
              <template #emptyfiltered="scope">
                <div class="p-2">{{ scope.emptyFilteredText }}</div>
              </template>
              <template #cell(edit)="scope">
                <b-button variant="link" @click="$emit('onModify', scope)" v-b-toggle.timeOffForm-sidebar>
                  <b-icon icon="pencil-fill" aria-label="Edit" class="row-action text-primary"></b-icon>
                  {{ $t('timeAttendances.edit') }}
                </b-button>
              </template>
              <template #cell(delete)="scope">
                <b-button variant="link text-danger" @click="showDelete = scope.item">
                  <b-icon icon="trash-fill" aria-label="Delete" class="row-action text-danger"></b-icon>
                  {{ $t('mealPlan.delete') }}
                </b-button>
              </template>
            </b-table>
          </div>
        </template>
        <employee-delete-modal
          :title="$t('employeeCreate.timeOff.deleteModal.title')"
          :body="$t('employeeCreate.timeOff.deleteModal.body')"
          :show="showDelete !== null"
          @onDelete="
            $emit('onDelete', showDelete);
            showDelete = null;
          "
          @onCancel="showDelete = null"
        ></employee-delete-modal>
      </div>
    </b-container>
  </div>
</template>
<script>
import {getDateFormatFromUserConfig, hasWeekdayEnabled, toggleWeekDayFromSchedule} from '@/helpers/date.helpers';
import { DateTime } from 'luxon';
import EmployeeDeleteModal from '@/private/components/employees/components/EmployeeDeleteModal';
import { getTimeAttendanceShifts } from '@/services/timeAttendances';
import { getToken } from '@/helpers/token.helper';
import locationService from '@/services/locations.service';

export default {
  name: 'employee-availability',
  components: {
    EmployeeDeleteModal
  },
  props: {
    employee: {
      type: Object,
      default: () => ({ id: null })
    },
    timeOffs: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    },
    submited: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startDate: '',
      showDelete: null,
      endDate: '',
      timeOff: { description: null, startDate: null, endDate: null },
      showActive: false,
      shifts: [],
      availabilityForm: {}
    };
  },
  computed: {
    fields() {
      return [
        {
          key: 'description',
          label: this.$t('employeeCreate.availability.timeOff.form.description'),
          class: 'name vertical-align-center'
        },
        {
          key: 'startDate',
          label: this.$t('employeeCreate.availability.timeOff.form.startDate'),
          class: 'startDate vertical-align-center'
        },
        {
          key: 'endDate',
          label: this.$t('employeeCreate.availability.timeOff.form.endDate'),
          class: 'endDate vertical-align-center'
        },
        {
          key: 'edit',
          label: '',
          class: 'edit'
        },
        {
          key: 'delete',
          label: '',
          class: 'edit'
        }
      ];
    },
    upcomingLeaves() {
      return (this.timeOffs || [])
        .filter(t => t.deleted !== true)
        .filter(t => new Date(t.startDate).getTime() > new Date().getTime())
        .map(t => ({
          ...t,
          startDate: DateTime.fromISO(t.startDate).toFormat(getDateFormatFromUserConfig()),
          endDate: DateTime.fromISO(t.endDate).toFormat(getDateFormatFromUserConfig())
        }));
    },
    previousLeaves() {
      return (this.timeOffs || [])
        .filter(t => t.deleted !== true)
        .filter(t => new Date(t.startDate).getTime() <= new Date().getTime())
        .map(t => ({
          ...t,
          startDate: DateTime.fromISO(t.startDate).toFormat(getDateFormatFromUserConfig()),
          endDate: DateTime.fromISO(t.endDate).toFormat(getDateFormatFromUserConfig())
        }));
    },
    isAvailableAllTime() {
      let isAvailableAllTime = true;
      Object.keys(this.availabilityForm).forEach(a => {
        Object.keys(this.availabilityForm[a]).forEach(c => {
          if (!this.availabilityForm[a][c]) {
            isAvailableAllTime = false;
          }
        });
      });
      return isAvailableAllTime;
    }
  },
  methods: {
    setStartDate(val) {
      if (val.length === 10) {
        this.timeOff.startDate = DateTime.fromFormat(val, 'D').toISO();
        if (!this.timeOff.endDate) {
          this.timeOff.endDate = DateTime.fromFormat(val, 'D').toISO();
        }
      } else if (val.length === 0) {
        this.timeOff.startDate = null;
      }
    },
    onSelectStartDate(ctx) {
      this.startDate = ctx.selectedFormatted;
    },
    setEndDate(val) {
      if (val.length === 10) {
        this.timeOff.endDate = DateTime.fromFormat(val, 'D').toISO();
      } else if (val.length === 0) {
        this.timeOff.endDate = null;
      }
    },
    onSelectEndDate(ctx) {
      this.endDate = ctx.selectedFormatted;
    },
    onAvailableAllTime(value) {
      this.selectAll(value);
    },
    formatter(value) {
      return value.replace(/[^A-Z\- ]/gi, '');
    },
    clear() {
      this.timeOff = { description: null, startDate: null, endDate: null };
    },
    setActive(active) {
      this.showActive = active;
    },
    getAvailabilityIdByShiftIf(shiftId) {
      const results = (this.employee.availability || []).filter(x => x.timeAttendanceShiftId === shiftId);
      if (results.length) {
        return results[0].id;
      }
      return null;
    },
    toggle(shiftId, dayNumber) {
      this.availabilityForm = {
        ...this.availabilityForm,
        [shiftId]: {
          ...this.availabilityForm[shiftId],
          [dayNumber]: !this.availabilityForm[shiftId][dayNumber]
        }
      };

      this.employee.availability = Object.keys(this.availabilityForm).map(key => ({
        timeAttendanceShiftId: key,
        id: this.getAvailabilityIdByShiftIf(key),
        weeklySchedule: Object.keys(this.availabilityForm[key]).reduce((a, b) => {
          let c = a;
          if (this.availabilityForm[key][b]) {
            c = toggleWeekDayFromSchedule(a, Number(b) + 1);
          }

          return c;
        }, 0)
      }));

      this.employee.availableAllTime = this.isAvailableAllTime;
    },
    selectAll(x) {
      this.availabilityForm = Object.keys(this.availabilityForm).reduce((a, b) => {
        a[b] = Object.keys(this.availabilityForm[b]).reduce((c, d) => {
          c[d] = x;
          return c;
        }, {});
        return a;
      }, {});
      this.employee.availability = Object.keys(this.availabilityForm).map(key => ({
        timeAttendanceShiftId: key,
        id: this.getAvailabilityIdByShiftIf(key),
        weeklySchedule: Object.keys(this.availabilityForm[key]).reduce((a, b) => {
          let c = a;
          if (this.availabilityForm[key][b]) {
            c = toggleWeekDayFromSchedule(a, Number(b) + 1);
          }

          return c;
        }, 0)
      }));
    }
  },
  async mounted() {
    const location = await locationService.getLocations(getToken(), {
      licenceNumber: this.employee.licenceNumber
    });

    this.shifts = await getTimeAttendanceShifts({ locationId: location[0].id }).then(r => r.content);

    for (const s of this.shifts) {
      if (!this.availabilityForm[s.id]) {
        this.availabilityForm[s.id] = { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false };
      }
    }
    for (const a of (this.employee && this.employee.availability) || []) {
      for (let i = 1; i < 8; i++) {
        if (hasWeekdayEnabled(a.weeklySchedule, i) || this.employee.availableAllTime) {
          this.availabilityForm[a.timeAttendanceShiftId][i - 1] = true;
        }
      }
    }

    this.employee.availableAllTime = this.isAvailableAllTime;
    this.$forceUpdate();
  },
  watch: {
    employee: {
      handler() {
        for (const a of (this.employee && this.employee.availability) || []) {
          for (let i = 1; i < 8; i++) {
            if (hasWeekdayEnabled(a.weeklySchedule, i)) {
              this.availabilityForm[a.timeAttendanceShiftId][i - 1] = true;
            }
          }
        }
        this.$forceUpdate();
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
/deep/ {
  .btn-group.mode {
    button {
      text-transform: uppercase;
      width: 140px;
    }
  }

  .edit {
    width: 115px;
  }

  .custom-control-label {
    line-height: 1.5rem;
  }

  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }

  .form-text {
    min-height: 18px;
  }
}

.availability-grid .btn-light {
  background-color: white;
  border: 1px solid #ccc;
}

.availability-grid .day .btn-light {
  color: #c0c0c0;
}

.datepicker /deep/ button {
  background-color: white;
  border-bottom: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
}

h5 {
  background-color: #f5f5f5;
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.datepicker .input-group-append {
  margin-left: -35px;
}

.btn-light {
  background-color: #fff;
}

.custom-control {
  margin-bottom: 8px;
}
</style>
