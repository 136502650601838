import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import locationsService from '@/services/locations.service';
import { oc } from 'ts-optchain';
import router from '@/router';
import { useDataLocationsStore } from '@/stores/data/locations.module';

const compareLocationLabel = (l1, l2) => oc(l1).label('').localeCompare(oc(l2).label(''));
const toLabelValue = location => ({ label: location.name, value: location.id });

export const useUILocationSelector = defineStore('locationSelector', {
  state: () => ({
    locations: [],
    status: { isLoading: null },
    error: null,
    selected: '',
    selectedMultiple: [],
    isGenerated: false
  }),
  getters: {
    isLoading: state => oc(state).status.isLoading(false),
    hasError: state => state.error !== null,
    hasSingleLocation: state => {
      if (state.selectedMultiple.length === 1) {
        return true;
      }
      return state.selected !== '';
    },
    getLocationById: state => locationId =>
      oc((state.locations || []).filter(l => l.value === locationId)[0]).label(null),
    getLocationSelectorSelected: state =>
      state.selectedMultiple.length === 1 ? state.selectedMultiple[0].value : state.selected,
    getLocationSelectorSelectedMultiple: state => state.selectedMultiple,
    getLocationSelectorSelectedMultipleEntities: state => {
      const dataLocationsStore = useDataLocationsStore();
      return (state.selectedMultiple || []).map(x => dataLocationsStore.data.entities[x.value]);
    },
    getLocationSelectorSelectedMultipleLength: state => state.selectedMultiple.length,
    getLocationSelectorSelectedMultipleIds: state => (state.selectedMultiple || []).map(x => x.value),
    getSelectedLocation: state => {
      const dataLocationsStore = useDataLocationsStore();
      if (state.selected === '' && state.selectedMultiple && state.selectedMultiple.length === 1) {
        return dataLocationsStore.data.entities[state.selectedMultiple[0].value];
      }
      return dataLocationsStore.data.entities[state.selected];
    }
  },
  actions: {
    async onChange(query: any = {}) {
      const dataLocationsStore = useDataLocationsStore();
      let selectedLocationCurrency = null;

      if (query.locationId && query.locationId !== '') {
        selectedLocationCurrency = dataLocationsStore.data.entities[query.locationId].detailedCurrency && dataLocationsStore.data.entities[query.locationId].detailedCurrency.currencyCode;
      }

      this.updateSelectedLocation(query);

      if (!(query && query.disableRouteChange)) {
        try {
          await router.replace({
            name: router.currentRoute.name,
            query: {
              ...router.currentRoute.query,
              locationId: query.locationId,
              locationIds: query.locationId,
              currencyId: selectedLocationCurrency,
              groupId: query.disableRouteChange ? null : query.groupId
            }
          });
          this.updateSelectedLocationSuccess();
        } catch (error) {
          this.updateSelectedLocationFailure(error);
        }
      }
    },
    async onChangeMultiple(query: any = {}) {
      console.log('!!', query);
      const dataLocationsStore = useDataLocationsStore();
      let selectedLocationCurrency = null;

      if (query.locationIds && query.locationIds.length) {
        selectedLocationCurrency = dataLocationsStore.data.entities[query.value[0].value].detailedCurrency && dataLocationsStore.data.entities[query.value[0].value].detailedCurrency.currencyCode;
      }

      this.updateSelectedMultipleLocation(query);

      if (!(query && query.disableRouteChange)) {
        try {
          await router.replace({
            name: router.currentRoute.name,
            query: {
              ...router.currentRoute.query,
              locationIds: query.value.map(x => x.value),
              currencyId: selectedLocationCurrency,
              groupId: query.disableRouteChange ? null : query.groupId
            }
          });
          this.updateSelectedMultipleLocationSuccess();
        } catch (error) {
          this.updateSelectedMultipleLocationFailure(error);
        }
      }
    },
    routeChanged(query) {
      if (this.selected !== query.to.query.locationId) {
        this.updateSelectedLocation(query.to.query);
      }
      if (
        JSON.stringify(this.selectedMultiple) !== JSON.stringify(query.to.query.locationIds) &&
        query.to.query.locationIds
      ) {
        this.updateSelectedMultipleLocation({
          value:
            typeof query.to.query.locationIds === 'string'
              ? [{ value: query.to.query.locationIds, label: '' }]
              : query.to.query.locationIds.map(x => ({ value: x, label: '' }))
        });
      }
    },
    ouGroupSelectorChange() {
      this.updateSelectedLocation({});
      this.updateSelectedMultipleLocation({ value: [] });
    },
    async loadLocations(query = {}) {
      try {
        this.loadLocationsRequest();
        const locations = await locationsService.getLocations(getToken(), query);
        this.loadLocationsSuccess(locations.map(toLabelValue).sort(compareLocationLabel));
      } catch (error) {
        this.loadLocationsFailure(error);
      }
    },
    gen(isGenerated) {
      this.isGenerated = isGenerated;
    },
    updateSelectedLocation(payload) {
      this.selected = payload.locationId || '';
      this.selectedMultiple = [];
    },
    updateSelectedLocationSuccess() {
      this.error = null;
    },
    updateSelectedLocationFailure(error) {
      this.error = error;
    },
    updateSelectedMultipleLocation(payload) {
      this.selectedMultiple = payload.value || [];
    },
    updateSelectedMultipleLocationSuccess() {
      this.error = null;
    },
    uupdateSelectedMultipleLocationFailure(error) {
      this.error = error;
    },
    loadLocationsRequest() {
      this.status = { isLoading: true };
      this.locations = this.locations || [];
      this.error = null;
    },
    loadLocationsSuccess(locations) {
      this.status = { isLoading: false };
      this.locations = locations;
      this.error = null;
    },
    loadLocationsFailure(error) {
      this.status = { isLoading: false };
      this.locations = [];
      this.error = error;
    }
  }
});
