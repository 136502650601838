var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      ref: "sidebarTimeOffForm",
      attrs: {
        "header-class": "bg-light",
        "bg-variant": "white",
        width: "660px",
        right: "",
        id: "timeOffForm-sidebar",
        title: "Sidebar",
        shadow: "",
        backdrop: ""
      },
      on: {
        hidden: function($event) {
          return _vm.clear()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function(ref) {
            return [
              _c(
                "h6",
                {
                  staticClass:
                    "bg-gray-200 bg-light w-100 mb-0 text-center py-1"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          _vm.timeOff &&
                            (_vm.timeOff.id || _vm.timeOff.id === null)
                            ? "employeeCreate.timeOffEdit.title"
                            : "employeeCreate.timeOff.title"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ]
          }
        },
        {
          key: "footer",
          fn: function(ref) {
            var hide = ref.hide
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2",
                  staticStyle: { "border-top": "2px solid #d6d6d8" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !(
                          _vm.$refs.form && _vm.$refs.form.enableSaveChanges
                        ),
                        variant: "primary",
                        f: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateTimeOff()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.timeOff && _vm.timeOff.id
                                ? "employeeCreate.btns.saveChanges"
                                : "employeeCreate.btns.save"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function($event) {
                          return _vm.clear(hide)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("employeeCreate.btns.cancel")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "b-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pending,
              expression: "pending"
            }
          ],
          staticClass: "p-4",
          attrs: { fluid: "" }
        },
        [
          _c("time-off-form", {
            ref: "form",
            attrs: {
              licenceNumber: _vm.employee.licenceNumber,
              "time-off": _vm.timeOff
            },
            on: {
              "update:timeOff": function($event) {
                _vm.timeOff = $event
              },
              "update:time-off": function($event) {
                _vm.timeOff = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }