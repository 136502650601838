var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "underMaintenance" },
    [
      _c("logo", { staticClass: "underMaintenance__logo" }),
      _c("h1", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "maintenance.title",
            expression: "'maintenance.title'"
          }
        ],
        staticClass: "underMaintenance__title"
      }),
      _c("p", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "maintenance.text",
            expression: "'maintenance.text'"
          }
        ],
        staticClass: "underMaintenance__text"
      }),
      _c("vel-button", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "maintenance.return",
            expression: "'maintenance.return'"
          }
        ],
        staticClass: "underMaintenance__back-button",
        on: { click: _vm.reload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }