<template>
  <pie-line-chart
    :pending="isPending"
    :options="chartOptions"
    :series="getSeries"
    :labels="getLabels"
    :currency="currencyId"
    :title="$t('sales.zoneChart.title', { limit })"
    :type="get.default_chart_sales_per_order_mode.value"
    class="hourly-sales__chart"
  />
</template>

<script>
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import {useDataSalesZones} from "@/stores/data/sales/zones.module";
import {storeToRefs} from "pinia";
import {mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataConfig} from "@/stores/data/config.module";

export default {
  name: 'zone-sales__chart',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  setup() {
    const salesZonesStore = useDataSalesZones();
    const { isPending } = storeToRefs(salesZonesStore)
    return {
      isPending,
      salesZonesStore
    };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    getSeries() {
      return [
        {
          name: this.$t('sales.zoneChart.series.title', { currency: this.currencyId }),
          data: this.salesZonesStore.getSeries(this.currencyId).slice(0, this.limit)
        }
      ];
    },
    getLabels() {
      return this.salesZonesStore.getLabels(this.currencyId)
        .map(l => this.$t(`sales.zoneChart.zones.${l}`))
        .slice(0, this.limit);
    },
    getTotal() {
      return this.salesZonesStore.getTotal(this.currencyId);
    },
    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('sales.modeSalesPerCustomersChart.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('sales.modeSalesPerCustomersChart.yaxis')
          }
        }
      };
    }
  },
  mounted() {
    this.salesZonesStore.getSalesByZone();
  }
};
</script>
