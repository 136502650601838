var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "users",
      attrs: { loading: _vm.status.isLoading, "enable-toolbar": true },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("pages.users")) + " ")]
          },
          proxy: true
        },
        {
          key: "toolbar",
          fn: function() {
            return [
              _c(
                "vel-input-group",
                [
                  _c(
                    "vel-link",
                    {
                      staticStyle: { "margin-left": "auto" },
                      attrs: { to: { name: "createUser" } }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("users.actions.create")) + " ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      [
        !_vm.isMediumUp
          ? _c(
              "div",
              { staticClass: "users__filter" },
              [
                _c("vel-table-filter", {
                  model: {
                    value: _vm.inputFilter,
                    callback: function($$v) {
                      _vm.inputFilter = $$v
                    },
                    expression: "inputFilter"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      _vm.isMediumUp
        ? [
            _c(
              "vel-card",
              { staticClass: "users__table" },
              [
                _c("vel-table", {
                  attrs: {
                    rows: _vm.rows,
                    columns: _vm.columns,
                    defaultSort: _vm.defaultSort,
                    filterValue: _vm.filter,
                    filterable: true,
                    filterPlaceholder: _vm.$t("defaults.placeholder.filter"),
                    showRowsLength: true,
                    initialLength: _vm.getUsersAmount
                  },
                  on: { "filter-change": _vm.setFilter },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "name-column",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "users__name" },
                              [
                                _c("vel-avatar", {
                                  staticClass: "users__name-avatar",
                                  attrs: {
                                    fullname:
                                      scope.row.firstName +
                                      " " +
                                      scope.row.lastName
                                  }
                                }),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "users__name-text",
                                    attrs: {
                                      to: {
                                        name: "user",
                                        params: { userId: scope.row.id }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.firstName) +
                                        " " +
                                        _vm._s(scope.row.lastName) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "passwordReset-column",
                        fn: function(scope) {
                          return [
                            _c("UserReset", { attrs: { user: scope.row } })
                          ]
                        }
                      },
                      {
                        key: "enabled-column",
                        fn: function(scope) {
                          return [
                            scope.row.enabled
                              ? _c("vel-icon", {
                                  attrs: { name: "checkmark-circle" }
                                })
                              : _c("vel-icon", {
                                  staticClass: "disabled",
                                  attrs: { name: "cancel" }
                                })
                          ]
                        }
                      },
                      {
                        key: "empty",
                        fn: function() {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.$t("users.errors.noData")) + " "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1706314211
                  )
                })
              ],
              1
            )
          ]
        : _vm._l(_vm.rows, function(user) {
            return _c(
              "vel-card",
              {
                key: user.email,
                staticClass: "users__list",
                attrs: { "title-tag": "header" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("vel-avatar", {
                            staticClass: "user__avatar",
                            attrs: { fullname: user.name }
                          }),
                          _c(
                            "router-link",
                            {
                              staticClass: "users__name-link",
                              attrs: {
                                to: {
                                  name: "user",
                                  params: { userId: user.id }
                                }
                              }
                            },
                            [
                              _c("h3", { staticClass: "user__name" }, [
                                _vm._v(
                                  _vm._s(user.firstName) +
                                    " " +
                                    _vm._s(user.lastName)
                                )
                              ])
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _c("p", { staticClass: "user__email" }, [
                  _vm._v(_vm._s(user.email))
                ]),
                _c("UserReset", { attrs: { user: user } })
              ],
              1
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }