<template>
  <portal to="app">
    <div class="coupon-edit-modal">
      <vel-modal dismissable @dismiss="handleCancel">
        <template v-slot:header>
          {{ $t('coupons.modals.edit.title') }}
        </template>

        <template v-slot:default>
          <vel-alert @dismiss="clearUpdateCouponError" class="alert" level="error" v-if="hasUpdateCouponError">
            {{ $t('coupons.errors.updateCoupon') }}
          </vel-alert>

          <coupon-form
            class="coupon-edit-modal__form"
            :currentGroupId="currentGroupId"
            :coupon="coupon"
            @submit="handleSubmit"
            @cancel="handleCancel"
          ></coupon-form>
        </template>
      </vel-modal>
    </div>
  </portal>
</template>

<script>
import CouponForm from '../coupon-form/CouponForm.vue';
import VelAlert from '@/components/alert/VelAlert';
import VelModal from '@/components/modal/VelModal';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia";
import {useDataCoupons} from "@/stores/data/coupons.module";

export default {
  name: 'edit-coupon-modal',
  components: {
    CouponForm,
    VelModal,
    VelAlert
  },
  props: {
    coupon: {
      type: Object,
      default: () => ({
        code: null,
        name: null,
        isActive: false,
        expires: null,
        plu1: null,
        plu2: null,
        plu1Amount: null,
        plu2Amount: null,
        groupId: null,
        couponType: null,
        redeemed: null
      })
    },
    currentGroupId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      currentCoupon: { ...this.coupon }
    };
  },
  methods: {
    ...mapPiniaActions(useDataCoupons, [
      'clearLatestCreatedCoupon',
      'clearLatestUpdatedCoupon',
      'updateCoupon',
      'clearUpdateCouponError',
      'clearCreateCouponError',
      'clearUpdateCouponError'
    ]),
    handleCancel() {
      this.$emit('dismiss');
    },
    handleSubmit(coupon) {
      this.currentCoupon = { ...coupon };
      this.updateCoupon(this.currentCoupon);
    }
  },
  watch: {
    latestUpdatedCoupon(updatedCoupon) {
      if (updatedCoupon) {
        this.$emit('dismiss');
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataCoupons, ['hasUpdateCouponError', 'latestUpdatedCoupon'])
  },
  created() {
    this.clearLatestCreatedCoupon();
    this.clearLatestUpdatedCoupon();
    this.clearCreateCouponError();
    this.clearUpdateCouponError();
  }
};
</script>
<style lang="scss" scoped>
/deep/ .coupon-form__location .el-select {
  width: 100%;
}
</style>
