var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page bootstrap",
      attrs: {
        enableToolbar: false,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        scroll: true
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  attrs: { disabled: !_vm.canSave, variant: "primary" },
                  on: { click: _vm.handleCreateMixMatch }
                },
                [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.handleShowCancelModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "h4",
        {
          staticClass: "p-3 bg-white w-100",
          staticStyle: {
            "border-bottom": "1px solid rgba(76, 86, 103, 0.2)",
            "border-top": "1px solid rgba(76, 86, 103, 0.2)"
          },
          attrs: { "data-v-1bae3c7b": "" }
        },
        [_vm._v(" " + _vm._s(_vm.formData.name || "--") + " ")]
      ),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("createMixMatch.generalConfiguration.title")))
      ]),
      _c(
        "b-container",
        { staticClass: "content px-4 pt-4 pb-0", attrs: { fluid: "" } },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column flex-md-row mb-md-4 justify-content-between"
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    description: _vm.$t(
                      "createMixMatch.generalConfiguration.input.required"
                    ),
                    label: _vm.$t(
                      "createMixMatch.generalConfiguration.input.label"
                    ),
                    "label-for": "name"
                  }
                },
                [
                  _c("b-form-input", {
                    class: {
                      required: !_vm.formData.name || _vm.formData.name === ""
                    },
                    attrs: { id: "name", maxlength: "50", trim: "" },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("createMixMatch.activation.title")))
      ]),
      _c(
        "b-container",
        { staticClass: "content p-4", attrs: { fluid: "" } },
        [
          _c(
            "b-form-checkbox",
            {
              class: { "mb-4": !_vm.formData.alwaysActive },
              attrs: { id: "alwaysActive", name: "alwaysActive" },
              on: { change: _vm.handleIsAlwaysActive },
              model: {
                value: _vm.formData.alwaysActive,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "alwaysActive", $$v)
                },
                expression: "formData.alwaysActive"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("createMixMatch.activation.checkboxLabel")) +
                  " "
              )
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                background: "#fff",
                "-ms-overflow-style": "none",
                "scrollbar-width": "none"
              }
            },
            _vm._l(_vm.formData.activations, function(activation, index) {
              return _c("mix-match-activation-form", {
                key: "activations-" + index,
                attrs: {
                  activation: activation,
                  activations: _vm.formData.activations,
                  "always-active": _vm.formData.alwaysActive,
                  "available-hours": _vm.availableHours,
                  "available-week-days": _vm.availableWeekDays,
                  index: index,
                  "show-delete-button": _vm.canShowDeleteButton(
                    _vm.formData.activations
                  )
                },
                on: {
                  addActivation: _vm.addActivation,
                  removeActivation: function($event) {
                    return _vm.handleRemoveActivation(index)
                  }
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("createMixMatch.priceConfiguration.title")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c("div", [
          _c(
            "div",
            {
              staticStyle: {
                background: "#fff",
                "-ms-overflow-style": "none",
                "scrollbar-width": "none"
              }
            },
            _vm._l(_vm.formData.priceConfigurations, function(
              priceConfig,
              index
            ) {
              return _c(
                "div",
                {
                  key: "priceConfig-" + index,
                  staticClass: "w-100 d-flex",
                  staticStyle: { padding: "24px 0 0 0" }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mx-0 price-configuration" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  index === 0 &&
                                  _vm.$t(
                                    "createMixMatch.priceConfiguration.qtyLabel"
                                  )
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  formatter: _vm.formatQty,
                                  placeholder: _vm.$t(
                                    "createMixMatch.priceConfiguration.quantity"
                                  ),
                                  maxlength: "10",
                                  type: "number",
                                  number: "",
                                  min: "0"
                                },
                                model: {
                                  value:
                                    _vm.formData.priceConfigurations[index]
                                      .quantity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.priceConfigurations[index],
                                      "quantity",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "formData.priceConfigurations[index].quantity"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  index === 0 &&
                                  _vm.$t(
                                    "createMixMatch.priceConfiguration.priceLabel"
                                  )
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "$" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "name",
                                      "no-wheel": "",
                                      step: "0.01",
                                      "lazy-formatter": "",
                                      formatter: _vm.formatAmount,
                                      type: "number",
                                      "aria-describedby":
                                        "input-live-help input-live-feedback",
                                      maxlength: "10",
                                      min: "0"
                                    },
                                    model: {
                                      value:
                                        _vm.formData.priceConfigurations[index]
                                          .amount,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.priceConfigurations[
                                            index
                                          ],
                                          "amount",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "formData.priceConfigurations[index].amount"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center"
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  index === 0 &&
                                  _vm.$t(
                                    "createMixMatch.priceConfiguration.totalLabel"
                                  )
                              }
                            },
                            [
                              _c("b-checkbox", {
                                model: {
                                  value:
                                    _vm.formData.priceConfigurations[index]
                                      .totalPrices,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.priceConfigurations[index],
                                      "totalPrices",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.priceConfigurations[index].totalPrices"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _vm.canShowDeleteButton(_vm.formData.priceConfigurations)
                        ? _c("b-icon", {
                            staticClass: "rounded mr-2",
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              icon: "x-circle",
                              "font-scale": "1.5",
                              variant: "danger"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleRemovePriceConfig(index)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.formData.priceConfigurations.length < 10
                        ? _c("b-icon", {
                            staticClass: "rounded",
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              icon: "plus-circle",
                              "font-scale": "1.5",
                              variant: "primary"
                            },
                            on: { click: _vm.addPriceConfig }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ])
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("createMixMatch.modals.cancel.title"),
            static: true,
            centered: true,
            "hide-header-close": true,
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "w-100",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end"
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: {
                            border: "none",
                            "margin-right": "15px"
                          },
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.handleDiscardChanges }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("createMixMatch.modals.cancel.discard")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              _vm.showModal = false
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("createMixMatch.modals.cancel.continue")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("b-row", { staticClass: "mb-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("createMixMatch.modals.cancel.body")) + " "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }