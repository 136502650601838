var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("pie-line-chart", {
        staticClass: "users-per-month__chart",
        attrs: {
          pending: _vm.isLoading,
          options: _vm.chartOptions,
          force: true,
          series: _vm.series,
          labels: _vm.labels(),
          limit: 1000,
          line: true,
          multiple: false,
          title: _vm.$t("analytics.usersPerMonth.title")
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }