var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-input", {
    staticClass: "vel-table-filter",
    attrs: {
      placeholder: _vm.placeholder,
      value: _vm.value,
      "prefix-icon": "el-icon-search",
      clearable: ""
    },
    on: { input: _vm.handleInput },
    model: {
      value: _vm.input,
      callback: function($$v) {
        _vm.input = $$v
      },
      expression: "input"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }