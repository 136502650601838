var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "locations",
      attrs: { loading: _vm.status.isLoading },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("pages.locations")) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isLargeUp
        ? [
            _c(
              "vel-card",
              { staticClass: "locations__table" },
              [
                _c("vel-table", {
                  attrs: {
                    defaultDirection: "asc",
                    defaultSort: "lastInvoiceTime",
                    rows: _vm.rows,
                    columns: _vm.columns,
                    hoverable: false,
                    exportable: true,
                    exportableMetas: _vm.exportableMetas,
                    filterValue: _vm.filter,
                    filterable: true,
                    filterPlaceholder: _vm.$t("defaults.placeholder.filter"),
                    showRowsLength: true,
                    initialLength: _vm.getLocationsAmount
                  },
                  on: { "filter-change": _vm.filterCallback },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "name-column",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "locations__name" },
                              [
                                _c("vel-avatar", {
                                  staticClass: "locations__name-avatar",
                                  attrs: { fullname: scope.cell.value }
                                }),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "locations__name-text",
                                    attrs: {
                                      to: {
                                        name: "location",
                                        params: { locationId: scope.row.id }
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "locations__name-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.cell.value || scope.row.id
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "version-column",
                        fn: function(scope) {
                          return [_vm._v(" " + _vm._s(scope.row.version) + " ")]
                        }
                      },
                      {
                        key: "lastInvoiceTime-column",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(
                                    scope.row.lastInvoiceTime
                                  )
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "countryId-column",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (scope.row.countryCode &&
                                    _vm.$t(
                                      "countries." + scope.row.countryCode
                                    )) ||
                                    ""
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "empty",
                        fn: function() {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("locations.errors.noData")) +
                                " "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    347205165
                  )
                })
              ],
              1
            )
          ]
        : [
            _c("vel-table-filter", {
              staticClass: "locations__filter",
              model: {
                value: _vm.inputFilter,
                callback: function($$v) {
                  _vm.inputFilter = $$v
                },
                expression: "inputFilter"
              }
            }),
            _vm._l(_vm.rows, function(location) {
              return _c(
                "vel-card",
                {
                  key: location.licenceNumber,
                  staticClass: "locations__list",
                  attrs: { "title-tag": "header" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _c("vel-avatar", {
                              staticClass: "location__avatar",
                              attrs: { fullname: location.name }
                            }),
                            _c(
                              "router-link",
                              {
                                staticClass: "locations__name-text",
                                attrs: {
                                  to: {
                                    name: "location",
                                    params: { locationId: location.id }
                                  }
                                }
                              },
                              [
                                _c("h3", { staticClass: "location__name" }, [
                                  _vm._v(_vm._s(location.name))
                                ])
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c("p", { staticClass: "location__group" }, [
                    _vm._v(_vm._s(location.groups))
                  ]),
                  _c("p", { staticClass: "location__address" }, [
                    _vm._v(_vm._s(location.address))
                  ]),
                  _c("p", { staticClass: "location__licence-number" }, [
                    _vm._v(_vm._s(location.licenceNumber))
                  ]),
                  _c("p", { staticClass: "location__veloce-version" }, [
                    _vm._v(" " + _vm._s(location.version) + " ")
                  ]),
                  _c("p", { staticClass: "location__last-invoice-time" }, [
                    _c(
                      "span",
                      {
                        attrs: { title: _vm.$t("locations.syncDate.tooltip") }
                      },
                      [
                        _c("vel-icon", {
                          attrs: {
                            name: "sync",
                            modifier: "on-bright",
                            size: "xsmall"
                          }
                        })
                      ],
                      1
                    ),
                    _c("span", { staticClass: "last-invoice-time__date" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(location.lastInvoiceTime))
                      )
                    ])
                  ])
                ]
              )
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }