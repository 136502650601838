import { render, staticRenderFns } from "./GrossSalesVolume.vue?vue&type=template&id=58092430&"
import script from "./GrossSalesVolume.vue?vue&type=script&lang=js&"
export * from "./GrossSalesVolume.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/runner/work/velweb/velweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58092430')) {
      api.createRecord('58092430', component.options)
    } else {
      api.reload('58092430', component.options)
    }
    module.hot.accept("./GrossSalesVolume.vue?vue&type=template&id=58092430&", function () {
      api.rerender('58092430', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/private/shared/indicators/indicator/GrossSalesVolume.vue"
export default component.exports