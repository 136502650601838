var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page bootstrap",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm._v(" " + _vm._s(_vm.$t("pages.location-configuration")) + " ")
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "vel-card",
        { staticClass: "content" },
        [
          _c("h3", { staticClass: "mb-4" }, [_vm._v("Back-Office")]),
          _c(
            "b-card",
            {
              staticClass: "back-card",
              attrs: {
                "bg-variant": "dark",
                "text-variant": "white",
                "no-body": ""
              }
            },
            [
              _c(
                "b-card-header",
                {
                  attrs: {
                    "header-bg-variant": "dark",
                    "text-variant": "white"
                  }
                },
                [_vm._v(" Employee Management ")]
              ),
              _c(
                "b-card-body",
                {
                  attrs: {
                    "body-bg-variant": "white",
                    "body-text-variant": "dark"
                  }
                },
                [
                  _c(
                    "b-button",
                    { attrs: { variant: "link", to: { name: "employees" } } },
                    [
                      _c("b-icon", { attrs: { icon: "file-person-fill" } }),
                      _vm._v(" Employee Profiles ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }