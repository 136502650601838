var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "change-password-page",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("changePassword.title")))]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "vel-card",
        [
          _vm.showAlert
            ? _c("vel-modal", {
                attrs: { "auto-dismiss": true, dismissable: "" },
                on: { dismiss: _vm.navigateToLogout },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.$t("changePassword.title")) + " "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _vm._v(_vm._s(_vm.$t("changePassword.success")))
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "footer",
                      fn: function() {
                        return [
                          _c(
                            "vel-button",
                            {
                              attrs: { type: "secondary" },
                              on: { click: _vm.navigateToLogout }
                            },
                            [_vm._v(_vm._s(_vm.$t("defaults.actions.close")))]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2093086654
                )
              })
            : _vm._e(),
          _c("change-password-form", { on: { submit: _vm.handleSubmit } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }