import { render, staticRenderFns } from "./ResetPasswordForm.vue?vue&type=template&id=09809199&"
import script from "./ResetPasswordForm.vue?vue&type=script&lang=js&"
export * from "./ResetPasswordForm.vue?vue&type=script&lang=js&"
import style0 from "./ResetPasswordForm.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/runner/work/velweb/velweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('09809199')) {
      api.createRecord('09809199', component.options)
    } else {
      api.reload('09809199', component.options)
    }
    module.hot.accept("./ResetPasswordForm.vue?vue&type=template&id=09809199&", function () {
      api.rerender('09809199', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/public/users/components/ResetPasswordForm.vue"
export default component.exports