var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bootstrap" }, [
    _c(
      "div",
      { class: "align_" + _vm.position },
      [
        _vm.isGroupSelectable
          ? _c("vel-group-selector", {
              staticClass: "toolbar__group-selector",
              attrs: {
                allowGlobalGroupOption: "",
                allGroupsLabel: _vm.$t("dashboard.groupSelector.allGroupsText"),
                groupId: _vm.groupId,
                groupsOptions: _vm.groups,
                disabled: _vm.getLocationsIsLoading,
                multiple: false,
                autofocus: "",
                filterable: "",
                showGlobalGroupOptionGroupLabel: false
              },
              on: { change: _vm.onGroupChange }
            })
          : _vm._e(),
        _vm.isCurrencySelectable
          ? _c("vel-currency-selector", {
              staticClass: "toolbar__currency-selector",
              attrs: {
                allowGlobalCurrencyOption: _vm.enableAllCurrencies,
                "select-first": !_vm.locationId,
                disabled: _vm.getLocationsIsLoading || _vm.isCurrencyDisabled,
                currencyId: _vm.getCurrencySelectorSelected,
                currenciesOptions: _vm.getLocationsCurrenciesBySelectedGroup,
                showGlobalCurrencyOptionGroupLabel: false
              },
              on: { change: _vm.onCurrencyChange }
            })
          : _vm._e(),
        !_vm.multipleLocations
          ? _c("vel-location-selector", {
              staticClass: "toolbar__location-selector",
              attrs: {
                allowGlobalLocationOption:
                  _vm.locations.length > 1 && _vm.enableAllLocations,
                allLocationsLabel: _vm.$t(
                  "dashboard.locationSelector.allLocationsText"
                ),
                disabled: _vm.getLocationsIsLoading,
                locationId: _vm.locationId,
                locationsOptions: _vm.locationsBySelectedGroup,
                showGlobalLocationOptionGroupLabel: false
              },
              on: { change: _vm.onLocationChange }
            })
          : _vm._e(),
        _vm.multipleLocations
          ? _c(
              "multiselect",
              {
                ref: "test",
                class: _vm.getClassByLocationCount,
                staticStyle: { "max-width": "300px" },
                attrs: {
                  options: _vm.getLocationsWithVerticalOrder,
                  multiple: true,
                  showLabels: false,
                  "close-on-select": false,
                  "clear-on-select": false,
                  "preserve-search": true,
                  value: _vm.getLocationIds,
                  placeholder: "",
                  label: "label",
                  "options-limit": 100000,
                  "track-by": "value",
                  "max-height": 600,
                  disabled: _vm.getLocationsIsLoading
                },
                on: { input: _vm.onLocationMultipleChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function(props) {
                        return [
                          _c("div", { staticClass: "option__desc" }, [
                            _c("span", { staticClass: "option__title" }, [
                              _vm._v(_vm._s(props.option.label || "---"))
                            ])
                          ])
                        ]
                      }
                    },
                    {
                      key: "selection",
                      fn: function(ref) {
                        var values = ref.values
                        var search = ref.search
                        var isOpen = ref.isOpen
                        return [
                          _vm.locationsBySelectedGroup.length > 1 &&
                          values.length === 0 &&
                          !isOpen
                            ? _c(
                                "span",
                                { staticClass: "multiselect__single" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.locationSelector.allLocationsText"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.locationsBySelectedGroup.length > 1 &&
                          values.length &&
                          values.length > 1 &&
                          !isOpen
                            ? _c(
                                "span",
                                { staticClass: "multiselect__single" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.locationSelector.locationsSelected",
                                          {
                                            count: values.length || 0,
                                            total:
                                              _vm.locationsBySelectedGroup
                                                .length || 0
                                          }
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          !isOpen &&
                          (_vm.locationsBySelectedGroup.length === 1 ||
                            (values.length && values.length == 1))
                            ? _c(
                                "span",
                                {
                                  staticClass: "multiselect__single",
                                  staticStyle: {
                                    overflow: "hidden",
                                    "text-overflow": "fade(5%)",
                                    "text-wrap": "none",
                                    "white-space": "nowrap"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getSelectedLocation &&
                                          (_vm.getSelectedLocation
                                            .reportingDisplayName ||
                                            _vm.getSelectedLocation.name)
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("div")
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3756422326
                )
              },
              [
                _c("template", { slot: "noResult" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("dashboard.locationSelector.noResult")) +
                      " "
                  )
                ]),
                _c("template", { slot: "beforeList" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "row",
                        "justify-items": "center",
                        padding: "9px 16px",
                        width: "100%"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          cursor: "pointer",
                          width: "28px",
                          height: "16px",
                          "margin-right": "0.5rem"
                        },
                        attrs: {
                          src: _vm.enableMultiSelection
                            ? "/toggle-on.png"
                            : "/toggle-off.png",
                          alt: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.onToggleMultiSelection(
                              !_vm.enableMultiSelection
                            )
                          }
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("dashboard.locationSelector.multi")) +
                          " "
                      )
                    ]
                  ),
                  _vm.locationsBySelectedGroup.length > 1
                    ? _c(
                        "div",
                        {
                          staticClass: "bootstrap allloc",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            padding: "9px 16px",
                            width: "100%"
                          }
                        },
                        [
                          !(_vm.getLocationIds.length === 0)
                            ? _c("div", { staticStyle: { width: "120px" } }, [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.onLocationMultipleChange([])
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "dashboard.locationSelector.allLocationsTextLabel"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.getLocationIds.length === 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    width: "120px"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.onLocationMultipleChange(
                                            []
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "dashboard.locationSelector.allLocationsTextLabel"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.enableMultiSelection
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "flex-grow": "1",
                                    "padding-left": "2rem",
                                    "text-align": "right",
                                    width: "auto"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        width: "100%"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#00a7e1",
                                            cursor: "pointer",
                                            "white-space": "nowrap"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onLocationMultipleChange(
                                                _vm.getLocationIds.length !==
                                                  _vm.locationsBySelectedGroup
                                                    .length
                                                  ? _vm.locationsBySelectedGroup
                                                  : []
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dashboard.locationSelector.selectAll"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _vm.getLocationIds.length > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#00a7e1",
                                                cursor: "pointer",
                                                "padding-left": "1rem",
                                                "white-space": "nowrap"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onLocationMultipleChange(
                                                    []
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "dashboard.locationSelector.unselectAll"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ])
              ],
              2
            )
          : _vm._e(),
        !_vm.enableDateRange
          ? _c(
              "div",
              [
                _vm.enableDatePicker
                  ? _c("vel-datepicker", {
                      staticClass: "toolbar__date-selector",
                      attrs: {
                        disabled: _vm.getLocationsIsLoading,
                        editable: _vm.isDateEditable,
                        date: _vm.selectedDate,
                        align: "center",
                        type: "date",
                        clearable: false,
                        format: _vm.getDateFormat
                      },
                      model: {
                        value: _vm.selectedDate,
                        callback: function($$v) {
                          _vm.selectedDate = $$v
                        },
                        expression: "selectedDate"
                      }
                    })
                  : _vm._e(),
                _vm.enableDateCompare
                  ? _c("vel-date-compare-selector", {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:show",
                          value: "enable_period_selector",
                          expression: "'enable_period_selector'",
                          arg: "show"
                        }
                      ],
                      attrs: {
                        selected: _vm.getDateCompareSelectorSelected,
                        disabled: _vm.getLocationsIsLoading,
                        format: _vm.getDateFormat
                      },
                      on: { change: _vm.onComparatorChange }
                    })
                  : _vm._e()
              ],
              1
            )
          : _c(
              "div",
              [
                _c("date-range-selector", {
                  staticClass: "report__date-range-selector",
                  attrs: {
                    range: _vm.selectedDateRange,
                    disabled: _vm.getLocationsIsLoading,
                    format: _vm.getDateFormat
                  },
                  on: { change: _vm.onDateRangeChange }
                }),
                _vm.enableDateCompare
                  ? _c("vel-date-compare-selector", {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:show",
                          value: "enable_period_selector",
                          expression: "'enable_period_selector'",
                          arg: "show"
                        }
                      ],
                      attrs: {
                        selected: _vm.getDateCompareSelectorSelected,
                        disabled: _vm.getLocationsIsLoading,
                        format: _vm.getDateFormat
                      },
                      on: { change: _vm.onComparatorChange }
                    })
                  : _vm._e()
              ],
              1
            ),
        _c("div", [_vm._t("default")], 2),
        _c(
          "span",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.top.hover",
                modifiers: { top: true, hover: true }
              }
            ],
            staticClass: "generate d-flex",
            attrs: {
              title:
                _vm.getLocationsIsLoading || _vm.isGenerated
                  ? _vm.$t("toolbar.selectors.generateLabelTooltip")
                  : ""
            }
          },
          [
            _vm.enableButton &&
            (_vm.isUserAdmin ||
              this.enableButtonOnSingleSelection ||
              this.enableMultiSelection)
              ? _c(
                  "vel-button",
                  {
                    staticClass: "generate d-flex",
                    attrs: {
                      disabled: _vm.getLocationsIsLoading || _vm.isGenerated
                    },
                    on: {
                      click: function($event) {
                        return _vm.generate()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("toolbar.selectors.generateLabel")) +
                        " "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm.isGenerated &&
        _vm.multipleLocations &&
        (this.enableButtonOnSingleSelection || this.enableMultiSelection) &&
        this.enableMultiSelection &&
        _vm.enableButton &&
        (_vm.locationsBySelectedGroup || []).filter(function(x) {
          return _vm.getLocationIds
            .map(function(x) {
              return x.value
            })
            .includes(x.value)
        }).length > 0
          ? _c(
              "div",
              {
                staticStyle: {
                  "align-self": "center",
                  color: "#00a7e1",
                  cursor: "pointer",
                  "margin-left": "2rem",
                  "vertical-align": "middle"
                },
                on: {
                  click: function($event) {
                    _vm.showLocations = !_vm.showLocations
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        _vm.showLocations
                          ? "toolbar.selectors.hideLocation"
                          : "toolbar.selectors.showLocation",
                        {
                          count: (_vm.locationsBySelectedGroup || []).filter(
                            function(x) {
                              return _vm.getLocationIds
                                .map(function(x) {
                                  return x.value
                                })
                                .includes(x.value)
                            }
                          ).length
                        }
                      )
                    ) +
                    " "
                )
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _vm.multipleLocations && _vm.showLocations
      ? _c(
          "div",
          {
            class: { generated: _vm.isGenerated, tagsList: true },
            staticStyle: { "flex-wrap": "wrap" }
          },
          _vm._l(
            (_vm.locationsBySelectedGroup || []).filter(function(x) {
              return _vm.getLocationIds
                .map(function(x) {
                  return x.value
                })
                .includes(x.value)
            }),
            function(tag) {
              return _c(
                "el-tag",
                {
                  key: tag.value,
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { type: "primary", closable: "" },
                  on: {
                    close: function($event) {
                      _vm.getLocationIds.splice(
                        _vm.getLocationIds
                          .map(function(x) {
                            return x.value
                          })
                          .indexOf(tag.value),
                        1
                      )
                      _vm.onLocationMultipleChange(_vm.getLocationIds)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(tag.label) + " ")]
              )
            }
          ),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }