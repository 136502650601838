var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4 mt-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center mb-2 mb-md-0",
              attrs: { cols: "12", md: "4" }
            },
            [_vm._v(_vm._s(_vm.$t("jobRoles.add.label")))]
          ),
          _c(
            "b-col",
            [
              _c("b-form-input", {
                attrs: {
                  id: "name",
                  placeholder: _vm.$t("jobRoles.add.placeholder")
                },
                model: {
                  value: _vm.roleName,
                  callback: function($$v) {
                    _vm.roleName = $$v
                  },
                  expression: "roleName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      !_vm.isEdit
        ? _c(
            "b-button",
            {
              attrs: { disabled: _vm.roleName === "", variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.addRole()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("jobRoles.add.btn")) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }