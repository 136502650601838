import 'whatwg-fetch';
import { get, patch, post, put, remove, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';
import pick from 'lodash.pick';

/**
 * Get all items
 * @link https://api.posveloce.com/docs/#operation/getItems
 */

const getItems = (token, query) => {
  const params = pick(query, [
    'locationId',
    'locationIds',
    'groupId',
    'divisionId',
    'itemType',
    'isActive',
    'limit',
    'offset',
    'kind'
  ]);
  const url = resolveCommonQuery('/items', params).toString();
  return get(token, url);
};

/**
 * Get item by id
 * @link https://api.posveloce.com/docs/private#operation/getItem
 */

const getItemById = (token, query) => {
  const params = pick(query, ['include']);
  const url = resolveCommonQuery(`/items/${query.id}`, params).toString();
  return get(token, url);
};

/**
 * Update a item by its id
 * @link https://api.posveloce.com/docs/private#operation/putItem
 */

const updateItemById = (token, itemId, item) => put(token, `/items/${itemId}`, item);

/**
 * Update partialy an item
 * @link https://api.posveloce.com/docs/private#operation/patchItem
 */

const patchItemById = (token, itemId, item) => patch(token, `/items/${itemId}`, item);

/**
 * Get item schedule
 * @link https://pr-XX.api.veloce.app/items/{itemID}/schedule
 */

const getItemByIdSchedule = (token, query) => {
  const params = pick(query, ['limit', 'offset']);
  const url = resolveCommonQuery(`/items/${query.id}/schedule`, params).toString();
  return get(token, url);
};

/**
 * Update or create item schedule
 * @link https://api.posveloce.com/docs/private#operation/putItemSchedule
 */

const updateItemByIdSchedule = (token, itemId, schedule) => put(token, `/items/${itemId}/schedule`, schedule);

/**
 * Update or create item prices
 * @link https://api.posveloce.com/docs/private#operation/putItemPrices
 */

const updateItemByIdSPrices = (token, itemId, prices) => put(token, `/items/${itemId}/prices`, prices);

/**
 * Get item prices
 * @link https://api.posveloce.com/docs/private#operation/getItemPrices
 */

const getItemByIdPrices = (token, query) => {
  const params = pick(query, ['limit', 'offset']);
  const url = resolveCommonQuery(`/items/${query.id}/prices`, params).toString();
  return get(token, url);
};

/**
 * Get item services
 * @link https://api.posveloce.com/docs/private#operation/getItemServices
 */

export const getItemServices = query => {
  const params = pick(query, ['limit', 'offset', 'groupId', 'locationId']);
  const url = resolveCommonQuery(`/items/services`, params).toString();
  return get(getToken(), url);
};

/**
 * Update an item service
 * @link https://api.posveloce.com/docs/private#operation/patchItemService
 */

export const patchItemService = (serviceId, coursing) => patch(getToken(), `/items/services/${serviceId}`, coursing);

/**
 * Create an item service
 * @link https://api.posveloce.com/docs/private#operation/postItemService
 */

export const postItemService = coursing => post(getToken(), `/items/services`, coursing);

/**
 * Delete item service by id
 * @link https://api.posveloce.com/docs/private#operation/deleteItemService
 */

export const deleteItemService = serviceId => remove(getToken(), `/items/services/${serviceId}`);

/**
 * Get item counters
 * @link https://api.posveloce.com/docs/private#operation/getItemCounters
 */

export const getItemCounters = query => {
  const params = pick(query, ['limit', 'offset', 'groupId', 'locationId']);
  const url = resolveCommonQuery(`/items/counters`, params).toString();
  return get(getToken(), url);
};

/**
 * Update an item counter
 * @link https://api.posveloce.com/docs/private#operation/patchItemCounter
 */

export const patchItemCounter = (counterId, counter) => patch(getToken(), `/items/counters/${counterId}`, counter);

/**
 * Create an item counter
 * @link https://api.posveloce.com/docs/private#operation/postItemCounter
 */

export const postItemCounter = counter => post(getToken(), `/items/counters`, counter);

/**
 * Delete item counter by id
 * @link https://api.posveloce.com/docs/private#operation/deleteItemCounter
 */

export const deleteItemCounter = counterId => remove(getToken(), `/items/counters/${counterId}`);

/**
 * Create an item profile
 * @link https://api.posveloce.com/docs/private#operation/postItemProfile
 */

export const postItemProfile = itemProfile => post(getToken(), `/items/profiles`, itemProfile);

/**
 * pate an item profile
 * @link https://api.posveloce.com/docs/private#operation/updateItemProfile
 */

export const updateItemProfile = (profileId, itemProfile) =>
  patch(getToken(), `/items/profiles/${profileId}`, itemProfile);

/**
 * Get item profiles
 * @link https://api.posveloce.com/docs/private#operation/getItemProfiles
 */

export const getItemProfiles = query => {
  const params = pick(query, ['limit', 'offset', 'groupId', 'locationId', 'include']);
  const url = resolveCommonQuery(`/items/profiles`, params).toString();
  return get(getToken(), url);
};

/**
 * Get an item profile by id
 * @link https://api.posveloce.com/docs/private#operation/getItemProfileById
 */

const getItemProfileById = (token, profileId) => get(token, `/items/profiles/${profileId}`);

/**
 * Delete an item profile by id
 * @link https://api.posveloce.com/docs/private#operation/deleteItemProfile
 */

export const deleteItemProfile = profileId => remove(getToken(), `/items/profiles/${profileId}`);

/**
 * Get combo included items
 * @link https://api.posveloce.com/docs/private#operation/getComboIncludedItems
 */

export const getComboIncludedItems = query => {
  const params = pick(query, ['limit', 'offset', 'groupId', 'locationId', 'include', 'search', 'order']);
  const url = resolveCommonQuery(`/items/comboIncludedItems`, params).toString();
  return get(getToken(), url);
};

/**
 * Get an item profile by id
 * @link https://api.posveloce.com/docs/private#operation/getItemProfileById
 */

const getComboIncludedItemById = (token, comboId) => get(token, `/items/comboIncludedItems/${comboId}`);

/**
 * Create a combo included item with details
 * @link https://api.posveloce.com/docs/private#operation/postComboIncludedItems
 */
export const postComboIncludedItems = comboIncludedItem => post(getToken(), `/items/comboIncludedItems`, comboIncludedItem);

/**
 * Create a combo included item with details
 * @link https://api.posveloce.com/docs/private#operation/postComboIncludedItems
 */
export const updateComboIncludedItem = (comboId, comboIncludedItem) => patch(getToken(), `/items/comboIncludedItems/${comboId}`, comboIncludedItem);

/**
 * Get item popups
 * @link https://api.posveloce.com/docs/private#operation/getItemPopups
 */

export const getItemPopups = query => {
  const params = pick(query, ['limit', 'offset', 'groupId', 'locationId', 'include']);
  const url = resolveCommonQuery(`/items/popups`, params).toString();
  return get(getToken(), url);
};

/**
 * Delete combo included item by id
 * @link https://api.posveloce.com/docs/private#operation/deleteComboIncludedItem
 */

export const deleteComboIncludedItem = comboId => remove(getToken(), `/items/comboIncludedItems/${comboId}`);

export default {
  getItemById,
  getItemByIdPrices,
  getItemByIdSchedule,
  getItems,
  getItemCounters,
  patchItemById,
  updateItemById,
  updateItemByIdSchedule,
  updateItemByIdSPrices,
  getItemProfiles,
  postItemProfile,
  getItemProfileById,
  updateItemProfile,
  deleteItemProfile,
  getComboIncludedItems,
  getItemPopups,
  deleteComboIncludedItem,
  postComboIncludedItems,
  getComboIncludedItemById,
  updateComboIncludedItem
};
