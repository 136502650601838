<template>
  <vel-page class="divisions-list" :loading="loading">
    <template v-slot:title>
      {{ $t('divisionsList.title') }}
    </template>

    <div class="divisions-list-location-selector-wrapper">
      <vel-location-selector
        class="big-divisions-page-location-selector"
        :allLocationsLabel="$t('dashboard.locationSelector.allLocationsText')"
        :locationId="locationId"
        :locationsOptions="locations"
        :disabled="getLocationsIsLoading"
        @change="onLocationChange"
      />
    </div>
    <vel-card>
      <vel-table
        :rows="rows"
        :columns="columns"
        filterable
        :filterValue="filter"
        :filterPlaceholder="$t('defaults.placeholder.filter')"
        @filter-change="filterCallback"
        :showRowsLength="true"
        :initialLength="selectTotal"
        class="divisions-table"
      >
        <template v-slot:actions-column="scope">
          <vel-button type="link" icon="edit" @click="handleGoToDivision(scope.row.id, scope.row.bigDivisionId)" />
        </template>
      </vel-table>
    </vel-card>
  </vel-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelLocationSelector from '@/components/location-selector/VelLocationSelector';
import VelPage from '@/components/page/VelPage';
import VelTable from '@/components/table/VelTable';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataBigDivisions} from "@/stores/data/big-divisions.module";
import {useDataDivisions} from "@/stores/data/divisions.module";

export default {
  name: 'divisions-list',
  components: { VelLocationSelector, VelButton, VelCard, VelPage, VelTable },
  mixins: [DeviceMixin],
  computed: {
    ...mapPiniaState(useDataDivisions, ['filter']),
    ...mapPiniaState(useDataLocationsStore, ['locations', 'getLocationsIsLoading']),
    ...mapPiniaState(useUILocationSelector, {
      locationId: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useDataDivisions, ['loading', 'divisions', 'selectTotal']),
    rows() {
      return this.divisions;
    },
    columns() {
      return {
        remoteId: {
          title: this.$t('divisionsList.table.columns.remoteID'),
          width: '80px',
          sortable: true
        },
        name: {
          title: this.$t('divisionsList.table.columns.name'),
          sortable: true,
          width: this.isMediumDown ? '200px' : '50%'
        },
        bigDivisionName: {
          title: this.$t('divisionsList.table.columns.bigDivisionName'),
          sortable: true,
          width: this.isMediumDown ? '200px' : '50%'
        },
        actions: {
          title: 'Action',
          width: '80px'
        }
      };
    }
  },
  methods: {
    ...mapPiniaActions(useDataDivisions, ['getDivisions', 'setFilter', 'setSelected']),
    ...mapPiniaActions(useDataBigDivisions, ['getBigDivisions']),
    ...mapPiniaActions(useUILocationSelector, ['onChange']),
    filterCallback(value) {
      this.setFilter(value);
    },
    onLocationChange(locationId) {
      this.onChange({ locationId }, { root: true });
    },
    handleGoToDivision(divisionId, bigDivisionId) {
      this.setSelected(divisionId);
      this.$router.push({
        path: '/division',
        query: { ...this.$router.currentRoute.query, divisionId, bigDivisionId }
      });
    }
  },
  async mounted() {
    await this.getDivisions();
    await this.getBigDivisions();
  },
  destroyed() {
    this.setFilter('');
  }
};
</script>

<style lang="scss">
.divisions-list {
  &-location-selector-wrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }

  &-location-selector {
    margin-bottom: rem-calc(20);
    width: rem-calc(300);
  }

  .divisions-table {
    height: 100%;
    overflow: auto;
    width: 100%;
  }
}
</style>
