var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("indicator", {
    attrs: {
      loading: _vm.loading,
      amount: _vm.amount,
      "previous-amount": _vm.previousAmount,
      iconName: "users-solid",
      color: "blue"
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _vm._v(" " + _vm._s(_vm.$t("dashboard.customersCount.title")) + " ")
          ]
        },
        proxy: true
      },
      {
        key: "tooltip",
        fn: function() {
          return [
            _vm._v(
              " " + _vm._s(_vm.$t("dashboard.customersCount.description")) + " "
            )
          ]
        },
        proxy: true
      },
      {
        key: "value",
        fn: function() {
          return [_vm._v(" " + _vm._s(_vm.amount) + " ")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }