var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-card",
    {
      staticClass: "entity__info",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm._v(
                " " + _vm._s(_vm.$t(_vm.translationPrefix + ".title")) + " "
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.isLoading && _vm.entity && _vm.properties
        ? _c("div", [
            _vm.avatar
              ? _c(
                  "div",
                  { staticClass: "entity__info-avatar" },
                  [
                    _c("vel-avatar", {
                      attrs: { fullname: _vm.entity[_vm.avatar] }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "entity__info-details" },
              [
                _vm._l(_vm.filteredProperties, function(property) {
                  return _c("p", { key: property, staticClass: "property" }, [
                    _c("span", { staticClass: "property__label" }, [
                      _vm._v(
                        _vm._s(_vm.$t(_vm.translationPrefix + "." + property))
                      )
                    ]),
                    _c("span", { staticClass: "property__value" }, [
                      _vm._v(
                        _vm._s(
                          _vm.transformValueByKey(
                            property,
                            _vm.entity[property]
                          )
                        )
                      )
                    ])
                  ])
                }),
                _vm._l(_vm.explicitProperties, function(property) {
                  return _c(
                    "p",
                    { key: property.name, staticClass: "property" },
                    [
                      _c("span", { staticClass: "property__label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.translationPrefix + "." + property.name)
                          )
                        )
                      ]),
                      _c("span", { staticClass: "property__value" }, [
                        _vm._v(_vm._s(property.value))
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ])
        : _c("vel-spinner")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }