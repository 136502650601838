var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page",
      attrs: {
        enableToolbar: false,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        mode: "edit",
        loading: _vm.loading
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("item-profile-footer", {
                attrs: {
                  "item-profile": _vm.itemProfile,
                  pending: _vm.pending,
                  canSave: _vm.canSave
                },
                on: {
                  onSave: function($event) {
                    return _vm.updateItemProfile()
                  },
                  onCancel: function($event) {
                    return _vm.handleCancel(false)
                  }
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "header",
          fn: function() {
            return [
              _c("item-profile-header", {
                attrs: { "item-profile": _vm.itemProfile },
                on: {
                  "update:itemProfile": function($event) {
                    _vm.itemProfile = $event
                  },
                  "update:item-profile": function($event) {
                    _vm.itemProfile = $event
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("itemProfile.edit.configuration")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column flex-md-row mb-md-2" },
          [
            _c(
              "b-form-group",
              {
                staticClass:
                  "mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 pl-md-0",
                attrs: {
                  label: _vm.$t("itemProfile.edit.fields.description.label"),
                  description: _vm.$t("itemProfile.required"),
                  "label-for": "name"
                }
              },
              [
                _c("b-form-input", {
                  class: {
                    required:
                      !_vm.itemProfile.name || _vm.itemProfile.name === ""
                  },
                  attrs: {
                    id: "name",
                    state: !_vm.submited
                      ? null
                      : _vm.itemProfile.name !== "" && null,
                    maxlength: "50",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.itemProfile.name,
                    callback: function($$v) {
                      _vm.$set(_vm.itemProfile, "name", $$v)
                    },
                    expression: "itemProfile.name"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2",
                attrs: {
                  label: _vm.$t("itemProfile.edit.fields.baseItem.label"),
                  description: _vm.$t("itemProfile.required"),
                  "label-for": "name"
                }
              },
              [
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    options: [
                      { id: null, name: _vm.$t("itemProfile.emptyValue") }
                    ].concat(_vm.items),
                    id: "supplierId",
                    "value-field": "itemId",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.itemProfile.baseItemId,
                    callback: function($$v) {
                      _vm.$set(_vm.itemProfile, "baseItemId", $$v)
                    },
                    expression: "itemProfile.baseItemId"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("itemProfile.edit.parametersSettings")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          { staticClass: "row mx-0 mb-lg-4" },
          [
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.category,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "category", $$v)
                  },
                  expression: "itemProfile.category"
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("itemProfile.edit.fields.category")) + " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.remarksAndPopups,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "remarksAndPopups", $$v)
                  },
                  expression: "itemProfile.remarksAndPopups"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("itemProfile.edit.fields.remarksAndPopups")) +
                    " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.options,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "options", $$v)
                  },
                  expression: "itemProfile.options"
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("itemProfile.edit.fields.options")) + " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.forcedModes,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "forcedModes", $$v)
                  },
                  expression: "itemProfile.forcedModes"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("itemProfile.edit.fields.forcedModes")) +
                    " "
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row mx-0 mb-lg-4" },
          [
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.itemType,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "itemType", $$v)
                  },
                  expression: "itemProfile.itemType"
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("itemProfile.edit.fields.itemType")) + " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.links,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "links", $$v)
                  },
                  expression: "itemProfile.links"
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("itemProfile.edit.fields.links")) + " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.availabilitySchedule,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "availabilitySchedule", $$v)
                  },
                  expression: "itemProfile.availabilitySchedule"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("itemProfile.edit.fields.availabilitySchedule")
                    ) +
                    " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.copyChangedRegularPrices,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "copyChangedRegularPrices", $$v)
                  },
                  expression: "itemProfile.copyChangedRegularPrices"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("itemProfile.edit.fields.copyChangedRegularPrices")
                    ) +
                    " "
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row mx-0 mb-lg-4" },
          [
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.price,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "price", $$v)
                  },
                  expression: "itemProfile.price"
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("itemProfile.edit.fields.price")) + " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.conversions,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "conversions", $$v)
                  },
                  expression: "itemProfile.conversions"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("itemProfile.edit.fields.conversions")) +
                    " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.delivery,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "delivery", $$v)
                  },
                  expression: "itemProfile.delivery"
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("itemProfile.edit.fields.delivery")) + " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.holdAndFireDelay,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "holdAndFireDelay", $$v)
                  },
                  expression: "itemProfile.holdAndFireDelay"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("itemProfile.edit.fields.holdAndFireDelay")) +
                    " "
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row mx-0" },
          [
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.printouts,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "printouts", $$v)
                  },
                  expression: "itemProfile.printouts"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("itemProfile.edit.fields.printouts")) +
                    " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.counters,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "counters", $$v)
                  },
                  expression: "itemProfile.counters"
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("itemProfile.edit.fields.counters")) + " "
                )
              ]
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "ml-0 col-12 col-md-6 col-lg-2",
                model: {
                  value: _vm.itemProfile.taxes,
                  callback: function($$v) {
                    _vm.$set(_vm.itemProfile, "taxes", $$v)
                  },
                  expression: "itemProfile.taxes"
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("itemProfile.edit.fields.taxes")) + " "
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("discardChange.title"),
            static: true,
            centered: true,
            "hide-header-close": true,
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          on: {
            hidden: function($event) {
              return _vm.$emit("onCancel", true)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c("b-container", { attrs: { fluid: "" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.continue")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right ml-2",
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                                _vm.handleCancel(true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.discard")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showDiscardChanges,
            callback: function($$v) {
              _vm.showDiscardChanges = $$v
            },
            expression: "showDiscardChanges"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("discardChange.body")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }