var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-select",
    {
      staticClass: "vel-select",
      attrs: {
        autofocus: _vm.autofocus,
        disabled: _vm.disabled,
        filterable: _vm.isFilterable,
        multiple: _vm.multiple,
        "no-data-text": _vm.$t("group.selector.nodata"),
        "no-match-text": _vm.$t("group.selector.nomatch"),
        placeholder: _vm.$t("group.selector.placeholder"),
        value: _vm.groupId
      },
      on: { change: _vm.handleChange }
    },
    [
      _vm.allowGlobalGroupOption
        ? _c(
            "vel-option-group",
            {
              attrs: {
                label: _vm.showGlobalGroupOptionGroupLabel
                  ? _vm.$t("group.selector.options.groups.all.title")
                  : ""
              }
            },
            [
              _c("vel-option", {
                attrs: {
                  label:
                    _vm.allGroupsLabel ||
                    _vm.$t("group.selector.options.groups.all.defaultLabel"),
                  value: ""
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.groupsOptions.length > 0
        ? _c(
            "vel-option-group",
            {
              attrs: {
                label: _vm.allowGlobalGroupOption
                  ? _vm.$t("group.selector.options.groups.groups.title")
                  : ""
              }
            },
            _vm._l(_vm.groupsOptions, function(option) {
              return _c("vel-option", {
                key: option.value,
                attrs: { label: option.label, value: option.value }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }