var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "report",
      attrs: {
        "multiple-locations": _vm.locations && _vm.locations.length > 1,
        "enable-date-range": true,
        "enable-toolbar": "",
        "show-selector-button": true,
        "enable-currency": true,
        "show-crumbs": false,
        "generated-time": _vm.generatedTime
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("pages.reports.netSales")))]
          },
          proxy: true
        },
        {
          key: "export",
          fn: function() {
            return [
              _vm.hasData
                ? _c("vel-export", {
                    attrs: {
                      disabled:
                        _vm.areSalesLoading || !_vm.hasData || !_vm.columns,
                      generating: _vm.generating,
                      "enable-xls": true,
                      "xls-sub-level": _vm.xlsSubSections
                    },
                    on: { exportXLS: _vm.onExportXLS }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "toolbar-options",
          fn: function() {
            return [
              (!_vm.locationCount || _vm.locationCount > 1) &&
              _vm.getCurrencySelectorSelected !== ""
                ? _c(
                    "div",
                    { staticStyle: { padding: "8px 1rem 0 1rem" } },
                    [
                      _c(
                        "vel-checkbox",
                        {
                          on: {
                            change: function($event) {
                              return _vm.onConsolidate()
                            }
                          },
                          model: {
                            value: _vm.isConsolidated,
                            callback: function($$v) {
                              _vm.isConsolidated = $$v
                            },
                            expression: "isConsolidated"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("toolbar.selectors.consolidate")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "vel-card",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(_vm._s(_vm.$t("netSalesReport.title")))]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.areSalesLoading
            ? _c("vel-spinner", { staticClass: "spinner" })
            : _vm._e(),
          !_vm.hasData && !_vm.areSalesLoading
            ? _c(
                "tr",
                { staticClass: "vel-table__row", attrs: { role: "row" } },
                [
                  _c(
                    "td",
                    {
                      staticClass: "vel-table__cell vel-table__cell_empty",
                      attrs: { role: "cell" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("defaults.empty")) + " ")]
                  )
                ]
              )
            : _c("div", { staticClass: "vel-table-wrapper" }, [
                _c("table", { staticClass: "vel-table vel-table_hoverable" }, [
                  _c("thead", { staticClass: "vel-table-header" }, [
                    _c(
                      "tr",
                      { staticClass: "vel-table__row" },
                      _vm._l(_vm.columns, function(col, cindex) {
                        return _c(
                          "th",
                          {
                            key: cindex,
                            on: {
                              click: function($event) {
                                return _vm.handleColumnClick(cindex)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "container" }, [
                              _c("div", [_vm._v(_vm._s(col.title))]),
                              _c(
                                "div",
                                { staticClass: "sort-icon" },
                                [
                                  _c("vel-icon", {
                                    class:
                                      "vel-table-header__sort-icon vel-table-header__sort" +
                                      (_vm.hasSort(cindex) &&
                                      _vm.hasDirection("asc")
                                        ? "-icon_up"
                                        : ""),
                                    attrs: { name: "order-asc" }
                                  }),
                                  _c("vel-icon", {
                                    class:
                                      " vel-table-header__sort-icon vel-table-header__sort" +
                                      (_vm.hasSort(cindex) &&
                                      _vm.hasDirection("desc")
                                        ? "-icon_down"
                                        : ""),
                                    attrs: { name: "order-desc" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]),
                  _c(
                    "tbody",
                    { staticClass: "vel-table__body" },
                    [
                      _vm._l(_vm.computedRows, function(row, rindex) {
                        return _c(
                          "tr",
                          { key: rindex, staticClass: "vel-table__row" },
                          _vm._l(_vm.filterRow(row), function(cell, cindex) {
                            return _c(
                              "td",
                              { key: cindex, staticClass: "vel-table__cell" },
                              [
                                cindex === 0
                                  ? _c("div", { staticClass: "content" }, [
                                      _vm._v(_vm._s(cell.value))
                                    ])
                                  : cindex === 4
                                  ? _c("vel-amount", {
                                      attrs: {
                                        amount: cell.value,
                                        currency: _vm.sales[0].currency
                                      }
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatAmount(
                                            Math.round(cell.value)
                                          )
                                        )
                                      )
                                    ])
                              ],
                              1
                            )
                          }),
                          0
                        )
                      }),
                      _vm.hasData && !_vm.isConsolidated
                        ? _c(
                            "tr",
                            { staticClass: "vel-table__row vel-table__total" },
                            _vm._l(_vm.columns, function(col, cindex) {
                              return _c(
                                "td",
                                { key: cindex, staticClass: "vel-table__cell" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.getSummaries(cindex)) + " "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }