import { downloadXlsxFile, get, post, put, remove, upload, resolveCommonQuery } from '@/helpers/service.helper';
import { getCurrentTimeForReport } from '@/helpers/date.helpers';
import { getToken } from '@/helpers/token.helper';

const GROUPS_PATH = '/groups/';
const COUPONS_PATH = '/coupons';

/**
 * Get non-expired coupon
 * @link https://api.posveloce.com/docs/#operation/getCouponById
 */
const getCouponById = (token, { coupon }) => get(token, `/coupons/${coupon}`);

/**
 * Get groups coupons
 * @link https://api.posveloce.com/docs/#operation/getCouponsByGroupId
 */
const getCoupons = (groupId, params = {}) => {
  const url = resolveCommonQuery(`${resolveCouponsUrl(groupId, null)}`, params).toString();
  return get(getToken(), url);
};
const downloadCouponsXlsx = (groupId, params = {}) => {
  const url = resolveCommonQuery(`${resolveCouponsUrl(groupId, null)}`, params).toString();
  return downloadXlsxFile(getToken(), url, `Coupons Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Create coupon
 * @link https://api.posveloce.com/docs/#operation/createCoupon
 */
const createCoupon = (token, coupon) => post(token, resolveCouponsUrl(coupon.groupId, null), coupon);

/**
 * Import coupon
 * @link https://api.posveloce.com/docs/#operation/createCoupon
 */
const importCoupons = (token, formData) => {
  const groupId = formData.get('groupId');
  return upload(token, resolveCouponsUrl(groupId, null), formData);
};

/**
 * Get coupon
 * @link https://api.posveloce.com/docs/#operation/getCouponByGroupIDAndCode
 */
const getCoupon = (token, { groupId, coupon }) => get(token, resolveCouponsUrl(groupId, coupon));

/**
 * Delete a coupon
 * @link https://api.posveloce.com/docs/#operation/deleteCoupon
 */
const deleteCoupon = (token, coupon) => remove(token, resolveCouponsUrl(coupon.groupId, coupon.code));

/**
 * Update coupon
 * @link https://api.posveloce.com/docs/#operation/updateCoupon
 */
const updateCoupon = (token, coupon) => put(token, resolveCouponsUrl(coupon.groupId, coupon.code), coupon);

const batchDeleteCoupons = (token, formData) => {
  const groupId = formData.get('groupId');
  return upload(token, `${resolveCouponsUrl(groupId, null)}/delete`, formData);
};


const resolveCouponsUrl = (groupId, couponId) =>
  `${GROUPS_PATH}${groupId}${COUPONS_PATH}${couponId ? `/${couponId}` : ``}`;

export default {
  downloadCouponsXlsx,
  getCouponById,
  getCoupon,
  createCoupon,
  getCoupons,
  updateCoupon,
  deleteCoupon,
  importCoupons,
  batchDeleteCoupons
};
