var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticStyle: { "border-bottom": "8px solid #f5f5f5" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "b-row",
        {
          staticClass: "py-3",
          staticStyle: { "border-bottom": "1px solid #4c566733" }
        },
        [
          _c(
            "b-col",
            {
              staticStyle: { display: "flex", "flex-direction": "row" },
              attrs: { md: "3" }
            },
            [
              _c(
                "div",
                { staticClass: "title-icon d-none" },
                [_c("b-icon", { attrs: { icon: "pencil-fill" } })],
                1
              ),
              _c("div", { staticStyle: { "padding-left": "1rem" } }, [
                _c("h4", { staticClass: "title-right my-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.combo.name !== "" && _vm.combo.name) || "--"
                      ) +
                      " "
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }