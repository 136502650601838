var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#f5f5f5", margin: "0 -15px" } },
    [
      _c(
        "h4",
        {
          staticClass: "p-3 mb-2 bg-white w-100",
          staticStyle: {
            "border-bottom": "1px solid rgba(76, 86, 103, 0.2)",
            "border-top": "1px solid rgba(76, 86, 103, 0.2)"
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.form.id
                  ? _vm.form.name
                  : _vm.$t("accessType.title", { description: _vm.form.name })
              ) +
              " "
          )
        ]
      ),
      _c(
        "b-tabs",
        {
          staticClass: "bg-white",
          model: {
            value: _vm.selectedTab,
            callback: function($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab"
          }
        },
        [
          _vm.form
            ? _c(
                "b-tab",
                {
                  attrs: {
                    title: _vm.$t("accessType.tabs.general.title"),
                    "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
                  }
                },
                [
                  _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("accessType.tabs.general.employeeAccessSetup")
                      )
                    )
                  ]),
                  _c(
                    "b-container",
                    { staticClass: "content p-4", attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "accessType.tabs.general.description.label"
                                    ),
                                    description: _vm.$t(
                                      "employeeCreate.required"
                                    )
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    class: {
                                      required:
                                        !_vm.form.name || _vm.form.name === ""
                                    },
                                    attrs: {
                                      id: "name",
                                      state: !_vm.enableSaveChanges
                                        ? null
                                        : _vm.form.name !== "" && null,
                                      placeholder: _vm.$t(
                                        "accessType.tabs.general.description.placeholder"
                                      ),
                                      "aria-describedby": "passwd-feedback"
                                    },
                                    model: {
                                      value: _vm.form.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "accessType.tabs.general.employeeType.label"
                                    ),
                                    description: _vm.$t(
                                      "employeeCreate.required"
                                    )
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    staticClass: "form-text",
                                    class: { required: !_vm.form.employeeType },
                                    attrs: {
                                      options: [
                                        { id: 2, name: "Bar Waiter" },
                                        { id: 4, name: "Cash clerk" },
                                        { id: 9, name: "Cash clerk-Manager" },
                                        { id: 6, name: "Driver" },
                                        { id: 3, name: "Head waiter" },
                                        { id: 8, name: "Hostess" },
                                        { id: 0, name: "In training" },
                                        { id: 5, name: "Manager" },
                                        { id: 7, name: "None" },
                                        { id: 1, name: "Waiter" },
                                        {
                                          id: null,
                                          name: _vm.$t(
                                            "employeeCreate.emptyValue"
                                          )
                                        }
                                      ],
                                      id: "test",
                                      "value-field": "id",
                                      "text-field": "name",
                                      "aria-describedby":
                                        "input-live-help input-live-feedback"
                                    },
                                    model: {
                                      value: _vm.form.employeeType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "employeeType", $$v)
                                      },
                                      expression: "form.employeeType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("accessType.tabs.general.generalAccess")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-container",
                    { staticClass: "content p-4", attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "onlyOneWorkstationAtTime",
                                    name: "onlyOneWorkstationAtTime"
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .onlyOneWorkstationAtTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "onlyOneWorkstationAtTime",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.onlyOneWorkstationAtTime"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.onlyOneWorkstationAtTime"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "useTables", name: "useTables" },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general.useTables,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "useTables",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.useTables"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.useTables"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "qsr", name: "qsr" },
                                  model: {
                                    value: _vm.form.accessTypes.general.qsr,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "qsr",
                                        $$v
                                      )
                                    },
                                    expression: "form.accessTypes.general.qsr"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("accessType.tabs.general.qsr")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "delivery", name: "delivery" },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general.delivery,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "delivery",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.delivery"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.delivery"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "takeout", name: "takeout" },
                                  model: {
                                    value: _vm.form.accessTypes.general.takeout,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "takeout",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.takeout"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.takeout"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "driveThruLane",
                                    name: "driveThruLane"
                                  },
                                  on: { change: _vm.check },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .driveThruLane,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "driveThruLane",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.driveThruLane"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.driveThruLane"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "driveThruLaneIsDriveThruLane",
                                    name: "driveThruLaneIsDriveThruLane",
                                    disabled: !_vm.form.accessTypes.general
                                      .driveThruLane
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .driveThruLaneIsDriveThruLane,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "driveThruLaneIsDriveThruLane",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.driveThruLaneIsDriveThruLane"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.driveThruLaneIsDriveThruLane"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "runningTotals",
                                    name: "runningTotals"
                                  },
                                  on: { change: _vm.check },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .runningTotals,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "runningTotals",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.runningTotals"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.runningTotals"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-group",
                                { staticClass: "pl-4" },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "runningTotalsOrderOnHold",
                                        inline: "",
                                        name: "runningTotalsOrderOnHold",
                                        disabled: !_vm.form.accessTypes.general
                                          .runningTotals
                                      },
                                      model: {
                                        value:
                                          _vm.form.accessTypes.general
                                            .runningTotalsOrderOnHold,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.accessTypes.general,
                                            "runningTotalsOrderOnHold",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.accessTypes.general.runningTotalsOrderOnHold"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accessType.tabs.general.runningTotalsOrderOnHold"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "runningTotalsSearchName",
                                        inline: "",
                                        name: "runningTotalsSearchName",
                                        disabled: !_vm.form.accessTypes.general
                                          .runningTotals
                                      },
                                      model: {
                                        value:
                                          _vm.form.accessTypes.general
                                            .runningTotalsSearchName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.accessTypes.general,
                                            "runningTotalsSearchName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.accessTypes.general.runningTotalsSearchName"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accessType.tabs.general.runningTotalsSearchName"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "voids", name: "voids" },
                                  model: {
                                    value: _vm.form.accessTypes.general.voids,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "voids",
                                        $$v
                                      )
                                    },
                                    expression: "form.accessTypes.general.voids"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("accessType.tabs.general.voids")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "refunds", name: "refunds" },
                                  model: {
                                    value: _vm.form.accessTypes.general.refunds,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "refunds",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.refunds"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.refunds"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "paymentAccess",
                                    name: "paymentAccess"
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .paymentAccess,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "paymentAccess",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.paymentAccess"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.paymentAccess"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "modifyClosedPayments",
                                    name: "modifyClosedPayments"
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .modifyClosedPayments,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "modifyClosedPayments",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.modifyClosedPayments"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.notifyClosedPayments"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "unrestrictedScreenProgramming",
                                    name: "unrestrictedScreenProgramming"
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .unrestrictedScreenProgramming,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "unrestrictedScreenProgramming",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.unrestrictedScreenProgramming"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.unrestrictedScreenProgramming"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "openDrawer",
                                    name: "openDrawer"
                                  },
                                  on: { change: _vm.check },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general.openDrawer,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "openDrawer",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.openDrawer"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.openDrawer"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-group",
                                { staticClass: "pl-4" },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "disableOpenDrawerDuringOrder",
                                        inline: "",
                                        name: "disableOpenDrawerDuringOrder",
                                        disabled: !_vm.form.accessTypes.general
                                          .openDrawer
                                      },
                                      model: {
                                        value:
                                          _vm.form.accessTypes.general
                                            .disableOpenDrawerDuringOrder,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.accessTypes.general,
                                            "disableOpenDrawerDuringOrder",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.accessTypes.general.disableOpenDrawerDuringOrder"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accessType.tabs.general.disableOpenDrawerDuringOrder"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "cancel", name: "cancel" },
                                  model: {
                                    value: _vm.form.accessTypes.general.cancel,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "cancel",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.cancel"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("accessType.tabs.general.cancel")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "viewPaymentBalances",
                                    name: "viewPaymentBalances"
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .viewPaymentBalances,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "viewPaymentBalances",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.viewPaymentBalances"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.viewPaymentBalances"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "accessType.tabs.general.tipsGeneralConfiguration"
                        )
                      )
                    )
                  ]),
                  _c(
                    "b-container",
                    { staticClass: "content p-4", attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "tips", name: "tips" },
                                  model: {
                                    value: _vm.form.accessTypes.general.tips,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "tips",
                                        $$v
                                      )
                                    },
                                    expression: "form.accessTypes.general.tips"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("accessType.tabs.general.tips")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "tipDeclaration",
                                    name: "tipDeclaration"
                                  },
                                  on: { change: _vm.check },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .tipDeclaration,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "tipDeclaration",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.tipDeclaration"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.tipDeclaration"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "tipDeclarationPriorCashout",
                                    name: "tipDeclarationPriorCashout",
                                    disabled: !_vm.form.accessTypes.general
                                      .tipDeclaration
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .tipDeclarationPriorCashout,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "tipDeclarationPriorCashout",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.tipDeclarationPriorCashout"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.tipDeclarationPriorCashout"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "xxxxxxxx",
                                    name: "xxxxxxxx",
                                    disabled: !_vm.form.accessTypes.general
                                      .tipDeclaration
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .tipDeclarationShowTipDeclaration,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "tipDeclarationShowTipDeclaration",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.tipDeclarationShowTipDeclaration"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.tipDeclarationShowTipDeclaration"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "tipDeclarationSaveTipDeclaration",
                                    name: "tipDeclarationSaveTipDeclaration",
                                    disabled: !_vm.form.accessTypes.general
                                      .tipDeclaration
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .tipDeclarationSaveTipDeclaration,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "tipDeclarationSaveTipDeclaration",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.tipDeclarationSaveTipDeclaration"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.tipDeclarationSaveTipDeclaration"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "tipDistributionTeams",
                                    name: "tipDistributionTeams"
                                  },
                                  on: { change: _vm.check },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .tipDistributionTeams,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "tipDistributionTeams",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.tipDistributionTeams"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.tipDistributionTeams"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id:
                                      "tipDistributionTeamsRemoveUponDeclaration",
                                    name:
                                      "tipDistributionTeamsRemoveUponDeclaration",
                                    disabled: !_vm.form.accessTypes.general
                                      .tipDistributionTeams
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .tipDistributionTeamsRemoveUponDeclaration,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "tipDistributionTeamsRemoveUponDeclaration",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.tipDistributionTeamsRemoveUponDeclaration"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.tipDistributionTeamsRemoveUponDeclaration"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "tipTransfer",
                                    name: "tipTransfer"
                                  },
                                  on: { change: _vm.check },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general.tipTransfer,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "tipTransfer",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.tipTransfer"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.tipTransfer"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "tipTransferEmployeeSelection",
                                    name: "tipTransferEmployeeSelection",
                                    disabled: !_vm.form.accessTypes.general
                                      .tipTransfer
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .tipTransferEmployeeSelection,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "tipTransferEmployeeSelection",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.tipTransferEmployeeSelection"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.tipTransferEmployeeSelection"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("b-col", { attrs: { cols: "12", md: "4" } })
                        ],
                        1
                      ),
                      _c("h6", { staticClass: "mt-3 mb-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "accessType.tabs.general.tipsOptionalConfiguration"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-row",
                                {
                                  staticClass: "mb-3",
                                  staticStyle: { "max-width": "500px" }
                                },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex align-items-center mb-md-0",
                                      attrs: { cols: "12", md: "3" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accessType.tabs.general.bussers.label"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "9" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        {
                                          staticClass: "align-items-center",
                                          attrs: { prepend: "%" }
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "name",
                                              "no-wheel": "",
                                              type: "number",
                                              number: "",
                                              min: 0,
                                              max: 100,
                                              state: !_vm.enableSaveChanges
                                                ? null
                                                : _vm.form.accessTypes.name !==
                                                    "" && null,
                                              placeholder: _vm.$t(
                                                "accessType.tabs.general.bussers.placeholder"
                                              ),
                                              "aria-describedby":
                                                "passwd-feedback"
                                            },
                                            model: {
                                              value:
                                                _vm.form.accessTypes.general
                                                  .tipDistributionBussersPercentage,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form.accessTypes.general,
                                                  "tipDistributionBussersPercentage",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.accessTypes.general.tipDistributionBussersPercentage"
                                            }
                                          }),
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "ml-5 my-0",
                                              attrs: {
                                                id:
                                                  "tipDistributionIncludeServers",
                                                name:
                                                  "tipDistributionIncludeServers"
                                              },
                                              model: {
                                                value:
                                                  _vm.form.accessTypes.general
                                                    .tipDistributionIncludeServers,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form.accessTypes
                                                      .general,
                                                    "tipDistributionIncludeServers",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.accessTypes.general.tipDistributionIncludeServers"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "accessType.tabs.general.tipDistributionIncludeServers"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "ml-5 my-0",
                                              attrs: {
                                                id:
                                                  "tipDistributionIncludeCooks",
                                                name:
                                                  "tipDistributionIncludeCooks"
                                              },
                                              model: {
                                                value:
                                                  _vm.form.accessTypes.general
                                                    .tipDistributionIncludeCooks,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form.accessTypes
                                                      .general,
                                                    "tipDistributionIncludeCooks",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.accessTypes.general.tipDistributionIncludeCooks"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "accessType.tabs.general.tipDistributionIncludeCooks"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accessType.tabs.general.accessMode")) +
                        " "
                    )
                  ]),
                  _c(
                    "b-container",
                    { staticClass: "content p-4", attrs: { fluid: "" } },
                    [
                      _c(
                        "b-input-group",
                        {
                          staticClass:
                            "align-items-md-center flex-md-row flex-column"
                        },
                        [
                          _vm._l(_vm.modes, function(mode) {
                            return _c(
                              "b-form-checkbox",
                              {
                                key: mode.id,
                                staticClass: "mr-5",
                                attrs: { name: "accessModes" },
                                on: {
                                  change: function($event) {
                                    return _vm.onModeChange($event, mode.id)
                                  }
                                },
                                model: {
                                  value: _vm.modesForm[mode.id],
                                  callback: function($$v) {
                                    _vm.$set(_vm.modesForm, mode.id, $$v)
                                  },
                                  expression: "modesForm[mode.id]"
                                }
                              },
                              [_vm._v(" " + _vm._s(mode.name) + " ")]
                            )
                          }),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mr-md-5",
                              attrs: {
                                id: "accessPromptMode",
                                name: "accessPromptMode"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.general.accessPromptMode,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.general,
                                    "accessPromptMode",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.general.accessPromptMode"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.general.accessPromptMode"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        2
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    label: _vm.$t(
                                      "accessType.tabs.general.forcedMode.label"
                                    )
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    staticClass: "form-text",
                                    attrs: {
                                      options: _vm.modes.concat([
                                        {
                                          id: null,
                                          name: _vm.$t(
                                            "employeeCreate.emptyValue"
                                          )
                                        }
                                      ]),
                                      id: "test",
                                      "value-field": "id",
                                      "text-field": "name",
                                      "aria-describedby":
                                        "input-live-help input-live-feedback"
                                    },
                                    model: {
                                      value:
                                        _vm.form.accessTypes.general
                                          .accessForcedModeId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.accessTypes.general,
                                          "accessForcedModeId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.accessTypes.general.accessForcedModeId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("accessType.tabs.general.clientAccount")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-container",
                    { staticClass: "content p-4", attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "accessClientParams",
                                    name: "accessClientParams"
                                  },
                                  on: { change: _vm.check },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .accessClientParams,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "accessClientParams",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.accessClientParams"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.accessClientParams"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "accessClientParamsAccountPayments",
                                    name: "accessClientParamsAccountPayments",
                                    disabled: !_vm.form.accessTypes.general
                                      .accessClientParams
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .accessClientParamsAccountPayments,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "accessClientParamsAccountPayments",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.accessClientParamsAccountPayments"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.accessClientParamsAccountPayments"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "accessClientParamsClientRec",
                                    name: "accessClientParamsClientRec",
                                    disabled: !_vm.form.accessTypes.general
                                      .accessClientParams
                                  },
                                  on: { change: _vm.check },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .accessClientParamsClientRec,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "accessClientParamsClientRec",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.accessClientParamsClientRec"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.accessClientParamsClientRec"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-5",
                                  attrs: {
                                    id:
                                      "accessClientParamsClientRecCreateNewClient",
                                    name:
                                      "accessClientParamsClientRecCreateNewClient",
                                    disabled:
                                      !_vm.form.accessTypes.general
                                        .accessClientParams ||
                                      !_vm.form.accessTypes.general
                                        .accessClientParamsClientRec
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .accessClientParamsClientRecCreateNewClient,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "accessClientParamsClientRecCreateNewClient",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.accessClientParamsClientRecCreateNewClient"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.accessClientParamsClientRecCreateNewClient"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-5",
                                  attrs: {
                                    id: "accessClientParamsClientRecEditPin",
                                    name: "accessClientParamsClientRecEditPin",
                                    disabled:
                                      !_vm.form.accessTypes.general
                                        .accessClientParams ||
                                      !_vm.form.accessTypes.general
                                        .accessClientParamsClientRec
                                  },
                                  on: { change: _vm.check },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .accessClientParamsClientRecEditPin,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "accessClientParamsClientRecEditPin",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.accessClientParamsClientRecEditPin"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.accessClientParamsClientRecEditPin"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "ml-5",
                                      attrs: {
                                        id:
                                          "accessClientParamsClientRecEditPinEventNoPreviousInfo",
                                        name:
                                          "accessClientParamsClientRecEditPinEventNoPreviousInfo",
                                        disabled:
                                          !_vm.form.accessTypes.general
                                            .accessClientParams ||
                                          !_vm.form.accessTypes.general
                                            .accessClientParamsClientRec ||
                                          !_vm.form.accessTypes.general
                                            .accessClientParamsClientRecEditPin
                                      },
                                      model: {
                                        value:
                                          _vm.form.accessTypes.general
                                            .accessClientParamsClientRecEditPinEventNoPreviousInfo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.accessTypes.general,
                                            "accessClientParamsClientRecEditPinEventNoPreviousInfo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.accessTypes.general.accessClientParamsClientRecEditPinEventNoPreviousInfo"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accessType.tabs.general.accessClientParamsClientRecEditPinEventNoPreviousInfo"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "accessClientParamsReports",
                                        name: "accessClientParamsReports",
                                        disabled: !_vm.form.accessTypes.general
                                          .accessClientParams
                                      },
                                      model: {
                                        value:
                                          _vm.form.accessTypes.general
                                            .accessClientParamsReports,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.accessTypes.general,
                                            "accessClientParamsReports",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.accessTypes.general.accessClientParamsReports"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accessType.tabs.general.accessClientParamsReports"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id:
                                          "accessClientParamsBypassCreditLimit",
                                        name:
                                          "accessClientParamsBypassCreditLimit",
                                        disabled: !_vm.form.accessTypes.general
                                          .accessClientParams
                                      },
                                      model: {
                                        value:
                                          _vm.form.accessTypes.general
                                            .accessClientParamsBypassCreditLimit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.accessTypes.general,
                                            "accessClientParamsBypassCreditLimit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.accessTypes.general.accessClientParamsBypassCreditLimit"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accessType.tabs.general.accessClientParamsBypassCreditLimit"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "accessClientParamsAccountBalance",
                                        name:
                                          "accessClientParamsAccountBalance",
                                        disabled: !_vm.form.accessTypes.general
                                          .accessClientParams
                                      },
                                      model: {
                                        value:
                                          _vm.form.accessTypes.general
                                            .accessClientParamsAccountBalance,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.accessTypes.general,
                                            "accessClientParamsAccountBalance",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.accessTypes.general.accessClientParamsAccountBalance"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accessType.tabs.general.accessClientParamsAccountBalance"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "clientAccountRentals",
                                    name: "clientAccountRentals"
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .clientAccountRentals,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "clientAccountRentals",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.clientAccountRentals"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.clientAccountRentals"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "clientAccountRoomService",
                                    name: "clientAccountRoomService"
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .clientAccountRoomService,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "clientAccountRoomService",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.clientAccountRoomService"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.clientAccountRoomService"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id: "clientAccountSelfServiceKiosk",
                                    name: "clientAccountSelfServiceKiosk"
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.general
                                        .clientAccountSelfServiceKiosk,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.general,
                                        "clientAccountSelfServiceKiosk",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.general.clientAccountSelfServiceKiosk"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.general.clientAccountSelfServiceKiosk"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("b-col")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("accessType.tabs.dining.title"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accessType.tabs.dining.tableManagement")) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c("h6", { staticClass: "mb-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accessType.tabs.dining.section")) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "sectionDeleteAssigned",
                                name: "sectionDeleteAssigned"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .sectionDeleteAssigned,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "sectionDeleteAssigned",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.sectionDeleteAssigned"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.sectionDeleteAssigned"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { id: "useTables", name: "useTables" },
                              model: {
                                value: _vm.form.accessTypes.dining.sectionEdit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "sectionEdit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.sectionEdit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("accessType.tabs.dining.sectionEdit")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("h6", { staticClass: "mt-3 mb-3" }, [
                            _vm._v(
                              _vm._s(_vm.$t("accessType.tabs.dining.misc"))
                            )
                          ]),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "miscAutoTableNumber",
                                        name: "miscAutoTableNumber"
                                      },
                                      model: {
                                        value:
                                          _vm.form.accessTypes.dining
                                            .miscAutoTableNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.accessTypes.dining,
                                            "miscAutoTableNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.accessTypes.dining.miscAutoTableNumber"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accessType.tabs.dining.miscAutoTableNumber"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("b-form-select", {
                                    staticClass: "form-text",
                                    attrs: {
                                      options: _vm.tablesSections.concat([
                                        {
                                          id: null,
                                          name: _vm.$t(
                                            "employeeCreate.emptyValue"
                                          )
                                        }
                                      ]),
                                      id: "test",
                                      "value-field": "id",
                                      "text-field": "name",
                                      "aria-describedby":
                                        "input-live-help input-live-feedback"
                                    },
                                    model: {
                                      value:
                                        _vm.form.accessTypes.dining
                                          .miscAutoTableSectionId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.accessTypes.dining,
                                          "miscAutoTableSectionId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.accessTypes.dining.miscAutoTableSectionId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "miscDisableTapMap",
                                name: "miscDisableTapMap"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining.miscDisableTapMap,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "miscDisableTapMap",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.miscDisableTapMap"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.miscDisableTapMap"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "miscCallEmployeeTable",
                                name: "miscCallEmployeeTable"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .miscCallEmployeeTable,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "miscCallEmployeeTable",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.miscCallEmployeeTable"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.miscCallEmployeeTable"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "miscWarnWrongTable",
                                name: "miscWarnWrongTable"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .miscWarnWrongTable,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "miscWarnWrongTable",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.miscWarnWrongTable"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.miscWarnWrongTable"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("h6", { staticClass: "mt-3 mb-3" }, [
                            _vm._v(
                              _vm._s(_vm.$t("accessType.tabs.dining.hostess"))
                            )
                          ]),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "hostessClearTableMapSections",
                                name: "hostessClearTableMapSections"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .hostessClearTableMapSections,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "hostessClearTableMapSections",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.hostessClearTableMapSections"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.hostessClearTableMapSections"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "hostessAutoAssignTableMap",
                                name: "hostessAutoAssignTableMap"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .hostessAutoAssignTableMap,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "hostessAutoAssignTableMap",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.hostessAutoAssignTableMap"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.hostessAutoAssignTableMap"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "hostessConfirmFunctions",
                                name: "hostessConfirmFunctions"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .hostessConfirmFunctions,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "hostessConfirmFunctions",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.hostessConfirmFunctions"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.hostessConfirmFunctions"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "hostessManualAssignEmployeeTable",
                                name: "hostessManualAssignEmployeeTable"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .hostessManualAssignEmployeeTable,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "hostessManualAssignEmployeeTable",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.hostessManualAssignEmployeeTable"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.hostessManualAssignEmployeeTable"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("h6", { staticClass: "mt-3 mb-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("accessType.tabs.dining.operations")
                              )
                            )
                          ]),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operationAutoExit",
                                name: "operationAutoExit"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining.operationAutoExit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "operationAutoExit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.operationAutoExit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.operationAutoExit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operationAutoSeating",
                                name: "operationAutoSeating"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .operationAutoSeating,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "operationAutoSeating",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.operationAutoSeating"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.operationAutoSeating"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operationReturnGroupAfterOrder",
                                name: "operationReturnGroupAfterOrder"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .operationReturnGroupAfterOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "operationReturnGroupAfterOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.operationReturnGroupAfterOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.operationReturnGroupAfterOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operationOrderReview",
                                name: "operationOrderReview"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .operationOrderReview,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "operationOrderReview",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.operationOrderReview"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.operationOrderReview"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operationConfirmTransaction",
                                name: "operationConfirmTransaction"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .operationConfirmTransaction,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "operationConfirmTransaction",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.operationConfirmTransaction"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.operationConfirmTransaction"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "pr-md-6", attrs: { md: "3" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        label: _vm.$t(
                                          "accessType.tabs.dining.operationCommonReading.label"
                                        )
                                      }
                                    },
                                    [
                                      _c("b-form-select", {
                                        staticClass: "form-text",
                                        attrs: {
                                          options: _vm.commonReadings,
                                          id: "test",
                                          "value-field": "id",
                                          "text-field": "name",
                                          "aria-describedby":
                                            "input-live-help input-live-feedback"
                                        },
                                        model: {
                                          value:
                                            _vm.form.accessTypes.dining
                                              .operationCommonReading,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.accessTypes.dining,
                                              "operationCommonReading",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.accessTypes.dining.operationCommonReading"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "pl-md-6", attrs: { md: "3" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        label: _vm.$t(
                                          "accessType.tabs.dining.operationOperatingMode.label"
                                        )
                                      }
                                    },
                                    [
                                      _c("b-form-select", {
                                        staticClass: "form-text",
                                        attrs: {
                                          options: _vm.operatingModes,
                                          id: "test",
                                          "value-field": "id",
                                          "text-field": "name",
                                          "aria-describedby":
                                            "input-live-help input-live-feedback"
                                        },
                                        model: {
                                          value:
                                            _vm.form.accessTypes.dining
                                              .operationOperatingMode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.accessTypes.dining,
                                              "operationOperatingMode",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "form.accessTypes.dining.operationOperatingMode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("accessType.tabs.dining.reservation")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reservationTableAgendaAccess",
                                name: "reservationTableAgendaAccess"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .reservationTableAgendaAccess,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "reservationTableAgendaAccess",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.reservationTableAgendaAccess"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.reservationTableAgendaAccess"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reservationStartAgendaAccess",
                                name: "reservationStartAgendaAccess"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .reservationStartAgendaAccess,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "reservationStartAgendaAccess",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.reservationStartAgendaAccess"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.reservationStartAgendaAccess"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reservationRoomAgendaAccess",
                                name: "reservationRoomAgendaAccess"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .reservationRoomAgendaAccess,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "reservationRoomAgendaAccess",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.reservationRoomAgendaAccess"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.reservationRoomAgendaAccess"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reservationDisplayOrderSummaryKitchen",
                                name: "reservationDisplayOrderSummaryKitchen"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .reservationDisplayOrderSummaryKitchen,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "reservationDisplayOrderSummaryKitchen",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.reservationDisplayOrderSummaryKitchen"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.reservationDisplayOrderSummaryKitchen"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("accessType.tabs.dining.invoicing")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingCashClerk",
                                name: "invoicingCashClerk"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingCashClerk,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingCashClerk",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingCashClerk"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingCashClerk"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingCustomerRedistributionCheck",
                                name: "invoicingCustomerRedistributionCheck"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingCustomerRedistributionCheck,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingCustomerRedistributionCheck",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingCustomerRedistributionCheck"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingCustomerRedistributionCheck"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "invoicingCustomerRedistributionCheckWithoutDiscount",
                                name:
                                  "invoicingCustomerRedistributionCheckWithoutDiscount",
                                disabled: !_vm.form.accessTypes.dining
                                  .invoicingCustomerRedistributionCheck
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingCustomerRedistributionCheckWithoutDiscount,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingCustomerRedistributionCheckWithoutDiscount",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingCustomerRedistributionCheckWithoutDiscount"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingCustomerRedistributionCheckWithoutDiscount"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingDiscounts",
                                name: "invoicingDiscounts"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingDiscounts,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingDiscounts",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingDiscounts"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingDiscounts"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "invoicingDiscountsAfterCheck",
                                name: "invoicingDiscountsAfterCheck",
                                disabled: !_vm.form.accessTypes.dining
                                  .invoicingDiscounts
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingDiscountsAfterCheck,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingDiscountsAfterCheck",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingDiscountsAfterCheck"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingDiscountsAfterCheck"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingOrderAfterCheck",
                                name: "invoicingOrderAfterCheck"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingOrderAfterCheck,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingOrderAfterCheck",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingOrderAfterCheck"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingOrderAfterCheck"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingTransferPaymentDifferentCheck",
                                name: "invoicingTransferPaymentDifferentCheck"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingTransferPaymentDifferentCheck,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingTransferPaymentDifferentCheck",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingTransferPaymentDifferentCheck"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingTransferPaymentDifferentCheck"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "invoicingTransferPaymentDifferentCheckSelectDestination",
                                name:
                                  "invoicingTransferPaymentDifferentCheckSelectDestination",
                                disabled: !_vm.form.accessTypes.dining
                                  .invoicingTransferPaymentDifferentCheck
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingTransferPaymentDifferentCheckSelectDestination,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingTransferPaymentDifferentCheckSelectDestination",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingTransferPaymentDifferentCheckSelectDestination"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingTransferPaymentDifferentCheckSelectDestination"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingAllCashButton",
                                name: "invoicingAllCashButton"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingAllCashButton,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingAllCashButton",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingAllCashButton"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingAllCashButton"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingReturnOrderScreenAfterPayment",
                                name: "invoicingReturnOrderScreenAfterPayment"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingReturnOrderScreenAfterPayment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingReturnOrderScreenAfterPayment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingReturnOrderScreenAfterPayment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingReturnOrderScreenAfterPayment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingEnforceDrawClosePriorNextOrder",
                                name: "invoicingEnforceDrawClosePriorNextOrder"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingEnforceDrawClosePriorNextOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingEnforceDrawClosePriorNextOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingEnforceDrawClosePriorNextOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingEnforceDrawClosePriorNextOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingPaymentDeleteClosedCheck",
                                name: "invoicingPaymentDeleteClosedCheck"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingPaymentDeleteClosedCheck,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingPaymentDeleteClosedCheck",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingPaymentDeleteClosedCheck"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingPaymentDeleteClosedCheck"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "invoicingPaymentDeleteClosedCheckEdit",
                                name: "invoicingPaymentDeleteClosedCheckEdit",
                                disabled: !_vm.form.accessTypes.dining
                                  .invoicingPaymentDeleteClosedCheck
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingPaymentDeleteClosedCheckEdit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingPaymentDeleteClosedCheckEdit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingPaymentDeleteClosedCheckEdit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingPaymentDeleteClosedCheckEdit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingNoCheckButton",
                                name: "invoicingNoCheckButton"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingNoCheckButton,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingNoCheckButton",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingNoCheckButton"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingNoCheckButton"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingChoosePrinter",
                                name: "invoicingChoosePrinter"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingChoosePrinter,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingChoosePrinter",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingChoosePrinter"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingChoosePrinter"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingPrintDuplicates",
                                name: "invoicingPrintDuplicates"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingPrintDuplicates,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingPrintDuplicates",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingPrintDuplicates"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingPrintDuplicates"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingPrintInternalCopies",
                                name: "invoicingPrintInternalCopies"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingPrintInternalCopies,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingPrintInternalCopies",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingPrintInternalCopies"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingPrintInternalCopies"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "invoicingPrintPricelessCopies",
                                name: "invoicingPrintPricelessCopies"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .invoicingPrintPricelessCopies,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "invoicingPrintPricelessCopies",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.invoicingPrintPricelessCopies"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.invoicingPrintPricelessCopies"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("accessType.tabs.dining.cashDrawer")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "cashDrawerManagement",
                                name: "cashDrawerManagement"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .cashDrawerManagement,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "cashDrawerManagement",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.cashDrawerManagement"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.cashDrawerManagement"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "cashDrawerEnforceAssign",
                                name: "cashDrawerEnforceAssign",
                                disabled: !_vm.form.accessTypes.dining
                                  .cashDrawerManagement
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .cashDrawerEnforceAssign,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "cashDrawerEnforceAssign",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.cashDrawerEnforceAssign"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.cashDrawerEnforceAssign"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "cashDrawerCashInDeposit",
                                name: "cashDrawerCashInDeposit",
                                disabled: !_vm.form.accessTypes.dining
                                  .cashDrawerManagement
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .cashDrawerCashInDeposit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "cashDrawerCashInDeposit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.cashDrawerCashInDeposit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.cashDrawerCashInDeposit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "cashDrawerOpenUnassignDrawer",
                                name: "cashDrawerOpenUnassignDrawer",
                                disabled: !_vm.form.accessTypes.dining
                                  .cashDrawerManagement
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .cashDrawerOpenUnassignDrawer,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "cashDrawerOpenUnassignDrawer",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.cashDrawerOpenUnassignDrawer"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.cashDrawerOpenUnassignDrawer"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "cashDrawerBankDeposit",
                                name: "cashDrawerBankDeposit",
                                disabled: !_vm.form.accessTypes.dining
                                  .cashDrawerManagement
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .cashDrawerBankDeposit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "cashDrawerBankDeposit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.cashDrawerBankDeposit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.cashDrawerBankDeposit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "cashDrawerDepositCashedIn",
                                name: "cashDrawerDepositCashedIn"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .cashDrawerDepositCashedIn,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "cashDrawerDepositCashedIn",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.cashDrawerDepositCashedIn"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.cashDrawerDepositCashedIn"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "cashDrawerEnforceCashedIn",
                                name: "cashDrawerEnforceCashedIn"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .cashDrawerEnforceCashedIn,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "cashDrawerEnforceCashedIn",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.cashDrawerEnforceCashedIn"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.cashDrawerEnforceCashedIn"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "cashDrawerEnforceCashedInBlockOperations",
                                name:
                                  "cashDrawerEnforceCashedInBlockOperations",
                                disabled: !_vm.form.accessTypes.dining
                                  .cashDrawerEnforceCashedIn
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .cashDrawerEnforceCashedInBlockOperations,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "cashDrawerEnforceCashedInBlockOperations",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.cashDrawerEnforceCashedInBlockOperations"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.cashDrawerEnforceCashedInBlockOperations"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("accessType.tabs.dining.visualConfiguration")
                    ) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("accessType.tabs.dining.onScreenButtons")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenSend",
                                name: "visualScreenSend"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining.visualScreenSend,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenSend",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenSend"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenSend"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenPrint",
                                name: "visualScreenPrint"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining.visualScreenPrint,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenPrint",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenPrint"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenPrint"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenClose",
                                name: "visualScreenClose"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining.visualScreenClose,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenClose",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenClose"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenClose"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenMenu",
                                name: "visualScreenMenu"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining.visualScreenMenu,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenMenu",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenMenu"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenMenu"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenTakeout",
                                name: "visualScreenTakeout"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTakeout,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTakeout",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTakeout"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTakeout"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenTableTakeout",
                                name: "visualScreenTableTakeout"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTableTakeout,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTableTakeout",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTableTakeout"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTableTakeout"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenRecipe",
                                name: "visualScreenRecipe"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenRecipe,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenRecipe",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenRecipe"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenRecipe"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "visualScreenRecipePrint",
                                name: "visualScreenRecipePrint",
                                disabled: !_vm.form.accessTypes.dining
                                  .visualScreenRecipe
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenRecipePrint,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenRecipePrint",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenRecipePrint"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenRecipePrint"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenTransferTable",
                                name: "visualScreenTransferTable"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferTable,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferTable",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferTable"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferTable"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenTransferCustomer",
                                name: "visualScreenTransferCustomer"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferCustomer,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferCustomer",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferCustomer"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferCustomer"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "visualScreenTransferCustomerPromptPassword",
                                name:
                                  "visualScreenTransferCustomerPromptPassword",
                                disabled: !_vm.form.accessTypes.dining
                                  .visualScreenTransferCustomer
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferCustomerPromptPassword,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferCustomerPromptPassword",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferCustomerPromptPassword"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferCustomerPromptPassword"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "visualScreenTransferCustomerOnlyNoChecks",
                                name:
                                  "visualScreenTransferCustomerOnlyNoChecks",
                                disabled: !_vm.form.accessTypes.dining
                                  .visualScreenTransferCustomer
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferCustomerOnlyNoChecks,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferCustomerOnlyNoChecks",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferCustomerOnlyNoChecks"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferCustomerOnlyNoChecks"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenTransferItem",
                                name: "visualScreenTransferItem"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferItem,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferItem",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferItem"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferItem"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "visualScreenTransferItemSplit",
                                name: "visualScreenTransferItemSplit",
                                disabled: !_vm.form.accessTypes.dining
                                  .visualScreenTransferItem
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferItemSplit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferItemSplit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferItemSplit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferItemSplit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "visualScreenTransferItemOnlyNoChecks",
                                name: "visualScreenTransferItemOnlyNoChecks",
                                disabled: !_vm.form.accessTypes.dining
                                  .visualScreenTransferItem
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferItemOnlyNoChecks,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferItemOnlyNoChecks",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferItemOnlyNoChecks"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferItemOnlyNoChecks"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenTransferEmployee",
                                name: "visualScreenTransferEmployee"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferEmployee,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferEmployee",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferEmployee"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferEmployee"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "visualScreenTransferEmployeeNoPromptPassword",
                                name:
                                  "visualScreenTransferEmployeeNoPromptPassword",
                                disabled: !_vm.form.accessTypes.dining
                                  .visualScreenTransferEmployee
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferEmployeeNoPromptPassword,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferEmployeeNoPromptPassword",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferEmployeeNoPromptPassword"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferEmployeeNoPromptPassword"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenOrderCombinations",
                                name: "visualScreenOrderCombinations"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenOrderCombinations,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenOrderCombinations",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenOrderCombinations"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenOrderCombinations"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "visualScreenOrderCombinationsPromptPasswordDiffEmployee",
                                name:
                                  "visualScreenOrderCombinationsPromptPasswordDiffEmployee",
                                disabled: !_vm.form.accessTypes.dining
                                  .visualScreenOrderCombinations
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenOrderCombinationsPromptPasswordDiffEmployee,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenOrderCombinationsPromptPasswordDiffEmployee",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenOrderCombinationsPromptPasswordDiffEmployee"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenOrderCombinationsPromptPasswordDiffEmployee"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenResendOrder",
                                name: "visualScreenResendOrder"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenResendOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenResendOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenResendOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenResendOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenRound",
                                name: "visualScreenRound"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining.visualScreenRound,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenRound",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenRound"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenRound"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenPreOrder",
                                name: "visualScreenPreOrder"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenPreOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenPreOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenPreOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenPreOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenTransferOrder",
                                name: "visualScreenTransferOrder"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenTransferOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenTransferOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenTransferOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenTransferOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenGroupTableOrderBatch",
                                name: "visualScreenGroupTableOrderBatch"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenGroupTableOrderBatch,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenGroupTableOrderBatch",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenGroupTableOrderBatch"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenGroupTableOrderBatch"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "visualScreenGroupTableOrderBatchSeparateReceipts",
                                name:
                                  "visualScreenGroupTableOrderBatchSeparateReceipts",
                                disabled: !_vm.form.accessTypes.dining
                                  .visualScreenGroupTableOrderBatch
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenGroupTableOrderBatchSeparateReceipts,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenGroupTableOrderBatchSeparateReceipts",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenGroupTableOrderBatchSeparateReceipts"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenGroupTableOrderBatchSeparateReceipts"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenAssignVisitorMembers",
                                name: "visualScreenAssignVisitorMembers"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenAssignVisitorMembers,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenAssignVisitorMembers",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenAssignVisitorMembers"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenAssignVisitorMembers"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenAllowTableOrderOnHold",
                                name: "visualScreenAllowTableOrderOnHold"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenAllowTableOrderOnHold,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenAllowTableOrderOnHold",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenAllowTableOrderOnHold"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenAllowTableOrderOnHold"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "visualScreenAllowTableOrderOnHoldDefault",
                                name:
                                  "visualScreenAllowTableOrderOnHoldDefault",
                                disabled: !_vm.form.accessTypes.dining
                                  .visualScreenAllowTableOrderOnHold
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .visualScreenAllowTableOrderOnHoldDefault,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "visualScreenAllowTableOrderOnHoldDefault",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.visualScreenAllowTableOrderOnHoldDefault"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.visualScreenAllowTableOrderOnHoldDefault"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accessType.tabs.dining.menuButton")) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    _vm._l(
                      _vm.form.accessTypes.diningBtn || [
                        { keyGroupId: null, isDefault: false }
                      ],
                      function(btn, index) {
                        return _c(
                          "b-col",
                          { key: btn.value, attrs: { md: "4" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-3",
                                attrs: {
                                  label: _vm.$t(
                                    "accessType.tabs.dining.menuButtonLabel",
                                    { id: index + 1 }
                                  )
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-row" },
                                  [
                                    _c("b-form-select", {
                                      staticClass: "form-text",
                                      staticStyle: { "max-width": "200px" },
                                      attrs: {
                                        options: [
                                          {
                                            id: null,
                                            name: _vm.$t(
                                              "employeeCreate.emptyValue"
                                            )
                                          }
                                        ].concat(_vm.keyGroups),
                                        id: "test",
                                        "value-field": "id",
                                        "text-field": "name",
                                        "aria-describedby":
                                          "input-live-help input-live-feedback"
                                      },
                                      on: {
                                        change: function($event) {
                                          if (!$event) {
                                            _vm.form.accessTypes.diningBtn[
                                              index
                                            ].isDefault = false
                                          }
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.form.accessTypes.diningBtn[index]
                                            .keyGroupId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.accessTypes.diningBtn[
                                              index
                                            ],
                                            "keyGroupId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.accessTypes.diningBtn[index].keyGroupId"
                                      }
                                    }),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "ml-4",
                                        attrs: {
                                          disabled: !_vm.form.accessTypes
                                            .diningBtn[index].keyGroupId
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setAsDefault(
                                              index,
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.form.accessTypes.diningBtn[
                                              index
                                            ].isDefault,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.accessTypes.diningBtn[
                                                index
                                              ],
                                              "isDefault",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.accessTypes.diningBtn[index].isDefault"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "employeeCreate.setAsDefault"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _vm.form.accessTypes.diningBtn.length < 5
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "rounded-circle p-1 ml-3 align-self-center",
                                            style: {
                                              visibility:
                                                _vm.form.accessTypes.diningBtn
                                                  .length ===
                                                index + 1
                                                  ? "visible"
                                                  : "hidden"
                                            },
                                            attrs: {
                                              variant: "outline-primary",
                                              size: "sm"
                                            },
                                            on: { click: _vm.onAddDiningBtn }
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "plus",
                                                scale: "1.4"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accessType.tabs.dining.specialKeys")) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id:
                                  "specialKeysEmployeeSelectionKeyDefaultEmployee",
                                name:
                                  "specialKeysEmployeeSelectionKeyDefaultEmployee"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .specialKeysEmployeeSelectionKeyDefaultEmployee,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "specialKeysEmployeeSelectionKeyDefaultEmployee",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.specialKeysEmployeeSelectionKeyDefaultEmployee"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.specialKeysEmployeeSelectionKeyDefaultEmployee"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "specialKeysExemptionFromPaymentKeys",
                                name: "specialKeysExemptionFromPaymentKeys"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .specialKeysExemptionFromPaymentKeys,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "specialKeysExemptionFromPaymentKeys",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.specialKeysExemptionFromPaymentKeys"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.specialKeysExemptionFromPaymentKeys"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "specialKeysPriceChange",
                                name: "specialKeysPriceChange"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .specialKeysPriceChange,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "specialKeysPriceChange",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.specialKeysPriceChange"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.specialKeysPriceChange"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "specialKeysMiscItems",
                                name: "specialKeysMiscItems"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .specialKeysMiscItems,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "specialKeysMiscItems",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.specialKeysMiscItems"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.specialKeysMiscItems"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "specialKeysNegativeMiscItems",
                                name: "specialKeysNegativeMiscItems"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.dining
                                    .specialKeysNegativeMiscItems,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.dining,
                                    "specialKeysNegativeMiscItems",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.dining.specialKeysNegativeMiscItems"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.dining.specialKeysNegativeMiscItems"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("accessType.tabs.quickService.title"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("accessType.tabs.quickService.visualConfiguration")
                    ) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("accessType.tabs.quickService.onScreenButtons")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenRetrieveStoredOrder",
                                name: "visualScreenRetrieveStoredOrder"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.quickService
                                    .visualScreenRetrieveStoredOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.quickService,
                                    "visualScreenRetrieveStoredOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.quickService.visualScreenRetrieveStoredOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.quickService.visualScreenRetrieveStoredOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenStoredOrder",
                                name: "visualScreenStoredOrder"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.quickService
                                    .visualScreenStoredOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.quickService,
                                    "visualScreenStoredOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.quickService.visualScreenStoredOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.quickService.visualScreenStoredOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "visualScreenStoredOrderPrintPrices",
                                name: "visualScreenStoredOrderPrintPrices",
                                disabled: !_vm.form.accessTypes.quickService
                                  .visualScreenStoredOrder
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.quickService
                                    .visualScreenStoredOrderPrintPrices,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.quickService,
                                    "visualScreenStoredOrderPrintPrices",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.quickService.visualScreenStoredOrderPrintPrices"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.quickService.visualScreenStoredOrderPrintPrices"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "visualScreenStoredOrderPrintBarcode",
                                name: "visualScreenStoredOrderPrintBarcode",
                                disabled: !_vm.form.accessTypes.quickService
                                  .visualScreenStoredOrder
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.quickService
                                    .visualScreenStoredOrderPrintBarcode,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.quickService,
                                    "visualScreenStoredOrderPrintBarcode",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.quickService.visualScreenStoredOrderPrintBarcode"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.quickService.visualScreenStoredOrderPrintBarcode"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "visualScreenStoredOrderExpectedDate",
                                name: "visualScreenStoredOrderExpectedDate",
                                disabled: !_vm.form.accessTypes.quickService
                                  .visualScreenStoredOrder
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.quickService
                                    .visualScreenStoredOrderExpectedDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.quickService,
                                    "visualScreenStoredOrderExpectedDate",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.quickService.visualScreenStoredOrderExpectedDate"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.quickService.visualScreenStoredOrderExpectedDate"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "visualScreenStoredOrderMemberAssociation",
                                name:
                                  "visualScreenStoredOrderMemberAssociation",
                                disabled: !_vm.form.accessTypes.quickService
                                  .visualScreenStoredOrder
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.quickService
                                    .visualScreenStoredOrderMemberAssociation,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.quickService,
                                    "visualScreenStoredOrderMemberAssociation",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.quickService.visualScreenStoredOrderMemberAssociation"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.quickService.visualScreenStoredOrderMemberAssociation"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenViewItemPricesWithoutOrdering",
                                name:
                                  "visualScreenViewItemPricesWithoutOrdering"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.quickService
                                    .visualScreenViewItemPricesWithoutOrdering,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.quickService,
                                    "visualScreenViewItemPricesWithoutOrdering",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.quickService.visualScreenViewItemPricesWithoutOrdering"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.quickService.visualScreenViewItemPricesWithoutOrdering"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "visualScreenAllowQuickServiceOrderOnHold",
                                name: "visualScreenAllowQuickServiceOrderOnHold"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.quickService
                                    .visualScreenAllowQuickServiceOrderOnHold,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.quickService,
                                    "visualScreenAllowQuickServiceOrderOnHold",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.quickService.visualScreenAllowQuickServiceOrderOnHold"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.quickService.visualScreenAllowQuickServiceOrderOnHold"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "visualScreenAllowQuickServiceOrderOnHoldDefault",
                                name:
                                  "visualScreenAllowQuickServiceOrderOnHold",
                                disabled: !_vm.form.accessTypes.quickService
                                  .visualScreenAllowQuickServiceOrderOnHold
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.quickService
                                    .visualScreenAllowQuickServiceOrderOnHoldDefault,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.quickService,
                                    "visualScreenAllowQuickServiceOrderOnHoldDefault",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.quickService.visualScreenAllowQuickServiceOrderOnHoldDefault"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.quickService.visualScreenAllowQuickServiceOrderOnHoldDefault"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("accessType.tabs.bar.title"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("accessType.tabs.bar.barInterface")) + " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accessType.tabs.bar.activeInterface")) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "activeInterfaceClearBarOrderMemory",
                                name: "activeInterfaceClearBarOrderMemory"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .activeInterfaceClearBarOrderMemory,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "activeInterfaceClearBarOrderMemory",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.activeInterfaceClearBarOrderMemory"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.activeInterfaceClearBarOrderMemory"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "activeInterfaceCoinFree",
                                name: "activeInterfaceCoinFree"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .activeInterfaceCoinFree,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "activeInterfaceCoinFree",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.activeInterfaceCoinFree"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.activeInterfaceCoinFree"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "activeInterfaceReplacementProduct",
                                name: "activeInterfaceReplacementProduct"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .activeInterfaceReplacementProduct,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "activeInterfaceReplacementProduct",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.activeInterfaceReplacementProduct"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.activeInterfaceReplacementProduct"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "activeInterfaceShowBarOrderFirst",
                                name: "activeInterfaceShowBarOrderFirst"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .activeInterfaceShowBarOrderFirst,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "activeInterfaceShowBarOrderFirst",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.activeInterfaceShowBarOrderFirst"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.activeInterfaceShowBarOrderFirst"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "activeInterfaceShowAlternativeBarSection",
                                name: "activeInterfaceShowAlternativeBarSection"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .activeInterfaceShowAlternativeBarSection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "activeInterfaceShowAlternativeBarSection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.activeInterfaceShowAlternativeBarSection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.activeInterfaceShowAlternativeBarSection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id:
                                  "activeInterfaceRemoveOrderWithoutInterface",
                                name:
                                  "activeInterfaceRemoveOrderWithoutInterface"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .activeInterfaceRemoveOrderWithoutInterface,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "activeInterfaceRemoveOrderWithoutInterface",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.activeInterfaceRemoveOrderWithoutInterface"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.activeInterfaceRemoveOrderWithoutInterface"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "activeInterfaceCombineBarOrders",
                                name: "activeInterfaceCombineBarOrders"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .activeInterfaceCombineBarOrders,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "activeInterfaceCombineBarOrders",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.activeInterfaceCombineBarOrders"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.activeInterfaceCombineBarOrders"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "activeInterfaceReactivateExpiredOrder",
                                name: "activeInterfaceReactivateExpiredOrder"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .activeInterfaceReactivateExpiredOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "activeInterfaceReactivateExpiredOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.activeInterfaceReactivateExpiredOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.activeInterfaceReactivateExpiredOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accessType.tabs.bar.passiveInterface")) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id:
                                  "passiveInterfaceActualPouredQuantityReport",
                                name:
                                  "passiveInterfaceActualPouredQuantityReport"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceActualPouredQuantityReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceActualPouredQuantityReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceActualPouredQuantityReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceActualPouredQuantityReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "passiveInterfaceActualPouredQuantityReportCloseBarShift",
                                name:
                                  "passiveInterfaceActualPouredQuantityReportCloseBarShift",
                                disabled: !_vm.form.accessTypes.bar
                                  .passiveInterfaceActualPouredQuantityReport
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceActualPouredQuantityReportCloseBarShift,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceActualPouredQuantityReportCloseBarShift",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceActualPouredQuantityReportCloseBarShift"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceActualPouredQuantityReportCloseBarShift"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "passiveInterfaceBarOperator",
                                name: "passiveInterfaceBarOperator"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceBarOperator,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceBarOperator",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceBarOperator"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceBarOperator"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "passiveInterfaceChangeBarSection",
                                name: "passiveInterfaceChangeBarSection"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceChangeBarSection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceChangeBarSection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceChangeBarSection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceChangeBarSection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "passiveInterfaceAutomaticSectionReports",
                                name: "passiveInterfaceAutomaticSectionReports"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceAutomaticSectionReports,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceAutomaticSectionReports",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceAutomaticSectionReports"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceAutomaticSectionReports"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id:
                                  "passiveInterfaceAutomaticallyOrderOutOfBalance",
                                name:
                                  "passiveInterfaceAutomaticallyOrderOutOfBalance"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceAutomaticallyOrderOutOfBalance,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceAutomaticallyOrderOutOfBalance",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceAutomaticallyOrderOutOfBalance"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceAutomaticallyOrderOutOfBalance"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "passiveInterfaceManualAdjustment",
                                name: "passiveInterfaceManualAdjustment"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceManualAdjustment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceManualAdjustment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceManualAdjustment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceManualAdjustment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "passiveInterfaceManualAdjustmentSync",
                                name: "passiveInterfaceManualAdjustmentSync",
                                disabled: !_vm.form.accessTypes.bar
                                  .passiveInterfaceManualAdjustment
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceManualAdjustmentSync,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceManualAdjustmentSync",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceManualAdjustmentSync"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceManualAdjustmentSync"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "passiveInterfaceManualAdjustmentAddBottle",
                                name:
                                  "passiveInterfaceManualAdjustmentAddBottle",
                                disabled: !_vm.form.accessTypes.bar
                                  .passiveInterfaceManualAdjustment
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceManualAdjustmentAddBottle,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceManualAdjustmentAddBottle",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceManualAdjustmentAddBottle"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceManualAdjustmentAddBottle"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "passiveInterfaceManualAdjustmentAutoSection",
                                name:
                                  "passiveInterfaceManualAdjustmentAutoSection",
                                disabled: !_vm.form.accessTypes.bar
                                  .passiveInterfaceManualAdjustment
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceManualAdjustmentAutoSection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceManualAdjustmentAutoSection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceManualAdjustmentAutoSection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceManualAdjustmentAutoSection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "passiveInterfaceManualAdjustmentCalibration",
                                name:
                                  "passiveInterfaceManualAdjustmentCalibration",
                                disabled: !_vm.form.accessTypes.bar
                                  .passiveInterfaceManualAdjustment
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceManualAdjustmentCalibration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceManualAdjustmentCalibration",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceManualAdjustmentCalibration"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceManualAdjustmentCalibration"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "passiveInterfaceManualAdjustmentPrintAdjustedValues",
                                name:
                                  "passiveInterfaceManualAdjustmentPrintAdjustedValues",
                                disabled: !_vm.form.accessTypes.bar
                                  .passiveInterfaceManualAdjustment
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .passiveInterfaceManualAdjustmentPrintAdjustedValues,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "passiveInterfaceManualAdjustmentPrintAdjustedValues",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.passiveInterfaceManualAdjustmentPrintAdjustedValues"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.passiveInterfaceManualAdjustmentPrintAdjustedValues"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("accessType.tabs.bar.common")) + " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "commonAutoOrderingControl",
                                name: "commonAutoOrderingControl"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .commonAutoOrderingControl,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "commonAutoOrderingControl",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.commonAutoOrderingControl"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.commonAutoOrderingControl"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "commonAutoOrderingControlVoidAutoOrder",
                                name: "commonAutoOrderingControlVoidAutoOrder",
                                disabled: !_vm.form.accessTypes.bar
                                  .commonAutoOrderingControl
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .commonAutoOrderingControlVoidAutoOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "commonAutoOrderingControlVoidAutoOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.commonAutoOrderingControlVoidAutoOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.commonAutoOrderingControlVoidAutoOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "commonSpoutAssignment",
                                name: "commonSpoutAssignment"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .commonSpoutAssignment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "commonSpoutAssignment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.commonSpoutAssignment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.commonSpoutAssignment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "commonProductCalibration",
                                name: "commonProductCalibration"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .commonProductCalibration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "commonProductCalibration",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.commonProductCalibration"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.commonProductCalibration"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "commonVarConsoleMemAssignment",
                                name: "commonVarConsoleMemAssignment"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.bar
                                    .commonVarConsoleMemAssignment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.bar,
                                    "commonVarConsoleMemAssignment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.bar.commonVarConsoleMemAssignment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.bar.commonVarConsoleMemAssignment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("accessType.tabs.operation.title"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accessType.tabs.operation.timeAttendance")) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accessType.tabs.operation.general")) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalEnforcePunchIn",
                                name: "generalEnforcePunchIn"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalEnforcePunchIn,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalEnforcePunchIn",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalEnforcePunchIn"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalEnforcePunchIn"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalReadingAfterPunchOut",
                                name: "generalReadingAfterPunchOut"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalReadingAfterPunchOut,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalReadingAfterPunchOut",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalReadingAfterPunchOut"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalReadingAfterPunchOut"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalTimeAttendanceManager",
                                name: "generalTimeAttendanceManager"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalTimeAttendanceManager,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalTimeAttendanceManager",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalTimeAttendanceManager"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalTimeAttendanceManager"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalCantPunchOutOpenTables",
                                name: "generalCantPunchOutOpenTables"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalCantPunchOutOpenTables,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalCantPunchOutOpenTables",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalCantPunchOutOpenTables"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalCantPunchOutOpenTables"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalPerformCashAdvances",
                                name: "generalPerformCashAdvances"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalPerformCashAdvances,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalPerformCashAdvances",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalPerformCashAdvances"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalPerformCashAdvances"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "generalPerformCashAdvancesCashingOutEmployeeSelection",
                                name:
                                  "generalPerformCashAdvancesCashingOutEmployeeSelection",
                                disabled: !_vm.form.accessTypes.operation
                                  .generalPerformCashAdvances
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalPerformCashAdvancesCashingOutEmployeeSelection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalPerformCashAdvancesCashingOutEmployeeSelection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalPerformCashAdvancesCashingOutEmployeeSelection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalPerformCashAdvancesCashingOutEmployeeSelection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalChangeEmployeeMeals",
                                name: "generalChangeEmployeeMeals"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalChangeEmployeeMeals,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalChangeEmployeeMeals",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalChangeEmployeeMeals"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalChangeEmployeeMeals"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "generalChangeEmployeeMealsPayingEmployeeSelection",
                                name:
                                  "generalChangeEmployeeMealsPayingEmployeeSelection",
                                disabled: !_vm.form.accessTypes.operation
                                  .generalChangeEmployeeMeals
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalChangeEmployeeMealsPayingEmployeeSelection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalChangeEmployeeMealsPayingEmployeeSelection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalChangeEmployeeMealsPayingEmployeeSelection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalChangeEmployeeMealsPayingEmployeeSelection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalResolveCashAdvances",
                                name: "generalResolveCashAdvances"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalResolveCashAdvances,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalResolveCashAdvances",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalResolveCashAdvances"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalResolveCashAdvances"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalReceiveEmployeeMealCharge",
                                name: "generalReceiveEmployeeMealCharge"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalReceiveEmployeeMealCharge,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalReceiveEmployeeMealCharge",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalReceiveEmployeeMealCharge"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalReceiveEmployeeMealCharge"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalForceTaskSelection",
                                name: "generalForceTaskSelection"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .generalForceTaskSelection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "generalForceTaskSelection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.generalForceTaskSelection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.generalForceTaskSelection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "accessType.tabs.operation.timeAttendanceReports"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "timeAttendanceReportNoEmployeeReport",
                                name: "timeAttendanceReportNoEmployeeReport"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .timeAttendanceReportNoEmployeeReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "timeAttendanceReportNoEmployeeReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.timeAttendanceReportNoEmployeeReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.timeAttendanceReportNoEmployeeReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "timeAttendanceReportActiveEmployeeReport",
                                name: "timeAttendanceReportActiveEmployeeReport"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .timeAttendanceReportActiveEmployeeReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "timeAttendanceReportActiveEmployeeReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.timeAttendanceReportActiveEmployeeReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.timeAttendanceReportActiveEmployeeReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id:
                                  "timeAttendanceReportScheduledEmployeeReport",
                                name:
                                  "timeAttendanceReportScheduledEmployeeReport"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .timeAttendanceReportScheduledEmployeeReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "timeAttendanceReportScheduledEmployeeReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.timeAttendanceReportScheduledEmployeeReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.timeAttendanceReportScheduledEmployeeReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "timeAttendanceReportEmployeesBreakReport",
                                name: "timeAttendanceReportEmployeesBreakReport"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .timeAttendanceReportEmployeesBreakReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "timeAttendanceReportEmployeesBreakReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.timeAttendanceReportEmployeesBreakReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.timeAttendanceReportEmployeesBreakReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "timeAttendanceReportDailyPunch",
                                name: "timeAttendanceReportDailyPunch"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .timeAttendanceReportDailyPunch,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "timeAttendanceReportDailyPunch",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.timeAttendanceReportDailyPunch"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.timeAttendanceReportDailyPunch"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "timeAttendanceReportDailySchedule",
                                name: "timeAttendanceReportDailySchedule"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .timeAttendanceReportDailySchedule,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "timeAttendanceReportDailySchedule",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.timeAttendanceReportDailySchedule"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.timeAttendanceReportDailySchedule"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "timeAttendanceReportDailyNoShow",
                                name: "timeAttendanceReportDailyNoShow"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .timeAttendanceReportDailyNoShow,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "timeAttendanceReportDailyNoShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.timeAttendanceReportDailyNoShow"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.timeAttendanceReportDailyNoShow"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accessType.tabs.operation.reports")) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsPerformEndOfDayProcedure",
                                name: "reportsPerformEndOfDayProcedure"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .reportsPerformEndOfDayProcedure,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "reportsPerformEndOfDayProcedure",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.reportsPerformEndOfDayProcedure"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.reportsPerformEndOfDayProcedure"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsEndOfDay",
                                name: "reportsEndOfDay"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .reportsEndOfDay,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "reportsEndOfDay",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.reportsEndOfDay"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.reportsEndOfDay"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsWeekly",
                                name: "reportsWeekly"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation.reportsWeekly,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "reportsWeekly",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.reportsWeekly"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.reportsWeekly"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsMonthly",
                                name: "reportsMonthly"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation.reportsMonthly,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "reportsMonthly",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.reportsMonthly"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.reportsMonthly"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsYearly",
                                name: "reportsYearly"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation.reportsYearly,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "reportsYearly",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.reportsYearly"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.reportsYearly"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsPeriodic",
                                name: "reportsPeriodic"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .reportsPeriodic,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "reportsPeriodic",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.reportsPeriodic"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.reportsPeriodic"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsTimeGap",
                                name: "reportsTimeGap"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation.reportsTimeGap,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "reportsTimeGap",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.reportsTimeGap"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.reportsTimeGap"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsDepartmentSelection",
                                name: "reportsDepartmentSelection"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .reportsDepartmentSelection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "reportsDepartmentSelection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.reportsDepartmentSelection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.reportsDepartmentSelection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsEmployeeSelection",
                                name: "reportsEmployeeSelection"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .reportsEmployeeSelection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "reportsEmployeeSelection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.reportsEmployeeSelection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.reportsEmployeeSelection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accessType.tabs.operation.readings")) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("accessType.tabs.operation.salesReading")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "salesReadingCloseShift",
                                name: "salesReadingCloseShift"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .salesReadingCloseShift,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "salesReadingCloseShift",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.salesReadingCloseShift"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.salesReadingCloseShift"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "salesReadingCloseShiftPromptAmountBeforeClose",
                                name:
                                  "salesReadingCloseShiftPromptAmountBeforeClose",
                                disabled: !_vm.form.accessTypes.operation
                                  .salesReadingCloseShift
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .salesReadingCloseShiftPromptAmountBeforeClose,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "salesReadingCloseShiftPromptAmountBeforeClose",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeClose"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.salesReadingCloseShiftPromptAmountBeforeClose"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-4" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id:
                                      "salesReadingCloseShiftPromptAmountBeforeCloseOverShort",
                                    name:
                                      "salesReadingCloseShiftPromptAmountBeforeCloseOverShort",
                                    disabled:
                                      !_vm.form.accessTypes.operation
                                        .salesReadingCloseShift ||
                                      !_vm.form.accessTypes.operation
                                        .salesReadingCloseShiftPromptAmountBeforeClose
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.operation
                                        .salesReadingCloseShiftPromptAmountBeforeCloseOverShort,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.operation,
                                        "salesReadingCloseShiftPromptAmountBeforeCloseOverShort",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeCloseOverShort"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.operation.salesReadingCloseShiftPromptAmountBeforeCloseOverShort"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id:
                                      "salesReadingCloseShiftPromptAmountBeforeCloseDepositForm",
                                    name:
                                      "salesReadingCloseShiftPromptAmountBeforeCloseDepositForm",
                                    disabled:
                                      !_vm.form.accessTypes.operation
                                        .salesReadingCloseShift ||
                                      !_vm.form.accessTypes.operation
                                        .salesReadingCloseShiftPromptAmountBeforeClose
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.operation
                                        .salesReadingCloseShiftPromptAmountBeforeCloseDepositForm,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.operation,
                                        "salesReadingCloseShiftPromptAmountBeforeCloseDepositForm",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeCloseDepositForm"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.operation.salesReadingCloseShiftPromptAmountBeforeCloseDepositForm"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "salesReadingCloseShiftOnScreenOnly",
                                name: "salesReadingCloseShiftOnScreenOnly",
                                disabled: !_vm.form.accessTypes.operation
                                  .salesReadingCloseShift
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .salesReadingCloseShiftOnScreenOnly,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "salesReadingCloseShiftOnScreenOnly",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.salesReadingCloseShiftOnScreenOnly"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.salesReadingCloseShiftOnScreenOnly"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "reportsMonthly",
                                name: "reportsMonthly"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .salesReadingSalesConfirmation,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "salesReadingSalesConfirmation",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.salesReadingSalesConfirmation"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.salesReadingSalesConfirmation"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "salesReadingSalesConfirmationNoOpenTables",
                                name:
                                  "salesReadingSalesConfirmationNoOpenTables",
                                disabled: !_vm.form.accessTypes.operation
                                  .salesReadingSalesConfirmation
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .salesReadingSalesConfirmationNoOpenTables,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "salesReadingSalesConfirmationNoOpenTables",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.salesReadingSalesConfirmationNoOpenTables"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.salesReadingSalesConfirmationNoOpenTables"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "salesReadingSalesConfirmationOnScreenOnly",
                                name:
                                  "salesReadingSalesConfirmationOnScreenOnly",
                                disabled: !_vm.form.accessTypes.operation
                                  .salesReadingSalesConfirmation
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .salesReadingSalesConfirmationOnScreenOnly,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "salesReadingSalesConfirmationOnScreenOnly",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.salesReadingSalesConfirmationOnScreenOnly"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.salesReadingSalesConfirmationOnScreenOnly"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "salesReadingOnScreenReadings",
                                name: "salesReadingOnScreenReadings"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .salesReadingOnScreenReadings,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "salesReadingOnScreenReadings",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.salesReadingOnScreenReadings"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.salesReadingOnScreenReadings"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "salesReadingCumulativeReadings",
                                name: "salesReadingCumulativeReadings"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .salesReadingCumulativeReadings,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "salesReadingCumulativeReadings",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.salesReadingCumulativeReadings"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.salesReadingCumulativeReadings"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "salesReadingSalesEmployee",
                                name: "salesReadingSalesEmployee"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .salesReadingSalesEmployee,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "salesReadingSalesEmployee",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.salesReadingSalesEmployee"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.salesReadingSalesEmployee"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("accessType.tabs.operation.globalReading")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "globalReading",
                                name: "globalReading"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation.globalReading,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "globalReading",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.globalReading"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.globalReading"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "globalReadingByPatch",
                                name: "globalReadingByPatch"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .globalReadingByPatch,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "globalReadingByPatch",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.globalReadingByPatch"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.globalReadingByPatch"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "globalReadingByPatchCloseBatch",
                                name: "globalReadingByPatchCloseBatch",
                                disabled: !_vm.form.accessTypes.operation
                                  .globalReadingByPatch
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .globalReadingByPatchCloseBatch,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "globalReadingByPatchCloseBatch",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.globalReadingByPatchCloseBatch"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.globalReadingByPatchCloseBatch"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "globalReadingByPatchByDepartment",
                                name: "globalReadingByPatchByDepartment",
                                disabled: !_vm.form.accessTypes.operation
                                  .globalReadingByPatch
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .globalReadingByPatchByDepartment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "globalReadingByPatchByDepartment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.globalReadingByPatchByDepartment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.globalReadingByPatchByDepartment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("accessType.tabs.operation.readingFormat")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "generalEnforcePunchIn",
                                name: "generalEnforcePunchIn"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatCountersReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatCountersReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatCountersReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatCountersReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatSalesByCategory",
                                name: "readingFormatSalesByCategory"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatSalesByCategory,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatSalesByCategory",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatSalesByCategory"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatSalesByCategory"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatSalesBySubCategory",
                                name: "readingFormatSalesBySubCategory"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatSalesBySubCategory,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatSalesBySubCategory",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatSalesBySubCategory"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatSalesBySubCategory"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatCheckListing",
                                name: "readingFormatCheckListing"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatCheckListing,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatCheckListing",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatCheckListing"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatCheckListing"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatTransactionsPaymentType",
                                name: "readingFormatTransactionsPaymentType"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatTransactionsPaymentType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatTransactionsPaymentType",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatTransactionsPaymentType"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatTransactionsPaymentType"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatDrawerDepositReport",
                                name: "readingFormatDrawerDepositReport"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatDrawerDepositReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatDrawerDepositReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatDrawerDepositReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatDrawerDepositReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatSalesByMemberCategories",
                                name: "readingFormatSalesByMemberCategories"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatSalesByMemberCategories,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatSalesByMemberCategories",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatSalesByMemberCategories"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatSalesByMemberCategories"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatCumulativeTotalShiftReadings",
                                name:
                                  "readingFormatCumulativeTotalShiftReadings"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatCumulativeTotalShiftReadings,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatCumulativeTotalShiftReadings",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatCumulativeTotalShiftReadings"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatCumulativeTotalShiftReadings"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatTimeFirstLastOrder",
                                name: "readingFormatTimeFirstLastOrder"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatTimeFirstLastOrder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatTimeFirstLastOrder",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatTimeFirstLastOrder"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatTimeFirstLastOrder"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatNumberCashDrawerNoSales",
                                name: "readingFormatNumberCashDrawerNoSales"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatNumberCashDrawerNoSales,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatNumberCashDrawerNoSales",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatNumberCashDrawerNoSales"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatNumberCashDrawerNoSales"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatVoidTotalQty",
                                name: "readingFormatVoidTotalQty"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatVoidTotalQty,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatVoidTotalQty",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatVoidTotalQty"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatVoidTotalQty"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "readingFormatVoidTotalQtyItemVentilated",
                                name: "readingFormatVoidTotalQtyItemVentilated",
                                disabled: !_vm.form.accessTypes.operation
                                  .readingFormatVoidTotalQty
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatVoidTotalQtyItemVentilated,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatVoidTotalQtyItemVentilated",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatVoidTotalQtyItemVentilated"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatVoidTotalQtyItemVentilated"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatNumberPromoItems",
                                name: "readingFormatNumberPromoItems"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatNumberPromoItems,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatNumberPromoItems",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatNumberPromoItems"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatNumberPromoItems"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatMealCount",
                                name: "readingFormatMealCount"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatMealCount,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatMealCount",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatMealCount"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatMealCount"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatCustomerCount",
                                name: "readingFormatCustomerCount"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatCustomerCount,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatCustomerCount",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatCustomerCount"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatCustomerCount"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatAverageMeal",
                                name: "readingFormatAverageMeal"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatAverageMeal,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatAverageMeal",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatAverageMeal"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatAverageMeal"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatAverageCustomer",
                                name: "readingFormatAverageCustomer"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatAverageCustomer,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatAverageCustomer",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatAverageCustomer"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatAverageCustomer"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatValuePerMeal",
                                name: "readingFormatValuePerMeal"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatValuePerMeal,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatValuePerMeal",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatValuePerMeal"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatValuePerMeal"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatEmployeeTips",
                                name: "readingFormatEmployeeTips"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatEmployeeTips,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatEmployeeTips",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatEmployeeTips"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatEmployeeTips"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatIncludedTipFromOverPayment",
                                name: "readingFormatIncludedTipFromOverPayment"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatIncludedTipFromOverPayment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatIncludedTipFromOverPayment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatIncludedTipFromOverPayment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatIncludedTipFromOverPayment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatTaxes",
                                name: "readingFormatTaxes"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatTaxes,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatTaxes",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatTaxes"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatTaxes"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatCancelledTransactions",
                                name: "readingFormatCancelledTransactions"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatCancelledTransactions,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatCancelledTransactions",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatCancelledTransactions"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatCancelledTransactions"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "readingFormatCancelledTransactionsVentilated",
                                name:
                                  "readingFormatCancelledTransactionsVentilated",
                                disabled: !_vm.form.accessTypes.operation
                                  .readingFormatCancelledTransactions
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatCancelledTransactionsVentilated,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatCancelledTransactionsVentilated",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatCancelledTransactionsVentilated"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatCancelledTransactionsVentilated"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatReportsPriceLevel",
                                name: "readingFormatReportsPriceLevel"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatReportsPriceLevel,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatReportsPriceLevel",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatReportsPriceLevel"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatReportsPriceLevel"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatNumberDuplicate",
                                name: "readingFormatNumberDuplicate"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatNumberDuplicate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatNumberDuplicate",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatNumberDuplicate"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatNumberDuplicate"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatReportsByDepartment",
                                name: "readingFormatReportsByDepartment"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatReportsByDepartment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatReportsByDepartment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatReportsByDepartment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatReportsByDepartment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatTipDeclarationDataResume",
                                name: "readingFormatTipDeclarationDataResume"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatTipDeclarationDataResume,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatTipDeclarationDataResume",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatTipDeclarationDataResume"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatTipDeclarationDataResume"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatTimezoneReport",
                                name: "readingFormatTimezoneReport"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatTimezoneReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatTimezoneReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatTimezoneReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatTimezoneReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatNumberItemsSoldReturns",
                                name: "readingFormatNumberItemsSoldReturns"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatNumberItemsSoldReturns,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatNumberItemsSoldReturns",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatNumberItemsSoldReturns"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatNumberItemsSoldReturns"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id:
                                  "readingFormatDiscountItemIncludedDiscountedItem",
                                name:
                                  "readingFormatDiscountItemIncludedDiscountedItem"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatDiscountItemIncludedDiscountedItem,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatDiscountItemIncludedDiscountedItem",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatDiscountItemIncludedDiscountedItem"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatDiscountItemIncludedDiscountedItem"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatTotalAndSubtotal",
                                name: "readingFormatTotalAndSubtotal"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatTotalAndSubtotal,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatTotalAndSubtotal",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatTotalAndSubtotal"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatTotalAndSubtotal"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatDiscounts",
                                name: "readingFormatDiscounts"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatDiscounts,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatDiscounts",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatDiscounts"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatDiscounts"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "readingFormatAllItemsVentilated",
                                name: "readingFormatAllItemsVentilated"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .readingFormatAllItemsVentilated,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "readingFormatAllItemsVentilated",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.readingFormatAllItemsVentilated"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.readingFormatAllItemsVentilated"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accessType.tabs.operation.operator")) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operatorWorkstationsDepartment",
                                name: "operatorWorkstationsDepartment"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .operatorWorkstationsDepartment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "operatorWorkstationsDepartment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.operatorWorkstationsDepartment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.operatorWorkstationsDepartment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operatorEmployeesDepartment",
                                name: "operatorEmployeesDepartment"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .operatorEmployeesDepartment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "operatorEmployeesDepartment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.operatorEmployeesDepartment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.operatorEmployeesDepartment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operatorEmployeesRedirectionTable",
                                name: "operatorEmployeesRedirectionTable"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .operatorEmployeesRedirectionTable,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "operatorEmployeesRedirectionTable",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.operatorEmployeesRedirectionTable"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.globalReadingByPatchCloseBatch"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operatorEmployeesRequisitionLocation",
                                name: "operatorEmployeesRequisitionLocation"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .operatorEmployeesRequisitionLocation,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "operatorEmployeesRequisitionLocation",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.operatorEmployeesRequisitionLocation"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.operatorEmployeesRequisitionLocation"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operatorStopReassignAssignedEmployee",
                                name: "operatorStopReassignAssignedEmployee"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .operatorStopReassignAssignedEmployee,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "operatorStopReassignAssignedEmployee",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.operatorStopReassignAssignedEmployee"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.operatorStopReassignAssignedEmployee"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "operatorDesignationRequired",
                                name: "operatorDesignationRequired"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .operatorDesignationRequired,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "operatorDesignationRequired",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.operatorDesignationRequired"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.operatorDesignationRequired"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accessType.tabs.operation.delivery")) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "deliveryCost",
                                name: "deliveryCost"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation.deliveryCost,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "deliveryCost",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.deliveryCost"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.deliveryCost"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "deliveryManager",
                                name: "deliveryManager"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .deliveryManager,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "deliveryManager",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.deliveryManager"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.deliveryManager"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "deliveryTagFunction",
                                name: "deliveryTagFunction"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .deliveryTagFunction,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "deliveryTagFunction",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.deliveryTagFunction"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.deliveryTagFunction"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id:
                                  "deliveryAllowUpdateCustomersAvailableCredit",
                                name:
                                  "deliveryAllowUpdateCustomersAvailableCredit"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .deliveryAllowUpdateCustomersAvailableCredit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "deliveryAllowUpdateCustomersAvailableCredit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.deliveryAllowUpdateCustomersAvailableCredit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.deliveryAllowUpdateCustomersAvailableCredit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "deliveryCreditBatch1",
                                name: "deliveryCreditBatch1"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .deliveryCreditBatch1,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "deliveryCreditBatch1",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.deliveryCreditBatch1"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.deliveryCreditBatch1"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "deliverySingleDispatch",
                                name: "deliverySingleDispatch"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .deliverySingleDispatch,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "deliverySingleDispatch",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.deliverySingleDispatch"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.deliverySingleDispatch"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("accessType.tabs.operation.inventoryManagement")
                    ) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("accessType.tabs.operation.inventoryGeneral")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryBalance",
                                name: "inventoryBalance"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryBalance,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryBalance",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryBalance"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryBalance"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "inventoryBalanceIntegerQty",
                                name: "inventoryBalanceIntegerQty",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryBalance
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryBalanceIntegerQty,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryBalanceIntegerQty",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryBalanceIntegerQty"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryBalanceIntegerQty"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryReceipt",
                                name: "inventoryReceipt"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryReceipt,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryReceipt",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryReceipt"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryReceipt"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryInterLocationTransfer",
                                name: "inventoryInterLocationTransfer"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryInterLocationTransfer,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryInterLocationTransfer",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryInterLocationTransfer"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryInterLocationTransfer"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryProductFiles",
                                name: "inventoryProductFiles"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryProductFiles,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryProductFiles",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryProductFiles"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryProductFiles"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryAccessMultipleLocations",
                                name: "inventoryAccessMultipleLocations"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryAccessMultipleLocations,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryAccessMultipleLocations",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryAccessMultipleLocations"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryAccessMultipleLocations"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryPurchaseManagement",
                                name: "inventoryPurchaseManagement"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryPurchaseManagement,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryPurchaseManagement",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryPurchaseManagement"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryPurchaseManagement"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryShiftBalanceReport",
                                name: "inventoryShiftBalanceReport"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryShiftBalanceReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryShiftBalanceReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryShiftBalanceReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryShiftBalanceReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "inventoryShiftBalanceReportBackSelection",
                                name:
                                  "inventoryShiftBalanceReportBackSelection",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryShiftBalanceReport
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryShiftBalanceReportBackSelection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryShiftBalanceReportBackSelection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryShiftBalanceReportBackSelection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryShiftBalanceReportBackSelection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryShiftClosure",
                                name: "inventoryShiftClosure"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryShiftClosure,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryShiftClosure",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryShiftClosure"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryShiftClosure"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryUnitChoice",
                                name: "inventoryUnitChoice"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryUnitChoice,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryUnitChoice",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryUnitChoice"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryUnitChoice"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryReturnItemInventoryButton",
                                name: "inventoryReturnItemInventoryButton"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryReturnItemInventoryButton,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryReturnItemInventoryButton",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryReturnItemInventoryButton"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryReturnItemInventoryButton"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "inventoryReturnItemInventoryButtonReceiptProductOnly",
                                name:
                                  "inventoryReturnItemInventoryButtonReceiptProductOnly",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryReturnItemInventoryButton
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryReturnItemInventoryButtonReceiptProductOnly,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryReturnItemInventoryButtonReceiptProductOnly",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryReturnItemInventoryButtonReceiptProductOnly"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryReturnItemInventoryButtonReceiptProductOnly"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "inventoryReturnItemInventoryButtonUseOnlyVersion",
                                name:
                                  "inventoryReturnItemInventoryButtonUseOnlyVersion",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryReturnItemInventoryButton
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryReturnItemInventoryButtonUseOnlyVersion,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryReturnItemInventoryButtonUseOnlyVersion",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryReturnItemInventoryButtonUseOnlyVersion"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryReturnItemInventoryButtonUseOnlyVersion"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id:
                                  "inventoryPersistentCategoryInventoryManagement",
                                name:
                                  "inventoryPersistentCategoryInventoryManagement"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryPersistentCategoryInventoryManagement,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryPersistentCategoryInventoryManagement",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryPersistentCategoryInventoryManagement"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryPersistentCategoryInventoryManagement"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id:
                                  "inventoryNegativeQtyButtonInventoryManagement",
                                name:
                                  "inventoryNegativeQtyButtonInventoryManagement"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryNegativeQtyButtonInventoryManagement,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryNegativeQtyButtonInventoryManagement",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryNegativeQtyButtonInventoryManagement"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryNegativeQtyButtonInventoryManagement"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "accessType.tabs.operation.inventoryRequisitionTitle"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "inventoryRequisition",
                                name: "inventoryRequisition"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryRequisition,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryRequisition",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryRequisition"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryRequisition"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "inventoryRequisitionProductTransfer",
                                name: "inventoryRequisitionProductTransfer",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryRequisition
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryRequisitionProductTransfer,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryRequisitionProductTransfer",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryRequisitionProductTransfer"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryRequisitionProductTransfer"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-4" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id:
                                      "inventoryRequisitionProductTransferLimitFunctionsLocation",
                                    name:
                                      "inventoryRequisitionProductTransferLimitFunctionsLocation",
                                    disabled:
                                      !_vm.form.accessTypes.operation
                                        .inventoryRequisition ||
                                      !_vm.form.accessTypes.operation
                                        .inventoryRequisitionProductTransfer
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.operation
                                        .inventoryRequisitionProductTransferLimitFunctionsLocation,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.operation,
                                        "inventoryRequisitionProductTransferLimitFunctionsLocation",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.operation.inventoryRequisitionProductTransferLimitFunctionsLocation"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.operation.inventoryRequisitionProductTransferLimitFunctionsLocation"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "inventoryRequisitionChangeLocationNotAllowed",
                                name:
                                  "inventoryRequisitionChangeLocationNotAllowed",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryRequisition
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryRequisitionChangeLocationNotAllowed,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryRequisitionChangeLocationNotAllowed",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryRequisitionChangeLocationNotAllowed"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryRequisitionChangeLocationNotAllowed"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "inventoryRequisitionWholeNumberQuantitiesOnly",
                                name:
                                  "inventoryRequisitionWholeNumberQuantitiesOnly",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryRequisition
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryRequisitionWholeNumberQuantitiesOnly,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryRequisitionWholeNumberQuantitiesOnly",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryRequisitionWholeNumberQuantitiesOnly"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryRequisitionWholeNumberQuantitiesOnly"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "inventoryRequisitionRequiredForDate",
                                name: "inventoryRequisitionRequiredForDate",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryRequisition
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryRequisitionRequiredForDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryRequisitionRequiredForDate",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryRequisitionRequiredForDate"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryRequisitionRequiredForDate"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "inventoryRequisitionNoStockCheck",
                                name: "inventoryRequisitionNoStockCheck",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryRequisition
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryRequisitionNoStockCheck,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryRequisitionNoStockCheck",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryRequisitionNoStockCheck"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryRequisitionNoStockCheck"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "inventoryRequisitionByEmployeeSelection",
                                name: "inventoryRequisitionByEmployeeSelection",
                                disabled: !_vm.form.accessTypes.operation
                                  .inventoryRequisition
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.operation
                                    .inventoryRequisitionByEmployeeSelection,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.operation,
                                    "inventoryRequisitionByEmployeeSelection",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.operation.inventoryRequisitionByEmployeeSelection"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.operation.inventoryRequisitionByEmployeeSelection"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2 ml-1" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12", md: "8" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        label: _vm.$t(
                                          "accessType.tabs.operation.inventoryRequisitionIndexId.label"
                                        )
                                      }
                                    },
                                    [
                                      _c("b-form-select", {
                                        staticClass: "form-text",
                                        attrs: {
                                          options: _vm.requisitionIndexes.concat(
                                            [
                                              {
                                                id: null,
                                                name: _vm.$t(
                                                  "employeeCreate.emptyValue"
                                                )
                                              }
                                            ]
                                          ),
                                          id: "test",
                                          "value-field": "id",
                                          "text-field": "name",
                                          "aria-describedby":
                                            "input-live-help input-live-feedback"
                                        },
                                        model: {
                                          value:
                                            _vm.form.accessTypes.operation
                                              .inventoryRequisitionIndexId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.accessTypes.operation,
                                              "inventoryRequisitionIndexId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.accessTypes.operation.inventoryRequisitionIndexId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } }),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("accessType.tabs.misc.title"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("accessType.tabs.misc.electronicFoundTransfer")
                    ) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "voidTransaction",
                                name: "voidTransaction"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.voidTransaction,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "voidTransaction",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.voidTransaction"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.voidTransaction"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "transactionReport",
                                name: "transactionReport"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.transactionReport,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transactionReport",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transactionReport"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transactionReport"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { id: "batchClose", name: "batchClose" },
                              model: {
                                value: _vm.form.accessTypes.misc.batchClose,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "batchClose",
                                    $$v
                                  )
                                },
                                expression: "form.accessTypes.misc.batchClose"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("accessType.tabs.misc.batchClose")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "clearPendingTransactions",
                                name: "clearPendingTransactions"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .clearPendingTransactions,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "clearPendingTransactions",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.clearPendingTransactions"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.clearPendingTransactions"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "accountBalance",
                                name: "accountBalance"
                              },
                              model: {
                                value: _vm.form.accessTypes.misc.accountBalance,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "accountBalance",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.accountBalance"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.accountBalance"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "mobileTerminalAccess",
                                name: "mobileTerminalAccess"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .mobileTerminalAccess,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "mobileTerminalAccess",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.mobileTerminalAccess"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.mobileTerminalAccess"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "accessEftFunctions",
                                name: "accessEftFunctions"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.accessEftFunctions,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "accessEftFunctions",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.accessEftFunctions"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.accessEftFunctions"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(_vm._s(_vm.$t("accessType.tabs.misc.smartCard")))
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "smartCardSale",
                                name: "smartCardSale"
                              },
                              model: {
                                value: _vm.form.accessTypes.misc.smartCardSale,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "smartCardSale",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.smartCardSale"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("accessType.tabs.misc.smartCardSale")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "smartCardVoidTransaction",
                                name: "smartCardVoidTransaction"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .smartCardVoidTransaction,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "smartCardVoidTransaction",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.smartCardVoidTransaction"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.smartCardVoidTransaction"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "smartCardActivation",
                                name: "smartCardActivation"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.smartCardActivation,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "smartCardActivation",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.smartCardActivation"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.smartCardActivation"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "smartCartBalanceInquiry",
                                name: "smartCartBalanceInquiry"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .smartCartBalanceInquiry,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "smartCartBalanceInquiry",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.smartCartBalanceInquiry"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.smartCartBalanceInquiry"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "smartCartClearBalance",
                                name: "smartCartClearBalance"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .smartCartClearBalance,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "smartCartClearBalance",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.smartCartClearBalance"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.smartCartClearBalance"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      _vm._s(_vm.$t("accessType.tabs.misc.smartCardFidelity"))
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "smartCardFidelityActivate",
                                name: "smartCardFidelityActivate"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .smartCardFidelityActivate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "smartCardFidelityActivate",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.smartCardFidelityActivate"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.smartCardFidelityActivate"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "smartCardFidelityBalanceInquiry",
                                name: "smartCardFidelityBalanceInquiry"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .smartCardFidelityBalanceInquiry,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "smartCardFidelityBalanceInquiry",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.smartCardFidelityBalanceInquiry"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.smartCardFidelityBalanceInquiry"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "smartCardFidelityVoidTransaction",
                                name: "smartCardFidelityVoidTransaction"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .smartCardFidelityVoidTransaction,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "smartCardFidelityVoidTransaction",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.smartCardFidelityVoidTransaction"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.smartCardFidelityVoidTransaction"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accessType.tabs.misc.systemFunction")) +
                    " "
                )
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("accessType.tabs.misc.general")) + " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemTableState",
                                name: "systemTableState"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.systemTableState,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemTableState",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemTableState"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemTableState"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemMenuConfig",
                                name: "systemMenuConfig"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.systemMenuConfig,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemMenuConfig",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemMenuConfig"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemMenuConfig"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemGroupKeyConfig",
                                name: "systemGroupKeyConfig"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemGroupKeyConfig,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemGroupKeyConfig",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemGroupKeyConfig"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemGroupKeyConfig"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemEmployeeRecord",
                                name: "systemEmployeeRecord"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemEmployeeRecord,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemEmployeeRecord",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemEmployeeRecord"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemEmployeeRecord"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemFunctions",
                                name: "systemFunctions"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.systemFunctions,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemFunctions",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemFunctions"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemFunctions"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "systemFunctionsConfiguration",
                                name: "systemFunctionsConfiguration",
                                disabled: !_vm.form.accessTypes.misc
                                  .systemFunctions
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemFunctionsConfiguration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemFunctionsConfiguration",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemFunctionsConfiguration"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemFunctionsConfiguration"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemEditItemCountdown",
                                name: "systemEditItemCountdown"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemEditItemCountdown,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemEditItemCountdown",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemEditItemCountdown"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemEditItemCountdown"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemAdmissionTicketGenerator",
                                name: "systemAdmissionTicketGenerator"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemAdmissionTicketGenerator,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemAdmissionTicketGenerator",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemAdmissionTicketGenerator"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemAdmissionTicketGenerator"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "systemAdmissionTicketGeneratorCheckCreation",
                                name:
                                  "systemAdmissionTicketGeneratorCheckCreation",
                                disabled: !_vm.form.accessTypes.misc
                                  .systemAdmissionTicketGenerator
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemAdmissionTicketGeneratorCheckCreation,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemAdmissionTicketGeneratorCheckCreation",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemAdmissionTicketGeneratorCheckCreation"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemAdmissionTicketGeneratorCheckCreation"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-4" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    id:
                                      "systemAdmissionTicketGeneratorCheckCreationLayawayFunc",
                                    name:
                                      "systemAdmissionTicketGeneratorCheckCreationLayawayFunc",
                                    disabled: !_vm.form.accessTypes.misc
                                      .systemAdmissionTicketGeneratorCheckCreation
                                  },
                                  model: {
                                    value:
                                      _vm.form.accessTypes.misc
                                        .systemAdmissionTicketGeneratorCheckCreationLayawayFunc,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.accessTypes.misc,
                                        "systemAdmissionTicketGeneratorCheckCreationLayawayFunc",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.accessTypes.misc.systemAdmissionTicketGeneratorCheckCreationLayawayFunc"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accessType.tabs.misc.systemAdmissionTicketGeneratorCheckCreationLayawayFunc"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemDailyTemperatureAdjustment",
                                name: "systemDailyTemperatureAdjustment"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemDailyTemperatureAdjustment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemDailyTemperatureAdjustment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemDailyTemperatureAdjustment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemDailyTemperatureAdjustment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemAddClosedTablesOrderBatchList",
                                name: "systemAddClosedTablesOrderBatchList"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemAddClosedTablesOrderBatchList,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemAddClosedTablesOrderBatchList",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemAddClosedTablesOrderBatchList"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemAddClosedTablesOrderBatchList"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "systemEnableDisableForcedPriceBatches",
                                name: "systemEnableDisableForcedPriceBatches",
                                disabled: !_vm.form.accessTypes.misc
                                  .systemAddClosedTablesOrderBatchList
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemEnableDisableForcedPriceBatches,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemEnableDisableForcedPriceBatches",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemEnableDisableForcedPriceBatches"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemFunctionsConfiguration"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemPrintPromoCardBalance",
                                name: "systemPrintPromoCardBalance"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemPrintPromoCardBalance,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemPrintPromoCardBalance",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemPrintPromoCardBalance"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemPrintPromoCardBalance"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemSearchChecks",
                                name: "systemSearchChecks"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.systemSearchChecks,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemSearchChecks",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemSearchChecks"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemSearchChecks"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemReviewEditClosedChecks",
                                name: "systemReviewEditClosedChecks"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemReviewEditClosedChecks,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemReviewEditClosedChecks",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemReviewEditClosedChecks"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemReviewEditClosedChecks"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemModifyRedirectionTable",
                                name: "systemModifyRedirectionTable"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemModifyRedirectionTable,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemModifyRedirectionTable",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemModifyRedirectionTable"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemModifyRedirectionTable"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "systemModifyPagerNumber",
                                name: "systemModifyPagerNumber"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .systemModifyPagerNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "systemModifyPagerNumber",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.systemModifyPagerNumber"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.systemModifyPagerNumber"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accessType.tabs.misc.transfers")) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "transfersPayment",
                                name: "transfersPayment"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.transfersPayment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersPayment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersPayment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersPayment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "transfersPaidOut",
                                name: "transfersPaidOut"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.transfersPaidOut,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersPaidOut",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersPaidOut"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersPaidOut"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "transfersPaidOutEmployee",
                                name: "transfersPaidOutEmployee",
                                disabled: !_vm.form.accessTypes.misc
                                  .transfersPaidOut
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .transfersPaidOutEmployee,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersPaidOutEmployee",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersPaidOutEmployee"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersPaidOutEmployee"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "transfersEmployeeDeposit",
                                name: "transfersEmployeeDeposit"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .transfersEmployeeDeposit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersEmployeeDeposit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersEmployeeDeposit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersEmployeeDeposit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id:
                                  "transfersEmployeeDepositCounterbalanceExecOnly",
                                name:
                                  "transfersEmployeeDepositCounterbalanceExecOnly",
                                disabled: !_vm.form.accessTypes.misc
                                  .transfersEmployeeDeposit
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .transfersEmployeeDepositCounterbalanceExecOnly,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersEmployeeDepositCounterbalanceExecOnly",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersEmployeeDepositCounterbalanceExecOnly"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersEmployeeDepositCounterbalanceExecOnly"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "transfersWarnAmountBeforeDeposit",
                                name: "transfersWarnAmountBeforeDeposit"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .transfersWarnAmountBeforeDeposit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersWarnAmountBeforeDeposit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersWarnAmountBeforeDeposit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersWarnAmountBeforeDeposit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-input-group",
                            {
                              staticClass: "ml-4",
                              staticStyle: { "max-width": "160px" },
                              attrs: { prepend: "$" }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "name",
                                  prefix: "$",
                                  "no-wheel": "",
                                  "lazy-formatter": "",
                                  formatter: _vm.formatAmount,
                                  step: "0.01",
                                  type: "number",
                                  placeholder: _vm.$t(
                                    "accessType.tabs.misc.transfersWarnAmountBeforeDepositAmount.placeholder"
                                  ),
                                  "aria-describedby": "passwd-feedback"
                                },
                                model: {
                                  value:
                                    _vm.form.accessTypes.misc
                                      .transfersWarnAmountBeforeDepositAmount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.accessTypes.misc,
                                      "transfersWarnAmountBeforeDepositAmount",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "form.accessTypes.misc.transfersWarnAmountBeforeDepositAmount"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "transfersWarnAmountBeforeDepositAmount",
                                name: "transfersWarnAmountBeforeDepositAmount"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .transfersMaxAmountBeforeForcedDeposit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersMaxAmountBeforeForcedDeposit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersMaxAmountBeforeForcedDeposit"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersMaxAmountBeforeForcedDeposit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-input-group",
                            {
                              staticClass: "ml-4",
                              staticStyle: { "max-width": "160px" },
                              attrs: { prepend: "$" }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "name",
                                  prefix: "$",
                                  "no-wheel": "",
                                  "lazy-formatter": "",
                                  formatter: _vm.formatAmount,
                                  step: "0.01",
                                  type: "number",
                                  placeholder: _vm.$t(
                                    "accessType.tabs.misc.transfersMaxAmountBeforeForcedDepositAmount.placeholder"
                                  ),
                                  "aria-describedby": "passwd-feedback"
                                },
                                model: {
                                  value:
                                    _vm.form.accessTypes.misc
                                      .transfersMaxAmountBeforeForcedDepositAmount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.accessTypes.misc,
                                      "transfersMaxAmountBeforeForcedDepositAmount",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "form.accessTypes.misc.transfersMaxAmountBeforeForcedDepositAmount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "transfersPaidIn",
                                name: "transfersPaidIn"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc.transfersPaidIn,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersPaidIn",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersPaidIn"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersPaidIn"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "transfersPaidInEmployee",
                                name: "transfersPaidInEmployee",
                                disabled: !_vm.form.accessTypes.misc
                                  .transfersPaidIn
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .transfersPaidInEmployee,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersPaidInEmployee",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersPaidInEmployee"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersPaidInEmployee"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "transfersFinalDeposits",
                                name: "transfersFinalDeposits"
                              },
                              on: { change: _vm.check },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .transfersFinalDeposits,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersFinalDeposits",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersFinalDeposits"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersFinalDeposits"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                id: "transfersFinalDepositsDepartment",
                                name: "transfersFinalDepositsDepartment",
                                disabled: !_vm.form.accessTypes.misc
                                  .transfersFinalDeposits
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .transfersFinalDepositsDepartment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "transfersFinalDepositsDepartment",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.transfersFinalDepositsDepartment"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.transfersFinalDepositsDepartment"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "4" } })
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accessType.tabs.misc.recordingModule")) +
                        " "
                    )
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "recordingModulesGroupEvent",
                                name: "recordingModulesGroupEvent"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .recordingModulesGroupEvent,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "recordingModulesGroupEvent",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.recordingModulesGroupEvent"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.recordingModulesGroupEvent"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "recordingModulesPeriodicSalesSummary",
                                name: "recordingModulesPeriodicSalesSummary"
                              },
                              model: {
                                value:
                                  _vm.form.accessTypes.misc
                                    .recordingModulesPeriodicSalesSummary,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.accessTypes.misc,
                                    "recordingModulesPeriodicSalesSummary",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.accessTypes.misc.recordingModulesPeriodicSalesSummary"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accessType.tabs.misc.recordingModulesPeriodicSalesSummary"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }