var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portal",
    { attrs: { to: "app" } },
    [
      _c("vel-modal", {
        staticClass: "location-delete__modal",
        attrs: { dismissable: "" },
        on: { dismiss: _vm.handleCancel },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("group.locations.modals.delete.title")) +
                    " "
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm.deleteGroupLocationError
                  ? _c(
                      "vel-alert",
                      {
                        staticClass: "alert delete-location__error",
                        attrs: { level: "error" },
                        on: { dismiss: _vm.clearDeleteGroupLocationError }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("group.errors.deleteLocation")) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                _c("span", {
                  staticClass: "location-delete__confirmation",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("group.locations.modals.delete.textHtml", {
                        name: _vm.location.label
                      })
                    )
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "vel-button",
                  {
                    staticClass: "location-delete__close-button",
                    attrs: { type: "secondary" },
                    on: { click: _vm.handleCancel }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.close")) + " ")]
                ),
                _c(
                  "vel-button",
                  {
                    staticClass: "location-delete__confirm-button",
                    attrs: { type: "critical", loading: _vm.isSavingLocations },
                    on: { click: _vm.handleDelete }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("group.locations.modals.delete.button.text")
                        ) +
                        " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }