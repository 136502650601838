var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    _vm._b({ staticClass: "analytics" }, "vel-page", _vm.pageAttrs, false),
    [
      _c(
        "div",
        { staticClass: "graph" },
        [
          _c("vel-card", [_c("locations-per-month")], 1),
          _c("vel-card", [_c("gross-sales-per-month")], 1),
          _c("vel-card", [_c("locations-per-version")], 1),
          _c("vel-card", [_c("locations-per-currency")], 1),
          _c("vel-card", [_c("users-per-month")], 1),
          _c("vel-card", [_c("users-cummulative-month")], 1)
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }