<template>
  <div>
    <pie-line-chart
      :pending="isLoading"
      :options="chartOptions"
      :force="true"
      :series="series"
      :labels="labels()"
      :limit="1000"
      :line="true"
      :multiple="false"
      :title="$t('analytics.usersPerMonth.title')"
      class="users-per-month__chart"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import {mapState as mapPiniaState} from "pinia/dist/pinia.esm-browser";
import {useDataUsers} from "@/stores/data/users.module";
import {mapActions as mapPiniaActions} from "pinia/dist/pinia";

export default {
  name: 'users-per-month',
  components: {
    PieLineChart
  },
  computed: {
    ...mapPiniaState(useDataUsers, {
      getUsersAll: 'getUsersAll',
      isLoading: 'isLoading'
    }),
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        colors: [
          '#ADD8C7',
          '#F7B844',
          '#008ffb',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB'
        ],
        legend: {
          position: 'bottom',
          horizontalAlign: 'right',
          floating: true,
          offsetY: 8,
          offsetX: 5,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '10px',
            colors: ['#304758']
          }
        },
        xaxis: {
          offsetY: -16,
          offsetX: 5,
          categories: this.labels(),
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true
          }
        },
        responsive: [
          {
            breakpoint: 1024,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }
        ],
        yaxis: {
          title: {
            text: this.$t('analytics.usersPerMonth.yaxis')
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: true,
            formatter(val) {
              return `${val}`;
            }
          }
        },

        title: {
          text: this.$t('analytics.usersPerMonth.xaxis'),
          floating: true,
          offsetY: 305,
          position: 'bottom',
          align: 'center',
          fontSize: '12px',
          style: {
            color: '#444',
            'font-size': '20px'
          }
        }
      };
    },
    series() {
      const data = {};

      Object.keys(this.values).forEach(k => {
        const year = new Date(k).getUTCFullYear();
        const month = new Date(k).getUTCMonth() + 1;

        if (!data[year]) {
          data[year] = {
            name: year.toString(),
            type: 'column',
            data: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
          };
        }

        data[year].data[month] += this.values[k];
      });

      return Object.values(data).map(d => ({ ...d, data: Object.values(d.data) }));
    },
    values() {
      return (this.getUsersAll || [])
        .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
        .reduce((a, b) => {
          const aggregateValue = `${b.created.substr(0, 7)}-01`;
          a[aggregateValue] = (a[aggregateValue] || 0) + 1;
          return a;
        }, {});
    }
  },
  data() {
    return {
      labels() {
        return [
          '2021-01-01',
          '2021-02-01',
          '2021-03-01',
          '2021-04-01',
          '2021-05-01',
          '2021-06-01',
          '2027-07-01',
          '2021-08-01',
          '2021-09-01',
          '2021-10-01',
          '2021-11-01',
          '2021-12-01'
        ].map(d => DateTime.fromISO(d).toFormat('LLL'));
      }
    };
  },
  methods: {
    ...mapPiniaActions(useDataUsers, ['getUsers'])
  },
  mounted() {
    this.getUsers();
  }
};
</script>
<style lang="scss" scoped>
/deep/ .apexcharts-legend-text,
/deep/ .apexcharts-tooltip-text-label {
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

/deep/ .apexcharts-title-text {
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  text-transform: capitalize !important;
}
</style>
