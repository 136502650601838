import { bus } from '@/bus';
import {mapStores} from "pinia";
import {useUIAppStore} from "@/stores/ui/app.module";

export default {
  documentEvents: {
    keyup: 'handleDocumentKeyUp',
    mousedown: 'handleDocumentClick',
    touchstart: 'handleDocumentClick'
  },
  computed: {
    ...mapStores(useUIAppStore)
  },
  methods: {
    handleDocumentKeyUp(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        bus.$emit('document:keys:escape');
        this.handleDocumentEscape(event);
      }
    },
    handleDocumentEscape() {
      return this.appStore.resetPopover();
    },
    handleDocumentClick(event) {
      bus.$emit('document:click', event);
    },
    registerBusEvents() {
      Object.keys(this.$options.documentEvents).forEach(event => {
        const callback = this[this.$options.documentEvents[event]];
        document.addEventListener(event, callback);
      });
    },
    unregisterBusEvents() {
      Object.keys(this.$options.documentEvents).forEach(event => {
        const callback = this[this.$options.documentEvents[event]];
        document.removeEventListener(event, callback);
      });
    }
  }
};
