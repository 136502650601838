import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';

export const useDataSalesBigDivisions = defineStore('salesBigDivisions', {
  state: () => ({
    bigdivisionSales: null,
    pending: true,
    success: null,
    error: null
  }),
  getters: {
    isPending: state => state.pending,
    getBigDivisionSales: state => state.bigdivisionSales,
    getBigDivisionSalesByCurrency(state) {
      return currencyId =>
        (this.getBigDivisionSales || [])
          .filter(zs => zs.salesAmount > 0)
          .sort((a, b) => b.salesAmount - a.salesAmount);
    },
    isError: state => !!state.error,
    getError: state => state.error,
    isSuccess: state => state.success,
    getSeries(state) {
      return currencyId => this.getBigDivisionSalesByCurrency(currencyId).map(zs => zs.salesAmount);
    },
    getLabels(state) {
      return currencyId => this.getBigDivisionSalesByCurrency(currencyId).map(zs => zs.bigDivision.descriptionMain);
    },
    getTotal(state) {
      return currencyId => this.getBigDivisionSalesByCurrency(currencyId).length;
    }
  },
  actions: {
    async getSalesByBigDivision(payload) {
      const toDay = today().startOf('day').toString();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;

      const routeQuery = {
        currency: '$',
        ...router.currentRoute.query,
        from,
        to,
        ...payload
      };

      if (!requestAllocated(routeQuery)) {
        this.getBigDivisionSalesRequest();

        try {
          const bigdivisionSales = await salesService.getBigDivisionSalesV2(getToken(), routeQuery);
          this.getBigDivisionSalesSuccess({ bigdivisionSales });
          deallocateRequest(routeQuery);
        } catch (error) {
          this.getBigDivisionSalesFailure(error);
        }
      }
    },
    getBigDivisionSalesRequest() {
      this.pending = true;
      this.success = null;
      this.bigdivisionSales = null;
      this.error = null;
    },
    getBigDivisionSalesSuccess({ bigdivisionSales }) {
      this.pending = false;
      this.success = true;
      this.bigdivisionSales = bigdivisionSales;
      this.error = null;
    },
    getBigDivisionSalesFailure(error) {
      this.pending = false;
      this.success = false;
      this.bigdivisionSales = null;
      this.error = error;
    }
  }
});
