<template>
  <div v-loading="loading" class="users-commulative__chart">
    <h5>{{ $t('analytics.locationsPerCountry.title') }}</h5>
    <div v-if="!loading">
      <apexchart type="radialBar" height="375" :options="chartOptions" :series="series" />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { getTotalLocationsByCountries } from '@/services/analytics';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataLocationsStore} from "@/stores/data/locations.module";

export default {
  name: 'locations-per-currency',
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['isLoading', 'getLocationsAll']),
    chartOptions() {
      const self = this;
      return {
        chart: {
          height: 375,
          type: 'radialBar'
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: false
              }
            }
          }
        },
        colors: ['#008ffb', '#F7B844', '#ADD8C7', '#EC3C65', '#1E5D8C', '#421243', '#7F94B0', '#EF6537', '#C0ADDB'],
        labels: this.labels,
        legend: {
          show: true,
          floating: true,
          fontSize: '16px',
          position: 'left',
          offsetX: 160,
          offsetY: 15,
          labels: {
            useSeriesColors: true
          },
          markers: {
            size: 0
          },
          formatter(seriesName, opts) {
            return `${seriesName}: ${self.data[opts.seriesIndex].total}`;
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false
              }
            }
          }
        ]
      };
    },
    labels() {
      return (this.data || []).map(
        x =>
          (x.country.translation && x.country.translation[this.$i18n.locale === 'en' ? 'descEn' : 'descFr']) ||
          x.country.alpha3Code.toUpperCase()
      );
    },
    series() {
      const total = (this.data || []).reduce((a, b) => a + b.total, 0);

      return (this.data || []).map(x => (x.total * 100) / total);
    },
    values() {
      return (this.getLocationsAll || []).reduce((a, b) => {
        const label = b.currency;
        a[label] = (a[label] || 0) + 1;
        return a;
      }, {});
    }
  },
  data() {
    return {
      loading: true,
      data: []
    };
  },
  async mounted() {
    try {
      this.data = await getTotalLocationsByCountries().then(x =>
        (x || []).filter(a => a.total > 0).sort((a, b) => b.total - a.total)
      );
    } finally {
      this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$charts-empty-text-color: $shuttle-gray;
$charts-title-text-color: $river-bed;

.users-commulative__chart {
  font-family: $base-font-family;

  .chart__empty-state {
    color: $charts-empty-text-color;
  }

  .apexcharts-xaxis-texts-g text:nth-child(2n):not(:last-child) {
    visibility: hidden;
  }

  h5 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.apexcharts-title-text {
  color: $charts-title-text-color;
  font-size: rem-calc(13);
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(medium) {
    font-size: rem-calc(15);
  }
}

.apexcharts-xaxistooltip {
  overflow: visible;
}

.apexcharts-xaxistooltip-text {
  /* stylelint-disable */
  min-width: 30px !important;
}

.apexcharts-legend-text,
.apexcharts-tooltip-text-label {
  display: inline-block;
  text-transform: initial !important;

  &::first-letter {
    text-transform: uppercase;
  }
}
</style>
