var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-table", {
    attrs: {
      rows: _vm.rows,
      columns: _vm.columns,
      loading: _vm.loading,
      hoverable: true,
      defaultDirection: "asc",
      defaultSort: "location"
    },
    scopedSlots: _vm._u([
      {
        key: "empty",
        fn: function() {
          return [_vm._v(" " + _vm._s(_vm.$t("locations.errors.noData")) + " ")]
        },
        proxy: true
      },
      {
        key: "label-column",
        fn: function(scope) {
          return [
            _c(
              "div",
              { staticClass: "location__name" },
              [
                _c("vel-avatar", {
                  staticClass: "location__name-avatar",
                  attrs: { fullname: scope.row.name }
                }),
                _c("span", { staticClass: "location__name-text" }, [
                  _vm._v(_vm._s(scope.row.name))
                ])
              ],
              1
            )
          ]
        }
      },
      {
        key: "veloceVersion-column",
        fn: function(scope) {
          return [
            _vm._v(" " + _vm._s(_vm.validateVersion(scope.row.version)) + " ")
          ]
        }
      },
      {
        key: "actions-column",
        fn: function(scope) {
          return [
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c("vel-button", {
                  staticClass: "actions__goto",
                  attrs: {
                    type: "link",
                    disabled: _vm.disableActions,
                    icon: "external-link"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleGotoLocation(
                        _vm.locations[scope.rowIndex]
                      )
                    }
                  }
                }),
                _c("vel-button", {
                  staticClass: "actions__delete",
                  attrs: {
                    type: "link",
                    disabled: _vm.disableActions,
                    icon: "delete"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleDelete(_vm.locations[scope.rowIndex])
                    }
                  }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }