<template>
  <div>
    <vel-button
      type="primary"
      @click="this.exportXLS"
      :loading="loading || (externalExport && isExternalGenerating)"
      :icon="isMediumUp ? 'file-excel' : undefined"
      class="vel-button"
    >
      <vel-icon v-if="isMediumDown" name="file-download" />
      {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
    </vel-button>
  </div>
</template>
<script>
import DeviceMixin from '@/mixins/device-mixin';
import ExportXlsMixin from '@/mixins/export-xls-mixin';
import VelButton from '@/components/button/VelButton';
import VelIcon from '@/components/icon/VelIcon';

export default {
  name: 'vel-table-export',
  components: {
    VelButton,
    VelIcon
  },
  mixins: [ExportXlsMixin, DeviceMixin],
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    rows: {
      type: Array,
      default() {
        return [];
      }
    },
    metas: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    externalExport: {
      type: Boolean,
      default: false
    },
    isExternalGenerating: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    exportXLS() {
      this.$ga.event('report', 'download', this.$route.name);
      if (this.externalExport) {
        this.$emit('exportXls');
      } else {
        this.exportToXLS({ columns: this.columns, rows: this.rows, ...this.metas });
      }

    }
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.vel-button {
  @include noPrint;

  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

.vel-button-wide {
  width: 100%;
}
</style>
