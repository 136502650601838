import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import {getUniqHoursWithWeekdayFromArrays, getUTCHourWithFirstWeekDay, today} from '@/helpers/date.helpers';
import router from '@/router';
import salesService from '@/services/sales.service';
import { createCollectionState, getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import hourlySalesService from '@/services/sales.service';
import round from 'lodash.round';
import { oc } from 'ts-optchain';
import uniq from 'lodash.uniq';

const { selectAll } = getSelectors();

const initialState = {
  hourlySales: [[], [], [], []],
  status: {},
  error: null
};

export const useDataSalesHourly = defineStore('salesHourly', {
  state: () => initialState,
  getters: {
    areSalesLoading: (state: any) => state.status?.isLoadingSales || false,
    getHourlySales: state => state.hourlySales,
    previousWeekHourlySales: state => state.hourlySales,
    uniqueLocalTimes: state => uniqueLocalTimes(state),
    uniqueHours: state => uniqueHoursWithWeekDays(state),
    uniqueCurrencies: state => uniqueCurrencies(state),
    hourlySalesGroupedByCurrencyAndLocalTime({ hourlySales }) {
      return groupCurrentHourlySales(hourlySales, this.uniqueHours, this.uniqueCurrencies);
    },
    previousWeekHourlySalesGrouped({ hourlySales }) {
      return groupPreviousWeekHourlySales(hourlySales, this.uniqueHours, this.uniqueCurrencies);
    }
  },
  actions: {
    async getSalesHourly(payload: any = {}) {
      const from = router.currentRoute.query.from || today().startOf('day').toISO();
      const routeQuery: any = {
        locationId: '',
        ...router.currentRoute.query,
        day: from,
        ...payload
      };

      if (!requestAllocated(routeQuery)) {
        this.getHourlySalesRequest();

        try {
          const requests = [hourlySalesService.getSalesHourly(getToken(), routeQuery)];

          (payload.periods || []).forEach(period => {
            requests.push(
              hourlySalesService.getSalesHourly(getToken(), {
                ...routeQuery,
                ...period
              })
            );
          });

          const sales = await Promise.all(requests);

          this.getHourlySalesSuccess(sales);

          deallocateRequest(routeQuery);
        } catch (error) {
          this.getHourlySalesFailure(error);
        }
      }
    },
    getHourlySalesRequest() {
      this.status = { ...this.status, isLoadingSales: true };
      this.hourlySales = initialState.hourlySales;
      this.error = null;
    },
    getHourlySalesSuccess(sales) {
      this.status = { ...this.status, isLoadingSales: false };
      this.hourlySales = sales || initialState.hourlySales;
      this.error = null;
    },
    getHourlySalesFailure(error) {
      this.status = { ...this.status, isLoadingSales: false };
      this.hourlySales = initialState.hourlySales;
      this.error = error;
    }
  }
});


const uniqueLocalTimes = ({ hourlySales }) =>
  uniq([...hourlySales[0].map(sale => sale.invoiceLocalTime), ...hourlySales[1].map(sale => sale.invoiceLocalTime)]);

const uniqueHoursWithWeekDays = ({ hourlySales }) =>
  getUniqHoursWithWeekdayFromArrays(
    uniq(hourlySales[0].map(sale => sale.invoiceLocalTime)),
    uniq(hourlySales[1].map(sale => sale.invoiceLocalTime)),
    uniq(hourlySales[2].map(sale => sale.invoiceLocalTime)),
    uniq(hourlySales[3].map(sale => sale.invoiceLocalTime))
  );

const uniqueCurrencies = ({ hourlySales }) =>
  uniq([
    ...hourlySales[0].map(sale => sale.currency),
    ...hourlySales[1].map(sale => sale.currency),
    ...hourlySales[2].map(sale => sale.currency),
    ...hourlySales[3].map(sale => sale.currency)
  ]);

const groupCurrentHourlySales = (hourlySales, uniqueHours, uc) =>
  groupByCurrencyAndInvoiceLocalTime(hourlySales, uniqueHours, uc);

const groupPreviousWeekHourlySales = (previousWeekHourlySales, uniqueHours, uc) =>
  groupByCurrencyAndInvoiceLocalTime(previousWeekHourlySales, uniqueHours, uc);

const groupByCurrencyAndInvoiceLocalTime = (sales, hours, currencies) => {
  const currenciesMap = {};

  for (const currency of currencies) {
    currenciesMap[currency] = { salesAmount: 0, quantity: 0 };
  }

  const groupedSales = sales.map(s =>
    s.reduce((series, hs) => {
      const saleHour = getUTCHourWithFirstWeekDay(hs.invoiceLocalTime);

      series.set(saleHour, {
        ...series.get(saleHour),
        [hs.currency]: {
          salesAmount: series.get(saleHour)[hs.currency].salesAmount + hs.salesAmount,
          quantity: series.get(saleHour)[hs.currency].quantity + hs.quantity
        }
      });

      return series;
    }, new Map(hours.map(hour => [hour, currenciesMap])))
  );

  groupedSales.forEach(s =>
    s.forEach((value, time, map) => {
      for (const currency in value) {
        if (Object.prototype.hasOwnProperty.call(value, currency)) {
          map.set(time, {
            ...map.get(time),
            [currency]: {
              quantity: round(map.get(time)[currency].quantity, 2),
              salesAmount: round(map.get(time)[currency].salesAmount, 2)
            }
          });
        }
      }
    })
  );

  return groupedSales;
};
