<template>
  <vel-page class="group">
    <template v-slot:title v-if="group">
      {{ group.name }}
    </template>

    <vel-entity-information-card
      v-if="group"
      :isLoading="isLoadingGroup"
      :entity="group"
      :properties="['name', 'currency']"
      :explicitProperties="[{ name: 'kind', value: getTranslatedKindName(group.kind) }]"
      avatar="name"
      translationPrefix="group.info"
    ></vel-entity-information-card>

    <div class="locations__alerts">
      <vel-alert @dismiss="clearGetGroupLocationsError" class="alert" level="error" v-if="getGroupLocationsError">
        {{ $t('group.errors.getGroupLocationsError') }}
      </vel-alert>

      <vel-alert
        @dismiss="clearLatestAddedGroupLocations"
        class="alert"
        level="success"
        v-if="latestAddedGroupLocationsCount"
      >
        {{ $t('group.locations.form.success.addLocations') }}
      </vel-alert>

      <vel-alert
        @dismiss="clearLatestDeletedGroupLocation"
        class="alert"
        level="success"
        v-if="latestDeletedGroupLocation"
      >
        {{ $t('group.locations.form.success.deleteLocation') }}
      </vel-alert>
    </div>

    <vel-card class="group__permissions">
      <template v-slot:title>
        <div class="group__permissions-title">
          {{ $t('group.permissions.title') }}
          <div class="group__permissions__list-length">
            {{ groupPermissions && groupPermissions.length }}
          </div>
        </div>
      </template>

      <div class="permissions" v-if="!isLoadingGroupPermissions">
        <div class="permissions__list">
          <div v-if="hasPermissions">
            <permissions-list class="list__table" translationPrefix="group" :permissions="groupPermissions" />
          </div>

          <vel-alert :dismissable="false" class="list__none" level="notice" v-else>
            {{ $t('group.permissions.none') }}
          </vel-alert>
        </div>
      </div>
      <vel-spinner v-else />
    </vel-card>

    <vel-card class="group__locations">
      <template v-slot:title>
        <div class="group__locations-title">
          {{ $t('group.locations.title') }}
          <div class="group__locations__list-length">
            {{ groupLocations && groupLocations.length }}
          </div>
        </div>
      </template>

      <div class="locations" v-if="!areLocationsLoading">
        <div class="locations__actions">
          <vel-button class="actions__add" type="primary" @click="handleLocationAdd()">
            {{ $t('group.locations.actions.add') }}
          </vel-button>
        </div>

        <div class="locations__list">
          <div v-if="hasLocations">
            <locations-list
              class="list__table"
              :locationTitle="$t('group.locations.title')"
              :locations="groupLocations"
              @goToLocation="handleGoToLocation"
              @delete="handleLocationDelete"
            />
          </div>

          <vel-alert :dismissable="false" class="list__none" level="notice" v-else>
            {{ $t('group.locations.none') }}
          </vel-alert>
        </div>
      </div>
      <vel-spinner v-else />

      <div class="location__modals">
        <add-location-modal
          class="modals__add"
          :groupId="groupId"
          :locationsOptions="selectableLocations"
          @dismiss="handleAddModalDismiss"
          @success="handleAddModalDismiss"
          v-if="showAddLocation"
        ></add-location-modal>

        <delete-location-modal
          class="modals__delete"
          :groupId="groupId"
          :location="deletedLocation"
          @dismiss="handleDeleteModalDismiss"
          @success="handleDeleteModalDismiss"
          v-if="showDeleteLocation && !!deletedLocation"
        ></delete-location-modal>
      </div>
    </vel-card>
  </vel-page>
</template>

<script>
import AddLocationModal from './components/modals/AddLocationModal';
import DeleteLocationModal from './components/modals/DeleteLocationModal';
import GroupKindMixin from '@/mixins/group/group-kind-mixin';
import LocationsList from './components/locations-list/LocationsList';
import PermissionsList from '@/private/components/permissions-list/PermissionsList';
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelEntityInformationCard from '@/components/entity-information-card/VelEntityInformationCard';
import VelPage from '@/components/page/VelPage';
import VelSpinner from '@/components/spinner/VelSpinner';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataGroupsGroup} from "@/stores/data/groups/group.module";
import {useDataGroupsLocationGroup} from "@/stores/data/groups/location-group.module";
import {useDataGroupsPermissionsGroup} from "@/stores/data/groups/permissions-group.module";

export default {
  name: 'GroupPage',
  mixins: [GroupKindMixin],
  components: {
    AddLocationModal,
    DeleteLocationModal,
    LocationsList,
    PermissionsList,
    VelAlert,
    VelButton,
    VelCard,
    VelEntityInformationCard,
    VelPage,
    VelSpinner
  },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapPiniaActions(useDataGroupsGroup, ['getGroup', 'resetGroup']),
    ...mapPiniaActions(useDataGroupsPermissionsGroup, [
      'getGroupPermissions',
      'resetGroupPermissions',
      'clearGetGroupPermissionsError',
      'getPermissions'
    ]),
    ...mapPiniaActions(useDataGroupsLocationGroup, [
      'getGroupLocations',
      'resetGroupLocations',
      'clearLatestAddedGroupLocations',
      'clearLatestDeletedGroupLocation',
      'clearGetGroupLocationsError'
    ]),
    handleGoToLocation(location) {
      this.$router.push({
        name: 'location',
        params: {
          locationId: location.id
        }
      });
    },
    handleLocationDelete(location) {
      this.deletedLocation = location;
      this.showDeleteLocation = true;
    },
    handleLocationAdd() {
      this.showAddLocation = true;
    },
    handleAddModalDismiss() {
      this.showAddLocation = false;
    },
    handleDeleteModalDismiss() {
      this.showDeleteLocation = false;
      this.deletedLocation = null;
    }
  },
  data() {
    return {
      showAddLocation: false,
      showDeleteLocation: false,
      deletedLocation: null
    };
  },
  computed: {
    ...mapPiniaState(useDataGroupsGroup, ['isGroupNotFound', 'group', 'isLoadingGroup']),
    ...mapPiniaState(useDataGroupsPermissionsGroup, {
      hasPermissions: 'hasPermissions',
      groupPermissions: 'sortedPermissions',
      isLoadingGroupPermissions: 'isLoadingGroupPermissions',
      getGroupPermissionsError: 'getGroupPermissionsError'
    }),
    ...mapPiniaState(useDataGroupsLocationGroup, [
      'locations',
      'isLoadingGroupLocations',
      'getGroupLocationsError',
      'latestAddedGroupLocationsCount',
      'latestDeletedGroupLocation'
    ]),
    ...mapPiniaState(useDataLocationsStore, {
      getLocationsRows: 'getLocationsRows',
      allLocations: 'locations',
      allLocationsLoading: 'getLocationsIsLoading'
    }),
    hasPermissions() {
      return (this.groupPermissions || []).length > 0;
    },
    currentGroupLocationsIds() {
      return (this.locations && this.locations.map(location => location.locationId)) || [];
    },
    groupLocations() {
      if (!this.getLocationsRows || (this.getLocationsRows && !this.getLocationsRows.length)) {
        return [];
      }
      return this.getLocationsRows.filter(location => this.currentGroupLocationsIds.includes(location.id));
    },
    selectableLocations() {
      if (!this.allLocations.length) {
        return [];
      }

      return this.allLocations.filter(location => !this.currentGroupLocationsIds.includes(location.value));
    },
    hasLocations() {
      return this.allLocations && this.locations && !!this.allLocations.length && !!this.locations.length;
    },
    areLocationsLoading() {
      return this.isLoadingGroupLocations;
    }
  },
  watch: {
    isGroupNotFound(newValue) {
      if (newValue) {
        this.$router.replace({ name: 'notfound' });
      }
    }
  },
  created() {
    this.clearGetGroupPermissionsError();
    this.resetGroup();
    this.resetGroupLocations();
    this.getPermissions(this.groupId);
    this.getGroup(this.groupId);
    this.getGroupLocations(this.groupId);
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.group__permissions,
.group__locations {
  &-title {
    align-items: center;
    display: flex;
    height: rem-calc(25);
  }

  &__list-length {
    background: $primary-color;
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-size: $small-font-size;
    font-weight: bold;
    justify-content: center;
    margin-left: rem-calc(15);
    padding: rem-calc(5) 0 rem-calc(5) 0;
    width: rem-calc(40);
  }
}

.group__locations {
  margin-top: rem-calc(16);

  .locations {
    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    &__list {
      margin-top: rem-calc(16);
    }
  }
}
</style>
