<template>
  <vel-page class="create-user-page">
    <template v-slot:title>
      {{ $t('newUser.pageTitle') }}
    </template>
    <vel-alert @dismiss="clearLatestCreatedUser" class="alert" level="success" v-if="latestCreatedUser">
      {{ $t('users.form.success.createUser', { name: latestCreatedUser.name }) }}
    </vel-alert>
    <create-user-form />
  </vel-page>
</template>

<script>
import {storeToRefs} from 'pinia';
import CreateUserForm from './components/CreateUserForm.vue';
import VelAlert from '@/components/alert/VelAlert';
import VelPage from '@/components/page/VelPage';
import {useDataAccount} from "@/stores/data/account.module";

export default {
  name: 'create-user-page',
  components: {
    VelAlert,
    VelPage,
    CreateUserForm
  },
  setup() {
    const dataAccount = useDataAccount();
    const { error, latestCreatedUser } = storeToRefs(dataAccount);

    return { error, latestCreatedUser, clearLatestCreatedUser: dataAccount.clearLatestCreatedUser };
  }
};
</script>
