var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reseller-locations-list" },
    [
      _c("div", { staticClass: "reseller-locations-list-header" }, [
        _vm._v(" " + _vm._s(_vm.$t("resellerInfo.table.header")) + " "),
        _c("span", { staticClass: "reseller-locations-list-header-count" }, [
          _vm._v(_vm._s(_vm.selectTotal))
        ])
      ]),
      _c("vel-table", {
        attrs: {
          rows: _vm.rows,
          columns: _vm.columns,
          showRowsLength: true,
          initialLength: _vm.selectTotal
        },
        scopedSlots: _vm._u([
          {
            key: "name-column",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  { staticClass: "reseller-locations-list-name" },
                  [
                    _c("vel-avatar", {
                      staticClass: "reseller-locations-list-name-avatar",
                      attrs: { fullname: scope.row.name }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "reseller-locations-list-name-avatar-text"
                      },
                      [_vm._v(_vm._s(scope.row.name))]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }