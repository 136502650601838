<template>
  <portal to="app">
    <vel-modal class="coupon-delete__modal" dismissable @dismiss="handleCancel">
      <template v-slot:header>
        {{ $t('coupons.modals.delete.title') }}
      </template>
      <template v-slot:default>
        <vel-alert
          @dismiss="clearDeleteCouponError"
          class="alert delete-coupon__error"
          level="error"
          v-if="hasDeleteCouponError"
        >
          {{ $t('coupons.errors.deleteCoupon') }}
        </vel-alert>

        <span
          class="coupon-delete__confirmation"
          v-html="$t('coupons.modals.delete.textHtml', { name: coupon.name, code: coupon.code })"
        />
      </template>
      <template v-slot:footer>
        <vel-button class="coupon-delete__close-button" type="secondary" @click="handleCancel">
          {{ $t('defaults.actions.close') }}
        </vel-button>
        <vel-button class="coupon-delete__confirm-button" type="critical" :loading="isLoading" @click="handleDelete">
          {{ $t('coupons.modals.delete.button.text') }}
        </vel-button>
      </template>
    </vel-modal>
  </portal>
</template>

<script>
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelModal from '@/components/modal/VelModal';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia";
import {useDataCoupons} from "@/stores/data/coupons.module";

export default {
  name: 'delete-coupon-confirm-modal',
  components: {
    VelButton,
    VelModal,
    VelAlert
  },
  props: {
    coupon: {
      type: Object,
      default: () => ({
        code: null,
        name: null
      })
    }
  },
  data() {
    return {
      currentCoupon: { ...this.coupon }
    };
  },
  methods: {
    ...mapPiniaActions(useDataCoupons, ['clearLatestDeletedCoupon', 'deleteCoupon', 'clearDeleteCouponError']),
    handleCancel() {
      this.$emit('dismiss');
    },
    handleDelete() {
      this.deleteCoupon(this.currentCoupon);
    }
  },
  watch: {
    latestDeletedCoupon(deletedCoupon) {
      if (deletedCoupon && this.currentCoupon.code === deletedCoupon.code) {
        this.$emit('dismiss');
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataCoupons, ['hasDeleteCouponError', 'latestDeletedCoupon', 'isLoading'])
  },
  created() {
    this.clearLatestDeletedCoupon();
    this.clearDeleteCouponError();
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.delete-coupon__error {
  margin-bottom: rem-calc(16);
}
</style>
