var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.title,
            static: true,
            centered: true,
            "hide-header-close": true,
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          on: {
            hidden: function($event) {
              _vm.showList = false
              _vm.$emit("onCancel", true)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c("b-container", { attrs: { fluid: "" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right ml-2",
                            attrs: { variant: "primary" },
                            on: { click: _vm.download }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "taxCategoriesList.modal.errorDelete.actions.download"
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function($event) {
                                _vm.showDeleteModal = null
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "taxCategoriesList.modal.errorDelete.actions.close"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showDeleteModal,
            callback: function($$v) {
              _vm.showDeleteModal = $$v
            },
            expression: "showDeleteModal"
          }
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.body) } }),
              _c(
                "b-button",
                {
                  staticClass: "pl-0 ml-0",
                  attrs: { variant: "link" },
                  on: {
                    click: function($event) {
                      _vm.showList = !_vm.showList
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("taxCategoriesList.modal.errorDelete.link")
                      ) +
                      " "
                  ),
                  _c("b-icon", {
                    attrs: {
                      icon: _vm.showList ? "chevron-down" : "chevron-right",
                      scale: "0.7",
                      variant: "sm"
                    }
                  })
                ],
                1
              ),
              _vm.showList
                ? _c("div", { staticClass: "tax-categories-list" }, [
                    _c(
                      "ol",
                      {
                        staticStyle: {
                          columns: "2",
                          margin: "0",
                          padding: "0 0 0 1rem"
                        }
                      },
                      _vm._l(_vm.list, function(taxCategory) {
                        return _c("li", { key: taxCategory.id }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                (taxCategory.name === "" && "--") ||
                                  taxCategory.name
                              ) +
                              " "
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }