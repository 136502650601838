<template>
  <pie-line-chart
    :pending="isItemSalesLoading"
    :options="chartOptions"
    :series="series"
    :labels="labels"
    :currency="currencyId"
    :title="$t('items.sales.graph.title', { limit })"
    :type="get.default_chart_items_sales.value"
    class="items-sales-graph"
  />
</template>

<script>
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import {useDataSalesProducts} from "@/stores/data/sales/products.module";
import {storeToRefs} from "pinia";
import {mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataConfig} from "@/stores/data/config.module";

export default {
  name: 'items-sales-graph',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  setup() {
    const salesProductsStore = useDataSalesProducts();
    const { isItemSalesLoading, itemsSales } = storeToRefs(salesProductsStore);

    return { isItemSalesLoading, itemsSales, salesProductsStore }
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('items.sales.graph.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('items.sales.graph.yaxis')
          }
        }
      };
    },
    labels() {
      return this.itemsSales
        .map(item => item.product.nameMain)
        .slice(0, this.limit);
    },
    series() {
      return [
        {
          name: this.$t('items.sales.graph.sales', { currency: this.currencyId }),
          data: this.itemsSales
            .map(item => Number(item.salesAmount))
            .slice(0, this.limit)
        }
      ];
    }
  },
  methods: {
  },
  mounted() {
    this.salesProductsStore.getItemsSales({ limit: this.limit });
  }
};
</script>
