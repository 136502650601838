var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-card",
    { staticClass: "report__sales" },
    [
      _c("sales-breadcrumb", {
        attrs: { crumbs: _vm.crumbs },
        on: { click: _vm.handleBreadcrumb }
      }),
      _c("sales", {
        ref: "sales",
        attrs: {
          defaultDirection: _vm.defaultSalesDirection,
          defaultSort: _vm.defaultSalesSort,
          hoverable: false,
          loading: _vm.areSalesLoading,
          sales: _vm.sales,
          hiddenColumns: _vm.hiddenColumns,
          salesOverall:
            (_vm.getIndicatorsData && _vm.getIndicatorsData.grossSalesAmount) ||
            0,
          showQuantity: _vm.showSalesQuantity,
          exportableMetas: _vm.exportableMetas,
          filterable: false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }