var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        { staticClass: "mb-4", attrs: { show: _vm.error, variant: "danger" } },
        [_vm._v(" " + _vm._s(_vm.$t("employeeCreate.updateError")) + " ")]
      ),
      _c(
        "div",
        {
          staticClass: "px-4 pt-4",
          staticStyle: { "background-color": "#f5f5f5" }
        },
        [
          _c(
            "b-button-group",
            { staticClass: "text-right mode" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: !_vm.showActive ? "primary" : "light",
                    disabled: _vm.pending
                  },
                  on: {
                    click: function($event) {
                      return _vm.setActive(false)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "employeeCreate.availability.buttonGroup.availability"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    variant: _vm.showActive ? "primary" : "light",
                    disabled: _vm.pending
                  },
                  on: {
                    click: function($event) {
                      return _vm.setActive(true)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "employeeCreate.availability.buttonGroup.timeOff"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.showActive
                ? _vm.$t("employeeCreate.availability.timeOff.form.title")
                : _vm.$t("employeeCreate.availability.form.title")
            ) +
            " "
        )
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.pending,
                expression: "pending"
              }
            ],
            staticClass: "mb-md-4"
          },
          [
            !_vm.showActive
              ? _c(
                  "b-form-checkbox",
                  {
                    staticClass: "ml-0 mb-4",
                    on: { change: _vm.onAvailableAllTime },
                    model: {
                      value: _vm.employee.availableAllTime,
                      callback: function($$v) {
                        _vm.$set(_vm.employee, "availableAllTime", $$v)
                      },
                      expression: "employee.availableAllTime"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "employeeCreate.availability.form.selectAllTime"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            !_vm.showActive && _vm.shifts.length
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "availability-grid",
                      staticStyle: { "overflow-x": "auto" }
                    },
                    [
                      _c(
                        "b-row",
                        {
                          staticClass: "mb-4",
                          style:
                            "width:" + (180 * _vm.shifts.length + 120) + "px"
                        },
                        [
                          _c("b-col", {
                            staticClass: "p0",
                            staticStyle: { width: "120px" }
                          }),
                          _vm._l(_vm.shifts, function(shift) {
                            return _c(
                              "b-col",
                              {
                                key: shift.id,
                                staticClass: "p-0",
                                staticStyle: { width: "180px" }
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-100 font-weight-normal",
                                    attrs: { variant: "light" }
                                  },
                                  [_vm._v(" " + _vm._s(shift.name) + " ")]
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      ),
                      _vm._l([0, 1, 2, 3, 4, 5, 6], function(dayNumber) {
                        return _c(
                          "b-row",
                          {
                            key: dayNumber,
                            staticClass: "mb-4",
                            style:
                              "width:" + (180 * _vm.shifts.length + 120) + "px"
                          },
                          [
                            _c("b-col", { staticStyle: { width: "120px" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "employeeCreate.availability.dayOfWeek." +
                                        dayNumber
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _vm._l(_vm.shifts, function(shift) {
                              return _c(
                                "b-col",
                                {
                                  key: shift.id,
                                  staticClass: "p-0 day",
                                  staticStyle: { width: "180px" }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "w-100 font-weight-normal",
                                      attrs: {
                                        variant: _vm.availabilityForm[shift.id][
                                          dayNumber
                                        ]
                                          ? "success"
                                          : "light"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toggle(shift.id, dayNumber)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.availabilityForm[shift.id][
                                              dayNumber
                                            ]
                                              ? _vm.$t(
                                                  "employeeCreate.availability.form.available"
                                                )
                                              : _vm.$t(
                                                  "employeeCreate.availability.form.notAvailable"
                                                )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      })
                    ],
                    2
                  )
                ]
              : _vm._e(),
            _vm.showActive
              ? [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.timeOffForm-sidebar",
                          modifiers: { "timeOffForm-sidebar": true }
                        }
                      ],
                      attrs: { variant: "outline-primary" }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "plus", "aria-hidden": "true" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "employeeCreate.availability.timeOff.form.submit"
                            )
                          ) +
                          " "
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mt-4",
                      staticStyle: { "max-width": "100%", "overflow-x": "auto" }
                    },
                    [
                      _c("h6", { staticClass: "mt-4 mt-md-0 mb-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "employeeCreate.availability.timeOff.list.upcomingTitle"
                              )
                            ) +
                            " "
                        )
                      ]),
                      !_vm.upcomingLeaves.length
                        ? _c("p", { staticClass: "mt-4 mt-md-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "employeeCreate.availability.timeOff.list.empty"
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _c("b-table", {
                            ref: "table",
                            staticClass: "mt-4",
                            staticStyle: {
                              "max-width": "1200px",
                              "min-width": "1000px"
                            },
                            attrs: {
                              "thead-class": "bg-info",
                              striped: "",
                              small: "",
                              hover: "",
                              bordered: "",
                              "show-empty": "",
                              fixed: "",
                              "primary-key": "id",
                              items: _vm.upcomingLeaves,
                              fields: _vm.fields,
                              responsive: "sm"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "empty",
                                  fn: function(scope) {
                                    return [
                                      _c("div", { staticClass: "p-2" }, [
                                        _vm._v(_vm._s(scope.emptyText))
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "emptyfiltered",
                                  fn: function(scope) {
                                    return [
                                      _c("div", { staticClass: "p-2" }, [
                                        _vm._v(_vm._s(scope.emptyFilteredText))
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "cell(edit)",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName:
                                                "v-b-toggle.timeOffForm-sidebar",
                                              modifiers: {
                                                "timeOffForm-sidebar": true
                                              }
                                            }
                                          ],
                                          attrs: { variant: "link" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "onModify",
                                                scope
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass:
                                              "row-action text-primary",
                                            attrs: {
                                              icon: "pencil-fill",
                                              "aria-label": "Edit"
                                            }
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("timeAttendances.edit")
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "cell(delete)",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "link text-danger"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showDelete = scope.item
                                            }
                                          }
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass:
                                              "row-action text-danger",
                                            attrs: {
                                              icon: "trash-fill",
                                              "aria-label": "Delete"
                                            }
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("mealPlan.delete")
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1561072298
                            )
                          }),
                      _c("h6", { staticClass: "mt-4 mb-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "employeeCreate.availability.timeOff.list.previousTitle"
                              )
                            ) +
                            " "
                        )
                      ]),
                      !_vm.previousLeaves.length
                        ? _c("p", { staticClass: "mt-4 mt-md-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "employeeCreate.availability.timeOff.list.empty"
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _c("b-table", {
                            ref: "table",
                            staticClass: "mt-4",
                            staticStyle: {
                              "max-width": "1200px",
                              "min-width": "1000px"
                            },
                            attrs: {
                              "thead-class": "bg-info",
                              striped: "",
                              small: "",
                              hover: "",
                              bordered: "",
                              "show-empty": "",
                              fixed: "",
                              "primary-key": "id",
                              items: _vm.previousLeaves,
                              fields: _vm.fields,
                              responsive: "sm"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "empty",
                                  fn: function(scope) {
                                    return [
                                      _c("div", { staticClass: "p-2" }, [
                                        _vm._v(_vm._s(scope.emptyText))
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "emptyfiltered",
                                  fn: function(scope) {
                                    return [
                                      _c("div", { staticClass: "p-2" }, [
                                        _vm._v(_vm._s(scope.emptyFilteredText))
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "cell(edit)",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName:
                                                "v-b-toggle.timeOffForm-sidebar",
                                              modifiers: {
                                                "timeOffForm-sidebar": true
                                              }
                                            }
                                          ],
                                          attrs: { variant: "link" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "onModify",
                                                scope
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass:
                                              "row-action text-primary",
                                            attrs: {
                                              icon: "pencil-fill",
                                              "aria-label": "Edit"
                                            }
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("timeAttendances.edit")
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "cell(delete)",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "link text-danger"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showDelete = scope.item
                                            }
                                          }
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass:
                                              "row-action text-danger",
                                            attrs: {
                                              icon: "trash-fill",
                                              "aria-label": "Delete"
                                            }
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("mealPlan.delete")
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1561072298
                            )
                          })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _c("employee-delete-modal", {
              attrs: {
                title: _vm.$t("employeeCreate.timeOff.deleteModal.title"),
                body: _vm.$t("employeeCreate.timeOff.deleteModal.body"),
                show: _vm.showDelete !== null
              },
              on: {
                onDelete: function($event) {
                  _vm.$emit("onDelete", _vm.showDelete)
                  _vm.showDelete = null
                },
                onCancel: function($event) {
                  _vm.showDelete = null
                }
              }
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }