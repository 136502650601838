<template>
  <div class="user-reset">
    <template v-if="isMediumUp">
      <vel-button class="user-reset__button" type="link" icon="lock-reset" @click="handleModalButtonClick" />
    </template>

    <template v-else>
      <vel-button class="user-reset__button" type="secondary" icon="lock-reset" @click="handleModalButtonClick">
        {{ $t('resetPassword.modal.resetPassword.button.text') }}
      </vel-button>
    </template>

    <portal to="app">
      <vel-modal class="user-reset__modal" dismissable v-if="isModalDisplayed" @dismiss="handleModalDismiss">
        <template v-slot:header>
          {{ $t('resetPassword.modal.resetPassword.title') }}
        </template>
        <template v-slot:default>
          <template v-if="passwordResetUrl">
            <p class="user-reset__url" v-text="passwordResetUrl" />
          </template>
          <template v-else>
            <span
              class="user-reset__confirmation"
              v-html="$t('resetPassword.modal.resetPassword.textHtml', { email: user.email })"
            />
          </template>
        </template>
        <template v-slot:footer>
          <vel-button class="user-reset__close-button" type="secondary" @click="handleModalDismiss">
            {{ $t('defaults.actions.close') }}
          </vel-button>
          <vel-button
            class="user-reset__reset-button"
            :loading="status.isLoading"
            @click="handleResetButtonClick"
            v-if="!passwordResetUrl"
          >
            {{ $t('resetPassword.modal.resetPassword.button.text') }}
          </vel-button>
        </template>
      </vel-modal>
    </portal>
  </div>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelButton from '@/components/button/VelButton';
import VelModal from '@/components/modal/VelModal';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataUsersPasswordReset} from "@/stores/data/users/password-reset.module";

export default {
  name: 'user-reset',
  mixins: [DeviceMixin],
  components: {
    VelButton,
    VelModal
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isModalDisplayed: false
    };
  },
  computed: {
    ...mapPiniaState(useDataUsersPasswordReset, ['status', 'passwordResetUrl'])
  },
  methods: {
    ...mapPiniaActions(useDataUsersPasswordReset, ['getPasswordResetToken']),
    ...mapPiniaActions(useDataUsersPasswordReset, ['clearPasswordResetToken']),
    async handleModalButtonClick() {
      await this.clearPasswordResetToken();
      this.isModalDisplayed = true;
    },
    async handleResetButtonClick() {
      await this.getPasswordResetToken(this.user.email);
    },
    handleModalDismiss() {
      this.isModalDisplayed = false;
    }
  }
};
</script>
