<template>
  <div>
    <b-modal
      v-model="showDeleteModal"
      :title="title"
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
      @hidden="
        showList = false;
        $emit('onCancel', true);
      "
    >
      <b-container fluid>
        <div v-html="body"></div>

        <b-button variant="link" class="pl-0 ml-0" @click="showList = !showList">
          {{ $t('taxCategoriesList.modal.errorDelete.link') }}
          <b-icon :icon="showList ? 'chevron-down' : 'chevron-right'" scale="0.7" variant="sm"></b-icon>
        </b-button>
        <div v-if="showList" class="tax-categories-list">
          <ol style="columns: 2; margin: 0; padding: 0 0 0 1rem;">
            <li v-for="taxCategory of list" :key="taxCategory.id">
              {{ (taxCategory.name === '' && '--') || taxCategory.name }}
            </li>
          </ol>
        </div>
      </b-container>

      <template #modal-footer>
        <b-container fluid>
          <div class="w-100">
            <b-button variant="primary" class="float-right ml-2" @click="download">
              {{ $t('taxCategoriesList.modal.errorDelete.actions.download') }}
            </b-button>
            <b-button variant="outline-primary" class="float-right" @click="showDeleteModal = null">
              {{ $t('taxCategoriesList.modal.errorDelete.actions.close') }}
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ExportXlsMixin, { formatRow } from '@/mixins/export-xls-mixin';
import { DateTime } from 'luxon';
import XLSX from 'xlsx';
import {getDateFormatFromUserConfig, getTimeFormatFromUserConfig} from "@/helpers/date.helpers";

export default {
  name: 'TaxCategoryDeleteErrorModal',
  components: {},
  mixins: [ExportXlsMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    currentTaxCategory: {
      type: Object,
      default: () => null
    },
    body: {
      type: String,
      default: ''
    },
    fileIdentifier: {
      type: String,
      default: 'data'
    },
    fileName: {
      type: String,
      default: 'export'
    },
    locationName: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    show(value) {
      this.showDeleteModal = value;
    }
  },
  data() {
    return {
      showDeleteModal: false,
      showList: false
    };
  },
  computed: {},
  methods: {
    download() {
      const columns = [
        {
          key: 'name',
          title: '',
          width: 160
        }
      ];
      const rows = (this.list || []).map(e => [{ key: 'name', value: e.name }]);

      const totalRow = [];
      const fileName = this.$t('taxCategoriesList.modal.errorDelete.exportFileName', {
        fileName: this.fileName,
        name: this.fileIdentifier
      });

      const locationName = this.locationName;

      const extension = 'xlsx';
      const header = columns.map(a => ({ key: a.key, value: a.title }));

      const metasSpace = [[], [], [], [], [], [], [], [], [], []];
      const data = [...metasSpace, [...header], ...rows, ...totalRow].map(row => formatRow(columns, row));

      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: true });

      workBook.Props = {
        Title: fileName,
        CreatedDate: new Date()
      };

      const ws_data = [
        [],
        [],
        [this.$t('taxCategoriesList.modal.errorDelete.title', { name: this.currentTaxCategory.name })],
        [],
        [this.$t('employees.header.locationName'), [locationName]],
        [],
        [
          [this.$t('taxCategoriesList.modal.errorDelete.exportDate')],
          DateTime.fromJSDate(new Date()).toFormat(getDateFormatFromUserConfig()),
          [this.$t('taxCategoriesList.modal.errorDelete.exportTime')],
          DateTime.fromJSDate(new Date()).toFormat(getTimeFormatFromUserConfig())
        ],
        [],
        [this.$t('taxCategoriesList.modal.errorDelete.subTitle')]
      ];

      XLSX.utils.sheet_add_aoa(workSheet, ws_data);

      workSheet['!merges'] = [
        { s: { c: 0, r: 2 }, e: { c: 8, r: 2 } },
        { s: { c: 1, r: 4 }, e: { c: 8, r: 4 } },
        { s: { c: 0, r: 8 }, e: { c: 8, r: 8 } }
      ];

      XLSX.utils.book_append_sheet(workBook, workSheet, this.$t('taxCategoriesList.list.title'));
      workSheet['!cols'] = this.fitToColumn(
        columns.map(col => col.title),
        data
      ); // Will only work on XLSX

      XLSX.writeFile(workBook, `${fileName}.${extension}`);
    }
  }
};
</script>
<style lang="scss" scoped>
/* stylelint-disable */

.tax-categories-list {
  padding-left: 1rem;

  ol {
    columns: unset !important;
  }

  div {
    display: inline-block;
    font-size: 0.86rem;
    width: 50%;
  }
}

.modal-body {
  button {
    text-transform: none;
  }
}
</style>
