var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "status",
      class: {
        online: this.status === 0,
        warning: this.status === 1,
        offline: this.status === 2
      }
    },
    [
      _c("div", { staticClass: "bubble" }),
      _c("div", [
        _vm._v(_vm._s(_vm.$t("locationState.states[" + this.status + "]")))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }