import './plugins/elementui';
import './plugins/iview';
import './plugins/portal';
import './plugins/media-query';
import './plugins/resize';
import './plugins/vuebar';
import 'vue-resize/dist/vue-resize.css';

import { pinia } from '@/pinia';

import 'vue-toast-notification/dist/theme-sugar.css';
import './app.scss';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { PiniaVuePlugin, createPinia } from 'pinia';
import VueMask, { VueMaskFilter } from 'v-mask';
import App from './App.vue';
import AwesomeMask from 'awesome-mask';
import { faArrowDown, faArrowUp, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Multiselect from 'vue-multiselect';
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import VueToast from 'vue-toast-notification';
import i18n from './plugins/i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import router from './router';
import { showDirective } from '@/directives/show';
import { useRouteStore } from '@/stores/route.module';

const ignoreErrors = {
  "Cannot read properties of undefined (reading 'getBoundingClientRect')": true,
  "Cannot read properties of undefined (reading 'trace')": true,
  "Cannot read properties of undefined (reading 'op')": true,
  "Cannot read properties of undefined (reading 'querySelectorAll')": true
};

Vue.config.errorHandler = function (err) {
  if (ignoreErrors[err.message]) {
    console.warn(err.message);
  } else {
    throw err;
  }
};

Vue.use(PiniaVuePlugin);

Vue.config.productionTip = false;
Vue.component('multiselect', Multiselect);

library.add(faCalendarAlt);
library.add(faArrowUp);
library.add(faArrowDown);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueAnalytics, {
  id: 'UA-119828770-2',
  router
});

Vue.use(BootstrapVue);
Vue.use(VueToast);
Vue.use(IconsPlugin);
Vue.use(VueMask);
Vue.directive('mask', AwesomeMask);
Vue.filter('VMask', VueMaskFilter);

library.add(faCalendarAlt);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(VueToast);
Vue.use(IconsPlugin);
Vue.use(VueMask);
Vue.directive('mask', AwesomeMask);
Vue.filter('VMask', VueMaskFilter);

Vue.directive('can', showDirective);

const app = new Vue({
  pinia,
  router,
  i18n,
  render: h => h(App)
});

router.afterEach((to, from) => {
  const routeStore = useRouteStore(pinia);
  routeStore.routeChanged({ to, from });
  // store.dispatch('routeChanged', { to, from }, { root: true });
});

router.onReady(() => {
  app.$mount('#app');
});
