var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-table", {
    attrs: {
      rows: _vm.rows,
      columns: _vm.columns,
      loading: _vm.loading,
      hoverable: true,
      defaultDirection: "asc",
      defaultSort: "location"
    },
    scopedSlots: _vm._u([
      {
        key: "empty",
        fn: function() {
          return [_vm._v(" " + _vm._s(_vm.$t("users.errors.noData")) + " ")]
        },
        proxy: true
      },
      {
        key: "location-column",
        fn: function(scope) {
          return [
            scope.cell.value
              ? _c(
                  "div",
                  { staticClass: "location__name" },
                  [
                    _c("vel-avatar", {
                      staticClass: "location__name-avatar",
                      attrs: { fullname: scope.cell.value.name }
                    }),
                    _c("span", { staticClass: "location__name-text" }, [
                      _vm._v(_vm._s(scope.cell.value.name))
                    ])
                  ],
                  1
                )
              : _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("user.permissions.table.values.allLocations")
                      ) +
                      " "
                  )
                ])
          ]
        }
      },
      {
        key: "group-column",
        fn: function(scope) {
          return [
            scope.cell.value
              ? _c(
                  "div",
                  { staticClass: "group__name" },
                  [
                    _c("vel-avatar", {
                      staticClass: "group__name-avatar",
                      attrs: { fullname: scope.cell.value.name }
                    }),
                    _c("span", { staticClass: "group__name-text" }, [
                      _vm._v(_vm._s(scope.cell.value.name))
                    ])
                  ],
                  1
                )
              : _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("user.permissions.table.values.allGroups")
                      ) +
                      " "
                  )
                ])
          ]
        }
      },
      {
        key: "actions-column",
        fn: function(scope) {
          return [
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c("vel-button", {
                  attrs: {
                    type: "link",
                    disabled: _vm.disableActions,
                    icon: "edit"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleEdit(_vm.permissions[scope.rowIndex])
                    }
                  }
                }),
                _c("vel-button", {
                  attrs: {
                    type: "link",
                    disabled: _vm.disableActions,
                    icon: "delete"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleDelete(_vm.permissions[scope.rowIndex])
                    }
                  }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }