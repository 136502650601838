var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _c(
      "div",
      { staticClass: "filters__fields" },
      [
        _c(
          "b-form-group",
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("checkDetails.filters.dateRange.label")))
            ]),
            _c("date-range-selector", {
              staticClass: "report__date-range-selector",
              attrs: {
                "data-invalid": _vm.dateRangeInvalid,
                range: _vm.selectedDateRange,
                disabled: _vm.disabled || _vm.loading,
                format: _vm.getDateFormat,
                clearable: true
              },
              on: {
                "update:range": function($event) {
                  _vm.selectedDateRange = $event
                },
                change: _vm.onDateRangeChange
              }
            })
          ],
          1
        ),
        _c(
          "b-form-group",
          {
            attrs: {
              "invalid-feedback": _vm.$t("checkDetails.filters.check.invalid"),
              state: _vm.invoiceNumberValueValid
            }
          },
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("checkDetails.filters.check.label")))
            ]),
            _c(
              "b-input-group",
              [
                _c("b-input-group-prepend", [
                  _c(
                    "span",
                    { staticClass: "input-group-text" },
                    [_c("b-icon", { attrs: { icon: "search" } })],
                    1
                  )
                ]),
                _c("b-form-input", {
                  attrs: {
                    disabled: _vm.disabled || _vm.loading,
                    size: "sm",
                    placeholder: _vm.$t(
                      "checkDetails.filters.check.placeholder"
                    ),
                    state: _vm.invoiceNumberValueValid ? null : false,
                    debounce: "500"
                  },
                  on: { input: _vm.invoiceNumberRangeChanged },
                  model: {
                    value: _vm.selectedFilters.invoiceNumberValue,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedFilters, "invoiceNumberValue", $$v)
                    },
                    expression: "selectedFilters.invoiceNumberValue"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-form-group",
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("checkDetails.filters.tenderTypes.label")))
            ]),
            _c("vel-multiple-selector", {
              attrs: {
                "value-options": this.getFilterKeys(this.filters.tenderTypes),
                multiple: true,
                filterable: true,
                disabled: _vm.disabled || _vm.loading,
                "all-results-label": "checkDetails.filters.tenderTypes.all",
                "multiples-results-label":
                  "checkDetails.filters.tenderTypes.filtered"
              },
              model: {
                value: _vm.selectedFilters.tenderTypes,
                callback: function($$v) {
                  _vm.$set(_vm.selectedFilters, "tenderTypes", $$v)
                },
                expression: "selectedFilters.tenderTypes"
              }
            })
          ],
          1
        ),
        _c(
          "b-form-group",
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("checkDetails.filters.departments.label")))
            ]),
            _c("vel-multiple-selector", {
              attrs: {
                "value-options": this.getFilterKeys(this.filters.departments),
                multiple: true,
                filterable: true,
                disabled: _vm.disabled || _vm.loading,
                "all-results-label": "checkDetails.filters.departments.all",
                "multiples-results-label":
                  "checkDetails.filters.departments.filtered"
              },
              model: {
                value: _vm.selectedFilters.departments,
                callback: function($$v) {
                  _vm.$set(_vm.selectedFilters, "departments", $$v)
                },
                expression: "selectedFilters.departments"
              }
            })
          ],
          1
        ),
        _c(
          "b-form-group",
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("checkDetails.filters.workstations.label")))
            ]),
            _c("vel-multiple-selector", {
              attrs: {
                "value-options": this.getFilterKeys(this.filters.workstations),
                multiple: true,
                filterable: true,
                disabled: _vm.disabled || _vm.loading,
                "all-results-label": "checkDetails.filters.workstations.all",
                "multiples-results-label":
                  "checkDetails.filters.workstations.filtered"
              },
              model: {
                value: _vm.selectedFilters.workstations,
                callback: function($$v) {
                  _vm.$set(_vm.selectedFilters, "workstations", $$v)
                },
                expression: "selectedFilters.workstations"
              }
            })
          ],
          1
        ),
        _c(
          "b-form-group",
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("checkDetails.filters.discounts.label")))
            ]),
            _c("vel-multiple-selector", {
              attrs: {
                "value-options": this.getFilterKeys(this.filters.discounts),
                multiple: true,
                filterable: true,
                disabled: _vm.disabled || _vm.loading,
                "all-results-label": "checkDetails.filters.discounts.all",
                "multiples-results-label":
                  "checkDetails.filters.discounts.filtered"
              },
              model: {
                value: _vm.selectedFilters.discounts,
                callback: function($$v) {
                  _vm.$set(_vm.selectedFilters, "discounts", $$v)
                },
                expression: "selectedFilters.discounts"
              }
            })
          ],
          1
        ),
        _c(
          "b-form-group",
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("checkDetails.filters.status.label")))
            ]),
            _c("vel-multiple-selector", {
              attrs: {
                "value-options": this.statusList,
                multiple: false,
                filterable: false,
                disabled: _vm.disabled || _vm.loading,
                "all-results-label": "checkDetails.filters.status.all",
                "multiples-results-label":
                  "checkDetails.filters.status.filtered"
              },
              model: {
                value: _vm.selectedFilters.status,
                callback: function($$v) {
                  _vm.$set(_vm.selectedFilters, "status", $$v)
                },
                expression: "selectedFilters.status"
              }
            })
          ],
          1
        ),
        _c(
          "b-form-group",
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("checkDetails.filters.employee.label")))
            ]),
            _c(
              "b-input-group",
              [
                _c("b-input-group-prepend", [
                  _c(
                    "span",
                    { staticClass: "input-group-text" },
                    [_c("b-icon", { attrs: { icon: "search" } })],
                    1
                  )
                ]),
                _c("b-form-input", {
                  attrs: {
                    disabled: _vm.disabled || _vm.loading,
                    size: "sm",
                    max: "10",
                    placeholder: _vm.$t(
                      "checkDetails.filters.employee.placeholder"
                    )
                  },
                  model: {
                    value: _vm.selectedFilters.employeeName,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedFilters, "employeeName", $$v)
                    },
                    expression: "selectedFilters.employeeName"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-form-group",
          [
            _c("label", [
              _vm._v(
                _vm._s(_vm.$t("checkDetails.filters.employeeNumber.label"))
              )
            ]),
            _c(
              "b-input-group",
              [
                _c("b-input-group-prepend", [
                  _c(
                    "span",
                    { staticClass: "input-group-text" },
                    [_c("b-icon", { attrs: { icon: "search" } })],
                    1
                  )
                ]),
                _c("b-form-input", {
                  attrs: {
                    formatter: _vm.formatNumber,
                    disabled: _vm.disabled || _vm.loading,
                    size: "sm",
                    max: "10",
                    number: "",
                    step: "1",
                    min: "0",
                    placeholder: _vm.$t(
                      "checkDetails.filters.employeeNumber.placeholder"
                    )
                  },
                  model: {
                    value: _vm.selectedFilters.employeeNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedFilters, "employeeNumber", $$v)
                    },
                    expression: "selectedFilters.employeeNumber"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "filters__buttons" },
      [
        _c(
          "span",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.top.hover",
                modifiers: { top: true, hover: true }
              }
            ],
            staticClass: "generate d-flex",
            attrs: { title: this.searchPopupLabel }
          },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "primary", disabled: _vm.disableApply },
                on: { click: _vm.applyFilters }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("checkDetails.filters.search")) + " "
                )
              ]
            )
          ],
          1
        ),
        _c(
          "b-button",
          {
            attrs: {
              variant: "secondary",
              disabled: _vm.disabled || _vm.loading
            },
            on: { click: _vm.clearFilters }
          },
          [_vm._v(" " + _vm._s(_vm.$t("checkDetails.filters.clear")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }