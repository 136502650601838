var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page bootstrap",
      attrs: {
        enableToolbar: false,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        scroll: false,
        loading: _vm.isLoading
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "b-container",
                { staticClass: "mb-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "d-flex flex-column flex-md-row pt-3" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("b-breadcrumb", {
                            attrs: { items: _vm.breadcrumbs }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-between",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.$t("bigDepartments.title")))
                          ]),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName:
                                    "v-b-toggle.bigDepartmentForm-sidebar",
                                  modifiers: {
                                    "bigDepartmentForm-sidebar": true
                                  }
                                }
                              ],
                              staticClass:
                                "ml-4 d-flex align-items-center d-md-none",
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.bigDepartmentEdit = { name: "" }
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "plus",
                                  "aria-hidden": "true",
                                  scale: "1.4"
                                }
                              }),
                              _c("span", { staticClass: "d-none d-md-block" }, [
                                _vm._v(_vm._s(_vm.$t("bigDepartments.add-new")))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex mt-4 mt-md-0 flex-row justify-content-md-end flex-md-row",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              staticClass: "align-content-end align-self-end",
                              staticStyle: { "max-width": "250px" }
                            },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "search" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  debounce: "1",
                                  type: "text",
                                  placeholder: _vm.$t("bigDepartments.search")
                                },
                                model: {
                                  value: _vm.filter,
                                  callback: function($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName:
                                    "v-b-toggle.bigDepartmentForm-sidebar",
                                  modifiers: {
                                    "bigDepartmentForm-sidebar": true
                                  }
                                }
                              ],
                              staticClass:
                                "ml-4 d-md-flex align-items-center d-none",
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.bigDepartmentEdit = { name: "" }
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "plus",
                                  "aria-hidden": "true",
                                  scale: "1.4"
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("bigDepartments.add-new")) +
                                  " "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-container",
        { staticClass: "h-100", attrs: { fluid: "" } },
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              striped: "",
              hover: "",
              bordered: "",
              "show-empty": "",
              fixed: "",
              "primary-key": "id",
              "empty-text": _vm.$t("defaults.empty"),
              "empty-filtered-text": _vm.$t("defaults.empty"),
              busy: _vm.isLoading,
              fields: _vm.fields,
              "head-variant": "info",
              items: _vm.bigDepartments,
              filter: _vm.filter,
              responsive: "sm",
              "sort-by": "name",
              "sticky-header": ""
            },
            scopedSlots: _vm._u([
              {
                key: "empty",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "p-2" }, [
                      _vm._v(_vm._s(scope.emptyText))
                    ])
                  ]
                }
              },
              {
                key: "emptyfiltered",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "p-2" }, [
                      _vm._v(_vm._s(scope.emptyFilteredText))
                    ])
                  ]
                }
              },
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle p-2 m-4",
                          attrs: { variant: "primary" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(name)",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 justify-content-between d-flex align-items-center"
                      },
                      [_vm._v(" " + _vm._s(scope.item.name) + " ")]
                    )
                  ]
                }
              },
              {
                key: "cell(edit)",
                fn: function(scope) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle.bigDepartmentForm-sidebar",
                            modifiers: { "bigDepartmentForm-sidebar": true }
                          }
                        ],
                        attrs: { size: "sm", variant: "link" },
                        on: {
                          click: function($event) {
                            return _vm.onClickEdit(scope.item)
                          }
                        }
                      },
                      [
                        _c("b-icon", {
                          staticClass: "row-action text-primary",
                          attrs: { icon: "pencil-fill", "aria-label": "Edit" }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("bigDepartments.table.edit")) +
                            " "
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(delete)",
                fn: function(scope) {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "p-0 text-danger d-flex align-items-center",
                        attrs: { size: "sm", variant: "link" },
                        on: {
                          click: function($event) {
                            return _vm.onClickDelete(scope.item)
                          },
                          mouseover: function($event) {
                            _vm.isDeleteHovering = scope.item.id
                          },
                          mouseout: function($event) {
                            _vm.isDeleteHovering = null
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            class: {
                              "material-icons-outlined":
                                _vm.isDeleteHovering !== scope.item.id,
                              "material-icons":
                                _vm.isDeleteHovering === scope.item.id
                            }
                          },
                          [_vm._v(" delete ")]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("bigDepartments.table.delete")) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("discardChange.title"),
            static: true,
            centered: true,
            "hide-header-close": true,
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          on: {
            hidden: function($event) {
              return _vm.$emit("onCancel", true)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c("b-container", { attrs: { fluid: "" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.continue")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right ml-2",
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                                _vm.bigDepartmentEdit = null
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.discard")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showDiscardChanges,
            callback: function($$v) {
              _vm.showDiscardChanges = $$v
            },
            expression: "showDiscardChanges"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("discardChange.body")) + " ")]
      ),
      _c("employee-delete-modal", {
        attrs: {
          title: _vm.$t("bigDepartments.modal.delete.header", _vm.deleteEntity),
          body: _vm.$t("bigDepartments.modal.delete.body", _vm.deleteEntity),
          show: !!_vm.deleteEntity
        },
        on: {
          onDelete: function($event) {
            return _vm.onDelete(_vm.deleteEntity)
          },
          onCancel: function($event) {
            _vm.deleteEntity = null
          }
        }
      }),
      _vm.bigDepartmentEdit
        ? _c("big-department-sidebar-form-big-department", {
            attrs: {
              bigDepartment: _vm.bigDepartmentEdit,
              "licence-number": _vm.currentLocation.licenceNumber
            },
            on: {
              "update:bigDepartment": function($event) {
                _vm.bigDepartmentEdit = $event
              },
              "update:big-department": function($event) {
                _vm.bigDepartmentEdit = $event
              },
              onCancel: _vm.onClickCancel,
              onSuccess: _vm.onSuccess
            }
          })
        : _vm._e(),
      _c("big-department-delete-error-modal", {
        attrs: {
          title: _vm.$t("bigDepartments.modal.errorDelete.header"),
          body: _vm.$t("bigDepartments.modal.errorDelete.body", {
            name: _vm.deleteEntity ? _vm.deleteEntity.name : "",
            count: _vm.linkedDepartments.length
          }),
          currentBigDepartment: _vm.deleteEntity,
          locationName: _vm.currentLocation ? _vm.currentLocation.name : "",
          fileName: _vm.fileName,
          fileIdentifier: _vm.fileIdentifier,
          list: _vm.linkedDepartments,
          show: !!_vm.error
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }