var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "thead",
    {
      staticClass: "vel-table-header",
      class: _vm.classes,
      attrs: { role: "rowgroup" }
    },
    [
      _c(
        "tr",
        { staticClass: "vel-table-header__row", attrs: { role: "row" } },
        _vm._l(_vm.computedColumns, function(column, index) {
          return _c(
            "th",
            {
              key: index,
              staticClass: "vel-table-header__cell",
              class: {
                "vel-table-header__cell_sortable": _vm.isSortable(column)
              },
              style: _vm.getCellStyles(column),
              attrs: {
                role: "columnheader",
                scope: "col",
                "aria-colindex": index + 1
              },
              on: {
                click: function($event) {
                  return _vm.handleClick(column)
                }
              }
            },
            [
              _vm._v(" " + _vm._s(column.title) + " "),
              _vm.isSortable(column)
                ? _c(
                    "div",
                    { staticClass: "vel-table-header__sort-indicator" },
                    [
                      _c("vel-icon", {
                        class:
                          "vel-table-header__sort-icon vel-table-header__sort" +
                          (_vm.hasSort(column.key) && _vm.hasDirection("asc")
                            ? "-icon_up"
                            : ""),
                        attrs: { name: "order-asc" }
                      }),
                      _c("vel-icon", {
                        class:
                          "\n            vel-table-header__sort-icon vel-table-header__sort" +
                          (_vm.hasSort(column.key) && _vm.hasDirection("desc")
                            ? "-icon_down"
                            : ""),
                        attrs: { name: "order-desc" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }