var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dismissable
    ? _c("transition", { attrs: { name: "slide-fade" } }, [
        _c("div", { staticClass: "vel-alert", class: _vm.classes }, [
          _c(
            "div",
            [
              _vm.level === "success"
                ? _c("vel-icon", { attrs: { name: "checkmark" } })
                : _vm._e(),
              _vm._t("default")
            ],
            2
          ),
          _c(
            "button",
            {
              staticClass: "vel-alert__dismiss",
              attrs: { "aria-hidden": "true" },
              on: { click: _vm.handleDismiss }
            },
            [_vm._v("×")]
          )
        ])
      ])
    : _c(
        "div",
        { staticClass: "vel-alert", class: _vm.classes },
        [
          _vm.level === "success"
            ? _c("vel-icon", { attrs: { name: "checkmark" } })
            : _vm._e(),
          _vm._t("default")
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }