import { render, staticRenderFns } from "./ItemProfileHeader.vue?vue&type=template&id=531bc475&scoped=true&"
import script from "./ItemProfileHeader.vue?vue&type=script&lang=js&"
export * from "./ItemProfileHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531bc475",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/runner/work/velweb/velweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('531bc475')) {
      api.createRecord('531bc475', component.options)
    } else {
      api.reload('531bc475', component.options)
    }
    module.hot.accept("./ItemProfileHeader.vue?vue&type=template&id=531bc475&scoped=true&", function () {
      api.rerender('531bc475', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/private/item-profile/components/ItemProfileHeader.vue"
export default component.exports