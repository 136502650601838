var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    { attrs: { "show-crumbs": false } },
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("configMenuPage.title")))
      ]),
      _c("template", { slot: "export" }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "vel-card",
            { attrs: { shadowed: "" } },
            [
              _c("template", { slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("configMenuPage.users.title")))
              ]),
              _c("router-link", { attrs: { to: { name: "users" } } }, [
                _vm._v(" " + _vm._s(_vm.$t("configMenuPage.users.users")) + " ")
              ]),
              _c("router-link", { attrs: { to: { name: "createUser" } } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("configMenuPage.users.createNew")) + " "
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }