var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "divisions-list",
      attrs: { loading: _vm.loading },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("divisionsList.title")) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "divisions-list-location-selector-wrapper" },
        [
          _c("vel-location-selector", {
            staticClass: "big-divisions-page-location-selector",
            attrs: {
              allLocationsLabel: _vm.$t(
                "dashboard.locationSelector.allLocationsText"
              ),
              locationId: _vm.locationId,
              locationsOptions: _vm.locations,
              disabled: _vm.getLocationsIsLoading
            },
            on: { change: _vm.onLocationChange }
          })
        ],
        1
      ),
      _c(
        "vel-card",
        [
          _c("vel-table", {
            staticClass: "divisions-table",
            attrs: {
              rows: _vm.rows,
              columns: _vm.columns,
              filterable: "",
              filterValue: _vm.filter,
              filterPlaceholder: _vm.$t("defaults.placeholder.filter"),
              showRowsLength: true,
              initialLength: _vm.selectTotal
            },
            on: { "filter-change": _vm.filterCallback },
            scopedSlots: _vm._u([
              {
                key: "actions-column",
                fn: function(scope) {
                  return [
                    _c("vel-button", {
                      attrs: { type: "link", icon: "edit" },
                      on: {
                        click: function($event) {
                          return _vm.handleGoToDivision(
                            scope.row.id,
                            scope.row.bigDivisionId
                          )
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }