var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "vel-spinner icon icon_spinner",
      class: _vm.classes,
      attrs: {
        viewBox: "0 0 32 32",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("circle", {
        staticClass: "icon__path",
        attrs: {
          id: "spinner-" + _vm.hash,
          fill: "none",
          "stroke-dasharray": "100.531",
          "stroke-width": "2",
          "stroke-linecap": "round",
          cx: "16",
          cy: "16",
          r: "14"
        }
      }),
      _c(
        "animate",
        _vm._b(
          {
            attrs: {
              attributeName: "stroke-dashoffset",
              calcMode: "spline",
              values: "100.5309649149; 25.1327412287; 100.5309649149",
              keyTimes: "0; 0.5; 1",
              keySplines: ".4 .1 0 .2; .3 .1 .5 0",
              dur: "1.5s",
              repeatCount: "indefinite"
            }
          },
          "animate",
          { "xlink:href": "#spinner-" + _vm.hash },
          false
        )
      ),
      _c(
        "animateTransform",
        _vm._b(
          {
            attrs: {
              attributeName: "transform",
              attributeType: "XML",
              type: "rotate",
              calcMode: "spline",
              values: "0 16 16; 135 16 16; 450 16 16",
              keyTimes: "0; 0.5; 1",
              keySplines: ".4 .1 0 .2; .3 .1 .5 0",
              dur: "1.5s",
              repeatCount: "indefinite"
            }
          },
          "animateTransform",
          { "xlink:href": "#spinner-" + _vm.hash },
          false
        )
      ),
      _c(
        "animateTransform",
        _vm._b(
          {
            attrs: {
              attributeName: "transform",
              attributeType: "XML",
              type: "rotate",
              additive: "sum",
              calcMode: "spline",
              from: "0 16 16",
              to: "270 16 16",
              keySplines: ".4 .1 0 .2; .3 .1 .5 0",
              dur: "1.5s",
              repeatCount: "indefinite"
            }
          },
          "animateTransform",
          { "xlink:href": "#spinner-" + _vm.hash },
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }