<template>
  <vel-page class="change-password-page">
    <template v-slot:title>{{ $t('changePassword.title') }}</template>
    <vel-card>
      <vel-modal :auto-dismiss="true" dismissable @dismiss="navigateToLogout" v-if="showAlert">
        <template v-slot:header>
          {{ $t('changePassword.title') }}
        </template>
        <template v-slot:default>{{ $t('changePassword.success') }}</template>
        <template v-slot:footer>
          <vel-button type="secondary" @click="navigateToLogout">{{ $t('defaults.actions.close') }}</vel-button>
        </template>
      </vel-modal>
      <change-password-form @submit="handleSubmit" />
    </vel-card>
  </vel-page>
</template>

<script>
import ChangePasswordForm from './components/ChangePasswordForm';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelModal from '@/components/modal/VelModal';
import VelPage from '@/components/page/VelPage';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataUsersChangePassword} from "@/stores/data/users/change-password.module";

export default {
  name: 'change-password-page',
  components: {
    ChangePasswordForm,
    VelButton,
    VelCard,
    VelModal,
    VelPage
  },
  computed: {
    ...mapPiniaState(useDataUsersChangePassword, ['selectTotal', 'loading']),
    showAlert() {
      return !!this.selectTotal;
    }
  },
  methods: {
    ...mapPiniaActions(useDataUsersChangePassword, ['changePassword']),
    handleSubmit(changePasswordForm) {
      this.changePassword(changePasswordForm);
    },
    navigateToLogout() {
      this.$router.push('/logout');
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';

.success {
  margin-bottom: rem-calc(50);
}
</style>
