var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        { staticClass: "mb-4", attrs: { show: _vm.error, variant: "danger" } },
        [_vm._v(" " + _vm._s(_vm.$t("employeeCreate.updateError")) + " ")]
      ),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.links.workstation")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-md-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.department"),
                  "label-for": "revenueCenterId"
                }
              },
              [
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    options: _vm.departments.concat([
                      {
                        remoteId: null,
                        name: _vm.$t("employeeCreate.emptyValue")
                      }
                    ]),
                    id: "revenueCenterId",
                    "value-field": "id",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.revenueCenterId,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "revenueCenterId", $$v)
                    },
                    expression: "employee.revenueCenterId"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.cashDrawer"),
                  "label-for": "cashDrawer"
                }
              },
              [
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    options: [
                      {
                        id: 1,
                        name: _vm.$t("employeeCreate.links.cashDrawerItem", {
                          number: 1
                        })
                      },
                      {
                        id: 2,
                        name: _vm.$t("employeeCreate.links.cashDrawerItem", {
                          number: 2
                        })
                      },
                      { id: 0, name: _vm.$t("employeeCreate.emptyValue") }
                    ],
                    id: "cashDrawer",
                    "value-field": "id",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.cashDrawer,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "cashDrawer", $$v)
                    },
                    expression: "employee.cashDrawer"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.redirectionTable"),
                  "label-for": "redirectionTableId"
                }
              },
              [
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    options: _vm.redirectionsTables.concat([
                      { id: null, name: _vm.$t("employeeCreate.emptyValue") }
                    ]),
                    id: "redirectionTableId",
                    "value-field": "id",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.redirectionTableId,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "redirectionTableId", $$v)
                    },
                    expression: "employee.redirectionTableId"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.pageNumber.label"),
                  "label-for": "pagerNumber"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    id: "pagerNumber",
                    maxlength: "49",
                    placeholder: _vm.$t(
                      "employeeCreate.links.pageNumber.placeHolder"
                    ),
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.pagerNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "pagerNumber", $$v)
                    },
                    expression: "employee.pagerNumber"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.owedFees"),
                  "label-for": "owedFees"
                }
              },
              [
                _c(
                  "b-input-group",
                  { attrs: { prepend: "$" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        f: "",
                        id: "owedFees",
                        "no-wheel": "",
                        "lazy-formatter": "",
                        formatter: _vm.formatAmount,
                        step: "0.01",
                        type: "number",
                        "aria-describedby":
                          "input-live-help input-live-feedback"
                      },
                      model: {
                        value: _vm.employee.owedFeesDelivery,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employee,
                            "owedFeesDelivery",
                            _vm._n($$v)
                          )
                        },
                        expression: "employee.owedFeesDelivery"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.alternateWorkstation"),
                  "label-for": "alternateWorkstationId"
                }
              },
              [
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    f: "",
                    options: _vm.workstations.concat([
                      { id: null, name: _vm.$t("employeeCreate.emptyValue") }
                    ]),
                    id: "alternateWorkstationId",
                    "value-field": "id",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.alternateWorkstationId,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "alternateWorkstationId", $$v)
                    },
                    expression: "employee.alternateWorkstationId"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" })
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.links.tableSection")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.tableSection"),
                  "label-for": "tableSectionId"
                }
              },
              [
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    f: "",
                    options: _vm.tablesSections.concat([
                      { id: null, name: _vm.$t("employeeCreate.emptyValue") }
                    ]),
                    id: "tableSectionId",
                    "value-field": "id",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.tableSectionId,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "tableSectionId", $$v)
                    },
                    expression: "employee.tableSectionId"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.floorPlans"),
                  "label-for": "floorPlans"
                }
              },
              [
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    f: "",
                    options: _vm.floorPlans.concat([
                      { id: null, name: _vm.$t("employeeCreate.emptyValue") }
                    ]),
                    id: "floorPlans",
                    "value-field": "id",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.floorPlanId,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "floorPlanId", $$v)
                    },
                    expression: "employee.floorPlanId"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" })
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.links.electronicBar")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-md-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.electronicBarKey.label"),
                  "label-for": "electronicBarKey"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    f: "",
                    id: "electronicBarKey",
                    maxlength: "40",
                    placeholder: _vm.$t(
                      "employeeCreate.links.electronicBarKey.placeHolder"
                    ),
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.electronicBarKey,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "electronicBarKey", $$v)
                    },
                    expression: "employee.electronicBarKey"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-md-4 justify-content-between"
          },
          [
            _c(
              "b-button-group",
              {
                staticClass: "mb-2",
                class: { "w-100": _vm.isMediumDown },
                attrs: { vertical: _vm.isMediumDown }
              },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "d-sm-block border-input",
                    attrs: {
                      variant:
                        _vm.employee.electronicBarType === 0
                          ? "primary"
                          : "white"
                    },
                    on: {
                      click: function($event) {
                        _vm.employee.electronicBarType = 0
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("employeeCreate.links.fixedSection")) +
                        " "
                    )
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "d-sm-block border-input",
                    attrs: {
                      variant:
                        _vm.employee.electronicBarType === 1
                          ? "primary"
                          : "white"
                    },
                    on: {
                      click: function($event) {
                        _vm.employee.electronicBarType = 1
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("employeeCreate.links.asPerWorkstation")
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "d-sm-block border-input",
                    attrs: {
                      variant:
                        _vm.employee.electronicBarType === 2
                          ? "primary"
                          : "white"
                    },
                    on: {
                      click: function($event) {
                        _vm.employee.electronicBarType = 2
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("employeeCreate.links.asPerRedirectionTable")
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-4 justify-content-between"
          },
          [
            _vm.employee.electronicBarType == 0
              ? _c(
                  "b-form-group",
                  {
                    staticClass: "mr-5 w-100 mb-2 mb-md-0",
                    attrs: {
                      label: _vm.$t(
                        "employeeCreate.links.electronicBarSectionNumber.label"
                      ),
                      "label-for": "electronicBarSectionNumber"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        f: "",
                        id: "electronicBarSectionNumber",
                        maxlength: "8",
                        placeholder: _vm.$t(
                          "employeeCreate.links.electronicBarSectionNumber.placeHolder"
                        ),
                        number: "",
                        "aria-describedby":
                          "input-live-help input-live-feedback"
                      },
                      model: {
                        value: _vm.employee.electronicBarSectionNumber,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employee,
                            "electronicBarSectionNumber",
                            $$v
                          )
                        },
                        expression: "employee.electronicBarSectionNumber"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" })
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.links.salesRecordingModule")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.username.label"),
                  "label-for": "srmUsername"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    f: "",
                    id: "srmUsername",
                    maxlength: "40",
                    placeholder: _vm.$t(
                      "employeeCreate.links.username.placeHolder"
                    ),
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.srmUsername,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "srmUsername", $$v)
                    },
                    expression: "employee.srmUsername"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.password.label"),
                  "label-for": "srmPassword"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    f: "",
                    id: "srmPassword",
                    maxlength: "40",
                    placeholder: _vm.$t(
                      "employeeCreate.links.password.placeHolder"
                    ),
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.srmPassword,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "srmPassword", $$v)
                    },
                    expression: "employee.srmPassword"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" })
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.links.inventory")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-md-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.functionLocation"),
                  "label-for": "inventoryLocalisationId"
                }
              },
              [
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    f: "",
                    options: _vm.inventoryLocalisations.concat([
                      { id: null, name: _vm.$t("employeeCreate.emptyValue") }
                    ]),
                    id: "inventoryLocalisationId",
                    "value-field": "id",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.inventoryLocalisationId,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "inventoryLocalisationId", $$v)
                    },
                    expression: "employee.inventoryLocalisationId"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.links.supplier"),
                  "label-for": "supplierId"
                }
              },
              [
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    f: "",
                    options: _vm.suppliers.concat([
                      { id: null, name: _vm.$t("employeeCreate.emptyValue") }
                    ]),
                    id: "supplierId",
                    "value-field": "id",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.supplierId,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "supplierId", $$v)
                    },
                    expression: "employee.supplierId"
                  }
                })
              ],
              1
            ),
            _c(
              "b-checkbox",
              {
                staticClass:
                  "mr-md-5 ml-md-5 w-100 mb-2 mb-md-0 pt-md-4 justify-content-center align-self-center",
                model: {
                  value: _vm.employee.taxableFees,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "taxableFees", $$v)
                  },
                  expression: "employee.taxableFees"
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("employeeCreate.links.taxables")) + " "
                )
              ]
            ),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }