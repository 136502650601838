var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        { staticClass: "mb-4", attrs: { show: _vm.error, variant: "danger" } },
        [_vm._v(" " + _vm._s(_vm.$t("employeeCreate.updateError")) + " ")]
      ),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.jobRole.posLayout")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.jobRole.screenLayout.title"),
                  "label-for": "name"
                }
              },
              [
                _c(
                  "b-button-group",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "border-input",
                        attrs: {
                          variant: !this.employee.isRightHanded
                            ? "primary"
                            : "white",
                          disabled: _vm.loading
                        },
                        on: {
                          click: function($event) {
                            _vm.employee.isRightHanded = false
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("employeeCreate.jobRole.screenLayout.left")
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "border-input",
                        attrs: {
                          variant: this.employee.isRightHanded
                            ? "primary"
                            : "white",
                          disabled: _vm.loading
                        },
                        on: {
                          click: function($event) {
                            _vm.employee.isRightHanded = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "employeeCreate.jobRole.screenLayout.right"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.jobRole.payroll")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.jobRole.payrollCode"),
                  "label-for": "payrollCode"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    id: "payrollCode",
                    maxlength: "8",
                    type: "number",
                    number: "",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.payrollCode,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "payrollCode", _vm._n($$v))
                    },
                    expression: "employee.payrollCode"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: { label: _vm.$t("employeeCreate.jobRole.deduction") }
              },
              [
                _c(
                  "b-input-group",
                  { attrs: { prepend: "$" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "deduction",
                        "no-wheel": "",
                        "lazy-formatter": "",
                        formatter: _vm.formatAmount,
                        step: "0.01",
                        type: "number",
                        "aria-describedby":
                          "input-live-help input-live-feedback"
                      },
                      on: { update: _vm.update },
                      model: {
                        value: _vm.employee.deduction,
                        callback: function($$v) {
                          _vm.$set(_vm.employee, "deduction", _vm._n($$v))
                        },
                        expression: "employee.deduction"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-form-group",
              { staticClass: "mr-5 w-100 mb-2 mb-md-0" },
              [
                _c(
                  "label",
                  {
                    staticClass: "d-flex align-items-center",
                    attrs: { for: "mealPlan" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("employeeCreate.mealPlan.title")) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle.mealPlan-sidebar",
                            modifiers: { "mealPlan-sidebar": true }
                          }
                        ],
                        staticClass:
                          "material-icons mr-1 pointer text-primary medium ml-2"
                      },
                      [_vm._v(" create ")]
                    )
                  ]
                ),
                _c("b-form-select", {
                  staticClass: "form-text",
                  attrs: {
                    options: _vm.mealPlans.concat([
                      { id: null, name: _vm.$t("employeeCreate.emptyValue") }
                    ]),
                    id: "test",
                    "value-field": "id",
                    name: "mealPlan",
                    "text-field": "name",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.mealPlanId,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "mealPlanId", $$v)
                    },
                    expression: "employee.mealPlanId"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t("employeeCreate.jobRole.fringe"),
                  "label-for": "payrollCode"
                }
              },
              [
                _c(
                  "b-input-group",
                  { attrs: { prepend: "$" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "name",
                        "no-wheel": "",
                        "lazy-formatter": "",
                        formatter: _vm.formatAmount,
                        step: "0.01",
                        type: "number",
                        "aria-describedby":
                          "input-live-help input-live-feedback"
                      },
                      on: { update: _vm.update },
                      model: {
                        value: _vm.employee.fringeBenefit,
                        callback: function($$v) {
                          _vm.$set(_vm.employee, "fringeBenefit", $$v)
                        },
                        expression: "employee.fringeBenefit"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.jobRole.roleConfig")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          { staticClass: "mb-md-2 px-3" },
          [
            _c("employee-delete-modal", {
              attrs: {
                title: _vm.$t("employeeCreate.jobRole.deleteModal.title"),
                body: _vm.$t("employeeCreate.jobRole.deleteModal.body"),
                show: _vm.showDelete !== null
              },
              on: {
                onDelete: function($event) {
                  return _vm.onDelete(_vm.showDelete)
                },
                onCancel: function($event) {
                  _vm.showDelete = null
                }
              }
            }),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass: "border mb-4",
                    attrs: { cols: "12", md: "2" }
                  },
                  [
                    _c(
                      "b-row",
                      { staticClass: "border-bottom head rgh" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "border-right align-center d-flex align-items-center py-3"
                          },
                          [_vm._v(" Description ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "rgh" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "py-2 d-flex align-items-center border-right"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("employeeCreate.jobRole.title")) +
                                " "
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.accessTypes-sidebar",
                                    modifiers: { "accessTypes-sidebar": true }
                                  }
                                ],
                                staticClass:
                                  "material-icons mr-1 pointer text-primary medium ml-2"
                              },
                              [_vm._v(" create ")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("h6", { staticClass: "mt-2 mb-0" }, [
                      _vm._v(_vm._s(_vm.$t("employeeCreate.jobRole.payInfo")))
                    ]),
                    _c(
                      "b-row",
                      { staticClass: "rgh" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "py-2 d-flex align-items-center border-right"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("employeeCreate.timeAttendance.title")
                                ) +
                                " "
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName:
                                      "v-b-toggle.timeAttendance-sidebar",
                                    modifiers: {
                                      "timeAttendance-sidebar": true
                                    }
                                  }
                                ],
                                staticClass:
                                  "material-icons mr-1 pointer text-primary medium ml-2"
                              },
                              [_vm._v(" create ")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "rgh" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "py-2 d-flex align-items-center border-right"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("employeeCreate.jobRole.regularPay")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "rgh" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "py-2 d-flex align-items-center border-right"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("employeeCreate.jobRole.overtimePay")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "rgh" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "py-2 d-flex align-items-center border-right"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("employeeCreate.tipDistribution.title")
                                ) +
                                " "
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName:
                                      "v-b-toggle.tipDistribution-sidebar",
                                    modifiers: {
                                      "tipDistribution-sidebar": true
                                    }
                                  }
                                ],
                                staticClass:
                                  "material-icons mr-1 pointer text-primary medium ml-2"
                              },
                              [_vm._v(" create ")]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._l(_vm.employee.jobRoles || [], function(
                  userJobRole,
                  index
                ) {
                  return _c(
                    "b-col",
                    {
                      key: index,
                      class: {
                        deleted: userJobRole.delete === true,
                        border: true,
                        "mb-4": true
                      },
                      attrs: { cols: "12", md: "2" }
                    },
                    [
                      userJobRole.delete !== true
                        ? [
                            _c(
                              "b-row",
                              { staticClass: "border-bottom head rgh" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "text-center border-right justify-content-center align-center d-flex align-items-center"
                                  },
                                  [
                                    (_vm.employee.jobRoles || []).filter(
                                      function(jr) {
                                        return jr.delete !== true
                                      }
                                    ).length > 1
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "text-danger d-flex align-items-center text-decoration-none font-weight-bold",
                                            attrs: { variant: "link" },
                                            on: {
                                              click: function($event) {
                                                _vm.showDelete = index
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "material-icons-outlined mr-1"
                                              },
                                              [_vm._v("delete")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "employeeCreate.jobRole.delete"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "rgh" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "text-left d-flex flex-column justify-content-end"
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass: "form-text",
                                      class: {
                                        required: !_vm.employee.jobRoles[index]
                                          .functionId
                                      },
                                      attrs: {
                                        options: _vm.accessTypes.concat([
                                          {
                                            id: null,
                                            name: _vm.$t(
                                              "employeeCreate.emptyValue"
                                            )
                                          }
                                        ]),
                                        id: "test",
                                        "value-field": "id",
                                        "text-field": "name",
                                        "aria-describedby":
                                          "input-live-help input-live-feedback"
                                      },
                                      model: {
                                        value:
                                          _vm.employee.jobRoles[index]
                                            .functionId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.employee.jobRoles[index],
                                            "functionId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "employee.jobRoles[index].functionId"
                                      }
                                    }),
                                    _c(
                                      "b-form-text",
                                      { attrs: { id: "input-live-help" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("employeeCreate.required")
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      { attrs: { id: "input-live-feedback" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("employeeCreate.required")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "h5",
                              {
                                staticClass: "mt-2 mb-0",
                                staticStyle: { visibility: "hidden" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("employeeCreate.jobRole.payInfo")
                                  )
                                )
                              ]
                            ),
                            _c(
                              "b-row",
                              { staticClass: "rgh" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "text-left d-flex flex-column justify-content-center"
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass: "form-text",
                                      attrs: {
                                        options: _vm.timeAttendances.concat([
                                          {
                                            id: null,
                                            name: _vm.$t(
                                              "employeeCreate.emptyValue"
                                            )
                                          }
                                        ]),
                                        id: "test",
                                        "value-field": "id",
                                        "text-field": "name",
                                        "aria-describedby":
                                          "input-live-help input-live-feedback"
                                      },
                                      model: {
                                        value:
                                          _vm.employee.jobRoles[index]
                                            .timeAttendanceDepartmentId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.employee.jobRoles[index],
                                            "timeAttendanceDepartmentId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "employee.jobRoles[index].timeAttendanceDepartmentId"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "rgh" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "text-left d-flex flex-column justify-content-center"
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { attrs: { prepend: "$" } },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "name",
                                            step: "0.01",
                                            type: "number",
                                            "lazy-formatter": "",
                                            "no-wheel": "",
                                            formatter: _vm.formatAmount,
                                            "aria-describedby":
                                              "input-live-help input-live-feedback"
                                          },
                                          model: {
                                            value:
                                              _vm.employee.jobRoles[index]
                                                .regularPay,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.employee.jobRoles[index],
                                                "regularPay",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "employee.jobRoles[index].regularPay"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "rgh" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "text-left d-flex flex-column justify-content-center"
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { attrs: { prepend: "$" } },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "name",
                                            step: "0.01",
                                            "lazy-formatter": "",
                                            "no-wheel": "",
                                            formatter: _vm.formatAmount,
                                            type: "number",
                                            "aria-describedby":
                                              "input-live-help input-live-feedback"
                                          },
                                          model: {
                                            value:
                                              _vm.employee.jobRoles[index]
                                                .overtimePay,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.employee.jobRoles[index],
                                                "overtimePay",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "employee.jobRoles[index].overtimePay"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "rgh" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "text-left d-flex flex-column justify-content-center"
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass: "form-text",
                                      attrs: {
                                        options: _vm.tipDistributions.concat([
                                          {
                                            id: null,
                                            name: _vm.$t(
                                              "employeeCreate.emptyValue"
                                            )
                                          }
                                        ]),
                                        id: "test",
                                        "value-field": "id",
                                        "text-field": "name",
                                        "aria-describedby":
                                          "input-live-help input-live-feedback"
                                      },
                                      model: {
                                        value:
                                          _vm.employee.jobRoles[index]
                                            .tipDistributionId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.employee.jobRoles[index],
                                            "tipDistributionId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "employee.jobRoles[index].tipDistributionId"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                (_vm.employee.jobRoles || []).filter(function(jr) {
                  return jr.delete !== true
                }).length < 4
                  ? _c(
                      "b-col",
                      { staticClass: "mb-4", attrs: { cols: "12", md: "2" } },
                      [
                        _c(
                          "b-row",
                          { staticClass: "head rgh" },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass:
                                  "py-2 d-flex align-items-center align-center",
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "primary" },
                                    on: {
                                      click: function($event) {
                                        ;(_vm.employee.jobRoles || []).push({
                                          delete: false,
                                          functionId: null,
                                          timeAttendanceDepartmentId: null,
                                          tipDistributionId: null,
                                          function: {
                                            name: ""
                                          }
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _c("b-icon", { attrs: { icon: "plus" } }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("employeeCreate.jobRole.new")
                                        ) +
                                        " "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "rgh" },
                          [
                            _c("b-col", {
                              staticClass:
                                "text-left d-flex flex-column justify-content-end"
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "rgh" },
                          [
                            _c("b-col", {
                              staticClass:
                                "text-left d-flex flex-column justify-content-end"
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "rgh" },
                          [
                            _c("b-col", {
                              staticClass:
                                "text-left d-flex flex-column justify-content-end"
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "rgh" },
                          [
                            _c("b-col", {
                              staticClass:
                                "text-left d-flex flex-column justify-content-end"
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "rgh" },
                          [
                            _c("b-col", {
                              staticClass:
                                "text-left d-flex flex-column justify-content-end"
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "rgh" },
                          [
                            _c("b-col", {
                              staticClass:
                                "text-left d-flex flex-column justify-content-end"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }