var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    { staticClass: "changelog" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.lastCommitDate))]),
      _vm._l(_vm.merges, function(merge) {
        return _c("ul", { key: merge.id }, [
          _c("li", [_vm._v(_vm._s(merge.message))]),
          _c("li", [_vm._v(_vm._s(merge.date))]),
          _c("li", [_c("b", [_vm._v(_vm._s(merge.author))])])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }