var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "reseller-page",
      attrs: { loading: _vm.loading },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.getName || "") + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "vel-card",
        [
          _c("reseller-locations-list", {
            attrs: {
              resellerLocations: _vm.selectAll,
              selectTotal: _vm.selectTotal
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }