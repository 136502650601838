var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config_locations_display bootstrap" },
    _vm._l(_vm.list, function(opt, i) {
      return _c(
        "div",
        { key: opt.text },
        [
          _c("vel-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(opt.title))
          ]),
          _c(
            "draggable",
            {
              attrs: {
                group: i,
                options: { delayOnTouchOnly: true, delay: 800 }
              },
              on: { change: _vm.update },
              model: {
                value: opt.elements,
                callback: function($$v) {
                  _vm.$set(opt, "elements", $$v)
                },
                expression: "opt.elements"
              }
            },
            [
              _c(
                "transition-group",
                { attrs: { type: "transition", name: "flip-list" } },
                _vm._l(opt.elements, function(el) {
                  return _c(
                    "div",
                    {
                      key: el.text,
                      staticClass: "row my-2 mx-0 py-1 item",
                      staticStyle: {
                        border: "1px solid #dddddd",
                        "border-radius": "5px"
                      }
                    },
                    [
                      _vm.isAllowed(el.model)
                        ? _c(
                            "div",
                            { staticClass: "col-10 d-flex align-items-center" },
                            [
                              _c("span", { staticClass: "material-icons" }, [
                                _vm._v("drag_indicator")
                              ]),
                              _vm._v(" " + _vm._s(el.text) + " ")
                            ]
                          )
                        : _vm._e(),
                      _vm.isAllowed(el.model)
                        ? _c(
                            "div",
                            { staticClass: "col-2 text-right" },
                            [
                              _c("b-form-checkbox", {
                                attrs: { name: "check-button", switch: "" },
                                on: { change: _vm.update },
                                model: {
                                  value: _vm.config[el.model].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.config[el.model], "value", $$v)
                                  },
                                  expression: "config[el.model].value"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }