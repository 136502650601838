var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoadingUser && _vm.user
    ? _c(
        "vel-card",
        {
          staticClass: "user__edit",
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function() {
                  return [
                    _vm._v(" " + _vm._s(_vm.$t("user.info.editTitle")) + " ")
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2649152098
          )
        },
        [
          _vm.isError
            ? _c(
                "vel-alert",
                {
                  staticClass: "alert",
                  attrs: { level: "error" },
                  on: { dismiss: _vm.clearMessage }
                },
                [_vm._v(" " + _vm._s(_vm.$t("user.errors.patchUser")) + " ")]
              )
            : _vm._e(),
          _vm.isSuccess
            ? _c(
                "vel-alert",
                {
                  staticClass: "success",
                  attrs: { level: "success" },
                  on: { dismiss: _vm.clearMessage }
                },
                [_vm._v(" " + _vm._s(_vm.$t("user.success.patchUser")) + " ")]
              )
            : _vm._e(),
          _c("user-form", {
            attrs: {
              user: _vm.user,
              resellers: _vm.resellers,
              pending: _vm.isPending
            },
            on: { submit: _vm.submit }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }