<template>
  <div>
    <div class="bg-white p-4 mt-2">
      <b-form-group
        :label="this.$t('discount.edit.list.fields.couponItem')"
        :description="$t('itemProfile.required')"
        label-for="name"
        class="mr-5 w-100 mb-2 mb-md-0"
      >
        <b-form-select
          :options="[{ id: null, name: $t('itemProfile.emptyValue') }, ...items]"
          id="supplierId"
          value-field="itemId"
          text-field="name"
          aria-describedby="input-live-help input-live-feedback"
          class="form-text"
          :disabled="discountCoupon.editIndex >= 0"
          v-model="discountCoupon.itemId"
          :class="{ required: !discountCoupon || !discountCoupon.itemId || discountCoupon.itemId === '' }"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        :label="this.$t('discount.edit.list.fields.couponValue')"
        :description="$t('itemProfile.required')"
        label-for="name"
        class="mr-5 w-100 mb-2 mb-md-0 mt-4"
      >
        <b-form-input
          id="deduction"
          v-model.number="discountCoupon.couponValue"
          no-wheel
          lazy-formatter
          :formatter="formatAmount"
          step="0.01"
          type="number"
          aria-describedby="input-live-help input-live-feedback"
          :class="{
            required:
              !discountCoupon ||
              !discountCoupon.couponValue ||
              discountCoupon.couponValue === '' ||
              discountCoupon.couponValue === '0.00'
          }"
        ></b-form-input>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'discount-item-form',
  model: {},
  props: {
    items: {
      type: Array,
      default: () => []
    },
    discountCoupon: {
      type: Object,
      default: () => ({
        itemId: '',
        couponValue: 0
      })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      discountCouponOrig: null
    };
  },
  computed: {
    enableSaveChanges() {
      return (
        this.discountCoupon &&
        this.discountCoupon.itemId &&
        this.discountCoupon.itemId !== '' &&
        this.discountCoupon.couponValue &&
        this.discountCoupon.couponValue !== '0.00' &&
        JSON.stringify(this.discountCouponOrig) !== JSON.stringify(this.discountCoupon)
      );
    },
    haveChanges() {
      return JSON.stringify(this.discountCouponOrig) !== JSON.stringify(this.discountCoupon);
    }
  },
  methods: {
    formatAmount(value) {
      return Number(value).toFixed(2);
    },
    onChange(value) {
    },
    reset(value = null) {
      this.form = cloneDeep(
        value || {
          itemId: '',
          couponValue: 0
        }
      );
    }
  },
  mounted() {
    this.discountCouponOrig = cloneDeep(this.discountCoupon);
  }
};
</script>
<style lang="scss" scoped>
/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.form-group {
  max-width: 328px;
}
</style>
