<template>
  <vel-sales-report
    :displayedColumns="displayedColumns"
    :data="formatedTree"
    :areSalesLoading="areSalesLoading"
    :filterable="true"
    :external-export="true"
    :is-external-generating="generating"
    @exportXls="exportXLSX"
    class="item-sales-report w-100"
    @input="handleInput"
  />
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelSalesReport from '@/components/report/VelSalesReport';
import router from '@/router';
import salesService from '@/services/sales.service';


export default {
  name: 'item-sales-report',
  components: { VelSalesReport },
  mixins: [DeviceMixin],
  props: {
    sales: {
      type: Array,
      default() {
        return [];
      }
    },
    hiddenColumns: {
      type: Object,
      default() {
        return {};
      }
    },
    areSalesLoading: {
      type: Boolean,
      default: true
    },
    multipleLocation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      generating: false
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    getSalesWithPercent(sales) {
      const totalSales = (sales || []).reduce((result, s) => result + s.salesAmount, 0);
      return (sales || []).map(s => ({
        ...s,
        salesPercent: ((s.salesAmount / totalSales) * 100 || 0).toFixed(2),
        salesOverallPercent: ((s.salesAmount / this.salesOverall) * 100).toFixed(2),
        children: (s.children && this.getSalesWithPercent(s.children)) || null
      }));
    },
    async exportXLSX() {
      this.generating = true;
      const routeQuery = {
        ...router.currentRoute.query
      };
      await salesService.downloadSalesByEmployeeXlsx(routeQuery).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    }
  },
  computed: {
    salesOverall() {
      return (this.sales || []).reduce((cur, i) => cur + i.salesAmount, 0);
    },
    defaultEmptyMessage() {
      return this.$t('defaults.empty');
    },
    displayedColumns() {
      const columns = [];

      columns.push(this.$t('employees.header.singleLocation.name'));
      if (this.hiddenColumns.quantity) {
        columns.push(this.$t('employees.header.quantity'));
      } else {
        columns.push(null);
      }
      if (this.hiddenColumns.salesAmount) {
        columns.push(
          this.$t('employees.header.salesAmount', {
            currency: (this.sales && this.sales[0] && this.sales[0].currency) || '$'
          })
        );
      } else {
        columns.push(null);
      }
      if (this.hiddenColumns.salesPercent) {
        columns.push(this.$t('employees.header.salesPercent'));
      } else {
        columns.push(null);
      }

      if (this.hiddenColumns.salesPercentOverall) {
        columns.push(this.$t('employees.header.salesOverallPercent'));
      }
      return columns;
    },
    formatedTree() {
      return this.getSalesWithPercent(this.sales);
    }
  }
};
</script>
