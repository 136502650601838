var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "discountForm",
      staticClass: "discount-form",
      attrs: {
        rules: _vm.formRules,
        model: _vm.discountForm,
        submit: _vm.handleSubmit,
        "label-width": "150px",
        "label-position": _vm.labelPosition
      }
    },
    [
      _c(
        "vel-form-item",
        {
          staticClass: "discount-form-remote-id",
          attrs: {
            prop: "remoteId",
            label: _vm.$t("discountInfo.form.remoteId")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { disabled: "", type: "text" },
            model: {
              value: _vm.discountForm.remoteId,
              callback: function($$v) {
                _vm.$set(_vm.discountForm, "remoteId", $$v)
              },
              expression: "discountForm.remoteId"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "discount-form-name",
          attrs: { prop: "name", label: _vm.$t("discountInfo.form.name.label") }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { type: "text", maxlength: "23" },
            model: {
              value: _vm.discountForm.name,
              callback: function($$v) {
                _vm.$set(_vm.discountForm, "name", $$v)
              },
              expression: "discountForm.name"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "discount-form-alternative-name",
          attrs: {
            prop: "alternativeName",
            label: _vm.$t("discountInfo.form.alternativeName.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { type: "text", maxlength: "23" },
            model: {
              value: _vm.discountForm.alternativeName,
              callback: function($$v) {
                _vm.$set(_vm.discountForm, "alternativeName", $$v)
              },
              expression: "discountForm.alternativeName"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "discount-form-discount-type",
          attrs: {
            prop: "type",
            label: _vm.$t("discountInfo.form.discountType.label")
          }
        },
        [
          _c(
            "vel-select",
            {
              staticClass: "name-input",
              attrs: { type: "text" },
              on: { change: _vm.clearInvalidInputs },
              model: {
                value: _vm.discountForm.type,
                callback: function($$v) {
                  _vm.$set(_vm.discountForm, "type", $$v)
                },
                expression: "discountForm.type"
              }
            },
            _vm._l(_vm.types, function(type) {
              return _c("vel-option", {
                key: type.typeId,
                attrs: { label: type.name, value: type.typeId }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "discount-form-amount",
          attrs: {
            prop: "amount",
            label: _vm.$t("discountInfo.form.fixedAmount.label", {
              currency: "" + _vm.getCurrencySelectorSelected
            })
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { disabled: _vm.discountForm.type !== 0, type: "text" },
            on: { blur: _vm.formatInputs },
            model: {
              value: _vm.discountForm.amount,
              callback: function($$v) {
                _vm.$set(_vm.discountForm, "amount", $$v)
              },
              expression: "discountForm.amount"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "discount-form-rate",
          attrs: {
            prop: "rate",
            label: _vm.$t("discountInfo.form.fixedRate.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { disabled: _vm.discountForm.type !== 3, type: "text" },
            on: { blur: _vm.formatInputs },
            model: {
              value: _vm.discountForm.rate,
              callback: function($$v) {
                _vm.$set(_vm.discountForm, "rate", $$v)
              },
              expression: "discountForm.rate"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "discount-form-coupon-value",
          attrs: {
            prop: "couponValue",
            label: _vm.$t("discountInfo.form.couponValue.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { disabled: _vm.discountForm.type !== 2, type: "text" },
            on: { blur: _vm.formatInputs },
            model: {
              value: _vm.discountForm.couponValue,
              callback: function($$v) {
                _vm.$set(_vm.discountForm, "couponValue", $$v)
              },
              expression: "discountForm.couponValue"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "discount-form-availability",
          attrs: {
            prop: "availability",
            label: _vm.$t("discountInfo.form.availability.label")
          }
        },
        [
          _c("discount-availability", {
            attrs: { "schedule-by-modes": _vm.scheduleByModes }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        { staticClass: "discount-form__actions" },
        [
          _c(
            "vel-button",
            {
              staticClass: "actions__submit",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
          ),
          _c(
            "vel-button",
            {
              staticClass: "actions__cancel",
              attrs: { type: "secondary" },
              on: {
                click: function($event) {
                  return _vm.navigatePrevious()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }