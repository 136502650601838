import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import {addOne, createCollectionState, getSelectors, removeAll, setAll, setOne} from '@/helpers/stores.helpers';
import taxesService from "@/services/taxes.service";
import router from "@/router";

const { selectAll, selectAllFiltered } = getSelectors();

export const useDataTaxCategories = defineStore('taxCategories', {
  state: () => createCollectionState({
    filter: '',
    status: {},
    error: null
  }),
  getters: {
    getTaxCategories: selectAll,
    getTaxCategoriesRows: selectAllFiltered(['id', 'name']),
    isLoading: state => !!state.status.isLoading,
    success: state => !!state.status.success,
  },
  actions: {
    async fetchTaxCategories(showMessage = false) {
      try {
        this.getTaxCategoriesRequest();
        const taxCategories = await taxesService.getTaxCategories(getToken(), router.currentRoute.query);
        this.getTaxCategoriesSuccess({ taxCategories, showMessage });
      } catch (error) {
        this.getTaxCategoriesFailure(error);
      }
    },
    async createTaxCategory(payload) {
      try {
        this.createTaxCategoryRequest();
        const taxCategory = await taxesService.createTaxCategory(getToken(), payload);
        this.createTaxCategorySuccess(taxCategory);
        this.getTaxCategories(true);
      } catch (error) {
        this.createTaxCategoryFailur(error);
      }
    },
    async editTaxCategory(payload) {
      try {
        this.editTaxCategoryRequest();
        const taxCategory = await taxesService.editTaxCategory(getToken(), payload);
        this.editTaxCategorySuccess(taxCategory);
        this.getTaxCategories(true);
      } catch (error) {
        this.editTaxCategoryFailure( error);
      }
    },
    async deleteTaxCategory(id) {
      try {
        this.deleteTaxCategoryRequest();
        const taxCategory = await taxesService.deleteTaxCategory(getToken(), id);
        this.deleteTaxCategorySuccess(taxCategory);
        this.getTaxCategories(true);
      } catch (error) {
        this.deleteTaxCategoryFailure(error);
      }
    },
    setFilter( value) {
      this.setFilterValue(value);
    },
    clearSuccessMessage() {
      this.clearMessage();
    },
    setSelected( value) {
      this.setSelectedValue(value);
    },
    createTaxCategoryRequest() {
      this.status = { isLoading: true };
      this.data = removeAll(this.data);
      this.error = null;
    },
    createTaxCategorySuccess(taxCategory) {
      this.status = { success: true };
      this.data = addOne(taxCategory, this.data);
      this.error = null;
    },
    createTaxCategoryFailure(error) {
      this.status = {};
      this.data = removeAll(this.data);
      this.error = error;
    },
    getTaxCategoriesRequest() {
      this.status = { isLoading: true };
      this.data = removeAll(this.data);
      this.error = null;
    },
    getTaxCategoriesSuccess({ taxCategories, showMessage }) {
      this.status = { success: showMessage };
      this.data = setAll(taxCategories.content, this.data);
      this.error = null;
    },
    getTaxCategoriesFailure(error) {
      this.status = {};
      this.data = removeAll(this.data);
      this.error = error;
    },
    editTaxCategoryRequest() {
      this.status = { isLoading: true };
      this.data = removeAll(this.data);
      this.error = null;
    },
    editTaxCategorySuccess(taxCategory) {
      this.status = { success: true };
      this.data = addOne(taxCategory, this.data);
      this.error = null;
    },
    editTaxCategoryFailure(error) {
      this.status = {};
      this.data = removeAll(this.data);
      this.error = error;
    },
    deleteTaxCategoryRequest() {
      this.error = null;
    },
    deleteTaxCategorySuccess( taxCategory) {
      this.status = { success: true };
      this.data = addOne(taxCategory, this.data);
      this.error = null;
    },
    deleteTaxCategoryFailure(error) {
      this.status = {};
      this.error = error;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    clearMessage() {
      this.status.success = null;
    },
    clearError() {
      this.error = null;
    },
    setSelectedValue(value) {
      this.data.selected = value;
    }
  }
});
