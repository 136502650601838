import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from "@/services/users.service";


export const useDataUserUserEdit = defineStore('usersUserEdit', {
  state: () => ({
    user: null,
    pending: false,
    error: null,
    success: null
  }),
  getters: {
    isError: state => !!state.error,
    isPending: state => state.pending,
    isSuccess: state => state.success,
    getUser: state => state.user || {},
    hasUser: state => !!state.user,
    getError: state => state.error || {}
  },
  actions: {
    patchUser(userEntity) {
      this.patchUserRequest();

      usersService.patchUser(getToken(), userEntity).then(
        res => {
          this.patchUserSuccess(res);
          //dispatch('data/user/hydrateUser', res, { root: true });
        },
        error => {
          this.patchUserFailure(error);
        }
      );
    },
    resetUser() {
      this.reset();
    },
    patchUserRequest() {
      this.pending = true;
      this.user = null;
      this.error = null;
      this.success = null;
    },
    patchUserSuccess(user) {
      this.pending = false;
      this.user = user;
      this.error = null;
      this.success = true;
    },
    patchUserFailure(error) {
      this.pending = false;
      this.user = null;
      this.error = error;
      this.success = false;
    },
    reset() {
      this.user = null;
      this.pending = false;
      this.error = null;
      this.success = null;
    },
    clearMessage() {
      this.error = null;
      this.success = null;
    }
  }
});
