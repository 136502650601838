import 'whatwg-fetch';
import { downloadPdfFile, downloadXlsxFile, get, patch, put, resolveCommonQuery } from '@/helpers/service.helper';
import { getObjectStringValues, getObjectTypeValues } from '@/helpers/object.helpers';
import { getCurrentTimeForReport } from '@/helpers/date.helpers';
import { getToken } from '@/helpers/token.helper';
import pick from 'lodash.pick';

const LOCATIONS_PATH = `/locations`;

/**
 * Get locations
 * @link https://api.posveloce.com/docs/#operation/getLocations
 */
const getLocations = (token, query) => {
  const params = pick(query, ['include', 'licenceNumber']);
  const url = resolveCommonQuery(LOCATIONS_PATH, params).toString();
  return get(token, url).then(allLocations =>
    !Array.isArray(allLocations)
      ? allLocations
      : (allLocations || []).map(l => ({
          ...l,
          reportingDisplayName: l.reportingDisplayName === '' ? null : l.reportingDisplayName
        }))
  );
};

/**
 * Get sales by locations
 * @link https://api.posveloce.com/docs/#operation/getLocationSalesSummary
 */
const getLocationsSales = (token, query) => {
  const params = pick(query, ['from', 'to', 'currency', 'limit', 'offset', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/sales`, params).toString();
  return get(token, url);
};

/**
 * Get a location by its id
 * @link https://api.posveloce.com/docs/#operation/getLocationById
 */
const getLocation = (token, locationId) => get(token, `${LOCATIONS_PATH}/${locationId}`);

/**
 * Update a location by its id
 * @link https://api.posveloce.com/docs/#operation/updateLocationById
 */
const patchLocation = (token, locationEntity) => patch(token, `${LOCATIONS_PATH}/${locationEntity.id}`, locationEntity);

/**
 * Get location permissions
 * @link https://api.posveloce.com/docs/#operation/getLocationPermissions
 */
const getLocationPermissions = (token, query) => {
  const params = pick(query, ['include']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/${query.locationId}/permissions`, params).toString();
  return get(token, url);
};

/**
 * Get location groups
 * @link https://api.posveloce.com/docs/#operation/getLocationGroups
 */
const getLocationGroups = (token, locationId) => get(token, `${LOCATIONS_PATH}/${locationId}/groups`);

/**
 * Get location items
 * @link https://api.posveloce.com/docs/#operation/getLocationItems
 */
const getLocationItems = (token, locationId) => get(token, `${LOCATIONS_PATH}/${locationId}/items`);

/**
 * Get location popups
 * @link https://api.posveloce.com/docs/#operation/getLocationPopups
 */
const getLocationPopups = (token, locationId) => get(token, `${LOCATIONS_PATH}/${locationId}/popups`);

/**
 * Get location products
 * @link https://api.posveloce.com/docs/#operation/getLocationProducts
 */
const getLocationProducts = (token, locationId) => get(token, `${LOCATIONS_PATH}/${locationId}/products`);

/**
 * Get versions
 * @link https://api.posveloce.com/docs/#tag/locations/paths/~1locations~1versions/get
 */
const getLocationVersions = (token, query) => {
  const params = pick(query, ['locationId', 'locationIds', 'groupId']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/versions`, params).toString();
  return get(token, url);
};
/**
 * Get location settings
 * @link https://api.posveloce.com/docs/#operation/getLocationSettings
 */
const getLocationSettings = (token, query) => {
  const params = pick(query, ['limit', 'offset', 'locationId', 'locationIds']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/settings`, params).toString();
  return get(token, url).then(r => getObjectTypeValues((r[0] && r[0].settings) || {}));
};
/**
 * Put location settings
 * @link https://api.posveloce.com/docs/#operation/putLocationSettings
 */
const putLocationSettings = (token, locationId, settings) => {
  const url = `${LOCATIONS_PATH}/settings`;
  return put(token, url, [{ locationId, settings: getObjectStringValues(settings) }]);
};

/**
 * Get location filters
 * @link https://api.posveloce.com/docs/private#operation/getLocationFilters
 */
const getLocationFilters = query => {
  const params = pick(query, [
    'from',
    'to',
    'limit',
    'offset',
    'locationId',
    'locationIds',
    'tenderIDs',
    'departmentIDs',
    'workstationIDs',
    'discountIDs',
    'employeeName',
    'groupId'
  ]);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/filters`, params).toString();
  return get(getToken(), url);
};

const getLocationsSearch = (params = {}) => {
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/search`, params).toString();
  return get(getToken(), url);
};

const downloadLocationsSearchXlsx = (params = {}) => {
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/search`, params).toString();
  return downloadXlsxFile(getToken(), url, `Location Status Report_${getCurrentTimeForReport()}.xlsx`);
};

const downloadLocationsSearchPDF = (params = {}) => {
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/search`, params).toString();
  return downloadPdfFile(getToken(), url, `Location Status Report_${getCurrentTimeForReport()}.pdf`);
};


export default {
  getLocation,
  getLocations,
  getLocationsSales,
  getLocationPermissions,
  getLocationGroups,
  getLocationVersions,
  patchLocation,
  getLocationItems,
  getLocationPopups,
  getLocationProducts,
  getLocationSettings,
  putLocationSettings,
  getLocationFilters,
  getLocationsSearch,
  downloadLocationsSearchXlsx,
  downloadLocationsSearchPDF
};
