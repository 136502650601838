var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      attrs: {
        "show-crumbs": false,
        loading: _vm.loading,
        title: _vm.$t("languageSetupPage.title")
      }
    },
    [
      _c("vel-card", [
        _c("h4", [_vm._v(_vm._s(_vm.$t("languageSetupPage.supported")))]),
        _c("table", { staticClass: "supported-languages" }, [
          _c("thead", [
            _c("th"),
            _c("th", [
              _vm._v(_vm._s(_vm.$t("languageSetupPage.table.activate")))
            ]),
            _c("th", [
              _vm._v(_vm._s(_vm.$t("languageSetupPage.table.default")))
            ])
          ]),
          _c(
            "tbody",
            _vm._l(_vm.supportedLanguages, function(val, language) {
              return _c("tr", { key: language }, [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("languageSetupPage.table." + language)) +
                      " "
                  )
                ]),
                _c(
                  "td",
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { min: 1 },
                        model: {
                          value: _vm.availableLanguages,
                          callback: function($$v) {
                            _vm.availableLanguages = $$v
                          },
                          expression: "availableLanguages"
                        }
                      },
                      [
                        _c("el-checkbox", {
                          attrs: { label: language },
                          on: { change: _vm.handleAvailableLangChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("el-radio", {
                      attrs: {
                        disabled: !_vm.availableLanguages.includes(language),
                        label: language
                      },
                      on: { change: _vm.handleDefaultLangChange },
                      model: {
                        value: _vm.mainLanguage,
                        callback: function($$v) {
                          _vm.mainLanguage = $$v
                        },
                        expression: "mainLanguage"
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            0
          )
        ]),
        _c("h4", { staticClass: "preferred-languages-title" }, [
          _vm._v(_vm._s(_vm.$t("languageSetupPage.preferred")))
        ]),
        _c("table", { staticClass: "preferred-languages" }, [
          _c(
            "thead",
            [
              _c("th"),
              _vm._l(_vm.availableLanguages, function(language) {
                return _c(
                  "th",
                  {
                    key: language,
                    class: { "default-language": _vm.mainLanguage === language }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          "" +
                            (_vm.mainLanguage === language
                              ? _vm.$t("languageSetupPage.table.default") +
                                " (" +
                                _vm.$t("languageSetupPage.table." + language) +
                                ")"
                              : "" +
                                _vm.$t("languageSetupPage.table." + language))
                        ) +
                        " "
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c("tbody", [
            _c(
              "tr",
              [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("languageSetupPage.table.backOffice")) +
                      " "
                  )
                ]),
                _vm._l(_vm.availableLanguages, function(language) {
                  return _c(
                    "td",
                    { key: language },
                    [
                      _c("el-radio", {
                        attrs: { label: language },
                        model: {
                          value: _vm.preferredSettings.backOffice,
                          callback: function($$v) {
                            _vm.$set(_vm.preferredSettings, "backOffice", $$v)
                          },
                          expression: "preferredSettings.backOffice"
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _c(
              "tr",
              [
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("languageSetupPage.table.pos")) + " "
                  )
                ]),
                _vm._l(_vm.availableLanguages, function(language) {
                  return _c(
                    "td",
                    { key: language },
                    [
                      _c("el-radio", {
                        attrs: { label: language },
                        model: {
                          value: _vm.preferredSettings.pos,
                          callback: function($$v) {
                            _vm.$set(_vm.preferredSettings, "pos", $$v)
                          },
                          expression: "preferredSettings.pos"
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _c(
              "tr",
              [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("languageSetupPage.table.requisition") +
                          " " +
                          (_vm.hasMultipleLanguages
                            ? "(" +
                              _vm.$t("languageSetupPage.table.print-order") +
                              ")"
                            : "")
                      ) +
                      " "
                  )
                ]),
                _vm._l(_vm.availableLanguages, function(language) {
                  return _c(
                    "td",
                    { key: language },
                    [
                      _c("el-checkbox", {
                        model: {
                          value:
                            _vm.preferredSettings.requisition[language].enabled,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.preferredSettings.requisition[language],
                              "enabled",
                              $$v
                            )
                          },
                          expression:
                            "preferredSettings.requisition[language].enabled"
                        }
                      }),
                      _vm.hasMultipleLanguages &&
                      Object.values(_vm.preferredSettings.requisition).every(
                        function(p) {
                          return p.enabled
                        }
                      )
                        ? _c(
                            "vel-select",
                            {
                              on: {
                                change: function($event) {
                                  return _vm.handleReqPriorityChange(
                                    _vm.preferredSettings.requisition[language]
                                      .priority,
                                    language
                                  )
                                }
                              },
                              model: {
                                value:
                                  _vm.preferredSettings.requisition[language]
                                    .priority,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.preferredSettings.requisition[language],
                                    "priority",
                                    $$v
                                  )
                                },
                                expression:
                                  "preferredSettings.requisition[language].priority"
                              }
                            },
                            [
                              _c("vel-option", { attrs: { value: 1 } }),
                              _c("vel-option", { attrs: { value: 2 } })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _c(
              "tr",
              [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("languageSetupPage.table.customerCheck")) +
                      " "
                  )
                ]),
                _vm._l(_vm.availableLanguages, function(language) {
                  return _c(
                    "td",
                    { key: language },
                    [
                      _c("el-radio", {
                        attrs: { label: language },
                        model: {
                          value: _vm.preferredSettings.customerCheck,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.preferredSettings,
                              "customerCheck",
                              $$v
                            )
                          },
                          expression: "preferredSettings.customerCheck"
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _c(
              "tr",
              [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("languageSetupPage.table.kds") +
                          " " +
                          (_vm.hasMultipleLanguages
                            ? "(" +
                              _vm.$t("languageSetupPage.table.print-order") +
                              ")"
                            : "")
                      ) +
                      " "
                  )
                ]),
                _vm._l(_vm.availableLanguages, function(language) {
                  return _c(
                    "td",
                    { key: language },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.preferredSettings.kds[language].enabled,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.preferredSettings.kds[language],
                              "enabled",
                              $$v
                            )
                          },
                          expression: "preferredSettings.kds[language].enabled"
                        }
                      }),
                      _vm.hasMultipleLanguages &&
                      Object.values(_vm.preferredSettings.kds).every(function(
                        p
                      ) {
                        return p.enabled
                      })
                        ? _c(
                            "vel-select",
                            {
                              on: {
                                change: function($event) {
                                  return _vm.handleKdsPriorityChange(
                                    _vm.preferredSettings.kds[language]
                                      .priority,
                                    language
                                  )
                                }
                              },
                              model: {
                                value:
                                  _vm.preferredSettings.kds[language].priority,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.preferredSettings.kds[language],
                                    "priority",
                                    $$v
                                  )
                                },
                                expression:
                                  "preferredSettings.kds[language].priority"
                              }
                            },
                            [
                              _c("vel-option", {
                                attrs: { label: "1", value: 1 }
                              }),
                              _c("vel-option", {
                                attrs: { label: "2", value: 2 }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                })
              ],
              2
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c("vel-button", { on: { click: _vm.handleSaveSettings } }, [
            _vm._v(_vm._s(_vm.$t("defaults.actions.save")))
          ]),
          _c(
            "vel-button",
            { attrs: { type: "link" }, on: { click: _vm.handleCancel } },
            [_vm._v(_vm._s(_vm.$t("defaults.actions.cancel")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }