var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vel-sales-report", {
    staticClass: "big-division-sales-report",
    attrs: {
      displayedColumns: _vm.displayedColumns,
      data: _vm.formatedTree,
      consolidated: _vm.consolidated,
      locationCount: _vm.locationCount,
      locationTotal: _vm.locationTotal,
      areSalesLoading: _vm.areSalesLoading,
      filterable: false,
      expandable:
        _vm.multipleLocation && !_vm.consolidated && _vm.locationCount > 1,
      "external-export": true,
      "is-external-generating": _vm.generating
    },
    on: { exportXls: _vm.exportXLSX }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }