var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        { staticClass: "mb-4", attrs: { show: _vm.error, variant: "danger" } },
        [_vm._v(" " + _vm._s(_vm.$t("employeeCreate.updateError")) + " ")]
      ),
      _vm.employee.terminated
        ? _c(
            "b-container",
            {
              staticClass: "content px-4 pt-4 pb-0",
              staticStyle: { "background-color": "#f5f5f5" },
              attrs: { fluid: "" }
            },
            [
              _c("div", { staticClass: "bg-white p-3" }, [
                _c("strong", { staticStyle: { "font-size": "1rem" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "employeeCreate.personnalInformation.deactivationDetails"
                      )
                    )
                  )
                ]),
                _c("p", { staticClass: "d-block mt-3" }, [
                  _vm._v(
                    _vm._s(_vm.$t("employeeCreate.personnalInformation.note"))
                  )
                ]),
                _c(
                  "p",
                  {
                    staticClass: "d-block mt-1",
                    staticStyle: { "font-size": "1rem" }
                  },
                  [_vm._v(_vm._s(_vm.employee.terminatedReason || "--"))]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column flex-md-row justify-content-md-between",
                    staticStyle: { "max-width": "600px" }
                  },
                  [
                    _c("div", [
                      _c("p", { staticClass: "d-block mt-3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "employeeCreate.personnalInformation.deactivatedBy"
                            )
                          )
                        )
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "d-block mt-1",
                          staticStyle: { "font-size": "1rem" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.employee.terminatedBy.firstName) +
                              " " +
                              _vm._s(_vm.employee.terminatedBy.lastName) +
                              " "
                          )
                        ]
                      )
                    ]),
                    _c("div", [
                      _c("p", { staticClass: "d-block mt-3" }, [
                        _vm._v("Date:")
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "d-block mt-1",
                          staticStyle: { "font-size": "1rem" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.formatDate(_vm.employee.terminatedAt))
                          )
                        ]
                      )
                    ]),
                    _c("div", [
                      _c("p", { staticClass: "d-block mt-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "employeeCreate.personnalInformation.rehireable.label"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "d-block mt-1",
                          staticStyle: { "font-size": "1rem" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "employeeCreate.personnalInformation.rehireable." +
                                    ((_vm.employee.rehireable && "yes") || "no")
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.personnalInformation.title")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-md-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100 mb-2 mb-md-0",
                attrs: {
                  label: _vm.$t(
                    "employeeCreate.personnalInformation.fields.fullName"
                  ),
                  description: _vm.$t("employeeCreate.required"),
                  "label-for": "name"
                }
              },
              [
                _c("b-form-input", {
                  class: {
                    required: !_vm.employee.name || _vm.employee.name === ""
                  },
                  attrs: {
                    id: "name",
                    formatter: _vm.formatName,
                    state: !_vm.submited
                      ? null
                      : _vm.employee.name !== "" && null,
                    maxlength: "50",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.name,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "name", $$v)
                    },
                    expression: "employee.name"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 mb-2 mb-md-0 w-100",
                attrs: {
                  label: _vm.$t(
                    "employeeCreate.personnalInformation.fields.publicName"
                  ),
                  description: _vm.$t("employeeCreate.required"),
                  "label-for": "publicName"
                }
              },
              [
                _c("b-form-input", {
                  class: {
                    required:
                      !_vm.employee.publicName || _vm.employee.publicName === ""
                  },
                  attrs: {
                    id: "publicName",
                    formatter: _vm.formatName,
                    state: !_vm.submited
                      ? null
                      : _vm.employee.publicName !== "" && null,
                    maxlength: "50",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.publicName,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "publicName", $$v)
                    },
                    expression: "employee.publicName"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 mb-2 mb-md-0 w-100",
                attrs: {
                  label: _vm.$t(
                    "employeeCreate.personnalInformation.fields.address"
                  ),
                  description: _vm.$t("employeeCreate.required"),
                  "label-for": "address"
                }
              },
              [
                _c("b-form-input", {
                  class: {
                    required:
                      !_vm.employee.address || _vm.employee.address === ""
                  },
                  attrs: {
                    id: "address",
                    state: !_vm.submited
                      ? null
                      : _vm.employee.address !== "" && null,
                    maxlength: "512",
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.address,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "address", $$v)
                    },
                    expression: "employee.address"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 mb-2 mb-md-0 w-100",
                attrs: {
                  label: _vm.$t(
                    "employeeCreate.personnalInformation.fields.dob"
                  ),
                  "label-for": "birthDate"
                }
              },
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-form-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "99/99/9999",
                          expression: "'99/99/9999'"
                        }
                      ],
                      attrs: {
                        id: "example-input",
                        type: "text",
                        placeholder: _vm.$t("employeeCreate.datePlaceholder"),
                        value: _vm.birthDate,
                        autocomplete: "off"
                      },
                      on: { change: _vm.setBirthDate }
                    }),
                    _c(
                      "b-input-group-append",
                      { staticStyle: { border: "1px solid #ced4da" } },
                      [
                        _c("b-form-datepicker", {
                          attrs: {
                            "button-only": "",
                            locale: _vm.$i18n.locale,
                            right: "",
                            "button-variant": "white",
                            "label-no-date-selected": _vm.$t(
                              "datePicker.noDate"
                            ),
                            "label-calendar": _vm.$t("datePicker.calendar"),
                            "label-close-button": _vm.$t("datePicker.close"),
                            "label-current-month": _vm.$t(
                              "datePicker.currentMonth"
                            ),
                            "label-help": _vm.$t("datePicker.help"),
                            "label-nav": _vm.$t("datePicker.nav"),
                            "label-next-decade": _vm.$t(
                              "datePicker.nextDecade"
                            ),
                            "label-next-month": _vm.$t("datePicker.nextMonth"),
                            "label-next-year": _vm.$t("datePicker.nextYear"),
                            "label-prev-decade": _vm.$t(
                              "datePicker.previousDecade"
                            ),
                            "label-prev-month": _vm.$t(
                              "datePicker.previousMonth"
                            ),
                            "label-prev-year": _vm.$t(
                              "datePicker.previousYear"
                            ),
                            "label-reset-button": _vm.$t("datePicker.reset"),
                            "label-today": _vm.$t("datePicker.today"),
                            "label-today-button": _vm.$t(
                              "datePicker.selectToday"
                            ),
                            "date-format-options": {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit"
                            },
                            "aria-controls": "example-input"
                          },
                          on: { context: _vm.onSelectBirthDate },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "calendar-alt" }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.employee.birthDate,
                            callback: function($$v) {
                              _vm.$set(_vm.employee, "birthDate", $$v)
                            },
                            expression: "employee.birthDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100",
                attrs: {
                  label: _vm.$t(
                    "employeeCreate.personnalInformation.fields.hiringDate"
                  ),
                  "label-for": "hiringDate"
                }
              },
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-form-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "99/99/9999",
                          expression: "'99/99/9999'"
                        }
                      ],
                      attrs: {
                        id: "example-input",
                        type: "text",
                        placeholder: _vm.$t("employeeCreate.datePlaceholder"),
                        value: _vm.hiringDate,
                        autocomplete: "off"
                      },
                      on: { change: _vm.setHiringDate }
                    }),
                    _c(
                      "b-input-group-append",
                      { staticStyle: { border: "1px solid #ced4da" } },
                      [
                        _c("b-form-datepicker", {
                          attrs: {
                            "button-only": "",
                            right: "",
                            "button-variant": "white",
                            locale: _vm.$i18n.locale,
                            "label-no-date-selected": _vm.$t(
                              "datePicker.noDate"
                            ),
                            "label-calendar": _vm.$t("datePicker.calendar"),
                            "label-close-button": _vm.$t("datePicker.close"),
                            "label-current-month": _vm.$t(
                              "datePicker.currentMonth"
                            ),
                            "label-help": _vm.$t("datePicker.help"),
                            "label-nav": _vm.$t("datePicker.nav"),
                            "label-next-decade": _vm.$t(
                              "datePicker.nextDecade"
                            ),
                            "label-next-month": _vm.$t("datePicker.nextMonth"),
                            "label-next-year": _vm.$t("datePicker.nextYear"),
                            "label-prev-decade": _vm.$t(
                              "datePicker.previousDecade"
                            ),
                            "label-prev-month": _vm.$t(
                              "datePicker.previousMonth"
                            ),
                            "label-prev-year": _vm.$t(
                              "datePicker.previousYear"
                            ),
                            "label-reset-button": _vm.$t("datePicker.reset"),
                            "label-today": _vm.$t("datePicker.today"),
                            "label-today-button": _vm.$t(
                              "datePicker.selectToday"
                            ),
                            "date-format-options": {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit"
                            },
                            "aria-controls": "example-input"
                          },
                          on: { context: _vm.onSelectHiringDate },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "calendar-alt" }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.employee.hiringDate,
                            callback: function($$v) {
                              _vm.$set(_vm.employee, "hiringDate", $$v)
                            },
                            expression: "employee.hiringDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-100 mr-5 ml-md-5" },
              [
                _vm.languages && _vm.languages.length
                  ? _c(
                      "b-form-group",
                      {
                        staticClass: "mr-5 w-100 mb-2 mb-md-0",
                        attrs: {
                          label: _vm.$t(
                            "employeeCreate.personnalInformation.fields.language.title"
                          ),
                          "label-for": "name"
                        }
                      },
                      [
                        _c(
                          "b-button-group",
                          _vm._l(_vm.languages, function(lang) {
                            return _c(
                              "b-button",
                              {
                                key: lang.id,
                                staticClass: "border-input",
                                attrs: {
                                  variant:
                                    _vm.employee.languageId == lang.id
                                      ? "primary"
                                      : "white"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.employee.languageId = lang.id
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "employeeCreate.personnalInformation.fields.language." +
                                          lang.language
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "w-100 mr-5 ml-md-5" }),
            _c("div", { staticClass: "w-100 mr-5 ml-md-5" })
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.contactInformation.title")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-2 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 mb-2 mb-md-0 w-100",
                attrs: {
                  label: _vm.$t(
                    "employeeCreate.contactInformation.fields.cellPhone"
                  ),
                  "label-for": "mobilePhone"
                }
              },
              [
                _c("b-form-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "(999) 999-9999",
                      expression: "'(999) 999-9999'"
                    }
                  ],
                  attrs: {
                    id: "telephone",
                    placeholder: "(___) ___-____",
                    "aria-describedby": "cell-phone-feedback",
                    type: "text"
                  },
                  on: {
                    focus: function($event) {
                      return _vm.checkMask("cell-phone", "###-###-####")
                    }
                  },
                  model: {
                    value: _vm.employee.phone.mobile,
                    callback: function($$v) {
                      _vm.$set(_vm.employee.phone, "mobile", $$v)
                    },
                    expression: "employee.phone.mobile"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 mb-2 mb-md-0 ml-md-5 w-100",
                attrs: {
                  label: _vm.$t(
                    "employeeCreate.contactInformation.fields.homePhone"
                  ),
                  "label-for": "homePhone"
                }
              },
              [
                _c("b-form-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "(999) 999-9999",
                      expression: "'(999) 999-9999'"
                    }
                  ],
                  attrs: {
                    id: "homePhone",
                    placeholder: "(___) ___-____",
                    "aria-describedby": "cell-phone-feedback",
                    type: "text"
                  },
                  on: {
                    focus: function($event) {
                      return _vm.checkMask("cell-phone", "###-###-####")
                    }
                  },
                  model: {
                    value: _vm.employee.phone.home,
                    callback: function($$v) {
                      _vm.$set(_vm.employee.phone, "home", $$v)
                    },
                    expression: "employee.phone.home"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 w-100",
                attrs: {
                  label: _vm.$t(
                    "employeeCreate.contactInformation.fields.email"
                  ),
                  description: _vm.$t("employeeCreate.required"),
                  "label-for": "homePhone"
                }
              },
              [
                _c("b-form-input", {
                  class: {
                    required:
                      !_vm.employee.emailAddress ||
                      _vm.employee.emailAddress === ""
                  },
                  attrs: {
                    type: "email",
                    id: "email",
                    state: !_vm.submited
                      ? null
                      : _vm.employee.emailAddress !== "" && null,
                    maxlength: "128",
                    "aria-describedby": "input-live-help email-feedback"
                  },
                  model: {
                    value: _vm.employee.emailAddress,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "emailAddress", $$v)
                    },
                    expression: "employee.emailAddress"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "w-100 mr-5 ml-md-5" })
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.posAccess.title")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-2 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 mb-2 mb-md-0 w-100",
                attrs: {
                  label: _vm.$t("employeeCreate.posAccess.fields.accessCode"),
                  description: _vm.$t("employeeCreate.requiredCode"),
                  "label-for": "accessCode"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    id: "accessCode",
                    formatter: _vm.formatCode,
                    maxlength: "128",
                    "aria-describedby": "access-code-feedback"
                  },
                  model: {
                    value: _vm.employee.code,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "code", $$v)
                    },
                    expression: "employee.code"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 ml-md-5 w-100",
                attrs: {
                  label: _vm.$t("employeeCreate.posAccess.fields.password"),
                  description: _vm.$t("employeeCreate.requiredPassword"),
                  "label-for": "passwd"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    id: "passwd",
                    maxlength: "128",
                    formatter: _vm.formatCode,
                    "aria-describedby": "input-live-help input-live-feedback"
                  },
                  model: {
                    value: _vm.employee.password,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "password", $$v)
                    },
                    expression: "employee.password"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" }),
            _c("div", { staticClass: "mr-5 ml-md-5 w-100" })
          ],
          1
        )
      ]),
      _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
        _vm._v(_vm._s(_vm.$t("employeeCreate.integration.title")))
      ]),
      _c("b-container", { staticClass: "content p-4", attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-md-row mb-4 justify-content-between"
          },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mr-5 w-100",
                attrs: {
                  label: _vm.$t("employeeCreate.integration.fields.code"),
                  "label-for": "employee-code"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    id: "employee-code",
                    maxlength: "128",
                    "aria-describedby": "employee-code-feedback"
                  },
                  model: {
                    value: _vm.employee.integrationCode,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "integrationCode", $$v)
                    },
                    expression: "employee.integrationCode"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "w-100 mr-5 ml-md-5" }),
            _c("div", { staticClass: "w-100 mr-5 ml-md-5" }),
            _c("div", { staticClass: "w-100 mr-5 ml-md-5" })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }