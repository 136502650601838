var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "userForm",
      staticClass: "user-form",
      attrs: {
        model: _vm.userForm,
        rules: _vm.userFormRules,
        submit: _vm.handleSubmit,
        "label-position": "left",
        inline: false,
        "label-width": "200px"
      }
    },
    [
      _c("vel-input", {
        staticClass: "name-input",
        attrs: { name: "id", type: "hidden" },
        model: {
          value: _vm.userForm.id,
          callback: function($$v) {
            _vm.$set(_vm.userForm, "id", $$v)
          },
          expression: "userForm.id"
        }
      }),
      _c(
        "vel-form-item",
        {
          staticClass: "user-form-email",
          attrs: { prop: "email", label: _vm.$t("user.form.email.label") }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "email", type: "text" },
            model: {
              value: _vm.userForm.email,
              callback: function($$v) {
                _vm.$set(_vm.userForm, "email", $$v)
              },
              expression: "userForm.email"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "user-form-firstName",
          attrs: {
            prop: "firstName",
            label: _vm.$t("user.form.firstName.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "firstName", type: "text" },
            model: {
              value: _vm.userForm.firstName,
              callback: function($$v) {
                _vm.$set(_vm.userForm, "firstName", $$v)
              },
              expression: "userForm.firstName"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "user-form-lastName",
          attrs: { prop: "lastName", label: _vm.$t("user.form.lastName.label") }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "lastName", type: "text" },
            model: {
              value: _vm.userForm.lastName,
              callback: function($$v) {
                _vm.$set(_vm.userForm, "lastName", $$v)
              },
              expression: "userForm.lastName"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "user-form-phoneNumber",
          attrs: {
            prop: "phoneNumber",
            label: _vm.$t("user.form.phoneNumber.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "phoneNumber", type: "text" },
            model: {
              value: _vm.userForm.phoneNumber,
              callback: function($$v) {
                _vm.$set(_vm.userForm, "phoneNumber", $$v)
              },
              expression: "userForm.phoneNumber"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "user-form-type",
          attrs: { prop: "type", label: _vm.$t("user.form.type.label") }
        },
        [
          _c(
            "vel-select",
            {
              attrs: { placeholder: _vm.$t("user.form.type.placeholder") },
              on: { change: _vm.onTypeChange },
              model: {
                value: _vm.userForm.type,
                callback: function($$v) {
                  _vm.$set(_vm.userForm, "type", $$v)
                },
                expression: "userForm.type"
              }
            },
            [
              _c("vel-option", {
                key: 0,
                attrs: { label: _vm.$t("user.propertyValue.type.0"), value: 0 }
              }),
              _c("vel-option", {
                key: 128,
                attrs: {
                  label: _vm.$t("user.propertyValue.type.128"),
                  value: 128
                }
              }),
              _c("vel-option", {
                key: 256,
                attrs: {
                  label: _vm.$t("user.propertyValue.type.256"),
                  value: 256
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "reseller",
          attrs: {
            prop: "resellerId",
            label: _vm.$t("user.form.resellerId.label")
          }
        },
        [
          _c(
            "vel-select",
            {
              attrs: {
                disabled: _vm.userForm.type !== 128,
                placeholder: _vm.$t("user.form.resellerId.placeholder")
              },
              model: {
                value: _vm.userForm.resellerId,
                callback: function($$v) {
                  _vm.$set(_vm.userForm, "resellerId", $$v)
                },
                expression: "userForm.resellerId"
              }
            },
            [
              _c("vel-option", {
                attrs: { label: _vm.$t("user.form.resellerNone"), value: null }
              }),
              _vm._l(_vm.resellers, function(reseller) {
                return _c("vel-option", {
                  key: reseller.id,
                  attrs: { label: reseller.name, value: reseller.id }
                })
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "user-form-enabled",
          attrs: { prop: "enabled", label: _vm.$t("user.form.enabled.label") }
        },
        [
          _c("vel-switch", {
            model: {
              value: _vm.userForm.enabled,
              callback: function($$v) {
                _vm.$set(_vm.userForm, "enabled", $$v)
              },
              expression: "userForm.enabled"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        { staticClass: "user-form__actions" },
        [
          _c(
            "vel-button",
            {
              staticClass: "actions__submit",
              attrs: { type: "primary", loading: _vm.pending },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }