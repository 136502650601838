import { defineStore } from 'pinia';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import {useUIDateCompare} from "@/stores/ui/date-compare.module";
import {useUIDateSelector} from "@/stores/ui/date-selector.module";
import {useUIDateRangeSelector} from "@/stores/ui/date-range-selector.module";
import {useUICurrencySelector} from "@/stores/ui/currency-selector.module";
import {useUIBreadcrumbsStore} from "@/stores/ui/breadcrumbs.module";
import router, {PATH_LOGIN} from "@/router";

export const useRouteStore = defineStore('route', {
  state: () => ({
    error: null
  }),
  actions: {
    routeChanged({ to, from }) {
      if (to.path === PATH_LOGIN) {
        return;
      }

      const groupSelector = useUIGroupSelector();
      const locationSelector = useUILocationSelector();
      const dateCompareSelector = useUIDateCompare();
      const dateSelector = useUIDateSelector();
      const dateRangeSelector = useUIDateRangeSelector();
      const currencySelector = useUICurrencySelector();
      const breadCrumbs = useUIBreadcrumbsStore();

      const breadcrumbs = (router.currentRoute.matched || [])
        .filter(route => !route.meta.isDefault && route.name !== 'home' && route.name !== 'backOffice')
        .map(route => {
          let path = route.path;

          for (const [param, value] of Object.entries(router.currentRoute.params)) {
            path = path.replace(`:${param}`, value);
          }
          return { name: route.name, path };
        });
      breadCrumbs.updateBreadcrumbs(breadcrumbs);

      if (dateRangeSelector.range[0] !== to.query.from || dateRangeSelector.range[1] !== to.query.to) {
        dateRangeSelector.updateSelectorDate(to.query);
      }

      dateCompareSelector.updateDateComparator(to.query.dateCompare)
      currencySelector.updateCurrency(to.query.currencyId)

      if (groupSelector.selected !== to.query.groupId) {
        groupSelector.updateSelectedGroup(to.query);
      }

      if (dateSelector.selected !== to.query.from) {
        dateSelector.updateSelectorDate(to.query);
      }

      if (locationSelector.selected !== to.query.locationId) {
        locationSelector.updateSelectedLocation(to.query);
      }

      if (
        JSON.stringify(locationSelector.selectedMultiple) !== JSON.stringify(to.query.locationIds) &&
        to.query.locationIds
      ) {
        locationSelector.updateSelectedMultipleLocation({
          value:
            typeof to.query.locationIds === 'string'
              ? [{ value: to.query.locationIds, label: '' }]
              : to.query.locationIds.map(x => ({ value: x, label: '' }))
        });
      }
    }
  }
});
