var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "locationForm",
      staticClass: "location-form bootstrap",
      attrs: {
        model: _vm.locationForm,
        rules: _vm.locationFormRules,
        submit: _vm.handleSubmit,
        "label-width": "250px"
      }
    },
    [
      _c("vel-input", {
        staticClass: "name-input",
        attrs: { name: "id", type: "hidden" },
        model: {
          value: _vm.locationForm.id,
          callback: function($$v) {
            _vm.$set(_vm.locationForm, "id", $$v)
          },
          expression: "locationForm.id"
        }
      }),
      _c(
        "vel-form-item",
        {
          staticClass: "location-form-name",
          attrs: { prop: "name", label: _vm.$t("location.form.name.label") }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "confirm",
                  rawName: "v-confirm",
                  value: _vm.$t("location.form.confirmNameEditMessage"),
                  expression: "$t('location.form.confirmNameEditMessage')"
                }
              ],
              on: { accept: _vm.accept }
            },
            [
              _c("vel-input", {
                staticClass: "name-input",
                attrs: {
                  name: "name",
                  type: "text",
                  disabled: !_vm.isAllowedAlias
                },
                model: {
                  value: _vm.locationForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.locationForm, "name", $$v)
                  },
                  expression: "locationForm.name"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "location-form-reporting-display-name",
          attrs: {
            prop: "name",
            label: _vm.$t("location.form.reportingDisplayName.label")
          }
        },
        [
          _c(
            "div",
            [
              _c("vel-input", {
                staticClass: "name-input",
                attrs: { name: "reportingDisplayName", type: "text" },
                model: {
                  value: _vm.locationForm.reportingDisplayName,
                  callback: function($$v) {
                    _vm.$set(_vm.locationForm, "reportingDisplayName", $$v)
                  },
                  expression: "locationForm.reportingDisplayName"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "location-form-address",
          attrs: {
            prop: "address",
            label: _vm.$t("location.form.address.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "address", type: "text" },
            model: {
              value: _vm.locationForm.address,
              callback: function($$v) {
                _vm.$set(_vm.locationForm, "address", $$v)
              },
              expression: "locationForm.address"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          staticClass: "location-form-licenceNumber",
          attrs: {
            prop: "licenceNumber",
            label: _vm.$t("location.form.licenceNumber.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "licenceNumber", disabled: true, type: "text" },
            model: {
              value: _vm.locationForm.licenceNumber,
              callback: function($$v) {
                _vm.$set(_vm.locationForm, "licenceNumber", $$v)
              },
              expression: "locationForm.licenceNumber"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "location-form-currency",
          attrs: {
            prop: "currencyCode",
            label: _vm.$t("location.form.currency.label")
          }
        },
        [
          _c(
            "vel-select",
            {
              attrs: { filterable: true },
              model: {
                value: _vm.locationForm.currencyCode,
                callback: function($$v) {
                  _vm.$set(_vm.locationForm, "currencyCode", $$v)
                },
                expression: "locationForm.currencyCode"
              }
            },
            [
              _vm._l(_vm.currencies, function(currency) {
                return _c(
                  "vel-option",
                  {
                    key: currency.code,
                    attrs: { label: currency.name, value: currency.code }
                  },
                  [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "34px"
                          }
                        },
                        [_vm._v(_vm._s(currency.name.substring(0, 3)))]
                      ),
                      _vm._v(" " + _vm._s(currency.name.substring(3)) + " ")
                    ])
                  ]
                )
              }),
              _c("vel-option", {
                attrs: {
                  value: null,
                  label: _vm.$t("location.form.country.none")
                }
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "is-demo",
          attrs: { prop: "isDemo", label: _vm.$t("location.form.isDemo.label") }
        },
        [
          _c("vel-switch", {
            model: {
              value: _vm.locationForm.isDemo,
              callback: function($$v) {
                _vm.$set(_vm.locationForm, "isDemo", $$v)
              },
              expression: "locationForm.isDemo"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          staticClass: "location-form-logo",
          attrs: { prop: "logo", label: _vm.$t("location.form.logo.label") }
        },
        [
          _c("vel-file-upload", {
            ref: "velUpload",
            attrs: {
              name: "logo",
              accept: "image/jpeg image/png image/webp image/gif"
            },
            model: {
              value: _vm.locationForm.logo,
              callback: function($$v) {
                _vm.$set(_vm.locationForm, "logo", $$v)
              },
              expression: "locationForm.logo"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        { staticClass: "location-form__actions" },
        [
          _c(
            "vel-button",
            {
              staticClass: "actions__submit",
              attrs: { type: "primary", loading: _vm.pending },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }