var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", {
    staticClass: "vel-label",
    class: _vm.classes,
    attrs: { for: _vm.name },
    domProps: { textContent: _vm._s(_vm.text) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }