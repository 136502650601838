var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vel-card", class: _vm.classes },
    [
      _vm.$slots.title
        ? _c(
            _vm.titleTag,
            {
              tag: "component",
              staticClass: "vel-card__title",
              class: { "vel-card__title_capitalized": this.capitalized }
            },
            [_vm._t("title")],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "vel-card__body" }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }