var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    { staticClass: "veltest" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("veltest.mainTitle")))]),
      _c(
        "vel-select",
        {
          staticClass: "vel-select",
          attrs: {
            autofocus: true,
            disabled: false,
            filterable: true,
            "no-data-text": "noData",
            "no-match-text": "noMatch",
            placeholder: "Choisir email",
            value: _vm.defaultValue
          },
          on: { change: _vm.handleChange }
        },
        [
          _vm.getSortedRows().length > 0
            ? _c(
                "vel-option-group",
                { attrs: { label: "Choisir email" } },
                _vm._l(_vm.getSortedRows(), function(option) {
                  return _c("vel-option", {
                    key: option.id,
                    attrs: { label: option.email, value: option.id }
                  })
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("h1", [_vm._v(_vm._s(_vm.$t("veltest.subTitle")))]),
      _c("h3", [_vm._v(_vm._s(_vm.$t("veltest.groupTitle")))]),
      _c("div", [
        !_vm.groupsPermissions.length ? _c("p", [_vm._v("--")]) : _vm._e(),
        _c(
          "div",
          _vm._l(_vm.groupsPermissions, function(l) {
            return _c("h5", { key: l.id }, [_vm._v(_vm._s(l.group.name))])
          }),
          0
        )
      ]),
      _c("h3", [_vm._v(_vm._s(_vm.$t("veltest.locationTitle")))]),
      !_vm.locationsPermissions.length ? _c("p", [_vm._v("--")]) : _vm._e(),
      _c(
        "div",
        _vm._l(_vm.locationsPermissions, function(l) {
          return _c("h5", { key: l.id }, [_vm._v(_vm._s(l.location.name))])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }