var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tax-form", {
    attrs: {
      tax: _vm.getSelectedTax,
      taxCategories: _vm.getTaxCategoriesRows,
      isLoading: _vm.isLoading || _vm.isTaxLoading,
      errorLabel: "taxForm.errors.edit"
    },
    on: { submit: _vm.handleSubmit }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }