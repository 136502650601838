var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page",
      attrs: {
        enableToolbar: false,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        mode: "edit",
        loading: _vm.loading
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("employee-footer", {
                attrs: {
                  activeTab: _vm.activeTab,
                  employee: _vm.employee,
                  submited: _vm.submited,
                  canContinue: _vm.canContinue,
                  canSave: _vm.canSave
                },
                on: {
                  "update:activeTab": function($event) {
                    _vm.activeTab = $event
                  },
                  "update:active-tab": function($event) {
                    _vm.activeTab = $event
                  },
                  "update:submited": function($event) {
                    _vm.submited = $event
                  },
                  onSave: function($event) {
                    return _vm.updateSubscription()
                  }
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "header",
          fn: function() {
            return [
              _c("employee-header", {
                attrs: {
                  "access-types": _vm.accessTypes,
                  employee: _vm.employee
                },
                on: {
                  "update:employee": function($event) {
                    _vm.employee = $event
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("employee-sidebar-time-attendance", {
        attrs: {
          "time-attendances": _vm.timeAttendances,
          "time-attendance": _vm.timeAttendanceEdit,
          employee: _vm.employee,
          pending: _vm.pending
        },
        on: {
          "update:timeAttendance": function($event) {
            _vm.timeAttendanceEdit = $event
          },
          "update:time-attendance": function($event) {
            _vm.timeAttendanceEdit = $event
          },
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: function($event) {
            return _vm.getEmployeeTimeAttendances()
          },
          onDelete: function($event) {
            _vm.onDelete = _vm.onDeleteTimeAttendance
            _vm.showDelete($event, "timeAttendance")
          }
        }
      }),
      _c("employee-sidebar-add-tip-distribution", {
        attrs: {
          "tip-distributions": _vm.tipDistributions,
          "tip-distribution": _vm.tipDistributionEdit,
          employee: _vm.employee,
          pending: _vm.pending
        },
        on: {
          "update:tipDistribution": function($event) {
            _vm.tipDistributionEdit = $event
          },
          "update:tip-distribution": function($event) {
            _vm.tipDistributionEdit = $event
          },
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: function($event) {
            return _vm.getEmployeeTipDistributions()
          },
          onDelete: function($event) {
            _vm.onDelete = _vm.onDeleteTipDistribution
            _vm.showDelete($event, "tipDistribution")
          }
        }
      }),
      _c("employee-sidebar-form-time-attendance", {
        attrs: {
          employee: _vm.employee,
          "time-attendance": _vm.timeAttendanceEdit,
          pending: _vm.pending
        },
        on: {
          "update:timeAttendance": function($event) {
            _vm.timeAttendanceEdit = $event
          },
          "update:time-attendance": function($event) {
            _vm.timeAttendanceEdit = $event
          },
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: function($event) {
            return _vm.getEmployeeTimeAttendances()
          }
        }
      }),
      _c("employee-sidebar-edit-tip-distribution", {
        attrs: {
          employee: _vm.employee,
          "tip-distribution": _vm.tipDistributionEdit,
          pending: _vm.pending
        },
        on: {
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: function($event) {
            return _vm.getEmployeeTipDistributions()
          }
        }
      }),
      _c("employee-sidebar-add-job-role", {
        attrs: {
          "job-roles": _vm.jobRoles,
          "job-role": _vm.jobRoleEdit,
          employee: _vm.employee,
          pending: _vm.pending
        },
        on: {
          "update:jobRole": function($event) {
            _vm.jobRoleEdit = $event
          },
          "update:job-role": function($event) {
            _vm.jobRoleEdit = $event
          },
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: _vm.onUpdateJobRole,
          onDelete: function($event) {
            _vm.onDelete = _vm.onDeleteJobRole
            _vm.showDelete($event, "jobRole")
          }
        }
      }),
      _c("employee-sidebar-edit-job-role", {
        attrs: {
          employee: _vm.employee,
          "job-role": _vm.jobRoleEdit,
          pending: _vm.pending
        },
        on: {
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: _vm.onUpdateJobRole
        }
      }),
      _c("employee-sidebar-meal-plan", {
        attrs: {
          "meal-plans": _vm.mealPlans,
          "meal-plan": _vm.mealPlanEdit,
          pending: _vm.pending
        },
        on: {
          "update:mealPlan": function($event) {
            _vm.mealPlanEdit = $event
          },
          "update:meal-plan": function($event) {
            _vm.mealPlanEdit = $event
          },
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: _vm.getEmployeeMealPlans,
          onDelete: function($event) {
            _vm.onDelete = _vm.onDeleteMealPlan
            _vm.showDelete($event, "mealPlan")
          }
        }
      }),
      _c("employee-sidebar-access-type", {
        attrs: {
          accessTypes: _vm.accessTypes,
          accessType: _vm.accessTypeEdit,
          pending: _vm.pending
        },
        on: {
          "update:accessType": function($event) {
            _vm.accessTypeEdit = $event
          },
          "update:access-type": function($event) {
            _vm.accessTypeEdit = $event
          },
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: function($event) {
            return _vm.getEmployeeAccessTypes()
          },
          onDelete: function($event) {
            _vm.onDelete = _vm.onDeleteAccessType
            _vm.showDelete($event, "accessType")
          }
        }
      }),
      _c("employee-sidebar-form-access-type", {
        attrs: {
          employee: _vm.employee,
          "job-roles": _vm.jobRoles,
          "key-groups": _vm.keyGroups,
          modes: _vm.modes,
          "requisition-indexes": _vm.requisitionIndexes,
          "tables-sections": _vm.tablesSections,
          "access-type": _vm.accessTypeEdit,
          pending: _vm.pending
        },
        on: {
          "update:accessType": function($event) {
            _vm.accessTypeEdit = $event
          },
          "update:access-type": function($event) {
            _vm.accessTypeEdit = $event
          },
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: function($event) {
            return _vm.getEmployeeAccessTypes()
          }
        }
      }),
      _c("employee-sidebar-form-meal-plan", {
        attrs: {
          employee: _vm.employee,
          "meal-plan": _vm.mealPlanEdit,
          pending: _vm.pending
        },
        on: {
          "update:mealPlan": function($event) {
            _vm.mealPlanEdit = $event
          },
          "update:meal-plan": function($event) {
            _vm.mealPlanEdit = $event
          },
          "update:pending": function($event) {
            _vm.pending = $event
          },
          success: _vm.getEmployeeMealPlans
        }
      }),
      _c("employee-sidebar-form-time-off", {
        attrs: {
          employee: _vm.employee,
          "time-off": _vm.timeOffEdit,
          pending: _vm.pending
        },
        on: {
          "update:timeOff": function($event) {
            _vm.timeOffEdit = $event
          },
          "update:time-off": function($event) {
            _vm.timeOffEdit = $event
          },
          "update:pending": function($event) {
            _vm.pending = $event
          },
          onUpdate: function($event) {
            return _vm.onUpdateTimeOff($event)
          }
        }
      }),
      _c(
        "b-tabs",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pending,
              expression: "pending"
            }
          ],
          staticClass: "bg-grey",
          attrs: { active: "" },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("employeeCreate.tabs.personalInformation"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("employee-form-personal-information", {
                attrs: {
                  employee: _vm.employee,
                  languages: _vm.languages,
                  submited: _vm.submited,
                  error: _vm.isError
                },
                on: {
                  "update:employee": function($event) {
                    _vm.employee = $event
                  },
                  "update:error": function($event) {
                    _vm.isError = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("employeeCreate.jobRole.title"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("employee-form-job-role", {
                attrs: {
                  employee: _vm.employee,
                  "job-roles": _vm.jobRoles,
                  accessTypes: _vm.accessTypes,
                  "time-attendances": _vm.timeAttendances,
                  "tip-distributions": _vm.tipDistributions,
                  "meal-plans": _vm.mealPlans,
                  error: _vm.isError
                },
                on: {
                  "update:employee": function($event) {
                    _vm.employee = $event
                  },
                  "update:error": function($event) {
                    _vm.isError = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("employeeCreate.availability.title"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("employee-availability", {
                ref: "availabilityForm",
                attrs: {
                  loading: _vm.timeOffPending,
                  employee: _vm.employee,
                  "time-offs": _vm.timeOffs,
                  error: _vm.isError
                },
                on: {
                  "update:employee": function($event) {
                    _vm.employee = $event
                  },
                  onSubmit: function($event) {
                    return _vm.onUpdateTimeOff($event)
                  },
                  onModify: function($event) {
                    _vm.timeOffEdit = $event.item
                  },
                  onDelete: function($event) {
                    return _vm.onDeleteTimeOff($event)
                  },
                  "update:error": function($event) {
                    _vm.isError = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("employeeCreate.links.title"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("employee-form-links", {
                attrs: {
                  employee: _vm.employee,
                  "inventory-localisations": _vm.inventoryLocalisations,
                  departments: _vm.departments,
                  "redirections-tables": _vm.redirectionsTables,
                  suppliers: _vm.suppliers,
                  "floor-plans": _vm.floorPlans,
                  "tables-sections": _vm.tablesSections,
                  workstations: _vm.workstations,
                  submited: _vm.submited,
                  error: _vm.isError
                },
                on: {
                  "update:employee": function($event) {
                    _vm.employee = $event
                  },
                  "update:error": function($event) {
                    _vm.isError = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("employee-delete-modal", {
        attrs: {
          title: _vm.deleteModalTitle,
          body: _vm.deleteModalBody,
          show: !!_vm.deleteEntity
        },
        on: {
          onDelete: function($event) {
            return _vm.onDelete(_vm.deleteEntity)
          },
          onCancel: function($event) {
            _vm.deleteEntity = null
          }
        }
      }),
      _c("employee-delete-error-modal", {
        attrs: {
          title: _vm.deleteErrorModalTitle,
          "title-label": _vm.deleteErrorModalSubTitle,
          "file-identifier": _vm.deleteErrorModalIdentifier,
          "file-name": _vm.deleteErrorModalFileName,
          body: _vm.deleteErrorModalBody,
          list: _vm.deleteErrorEmployeeList || [],
          "location-name": _vm.locationName,
          show: !!_vm.deleteErrorEmployeeList
        },
        on: {
          onDelete: function($event) {
            return _vm.onDelete(_vm.deleteEntity)
          },
          onCancel: function($event) {
            _vm.deleteErrorEmployeeList = null
          }
        }
      }),
      _vm.showReactivation
        ? _c("employee-reactivate", {
            attrs: { employee: _vm.employee },
            on: {
              "update:employee": function($event) {
                _vm.employee = $event
              },
              onCancel: function($event) {
                _vm.showReactivation = false
              },
              onSuccess: function($event) {
                _vm.showReactivation = false
                _vm.updateSubscription(true)
              }
            }
          })
        : _vm._e(),
      _c("employee-history", { attrs: { employee: _vm.employee } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }