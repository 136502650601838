var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "page__settings-page",
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_c("div", [_vm._v(_vm._s(_vm.$t("pages.globalSettings")))])]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isMediumDown
        ? _c("vel-icon", {
            staticClass: "menu",
            attrs: { name: "hamburger", size: "medium" },
            on: {
              click: function($event) {
                _vm.isCollapse = !_vm.isCollapse
              }
            }
          })
        : _vm._e(),
      _c(
        "vel-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "vel-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCollapse || _vm.isMediumUp,
                  expression: "isCollapse || isMediumUp"
                }
              ],
              attrs: { span: 8 }
            },
            [
              _c("vel-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.tree,
                  props: _vm.defaultProps,
                  "node-key": "id",
                  "default-expand-all": true,
                  "expand-on-click-node": false,
                  "default-expanded-keys": _vm.defaultExpandedKeys
                },
                on: { "node-click": _vm.handleNodeClick }
              })
            ],
            1
          ),
          _c(
            "vel-col",
            { staticClass: "content-wrapper", attrs: { span: 16 } },
            [
              _c(
                "div",
                { staticClass: "arian" },
                _vm._l(_vm.getSelectedPath(), function(link) {
                  return _c("span", { key: link }, [
                    _vm._v(_vm._s(_vm.$t("settings.menu." + link)))
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _vm.parents[_vm.selectedNodeId]
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "group" },
                          _vm._l(_vm.parents[_vm.selectedNodeId], function(e) {
                            return _c(
                              "div",
                              {
                                key: e.id,
                                on: {
                                  click: function($event) {
                                    return _vm.setActivePath(e.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("settings.menu." + e.id)) +
                                    " "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm._l(_vm.elements, function(opt) {
                    return _c(
                      "div",
                      { key: opt.title },
                      [
                        _c(
                          "vel-divider",
                          { attrs: { "content-position": "left" } },
                          [_vm._v(_vm._s(opt.title))]
                        ),
                        _vm._l(opt.childs, function(el) {
                          return [
                            el.kind === "select"
                              ? [
                                  _c(
                                    "small",
                                    { key: el.id, staticClass: "label" },
                                    [_vm._v(_vm._s(el.label))]
                                  ),
                                  _c(
                                    "vel-select",
                                    {
                                      key: "select" + el.id,
                                      staticStyle: { "min-width": "320px" },
                                      on: { change: _vm.update },
                                      model: {
                                        value: _vm.config[el.id].value,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.config[el.id],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "config[el.id].value"
                                      }
                                    },
                                    _vm._l(el.options, function(opt) {
                                      return _c(
                                        "vel-option",
                                        {
                                          key: opt.key,
                                          attrs: {
                                            label: _vm.$t(
                                              "editors.options." + opt.key,
                                              {
                                                date: _vm.getDateByFormat(
                                                  opt.value
                                                )
                                              }
                                            ),
                                            value: opt.value
                                          }
                                        },
                                        [
                                          opt.icon
                                            ? _c("vel-icon", {
                                                staticClass: "option_icon",
                                                attrs: { name: opt.icon }
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "editors.options." + opt.key,
                                                  {
                                                    date: _vm.getDateByFormat(
                                                      opt.value
                                                    )
                                                  }
                                                )
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ]
                              : _c("vel-checkbox", {
                                  key: el.id,
                                  attrs: { label: el.label },
                                  on: { change: _vm.update },
                                  model: {
                                    value: _vm.config[el.id].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.config[el.id], "value", $$v)
                                    },
                                    expression: "config[el.id].value"
                                  }
                                })
                          ]
                        })
                      ],
                      2
                    )
                  })
                ],
                2
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }