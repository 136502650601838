var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-form",
    {
      ref: "bigDivisionForm",
      staticClass: "big-division-form",
      attrs: {
        model: _vm.bigDivisionForm,
        rules: _vm.bigDivisionFormRules,
        submit: _vm.handleSubmit,
        "label-width": "150px",
        "label-position": _vm.labelPosition
      }
    },
    [
      _c(
        "vel-form-item",
        {
          staticClass: "big-division-form-remoteId",
          attrs: {
            prop: "remoteId",
            label: _vm.$t("bigDivisionInfo.form.remoteId.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: {
              disabled: "",
              name: "remoteId",
              type: "text",
              value: _vm.bigDivisionForm.remoteId
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "big-division-form-name",
          attrs: {
            prop: "name",
            label: _vm.$t("bigDivisionInfo.form.name.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "name", type: "text", maxlength: "24" },
            model: {
              value: _vm.bigDivisionForm.name,
              callback: function($$v) {
                _vm.$set(_vm.bigDivisionForm, "name", $$v)
              },
              expression: "bigDivisionForm.name"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "big-division-form-alternative-name",
          attrs: {
            prop: "alternativeName",
            label: _vm.$t("bigDivisionInfo.form.alternativeName.label")
          }
        },
        [
          _c("vel-input", {
            staticClass: "name-input",
            attrs: { name: "alternativeName", type: "text", maxlength: "24" },
            model: {
              value: _vm.bigDivisionForm.alternativeName,
              callback: function($$v) {
                _vm.$set(_vm.bigDivisionForm, "alternativeName", $$v)
              },
              expression: "bigDivisionForm.alternativeName"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        {
          staticClass: "big-division-form-operation-assessment",
          attrs: {
            prop: "operationAssessment",
            label: _vm.$t("bigDivisionInfo.form.operationAssessment.label")
          }
        },
        [
          _c("vel-switch", {
            model: {
              value: _vm.bigDivisionForm.operationAssessment,
              callback: function($$v) {
                _vm.$set(_vm.bigDivisionForm, "operationAssessment", $$v)
              },
              expression: "bigDivisionForm.operationAssessment"
            }
          })
        ],
        1
      ),
      _c(
        "vel-form-item",
        { staticClass: "big-division-form__actions" },
        [
          _c(
            "vel-button",
            {
              staticClass: "actions__submit",
              attrs: { type: "primary", loading: _vm.pending },
              on: {
                click: function($event) {
                  return _vm.handleSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.save")) + " ")]
          ),
          _c(
            "vel-button",
            {
              staticClass: "actions__cancel",
              attrs: { type: "secondary", loading: _vm.pending },
              on: {
                click: function($event) {
                  return _vm.handleCancel()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("defaults.actions.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }