var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "vel-topbar", attrs: { role: "navigation" } },
    [
      _c("div", { staticClass: "vel-topbar__left" }, [_vm._t("left")], 2),
      _c("div", { staticClass: "vel-topbar__right" }, [_vm._t("right")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }