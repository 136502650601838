<template>
  <vel-backoffice-page
    class="backoffice-page"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    mode="edit"
    :loading="loading"
  >
    <template v-slot:footer>
      <employee-footer
        :activeTab.sync="activeTab"
        :employee="employee"
        :submited.sync="submited"
        :canContinue="canContinue"
        :canSave="canSave"
        @onSave="updateSubscription()"
      ></employee-footer>
    </template>
    <template v-slot:header>
      <employee-header :access-types="accessTypes" :employee.sync="employee"></employee-header>
    </template>
    <employee-sidebar-time-attendance
      :time-attendances="timeAttendances"
      :time-attendance.sync="timeAttendanceEdit"
      :employee="employee"
      :pending.sync="pending"
      @success="getEmployeeTimeAttendances()"
      @onDelete="
        onDelete = onDeleteTimeAttendance;
        showDelete($event, 'timeAttendance');
      "
    ></employee-sidebar-time-attendance>
    <employee-sidebar-add-tip-distribution
      :tip-distributions="tipDistributions"
      :tip-distribution.sync="tipDistributionEdit"
      :employee="employee"
      :pending.sync="pending"
      @success="getEmployeeTipDistributions()"
      @onDelete="
        onDelete = onDeleteTipDistribution;
        showDelete($event, 'tipDistribution');
      "
    ></employee-sidebar-add-tip-distribution>
    <employee-sidebar-form-time-attendance
      :employee="employee"
      :time-attendance.sync="timeAttendanceEdit"
      :pending.sync="pending"
      @success="getEmployeeTimeAttendances()"
    ></employee-sidebar-form-time-attendance>
    <employee-sidebar-edit-tip-distribution
      :employee="employee"
      :tip-distribution="tipDistributionEdit"
      :pending.sync="pending"
      @success="getEmployeeTipDistributions()"
    ></employee-sidebar-edit-tip-distribution>
    <employee-sidebar-add-job-role
      :job-roles="jobRoles"
      :job-role.sync="jobRoleEdit"
      :employee="employee"
      :pending.sync="pending"
      @success="onUpdateJobRole"
      @onDelete="
        onDelete = onDeleteJobRole;
        showDelete($event, 'jobRole');
      "
    ></employee-sidebar-add-job-role>
    <employee-sidebar-edit-job-role
      :employee="employee"
      :job-role="jobRoleEdit"
      :pending.sync="pending"
      @success="onUpdateJobRole"
    ></employee-sidebar-edit-job-role>
    <employee-sidebar-meal-plan
      :meal-plans="mealPlans"
      :meal-plan.sync="mealPlanEdit"
      :pending.sync="pending"
      @success="getEmployeeMealPlans"
      @onDelete="
        onDelete = onDeleteMealPlan;
        showDelete($event, 'mealPlan');
      "
    ></employee-sidebar-meal-plan>
    <employee-sidebar-access-type
      :accessTypes="accessTypes"
      :accessType.sync="accessTypeEdit"
      :pending.sync="pending"
      @success="getEmployeeAccessTypes()"
      @onDelete="
        onDelete = onDeleteAccessType;
        showDelete($event, 'accessType');
      "
    ></employee-sidebar-access-type>
    <employee-sidebar-form-access-type
      :employee="employee"
      :job-roles="jobRoles"
      :key-groups="keyGroups"
      :modes="modes"
      :requisition-indexes="requisitionIndexes"
      :tables-sections="tablesSections"
      :access-type.sync="accessTypeEdit"
      :pending.sync="pending"
      @success="getEmployeeAccessTypes()"
    ></employee-sidebar-form-access-type>
    <employee-sidebar-form-meal-plan
      :employee="employee"
      :meal-plan.sync="mealPlanEdit"
      :pending.sync="pending"
      @success="getEmployeeMealPlans"
    ></employee-sidebar-form-meal-plan>
    <employee-sidebar-form-time-off
      :employee="employee"
      :time-off.sync="timeOffEdit"
      :pending.sync="pending"
      @onUpdate="onUpdateTimeOff($event)"
    ></employee-sidebar-form-time-off>
    <b-tabs active class="bg-grey" v-loading="pending" v-model="activeTab">
      <b-tab :title="$t('employeeCreate.tabs.personalInformation')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <employee-form-personal-information
          :employee.sync="employee"
          :languages="languages"
          :submited="submited"
          :error.sync="isError"
        ></employee-form-personal-information>
      </b-tab>

      <b-tab :title="$t('employeeCreate.jobRole.title')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <employee-form-job-role
          :employee.sync="employee"
          :job-roles="jobRoles"
          :accessTypes="accessTypes"
          :time-attendances="timeAttendances"
          :tip-distributions="tipDistributions"
          :meal-plans="mealPlans"
          :error.sync="isError"
        ></employee-form-job-role>
      </b-tab>

      <b-tab :title="$t('employeeCreate.availability.title')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <employee-availability
          :loading="timeOffPending"
          :employee.sync="employee"
          :time-offs="timeOffs"
          @onSubmit="onUpdateTimeOff($event)"
          @onModify="timeOffEdit = $event.item"
          @onDelete="onDeleteTimeOff($event)"
          ref="availabilityForm"
          :error.sync="isError"
        ></employee-availability>
      </b-tab>

      <b-tab :title="$t('employeeCreate.links.title')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <employee-form-links
          :employee.sync="employee"
          :inventory-localisations="inventoryLocalisations"
          :departments="departments"
          :redirections-tables="redirectionsTables"
          :suppliers="suppliers"
          :floor-plans="floorPlans"
          :tables-sections="tablesSections"
          :workstations="workstations"
          :submited="submited"
          :error.sync="isError"
        ></employee-form-links>
      </b-tab>
    </b-tabs>
    <employee-delete-modal
      :title="deleteModalTitle"
      :body="deleteModalBody"
      :show="!!deleteEntity"
      @onDelete="onDelete(deleteEntity)"
      @onCancel="deleteEntity = null"
    ></employee-delete-modal>
    <employee-delete-error-modal
      :title="deleteErrorModalTitle"
      :title-label="deleteErrorModalSubTitle"
      :file-identifier="deleteErrorModalIdentifier"
      :file-name="deleteErrorModalFileName"
      :body="deleteErrorModalBody"
      :list="deleteErrorEmployeeList || []"
      :location-name="locationName"
      :show="!!deleteErrorEmployeeList"
      @onDelete="onDelete(deleteEntity)"
      @onCancel="deleteErrorEmployeeList = null"
    ></employee-delete-error-modal>
    <employee-reactivate
      :employee.sync="employee"
      v-if="showReactivation"
      @onCancel="showReactivation = false"
      @onSuccess="
        showReactivation = false;
        updateSubscription(true);
      "
    ></employee-reactivate>
    <employee-history :employee="employee"></employee-history>
  </vel-backoffice-page>
</template>

<script>
import {
  deleteEmployeeAccessType,
  deleteEmployeeMealPlan,
  deleteEmployeeTask,
  deleteEmployeeTimeOffs,
  deleteEmployeeTipDistribution,
  getEmployeeAccessTypes,
  getEmployeeMealPlans,
  getEmployeeTimeOff,
  getEmployeeTipDistributions,
  getEmployees,
  getEmployeesByID,
  getEmployeesTasks,
  patchEmployee,
  patchEmployeeTimeOff,
  postEmployee,
  postEmployeeTimeOff
} from '@/services/employees.service';
import { deleteTimeAttendanceDepartment, getTimeAttendanceDepartments } from '@/services/timeAttendances';
import { getInventoryLocalisations, getInventoryRequisitionIndexes } from '@/services/inventory';
import EmployeeAvailability from '@/private/employees/components/EmployeeAvailability';
import EmployeeDeleteErrorModal from '@/private/components/employees/components/EmployeeDeleteErrorModal';
import EmployeeDeleteModal from '@/private/components/employees/components/EmployeeDeleteModal';
import EmployeeFooter from '@/private/employees/components/EmployeeFooter';
import EmployeeFormJobRole from '@/private/employees/components/EmployeeFormJobRole';
import EmployeeFormLinks from '@/private/employees/components/EmployeeFormLinks';
import EmployeeFormPersonalInformation from '@/private/employees/components/EmployeeFormPersonalInformation';
import EmployeeHeader from '@/private/employees/components/EmployeeHeader';
import EmployeeHistory from '@/private/components/employees/components/EmployeeHistory';
import EmployeeReactivate from '@/private/components/employees/components/EmployeeReactivate';
import EmployeeSidebarAccessType from '@/private/employees/components/EmployeeSidebarAccessType';
import EmployeeSidebarAddJobRole from '@/private/employees/components/EmployeeSidebarAddJobRole';
import EmployeeSidebarAddTipDistribution from '@/private/employees/components/EmployeeSidebarAddTipDistribution';
import EmployeeSidebarEditJobRole from '@/private/employees/components/EmployeeSidebarEditJobRole';
import EmployeeSidebarEditTipDistribution from '@/private/employees/components/EmployeeSidebarEditTipDistribution';
import EmployeeSidebarFormAccessType from '@/private/employees/components/EmployeeSidebarFormAccessType';
import EmployeeSidebarFormMealPlan from '@/private/employees/components/EmployeeSidebarFormMealPlan';
import EmployeeSidebarFormTimeAttendance from '@/private/employees/components/EmployeeSidebarFormTimeAttendance';
import EmployeeSidebarFormTimeOff from '@/private/employees/components/EmployeeSidebarFormTimeOff';
import EmployeeSidebarMealPlan from '@/private/employees/components/EmployeeSidebarMealPlan';
import EmployeeSidebarTimeAttendance from '@/private/employees/components/EmployeeSidebarTimeAttendance';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import { accessTypeDefaultFormValue } from '@/private/components/access-type/components/AccessTypeForm';
import cloneDeep from 'lodash.clonedeep';
import { getFloorPlans } from '@/services/floorPlans';
import { getKeyGroups } from '@/services/keyGroups';
import { getLanguages } from '@/services/configurations';
import { getModes } from '@/services/modes';
import { getRedirectionTables } from '@/services/redirectionsTables';
import { getRevenueCenters } from '@/services/revenuecenters.service';
import { getSuppliers } from '@/services/suppliers';
import { getTablesSections } from '@/services/tables';
import { getToken } from '@/helpers/token.helper';
import { getWorkstations } from '@/services/workstations';
import locationService from '@/services/locations.service';
import { slugify } from '@/helpers/string.helpers';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataLocationsStore} from "@/stores/data/locations.module";

export default {
  name: 'EmployeeCreatePage',
  components: {
    VelBackofficePage,
    EmployeeAvailability,
    EmployeeDeleteErrorModal,
    EmployeeDeleteModal,
    EmployeeFormJobRole,
    EmployeeFooter,
    EmployeeHeader,
    EmployeeFormLinks,
    EmployeeFormPersonalInformation,
    EmployeeReactivate,
    EmployeeSidebarAccessType,
    EmployeeSidebarAddTipDistribution,
    EmployeeSidebarAddJobRole,
    EmployeeSidebarEditJobRole,
    EmployeeSidebarFormAccessType,
    EmployeeSidebarTimeAttendance,
    EmployeeSidebarEditTipDistribution,
    EmployeeSidebarMealPlan,
    EmployeeSidebarFormTimeAttendance,
    EmployeeSidebarFormMealPlan,
    EmployeeSidebarFormTimeOff,
    EmployeeHistory
  },
  props: {},
  methods: {
    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    showDelete(entity, type = 'mealPlan') {
      this.deleteEntity = entity;
      this.deleteModalTitle = this.$t(`employee.deleteModal.${type}.title`, {
        name: this.deleteEntity && this.deleteEntity.name
      });
      this.deleteModalBody = this.$t(`employee.deleteModal.${type}.body`, {
        name: this.deleteEntity && this.deleteEntity.name
      });
    },

    onUpdateTimeOff(timeOff) {
      try {
        this.timeOffPending = true;

        if (timeOff.id || timeOff.tmpId) {
          this.timeOffs.forEach((t, k) => {
            if ((t.id && t.id === timeOff.id) || (t.tmpId && t.tmpId === timeOff.tmpId)) {
              this.timeOffs[k] = {
                ...timeOff,
                startDate: new Date(new Date(timeOff.startDate).setUTCHours(5, 59, 59, 999)).toISOString(),
                endDate: new Date(new Date(timeOff.endDate).setUTCHours(23, 59, 59, 999)).toISOString()
              };
              this.timeOffs = [...this.timeOffs];
            }
          });
        } else {
          this.timeOffs.push({
            ...timeOff,
            tmpId: new Date().getTime(),
            startDate: new Date(new Date(timeOff.startDate).setUTCHours(5, 59, 59, 999)).toISOString(),
            endDate: new Date(new Date(timeOff.endDate).setUTCHours(23, 59, 59, 999)).toISOString()
          });
        }
        // await postEmployeeTimeOff(this.employee.id, []);
        this.$refs.availabilityForm.clear();
        // await this.getEmployeeTimeOff();
      } finally {
        this.timeOffPending = false;
      }
    },

    onDeleteTimeOff(timeOff) {
      try {
        this.timeOffPending = true;
        this.timeOffs.forEach((t, k) => {
          if ((t.id && t.id === timeOff.id) || (t.tmpId && t.tmpId === timeOff.tmpId)) {
            this.timeOffs[k] = {
              ...timeOff,
              deleted: true
            };
            this.timeOffs = [...this.timeOffs];
          }
        });
      } finally {
        this.timeOffPending = false;
      }
    },
    async onUpdateJobRole(jobRole) {
      await this.getEmployeeTasks();

      if (jobRole.remoteId) {
        (this.employee.jobRoles || []).forEach((j, k) => {
          if (j.task.remoteId === jobRole.remoteId) {
            this.employee.jobRoles[k].task.name = jobRole.name;
            this.$forceUpdate();
          }
        });
      }
    },
    async onDeleteMealPlan(scope) {
      this.deleteErrorEmployeeList = null;
      await deleteEmployeeMealPlan(scope.id)
        .then(async () => {
          await this.getEmployeeMealPlans();
          this.deleteEntity = null;
        })
        .catch(async e => {
          const location = await locationService.getLocations(getToken(), {
            licenceNumber: this.employee.licenceNumber
          });
          const employees = ((await getEmployees({ locationId: location[0].id })) || []).filter(
            employee => (e || []).map(empl => empl.id).indexOf(employee.id) >= 0
          );
          this.deleteErrorModalBody = this.$t('employee.deleteModal.error.mealPlan.body', {
            count: (employees || []).length,
            name: this.deleteEntity.name
          });
          this.deleteErrorModalTitle = this.$t('employee.deleteModal.error.mealPlan.title', {
            name: this.deleteEntity.name
          });
          this.deleteErrorModalSubTitle = this.$t('employee.deleteModal.error.mealPlan.subTitle');
          this.deleteErrorModalIdentifier = slugify(this.deleteEntity.name);
          this.deleteErrorModalFileName = slugify(this.$t('employeeCreate.mealPlan.title'));
          this.deleteEntity = null;
          this.deleteErrorEmployeeList = employees;
        });
    },
    async onDeleteAccessType(scope) {
      this.deleteErrorEmployeeList = null;
      await deleteEmployeeAccessType(scope.id)
        .then(async () => {
          await this.getEmployeeAccessTypes();
          this.deleteEntity = null;
        })
        .catch(async e => {
          const location = await locationService.getLocations(getToken(), {
            licenceNumber: this.employee.licenceNumber
          });
          const employees = ((await getEmployees({ locationId: location[0].id })) || []).filter(
            employee => (e || []).map(empl => empl.id).indexOf(employee.id) >= 0
          );
          this.deleteErrorModalBody = this.$t('employee.deleteModal.error.accessType.body', {
            count: (employees || []).length,
            name: this.deleteEntity.name
          });
          this.deleteErrorModalTitle = this.$t('employee.deleteModal.error.accessType.title', {
            name: this.deleteEntity.name
          });
          this.deleteErrorModalSubTitle = this.$t('employee.deleteModal.error.accessType.subTitle');
          this.deleteErrorModalIdentifier = slugify(this.deleteEntity.name);
          this.deleteErrorModalFileName = slugify(this.$t('employeeCreate.accessType.title'));
          this.deleteEntity = null;
          this.deleteErrorEmployeeList = employees;
        });
    },
    async onDeleteJobRole(scope) {
      this.deleteErrorEmployeeList = null;
      await deleteEmployeeTask(scope.id)
        .then(async () => {
          await this.getEmployeeTasks();
          this.deleteEntity = null;
        })
        .catch(async e => {
          const location = await locationService.getLocations(getToken(), {
            licenceNumber: this.employee.licenceNumber
          });
          const employees = ((await getEmployees({ locationId: location[0].id })) || []).filter(
            employee => (e || []).map(empl => empl.id).indexOf(employee.id) >= 0
          );
          this.deleteErrorModalBody = this.$t('employee.deleteModal.error.jobRole.body', {
            count: (employees || []).length,
            name: this.deleteEntity.name
          });
          this.deleteErrorModalTitle = this.$t('employee.deleteModal.error.jobRole.title', {
            name: this.deleteEntity.name
          });
          this.deleteErrorModalSubTitle = this.$t('employee.deleteModal.error.jobRole.subTitle');
          this.deleteErrorModalIdentifier = slugify(this.deleteEntity.name);
          this.deleteErrorModalFileName = slugify(this.$t('employeeCreate.jobRole.title'));
          this.deleteEntity = null;
          this.deleteErrorEmployeeList = employees;
        });
    },
    async onDeleteTimeAttendance(scope) {
      this.deleteErrorEmployeeList = null;
      await deleteTimeAttendanceDepartment(scope.id)
        .then(async () => {
          await this.getEmployeeTimeAttendances();
          this.deleteEntity = null;
        })
        .catch(async e => {
          const location = await locationService.getLocations(getToken(), {
            licenceNumber: this.employee.licenceNumber
          });
          const employees = ((await getEmployees({ locationId: location[0].id })) || []).filter(
            employee => (e || []).map(empl => empl.id).indexOf(employee.id) >= 0
          );
          this.deleteErrorModalBody = this.$t('employee.deleteModal.error.timeAttendance.body', {
            count: (employees || []).length,
            name: this.deleteEntity.name
          });
          this.deleteErrorModalTitle = this.$t('employee.deleteModal.error.timeAttendance.title', {
            name: this.deleteEntity.name
          });
          this.deleteErrorModalSubTitle = this.$t('employee.deleteModal.error.timeAttendance.subTitle');
          this.deleteErrorModalIdentifier = slugify(this.deleteEntity.name);
          this.deleteErrorModalFileName = slugify(this.$t('employeeCreate.timeAttendance.title'));
          this.deleteEntity = null;
          this.deleteErrorEmployeeList = employees;
        });
    },
    async onDeleteTipDistribution(scope) {
      this.deleteErrorEmployeeList = null;
      await deleteEmployeeTipDistribution(scope.id)
        .then(async () => {
          await this.getEmployeeTipDistributions();
          this.deleteEntity = null;
        })
        .catch(async e => {
          const location = await locationService.getLocations(getToken(), {
            licenceNumber: this.employee.licenceNumber
          });
          const employees = ((await getEmployees({ locationId: location[0].id })) || []).filter(
            employee => (e || []).map(empl => empl.id).indexOf(employee.id) >= 0
          );
          this.deleteErrorModalBody = this.$t('employee.deleteModal.error.tipDistribution.body', {
            count: (employees || []).length,
            name: this.deleteEntity.name
          });
          this.deleteErrorModalTitle = this.$t('employee.deleteModal.error.tipDistribution.title', {
            name: this.deleteEntity.name
          });
          this.deleteErrorModalSubTitle = this.$t('employee.deleteModal.error.tipDistribution.subTitle');
          this.deleteErrorModalIdentifier = slugify(this.deleteEntity.name);
          this.deleteErrorModalFileName = slugify(this.$t('employeeCreate.tipDistribution.title'));
          this.deleteEntity = null;
          this.deleteErrorEmployeeList = employees;
        });
    },
    async getEmployeeTimeOff() {
      const results = await getEmployeeTimeOff(this.employee.id);
      this.timeOffs = (results.content || []).sort((a, b) => a.endDate.localeCompare(b.endDate));
      this.timeOffsOrig = [...this.timeOffs];
    },
    async getEmployeeTasks() {
      const results = await getEmployeesTasks({ locationId: this.locationId });
      this.jobRoles = (results.content || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getEmployeeAccessTypes() {
      const results = await getEmployeeAccessTypes({ locationId: this.locationId });
      this.accessTypes = (results.content || [])
        .map(at => ({
          ...at,
          accessTypes: {
            ...at.accessTypes,
            dining: at.accessTypes.dining || accessTypeDefaultFormValue.accessTypes.dining,

            diningBtn: at.accessTypes.diningBtn.length
              ? at.accessTypes.diningBtn
              : [{ keyGroupId: null, isDefault: false }]
          }
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    async getEmployeeKeyGroups() {
      const results = await getKeyGroups({ locationId: this.locationId });
      this.keyGroups = (results.content || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getInventoryLocalisations() {
      const results = await getInventoryLocalisations({ locationId: this.locationId });
      this.inventoryLocalisations = (results.content || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getInventoryRequisitionIndexes() {
      const results = await getInventoryRequisitionIndexes({ locationId: this.locationId });
      this.requisitionIndexes = (results.content || []).sort((a, b) => a.name.localeCompare(b.name));
    },

    async getDepartments() {
      const results = await getRevenueCenters({ locationId: this.locationId });
      this.departments = (results || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getRedirectionsTables() {
      const results = await getRedirectionTables({ locationId: this.locationId });
      this.redirectionsTables = (results || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getSuppliers() {
      const results = await getSuppliers({ locationId: this.locationId });
      this.suppliers = (results || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getFloorPlans() {
      const results = await getFloorPlans({ locationId: this.locationId });
      this.floorPlans = (results || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getTablesSections() {
      const results = await getTablesSections({ locationId: this.locationId });
      this.tablesSections = (results || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getWorkstations() {
      const results = await getWorkstations({ locationId: this.locationId });
      this.workstations = (results || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getLanguages() {
      const results = await getLanguages({ locationId: this.locationId });
      this.languages = (results.content || [])
        .sort((a, b) => a.description.localeCompare(b.description))
        .filter(l => l.isActive);
    },
    async getModes() {
      const results = await getModes({ locationId: this.locationId });
      this.modes = (results || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getEmployeeTimeAttendances() {
      const results = await getTimeAttendanceDepartments({ locationId: this.locationId });
      this.timeAttendances = (results.content || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getEmployeeTipDistributions() {
      const location = await locationService.getLocations(getToken(), { licenceNumber: this.employee.licenceNumber });
      const results = await getEmployeeTipDistributions({ locationId: location[0].id });
      this.tipDistributions = (results.content || []).sort((a, b) => a.name.localeCompare(b.name));
    },
    async getEmployeeMealPlans() {
      const location = await locationService.getLocations(getToken(), { licenceNumber: this.employee.licenceNumber });
      this.locationName = location[0].name;
      const results = await getEmployeeMealPlans({ locationId: location[0].id });
      this.mealPlans = (results.content || [])
        .map(m => ({
          ...m,
          eligibility: (m.eligibility || [{ duration: null, amount: null }]).map(e => ({
            ...e,
            amount: Number(e.amount).toFixed(2)
          }))
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    checkMask(id, mask) {
      const el = document.getElementById(id);
      el.setAttribute('v-mask', mask);
    },
    async updateSubscription(forceUpdate = false) {
      if (this.employeeOrig.terminated === true && this.employee.terminated === false && !forceUpdate) {
        return (this.showReactivation = true);
      }
      if (this.employeeOrig.terminated === false && this.employee.terminated === true && !forceUpdate) {
        return (this.showReactivation = true);
      }
      this.pending = true;
      this.submited = true;
      this.isError = false;
      try {
        if (this.employee.id) {
          await patchEmployee(this.employee.id, {
            ...this.employee,
            hiringDate: this.employee.hiringDate ? new Date(this.employee.hiringDate).toISOString() : null,
            birthDate: this.employee.birthDate ? new Date(this.employee.birthDate).toISOString() : null,
            deduction: Number(this.employee.deduction),
            fringeBenefit: Number(this.employee.fringeBenefit),
            payrollCode: this.employee.payrollCode === '' ? 0 : this.employee.payrollCode,
            terminatedAt: this.employee.terminated ? new Date().toISOString() : null,
            owedFeesDelivery: Number(this.employee.owedFeesDelivery),
            jobRoles: (this.employee.jobRoles || [])
              .filter(jr => !(jr.delete === true && !jr.id))
              .map(jr => ({
                ...jr,
                overtimePay: Number(jr.overtimePay || 0),
                regularPay: Number(jr.regularPay || 0)
              }))
          });

          if (this.timeOffs.length) {
            const deletedEmployees = this.timeOffs.filter(t => t.deleted === true && t.id).map(t => t.id);

            await postEmployeeTimeOff(
              this.employee.id,
              this.timeOffs.filter(t => !t.id && t.deleted !== true)
            );
            await patchEmployeeTimeOff(
              this.employee.id,
              this.timeOffs.filter(t => !!t.id && t.deleted !== true)
            );

            if (deletedEmployees.length) {
              await deleteEmployeeTimeOffs(deletedEmployees);
            }
          }
          if (forceUpdate) {
            this.toast(
              !this.employeeOrig.terminated ? 'toast.EmployeeDeactivate' : 'toast.EmployeeReactivate',
              this.employee
            );
          } else {
            this.toast();
          }
          await this.$router.replace({ name: 'employees' });
        } else {
          const employee = await postEmployee({
            ...this.employee,
            hiringDate: this.employee.hiringDate ? new Date(this.employee.hiringDate).toISOString() : null,
            birthDate: this.employee.birthDate ? new Date(this.employee.birthDate).toISOString() : null,
            deduction: Number(this.employee.deduction),
            fringeBenefit: Number(this.employee.fringeBenefit),
            terminatedAt: this.employee.terminated ? new Date().toISOString() : null,
            owedFeesDelivery: Number(this.employee.owedFeesDelivery),
            jobRoles: (this.employee.jobRoles || [])
              .filter(jr => jr.delete !== true)
              .map(jr => ({
                ...jr,
                overtimePay: Number(jr.overtimePay || 0),
                regularPay: Number(jr.regularPay || 0)
              }))
          });
          if (this.timeOffs.length) {
            const deletedEmployees = this.timeOffs.filter(t => t.deleted === true).map(t => t.id);
            await postEmployeeTimeOff(
              employee.id,
              this.timeOffs.filter(t => !t.id && t.deleted !== true)
            );
            await patchEmployeeTimeOff(
              employee.id,
              this.timeOffs.filter(t => !!t.id && t.deleted !== true)
            );

            if (deletedEmployees.filter(et => et && et.id).length) {
              await deleteEmployeeTimeOffs(deletedEmployees.filter(et => et && et.id));
            }
          }
          this.toast('toast.EmployeeCreate', employee);

          await this.$router.replace({ name: 'employees' });
        }
      } catch (e) {
        this.isError = true;
      } finally {
        this.pending = false;
      }
      return null;
    }
  },
  data() {
    return {
      loading: true,
      isError: false,
      pending: false,
      locationId: null,
      employeeOrig: {},
      showReactivation: false,
      employee: {
        jobRoles: [
          {
            delete: false,
            functionId: null,
            timeAttendanceDepartmentId: null,
            tipDistributionId: null,
            taskId: null,
            regularPay: '0.00',
            overtimePay: '0.00',
            task: {
              name: ''
            }
          }
        ],
        phone: {
          home: '',
          mobile: ''
        },
        terminated: false,
        terminatedAt: null,
        terminatedReason: null,
        languageId: null,
        electronicBarType: 0,
        taxableFees: false,
        availability: null,
        pagerNumber: null,
        owedFeesDelivery: null,
        electronicBarKey: '',
        electronicBarSectionNumber: 0,
        srmUsername: '',
        srmPassword: '',
        fringeBenefit: '0.00',
        deduction: '0.00',
        tipDistributionId: null,
        isRightHanded: true,
        timeAttendanceDepartmentId: null,
        departmentId: 0,
        password: '',
        isActive: true,
        integrationCode: '',
        payrollCode: 0,
        code: '',
        floorPlanId: null,
        mealPlanId: null,
        revenueCenterId: null,
        cashDrawer: 0,
        sectionId: 0,
        redirectionTableId: null,
        alternateWorkstationId: null,
        tableSectionId: null,
        supplierId: null,
        inventoryLocalisationId: null
      },
      jobRoles: [],
      redirectionsTables: [],
      suppliers: [],
      floorPlans: [],
      languages: [],
      modes: [],
      tablesSections: [],
      requisitionIndexes: [],
      workstations: [],
      inventoryLocalisations: [],
      departments: [],
      timeAttendanceEdit: null,
      timeAttendances: [],
      keyGroups: [],
      accessTypes: [],
      accessTypeEdit: null,
      tipDistributionEdit: null,
      tipDistributions: [],
      mealPlanEdit: null,
      mealPlans: [],
      timeOffs: [],
      timeOffsOrig: [],
      timeOffEdit: null,
      locationName: '',
      activeTab: 0,
      timeOffPending: false,
      roleName: '',
      jobRoleEdit: null,
      submited: false,
      selected: 'french',
      showDeleteDialog: true,
      onDelete: () => null,
      deleteEntity: null,
      deleteErrorEmployeeList: null,
      deleteModalTitle: '',
      deleteErrorModalTitle: '',
      deleteErrorModalSubTitle: '',
      deleteErrorModalIdentifier: '',
      deleteErrorModalFileName: '',
      deleteModalBody: '',
      deleteErrorModalBody: '',
      terminated: false
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    canContinue() {
      if (this.activeTab === 0) {
        for (const propName of ['name', 'publicName', 'address', 'emailAddress']) {
          if (!this.employee[propName] || this.employee[propName] === '') {
            return false;
          }
        }
        if (this.employee.code !== '' && (Number(this.employee.code) < 1000 || Number(this.employee.code) > 9998)) {
          return false;
        }
        if (
          this.employee.password !== '' &&
          (Number(this.employee.password) < 0 || Number(this.employee.password) > 999)
        ) {
          return false;
        }
        return true;
      }
      if (this.activeTab === 1) {
        for (const jobRole of (this.employee.jobRoles || []).filter(jr => jr.delete !== true)) {
          if (!jobRole.functionId || jobRole.functionId === '') {
            return false;
          }
        }
        return true;
      }
      return true;
    },
    canSave() {
      for (const propName of ['name', 'publicName', 'address', 'emailAddress']) {
        if (!this.employee[propName] || this.employee[propName] === '') {
          return false;
        }
      }
      if (this.employee.code !== '' && (Number(this.employee.code) < 1000 || Number(this.employee.code) > 9998)) {
        return false;
      }
      if (
        this.employee.password !== '' &&
        (Number(this.employee.password) < 0 || Number(this.employee.password) > 999)
      ) {
        return false;
      }
      for (const jobRole of (this.employee.jobRoles || []).filter(jr => jr.delete !== true)) {
        if (!jobRole.functionId || jobRole.functionId === '') {
          return false;
        }
      }
      if (
        JSON.stringify(this.employeeOrig) === JSON.stringify(this.employee) &&
        JSON.stringify(this.timeOffsOrig) === JSON.stringify(this.timeOffs)
      ) {
        return false;
      }

      return true;
    }
  },
  async mounted() {
    try {
      if (this.$route.params.id) {
        this.employee = await getEmployeesByID(this.$route.params.id).then(e => ({
          ...e,
          deduction: (e.deduction || 0).toFixed(2),
          fringeBenefit: (e.fringeBenefit || 0).toFixed(2),
          languageId: e.languageId || null,
          owedFeesDelivery: (e.owedFeesDelivery || 0).toFixed(2),
          jobRoles: (e.jobRoles || []).map(jr => ({
            ...jr,
            overtimePay: jr.overtimePay.toFixed(2),
            regularPay: jr.regularPay.toFixed(2)
          }))
        }));

        if (!this.employee.phone) {
          this.employee.phone = {};
        }
        await this.getEmployeeTimeOff();
      } else {
        this.employee.licenceNumber = (this.getLocationsById(this.$route.query.locationId) || {}).licenceNumber;
      }

      const location = await locationService.getLocations(getToken(), { licenceNumber: this.employee.licenceNumber });
      this.locationId = location[0].id;

      await Promise.all([
        this.getEmployeeTimeAttendances(),
        this.getEmployeeTipDistributions(),
        this.getEmployeeTasks(),
        this.getEmployeeAccessTypes(),
        this.getEmployeeMealPlans(),
        this.getEmployeeKeyGroups(),
        this.getInventoryLocalisations(),
        this.getDepartments(),
        this.getRedirectionsTables(),
        this.getSuppliers(),
        this.getFloorPlans(),
        this.getTablesSections(),
        this.getInventoryRequisitionIndexes(),
        this.getWorkstations(),
        this.getLanguages(),
        this.getModes()
      ]);

      this.employee.jobRoles.forEach((v, k) => {
        if (!this.timeAttendances.filter(t => t.id === v.timeAttendanceDepartmentId).length) {
          this.employee.jobRoles[k].timeAttendanceDepartmentId = null;
        }
        if (!this.tipDistributions.filter(t => t.id === v.tipDistributionId).length) {
          this.employee.jobRoles[k].tipDistributionId = null;
        }
        if (!this.jobRoles.filter(t => t.id === v.taskId).length) {
          this.employee.jobRoles[k].taskId = null;
        }
        if (!this.accessTypes.filter(t => t.id === v.functionId).length) {
          this.employee.jobRoles[k].functionId = null;
        }
      });

      this.employeeOrig = cloneDeep(this.employee);
      this.loading = false;
    } catch (e) {
      this.isError = true;
      this.loading = false;
    }
    return true;
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/* stylelint-disable */
#history-sidebar {
  top: 3.78rem !important;
  height: calc(100vh - 3.75rem);
}

.col-sm-12 {
  margin-bottom: 2rem;
}

input,
select {
  + .form-text + .invalid-feedback {
    visibility: hidden;
    display: none !important;
  }
}

input,
select {
  + .form-text {
    display: block !important;
  }
}

input[aria-invalid='true'],
select[aria-invalid='true'] {
  + .form-text + .invalid-feedback {
    visibility: visible;
    display: block !important;
  }
}

input[aria-invalid='true'],
select[aria-invalid='true'] {
  + .form-text {
    display: none !important;
  }
}

.invalid-feedback {
  display: block !important;
}

/deep/ .nav-tabs {
  margin-left: 0;

  .nav-link {
    color: #2a3142;
    font-size: 0.875rem;
    border-radius: 0 !important;

    svg {
      margin-bottom: 0.2rem;
    }
  }

  .text-success {
    color: #1a6a0f !important;
  }

  .active {
    background-color: #31a8e1 !important;
    border-color: #31a8e1;
    border-radius: 0 !important;
    color: white !important;
  }
}

.vel-alert {
  align-self: center;
  max-width: fit-content;
}

*:focus {
  box-shadow: none !important;
  outline: none !important;
}

/deep/ h5 {
  color: #2a3142;
  letter-spacing: 1px;
}

/deep/ {
  .tab-content {
    border-top: 4px solid #f5f5f5;
  }

  .nav-link {
    padding: 1rem 2rem 1rem 1rem;

    &.active {
      transition: background-color 100ms linear;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
