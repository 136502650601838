var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "resellers-page",
      attrs: { title: _vm.$t("resellersList.title"), loading: _vm.loading }
    },
    [
      _c(
        "vel-card",
        [
          _c("resellers-list", {
            attrs: {
              data: _vm.resellers,
              initialLength: _vm.selectTotal,
              filterValue: _vm.filter,
              filterCallback: _vm.filterCallback
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }