<template>
  <vel-page
    :show-selector-button="true"
    :multiple-locations="locationsList && locationsList.length > 1"
    :enable-all-currencies="true"
    class="report"
    v-bind="pageAttrs"
  >
    <template v-slot:export>
      <vel-button
        :disabled="
          loading ||
          (!Object.values(voids).length && !Object.values(refunds).length && !Object.values(corrections).length)
        "
        :loading="generating"
        type="primary"
        @click="exportXLS"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button"
        v-if="Object.values(voids).length || Object.values(refunds).length || Object.values(corrections).length"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>

    <template v-slot:toolbar-options>
      <div style="padding: 8px 1rem 0 1rem;" v-if="(!locationCount || locationCount > 1) && getCurrencySelectorSelected !== ''">
        <vel-checkbox v-model="isConsolidated" @change="onConsolidate()">
          {{ $t('toolbar.selectors.consolidate') }}
        </vel-checkbox>
      </div>
    </template>
    <div v-if="invalidLocations" style="background-color: #fff2cc; margin-bottom: 1rem; padding: 1rem;">
      <template v-if="getLocationSelectorSelected">
        {{ $t('invalidVersions.requiredVersion') }}
      </template>
      <template v-else>
        {{ $t('invalidVersions.requiredVersionMultiLocations') }}
        <a href="javascript://" @click="showLocationsList = !showLocationsList">{{ $t('invalidVersions.collapse') }}</a>
        <ul v-if="showLocationsList" style="margin-top: 1rem;">
          <li v-for="location in invalidLocations" :key="location.id">
            {{ location.reportingDisplayName || location.name }}
          </li>
        </ul>
      </template>
    </div>
    <p
      v-if="showNotification && !isVersionLoading"
      style="background-color: #ffd2d2; margin-bottom: 1rem; padding: 1rem;"
    >
      {{ $t('invalidVersions.notice') }}
    </p>

    <vel-card>
      <h5>{{ $t('voids.voidedItems.title') }}</h5>
      <vel-spinner v-if="loading" class="spinner" />
      <div style="margin-top: 1rem;" v-if="!Object.keys(voids)[0] && !loading">
        {{ $t('voids.voidedItems.empty') }}
      </div>
      <table v-else>
        <thead>
          <tr>
            <th style="width: 12%;">{{ $t('voids.voidedItems.table.headers.locations') }}</th>
            <th style="width: 120px;" class="date">
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="isMultiDays && toggleSort('date', 'voids')"
              >
                Date
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.voids.by === 'date' && sort.voids.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.voids.by === 'date' && sort.voids.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
            <th style="width: 12%;" v-if="isConsolidatable && (!locationCount || locationCount > 1)">
              {{ $t('voids.voidedItems.table.headers.location') }}
            </th>
            <th style="width: 85px;" class="time">{{ $t('voids.voidedItems.table.headers.hours') }}</th>
            <th style="width: 85px;" class="text-right">{{ $t('voids.voidedItems.table.headers.qty') }}</th>
            <th>{{ $t('voids.voidedItems.table.headers.itemDescription') }}</th>
            <th style="width: 120px;" class="text-right">{{ $t('voids.voidedItems.table.headers.price') }}</th>
            <th style="width: 120px;" class="text-right">{{ $t('voids.voidedItems.table.headers.amount') }}</th>
            <th style="width: 120px;">{{ $t('voids.voidedItems.table.headers.check') }}</th>
            <th>
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="isMultiDays && toggleSort('category', 'voids')"
              >
                {{ $t('voids.voidedItems.table.headers.category') }}
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.voids.by === 'category' && sort.voids.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.voids.by === 'category' && sort.voids.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
            <th>
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="isMultiDays && toggleSort('employee', 'voids')"
              >
                {{ $t('voids.voidedItems.table.headers.employee') }}
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.voids.by === 'employee' && sort.voids.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.voids.by === 'employee' && sort.voids.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
            <th>
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="toggleSort('authorize', 'voids')"
              >
                {{ $t('voids.voidedItems.table.headers.menager') }}
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.voids.by === 'authorize' && sort.voids.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.voids.by === 'authorize' && sort.voids.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(location, locationId) in voids">
            <template v-for="(l, i) in location">
              <template v-for="(v, k) in l">
                <tr v-if="expand.voids[locationId]" :key="v.id + i">
                  <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'voids')">
                    <span v-if="!k">
                      <vel-icon style="height: 12px; width: 8px;" name="bottom-arrow"></vel-icon>
                      <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                        {{
                          !locationCount
                            ? $t('dashboard.locationSelector.allLocationsText')
                            : $t('dashboard.locationSelector.locationsSelected', {
                                count: locationCount,
                                total: locationsBySelectedGroup.length
                              })
                        }}
                      </span>
                      <span v-else>
                        {{ v.location.reportingDisplayName || v.location.name }}
                      </span>
                    </span>
                  </td>
                  <td
                    class="date"
                    v-bind:class="{ empty: sort.voids.by === 'date' && k && (!isDifferentValue(v, 'date') || !k) }"
                  >
                    <span v-if="isDifferentValue(v, 'date') || !k">{{ v.date }}</span>
                  </td>
                  <td v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                    {{ v.location.reportingDisplayName || v.location.name }}
                  </td>
                  <td class="time">
                    {{
                      v.time.replace(/^2(4|5|6|7|8)/gi, matched => {
                        return '0' + (matched - 24).toString();
                      })
                    }}
                  </td>
                  <td class="quantity text-right">{{ v.quantity }}</td>
                  <td class="itemName">{{ (v.product && v.product.name) || '--' }}</td>
                  <td class="price text-right">
                    <vel-amount :amount="v.price / v.quantity" :currency="v.location.detailedCurrency.currencySymbol"></vel-amount>
                  </td>
                  <td class="amount text-right">
                    <vel-amount :amount="v.price" :currency="v.location.detailedCurrency.currencySymbol"></vel-amount>
                  </td>
                  <td class="check"></td>
                  <td class="categoryName">{{ v.category }}</td>
                  <td class="employee">{{ v.employee }}</td>
                  <td class="authorized">
                    {{ v.employee !== v.authorize || sort.voids.by === 'authorize' ? v.authorize : '--' }}
                  </td>
                </tr>
                <tr :key="'a' + v.id" v-if="expand.voids[v.id]">
                  <td class="location"></td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.total') }}</strong>
                  </td>
                  <td class="location"></td>
                  <td v-if="isConsolidatable && (!locationCount || locationCount > 1)"></td>
                  <td>{{ v.quantity }}</td>
                  <td></td>
                  <td>
                    <vel-amount :amount="v.price" :currency="v.location.detailedCurrency.currencySymbol"></vel-amount>
                  </td>
                  <td></td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.all') }}</strong>
                  </td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.all') }}</strong>
                  </td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.all') }}</strong>
                  </td>
                </tr>
              </template>
              <tr v-if="expand.voids[locationId] && isMultiDays" :key="i" class="subtotal">
                <td class="location" @click="toggle(locationId, 'voids')"></td>
                <td class="date">
                  <strong>{{ $t('voids.voidedItems.subtotal') }}</strong>
                </td>
                <td v-if="isConsolidatable && (!locationCount || locationCount > 1)"></td>
                <td class="time"></td>
                <td class="quantity text-right">
                  <strong>
                    {{ getVoidsQuantityByIndex(locationId, i) }}
                  </strong>
                </td>
                <td class="itemName"></td>
                <td class="price text-right"></td>
                <td class="amount text-right">
                  <strong>
                    <vel-amount
                      :amount="getVoidsAmountByIndex(locationId, i)"
                      :currency="getVoidsCurrencyByIndex(locationId, i)"
                    ></vel-amount>
                  </strong>
                </td>
                <td class="check"></td>
                <td class="categoryName">
                  <strong>{{ sort.voids.by === 'category' ? voids[locationId][i][0].category : 'All' }}</strong>
                </td>
                <td class="employee">
                  <strong>{{ sort.voids.by === 'employee' ? voids[locationId][i][0].employee : 'All' }}</strong>
                </td>
                <td class="authorized">
                  <strong>{{ sort.voids.by === 'authorize' ? voids[locationId][i][0].authorize : 'All' }}</strong>
                </td>
              </tr>
            </template>

            <tr :class="{ total: expand.voids[locationId] }" :key="locationId">
              <td
                class="location"
                style="cursor: pointer;"
                :class="{ noBorder: expand.voids[locationId] }"
                @click="toggle(locationId, 'voids')"
              >
                <span v-if="!expand.voids[locationId]">
                  <vel-icon
                    style="height: 8px; position: relative; top: -2px; width: 8px;"
                    :name="expand.voids[locationId] ? 'bottom-arrow' : 'right-arrow'"
                  ></vel-icon>
                  <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                    {{
                      !locationCount
                        ? $t('dashboard.locationSelector.allLocationsText')
                        : $t('dashboard.locationSelector.locationsSelected', {
                            count: locationCount,
                            total: locationsBySelectedGroup.length
                          })
                    }}
                  </span>
                  <span v-else>
                    {{
                      Object.values(voids[locationId])[0][0].location.reportingDisplayName ||
                      Object.values(voids[locationId])[0][0].location.name
                    }}
                  </span>
                </span>
              </td>

              <td class="date"><strong>Total</strong></td>
              <td v-if="isConsolidatable && (!locationCount || locationCount > 1)"></td>
              <td class="time"></td>
              <td class="quantity text-right">
                <strong>
                  {{ getVoidsQuantityByIndex(locationId) }}
                </strong>
              </td>
              <td class="itemName"></td>
              <td class="price text-right"></td>
              <td class="amount text-right">
                <strong>
                  <vel-amount
                    :amount="getVoidsAmountByIndex(locationId)"
                    :currency="getVoidsCurrencyByIndex(locationId)"
                  ></vel-amount>
                </strong>
              </td>
              <td class="check"></td>
              <td class="categoryName">
                <strong>{{ $t('voids.voidedItems.all') }}</strong>
              </td>
              <td class="employee">
                <strong>{{ $t('voids.voidedItems.all') }}</strong>
              </td>
              <td class="authorized">
                <strong>{{ $t('voids.voidedItems.all') }}</strong>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </vel-card>
    <vel-card>
      <h5>{{ $t('voids.refundsItems.title') }}</h5>
      <vel-spinner v-if="loading" class="spinner" />
      <div style="margin-top: 1rem;" v-if="!Object.keys(refunds)[0] && !loading">
        {{ $t('voids.refundsItems.empty') }}
      </div>
      <table v-else>
        <thead>
          <tr>
            <th style="width: 12%;">{{ $t('voids.refundsItems.table.headers.locations') }}</th>
            <th style="width: 120px;">
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="isMultiDays && toggleSort('date', 'refunds')"
              >
                Date
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.refunds.by === 'date' && sort.refunds.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.refunds.by === 'date' && sort.refunds.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
            <th style="width: 12%;" v-if="isConsolidatable && (!locationCount || locationCount > 1)">
              {{ $t('voids.voidedItems.table.headers.location') }}
            </th>
            <th style="width: 85px;">{{ $t('voids.refundsItems.table.headers.hours') }}</th>
            <th style="width: 85px;" class="text-right">{{ $t('voids.refundsItems.table.headers.qty') }}</th>
            <th>{{ $t('voids.refundsItems.table.headers.itemDescription') }}</th>
            <th style="width: 120px;" class="text-right">{{ $t('voids.refundsItems.table.headers.price') }}</th>
            <th style="width: 120px;" class="text-right">{{ $t('voids.refundsItems.table.headers.amount') }}</th>
            <th style="width: 120px;">{{ $t('voids.refundsItems.table.headers.check') }}</th>
            <th>
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="isMultiDays && toggleSort('category', 'refunds')"
              >
                {{ $t('voids.refundsItems.table.headers.category') }}
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.refunds.by === 'category' && sort.refunds.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.refunds.by === 'category' && sort.refunds.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
            <th>
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="isMultiDays && toggleSort('employee', 'refunds')"
              >
                {{ $t('voids.refundsItems.table.headers.employee') }}
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.refunds.by === 'employee' && sort.refunds.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.refunds.by === 'employee' && sort.refunds.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
            <th>
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="toggleSort('authorize', 'refunds')"
              >
                {{ $t('voids.refundsItems.table.headers.menager') }}
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.refunds.by === 'authorize' && sort.refunds.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.refunds.by === 'authorize' && sort.refunds.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(location, locationId) in refunds">
            <template v-for="(l, i) in location">
              <template v-for="(v, k) in l">
                <tr v-if="expand.refunds[locationId]" :key="v.id + i">
                  <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'refunds')">
                    <span v-if="!k">
                      <vel-icon style="height: 12px; width: 8px;" name="bottom-arrow"></vel-icon>
                      <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                        {{
                          !locationCount
                            ? $t('dashboard.locationSelector.allLocationsText')
                            : $t('dashboard.locationSelector.locationsSelected', {
                                count: locationCount,
                                total: locationsBySelectedGroup.length
                              })
                        }}
                      </span>
                      <span v-else>
                        {{ v.location.reportingDisplayName || v.location.name }}
                      </span>
                    </span>
                  </td>
                  <td
                    class="date"
                    v-bind:class="{ empty: sort.refunds.by === 'date' && k && (!isDifferentValue(v, 'date') || !k) }"
                  >
                    <span v-if="isDifferentValue(v, 'date') || !k">{{ v.date }}</span>
                  </td>
                  <td v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                    {{ v.location.reportingDisplayName || v.location.name }}
                  </td>
                  <td class="time">
                    {{
                      v.time.replace(/^2(4|5|6|7|8)/gi, matched => {
                        return '0' + (matched - 24).toString();
                      })
                    }}
                  </td>
                  <td class="quantity text-right">{{ v.quantity }}</td>
                  <td class="itemName">{{ (v.product && v.product.name) || '--' }}</td>
                  <td class="price text-right">
                    <vel-amount :amount="v.price / v.quantity" :currency="v.location.detailedCurrency.currencySymbol"></vel-amount>
                  </td>
                  <td class="price text-right">
                    <vel-amount :amount="v.price" :currency="v.location.detailedCurrency.currencySymbol"></vel-amount>
                  </td>
                  <td class="check"></td>
                  <td class="categoryName">{{ v.category }}</td>
                  <td class="employee">{{ v.employee }}</td>
                  <td class="authorized">
                    {{ v.employee !== v.authorize || sort.refunds.by === 'authorize' ? v.authorize : '--' }}
                  </td>
                </tr>
                <tr :key="'a' + v.id" v-if="expand.refunds[v.id]">
                  <td class="location"></td>
                  <td v-if="isConsolidatable && (!locationCount || locationCount > 1)"></td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.total') }}</strong>
                  </td>
                  <td></td>
                  <td>{{ v.quantity }}</td>
                  <td></td>
                  <td>
                    <vel-amount :amount="v.price" :currency="v.location.detailedCurrency.currencySymbol"></vel-amount>
                  </td>
                  <td></td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.all') }}</strong>
                  </td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.all') }}</strong>
                  </td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.all') }}</strong>
                  </td>
                </tr>
              </template>
              <tr v-if="expand.refunds[locationId] && isMultiDays" :key="i" class="subtotal">
                <td class="location" @click="toggle(locationId, 'refunds')"></td>
                <td class="date">
                  <strong>{{ $t('voids.voidedItems.subtotal') }}</strong>
                </td>
                <td class="time"></td>
                <td v-if="isConsolidatable && (!locationCount || locationCount > 1)"></td>
                <td class="quantity text-right">
                  <strong>
                    {{ getRefundsQuantityByIndex(locationId, i) }}
                  </strong>
                </td>
                <td class="itemName"></td>
                <td class="price text-right"></td>
                <td class="amount text-right">
                  <strong>
                    <vel-amount
                      :amount="getRefundsAmountByIndex(locationId, i)"
                      :currency="getRefundsCurrencyByIndex(locationId, i)"
                    ></vel-amount>
                  </strong>
                </td>
                <td class="check"></td>
                <td class="categoryName">
                  <strong>{{ sort.refunds.by === 'category' ? refunds[locationId][i][0].category : 'All' }}</strong>
                </td>
                <td class="employee">
                  <strong>{{ sort.refunds.by === 'employee' ? refunds[locationId][i][0].employee : 'All' }}</strong>
                </td>
                <td class="authorized">
                  <strong>{{ sort.refunds.by === 'authorize' ? refunds[locationId][i][0].authorize : 'All' }}</strong>
                </td>
              </tr>
            </template>

            <tr :class="{ total: expand.refunds[locationId] }" :key="locationId">
              <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'refunds')">
                <span v-if="!expand.refunds[locationId]">
                  <vel-icon
                    style="height: 8px; position: relative; top: -2px; width: 8px;"
                    :name="expand.refunds[locationId] ? 'bottom-arrow' : 'right-arrow'"
                  ></vel-icon>
                  <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                    {{
                      !locationCount
                        ? $t('dashboard.locationSelector.allLocationsText')
                        : $t('dashboard.locationSelector.locationsSelected', {
                            count: locationCount,
                            total: locationsBySelectedGroup.length
                          })
                    }}
                  </span>
                  <span v-else>
                    {{
                      Object.values(refunds[locationId])[0][0].location.reportingDisplayName ||
                      Object.values(refunds[locationId])[0][0].location.name
                    }}
                  </span>
                </span>
              </td>

              <td class="date">
                <strong>{{ $t('voids.voidedItems.total') }}</strong>
              </td>
              <td v-if="isConsolidatable && (!locationCount || locationCount > 1)"></td>
              <td class="time"></td>
              <td class="quantity text-right">
                <strong>
                  {{ getRefundsQuantityByIndex(locationId) }}
                </strong>
              </td>
              <td class="itemName"></td>
              <td class="price text-right"></td>
              <td class="amount text-right">
                <strong>
                  <vel-amount
                    :amount="getRefundsAmountByIndex(locationId)"
                    :currency="getRefundsCurrencyByIndex(locationId)"
                  ></vel-amount>
                </strong>
              </td>
              <td class="check"></td>
              <td class="categoryName">
                <strong>{{ $t('voids.voidedItems.all') }}</strong>
              </td>
              <td class="employee">
                <strong>{{ $t('voids.voidedItems.all') }}</strong>
              </td>
              <td class="authorized">
                <strong>{{ $t('voids.voidedItems.all') }}</strong>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </vel-card>
    <vel-card>
      <h5>{{ $t('voids.itemCorrections.title') }}</h5>
      <vel-spinner v-if="loading" class="spinner" />
      <div style="margin-top: 1rem;" v-if="!Object.keys(corrections)[0] && !loading">
        {{ $t('voids.itemCorrections.empty') }}
      </div>
      <table v-else>
        <thead>
          <tr>
            <th style="width: 12%;">{{ $t('voids.itemCorrections.table.headers.locations') }}</th>
            <th style="width: 120px;">
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="isMultiDays && toggleSort('date', 'corrections')"
              >
                Date
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.corrections.by === 'date' && sort.corrections.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.corrections.by === 'date' && sort.corrections.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
            <th style="width: 12%;" v-if="isConsolidatable && (!locationCount || locationCount > 1)">
              {{ $t('voids.voidedItems.table.headers.location') }}
            </th>
            <th style="width: 85px;">{{ $t('voids.itemCorrections.table.headers.hours') }}</th>
            <th style="width: 85px;" class="text-right">{{ $t('voids.itemCorrections.table.headers.qty') }}</th>
            <th>{{ $t('voids.itemCorrections.table.headers.itemDescription') }}</th>
            <th style="width: 120px;" class="text-right">{{ $t('voids.itemCorrections.table.headers.price') }}</th>
            <th style="width: 120px;" class="text-right">{{ $t('voids.itemCorrections.table.headers.amount') }}</th>
            <th style="width: 120px;">{{ $t('voids.itemCorrections.table.headers.check') }}</th>
            <th>
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="isMultiDays && toggleSort('category', 'corrections')"
              >
                {{ $t('voids.itemCorrections.table.headers.category') }}
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.corrections.by === 'category' && sort.corrections.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.corrections.by === 'category' && sort.corrections.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
            <th>
              <div
                class="order-header"
                v-bind:class="{ multiple: isMultiDays }"
                @click="isMultiDays && toggleSort('employee', 'corrections')"
              >
                {{ $t('voids.itemCorrections.table.headers.employee') }}
                <div class="sort" v-if="isMultiDays">
                  <vel-icon
                    v-bind:class="{ active: sort.corrections.by === 'employee' && sort.corrections.order === 'asc' }"
                    class="order"
                    name="order-asc"
                  ></vel-icon>
                  <vel-icon
                    v-bind:class="{ active: sort.corrections.by === 'employee' && sort.corrections.order === 'desc' }"
                    class="order"
                    name="order-desc"
                  ></vel-icon>
                </div>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(location, locationId) in corrections">
            <template v-for="(l, i) in location">
              <template v-for="(v, k) in l">
                <tr v-if="expand.corrections[locationId]" :key="v.id + i">
                  <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'corrections')">
                    <span v-if="!k">
                      <vel-icon style="height: 12px; width: 8px;" name="bottom-arrow"></vel-icon>
                      <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                        {{
                          !locationCount
                            ? $t('dashboard.locationSelector.allLocationsText')
                            : $t('dashboard.locationSelector.locationsSelected', {
                                count: locationCount,
                                total: locationsBySelectedGroup.length
                              })
                        }}
                      </span>
                      <span v-else>
                        {{ v.location.reportingDisplayName || v.location.name }}
                      </span>
                    </span>
                  </td>
                  <td
                    class="date"
                    v-bind:class="{
                      empty: sort.corrections.by === 'date' && k && (!isDifferentValue(v, 'date') || !k)
                    }"
                  >
                    <span v-if="isDifferentValue(v, 'date') || !k">{{ v.date }}</span>
                  </td>
                  <td v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                    {{ v.location.reportingDisplayName || v.location.name }}
                  </td>
                  <td class="time">
                    {{
                      v.time.replace(/^2(4|5|6|7|8)/gi, matched => {
                        return '0' + (matched - 24).toString();
                      })
                    }}
                  </td>
                  <td class="quantity text-right">{{ v.quantity }}</td>
                  <td class="itemName">{{ (v.product && v.product.name) || '--' }}</td>
                  <td class="price text-right">
                    <vel-amount :amount="v.price / v.quantity" :currency="v.location.detailedCurrency.currencySymbol"></vel-amount>
                  </td>
                  <td class="amount text-right">
                    <vel-amount :amount="v.price" :currency="v.location.detailedCurrency.currencySymbol"></vel-amount>
                  </td>
                  <td class="check"></td>
                  <td class="categoryName">{{ v.category }}</td>
                  <td class="employee">{{ v.employee }}</td>
                </tr>
                <tr :key="'a' + v.id" v-if="expand.corrections[v.id]">
                  <td class="location"></td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.total') }}</strong>
                  </td>
                  <td></td>
                  <td>{{ v.quantity }}</td>
                  <td></td>
                  <td>
                    <vel-amount :amount="v.price" :currency="v.location.detailedCurrency.currencySymbol"></vel-amount>
                  </td>
                  <td></td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.all') }}</strong>
                  </td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.all') }}</strong>
                  </td>
                  <td>
                    <strong>{{ $t('voids.voidedItems.all') }}</strong>
                  </td>
                  <td></td>
                </tr>
              </template>
              <tr v-if="expand.corrections[locationId] && isMultiDays" :key="i" class="subtotal">
                <td class="location" @click="toggle(locationId, 'corrections')"></td>
                <td class="date">
                  <strong>{{ $t('voids.voidedItems.subtotal') }}</strong>
                </td>
                <td v-if="isConsolidatable && (!locationCount || locationCount > 1)"></td>
                <td class="time"></td>
                <td class="quantity text-right">
                  <strong>
                    {{ getCorrectionsQuantityByIndex(locationId, i) }}
                  </strong>
                </td>
                <td class="itemName"></td>
                <td class="price text-right"></td>
                <td class="amount text-right">
                  <strong>
                    <vel-amount
                      :amount="getCorrectionsAmountByIndex(locationId, i)"
                      :currency="getCorrectionsCurrencyByIndex(locationId, i)"
                    ></vel-amount>
                  </strong>
                </td>
                <td class="check"></td>
                <td class="categoryName">
                  <strong>
                    {{ sort.corrections.by === 'category' ? corrections[locationId][i][0].category : 'All' }}
                  </strong>
                </td>
                <td class="employee">
                  <strong>
                    {{ sort.corrections.by === 'employee' ? corrections[locationId][i][0].employee : 'All' }}
                  </strong>
                </td>
                <td></td>
              </tr>
            </template>

            <tr :class="{ total: expand.corrections[locationId] }" :key="locationId">
              <td class="location" style="cursor: pointer;" @click="toggle(locationId, 'corrections')">
                <span v-if="!expand.corrections[locationId]">
                  <vel-icon
                    style="height: 8px; position: relative; top: -2px; width: 8px;"
                    :name="expand.corrections[locationId] ? 'bottom-arrow' : 'right-arrow'"
                  ></vel-icon>
                  <span v-if="isConsolidatable && (!locationCount || locationCount > 1)">
                    {{
                      !locationCount
                        ? $t('dashboard.locationSelector.allLocationsText')
                        : $t('dashboard.locationSelector.locationsSelected', {
                            count: locationCount,
                            total: locationsBySelectedGroup.length
                          })
                    }}
                  </span>
                  <span v-else>
                    {{
                      Object.values(corrections[locationId])[0][0].location.reportingDisplayName ||
                      Object.values(corrections[locationId])[0][0].location.name
                    }}
                  </span>
                </span>
              </td>

              <td class="date">
                <strong>{{ $t('voids.voidedItems.total') }}</strong>
              </td>
              <td v-if="isConsolidatable && (!locationCount || locationCount > 1)"></td>
              <td class="time"></td>
              <td class="quantity text-right">
                <strong>
                  {{ getCorrectionsQuantityByIndex(locationId) }}
                </strong>
              </td>
              <td class="itemName"></td>
              <td class="price text-right"></td>
              <td class="amount text-right">
                <strong>
                  <vel-amount
                    :amount="getCorrectionsAmountByIndex(locationId)"
                    :currency="getCorrectionsCurrencyByIndex(locationId)"
                  ></vel-amount>
                </strong>
              </td>
              <td class="check"></td>
              <td class="categoryName">
                <strong>{{ $t('voids.voidedItems.all') }}</strong>
              </td>
              <td class="employee">
                <strong>{{ $t('voids.voidedItems.all') }}</strong>
              </td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
    </vel-card>
  </vel-page>
</template>

<script>
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import GeneratedTime from '@/mixins/generated-time-mixin';
import VelAmount from '@/components/amount/VelAmount';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelCheckbox from '@/components/checkbox/VelCheckbox';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import VelSpinner from '@/components/spinner/VelSpinner';
import clonedeep from 'lodash.clonedeep';
import { environment } from '@/configs';
import { formatVersion } from '@/helpers/versions.module.helper';
import { getToken } from '@/helpers/token.helper';
import groupBy from 'lodash.groupby';
import router from '@/router';
import {getDateFormatFromUserConfig, getTimeFormatFromUserConfig, today} from '@/helpers/date.helpers';
import transactionsService from '@/services/transactions.service';
import {useUIGroupSelector} from "@/stores/ui/group-selector.module";
import { mapState as mapPiniaState } from 'pinia';
import {useUICurrencySelector} from "@/stores/ui/currency-selector.module";
import {useUIDateRangeSelector} from "@/stores/ui/date-range-selector.module";
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {mapActions as mapPiniaActions, storeToRefs} from "pinia/dist/pinia";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataSalesLocations} from "@/stores/data/sales/locations.module";
import {useDataSalesIndicators} from "@/stores/data/sales/indicators.module";
import {useDataLocationsVersionsStore} from "@/stores/data/locations/versions.module";
import {useDataConfig} from "@/stores/data/config.module";
import {useDataGroups} from "@/stores/data/groups.module";
export default {
  name: 'SummaryReportPage',
  components: {
    VelPage,
    VelButton,
    VelAmount,
    VelCard,
    VelIcon,
    VelSpinner,
    VelCheckbox
  },
  mixins: [GeneratedTime, DeviceMixin],
  data() {
    return {
      isConsolidated: localStorage.getItem('isConsolidated') === 'true',
      showLocationsList: false,
      sort: {
        refunds: {
          by: 'date',
          order: 'asc'
        },
        voids: {
          by: 'date',
          order: 'asc'
        },
        corrections: {
          by: 'date',
          order: 'asc'
        }
      },
      loading: true,
      generating: false,
      isMultiDays: false,
      updateIndicators: 0,
      updateCharts: 1,
      diffValues: {},
      voids: {},
      corrections: {},
      refunds: [],
      expand: {
        voids: {},
        corrections: {},
        refunds: {}
      },
      previousIndicators: {
        itemCorrectionsAmount: 0,
        voidsAmount: 0,
        refundsAmount: 101
      }
    };
  },
  setup() {
    const locationsSalesStore = useDataSalesLocations();

    const { getLocationsSales } = storeToRefs(locationsSalesStore);

    return { getSales: getLocationsSales, locationsSalesStore };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, { locationsList: 'locations' }),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useUILocationSelector, ['getLocationSelectorSelected', 'locations', 'getLocationById']),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected', 'getCurrencySelectorSelectedSymbol']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useDataLocationsVersionsStore, {
      isVersionValid: 'isVersionValid',
      isVersionLoading: 'loading'
    }),
    ...mapPiniaState(useDataGroups, ['getGroupsRows']),
    ...mapPiniaState(useUILocationSelector, [
      'getLocationSelectorSelected',
      'locations',
      'getLocationSelectorSelectedMultiple'
    ]),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities'
    }),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById', 'locationsBySelectedGroup']),
    ...mapPiniaState(useDataSalesIndicators, [
      'getIndicatorsVoids',
      'getIndicatorsVoidsAmount',
      'getIndicatorsRefundsAmount',
      'getIndicatorsSalesVolume',
      'getIndicatorsRefunds',
      'getIndicatorsItemCorrections',
      'getIndicatorsItemCorrectionsAmount'
    ]),
    isConsolidatable() {
      return this.isConsolidated && this.getCurrencySelectorSelected !== '' && (!this.locationCount || this.locationCount > 1)
    },
    chartAttrs() {
      return {
        locationId: this.getLocationSelectorSelected,
        currencyId: this.getCurrencySelectorSelected,
        from: this.getDateSelectorSelectedRange[0],
        to: this.getDateSelectorSelectedRange[1],
        limit: this.get.charts_size_limitation.value
      };
    },
    config() {
      return environment;
    },
    pageAttrs() {
      return {
        enableToolbar: true,
        enableCurrency: true,
        enableDateRange: true,
        title: this.$t('pages.reports.voids'),
        showCrumbs: false,
        failed: this.hasPageFailed,
        generatedTime: this.generatedTime,
        showDateCompare: this.showLocationsSales
      };
    },
    hasPageFailed() {
      return this.false;
    },
    showLocationsSales() {
      return !this.getLocationSelectorSelected || this.locations.length === 1;
    },
    isAllInvalid() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) < 9620) {
        return true;
      }
      const locations = (this.getLocationSelectorSelectedMultiple || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      return locations.length === this.getLocationSelectorSelectedMultiple.length;
    },
    invalidLocations() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) >= 9620) {
        return false;
      }
      const locations = (this.getLocationSelectorSelectedMultiple || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      if (!locations.length) {
        return null;
      }
      return locations;
    },
    showNotification() {
      const isUpdated = this.getLocationSelectorSelected
        ? formatVersion(this.getLocationsById(this.getLocationSelectorSelected).version) >= 9620
        : !this.invalidLocations;
      const hasInvalidData = this.getLocationSelectorSelected
        ? !this.versionIsValid(this.getLocationSelectorSelected)
        : !!(this.getLocationSelectorSelectedMultiple || []).filter(x => !this.versionIsValid(x.value)).length;

      if (!this.getLocationSelectorSelected && !isUpdated) {
        const locations = (this.getLocationSelectorSelectedMultiple || [])
          .map(k => {
            const l = this.getLocationsById(k.value) || {};
            return { ...l, version: formatVersion(l.version) };
          })
          .filter(l => l.version >= 9620)
          .filter(l => !this.versionIsValid(l.id));

        if (locations.length) {
          return true;
        }
      }
      return isUpdated && hasInvalidData;
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataLocationsVersionsStore, ['getVersions']),
    onConsolidate() {
      this.gen(false);
      this.fetchData();
      localStorage.setItem('isConsolidated', this.isConsolidated);
    },
    versionIsValid(locationId = null) {
      // @todo refactor so that we no longer have to pass getters as arguments
      return (
        !this.isVersionLoading &&
        this.isVersionValid(
          '9.62.0',
          locationId ? locationId : this.getLocationSelectorSelected,
          locationId ? null : this.getGroupSelectorSelected,
          this.getDateSelectorSelectedRange[0]
        )
      );
    },
    /* eslint-disable */
    async exportXLS() {
      this.generating = true;
      this.$ga.event('report', 'download', this.$route.name);

      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;

      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };

      await transactionsService
        .downloadTransactionsVoidsSummaryXlsx(getToken(), { ...routeQuery })
        .catch(() => {
          this.generating = false;
        });

      this.generating = false;
    },
    isDifferentValue(obj, key) {
      if (this.diffValues[key] !== obj[key]) {
        this.diffValues[key] = obj[key];
        return true;
      }
      this.diffValues[key] = obj[key];
      return false;
    },
    autoUpdate() {
      this.updateIndicators += 2;
      this.updateCharts += 2;
    },
    toggle(id, type) {
      this.expand = { ...this.expand, [type]: { ...this.expand[type], [id]: !this.expand[type][id] } };
    },
    getCurrentGroupName() {
      const selectedGroup = (this.getGroupsRows || []).filter(group => group.id === this.getGroupSelectorSelected);
      if (!selectedGroup.length) {
        return null;
      }
      return `${this.$t('exports.groupLabel')} ${selectedGroup[0].name}`;
    },
    toggleSort(field, type) {
      if (this.sort[type].by === field && this.sort[type].order === 'desc') {
        this.sort[type].order = 'asc';
        this.sort[type].by = 'date';
      } else {
        this.sort[type].order =
          this.sort[type].by === field
            ? this.sort[type].order === 'asc'
              ? 'desc'
              : this.sort[type].order === 'desc'
              ? 'null'
              : 'asc'
            : 'asc';
        this.sort[type].by = field;
      }
      this.fetchData();
    },
    getVoidsQuantityByIndex(index, subindex = null) {
      if (subindex) {
        return Object.values(this.voids[index][subindex]).reduce((a, b) => a + b.quantity, 0);
      }
      return Object.values(this.voids[index]).reduce((a, b) => Object.values(b).reduce((c, d) => c + d.quantity, a), 0);
    },
    getVoidsPriceByIndex(index, subindex = null) {
      if (subindex) {
        return Object.values(this.voids[index][subindex]).reduce((a, b) => a + b.price, 0);
      }
      return Object.values(this.voids[index]).reduce((a, b) => Object.values(b).reduce((c, d) => c + d.price, a), 0);
    },
    getVoidsAmountByIndex(index, subindex = null) {
      if (subindex) {
        return Object.values(this.voids[index][subindex]).reduce((a, b) => a + b.price, 0);
      }
      return Object.values(this.voids[index]).reduce(
        (a, b) => Object.values(b).reduce((c, d) => c + d.price, a),
        0
      );
    },
    getVoidsCurrencyByIndex(index, subindex = null) {
      if (subindex) {
        return this.voids[index][subindex][0].location.detailedCurrency.currencySymbol;;
      }
      return Object.values(this.voids[index])[0][0].location.detailedCurrency.currencySymbol;
    },
    getCorrectionsQuantityByIndex(index, subindex = null) {
      if (subindex) {
        return Object.values(this.corrections[index][subindex]).reduce((a, b) => a + b.quantity, 0);
      }
      return Object.values(this.corrections[index]).reduce(
        (a, b) => Object.values(b).reduce((c, d) => c + d.quantity, a),
        0
      );
    },
    getCorrectionsPriceByIndex(index, subindex = null) {
      if (subindex) {
        return Object.values(this.corrections[index][subindex]).reduce((a, b) => a + b.price, 0);
      }
      return Object.values(this.corrections[index]).reduce(
        (a, b) => Object.values(b).reduce((c, d) => c + d.price, a),
        0
      );
    },
    getCorrectionsAmountByIndex(index, subindex = null) {
      if (subindex) {
        return Object.values(this.corrections[index][subindex]).reduce((a, b) => a + b.price, 0);
      }
      return Object.values(this.corrections[index]).reduce(
        (a, b) => Object.values(b).reduce((c, d) => c + d.price, a),
        0
      );
    },
    getCorrectionsCurrencyByIndex(index, subindex = null) {

      if (subindex) {
        return this.corrections[index][subindex][0].location.detailedCurrency.currencySymbol;;
      }
      return Object.values(this.corrections[index])[0][0].location.detailedCurrency.currencySymbol;
    },
    getRefundsQuantityByIndex(index, subindex = null) {
      if (subindex) {
        return Object.values(this.refunds[index][subindex]).reduce((a, b) => a + b.quantity, 0);
      }
      return Object.values(this.refunds[index]).reduce(
        (a, b) => Object.values(b).reduce((c, d) => c + d.quantity, a),
        0
      );
    },
    getRefundsPriceByIndex(index, subindex = null) {
      if (subindex) {
        return Object.values(this.refunds[index][subindex]).reduce((a, b) => a + b.price, 0);
      }
      return Object.values(this.refunds[index]).reduce((a, b) => Object.values(b).reduce((c, d) => c + d.price, a), 0);
    },
    getRefundsAmountByIndex(index, subindex = null) {
      if (subindex) {
        return Object.values(this.refunds[index][subindex]).reduce((a, b) => a + b.price, 0);
      }
      return Object.values(this.refunds[index]).reduce(
        (a, b) => Object.values(b).reduce((c, d) => c + d.price, a),
        0
      );
    },
    getRefundsCurrencyByIndex(index, subindex = null) {
      if (subindex) {
        return this.refunds[index][subindex][0].location.detailedCurrency.currencySymbol;;
      }
      return Object.values(this.refunds[index])[0][0].location.detailedCurrency.currencySymbol;
    },
    async fetchData() {
      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };
      const serviceVoidsData = await transactionsService
        .getTransactionsVoids(getToken(), {
          ...routeQuery
        })
        .then(ld => {
          return ld
            .sort((a, b) =>
              (a.location.reportingDisplayName || a.location.name).localeCompare(
                b.location.reportingDisplayName || b.location.name
              )
            )
            .map(d => ({
              ...d,
              time: DateTime.fromISO(d.eventTime).toFormat(getTimeFormatFromUserConfig()),
              date: DateTime.fromISO(d.eventTime).toFormat(getDateFormatFromUserConfig()),
              category:
                (d.product &&
                  d.product.division &&
                  d.product.division.bigDivision &&
                  d.product.division.bigDivision.name) ||
                '--',
              employee: d.sellingEmployee.name,
              authorize: d.carrierEmployee.name,
              quantity: d.quantity * -1,
              price: d.price * -1
            }));
        });

      const dataGroupVoidsByLocation = groupBy(serviceVoidsData, 'locationId');

      Object.keys(dataGroupVoidsByLocation).forEach(k => {
        dataGroupVoidsByLocation[k] = groupBy(
          dataGroupVoidsByLocation[k]
            .sort((a, b) => a.time.localeCompare(b.time))
            .sort((a, b) => {
              if(this.sort.voids.by === 'date' ) {
                return this.sort.voids.order === 'asc' ? new Date(a.date).getTime() - new Date(b.date).getTime() : new Date(b.date).getTime() - new Date(a.date).getTime()
              }
              return (
                (this.sort.voids.order === 'asc' ? a : b)[this.sort.voids.by] || ''
              )
                .toLowerCase()
                .localeCompare(
                  (
                    (this.sort.voids.order === 'asc' ? b : a)[
                      this.sort.voids.by === 'date' ? 'timestamp' : this.sort.voids.by
                    ] || ''
                  ).toLowerCase()
                )
            }),
          this.sort.voids.by
        );


      });

      this.voids = clonedeep(dataGroupVoidsByLocation);

      if (this.isConsolidatable && Object.keys(this.voids).length) {
        const correctionsLength = Object.keys(this.voids).length;
        const correctionsKeys = Object.keys(this.voids);
        const correctionsEntities = Object.values(this.voids);
        const firstLocationId = Object.keys(this.voids)[0];
        const firstLocationEntity = this.voids[firstLocationId];

        const allLocation = { [firstLocationId]: firstLocationEntity };

        for (let i = 1; i < correctionsLength; i++) {
          const days = Object.keys(correctionsEntities[i]);

          days.forEach(d => {
            if (!allLocation[firstLocationId][d]) {
              allLocation[firstLocationId][d] = correctionsEntities[i][d];
            } else {
              allLocation[firstLocationId][d] = [...allLocation[firstLocationId][d], ...correctionsEntities[i][d]];
            }
            allLocation[firstLocationId][d] = allLocation[firstLocationId][d].sort((a, b) =>
              a.time.localeCompare(b.time)
            );
          });
        }

        this.voids = allLocation;
      }

      /*
      CORRECTION
       */
      const serviceCorrectionsData = await transactionsService
        .getTransactionsItemCorrections(getToken(), {
          ...routeQuery
        })
        .then(ld =>
          ld
            .sort((a, b) =>
              (a.location.reportingDisplayName || a.location.name).localeCompare(
                b.location.reportingDisplayName || b.location.name
              )
            )
            .map(d => ({
              ...d,
              time: DateTime.fromISO(d.eventTime).toFormat(getTimeFormatFromUserConfig()),
              date: DateTime.fromISO(d.eventTime).toFormat(getDateFormatFromUserConfig()),
              category:
                (d.product &&
                  d.product.division &&
                  d.product.division.bigDivision &&
                  d.product.division.bigDivision.name) ||
                '--',
              employee: d.employee.name,
              authorize: '--',
              quantity: d.quantity * -1,
              price: d.price * -1
            }))
        );
      const dataGroupCorrectionsByLocation = groupBy(serviceCorrectionsData, 'locationId');

      Object.keys(dataGroupCorrectionsByLocation).forEach(k => {
        dataGroupCorrectionsByLocation[k] = Object.values(
          groupBy(
            dataGroupCorrectionsByLocation[k]
              .sort((a, b) => a.time.localeCompare(b.time))
              .sort((a, b) => {
                if(this.sort.corrections.by === 'date' ) {
                  return this.sort.corrections.order === 'asc' ? new Date(a.date).getTime() - new Date(b.date).getTime() : new Date(b.date).getTime() - new Date(a.date).getTime()
                }
                return (
                  (this.sort.corrections.order === 'asc' ? a : b)[this.sort.corrections.by] || ''
                )
                  .toLowerCase()
                  .localeCompare(
                    (
                      (this.sort.corrections.order === 'asc' ? b : a)[this.sort.corrections.by] || ''
                    ).toLowerCase()
                  )
              }),
            this.sort.corrections.by
          )
        );


      });
      this.corrections = clonedeep(dataGroupCorrectionsByLocation);

      if (this.isConsolidatable && Object.keys(this.corrections).length) {
        const correctionsLength = Object.keys(this.corrections).length;
        const correctionsKeys = Object.keys(this.corrections);
        const correctionsEntities = Object.values(this.corrections);
        const firstLocationId = Object.keys(this.corrections)[0];
        const firstLocationEntity = this.corrections[firstLocationId];

        const allLocation = { [firstLocationId]: firstLocationEntity };

        for (let i = 1; i < correctionsLength; i++) {
          const days = Object.keys(correctionsEntities[i]);

          days.forEach(d => {
            if (!allLocation[firstLocationId][d]) {
              allLocation[firstLocationId][d] = correctionsEntities[i][d];
            } else {
              allLocation[firstLocationId][d] = [...allLocation[firstLocationId][d], ...correctionsEntities[i][d]];
            }
            allLocation[firstLocationId][d] = allLocation[firstLocationId][d].sort((a, b) =>
              a.time.localeCompare(b.time)
            );
          });
        }

        this.corrections = allLocation;
      }

      /*
      REFUNDS
       */
      const serviceRefundsData = await transactionsService
        .getTransactionsRefunds(getToken(), {
          ...routeQuery
        })
        .then(ld =>
          ld
            .sort((a, b) =>
              (a.location.reportingDisplayName || a.location.name).localeCompare(
                b.location.reportingDisplayName || b.location.name
              )
            )
            .map(d => ({
              ...d,
              time: DateTime.fromISO(d.eventTime).toFormat(getTimeFormatFromUserConfig()),
              date: DateTime.fromISO(d.eventTime).toFormat(getDateFormatFromUserConfig()),
              category:
                (d.product &&
                  d.product &&
                  d.product.division &&
                  d.product.division.bigDivision &&
                  d.product.division.bigDivision.name) ||
                '--',
              employee: d.sellingEmployee.name,
              authorize: d.carrierEmployee.name,
              quantity: d.quantity * -1,
              price: d.price * -1
            }))
        );
      const dataGroupRefundsByLocation = groupBy(serviceRefundsData, 'locationId');

      Object.keys(dataGroupRefundsByLocation).forEach(k => {
        dataGroupRefundsByLocation[k] = groupBy(
          dataGroupRefundsByLocation[k]
            .sort((a, b) => a.time.localeCompare(b.time))
            .sort((a, b) =>
              {
                if(this.sort.refunds.by === 'date' ) {
                  return this.sort.refunds.order === 'asc' ? new Date(a.date).getTime() - new Date(b.date).getTime() : new Date(b.date).getTime() - new Date(a.date).getTime()
                }
                return (
                  (this.sort.refunds.order === 'asc' ? a : b)[this.sort.refunds.by] || ''
                )
                  .toLowerCase()
                  .localeCompare(
                    (
                      (this.sort.refunds.order === 'asc' ? b : a)[this.sort.refunds.by] || ''
                    ).toLowerCase()
                  )
              }),
          this.sort.refunds.by
        );

      });

      this.refunds = clonedeep(dataGroupRefundsByLocation);

      if (this.isConsolidatable && Object.keys(this.refunds).length) {
        const correctionsLength = Object.keys(this.refunds).length;
        const correctionsKeys = Object.keys(this.refunds);
        const correctionsEntities = Object.values(this.refunds);
        const firstLocationId = Object.keys(this.refunds)[0];
        const firstLocationEntity = this.refunds[firstLocationId];

        const allLocation = { [firstLocationId]: firstLocationEntity };

        for (let i = 1; i < correctionsLength; i++) {
          const days = Object.keys(correctionsEntities[i]);

          days.forEach(d => {
            if (!allLocation[firstLocationId][d]) {
              allLocation[firstLocationId][d] = correctionsEntities[i][d];
            } else {
              allLocation[firstLocationId][d] = [...allLocation[firstLocationId][d], ...correctionsEntities[i][d]];
            }

            allLocation[firstLocationId][d] = allLocation[firstLocationId][d].sort((a, b) =>
              a.time.localeCompare(b.time)
            );
          });
        }

        this.refunds = allLocation;
      }

      this.loading = false;
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  async mounted() {
    await this.getVersions();

    this.sort = {
      refunds: {
        by: 'date',
        order: 'asc'
      },
      voids: {
        by: 'date',
        order: 'asc'
      },
      corrections: {
        by: 'date',
        order: 'asc'
      }
    };
    await this.locationsSalesStore.getLocationsSales();
    const toDay = today().startOf('day').toISO();
    const from = router.currentRoute.query.from || toDay;
    const to = router.currentRoute.query.to || from || toDay;
    this.isMultiDays = true;
    await this.fetchData();
    // this.generateTimeAt('data/sales/indicators/getIndicatorsSuccess');
  }
};
</script>
<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .vel-card__body {
  overflow: auto;
}

table {
  border-spacing: 0;
  margin-top: 1rem;
  min-width: 1200px;
  table-layout: fixed;
  width: 100%;
}
/* stylelint-disable */
th {
  border-bottom: 1px solid #e5e5e5;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
td {
  border-top: 1px solid #e5e5e5;
  overflow: hidden;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  word-break: keep-all;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.arrow-right {
  /* Vector */
  background: #000;
  bottom: 25%;
  left: 35.79%;
  position: absolute;
  right: 33.33%;
  top: 25%;
}
.date {
  width: 120px;
}
.time {
  max-width: 100px;
}
.itemName {
  width: 360px;
}
.price {
  width: 120px;
}
.quantity {
  width: 90px;
}
.check {
  width: 120px;
}
.categoryName {
  width: 300px;
}
.employee {
  width: 300px;
}
.authorized {
  width: 300px;
}
.location {
  border: none;
  width: 200px;
}
.total td {
  background-color: #efefef;
}
.subtotal td {
  background-color: #f6f6f6;
  border-bottom: 1px solid #c0c0c0;
  &.location {
  }
}
.order-header {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  .sort {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    .order {
      height: 9px;
      width: 9px;
      &.active {
        color: #00a9e1;
        fill: #00a9e1;
      }
    }
  }
}
.multiple {
  cursor: pointer;
}
.subtotal + tr .location span {
  display: none;
}
.empty {
  border-top: 1px solid #fff;
}
tr {
  border: none;
}
.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;
  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }
  &:not(:last-of-type) {
    margin-right: 0.25em;
  }
  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }
    .vel-button__icon {
      padding-left: 0;
    }
  }
}
strong {
  font-weight: 700;
}
td {
  font-size: 0.9375rem;
}
</style>
