var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    _vm._b(
      {
        staticClass: "report",
        attrs: {
          "multiple-locations":
            _vm.locationsList && _vm.locationsList.length > 1,
          "enable-all-currencies": true,
          "show-selector-button": true
        },
        scopedSlots: _vm._u([
          {
            key: "export",
            fn: function() {
              return [
                Object.values(_vm.data).length
                  ? _c(
                      "vel-button",
                      {
                        staticClass: "vel-button",
                        attrs: {
                          disabled:
                            _vm.loading || !Object.values(_vm.data).length,
                          loading: _vm.generating,
                          type: "primary",
                          icon: _vm.isMediumUp ? "file-excel" : undefined
                        },
                        on: { click: _vm.exportXLS }
                      },
                      [
                        _vm.isMediumDown
                          ? _c("vel-icon", { attrs: { name: "file-download" } })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isMediumUp
                                ? _vm.$t("table.tableExport.button.value")
                                : ""
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "toolbar-options",
            fn: function() {
              return [
                (!_vm.locationCount || _vm.locationCount > 1) &&
                _vm.getCurrencySelectorSelected !== ""
                  ? _c(
                      "div",
                      { staticStyle: { padding: "8px 1rem 0 1rem" } },
                      [
                        _c(
                          "vel-checkbox",
                          {
                            on: {
                              change: function($event) {
                                return _vm.onConsolidate()
                              }
                            },
                            model: {
                              value: _vm.isConsolidated,
                              callback: function($$v) {
                                _vm.isConsolidated = $$v
                              },
                              expression: "isConsolidated"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("toolbar.selectors.consolidate")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      },
      "vel-page",
      _vm.pageAttrs,
      false
    ),
    [
      _vm.loading ? _c("vel-spinner", { staticClass: "spinner" }) : _vm._e(),
      !_vm.loading
        ? [
            !Object.values(_vm.data).length
              ? _c("div", { staticClass: "no-data" }, [
                  _vm._v(_vm._s(_vm.$t("itemSalesCategory.noData")))
                ])
              : _vm._l(
                  Object.values(_vm.data).sort(function(a, b) {
                    return a.name.localeCompare(b.name)
                  }),
                  function(location) {
                    return _c("vel-card", { key: location.id }, [
                      _vm.isConsolidable &&
                      (!_vm.locationCount || _vm.locationCount > 1)
                        ? _c(
                            "h5",
                            {
                              staticStyle: {
                                color: "#409eff",
                                "text-transform": "uppercase"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.locationCount
                                      ? _vm.$t(
                                          "toolbar.selectors.consolidate"
                                        ) +
                                          " - " +
                                          _vm.$t(
                                            "dashboard.locationSelector.allLocationsText"
                                          )
                                      : _vm.$t(
                                          "dashboard.locationSelector.locationsSelected",
                                          {
                                            count: _vm.locationCount,
                                            total:
                                              _vm.locationsBySelectedGroup
                                                .length
                                          }
                                        )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _c("h5", { staticStyle: { color: "#409eff" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    location.reportingDisplayName ||
                                    location.name ||
                                    ""
                                  ).toUpperCase()
                                ) +
                                " "
                            )
                          ]),
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticStyle: { width: "160px" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "discount.discounts.table.headers.date"
                                  )
                                )
                              )
                            ]),
                            !_vm.isConsolidable
                              ? _c("th", { staticStyle: { width: "120px" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "discount.discounts.table.headers.time"
                                      )
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "200px",
                                  width: "100%",
                                  "max-width": "400px"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "discount.discounts.table.headers.type"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "text-right",
                                staticStyle: { width: "200px" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "discount.discounts.table.headers.amount"
                                    )
                                  )
                                )
                              ]
                            ),
                            !_vm.isConsolidable
                              ? _c(
                                  "th",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { width: "200px" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "discount.discounts.table.headers.check"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isConsolidable
                              ? _c(
                                  "th",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { width: "200px" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "discount.discounts.table.headers.waiter"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.isConsolidable
                              ? _c(
                                  "th",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { width: "200px" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "discount.discounts.table.headers.count"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(location.dates, function(dates) {
                              return [
                                _vm._l(dates.discounts, function(
                                  tenderType,
                                  i
                                ) {
                                  return _c("tr", { key: tenderType.id }, [
                                    _c(
                                      "td",
                                      { class: { whitecell: i, date: true } },
                                      [
                                        !i
                                          ? _c("span", [
                                              _vm._v(_vm._s(dates.date))
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    !_vm.isConsolidable
                                      ? _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                tenderType.time.replace(
                                                  /^2(4|5|6|7|8)/gi,
                                                  function(matched) {
                                                    return (
                                                      "0" +
                                                      (matched - 24).toString()
                                                    )
                                                  }
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (tenderType.isItemDiscount
                                              ? tenderType.item &&
                                                tenderType.item.name
                                              : tenderType.discount &&
                                                tenderType.discount.name) ||
                                              "--"
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "td",
                                      { staticClass: "text-right" },
                                      [
                                        _c("vel-amount", {
                                          attrs: {
                                            amount: tenderType.amount,
                                            currency:
                                              location.currency ||
                                              tenderType.currency
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    !_vm.isConsolidable
                                      ? _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [_vm._v(_vm._s(tenderType.check))]
                                        )
                                      : _vm._e(),
                                    !_vm.isConsolidable
                                      ? _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (tenderType.employee &&
                                                    tenderType.employee.name) ||
                                                    "--"
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isConsolidable
                                      ? _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [_vm._v(_vm._s(tenderType.count))]
                                        )
                                      : _vm._e()
                                  ])
                                }),
                                dates.discounts.length
                                  ? _c(
                                      "tr",
                                      {
                                        key: dates.id,
                                        staticClass: "subtotal"
                                      },
                                      [
                                        _c("td", [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "discount.discounts.subtotal"
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        !_vm.isConsolidable
                                          ? _c("td")
                                          : _vm._e(),
                                        _c("td"),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "b",
                                              [
                                                _c("vel-amount", {
                                                  attrs: {
                                                    amount: dates.totalAmount,
                                                    currency:
                                                      location.currency ||
                                                      _vm.tenderType.currency
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        !_vm.isConsolidable
                                          ? _c("td")
                                          : _vm._e(),
                                        !_vm.isConsolidable
                                          ? _c("td")
                                          : _vm._e(),
                                        _vm.isConsolidable
                                          ? _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [_vm._v(_vm._s(dates.totalCount))]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                dates.discountsCash.length
                                  ? [
                                      _vm._l(dates.discountsCash, function(
                                        tenderType,
                                        i
                                      ) {
                                        return _c(
                                          "tr",
                                          { key: tenderType.id },
                                          [
                                            _c(
                                              "td",
                                              {
                                                class: {
                                                  whitecell: i,
                                                  date: true
                                                }
                                              },
                                              [
                                                !i
                                                  ? _c("span", [
                                                      _vm._v(_vm._s(dates.date))
                                                    ])
                                                  : _vm._e()
                                              ]
                                            ),
                                            !_vm.isConsolidable
                                              ? _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tenderType.time.replace(
                                                          /^2(4|5|6|7|8)/gi,
                                                          function(matched) {
                                                            return (
                                                              "0" +
                                                              (
                                                                matched - 24
                                                              ).toString()
                                                            )
                                                          }
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e(),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (tenderType.isItemDiscount
                                                      ? tenderType.item &&
                                                        tenderType.item.name
                                                      : tenderType.discount &&
                                                        tenderType.discount
                                                          .name) || "--"
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("vel-amount", {
                                                  attrs: {
                                                    amount: tenderType.amount,
                                                    currency:
                                                      location.currency ||
                                                      tenderType.currency
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            !_vm.isConsolidable
                                              ? _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(tenderType.check)
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.isConsolidable
                                              ? _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          (tenderType.employee &&
                                                            tenderType.employee
                                                              .name) ||
                                                            ""
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.isConsolidable
                                              ? _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(tenderType.count)
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }),
                                      _c(
                                        "tr",
                                        {
                                          key: dates.id,
                                          staticClass: "subtotal"
                                        },
                                        [
                                          _c("td", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "discount.discounts.subtotal"
                                                  )
                                                )
                                              )
                                            ])
                                          ]),
                                          !_vm.isConsolidable
                                            ? _c("td")
                                            : _vm._e(),
                                          _c("td"),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "b",
                                                [
                                                  _c("vel-amount", {
                                                    attrs: {
                                                      amount:
                                                        dates.totalAmountCash,
                                                      currency:
                                                        location.currency ||
                                                        _vm.tenderType.currency
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          !_vm.isConsolidable
                                            ? _c("td")
                                            : _vm._e(),
                                          !_vm.isConsolidable
                                            ? _c("td")
                                            : _vm._e(),
                                          _vm.isConsolidable
                                            ? _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _vm._v(
                                                    _vm._s(dates.totalCount)
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      dates.discountsCash.length &&
                                      dates.discounts.length
                                        ? _c(
                                            "tr",
                                            {
                                              key: dates.id,
                                              staticClass: "total"
                                            },
                                            [
                                              _c("td", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "discount.discounts.dailyTotal",
                                                        { date: dates.date }
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]),
                                              !_vm.isConsolidable
                                                ? _c("td")
                                                : _vm._e(),
                                              _c("td", {
                                                staticClass: "text-right"
                                              }),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "b",
                                                    [
                                                      _c("vel-amount", {
                                                        attrs: {
                                                          amount:
                                                            dates.totalAmountCash +
                                                            dates.totalAmount,
                                                          currency:
                                                            location.currency ||
                                                            _vm.tenderType
                                                              .currency
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              !_vm.isConsolidable
                                                ? _c("td")
                                                : _vm._e(),
                                              !_vm.isConsolidable
                                                ? _c("td")
                                                : _vm._e(),
                                              _vm.isConsolidable
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(dates.totalCount)
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _vm.isConsolidable && _vm.discountSalesTotals.length
                        ? _c(
                            "table",
                            _vm._l(_vm.discountSalesTotals, function(
                              discount,
                              index
                            ) {
                              return _c("tr", { key: index }, [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "min-width": "200px",
                                      width: "100%",
                                      "max-width": "400px"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(discount.name) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { width: "200px" }
                                  },
                                  [
                                    _c("vel-amount", {
                                      attrs: {
                                        amount: discount.amount,
                                        currency: discount.currency
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { width: "200px" }
                                  },
                                  [_vm._v(_vm._s(discount.count))]
                                )
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticStyle: { width: "160px" } }),
                            !_vm.isConsolidable
                              ? _c("th", { staticStyle: { width: "120px" } })
                              : _vm._e(),
                            _c("th", {
                              staticStyle: {
                                "min-width": "200px",
                                width: "100%"
                              }
                            }),
                            _c("th", {
                              staticClass: "text-right",
                              staticStyle: { width: "200px" }
                            }),
                            !_vm.isConsolidable
                              ? _c("th", {
                                  staticClass: "text-right",
                                  staticStyle: { width: "200px" }
                                })
                              : _vm._e(),
                            !_vm.isConsolidable
                              ? _c("th", {
                                  staticClass: "text-right",
                                  staticStyle: { width: "200px" }
                                })
                              : _vm._e(),
                            _vm.isConsolidable
                              ? _c("th", {
                                  staticClass: "text-right",
                                  staticStyle: { width: "200px" }
                                })
                              : _vm._e()
                          ])
                        ]),
                        _c("tbody", [
                          _c("tr", { staticClass: "subCategoryTotal" }, [
                            _c("td", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("discount.discounts.total"))
                                )
                              ])
                            ]),
                            !_vm.isConsolidable ? _c("td") : _vm._e(),
                            _c("td"),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("vel-amount", {
                                  attrs: {
                                    amount: location.allTotalAmount,
                                    currency: location.currency
                                  }
                                })
                              ],
                              1
                            ),
                            !_vm.isConsolidable ? _c("td") : _vm._e(),
                            !_vm.isConsolidable ? _c("td") : _vm._e(),
                            _vm.isConsolidable
                              ? _c("td", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(location.allTotalCount))
                                ])
                              : _vm._e()
                          ])
                        ])
                      ])
                    ])
                  }
                )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }