var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reset-password-page" }, [
    _c("div", { staticClass: "reset-password-content__wrapper" }, [
      !_vm.status.isLoading
        ? _c(
            "div",
            { staticClass: "reset-password-form-container" },
            [
              _vm.status.isValid
                ? _c("reset-password-form", {
                    staticClass: "reset-password-form",
                    attrs: { token: _vm.token }
                  })
                : _c(
                    "div",
                    [
                      _c("Alert", { attrs: { type: "error" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("resetPassword.form.tokenValidityError.text")
                          )
                        )
                      ]),
                      _c("router-link", { attrs: { to: "/" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("resetPassword.form.linkToLoginPage.text")
                          )
                        )
                      ])
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "reset-password-footer" },
                [
                  _c(
                    "vel-button",
                    {
                      attrs: { type: "link" },
                      on: { click: _vm.toggleLanguage }
                    },
                    [
                      _c(
                        "span",
                        [
                          _c("vel-icon", {
                            attrs: { name: "globe-solid", size: "small" }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("resetPassword.switchLanguage")) +
                              " "
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("h6", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("rightsReserved", {
                          currentYear: _vm.currentYear
                        })
                      )
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }