<template>
  <vel-page class="users" :loading="status.isLoading" :enable-toolbar="true">
    <template v-slot:title>
      {{ $t('pages.users') }}
    </template>

    <template v-slot:toolbar>
      <vel-input-group>
        <vel-link style="margin-left: auto;" :to="{ name: 'createUser' }">
          {{ $t('users.actions.create') }}
        </vel-link>
      </vel-input-group>
    </template>

    <template>
      <div class="users__filter" v-if="!isMediumUp">
        <vel-table-filter v-model="inputFilter" />
      </div>
    </template>

    <template v-if="isMediumUp">
      <vel-card class="users__table">
        <vel-table
          :rows="rows"
          :columns="columns"
          :defaultSort="defaultSort"
          :filterValue="filter"
          :filterable="true"
          :filterPlaceholder="$t('defaults.placeholder.filter')"
          @filter-change="setFilter"
          :showRowsLength="true"
          :initialLength="getUsersAmount"
        >
          <template v-slot:name-column="scope">
            <div class="users__name">
              <vel-avatar class="users__name-avatar" :fullname="scope.row.firstName + ' ' + scope.row.lastName" />
              <router-link class="users__name-text" :to="{ name: 'user', params: { userId: scope.row.id } }">
                {{ scope.row.firstName }} {{ scope.row.lastName }}
              </router-link>
            </div>
          </template>
          <template v-slot:passwordReset-column="scope">
            <UserReset :user="scope.row" />
          </template>
          <template v-slot:enabled-column="scope">
            <vel-icon v-if="scope.row.enabled" name="checkmark-circle" />
            <vel-icon v-else name="cancel" class="disabled" />
          </template>
          <template v-slot:empty>
            {{ $t('users.errors.noData') }}
          </template>
        </vel-table>
      </vel-card>
    </template>

    <template v-else>
      <vel-card class="users__list" title-tag="header" v-for="user in rows" :key="user.email">
        <template v-slot:title>
          <vel-avatar class="user__avatar" :fullname="user.name" />
          <router-link class="users__name-link" :to="{ name: 'user', params: { userId: user.id } }">
            <h3 class="user__name">{{ user.firstName }} {{ user.lastName }}</h3>
          </router-link>
        </template>
        <p class="user__email">{{ user.email }}</p>
        <UserReset :user="user" />
      </vel-card>
    </template>
  </vel-page>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';

import DeviceMixin from '@/mixins/device-mixin';
import UserReset from './components/user-reset/UserReset';
import VelAvatar from '@/components/avatar/VelAvatar';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelInputGroup from '@/components/input/VelInputGroup';
import VelLink from '@/components/link/VelLink';
import VelPage from '@/components/page/VelPage';
import VelTable from '@/components/table/VelTable';
import VelTableFilter from '@/components/table/VelTableFilter';
import {mapActions as mapPiniaActions} from "pinia/dist/pinia";
import {useDataAccount} from "@/stores/data/account.module";
import {useDataUsers} from "@/stores/data/users.module";

export default {
  name: 'UsersPage',
  mixins: [DeviceMixin],
  components: {
    VelPage,
    VelCard,
    VelTable,
    VelLink,
    VelIcon,
    VelInputGroup,
    VelAvatar,
    UserReset,
    VelTableFilter
  },
  methods: {
    ...mapPiniaActions(useDataUsers, ['getUsers', 'setFilter']),
    ...mapPiniaActions(useDataAccount, ['getPasswordResetUrl']),
    toggleResetPasswordModal() {
      this.resetPasswordModalToggled = !this.resetPasswordModalToggled;
      this.confirmationModalToggled = !this.confirmationModalToggled;
    },
    getSortedRows() {
      return (this.getUsersRows || []).sort((a, b) => (a[this.defaultSort] < b[this.defaultSort] ? -1 : 1));
    }
  },
  computed: {
    ...mapPiniaState(useDataUsers, ['users', 'status', 'filter']),
    ...mapPiniaState(useDataAccount, ['user', 'passwordResetUrl']),
    ...mapPiniaState(useDataUsers, ['getUsersRows', 'getUsersAmount']),
    defaultSort() {
      return 'name';
    },
    rows() {
      return this.getSortedRows();
    },
    columns() {
      return {
        id: {
          visible: false
        },
        name: {
          title: this.$t('users.tableColumns.name.text'),
          sortable: true,
          width: '20%'
        },
        email: {
          title: this.$t('users.tableColumns.email.text'),
          sortable: true,
          width: '50%'
        },
        enabled: {
          title: this.$t('users.tableColumns.enabled.text'),
          sortable: true
        },
        passwordReset: {
          title: this.$t('users.tableColumns.passwordReset.text'),
          width: '15  %'
        }
      };
    },
    inputFilter: {
      get() {
        return this.filter;
      },
      set(value) {
        this.setFilter(value);
      }
    }
  },
  created() {
    this.getUsers();
    this.$on('toggleModal', () => this.toggleResetPasswordModal());
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

// name
$locations__name-font-weight: $font-medium;

.users {
  @include breakpoint(medium) {
    &__name {
      align-items: center;
      display: inline-flex;
    }

    &__name-text {
      font-weight: $locations__name-font-weight;
      @include text-ellipsis;
    }

    &__name-avatar {
      margin-right: rem-calc(8);
    }
  }
  @include breakpoint(medium) {
    &__filter {
      margin-bottom: rem-calc(20);
    }
  }

  .vel-table-header__cell {
    &:nth-last-child(2),
    &:last-child {
      text-align: center;
    }
  }

  .vel-table__cell {
    &:nth-last-child(2),
    &:last-child {
      text-align: center;
    }

    &:nth-last-child(2) {
      color: green;

      .disabled {
        color: red;
      }

      .vel-icon {
        height: 1.375rem;
        margin-top: 0.5rem;
        width: 1.375rem;
      }
    }
  }
}
</style>
