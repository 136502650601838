var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: !_vm.employee.terminated
              ? _vm.$t("employee.deleteModal.employeeReactivate.title")
              : _vm.$t("employee.deleteModal.employeeDeactivate.title"),
            static: true,
            centered: true,
            "hide-header-close": true,
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c("b-container", { attrs: { fluid: "" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right ml-2",
                            attrs: {
                              variant: !_vm.employee.terminated
                                ? "primary"
                                : "danger"
                            },
                            on: {
                              click: function($event) {
                                _vm.showReactivate = false
                                _vm.showReactivateConfirmation = true
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.employee.terminated
                                    ? _vm.$t("employee.deleteModal.reactivate")
                                    : _vm.$t("employee.deleteModal.deactivate")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("onCancel")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("employee.deleteModal.cancel")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showReactivate,
            callback: function($$v) {
              _vm.showReactivate = $$v
            },
            expression: "showReactivate"
          }
        },
        [
          _c("b-container", { attrs: { fluid: "" } }, [
            _vm._v(
              " " +
                _vm._s(
                  !_vm.employee.terminated
                    ? _vm.$t("employee.deleteModal.employeeReactivate.body")
                    : _vm.$t("employee.deleteModal.employeeDeactivate.body")
                ) +
                " "
            )
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: !_vm.employee.terminated
              ? _vm.$t("employee.deleteModal.employeeReactivate.title")
              : _vm.$t("employee.deleteModal.employeeDeactivate.title"),
            static: true,
            centered: true,
            "hide-header-close": "true",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right ml-2",
                          attrs: {
                            disabled: !_vm.reason || _vm.reason === "",
                            variant:
                              !_vm.reason || _vm.reason === ""
                                ? "secondary"
                                : "primary",
                            f: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.employee.terminatedReason = _vm.reason
                              _vm.showReactivateConfirmation = false
                              _vm.$emit("onSuccess")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("employee.deleteModal.save")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "link", f: "" },
                          on: {
                            click: function($event) {
                              _vm.showReactivateConfirmation = false
                              _vm.$emit("onCancel")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("employee.deleteModal.cancel")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showReactivateConfirmation,
            callback: function($$v) {
              _vm.showReactivateConfirmation = $$v
            },
            expression: "showReactivateConfirmation"
          }
        },
        [
          _c("b-container", { attrs: { fluid: "" } }, [
            _c(
              "div",
              {
                staticClass: "pb-5",
                staticStyle: { "border-bottom": "1px solid #ccc" }
              },
              [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        !_vm.employee.terminated
                          ? _vm.$t(
                              "employee.deleteModal.employeeReactivate.body"
                            )
                          : _vm.$t(
                              "employee.deleteModal.employeeDeactivate.body"
                            )
                      ) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "pl-2 mt-3 font-weight-bolder" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "employee.deleteModal.employeeReactivate.confirmed"
                      )
                    )
                  )
                ])
              ]
            ),
            _c(
              "div",
              [
                _c("h6", { staticClass: "mt-4" }, [
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.employee.terminated
                            ? _vm.$t(
                                "employee.deleteModal.employeeReactivate.provideReason"
                              )
                            : _vm.$t(
                                "employee.deleteModal.employeeDeactivate.provideReason"
                              )
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("b-textarea", {
                  attrs: {
                    placeholder: !_vm.employee.terminated
                      ? _vm.$t(
                          "employee.deleteModal.employeeReactivate.provideReasonPlaceholder"
                        )
                      : _vm.$t(
                          "employee.deleteModal.employeeDeactivate.provideReasonPlaceholder"
                        )
                  },
                  model: {
                    value: _vm.reason,
                    callback: function($$v) {
                      _vm.reason = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "reason"
                  }
                }),
                _vm.employee.terminated
                  ? _c(
                      "div",
                      [
                        _c("h6", { staticClass: "mt-4" }, [
                          _c("b", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "employee.deleteModal.employeeDeactivate.rehirable"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c(
                          "b-button-group",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: _vm.employee.rehireable
                                    ? "primary"
                                    : "light"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.employee.rehireable = true
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("defaults.confirmations.yes")
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: !_vm.employee.rehireable
                                    ? "primary"
                                    : "light"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.employee.rehireable = false
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("defaults.confirmations.no")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }