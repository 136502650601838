import { handleResponse, logout, post } from '@/helpers/service.helper';
import {useDataConfig } from '@/stores/data/config.module';

/**
 * Authenticate and get an access token
 * @link https://api.posveloce.com/docs/#operation/authenticate
 */
export const login = (email, password) =>
  post(null, `/users/authenticate`, { email, password }).then(user => {
    if (user.token) {
      localStorage.setItem('user', JSON.stringify(user));
      useDataConfig().getConfig();
    }
    return user;
  });

/**
 * Refresh access token
 * @link https://api.posveloce.com/docs/#operation/refreshToken
 */
export const refreshToken = () => {
  if (!localStorage.getItem('user')) {
    return Promise.resolve(null);
  }

  const user = JSON.parse(localStorage.getItem('user'));

  return post(user.token, `/users/refreshToken`, null).then(res => {
    if (res.token) {
      const updatedUser = { ...user, token: res.token };
      localStorage.setItem('user', JSON.stringify(updatedUser));
    }
  });
};

export default {
  login,
  logout,
  refreshToken
};
