var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-backoffice-page",
    {
      staticClass: "backoffice-page",
      attrs: {
        enableToolbar: false,
        "enable-date-range": false,
        "enable-date-picker": false,
        "enable-groups": false,
        "show-date-compare": false,
        "enable-all-locations": false,
        mode: "edit",
        loading: _vm.loading
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("discount-footer", {
                attrs: {
                  disocunt: _vm.itemProfile,
                  pending: _vm.pending,
                  canSave: _vm.canSave,
                  "can-continue": _vm.canContinue,
                  showCanContinue: _vm.activeTab < 2
                },
                on: {
                  onSave: function($event) {
                    return _vm.updateItemProfile()
                  },
                  onContinue: function($event) {
                    _vm.activeTab++
                  },
                  onCancel: function($event) {
                    return _vm.handleCancel(false)
                  }
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "header",
          fn: function() {
            return [
              _c("discount-header", {
                attrs: { disocunt: _vm.itemProfile },
                on: {
                  "update:disocunt": function($event) {
                    _vm.itemProfile = $event
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-tabs",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pending,
              expression: "pending"
            }
          ],
          staticClass: "bg-grey",
          attrs: { active: "" },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("discount.edit.tabs.generalConfiguration"),
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("discount.edit.configuration")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column flex-md-row mb-md-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass:
                            "mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0",
                          attrs: {
                            label: _vm.$t(
                              "discount.edit.fields.description.label"
                            ),
                            description: _vm.$t("discount.required"),
                            "label-for": "name"
                          }
                        },
                        [
                          _c("b-form-input", {
                            class: {
                              required:
                                !_vm.itemProfile.name ||
                                _vm.itemProfile.name === ""
                            },
                            attrs: {
                              id: "name",
                              state: !_vm.submited
                                ? null
                                : _vm.itemProfile.name !== "" && null,
                              maxlength: "50",
                              "aria-describedby":
                                "input-live-help input-live-feedback"
                            },
                            model: {
                              value: _vm.itemProfile.name,
                              callback: function($$v) {
                                _vm.$set(_vm.itemProfile, "name", $$v)
                              },
                              expression: "itemProfile.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass:
                            "mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0",
                          attrs: {
                            label: _vm.$t(
                              "discount.edit.fields.alternativeName.label"
                            ),
                            "label-for": "name"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "name",
                              maxlength: "50",
                              "aria-describedby":
                                "input-live-help input-live-feedback"
                            },
                            model: {
                              value: _vm.itemProfile.alternativeName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemProfile,
                                  "alternativeName",
                                  $$v
                                )
                              },
                              expression: "itemProfile.alternativeName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass:
                            "mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0",
                          attrs: {
                            label: _vm.$t(
                              "discount.edit.fields.discountType.label"
                            ),
                            "label-for": "name"
                          }
                        },
                        [
                          _c("b-form-select", {
                            staticClass: "form-text",
                            attrs: {
                              options: [].concat(_vm.discountTypes),
                              id: "type",
                              "value-field": "id",
                              "text-field": "name",
                              "aria-describedby":
                                "input-live-help input-live-feedback"
                            },
                            model: {
                              value: _vm.itemProfile.type,
                              callback: function($$v) {
                                _vm.$set(_vm.itemProfile, "type", $$v)
                              },
                              expression: "itemProfile.type"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.itemProfile.type !== null
                        ? _c(
                            "b-form-group",
                            {
                              staticClass:
                                "mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0",
                              attrs: {
                                label: _vm.$t(
                                  "discount.edit.fields." +
                                    (_vm.itemProfile.type <= 2
                                      ? "amount"
                                      : "percentage") +
                                    ".label"
                                )
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  attrs: {
                                    prepend:
                                      _vm.itemProfile.type <= 2 ? "$" : "%"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "deduction",
                                      "no-wheel": "",
                                      "lazy-formatter": "",
                                      formatter: _vm.formatAmount,
                                      step: "0.01",
                                      "aria-describedby":
                                        "input-live-help input-live-feedback"
                                    },
                                    model: {
                                      value: _vm.itemProfile.amount,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.itemProfile,
                                          "amount",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "itemProfile.amount"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("discount.edit.taxes")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("span", { staticClass: "pr-4" }, [
                        _vm._v(
                          _vm._s(_vm.$t("discount.edit.fields.taxesOnInvoice"))
                        )
                      ]),
                      _c("b-form-checkbox", {
                        staticClass: "d-inline-block",
                        attrs: { name: "check-button", switch: "" },
                        model: {
                          value: _vm.itemProfile.taxesOnInvoice,
                          callback: function($$v) {
                            _vm.$set(_vm.itemProfile, "taxesOnInvoice", $$v)
                          },
                          expression: "itemProfile.taxesOnInvoice"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { id: "taxesOnInvoiceType" } },
                    [
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "mt-4",
                          attrs: { name: "taxesOnInvoiceType", value: 1 },
                          model: {
                            value: _vm.itemProfile.taxesOnInvoiceType,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemProfile,
                                "taxesOnInvoiceType",
                                $$v
                              )
                            },
                            expression: "itemProfile.taxesOnInvoiceType"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("discount.edit.fields.afterDiscount")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "mt-4",
                          attrs: { name: "taxesOnInvoiceType", value: 2 },
                          model: {
                            value: _vm.itemProfile.taxesOnInvoiceType,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemProfile,
                                "taxesOnInvoiceType",
                                $$v
                              )
                            },
                            expression: "itemProfile.taxesOnInvoiceType"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("discount.edit.fields.beforeDiscount")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "mt-4",
                          attrs: { name: "taxesOnInvoiceType", value: 3 },
                          model: {
                            value: _vm.itemProfile.taxesOnInvoiceType,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemProfile,
                                "taxesOnInvoiceType",
                                $$v
                              )
                            },
                            expression: "itemProfile.taxesOnInvoiceType"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("discount.edit.fields.taxExcluded")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "mt-4",
                          attrs: { name: "taxesOnInvoiceType", value: 4 },
                          model: {
                            value: _vm.itemProfile.taxesOnInvoiceType,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemProfile,
                                "taxesOnInvoiceType",
                                $$v
                              )
                            },
                            expression: "itemProfile.taxesOnInvoiceType"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("discount.edit.fields.taxIncluded")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass:
                        "b-md-0 col-12 col-md-6 col-lg-2 mt-4 p-0 mb-4",
                      attrs: {
                        label: _vm.$t(
                          "discount.edit.fields.removeTaxCategory.label"
                        ),
                        "label-for": "name"
                      }
                    },
                    [
                      _c("b-form-select", {
                        staticClass: "form-text",
                        attrs: {
                          options: [].concat(_vm.taxCategories),
                          id: "discountType",
                          "value-field": "id",
                          "text-field": "name",
                          "aria-describedby":
                            "input-live-help input-live-feedback"
                        },
                        model: {
                          value: _vm.itemProfile.removeTaxCategoryId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.itemProfile,
                              "removeTaxCategoryId",
                              $$v
                            )
                          },
                          expression: "itemProfile.removeTaxCategoryId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("discount.edit.subCategory")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column flex-md-row mb-md-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass:
                            "w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-3 pl-md-0 p-0",
                          attrs: {
                            label: _vm.$t(
                              "discount.edit.fields.subCategory.label"
                            ),
                            "label-for": "name"
                          }
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.divisions,
                                multiple: true,
                                showLabels: false,
                                "close-on-select": false,
                                "clear-on-select": false,
                                "preserve-search": true,
                                value: _vm.selectedDivisionsId,
                                placeholder: "",
                                label: "name",
                                "options-limit": 100000,
                                "track-by": "id",
                                "max-height": 600
                              },
                              on: { input: _vm.onDivisionChange },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var values = ref.values
                                    var isOpen = ref.isOpen
                                    return [
                                      values.length > 0 && !isOpen
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "multiselect__single"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "discount.edit.fields.subCategorySelected",
                                                      {
                                                        count:
                                                          values.length || 0
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      values.length === 0 && !isOpen
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "multiselect__single"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "discount.emptyValue"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("template", { slot: "noResult" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "dashboard.locationSelector.noResult"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.itemProfile.discountDivisions, function(tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag.id,
                        staticStyle: {
                          "margin-top": "1rem",
                          "margin-right": "1rem"
                        },
                        attrs: { type: "primary", closable: "" },
                        on: {
                          close: function($event) {
                            ;(_vm.itemProfile.discountDivisions || []).splice(
                              (_vm.itemProfile.discountDivisions || [])
                                .map(function(x) {
                                  return x.id
                                })
                                .indexOf(tag.id),
                              1
                            )
                            _vm.onDivisionChange(
                              _vm.itemProfile.discountDivisions
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(tag.division.name) + " ")]
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "row d-flex mt-5 p-0 m-0" },
                    [
                      _vm._l(_vm.itemProfile.discountDivisions, function(
                        discountDivision,
                        di
                      ) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-2 mb-4 pl-4 align-self-center"
                            },
                            [_vm._v(_vm._s(discountDivision.division.name))]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-2 mb-4" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  attrs: {
                                    prepend:
                                      _vm.itemProfile.type <= 2 ? "$" : "%"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "deduction",
                                      "no-wheel": "",
                                      "lazy-formatter": "",
                                      formatter: _vm.formatAmount,
                                      step: "0.01",
                                      "aria-describedby":
                                        "input-live-help input-live-feedback"
                                    },
                                    model: {
                                      value:
                                        _vm.itemProfile.discountDivisions[di]
                                          .value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.itemProfile.discountDivisions[di],
                                          "value",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "itemProfile.discountDivisions[di].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("discount.edit.tabs.availability"),
                disabled: !_vm.canContinue,
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("discount.edit.misc")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "unrestrictedAccess" },
                      model: {
                        value: _vm.itemProfile.unrestrictedAccess,
                        callback: function($$v) {
                          _vm.$set(_vm.itemProfile, "unrestrictedAccess", $$v)
                        },
                        expression: "itemProfile.unrestrictedAccess"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("discount.edit.fields.unrestrictedAccess")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "mt-4 d-inline-block",
                          attrs: { name: "cashReconciliation" },
                          model: {
                            value: _vm.itemProfile.cashReconciliation,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemProfile,
                                "cashReconciliation",
                                $$v
                              )
                            },
                            expression: "itemProfile.cashReconciliation"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "discount.edit.fields.cashReconciliation"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block pl-5 ml-md-5 mt-2 mt-md-0",
                          staticStyle: { "max-width": "300px", width: "100%" }
                        },
                        [
                          _c("b-form-select", {
                            staticClass: "form-text",
                            staticStyle: { width: "100%" },
                            attrs: {
                              options: [
                                { id: 0, name: _vm.$t("discount.emptyValue") }
                              ].concat(_vm.cashReconciliationTypes),
                              id: "cashReconciliationType",
                              "value-field": "id",
                              "text-field": "name",
                              "aria-describedby":
                                "input-live-help input-live-feedback"
                            },
                            model: {
                              value: _vm.itemProfile.cashReconciliationType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemProfile,
                                  "cashReconciliationType",
                                  $$v
                                )
                              },
                              expression: "itemProfile.cashReconciliationType"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "mt-4",
                      attrs: { name: "qualityDiscount" },
                      model: {
                        value: _vm.itemProfile.qualityDiscount,
                        callback: function($$v) {
                          _vm.$set(_vm.itemProfile, "qualityDiscount", $$v)
                        },
                        expression: "itemProfile.qualityDiscount"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("discount.edit.fields.qualityDiscount")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "mt-4",
                      attrs: { name: "withReference" },
                      model: {
                        value: _vm.itemProfile.withReference,
                        callback: function($$v) {
                          _vm.$set(_vm.itemProfile, "withReference", $$v)
                        },
                        expression: "itemProfile.withReference"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("discount.edit.fields.withReference")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("discount.edit.availability")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.pending,
                          expression: "pending"
                        }
                      ],
                      staticClass: "mb-md-4"
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "ml-0 mb-4",
                          on: { change: _vm.selectAll },
                          model: {
                            value: _vm.availableAllTime,
                            callback: function($$v) {
                              _vm.availableAllTime = $$v
                            },
                            expression: "availableAllTime"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "employeeCreate.availability.form.selectAllTime"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _vm.shifts.length
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "availability-grid",
                                staticStyle: { "overflow-x": "auto" }
                              },
                              [
                                _c(
                                  "b-row",
                                  {
                                    staticClass: "mb-4",
                                    style:
                                      "width:" +
                                      (180 * _vm.shifts.length + 120) +
                                      "px"
                                  },
                                  [
                                    _c("b-col", {
                                      staticClass: "p0",
                                      staticStyle: { width: "120px" }
                                    }),
                                    _vm._l(_vm.shifts, function(shift) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: shift.id,
                                          staticClass: "p-0",
                                          staticStyle: { width: "180px" }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "w-100 font-weight-normal btn-white",
                                              attrs: { variant: "light" }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(shift.name) + " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _vm._l([0, 1, 2, 3, 4, 5, 6], function(
                                  dayNumber
                                ) {
                                  return _c(
                                    "b-row",
                                    {
                                      key: dayNumber,
                                      staticClass: "mb-4",
                                      style:
                                        "width:" +
                                        (180 * _vm.shifts.length + 120) +
                                        "px"
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        { staticStyle: { width: "120px" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "employeeCreate.availability.dayOfWeek." +
                                                    dayNumber
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _vm._l(_vm.shifts, function(shift) {
                                        return _c(
                                          "b-col",
                                          {
                                            key: shift.id,
                                            staticClass: "p-0 day",
                                            staticStyle: { width: "180px" }
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "w-100 font-weight-normal",
                                                attrs: {
                                                  variant: _vm.availabilityForm[
                                                    shift.id
                                                  ][dayNumber]
                                                    ? "success"
                                                    : "light"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggle(
                                                      shift.id,
                                                      dayNumber
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.availabilityForm[
                                                        shift.id
                                                      ][dayNumber]
                                                        ? _vm.$t(
                                                            "employeeCreate.availability.form.available"
                                                          )
                                                        : _vm.$t(
                                                            "employeeCreate.availability.form.notAvailable"
                                                          )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                })
                              ],
                              2
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                title: _vm.$t("discount.edit.tabs.couponItems"),
                disabled: !_vm.canContinue,
                "title-item-class": "col-sm-12 col-md-auto p-0 m-0"
              }
            },
            [
              _c("h5", { staticClass: "pt-4 px-4 pb-2" }, [
                _vm._v(_vm._s(_vm.$t("discount.edit.itemList")))
              ]),
              _c(
                "b-container",
                { staticClass: "content p-4", attrs: { fluid: "" } },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.itemForm-sidebar",
                          modifiers: { "itemForm-sidebar": true }
                        }
                      ],
                      attrs: {
                        disabled: _vm.pending,
                        variant: "outline-primary"
                      },
                      on: {
                        click: function($event) {
                          _vm.discountCouponEdit = {
                            itemId: null,
                            couponValue: "0.00"
                          }
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "plus",
                          "aria-hidden": "true",
                          scale: "1.4"
                        }
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("discount.edit.btns.addItem")) + " "
                      )
                    ],
                    1
                  ),
                  _c("b-table", {
                    ref: "table",
                    staticClass: "mt-5",
                    attrs: {
                      striped: "",
                      bordered: "",
                      hover: "",
                      "show-empty": "",
                      fixed: "",
                      "primary-key": "id",
                      "empty-text": _vm.$t("employee.empty"),
                      "empty-filtered-text": _vm.$t("employee.empty"),
                      busy: _vm.loading,
                      fields: _vm.fields,
                      "head-variant": "info",
                      items: (_vm.itemProfile.discountCoupons || []).filter(
                        function(i) {
                          return !i.delete
                        }
                      ),
                      responsive: "sm",
                      "sticky-header": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "empty",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "p-2" }, [
                              _vm._v(_vm._s(scope.emptyText))
                            ])
                          ]
                        }
                      },
                      {
                        key: "emptyfiltered",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "p-2" }, [
                              _vm._v(_vm._s(scope.emptyFilteredText))
                            ])
                          ]
                        }
                      },
                      {
                        key: "table-busy",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-danger my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle p-2 m-4",
                                  attrs: { variant: "primary" }
                                })
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "cell(index)",
                        fn: function(scope) {
                          return [_vm._v(" " + _vm._s(scope.index + 1) + " ")]
                        }
                      },
                      {
                        key: "cell(couponValue)",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.item.couponValue.toFixed(2)) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(edit)",
                        fn: function(scope) {
                          return [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.itemForm-sidebar",
                                    modifiers: { "itemForm-sidebar": true }
                                  }
                                ],
                                staticClass: "p-0",
                                attrs: { variant: "link" },
                                on: {
                                  click: function($event) {
                                    return _vm.onEditItem(
                                      scope.item,
                                      scope.index
                                    )
                                  }
                                }
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "row-action text-primary",
                                  attrs: {
                                    icon: "pencil-fill",
                                    "aria-label": "Edit"
                                  }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("employee.list.table.edit")) +
                                    " "
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(delete)",
                        fn: function(scope) {
                          return [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "link text-danger" },
                                on: {
                                  click: function($event) {
                                    _vm.deleteEntity = scope.item
                                  }
                                }
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "row-action text-danger",
                                  attrs: {
                                    icon: "trash-fill",
                                    "aria-label": "Delete"
                                  }
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("mealPlan.delete")) + " "
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.discountCouponEdit
        ? _c("discount-item-sidebar", {
            attrs: {
              items: _vm.items,
              "discount-coupon": _vm.discountCouponEdit,
              pending: _vm.pending
            },
            on: {
              "update:discountCoupon": function($event) {
                _vm.discountCouponEdit = $event
              },
              "update:discount-coupon": function($event) {
                _vm.discountCouponEdit = $event
              },
              "update:pending": function($event) {
                _vm.pending = $event
              },
              onCancel: _vm.onClickCancel,
              onAdd: _vm.onClickAdd
            }
          })
        : _vm._e(),
      _c("employee-delete-modal", {
        attrs: {
          title: _vm.$t("discount.edit.list.delete.confirm.title", {
            name: _vm.deleteEntity && _vm.deleteEntity.item.name
          }),
          body: _vm.$t("discount.edit.list.delete.confirm.body", {
            name: _vm.deleteEntity && _vm.deleteEntity.item.name
          }),
          show: !!_vm.deleteEntity
        },
        on: {
          onDelete: function($event) {
            return _vm.onDeleteItem(_vm.deleteEntity)
          },
          onCancel: function($event) {
            _vm.deleteEntity = null
          }
        }
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("discardChange.title"),
            static: true,
            centered: true,
            "hide-header-close": true,
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          on: {
            hidden: function($event) {
              return _vm.$emit("onCancel", true)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c("b-container", { attrs: { fluid: "" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.continue")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right ml-2",
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                _vm.showDiscardChanges = false
                                _vm.handleCancel(true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("discardChange.discard")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showDiscardChanges,
            callback: function($$v) {
              _vm.showDiscardChanges = $$v
            },
            expression: "showDiscardChanges"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("discardChange.body")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }