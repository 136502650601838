<template>
  <div class="config_locations_display bootstrap">
    <div v-for="(opt, i) in list" :key="opt.text">
      <vel-divider content-position="left">{{ opt.title }}</vel-divider>
      <draggable @change="update" v-model="opt.elements" :group="i" :options="{ delayOnTouchOnly: true, delay: 800 }">
        <transition-group type="transition" name="flip-list">
          <div
            class="row my-2 mx-0 py-1 item"
            style="border: 1px solid #dddddd; border-radius: 5px;"
            v-for="el in opt.elements"
            :key="el.text"
          >
            <div class="col-10 d-flex align-items-center" v-if="isAllowed(el.model)">
              <span class="material-icons">drag_indicator</span>
              {{ el.text }}
            </div>
            <div class="col-2 text-right" v-if="isAllowed(el.model)">
              <b-form-checkbox
                v-model="config[el.model].value"
                @change="update"
                name="check-button"
                switch
              ></b-form-checkbox>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>
<script>
import Draggable from 'vuedraggable';
import VelDivider from '@/components/divider/VelDivider';
import { environment } from '@/configs';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useDataConfig} from "@/stores/data/config.module";

export default {
  name: 'config-locations-report-display',
  components: {
    VelDivider,
    Draggable
  },
  data() {
    return {
      options: []
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.updateOptions();
      }
    }
  },
  mounted() {
    this.updateOptions();
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    config: {
      get() {
        return this.get;
      },
      set() {
        return true;
      }
    },
    list() {
      return this.options;
    }
  },
  methods: {
    ...mapPiniaActions(useDataConfig, ['patchConfig']),
    updateOptions() {
      this.options = [
        {
          title: this.$t('editors.groups.indicators'),
          elements: [
            {
              model: 'locations_report_show_gross_sales_volume',
              text: this.$t('dashboard.salesVolume.title'),
              order: this.getOrder('locations_report_show_gross_sales_volume', 1)
            },
            {
              model: 'locations_report_show_gross_sales_per_customer',
              text: this.$t('dashboard.salesPerCustomer.title'),
              order: this.getOrder('locations_report_show_gross_sales_per_customer', 2)
            },
            {
              model: 'locations_report_show_cash_discounts',
              text: this.$t('dashboard.cashDiscounts.title'),
              order: this.getOrder('locations_report_show_cash_discounts', 3)
            },
            {
              model: 'locations_report_show_surcharge',
              text: this.$t('dashboard.surcharge.title'),
              order: this.getOrder('locations_report_show_surcharge', 4)
            },
            {
              model: 'locations_report_show_net_sales_volume',
              text: this.$t('dashboard.netSalesVolume.title'),
              order: this.getOrder('locations_report_show_net_sales_volume', 5)
            },
            {
              model: 'locations_report_show_taxe_volume',
              text: this.$t('dashboard.taxesVolume.title'),
              order: this.getOrder('locations_report_show_taxe_volume', 6)
            },
            {
              model: 'locations_report_show_net_sales_volume_taxes',
              text: this.$t('dashboard.netSalesWithTaxesVolume.title'),
              order: this.getOrder('locations_report_show_net_sales_volume_taxes', 7)
            },
            {
              model: 'locations_report_show_tips_total_amount',
              text: this.$t('dashboard.tipsTotalAmount.title'),
              order: this.getOrder('locations_report_show_tips_total_amount', 8)
            },
            {
              model: 'locations_report_show_total_with_tips_amount',
              text: this.$t('dashboard.totalWithTipsAmount.title'),
              order: this.getOrder('locations_report_show_total_with_tips_amount', 9)
            },
            {
              model: 'locations_report_show_customer_count',
              text: this.$t('dashboard.customersCount.title'),
              order: this.getOrder('locations_report_show_customer_count', 10)
            },
            {
              model: 'locations_report_show_discounts_percentage',
              text: this.$t('dashboard.discountsPercentage.title'),
              order: this.getOrder('locations_report_show_discounts_percentage', 11)
            },
            {
              model: 'locations_report_show_meal_count',
              text: this.$t('dashboard.mealCount.title'),
              order: this.getOrder('locations_report_show_meal_count', 12)
            },
            {
              model: 'locations_report_show_paid_ins',
              text: this.$t('dashboard.paidInsAmount.title'),
              order: this.getOrder('locations_report_show_paid_ins', 13)
            },
            {
              model: 'locations_report_show_paid_outs',
              text: this.$t('dashboard.paidOuts.title'),
              order: this.getOrder('locations_report_show_paid_outs', 14)
            },
            {
              model: 'locations_report_show_net_cash',
              text: this.$t('dashboard.netCashAmount.title'),
              order: this.getOrder('locations_report_show_net_cash', 15)
            },
            {
              model: 'locations_report_show_discounts_volume',
              text: this.$t('dashboard.discountsVolume.title'),
              order: this.getOrder('locations_report_show_discounts_volume', 16)
            },
            {
              model: 'locations_report_show_gross_sales_per_order',
              text: this.$t('dashboard.salesPerOrder.title'),
              order: this.getOrder('locations_report_show_gross_sales_per_order', 17)
            },
            {
              model: 'locations_report_show_net_income_per_customer',
              text: this.$t('dashboard.netIncomeCustomer.title'),
              order: this.getOrder('locations_report_show_net_income_per_customer', 18)
            },
            {
              model: 'locations_report_show_income_per_order',
              text: this.$t('dashboard.incomeOrder.title'),
              order: this.getOrder('locations_report_show_income_per_order', 19)
            }
          ].sort((a, b) => a.order - b.order)
        },
        {
          title: this.$t('editors.groups.charts'),
          elements: [
            {
              model: 'locations_report_show_locations_sales',
              text: this.$t('locations.sales.graph.config.title'),
              order: this.getOrder('locations_report_show_locations_sales', 1)
            },
            {
              model: 'locations_report_show_item_sales_top10',
              text: this.$t('items.sales.graph.config.title'),
              order: this.getOrder('locations_report_show_item_sales_top10', 2)
            },
            {
              model: 'locations_report_show_employees_sales_top10',
              text: this.$t('employees.sales.graph.config.title'),
              order: this.getOrder('locations_report_show_employees_sales_top10', 3)
            },
            {
              model: 'locations_report_show_gross_sales_per_order_mode',
              text: this.$t('sales.zoneChart.config.title'),
              order: this.getOrder('locations_report_show_gross_sales_per_order_mode', 4)
            },
            {
              model: 'locations_report_show_gross_sales_per_department',
              text: this.$t('sales.departmentChart.config.title'),
              order: this.getOrder('locations_report_show_gross_sales_per_department', 5)
            },
            {
              model: 'locations_report_show_gross_sales_per_mode',
              text: this.$t('sales.modeChart.config.title'),
              order: this.getOrder('locations_report_show_gross_sales_per_mode', 6)
            },
            {
              model: 'locations_report_show_customers_per_mode',
              text: this.$t('sales.modeCustomersChart.config.title'),
              order: this.getOrder('locations_report_show_customers_per_mode', 7)
            },
            {
              model: 'locations_report_show_mode_sales_per_customers',
              text: this.$t('sales.modeSalesPerCustomersChart.config.title'),
              order: this.getOrder('locations_report_show_mode_sales_per_customers', 8)
            },
            {
              model: 'locations_report_show_workstations_sales',
              text: this.$t('sales.workstationsSalesChart.config.title'),
              order: this.getOrder('locations_report_show_workstations_sales', 9)
            },
            {
              model: 'locations_report_show_gross_sales_per_big_division',
              text: this.$t('sales.bigDivisionChart.config.title'),
              order: this.getOrder('locations_report_show_gross_sales_per_big_division', 10)
            },
            {
              model: 'locations_report_show_discounts_table',
              text: this.$t('discountsTable.title'),
              order: this.getOrder('locations_report_show_discounts_table', 11)
            }
          ].sort((a, b) => a.order - b.order)
        },
        {
          title: this.$t('editors.groups.locations_report_table'),
          elements: [
            {
              model: 'locations_report_column_customer_count',
              text: this.$t('editors.elements.locations_report_column_customer_count'),
              order: this.getOrder('locations_report_column_customer_count', 1)
            },
            {
              model: 'locations_report_column_gross_sales_volume_percent_overall',
              text: this.$t('editors.elements.locations_report_column_gross_sales_volume_percent_overall'),
              order: this.getOrder('locations_report_column_gross_sales_volume_percent_overall', 2)
            },
            {
              model: 'locations_report_column_gross_sales_volume_percent',
              text: this.$t('editors.elements.locations_report_column_gross_sales_volume_percent'),
              order: this.getOrder('locations_report_column_gross_sales_volume_percent', 3)
            },
            {
              model: 'locations_report_column_gross_sales_volume',
              text: this.$t('editors.elements.locations_report_column_gross_sales_volume'),
              order: this.getOrder('locations_report_column_gross_sales_volume', 4)
            },
            {
              model: 'locations_report_column_discount_volume',
              text: this.$t('editors.elements.locations_report_column_discount_volume'),
              order: this.getOrder('locations_report_column_discount_volume', 5)
            },
            {
              model: 'locations_report_column_net_sales_volume',
              text: this.$t('editors.elements.locations_report_column_net_sales_volume'),
              order: this.getOrder('locations_report_column_net_sales_volume', 6)
            },
            {
              model: 'locations_report_column_quantity',
              text: this.$t('sales.table.columns.quantity'),
              order: this.getOrder('locations_report_column_quantity', 7)
            }
          ].sort((a, b) => a.order - b.order)
        }
      ];
    },
    getOrder(key, defaultOrder = 0) {
      return (this.config && this.config[key] && this.config[key].order) || defaultOrder;
    },
    update() {
      this.options.forEach(g => {
        g.elements.forEach((s, newOrder) => {
          if (this.config[s.model]) {
            this.config[s.model].order = newOrder + 1;
          }
        });
      });
      this.$emit('update', this.config);
      this.patchConfig({ config: this.config });
    },
    isAllowed(value) {
      return environment[value] !== false;
    }
  }
};
</script>

<style lang="scss">
@import 'mixins.scss';
@import 'constants.scss';

$page__header-shadow-color: rgba(black, 0.03);

.item {
  box-shadow: 0 2px 2px $page__header-shadow-color, 0 1px 0 $page__header-shadow-color;
}

.config_locations_display {
  display: block;

  .el-checkbox {
    display: block;
  }

  .el-divider__text {
    background-color: $wild-sand;
    padding: 0 10px 0 0;

    &.is-left {
      left: 0;
    }
  }
}
</style>
