var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    _vm._b(
      {
        staticClass: "report",
        attrs: {
          "multiple-locations":
            _vm.locationsList && _vm.locationsList.length > 1,
          "show-selector-button": true
        }
      },
      "vel-page",
      _vm.pageAttrs,
      false
    ),
    [
      _c("indicators", {
        key: _vm.updateIndicators,
        attrs: {
          "config-key": "locations_report",
          "show-icon": false,
          secondary: ""
        }
      }),
      _c("LocationReportCharts", { key: _vm.updateCharts }),
      _c("LocationsSalesData")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }