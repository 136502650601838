var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vel-page",
    {
      staticClass: "items__report",
      class: { modal: _vm.showConfig },
      attrs: {
        "multiple-locations": _vm.locationsList && _vm.locationsList.length > 1,
        "enable-date-range": true,
        "show-selector-button": true,
        showCrumbs: false,
        "enable-toolbar": true,
        "enable-currency": true,
        generatedTime: _vm.generatedTime
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("pages.reports.items")) + " ")]
          },
          proxy: true
        },
        {
          key: "toolbarMenu",
          fn: function() {
            return [
              _c(
                "vel-elink",
                {
                  attrs: { type: "info" },
                  on: {
                    click: function($event) {
                      _vm.showConfig = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("editors.customize_link")))]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.showConfig
        ? _c(
            "vel-modal",
            {
              attrs: { dismissable: "" },
              on: {
                dismiss: function($event) {
                  _vm.showConfig = false
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$t("editors.customize_link")) + " "
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1723931544
              )
            },
            [
              _c("config-items-report-display", {
                on: {
                  update: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "vel-card",
        { staticClass: "report__items" },
        [
          _c("item-sales-report", {
            attrs: {
              sales: _vm.getSalesTree,
              days: _vm.daysInRange,
              areSalesLoading: _vm.areSalesLoading,
              multipleLocation: _vm.multipleLocation,
              "hidden-columns": _vm.hiddenColumns
            },
            on: { input: _vm.handleInput }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }