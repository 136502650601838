import { defineStore } from 'pinia';
import router from '@/router';
import { useUIAlertStore } from '@/stores/ui/alert.module';
import uniqBy from 'lodash.uniqby';
import { parameterizeDate, today } from '@/helpers/date.helpers';
import { oc } from 'ts-optchain';
import locationsService from '@/services/locations.service';
import { getToken } from '@/helpers/token.helper';
import { createCollectionState, getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';
import { accentFold } from '@/helpers/string.helpers';
import uniq from 'lodash.uniq';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import {useUICurrencySelector} from "@/stores/ui/currency-selector.module";

const compareLocationLabel = (l1, l2) => oc(l1).label('').localeCompare(oc(l2).label(''));
const toLabelValue = location => ({ label: location.name, value: location.id });

const { selectAll, selectById } = getSelectors();

export const useDataLocationsStore = defineStore('locations', {
  state: () =>
    createCollectionState({
      filter: '',
      status: {},
      error: null
    }),
  getters: {
    getLocationsIsLoading(state) {
      return state.status.isLoading === true;
    },
    // @todo to be refactored
    getLocationsRows(state) {
      return state.filter
        ? this.getLocationsAll.filter(location => {
            const isGroupMatch = (location.groups || []).reduce(
              (a, b) => a || accentFold(b.name).toLowerCase().indexOf(accentFold(state.filter.toLowerCase())) >= 0,
              false
            );

            const value = `${location.name} ${location.reportingDisplayName} ${location.address} ${location.licenceNumber} ${location.version}`.toLowerCase();
            return isGroupMatch || accentFold(value).indexOf(accentFold(state.filter.toLowerCase())) >= 0;
          })
        : this.getLocationsAll;
    },
    getLocationsAll(state) {
      return selectAll(state);
    },
    getLocationsFirstCurrency(state) {
      return oc((this.getLocationsCurrencies || [])[0]).value();
    },
    getLocationsFirstCurrencySelectedGroup(state) {
      return oc((this.getLocationsCurrenciesBySelectedGroup || [])[0]).value();
    },
    getLocationsById: selectById,
    getLocationsByGroupId(state) {
      return id => this.getLocationsAll.filter(l => (l.groups || []).filter(g => g.id === id).length);
    },
    getLocationsByLicenceNumber(state) {
      return id => {
        const results = this.getLocationsAll.filter(l => l.licence_number === id);
        if (results && results[0]) {
          return results[0];
        }
        return null;
      };
    },
    getLocationsCurrencies() {
      return uniq(this.getLocationsAll.map(x => x.currency))
        .sort((a, b) => a.localeCompare(b))
        .map(x => ({ label: x, value: x }));
    },
    getLocationsCurrenciesBySelectedGroup() {
      const groupSelectorStore = useUIGroupSelector();
      return uniqBy(
        this.getLocationsAll
          .filter(
            l =>
              !groupSelectorStore.selected ||
              groupSelectorStore.selected === '' ||
              (l.groups || []).filter(g => groupSelectorStore.selected === g.id).length
          )
          .filter(x => !!(x.detailedCurrency && x.detailedCurrency.currencyCode))
          .map(x => x.detailedCurrency),
        'currencyCode'
      )
        .sort((a, b) => a.currencyCode.localeCompare(b.currencyCode))
        .map(x => ({
          label: `${x.currencyCode} (${x.currencySymbol})`,
          value: x.currencyCode,
          symbol: x.currencySymbol
        }));
    },
    locations() {
      return this.getLocationsAll
        .map(l => ({ label: l.reportingDisplayName && l.reportingDisplayName !== '' ? l.reportingDisplayName : l.name, value: l.id }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    locationsBySelectedGroup(state) {
      const groupSelectorStore = useUIGroupSelector();
      const currencySelectorStore = useUICurrencySelector()
      return this.getLocationsAll
        .filter(
          l =>
            !groupSelectorStore.selected ||
            groupSelectorStore.selected === '' ||
            (l.groups || []).filter(g => groupSelectorStore.selected === g.id).length
        )
        .filter(
          l =>
            !currencySelectorStore.getCurrencySelectorSelected ||
            currencySelectorStore.getCurrencySelectorSelected === '' ||
            l.detailedCurrency && l.detailedCurrency.currencyCode === currencySelectorStore.getCurrencySelectorSelected
        )
        .map(l => ({
          label: l.reportingDisplayName && l.reportingDisplayName !== '' ? l.reportingDisplayName : l.name,
          value: l.id
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    multiLocationsBySelectedGroup(state) {
      const groupSelectorStore = useUIGroupSelector();
      const locationSelectorStore = useUILocationSelector();
      this.getLocationsAll
        .filter(
          l =>
            (!groupSelectorStore.selected ||
              groupSelectorStore.selected === '' ||
              (l.groups || []).filter(g => groupSelectorStore.selected === g.id).length) &&
            (!locationSelectorStore.selectedMultiple.length ||
              locationSelectorStore.selectedMultiple.map(x => x.value).includes(l.id))
        )
        .map(l => ({ label: l.name === '' ? l.id : l.name, value: l.id }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    getLocationsAmount: state => state.data.ids.length
  },
  actions: {
    getLocations(query = {}) {
      this.getLocationsRequest();
      locationsService.getLocations(getToken(), query).then(
        locations => {
          this.getLocationsSuccess(locations);
        },
        error => {
          this.getLocationsFailure(error);
          const store = useUIAlertStore();
          store.newError(error);
        }
      );
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    getLocationsRequest() {
      this.status = { isLoading: true };
      this.data = removeAll(this.data);
      this.error = null;
      this.filter = '';
    },
    getLocationsSuccess(locations) {
      this.status = {};
      this.data = setAll(locations, this.data);
      this.error = null;
      this.filter = '';
    },
    getLocationsFailure(error) {
      this.status = {};
      this.data = removeAll(this.data);
      this.error = error;
    },
    setFilterValue(value) {
      this.filter = value.trim();
    }
  }
});
