<template>
  <portal to="app">
    <div class="location-add-modal">
      <vel-modal dismissable @dismiss="handleCancel">
        <template v-slot:header>
          {{ $t('group.locations.modals.add.title') }}
        </template>

        <template v-slot:default>
          <vel-alert @dismiss="clearAddGroupLocationsError" class="alert" level="error" v-if="addGroupLocationsError">
            {{ $t('group.errors.addGroupLocations') }}
          </vel-alert>

          <add-location-form
            class="location-add-modal__form"
            :locationsOptions="locationsOptions"
            :allowMultipleLocations="true"
            :isSavingLocations="isSavingLocations"
            @submit="handleSubmit"
            @cancel="handleCancel"
          ></add-location-form>
        </template>
      </vel-modal>
    </div>
  </portal>
</template>

<script>
import AddLocationForm from '../group-form/AddLocationForm.vue';
import VelAlert from '@/components/alert/VelAlert';
import VelModal from '@/components/modal/VelModal';
import {mapState as mapPiniaState, mapActions as mapPiniaActions} from "pinia/dist/pinia";
import {useDataGroupsLocationGroup} from "@/stores/data/groups/location-group.module";

export default {
  name: 'add-location-modal',
  components: {
    AddLocationForm,
    VelAlert,
    VelModal
  },
  data() {
    return {
      location: {}
    };
  },
  props: {
    groupId: {
      type: String,
      required: true
    },
    locationsOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapPiniaState(useDataGroupsLocationGroup, [
      'addGroupLocationsError',
      'latestAddedGroupLocationsCount',
      'isSavingLocations'
    ])
  },
  watch: {
    latestAddedGroupLocationsCount(addedGroupLocations) {
      if (addedGroupLocations) {
        this.$emit('dismiss');
      }
    }
  },
  methods: {
    ...mapPiniaActions(useDataGroupsLocationGroup, [
      'addGroupLocations',
      'clearLatestAddedGroupLocations',
      'clearAddGroupLocationsError'
    ]),
    handleCancel() {
      this.$emit('dismiss');
    },
    handleSubmit(locationIds) {
      this.addGroupLocations({ groupId: this.groupId, locationIds });
    }
  },
  created() {
    this.clearLatestAddedGroupLocations();
    this.clearAddGroupLocationsError();
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/deep/ .location-form__location .el-select {
  width: 100%;
}

.location-add-modal__form {
  margin-top: rem-calc(16);
}
</style>
