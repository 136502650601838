<template>
  <vel-backoffice-page class="bootstrap px-2">
    <template slot="header">
      <div style="border-bottom: 8px solid rgb(245, 245, 245);">
        <div
          class="d-flex justify-content-between"
          style="border-bottom: 1px solid rgba(76, 86, 103, 0.2); padding: 0.8rem 1.5rem;"
        >
          <h1 class="title-right my-0" style="font-size: 1.5rem;">
            {{ $t('locationStatus.title') }}
          </h1>
          <vel-export
            v-if="Object.values(locations).length"
            :disabled="isLoading || !Object.values(locations).length"
            :generating="generating"
            :enable-pdf="true"
            :enable-xls="true"
            @exportPDF="onExportPDF"
            @exportXLS="onExportXLS"
          ></vel-export>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="px-3 py-1 row">
        <div class="col-md-12 d-flex justify-content-end align-items-center">
          <b-form-group class="d-none d-md-block">
            {{ $t('employee.list.rowsPerPage') }}
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="[10, 25, 50, 100]"
              style="width: 80px;"
              class="mx-3"
              :disabled="isLoading || generating || !locations.length"
              @change="fetchData"
            ></b-form-select>
          </b-form-group>
          <span class="d-none d-md-flex align-self-center mr-6" v-if="locations.length">
            {{
              $t('pagination.format', {
                first: currentPage * perPage - perPage + 1,
                last: Math.min(currentPage * perPage, totalResults),
                total: totalResults
              })
            }}
          </span>
          <b-pagination
            size="sm"
            pills
            class="m-0 ml-2"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalResults"
            :limit="5"
            align="right"
            :disabled="isLoading || generating || !locations.length"
          ></b-pagination>
        </div>
      </div>
    </template>
    <div class="p-2 px-4">
      <vel-group-selector
        allowGlobalGroupOption
        class="group-selector"
        :allGroupsLabel="$t('dashboard.groupSelector.allGroupsText')"
        :groupId="groupId"
        :groupsOptions="groups"
        :disabled="groupsLoading"
        :multiple="false"
        autofocus
        filterable
        :showGlobalGroupOptionGroupLabel="false"
        @change="handleGroupChange"
      ></vel-group-selector>
      <vel-button id="generateButton" @click="fetchData()">
        {{ this.newGeneration ? $t('toolbar.selectors.generateLabel') : $t('locationStatus.refresh') }}
      </vel-button>
    </div>
    <b-container fluid class="h-100 px-4 mb-4 mt-2" id="table-container">
      <div class="text-center" v-show="isLoading">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <vel-card v-show="!isLoading && !newGeneration">
        <div class="report">
          <div class="filters_toggle_container">
            <span class="filters_toggle" @click="showFilters = !showFilters">
              <label>
                {{ showFilters ? $t('locationStatus.filters.hide') : $t('locationStatus.filters.show') }}
              </label>
              <b-icon icon="chevron-down" />
            </span>
          </div>
          <transition name="fade">
            <LocationsFilters
              v-show="showFilters"
              :disabled="isLoading"
              @filtersChanged="fetchData"
              ref="locationsFilters"
            />
          </transition>
          <b-table
            v-if="isLoading || locations.length > 0"
            primary-key="id"
            :busy="isLoading"
            :items="locations"
            :fields="columns"
            responsive="sm"
            ref="table"
            sticky-header
            :no-local-sorting="true"
            @sort-changed="sortingChanged"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            class="locationStatusTable"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner variant="primary" class="align-middle p-2 m-4"></b-spinner>
              </div>
            </template>
            <template v-slot:head()="scope">
              <div
                class="container"
                :id="`table_header_${scope.field.key}`"
                v-b-tooltip.hover.bottom
                :title="scope.field.tooltip ? scope.field.tooltip : null"
              >
                <div>{{ scope.field.label }}</div>
                  <vel-icon
                    v-if="scope.field.tooltip"
                    style="fill: #2a314261; color: #2a314261; margin-left: 0.5rem"
                    name="question-circle"
                    modifier="on-bright"
                    size="small"
                  />
                <div v-if="scope.field.sortable || false" class="sort-icon">
                  <b-icon
                    icon="arrow-down"
                    :class="`vel-table-header__sort${
                      sortBy === scope.field.key ? (sortDesc ? '-icon_down' : '-icon_up') : ''
                    }`"
                  />
                </div>
              </div>
            </template>
            <template #cell(licenceNumber)="scope">
              {{ scope.item.licenceNumber }}
            </template>
            <template #cell(locationName)="scope">
              {{ scope.item.name || '--' }}
            </template>
            <template #cell(status)="scope">
              <LocationState :status="scope.item.status.health" />
            </template>
            <template #cell(lastActivity)="scope">
              {{ (scope.item.status.lastActivityTime ? formatDateTime(scope.item.status.lastActivityTime) : '--') }}
            </template>
            <template #cell(lastInvoice)="scope">
              {{ (scope.item.status.lastInvoiceTime ? formatDateTime(scope.item.status.lastInvoiceTime) : '--') }}
            </template>
            <template #cell(posType)="scope">
              <VeloceLogo v-if="scope.item.posType === 0" />
              <MaitreDLogo v-if="scope.item.posType === 1" />
            </template>
            <template #cell(posVersion)="scope">
              {{ scope.item.version }}
            </template>
          </b-table>

          <vel-no-results v-if="!isLoading && locations.length === 0" />
        </div>
      </vel-card>
    </b-container>
  </vel-backoffice-page>
</template>

<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import LanguageMixin from '@/mixins/language-mixin';
import LocationState from './components/LocationState';
import LocationsFilters from './components/LocationsFilters';
import MaitreDLogo from '@/assets/svg/MaitreD.svg';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelExport from '@/components/export/VelExport';
import VelGroupSelector from '@/components/group-selector/VelGroupSelector';
import VelNoResults from '@/components/no-results/VelNoResults';
import VeloceLogo from '@/assets/svg/Veloce.svg';
import { getDateFormatFromUserConfig } from '@/helpers/date.helpers';
import locationsService from '@/services/locations.service';
import { storeToRefs } from 'pinia';
import { useDataConfig } from '@/stores/data/config.module';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import VelIcon from '@/components/icon/VelIcon';

export default {
  name: 'LocationsStatusPage',
  components: {
    VelCard,
    VelButton,
    LocationsFilters,
    VelBackofficePage,
    VelNoResults,
    LocationState,
    VeloceLogo,
    MaitreDLogo,
    VelGroupSelector,
    VelIcon,
    VelExport
  },
  mixins: [DeviceMixin, LanguageMixin],
  setup() {
    const uiGroupSelectorStore = useUIGroupSelector();
    const { getGroupSelectorSelected } = storeToRefs(uiGroupSelectorStore);

    return {
      groupId: getGroupSelectorSelected,
      onGroupSelectorChange: uiGroupSelectorStore.onChange,
      uiGroupSelectorStore
    };
  },
  data() {
    return {
      newGeneration: true,
      showFilters: false,
      generating: false,
      sortBy: 'licenceNumber',
      sortDesc: false,
      locations: [],
      perPage: window.localStorage.getItem('LocationStatusRowsPerPage') || 25,
      totalResults: 0,
      currentPage: 1,
      isLoading: false,
      selectedGroupId: null
    };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useUIGroupSelector, {
      groups: 'groups',
      groupsLoading: 'isLoading'
    }),
    columns() {
      return [
        {
          key: 'licenceNumber',
          class: 'licenceNumber',
          label: this.$t('locationStatus.table.headers.licenceNumber'),
          sortable: true,
          filterName: 'licenceNumber'
        },
        {
          key: 'locationName',
          class: 'locationName',
          label: this.$t('locationStatus.table.headers.location'),
          sortable: true,
          filterName: 'name'
        },
        {
          key: 'status',
          class: 'status',
          label: this.$t('locationStatus.table.headers.status'),
          sortable: true,
          filterName: 'status'
        },
        {
          key: 'lastActivity',
          class: 'lastActivity',
          label: this.$t('locationStatus.table.headers.lastActivity'),
          sortable: true,
          filterName: 'lastActivity',
          tooltip: this.$t('locationStatus.table.headers.lastActivityTooltip')
        },
        {
          key: 'lastInvoice',
          class: 'lastInvoice',
          label: this.$t('locationStatus.table.headers.lastInvoice'),
          sortable: true,
          filterName: 'lastInvoice',
          tooltip: this.$t('locationStatus.table.headers.lastInvoiceTooltip')
        },
        {
          key: 'posType',
          class: 'posType',
          label: this.$t('locationStatus.table.headers.posType'),
          sortable: true,
          filterName: 'posType'
        },
        {
          key: 'posVersion',
          class: 'posVersion',
          label: this.$t('locationStatus.table.headers.posVersion'),
          sortable: true,
          filterName: 'version'
        }
      ];
    }
  },
  watch: {
    async currentPage() {
      await this.fetchData();
    },
    async perPage(val) {
      window.localStorage.setItem('LocationStatusRowsPerPage', val);
      await this.fetchData();
    }
  },
  methods: {
    ...mapPiniaActions(useUIGroupSelector, ['loadGroups']),
    handleGroupChange(groupId) {
      this.selectedGroupId = groupId;

      this.onGroupSelectorChange({
        groupId,
        locationId: null,
        locationIds: null
      });
    },
    async fetchData() {
      this.isLoading = true;

      const filtersParams = this.$refs.locationsFilters.getAppliedFilters();
      const params = {
        groupId: this.$route.query.groupId || null,
        locationId: null,
        offset: (this.currentPage - 1) * this.perPage,
        limit: this.perPage,
        sort: this.columns.find(a => a.key === this.sortBy).filterName || 'licenceNumber',
        order: this.sortDesc ? 'desc' : 'asc',
        ...filtersParams
      };

      await locationsService
        .getLocationsSearch(params)
        .then(data => {
          this.totalResults = data.total;
          this.locations = data.content || [];
        })
        .catch(e => {
          console.error(e);
          this.locations = [];
        })
        .finally(() => {
          this.isLoading = false;
          this.newGeneration = false;
        });
    },
    formatDateTime(value) {
      if (!value) {
        return '--';
      }

      const use24h = this.get.enable_military_time_format.value;
      const date = DateTime.fromISO(value);

      if (use24h) {
        return `${date.toFormat(getDateFormatFromUserConfig())} - ${date.toFormat('HH:mm')}`;
      }
      return `${date.toFormat(getDateFormatFromUserConfig())} - ${date.toFormat('hh:mm a')}`;
    },
    async sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;

      await this.fetchData();
    },
    getExportParameters() {
      this.$ga.event('report', 'download', this.$route.name);
      this.generating = true;

      const filtersParams = this.$refs.locationsFilters.getAppliedFilters();
      return {
        groupId: this.$route.query.groupId || null,
        locationId: null,
        sort: this.columns.find(a => a.key === this.sortBy).filterName || 'licenceNumber',
        order: this.sortDesc ? 'desc' : 'asc',
        lang: this.getLanguage(),
        ...filtersParams
      };
    },
    async onExportPDF() {
      this.generating = true;

      const params = this.getExportParameters();
      await locationsService.downloadLocationsSearchPDF(params).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    async onExportXLS() {
      this.generating = true;

      const params = this.getExportParameters();
      await locationsService.downloadLocationsSearchXlsx(params).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    }
  },
  created() {
    if (!this.groups.length) {
      this.loadGroups().then(() => {
        if (this.groups && this.groups.length === 1) {
          this.handleGroupChange(this.groups[0].value);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';
@import '@/components/button/VelButton.scss';

$font-size-small: rem-calc(12);
$font-size-normal: rem-calc(14);
$contrast-color: #00a9e1;

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.page {
  display: flex;
  overflow: hidden;
  background: #f5f5f5;

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    margin: rem-calc(10) rem-calc(10) rem-calc(0);

    @include breakpoint(large) {
      margin: rem-calc(32) rem-calc(40) rem-calc(0);
    }
  }
}

#table-container {
  * {
    font-size: $font-size-small;
  }

  flex-grow: 1;
  overflow: hidden;
  padding: 0;
  padding-bottom: 2px;

  .report {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .vel-card {
    height: 100%;
    overflow: hidden;
    padding: 20px 24px;
    box-shadow: 0px 2px 4px #00000014;

    /deep/ .vel-card__body,
    .b-table-sticky-header {
      height: 100%;
      max-height: 100%;
      padding-top: 0;
      gap: 10px;
    }

    /deep/ .vel-card__body {
      display: flex;
      flex-direction: column;
    }
  }

  /deep/ table {
    padding-top: rem-calc(12);

    th {
      border-top: none;
      background-image: none;
      background: white;
      padding-top: rem-calc(16);
      padding-bottom: rem-calc(16);
      padding-right: 0.5rem;

      .container {
        align-items: center;
        cursor: pointer;
        display: flex;
        user-select: none;
        word-break: keep-all;
        padding: 0;
      }

      .sort-icon {
        margin-left: rem-calc(5);

        & .vel-table-header__sort-icon_down,
        & .vel-table-header__sort-icon_up {
          color: $contrast-color;
        }

        & .vel-table-header__sort-icon_up {
          transform: rotate(180deg);
        }
      }

      &.align-right > .container {
        justify-content: end;
      }
    }

    tbody {
      td {
        &,
        * {
          font-size: $font-size-normal !important;
        }

        min-width: unset;
        padding: 0;
        text-align: left;
        text-overflow: ellipsis;
        vertical-align: top !important;
        @include breakpoint(medium) {
          padding: 1em 1.25em 1em 0.5em;
        }

        &.align-right {
          text-align: right;
        }

        &:last-child a {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          padding: 0.5em;

          & > .action {
            color: $contrast-color;
            height: 16px;
            width: 16px;
          }
        }
      }

      tr.selected {
        &:not(.b-table-empty-row):not(.b-table-busy-slot) {
          & td {
            color: white;
            background: $contrast-color;

            &:first-child {
              border-top-left-radius: 0.5em;
              border-bottom-left-radius: 0.5em;
            }
            &:last-child {
              border-top-right-radius: 0.5em;
              border-bottom-right-radius: 0.5em;

              & a {
                background: white;
                border-radius: 50%;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .filters_toggle {
    overflow: hidden;

    * {
      cursor: pointer;
      color: $contrast-color;
    }

    & > .b-icon {
      padding-left: 5px;
      height: 16px;
      width: 16px;
    }
  }

  .fade-enter-active {
    animation: fade 0.5s ease-in forwards;
  }

  .fade-leave-active {
    animation: fade 0.5s reverse ease-in forwards;
  }

  @keyframes fade {
    0% {
      max-height: 0%;

    }
    100% {
      max-height: 100%;
    }
  }

  .filters {
    align-items: flex-end;
    display: flex;
    margin-bottom: 0.5em;

    .btn-secondary {
      background: #f5f5f5;
      color: $contrast-color;
      border: none;
    }

    &__fields {
      display: flex;
      flex-grow: 1;
      gap: 5px;
      padding-right: rem-calc(16);

      .el-select,
      /deep/ .el-select__tags,
      /deep/ .el-input,
      /deep/ .el-select__input,
      /deep/ .el-input__inner,
      /deep/ .el-input__suffix {
        max-height: 32px;
        height: 32px;
      }

      /deep/ .el-input__icon {
        line-height: 32px;
        color: $contrast-color;
      }

      input.form-control {
        height: 32px;
      }

      .form-group {
        max-width: 150px;
        .input-group-text {
          width: 24px;
          border-right: none;
          background-color: transparent !important;
          color: #d9d9d9;
          padding: 0;

          svg {
            margin-left: 4px;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      gap: 5px;
      border-left: 1px solid #dbdbdb;
      padding-left: rem-calc(16);
    }
  }
}

.group-selector {
  margin-right: rem-calc(8);
}

.xlsx-export-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

.locationStatusTable /deep/ {

  overflow: auto;

  table {
    min-width: 1200px;
  }

  .licenceNumber {
    width: 200px;
  }

  .locationName {
    width: 360px;
  }

  .status {
    width: 140px;
  }

  .lastActivity {
    width: 260px;
  }

  .lastInvoice {
    width: 260px;
  }

  .posType {
    width: 260px;
  }

  .posVersion {
    width: auto;
  }
}

/deep/ .tooltip-inner {
  background-color: #fff;
  border: 1px solid #6f6f6f;
  border-radius: 0.25rem;
  color: #666;
  font-size: 0.8rem;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}

#generateButton {
  width: 100px;
}

/deep/ .vel-page__content {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
