import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';
import {useDataSalesIndicators} from "@/stores/data/sales/indicators.module";
import discountsModuleHelper from "@/stores/discounts.module.helper";
import groupsService from "@/services/groups.service";
import itemsService from "@/services/items.service";
import {addMany, getSelectors, removeAll} from "@/helpers/stores.helpers";

const { selectAll, selectTotal } = getSelectors();

export const useDataItemsPricesItem = defineStore('itemsPricesItem', {
  state: () => ({
    loading: false,
    error: null
  }),
  getters: {
    error: state => state.error,
    loading: state => state.loading,
    hasError: state => !!state.error,
    total: selectTotal,
    prices: selectAll
  },
  actions: {
    getPricesItem(id) {
      this.getPricesItemRequest();

      itemsService.getItemByIdPrices(getToken(), { id }).then(
        locations => {
          this.getPricesItemSuccess(locations);
        },
        error => {
          this.getPricesItemFailure(error);
        }
      );
    },
    updatePricesItem({ itemId, prices }) {
      this.updatePricesItemRequest();

      itemsService.updateItemByIdSPrices(getToken(), itemId, prices).then(
        locations => {
          this.updatePricesItemSuccess(locations);
        },
        error => {
          this.updatePricesItemFailure(error);
        }
      );
    },
    getPricesItemRequest() {
      this.loading = true;
      this.error = null;
      this.data = removeAll(this.data);
    },
    getPricesItemSuccess(prices) {
      this.loading = false;
      this.data = addMany(
        prices.map((s, k) => ({ ...s, id: s.id || k, price: s.price })),
        this.data
      );
    },
    getPricesItemFailure(error) {
      this.loading = false;
      this.error = error;
    },
    updatePricesItemRequest() {
      this.loading = true;
      this.error = null;
    },
    updatePricesItemSuccess(prices) {
      this.loading = false;
      this.data = addMany(
        prices.map((s, k) => ({ ...s, id: s.id || k, price: s.price })),
        this.data
      );
    },
    updatePricesItemFailure(error) {
      this.loading = false;
      this.error = error;
    }
  }
});
