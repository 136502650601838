<template>
  <vel-page class="report" :showCrumbs="false" :generatedTime="generatedTime" :enable-currency="true">
    <template v-slot:title>
      {{ $t('pages.reports.sales') }}
    </template>

    <template v-slot:toolbar>
      <vel-location-selector
        allowGlobalLocationOption
        class="report__location-selector"
        :allLocationsLabel="$t('dashboard.locationSelector.allLocationsText')"
        :disabled="locationsLoading"
        :locationId="mutableLocationId"
        :locationsOptions="locations"
        :showGlobalLocationOptionGroupLabel="false"
        @change="handleLocationChange"
      />

      <date-range-selector class="report__date-range-selector" :range="mutableRange" @change="handleDateRangeChange" />
    </template>

    <vel-card class="report__sales">
      <sales-detailed
        class="report_table"
        :loading="areSalesLoading"
        :sales="sales"
        :hoverable="false"
        :showQuantity="false"
        :exportableMetas="exportableMetas"
      />
    </vel-card>
  </vel-page>
</template>

<script>
import DateRangeSelector from '@/private/components/date-range-selector/DateRangeSelector.vue';
import GeneratedTime from '@/mixins/generated-time-mixin';
import SalesDetailed from '@/private/components/sales/SalesDetailed';
import SelectableDateRangeMixin from '@/mixins/selectable-date-range-mixin';
import SelectableLocationMixin from '@/mixins/selectable-location-mixin';
import VelCard from '@/components/card/VelCard';
import VelLocationSelector from '@/components/location-selector/VelLocationSelector';
import VelPage from '@/components/page/VelPage';
import pickBy from 'lodash.pickby';
import { slugify } from '@/helpers/string.helpers';
import {mapActions as mapPiniaActions, mapState as mapPiniaState} from "pinia/dist/pinia";
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataSales} from "@/stores/data/sales.module";

export default {
  name: 'LocationReportPage',
  components: {
    DateRangeSelector,
    SalesDetailed,
    VelCard,
    VelLocationSelector,
    VelPage
  },
  mixins: [SelectableDateRangeMixin, SelectableLocationMixin, GeneratedTime],
  computed: {
    ...mapPiniaState(useUILocationSelector, {
      locations: 'locations',
      locationsLoading: 'isLoading',
      getLocationSelectorSelected: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    ...mapPiniaState(useDataSales, {
      areSalesLoading: 'areSalesLoading',
      salesLocation: 'location'
    }),
    ...mapPiniaState(useDataSales, {
      sales: 'sales'
    }),

    exportableMetas() {
      return {
        title: this.$t('exports.title.sales'),
        locationTitle: this.getLocationSelectorSelected
          ? this.getLocationsById(this.getLocationSelectorSelected) &&
            (this.getLocationsById(this.getLocationSelectorSelected).reportingDisplayName ||
              this.getLocationsById(this.getLocationSelectorSelected).name)
          : this.$t('exports.allLocationsTitle'),
        fileName: slugify(this.$t('exports.title.sales')),
        from: this.from,
        to: this.to,
        i18n: this.$t('export')
      };
    }
  },
  methods: {
    ...mapPiniaActions(useDataSales, ['getDetailedSales']),
    ...mapPiniaActions(useDataSales, ['reset'])
  },
  beforeDestroy() {
    this.reset();
  },
  mounted() {
    this.getDetailedSales(
      pickBy({ from: this.mutableRange[0], to: this.mutableRange[1], locationId: this.mutableLocationId }, a => !!a)
    );
    // this.generateTimeAt('data/sales/bigDivisions/getBigDivisionSalesSuccess');
  }
};
</script>
<style lang="scss" scoped>
@import 'mixins.scss';

.report__date-range-selector {
  margin-top: 4px;
  max-width: 100%;
  min-width: 190px;
  width: 100%;

  @include breakpoint(medium) {
    margin-top: 0;
    max-width: 205px;
    width: initial;
  }
}

/deep/ .vel-page__toolbar {
  margin-bottom: 1.25rem;
}

.report_table {
  /deep/ .vel-table-action {
    margin-top: 0;
  }
}
</style>
