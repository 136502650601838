import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import {useDataAccount} from "@/stores/data/account.module";
import {useDataGroups} from "@/stores/data/groups.module";
import {useDataLocationsStore} from "@/stores/data/locations.module";

export const useDataUsersUserPermissions = defineStore('usersUserPermissions', {
  state: () => ({
    status: { loggedIn: true, isRefreshingUser: false, isLoadingCurentUserPermissions: false },
    userPermissions: null,
    isPermissionsLoaded: false
  }),
  getters: {
    permissions(state) {
      return state.userPermissions;
    },
    globalPermissions(state) {
      return (state.userPermissions && state.userPermissions.global) || [];
    },
    hasGlobalPermission() {
      return permission => this.globalPermissions.some(access => access.permission === permission);
    },
    hasProductAccess: state => product => {
      const hasProduct = g => (g.products || []).map(p => p.toLowerCase()).includes(product.toLowerCase());
      return (state.userPermissions && state.userPermissions.groups || []).length > 0
      //return ((state.userPermissions && state.userPermissions.groups) || []).some(hasProduct);
    },
    hasCouponsAccess() {
      return this.isUserAdmin || this.hasProductAccess('coupons');
    },
    isUserAdmin(state) {
      return this.hasGlobalPermission('fullAccess');
    }
  },
  actions: {
    getCurrentUserPermissions() {
      this.getCurrentUserPermissionsRequest();

      const accountStore = useDataAccount();
      const userId = accountStore.userId;

      usersService.getUserPermissions(getToken(), { userId, include: ['location', 'product'] }).then(
        permissions => {
          this.getCurrentUserPermissionsSuccess(permissions);

          const groupsStore = useDataGroups();
          const locationsStores = useDataLocationsStore();

          locationsStores.getLocations( { include: 'groups' });
          groupsStore.fetchGroups();
        },
        error => {
          this.getCurrentUserPermissionsFailure(error);
        }
      );
    },
    getCurrentUserPermissionsRequest() {
      this.status = { ...this.status, isLoadingCurentUserPermissions: true };
      this.error = null;
      this.isPermissionsLoaded = false;
    },
    getCurrentUserPermissionsSuccess(permissions) {
      this.status = { ...this.status, isLoadingCurentUserPermissions: false };
      this.userPermissions = permissions;
      this.error = null;
      this.isPermissionsLoaded = true;
    },
    getCurrentUserPermissionsFailure(error) {
      this.status = { ...this.status, isLoadingCurentUserPermissions: false };
      this.userPermissions = null;
      this.error = error;
      this.isPermissionsLoaded = true;
    },
    reset() {
      this.userPermissions = null;
      this.isPermissionsLoaded = false;
    }
  }
});
